import strings from "../../localization";

const Currency = {
    POUND: 1
};

export function getCurrency() {
    return [
        {
            id: Currency.POUND,
            name: getCurrencyString(Currency.POUND)
        }
    ]
}

export function currencyDefaultValue() {
    return {
        id: Currency.POUND,
        name: getCurrencyString(Currency.POUND)
    }
}

export function getCurrencyString(currency) {
    switch(currency) {
        case Currency.POUND: return strings.constants.base.currency.pound;
    }
}

export default Currency;