import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import strings from "../../../../localization";
import { Button } from "@mui/material";
import TextFieldControl from '../../../Controls/Inputs/TextFieldControl';
import CheckBoxControl from '../../../Controls/Inputs/CheckBoxControl';
import SelectControl from '../../../Controls/Inputs/SelectControl';
import { getParties } from '../../../../Constants/Contract/Partie';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getAllCompanies, getAllCompaniesForContract } from "../../../../Services/Company/CompanyService";
import Tooltip from "@mui/material/Tooltip";
import ContractContentType from "../../../../Constants/Contract/ContractContentType";
import ContractStatus from "../../../../Constants/Contract/ContractStatus";

const partieDefaultValue = { id: -1, name: strings.constants.contract.parties.parties }

const InviteCollaboratorForm = ({
    setSendInviteForm,
    submitCollaborator,
    uploadedContent,
    contract,
    addSignee,
    setShowForm,
}) => {

    const form = useForm();
    const { data, control, handleSubmit, getValues, setValue, setError, formState: { errors } } = form;
    const [parties, setParties] = useState([]);

    useEffect(() => {
        fetchCompanies();
    }, [])

    const fetchCompanies = () => {

        if (parties?.length > 0) {
            return
        }

        // let result = [{
        //     companyName: 'Individual',
        //     id: -1
        // }];

        // setParties(result);

        let result = []



        getAllCompaniesForContract(contract.id).then(response => {

            result.push({
                companyName: 'Individual',
                id: -1
            })
            
            for (const company of response.data) {
                if (company) {
                    result.push({
                        companyName: company,
                        id: company
                    });
                }
            }

          

            setParties(result);
        });

    }

    const handleCloseInviteForm = () => {
        setSendInviteForm(false)
    }

    const onSubmit = (data) => {

        if ((contract.state === ContractStatus.PENDING_REVIEW || contract.state === ContractStatus.CREATED) && (data?.email === contract?.user?.email)) {
            setError('email', { type: 'custom', message: strings.forms.contractList.collaboratorsForm.cannotAddOwnerForReviewer })
            return;
        }

        setSendInviteForm(false)
        submitCollaborator(data)
    }

    return (
        <div className="invite-collaborator-form">

            <div className="title" onClick={() => setShowForm(false)}>
                <span className="info-icon-text">

                    {!addSignee ? 'Share with people' : 'Add Signee'}
                </span>
            </div>

            <FormProvider {...form}>
                <TextFieldControl
                    name='fullName'
                    control={data}
                    defaultValue=''
                    rules={{ required: true }}
                    margin="normal"
                    fullWidth
                    error={Boolean(errors.fullName)}
                    helperText={errors.fullName && strings.forms.contractList.collaboratorsForm.fullName}
                    placeholder={strings.forms.contractList.collaboratorsForm.fullName}
                />

                <TextFieldControl
                    name='email'
                    control={data}
                    defaultValue=''
                    rules={{ required: true }}
                    margin="normal"
                    fullWidth
                    error={Boolean(errors.email)}
                    helperText={errors.email && (errors.email.message || strings.forms.contractList.collaboratorsForm.email)}
                    placeholder={strings.forms.contractList.collaboratorsForm.email}
                />

                <SelectControl
                    defaultValue={''}
                    setValue={setValue}
                    name='company'
                    options={parties}
                    nameKey={'companyName'}
                    valueKey={'id'}
                />

                <div className="checkboxes-container collaborator-types-container">
                    {/*{*/}
                    {/*    contract.contentType === ContractContentType.GENERATED &&*/}
                    {/*    <React.Fragment>*/}
                    {/*        <CheckBoxControl*/}
                    {/*            control={control}*/}
                    {/*            name={'reviewer'}*/}
                    {/*            control={data}*/}
                    {/*            label={strings.forms.contractList.collaboratorsForm.reviewer}*/}
                    {/*            setValue={setValue}*/}
                    {/*        />*/}
                    {/*        <Tooltip*/}
                    {/*            title={'A reviewer is a person who can review your contract and suggest amendments and add comment. As the contract owner you will have the autonomy to reject or accept the amendments. Once you have selected the reviewers you can invite them to review the contract.'}>*/}
                    {/*            <img className={'tooltip-icon'} src={'/images/info_circle.svg'}/>*/}
                    {/*        </Tooltip>*/}
                    {/*    </React.Fragment>*/}
                    {/*}*/}

                    {/*{*/}
                    {/*    contract.contentType === ContractContentType.GENERATED &&*/}
                    {/*    <CheckBoxControl*/}
                    {/*        name={'signatory'}*/}
                    {/*        control={data}*/}
                    {/*        label={strings.forms.contractList.collaboratorsForm.signatory}*/}
                    {/*        setValue={setValue}*/}
                    {/*    />*/}
                    {/*}*/}

                    {/*{*/}
                    {/*    contract.contentType === ContractContentType.UPLOADED &&*/}
                    {/*    <CheckBoxControl*/}
                    {/*        name={'signatory'}*/}
                    {/*        control={data}*/}
                    {/*        label={strings.forms.contractList.collaboratorsForm.signatory}*/}
                    {/*        setValue={setValue}*/}
                    {/*        defaultValue={true}*/}
                    {/*        disabled={true}*/}
                    {/*    />*/}
                    {/*}*/}

                    {/*<Tooltip*/}
                    {/*    title={'An signatory is a person who will e-sign the document to legally bind the contract. Once you have selected the signatory and completed the review stage you can invite the signatories to e-sign the contract.'}>*/}
                    {/*    <img className={'tooltip-icon'} src={'/images/info_circle.svg'}/>*/}
                    {/*</Tooltip>*/}
                </div>

                <Button className="btn default-btn" onClick={handleSubmit(onSubmit)}>
                    {strings.forms.contractList.collaboratorsForm.sendInviteBtn}
                </Button>

            </FormProvider>

        </div>
    )
}

export default InviteCollaboratorForm;