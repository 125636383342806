import React from 'react';
import styles from "../../../../../Pages/dealRoom/DealRoom.module.css";


const FundingForm = ({ setFormName, setPageName, formData, handleChange, setErrors, errors }) => {


    const handleContinue = () => {
        const { fullName, email, mobile } = formData;
        const newErrors = {};

        if (!fullName) {
            newErrors.fullName = "Full Name is required";
        }
        if (!email) {
            newErrors.email = "Email is required";
        }
        if (!mobile) {
            newErrors.mobile = "Mobile Number is required";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setFormName("businessInfoForm");
    };
    return (
        <div className={styles.dealRoom}>
            <div className={styles.filledBox} >
                <div className={styles.labelText}>
                    <span>Your Name:*</span>
                </div>
                <div className={styles.inputContainer} >
                    <input
                        className={styles.inputBox}
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter your Name"
                    />
                </div>
                {errors.fullName && <span className={styles.errorMessage}>{errors.fullName}</span>}
            </div>
            <div className={styles.filledBox} >
                <div className={styles.labelText}>
                    <span>Email Address:*</span>
                </div>
                <div className={styles.inputContainer} >
                    <input
                        className={styles.inputBox}
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        type="email"
                        placeholder="Enter email address"
                    />
                </div>
                {errors.email && <span className={styles.errorMessage}>{errors.email}</span>}
            </div>
            <div className={styles.filledBox} >
                <div className={styles.labelText}>
                    <span>Phone:*</span>
                </div>
                <div className={styles.inputContainer} >
                    <input
                        className={styles.inputBox}
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="Enter phone number"
                    />
                </div>
                {errors.mobile && <span className={styles.errorMessage}>{errors.mobile}</span>}
            </div>
            <div className={styles.payButtonRow}>
                <div className={styles.addButton} style={{ borderRadius: "7px", backgroundColor: "#FFF" }} onClick={() => setPageName("connectPage")} >
                    <span style={{ color: "#3D32CF" }}>Back</span>
                </div>
                <div className={styles.addButton} style={{ borderRadius: "7px" }} onClick={() => handleContinue()} >
                    <span >Continue</span>
                </div>
            </div>
        </div>
    )
}

export default FundingForm