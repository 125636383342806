import React, { useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "@mui/material";
import strings from "../../localization";
import SelectControl from '../../Components/Controls/Inputs/SelectControl';
import { cancelSubscription, getCreditcardDetails, getSubscriptionData, updatePaymentMethod } from "../../Services/Subscribe/SubscribeService";
import { formatDateWithTime } from "../../Util/DateUtil";
import { getPaymentFilter, getPaymentFilterDefault } from '../../Constants/Dashboard/Payment';
import { formatDateInText } from '../../Util/DateUtil';
import UnsubscribeInfo from '../../Components/Modals/Pages/Dashboard/UnsubscribeInfo';
import Modal from "@mui/material/Modal";
import SimpleConfirmModal from './../../Components/Modals/SimpleConfirmModal';
import PaymentMethodModal from '../../Components/Modals/Pages/Dashboard/PaymentMethodModal';
import SnackbarContext from "../../Context/SnackbarContext";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isPackageValid } from "../../Util/PermissionUtil";
import { getCurrentUser } from "../../Base/OAuth";
import { setAuth } from "../../Slices/AuthSlice";
import LoaderContext from "../../Context/LoaderContext";
import CreateInvoicePdf from "./CreateInvoicePdf";
import { set } from "date-fns";
import ConfirmModal from "../../Components/Modals/ConfirmModal";

const paymentFilterTitle = { id: -1, name: strings.constants.dashboard.payment.filter }

const SubscriptionDetails = () => {

    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user)
    const form = useForm();
    const spaces = '          ';
    const { data, control, handleSubmit, getValues, setValue, formState: { errors } } = form;
    const [paymentFilter, setPaymentFilter] = useState([])
    const [subscription, setSubscription] = useState(null)
    const [invoices, setInvoices] = useState([])
    const [product, setProduct] = useState(null);
    const [openUnsubscribeModal, setOpenUnsubscribeModal] = useState(false)
    const [openPaymentModal, setOpenPaymentModal] = useState(false)
    const [openUnsubscribeInfo, setOpenUnsubscribeInfo] = useState(false)
    const { showMessage } = useContext(SnackbarContext);
    const [subscriptionEndDate, setSubscriptionEndDate] = useState(null)
    const dispatch = useDispatch();
    const { showLoader, setLoaderTitle } = useContext(LoaderContext);
    const [unsubscribe, setUnsubscribe] = useState(false);
    const [creditCardNumber, setCreditCardNumber] = useState('');
    const [openCreditCardModal,setOpenCreditCardModal] =useState(false);
    const [validationTime,setValidationTime] =useState('');
    const [openConfirmationForCardChange, setOpenConfirmationForCardChange] = useState(false);
    const [creditCardNotExist, setCreditCardNotExist] = useState(false);
    const [creditCardValidData, setCreditcardValidData] = useState('');

    useEffect(() => {
        setPaymentFilter([
            paymentFilterTitle,
            ...getPaymentFilter()
        ])
        fetch();
    }, [])

    const fetch = () => {
        getSubscriptionData().then(response => {
            setProduct(response.data.product)
            setInvoices(response.data.invoices ? response.data.invoices : [])
            setSubscription(response.data.subscriptions && response.data.subscriptions.length > 0 ? response.data.subscriptions[0] : null)
            setSubscriptionEndDate(formatDateWithTime(new Date(response.data.subscriptions[0].currentPeriodEnd)))
            setUnsubscribe(response.data.subscriptions[0].cancelAtPeriodEnd)
        })
    }

    const creditCardDetails =async () =>{
        try {
            const res = await getCreditcardDetails();
            if (res.status === 200) {
                const userCreditCardDetails = 'xxxx-xxxx-xxxx-' + res?.data?.last4;
                const validTime = res?.data?.expMonth+'/'+res?.data?.expYear;
                setCreditCardNumber(userCreditCardDetails)
                setValidationTime(validTime);
                setOpenCreditCardModal(true);
            } else if ('error' in res){
                setOpenPaymentModal(true);
                setCreditCardNotExist(true);
            }
        } catch (error) {
            console.error(error);
        }
    }


    const renderInvoicesHeader = () => {
        return <div className='invoice header'>
            <div className='invoice-item'>
                Invoice number
            </div>
            <div className='invoice-item'>
                Amount
            </div>
            <div className='invoice-item'>
                Transaction date
            </div>
            <div className='invoice-item'>
                Status
            </div>
            <div className='invoice-item' >

                Invoice
            </div>
        </div>
    }

    const renderInvoices = () => {
        let result = []

        for (let invoice of invoices) {
            result.push(<div className='invoice'>
                <div className='invoice-item'>
                    {invoice.number}
                </div>
                <div className='invoice-item'>
                    {invoice.total / 100} £
                </div>
                <div className='invoice-item'>
                    {formatDateWithTime(new Date(invoice.created))}
                </div>
                <div className='invoice-item'>
                    {invoice.status}
                </div>
                <div className='invoice-item'>
                    <CreateInvoicePdf invoice={invoice} />
                    
                </div>
            </div>)
        }

        return result;
    }

    useEffect(() => {
        setPaymentFilter(getPaymentFilter())
    }, [])

    const handleOpenPaymentModal = async () => {
       await creditCardDetails();
    }

    const handleClosePaymentModal = () => {
        setOpenPaymentModal(false)
        setOpenCreditCardModal(false)
        setOpenConfirmationForCardChange(false)
    }
    const handleOpenAddCreditCardModal =() => {
        setOpenCreditCardModal(false)
        setOpenPaymentModal(true)
    }

    const handleOpenConfirmModalForCreditCardChange =(data)=>{
        setOpenConfirmationForCardChange(true)
        setCreditcardValidData(data)
    }

    const handlePaymentConfirm = (paymentData) => {
            const expMonth = paymentData.creditCard.expiry.split('/')[0].trim()
            const expYear = paymentData.creditCard.expiry.split('/')[1].trim()
            updatePaymentMethod({
                card: paymentData.creditCard.number,
                expMonth: expMonth,
                expYear: expYear,
                csv: paymentData.creditCard.cvc
            }).then(response => {
                if (!response || !response.ok) {
                    showMessage('Error updating payment method', 'error');
                    return
                }
                showMessage('Payment method updated');
                setOpenPaymentModal(false)
            })
        }

    const handleOpenUnsubscribeModal = () => {
        setOpenUnsubscribeModal(true)
    }

    const handleCloseUnsubscribeModal = () => {
        setOpenUnsubscribeModal(false)
    }

    const handleUnsubscribeConfirm = () => {

        setOpenUnsubscribeModal(false)

        showLoader(true);

        cancelSubscription(subscription.id).then(response => {
            if (!response || !response.ok) {
                return;
            }

            getCurrentUser().then(response => {
                showLoader(false);
                setOpenUnsubscribeInfo(true)
                dispatch(setAuth(response.data));
            });

            fetch();
        })
    }

    const closeUnsubscribeInfo = () => {
        setOpenUnsubscribeInfo(false)
    }

    const confirmUnsubscribeInfo = () => {
        navigate('/pricing-and-package')
    }

    const packageEnds = (user) => {

        if (!isPackageValid(user)) {
            return 'expired'
        }

        return <React.Fragment> - Renewal date on {formatDateInText(new Date(user.company.packageValidUntil))}</React.Fragment>
    }

    const handleCloseCreditCardChangeModal = () =>{
        setOpenConfirmationForCardChange(false)
    }
    const handleCreditCardChangeConfirm = () =>{
        setOpenConfirmationForCardChange(false);
        setOpenPaymentModal(false)
        handlePaymentConfirm(creditCardValidData)
    }

    return (
        <FormProvider {...form}>
            <div className="tab-page-content">
                <div id="dashboard" className="dashboard subscription-details">

                    <div className="filters-container">
                        <div className='filter-item'>
                            <SelectControl
                                setValue={setValue}
                                name='payment'
                                options={paymentFilter}
                                nameKey='name'
                                valueKey='id'
                                defaultValue={getPaymentFilterDefault()}
                                className="select-control-container"
                            />
                        </div>
                        <div className="buttons">
                            <Button className="default-btn btn" onClick={handleOpenPaymentModal}>
                                {strings.pages.dashboard.subscriptionDetails.editPaymentMethod}</Button>
                            {
                                (subscription && !unsubscribe) &&
                                <Button className="neutral-btn btn" onClick={handleOpenUnsubscribeModal}>
                                    {strings.pages.dashboard.subscriptionDetails.unsubscribe}</Button>
                            }
                        </div>
                    </div>

                    <div className="invoices-container">
                        {
                            user && user.company && user.company.package &&
                            <h4 style={{ marginLeft: '10px' }}>Package: {user.company.package.name} { user.company.isTrial ? '(Trial)' : '' } {packageEnds(user)}</h4>
                        }

                        {
                            invoices &&
                            <React.Fragment>
                                {renderInvoicesHeader()}
                                <div className='invoices'>
                                    {renderInvoices()}
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <Modal
                        open={openPaymentModal}
                        onClose={handleClosePaymentModal}
                        className='confirm-modal-container'
                    >
                        <PaymentMethodModal
                            title={strings.modals.subscriptionDetails.unsubscribe.confirmText}
                            cancelBtn={strings.modals.subscriptionDetails.unsubscribe.cancel}
                            confirmBtn={strings.modals.subscriptionDetails.unsubscribe.add}
                            handleCloseModal={handleClosePaymentModal}
                            handleConfirm={!creditCardNotExist ? handleOpenConfirmModalForCreditCardChange :handlePaymentConfirm}

                        />
                        
                    </Modal>
                    <Modal
                        open={openCreditCardModal}
                        onClose={handleClosePaymentModal}
                        className='confirm-modal-container'
                    >
                        <PaymentMethodModal
                            title={strings.modals.subscriptionDetails.unsubscribe.confirmText}
                            cancelBtn={strings.modals.subscriptionDetails.unsubscribe.cancel}
                            confirmBtn={strings.modals.subscriptionDetails.unsubscribe.update}
                            handleCloseModal={handleClosePaymentModal}
                            handleConfirm={handleOpenAddCreditCardModal}
                            creditCardNumber={creditCardNumber}
                            validationTime={validationTime}
                        />
                    </Modal>
                    <Modal
                        open={openConfirmationForCardChange}
                        onClose={handleCloseCreditCardChangeModal}
                        className='confirm-modal-container'
                    >
                        <ConfirmModal
                        title='Alert!'
                        contentText={strings.modals.subscriptionDetails.editPaymentMethod.creditCardChange}
                        cancelBtn={strings.modals.subscriptionDetails.unsubscribe.cancel}
                        confirmBtn={strings.modals.subscriptionDetails.unsubscribe.yes}
                        handleCloseModal={handleCloseCreditCardChangeModal}
                        handleConfirm={handleCreditCardChangeConfirm}
                        />

                    </Modal>

                    <Modal
                        open={openUnsubscribeModal}
                        onClose={handleCloseUnsubscribeModal}
                        className='confirm-modal-container'
                    >
                        <SimpleConfirmModal
                            title={strings.modals.subscriptionDetails.unsubscribe.confirmText}
                            cancelBtn={strings.modals.subscriptionDetails.unsubscribe.cancel}
                            confirmBtn={strings.modals.subscriptionDetails.unsubscribe.yes}
                            handleCloseModal={handleCloseUnsubscribeModal}
                            handleConfirm={handleUnsubscribeConfirm}
                        />
                    </Modal>

                    <Modal
                        open={openUnsubscribeInfo}
                        onClose={closeUnsubscribeInfo}
                        className='confirm-modal-container'
                    >
                        <UnsubscribeInfo
                            title={strings.modals.subscriptionDetails.unsubscribeInfo.infoText}
                            value={subscriptionEndDate ? subscriptionEndDate : null}
                            confirmBtn={strings.modals.subscriptionDetails.unsubscribeInfo.infoBtn}
                            onClose={closeUnsubscribeInfo}
                            handleConfirmModal={confirmUnsubscribeInfo}
                            style='unsubscribe-info'
                            btnStyle='default-btn btn'
                        />
                    </Modal>
                </div>
            </div>
        </FormProvider>

    )
}

export default SubscriptionDetails;