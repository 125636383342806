import {useEffect, useState, useContext} from "react";
import React from "react";
import strings from "../../../localization";
import SnackbarContext from "../../../Context/SnackbarContext";
import TextFieldControl from "../../../Components/Controls/Inputs/TextFieldControl";
import IconButton from '@mui/material/IconButton';
import TextareaControl from "../../../Components/Controls/Inputs/TextareaControl";
import {FormProvider, useForm} from "react-hook-form";
import {
    addTemplateQuestionOption,
    editTemplateQuestionOption, makeDefaultTemplateQuestionOption
} from "../../../Services/Template/TemplateQuestionOptionService";
import QuestionFormat from "../../../Constants/Admin/QuestionFormat";
import {
    addTemplateQuestionOptionClause,
    getAllTemplateQuestionOptionClauses
} from "../../../Services/Template/TemplateQuestionOptionClauseService";
import {OptionClauses} from "./OptionClauses";
import Tooltip from "@mui/material/Tooltip";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import { Editor } from 'ckeditor5-custom-build/build/ckeditor';
import { deleteTemplateQuestionOption } from "../../../Services/Template/TemplateQuestionOptionService";

const toolbarConfig = {
    items: [
        'heading', '|',
        'fontfamily', 'fontsize', '|',
        'alignment', '|',
        'fontColor', 'fontBackgroundColor', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
        'link', '|',
        'outdent', 'indent', '|',
        'bulletedList', 'numberedList', 'todoList', '|',
        'code', 'codeBlock', '|',
        'insertTable', '|',
        'uploadImage', 'blockQuote', '|',
        'undo', 'redo'
    ],
    link: { addTargetToExternalLinks: true },
    shouldNotGroupWhenFull: true,
    removePlugins: ['RealTimeCollaborativeEditing', 'PresenceList', 'RealTimeCollaborativeComments', 'RealTimeCollaborativeTrackChanges']
}

const QuestionOption = (props) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const {showMessage} = useContext(SnackbarContext);
    const [questionOption, setQuestionOption] = useState(props.questionOption);
    const [showExplanation, setShowExplanation] = useState(false);
    const [showClauseContent, setShowClauseContent] = useState(false);
    const [clauses, setClauses] = useState([]);
    const [editor, setEditor] = useState(null);
    const [explanationEditor, setExplanationEditor] = useState(null);

    useEffect(() => {

        setQuestionOption(props.questionOption);

        setValue('stringValue', props.questionOption.stringValue);
        setValue('intValue', props.questionOption.intValue);
        setValue('description', props.questionOption.description);
        setValue('clause', props.questionOption.clause);
        setValue('code', getNextMergeKey(props.questionOption))
        fetch();
    }, [props.questionOption]);

    useEffect(() => {
        setValue('code', getNextMergeKey(questionOption));

    }, [clauses]);

    useEffect(() => {
        fetch();
    }, []);


    const fetch = () => {
        getAllTemplateQuestionOptionClauses(questionOption.id).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setClauses(response.data);
        })
    }

    const getNextMergeKey = (questionOption) => {

        if (!questionOption) {
            return '';
        }

        let mergeKey = ('qo-' + questionOption.id + '-c-') + (clauses.length + 1);

        try {
            let maxCode = 0
            clauses.map(clause => {
                    const codeParts = clause.code.split('-');
                    const codeNumber = parseInt(codeParts[3]);
                
                    if (!isNaN(codeNumber) && codeNumber > maxCode) {
                    maxCode = codeNumber;
                    }
                });
                mergeKey = ('qo-' + questionOption.id + '-c-') + (maxCode + 1);

        } catch (err) {
            console.log(err);
        }

        return mergeKey;
    }

    const save = (data) => {

        if (questionOption.id) {
            editTemplateQuestionOption({
                id: questionOption.id,
                templateQuestionId: props.question.id,
                ...data,
                description: explanationEditor ? explanationEditor.getData() : ''
            }).then(response => {

                if (!response || !response.ok) {
                    return;
                }

                setQuestionOption(response.data);
            })
            showMessage(strings.common.edited)
        } else {
            addTemplateQuestionOption({
                templateQuestionId: props.question.id,
                ...data,
                description: explanationEditor ? explanationEditor.getData() : ''
            }).then(response => {

                if (!response || !response.ok) {
                    return;
                }

                setQuestionOption(response.data);
                setValue('code', getNextMergeKey(response.data))
            })
        }

        if (data.code && editor && editor.getData()) {

            let content = props.editor.getData()
            let selection = props.editor.model.document.selection
            const range = selection.getFirstRange();
            let selectedText = '';
            for (const item of range.getItems()) {

                if (!item.data) {
                    continue;
                }

                selectedText = item.data
            }

            if (selectedText !== '') {
                content = content.replaceAll(selectedText, '[' + data.code + ']')
                props.editor.setData(content);
            }

            addTemplateQuestionOptionClause({
                code: data.code,
                content: editor.getData(),
                templateQuestionOptionId: questionOption.id
            }).then(response => {
                editor.setData('');

                setValue('clause', '');
                fetch();
            })
            showMessage(strings.common.added)
        }
    }

    const makeDefault = (id) => {
        makeDefaultTemplateQuestionOption(id).then(response => {
            if (!response || !response.ok) {
                return;
            }

            props.fetchOptions(props.question.id)
        })
    }

    const placeOptionMergeKey = (questionOption) => {

        const code = props.question.code + '-' + questionOption.stringValue.replaceAll(' ', '-');

        let selection = props.editor.model.document.selection
        const range = selection.getFirstRange();
        let selectedText = '';

        for (const item of range.getItems()) {

            if(!item.data) {
                continue;
            }

            selectedText = item.data
        }

        if(selectedText !== '') {
            props.editor.model.change( writer => {
                props.editor.model.insertContent( writer.createText('[' + code + ']'), range )
            } );
        }
        else {
            props.editor.model.change( writer => {
                writer.insertText( `[${code}]`, props.editor.model.document.selection.getFirstPosition() );
            } );
        }
    }

    const handleDeleteQuestion = (id) => {
        deleteTemplateQuestionOption(id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            props.fetchOptions(props.question.id)
        });

    }

    return <React.Fragment>
        <FormProvider {...form}>
            <span className="title">{questionOption.stringValue}{!questionOption.isDefault && props.question.questionType !== QuestionFormat.COMPANY_HOUSE &&
                <span className='make-default' onClick={() => {
                    makeDefault(questionOption.id)
                }
                }>(Make default)</span>}</span>
            <div className="row-items main-input-container">
                {
                    props.question.questionType !== QuestionFormat.SLIDER &&
                    <TextFieldControl
                        name='stringValue'
                        defaultValue=''
                        disabled={props.question.questionType === QuestionFormat.COMPANY_HOUSE ||
                            props.question.questionType === QuestionFormat.SWITCH ||
                            props.question.questionType === QuestionFormat.CHECKBOX}
                        rules={{required: true}}
                        fullWidth
                        margin="normal"
                        placeholder={strings.forms.admin.adminTemplateForm.questionFormatForm.nameOfAnswer}
                    />
                }
                {
                    props.question.questionType === QuestionFormat.SLIDER &&
                    <TextFieldControl
                        name='intValue'
                        defaultValue=''
                        rules={{required: true}}
                        fullWidth
                        margin="normal"
                        placeholder={strings.forms.admin.adminTemplateForm.questionFormatForm.nameOfAnswer}
                    />
                }

                {
                    props.question.questionType === QuestionFormat.COMPANY_HOUSE &&
                    <Tooltip
                        title={strings.forms.admin.adminTemplateForm.tooltips.placeMergeKey}
                        placement="top"
                        arrow>
                        <IconButton className="item-icon" onClick={() => placeOptionMergeKey(questionOption)}>
                            <img src="/images/icons/merge.png" />
                        </IconButton>
                    </Tooltip>
                }
                {
                    props.question.questionType !== QuestionFormat.COMPANY_HOUSE &&
                    <Tooltip
                        title={'Save'}
                        placement="top"
                        arrow>
                        <IconButton className="item-icon" onClick={handleSubmit(save)}>
                            <img src="/images/icons/save-blue.png"/>
                        </IconButton>
                    </Tooltip>
                }

                <Tooltip
                    title={strings.forms.admin.adminTemplateForm.tooltips.addOptionExplanation}
                    placement="top"
                    arrow>
                    <IconButton className="item-icon" onClick={() => setShowExplanation(!showExplanation)}>
                        <img src="/images/icons/message-add.png"/>
                    </IconButton>
                </Tooltip>

                {
                    props.question.questionType !== QuestionFormat.COMPANY_HOUSE &&
                    <Tooltip
                        title={strings.forms.admin.adminTemplateForm.tooltips.selectMergeCLauseType}
                        placement="top"
                        arrow>
                        <IconButton className="item-icon" onClick={() => setShowClauseContent(!showClauseContent)}>
                            <img src="/images/icons/copy.png"/>
                        </IconButton>
                    </Tooltip>

                }

                { (props.question.questionType === QuestionFormat.SELECT || props.question.questionType === QuestionFormat.RADIO_BUTTON || props.question.questionType === QuestionFormat.CHECKBOX_GROUP) && questionOption.id &&
                    <Tooltip
                        title={strings.forms.admin.adminTemplateForm.tooltips.delete}
                        placement="top"
                        arrow>
                        <IconButton className="item-icon" onClick={() => handleDeleteQuestion(questionOption.id)}>
                            <img src="/images/icons/delete.png" />
                        </IconButton>
                    </Tooltip>
                }
                
            </div>
            {
                showExplanation &&
                <React.Fragment>
                    <div style={{background: 'white', padding: '10px', marginTop: '15px', minHeight: '200px'}}>
                        {
                            <CKEditor
                                onReady={editor => {
                                    editor.ui.getEditableElement()?.parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement()
                                    );
                                    setExplanationEditor(editor)
                                }}
                                // onError={({willEditorRestart}) => {
                                //     if (willEditorRestart) {
                                //         editor.ui.view.toolbar.element.remove();
                                //     }
                                // }}
                                data={getValues('description')}
                                editor={Editor}
                                config={toolbarConfig}
                            />
                        }

                    </div>
                </React.Fragment>
            }

            {
                showClauseContent &&
                <React.Fragment>
                    <TextFieldControl
                        name='code'
                        defaultValue=''
                        rules={{required: true}}
                        fullWidth
                        margin="normal"
                        placeholder={strings.forms.admin.adminTemplateForm.questionFormatForm.mergeKey}
                    />

                    <div style={{background: 'white', padding: '10px', marginTop: '15px', minHeight: '200px'}}>
                    {
                            <CKEditor
                                onReady={editor => {
                                    editor.ui.getEditableElement()?.parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement()
                                    );
                                    setEditor(editor)
                                }}
                                // onError={({willEditorRestart}) => {
                                //     if (willEditorRestart) {
                                //         editor.ui.view.toolbar.element.remove();
                                //     }
                                // }}
                                editor={Editor}
                                config={toolbarConfig}
                            />
                        }
                    </div>


                </React.Fragment>

            }
            {/*<div className="row-items">*/}
            {/*    <Button className="default-btn btn" variant="contained" onClick={handleSubmit(save)}>*/}
            {/*        <span className="btn-txt">{strings.forms.admin.adminTemplateForm.questionFormatForm.save}</span>*/}
            {/*    </Button>*/}
            {/*</div>*/}

            <OptionClauses mainEditor={props.editor} clauses={clauses} fetch={fetch}/>
            <div className="border"/>
        </FormProvider>
    </React.Fragment>
}

export default QuestionOption;