import React, { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import strings from "../../../../localization";
import FileUploadComponent from "../../../Controls/FileUploadComponent";
import SwitchControl from "../../../Controls/Inputs/SwitchControl";
import styles from "../../../../Pages/Admin/AdminAIData/AIData.module.css"
import styles1 from "../../../../Pages/dealRoom/DealRoom.module.css"
import { dueDeligenceUploadFilesToQuestion } from "../../../../Services/DealRoom/DealRoomService";
import {useParams} from "react-router-dom";

const UploadView = ({handleCloseModal,questionRow,showMessage,getSectionListHandler}) => {
  const params = useParams()

    
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [fileUpload, setFileUpload] = useState(true);
  const [files, setFiles] = useState([]);

  const selectFilesHandler = async (files) => {
    console.log("files", files)
    setDisabledSubmit(false);
    setFiles(files);
  };

  const uploadfilesHandler = () => {
    let projectId = params.id

    const formData = new FormData();

    files.forEach((file,index) => {
      console.log(file,index)
      formData.append(`files`, file);
    });

    dueDeligenceUploadFilesToQuestion(projectId,questionRow?.id,formData)
    .then(res =>{
      console.log("upload res",res)
      getSectionListHandler()
      showMessage("Document uploaded Successfully!")
      handleCloseModal()
    })
  }

  const removeFileHandler = (index) => {
    let tempFiles = structuredClone(files)
    tempFiles.splice(index, 1)
    setFiles(tempFiles)
  };

 

  const getExtension = (filename) => {
    let parts = filename.split(".");
    return parts[parts.length - 1];
  };


  console.log("fileUpload :", fileUpload);

  return (
    <>
    <Box className="file-upload-container">
        <FileUploadComponent
          accept=".pdf"
          hideUploadButton={true}
          onDrop={selectFilesHandler}
        />
      </Box>

      {files?.map((file, index) => {
        return (<Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <div className={styles1.uploadFileRow}>
            <div
              className={styles.inputContainer}
              style={{ width: "50%", marginLeft: "30px" }}
            >
              <input
                className={styles.inputBox}
                value={file?.name}
                name="question"
                type="text"
                placeholder="Add the Question"
              />
            </div>
            <div
            >
              <CloseIcon onClick={() => removeFileHandler(index)} />
            </div>
          </div>
         
        </Box>)
      })

      }
      <Box>
        <Box
          className="buttons-container"
          display={"flex"}
          justifyContent={"end"}
          marginTop={"20px"}
        >
          <Button
            className="neutral-btn btn cancel-btn"
            variant="contained"
            onClick={handleCloseModal}
            sx={{ marginRight: "0.5rem" }}
          >
            {strings.forms.contractList.cancel}
          </Button>
          <Button
            className="default-btn btn"
            variant="contained"
            disabled={disabledSubmit}
            onClick={uploadfilesHandler}
          >
            {strings.forms.contractList.upload}
          </Button>
        </Box>
      </Box>

    </>
  )
}

export default UploadView