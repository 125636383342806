import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import React, {useEffect, useState} from "react";
import {Collapse, Tooltip} from "@material-ui/core";
import {FormProvider, useForm} from "react-hook-form";
import QuestionFormat from "../../../Constants/Admin/QuestionFormat";
import MenuItem from "@mui/material/MenuItem";
import {Select} from "@mui/material";
import {
    getQuestionRelationTypes,
    getQuestionRelationTypesByQuestionFormat, getQuestionRelationTypeString
} from "../../../Constants/Admin/QuestionRelationType";
import {getAllTemplateQuestionOptions} from "../../../Services/Template/TemplateQuestionOptionService";
import TextField from "@mui/material/TextField";
import {deleteQuestionRelation, editQuestionRelation} from "../../../Services/Template/QuestionRelationService";
import IconButton from "@mui/material/IconButton";
import strings from "../../../localization";
import DatePickerControl from "../../../Components/Controls/Inputs/DatePickerControl";
import TimePickerControl from "../../../Components/Controls/Inputs/TimePickerControl";

export const QuestionRelation = ({relation, fetch, order, questions}) => {

    const [collapsed, setCollapsed] = useState(true)
    const [inputData, setInputData] = useState(relation);
    const [options, setOptions] = useState([]);
    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;

    useEffect(() => {

    }, [])

    useEffect(() => {

        editQuestionRelation({
            ...inputData,
            questionId: inputData.question.id,
            toQuestionId: inputData.toQuestion ? inputData.toQuestion.id : -1,
            type: inputData.type ? inputData.type : -1,
            stringValue: inputData.stringValue ? inputData.stringValue : '',
            numberValue: inputData.numberValue ? inputData.numberValue : 0,
            boolValue: false,
            templateQuestionOptionId: inputData.templateQuestionOption ? inputData.templateQuestionOption.id : -1
        }).then(response => {

        })

        if (inputData.toQuestion) {
            fetchOptions()
        }

    }, [inputData])

    const fetchOptions = () => {
        getAllTemplateQuestionOptions(inputData.toQuestion.id).then(response => {
            setOptions(response.data)
        })
    }

    const deleteRelation = (id) => {
        deleteQuestionRelation(id).then(response => {
            fetch();
        })
    }

    const getRelationTooltip = (relation) => {

        return <React.Fragment>
            <p>Question: { relation.question ? relation.question.name : '' }</p>
            <p>Relation: { relation.type ? getQuestionRelationTypeString(relation.type) : ''}</p>
            <p>To question: { relation.toQuestion ? relation.toQuestion.name : ''}</p>
        </React.Fragment>
    }

    return <div className={'relation'}>
        <div className={'relation-header'}>

            <div className='relation-header-title'>
                <Tooltip
                    title={getRelationTooltip(relation)}
                    placement="top"
                    arrow>
                    <h4>Relation {order}</h4>
                </Tooltip>
                <IconButton className="item-icon" onClick={() => deleteRelation(relation.id)}>
                    <img src="/images/icons/delete.png"/>
                </IconButton>
            </div>

            <React.Fragment>
                {collapsed
                    ?
                    <AddIcon className="toggle-icon" onClick={() => setCollapsed(!collapsed)}/>
                    :
                    <RemoveIcon className="toggle-icon" onClick={() => setCollapsed(!collapsed)}/>
                }
            </React.Fragment>
        </div>
        <Collapse in={!collapsed} timeout="auto" unmountOnExit>
            <FormProvider {...form}>
                {
                    questions &&
                    <Select
                        className="select-box"
                        value={inputData.toQuestion ? inputData.toQuestion.id : -1}
                        name='toQuestion'
                        onChange={(e) => {
                            setInputData({
                                ...inputData,
                                toQuestion: questions.find(x => x.id === e.target.value)
                            })
                        }}
                    >
                        {questions.filter(x => relation.question && x.id !== relation.question.id).map((question) => (
                            <MenuItem key={question.id} value={question.id}>
                                {question.name}
                            </MenuItem>
                        ))}
                    </Select>
                }
                <Select
                    className="select-box"
                    value={inputData.type ? inputData.type : -1}
                    name='type'
                    disabled={!inputData.toQuestion}
                    onChange={(e) => {
                        setInputData({
                            ...inputData,
                            type: e.target.value
                        })
                    }}
                >
                    {inputData.toQuestion && getQuestionRelationTypesByQuestionFormat(inputData.toQuestion.questionType).map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                            {type.name}
                        </MenuItem>
                    ))}
                </Select>

                {
                    inputData && inputData.toQuestion &&
                    (inputData.toQuestion.questionType === QuestionFormat.TEXT_FIELD ||
                        inputData.toQuestion.questionType === QuestionFormat.TEXTAREA) &&
                    <TextField
                        value={inputData.stringValue}
                        onChange={(e) => {
                            setInputData({
                                ...inputData,
                                stringValue: e.target.value
                            })
                        }}
                        disabled={!inputData.toQuestion}
                        InputLabelProps={{shrink: Boolean(inputData.stringValue)}}
                        fullWidth
                        margin={'normal'}
                        className={"textfield-control"}
                        placeholder={'String value'}
                    />
                }

                {
                    inputData && inputData.toQuestion &&
                    (inputData.toQuestion.questionType === QuestionFormat.NUMBER_INPUT) &&
                    <TextField
                        value={inputData.numberValue}
                        onChange={(e) => {
                            setInputData({
                                ...inputData,
                                numberValue: e.target.value
                            })
                        }}
                        disabled={!inputData.toQuestion}
                        InputLabelProps={{shrink: Boolean(inputData.stringValue)}}
                        fullWidth
                        margin={'normal'}
                        className={"textfield-control"}
                        placeholder={'String value'}
                    />
                }

                {
                    inputData && inputData.toQuestion &&
                    (inputData.toQuestion.questionType === QuestionFormat.DATE_PICKER) &&
                    <DatePickerControl
                        setValue={setValue}
                        value={new Date(inputData.stringValue)}
                        onChange={(newValue) => {
                            setInputData({
                                ...inputData,
                                stringValue: newValue
                            })
                        }}
                        disabled={!inputData.toQuestion}
                        InputLabelProps={{shrink: Boolean(inputData.stringValue)}}
                        fullWidth
                        margin={'normal'}
                        className={"textfield-control"}
                        placeholder={'String value'}
                    />
                }

                {
                    inputData && inputData.toQuestion &&
                    (inputData.toQuestion.questionType === QuestionFormat.TIME_PICKER) &&
                    <TimePickerControl
                        setValue={setValue}
                        value={inputData.stringValue ? new Date(inputData.stringValue) : new Date()}
                        onChange={(newValue) => {
                            setInputData({
                                 ...inputData,
                                stringValue: newValue
                            })
                        }}
                        disabled={!inputData.toQuestion}
                        InputLabelProps={{shrink: Boolean(inputData.stringValue)}}
                        fullWidth
                        margin={'normal'}
                        className={"textfield-control"}
                        placeholder={'String value'}
                    />
                }

                {
                    inputData && inputData.toQuestion &&
                    (inputData.toQuestion.questionType === QuestionFormat.SLIDER) &&
                    <TextField
                        value={inputData.numberValue}
                        disabled={!inputData.toQuestion}
                        onChange={(e) => {
                            setInputData({
                                ...inputData,
                                numberValue: parseFloat(e.target.value)
                            })
                        }}
                        InputLabelProps={{shrink: Boolean(inputData.stringValue)}}
                        fullWidth
                        margin={'normal'}
                        className={"textfield-control"}
                        placeholder={'Number value'}
                    />
                }
                {
                    inputData && inputData.toQuestion &&
                    (inputData.toQuestion.questionType === QuestionFormat.AUTOCOMPLETE ||
                        inputData.toQuestion.questionType === QuestionFormat.RADIO_BUTTON ||
                        inputData.toQuestion.questionType === QuestionFormat.SWITCH ||
                        inputData.toQuestion.questionType === QuestionFormat.TOGGLE ||
                        inputData.toQuestion.questionType === QuestionFormat.CHECKBOX ||
                        inputData.toQuestion.questionType === QuestionFormat.SELECT || 
                        inputData.toQuestion.questionType === QuestionFormat.CHECKBOX_GROUP) &&
                    <Select
                        className="select-box"
                        disabled={!inputData.toQuestion}
                        value={inputData.templateQuestionOption ? inputData.templateQuestionOption.id : -1}
                        name='toQuestion'
                        onChange={(e) => {
                            setInputData({
                                ...inputData,
                                templateQuestionOption: options.find(x => x.id === e.target.value)
                            })
                        }}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.stringValue}
                            </MenuItem>
                        ))}
                    </Select>
                }
            </FormProvider>
        </Collapse>
    </div>
}
