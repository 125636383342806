import strings from "../../localization";

const SortContract = {
    A_TO_Z: 1,
    Z_TO_A: 2, 
    LAST_UPLOADED: 3,
    FIRST_UPLOADED: 4,
    LAST_MODIFIED: 5,
    CREATED_DATE: 6
};

export function getSortContractFilter() {
    return [
        {
            id: SortContract.A_TO_Z,
            name: getSortContractString(SortContract.A_TO_Z)
        },
        {
            id: SortContract.Z_TO_A,
            name: getSortContractString(SortContract.Z_TO_A)
        },
        {
            id: SortContract.LAST_UPLOADED,
            name: getSortContractString(SortContract.LAST_UPLOADED)
        },
        {
            id: SortContract.FIRST_UPLOADED,
            name: getSortContractString(SortContract.FIRST_UPLOADED)
        },
        {
            id: SortContract.LAST_MODIFIED,
            name: getSortContractString(SortContract.LAST_MODIFIED)
        },
        {
            id: SortContract.CREATED_DATE,
            name: getSortContractString(SortContract.CREATED_DATE)
        }
    ]
}

export function getSortContractString(sortContract) {
    switch(sortContract) {
        case SortContract.A_TO_Z: return strings.constants.contract.sortContract.aToZ;
        case SortContract.Z_TO_A: return strings.constants.contract.sortContract.zToA;
        case SortContract.LAST_UPLOADED: return strings.constants.contract.sortContract.lastUploaded;
        case SortContract.FIRST_UPLOADED: return strings.constants.contract.sortContract.firstUploaded;
        case SortContract.LAST_MODIFIED: return strings.constants.contract.sortContract.lastModified;
        case SortContract.CREATED_DATE: return strings.constants.contract.sortContract.createdDate;
    }
}

export default SortContract;