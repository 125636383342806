import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldPasswordControl from "../../../Controls/Inputs/TextFieldPasswordControl";

const ResetPasswordForm = ({
                              onSubmit,
                              errors,
                              data,
                              form,
                              formRules
                          }) => (
    <FormProvider {...form}>
        <form id='reset-password-form' onSubmit={ onSubmit } action = "#">

        <TextFieldPasswordControl
                name='password'
                control={data}
                defaultValue=''
                rules={formRules['password']}
                fullWidth
                type='password'
                margin="normal"
                error={Boolean(errors.password)}
                helperText={errors.password && errors.password.message}
                placeholder={ strings.forms.resetPassword.password }
            />

            <TextFieldPasswordControl
                name='passwordRepeat'
                control={data}
                defaultValue=''
                rules={formRules['passwordRepeat']}
                fullWidth
                type='password'
                margin="normal"
                error={Boolean(errors.passwordRepeat)}
                helperText={errors.passwordRepeat && errors.passwordRepeat.message}
                placeholder={ strings.forms.resetPassword.passwordRepeat }
            />

            <div className='submit-container'>
                <Button className="reset-pass-btn btn" variant="contained" color="primary" onClick={ onSubmit }>
                    { strings.forms.resetPassword.resetPassword }
                </Button>
            </div>

        </form>
    </FormProvider>
);

export default ResetPasswordForm;
