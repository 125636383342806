import React, { useRef, useState } from "react";
import styles from "../../../../../Pages/dealRoom/DealRoom.module.css";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  administratorFormObj,
  dealRoomParty,
} from "../../../../../Constants/Admin/DealRoomModel";

const AdministratorsForm = ({ formData, setFormData, showMessage }) => {

  const inputRefs = useRef([]);

  const [isOpenChoiceBox, setisOpenChoiceBox] = useState({});

  const addRowHandler = () => {
    let tempData = structuredClone(formData);
    tempData.administrators.push(administratorFormObj);
    setFormData(tempData);
  };

  // const toggleRowEditable = (index) => {
  //     let tempData = structuredClone(formData)
  //     tempData.administrators.forEach((obj, id) => {
  //         if (id === index) {
  //             obj.disabled = !obj.disabled
  //         }
  //     })
  //     setFormData(tempData);
  // }

  const handleMouseLeave = (index) => {
     inputRefs?.current[index]?.blur();
    setisOpenChoiceBox({ ...isOpenChoiceBox, [index]: false })
    // setisOpenChoiceBox({ ...isOpenChoiceBox, sector: false })
  };

  const removeRowHandler = (index) => {
    if (formData?.administrators?.length === 1) {
      showMessage("Administrators can not be less than 1.", "warning");
      return;
    }
    let tempData = structuredClone(formData);
    tempData.administrators.splice(index, 1);
    setFormData(tempData);
  };

  const setFieldDataHandler = (e, index) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "party") {
      value = parseInt(value);
    }

    let tempData = structuredClone(formData);
    tempData.administrators[index][name] = value;

    setFormData(tempData);
  };

  const selectPartyHandler = (value, index) => {
    let tempData = structuredClone(formData);
    tempData.administrators[index]["party"] = value;
    setFormData(tempData);
  };

  return (
    <div className={styles.formContainer} style={{ marginTop: "16px" }}>
      <div
        className={styles.headingTextBox}
        style={{ margin: "0", width: "100%" }}
      >
        <span className={styles.subHeading}>Administrators</span>
      </div>
      {formData?.administrators?.map((obj, index) => {
        return (
          <div key={index} className={styles.administratorsFormBox}>
            <div className={styles.formRow}>
              <div className={styles.filledBox} style={{ width: "150px" }}>
                <div className={styles.labelText}>
                  <span className="required">Name</span>
                </div>
                <div className={styles.inputContainer}>
                  <input
                    className={styles.inputBox}
                    value={obj?.name}
                    name="name"
                    onChange={(e) => setFieldDataHandler(e, index)}
                    type="text"
                    placeholder="Name"
                    disabled={obj.disabled}
                  />
                </div>
              </div>
              <div
                className={styles.filledBox}
                style={{ width: "300px", margin: "0 20px" }}
              >
                <div className={styles.labelText}>
                  <span className="required">Email</span>
                </div>
                <div className={styles.inputContainer}>
                  <input
                    className={styles.inputBox}
                    value={obj?.email}
                    name="email"
                    onChange={(e) => setFieldDataHandler(e, index)}
                    type="email"
                    placeholder="Email"
                    disabled={obj.disabled}
                  />
                </div>
              </div>
              <div className={styles.filledBox} style={{ width: "150px" }} onMouseLeave={() => handleMouseLeave(index)}>
                <div className={styles.labelText}>
                  <span className="required">Party</span>
                </div>
                <div className={styles.inputContainer}
                  style={{ position: "relative" }}
                >
                  <input
                    readOnly
                    value={obj?.party === 0 ? "Buyer" : "Seller"}
                    name="party"
                    onChange={(e) => setFieldDataHandler(e, index)}
                    className={styles.inputBox}
                    ref={(el) => (inputRefs.current[index] = el)}
                    onFocus={() => setisOpenChoiceBox({ ...isOpenChoiceBox, [index]: true })}
                    placeholder="Please Select Party"
                    style={{
                      WebkitAppearance: "none",
                      background: `url(/images/icons/downArrow.png)`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right center",
                    }}
                    disabled={obj.disabled}
                  />
                  {isOpenChoiceBox?.[index] && (
                    <div className={styles.options}>
                      <ul onMouseLeave={() => handleMouseLeave(index)}>
                        <li onClick={() => selectPartyHandler(dealRoomParty[0].value, index)}>
                          {dealRoomParty[0].name}
                        </li>
                        <li onClick={() => selectPartyHandler(dealRoomParty[1].value, index)}>
                          {dealRoomParty[1].name}
                        </li>
                      </ul>
                    </div>
                  )}

                </div>
              </div>
              <div
                className={styles.filledBox}
                style={{ width: "150px", alignSelf: "flex-end" }}
                // onClick={() => toggleRowEditable(index)}
                onClick={() => removeRowHandler(index)}
              >
                {/* {obj.disabled
                                    ? < div className={styles.addButton} >
                                        <span>Add</span>
                                    </div>
                                    :  */}
                <div className={styles.closeButton}>
                  <CloseIcon sx={{ color: "#FFF" }} />
                </div>
                {/* } */}
              </div>
            </div>
          </div>
        );
      })}

      <div
        className={styles.headerSecondCol}
        style={{ width: "500px", marginTop: "20px" }}
      >
        <div className={styles.createAdminBox} onClick={addRowHandler}>
          <AddIcon sx={{ color: "#3D32CF" }} />
          <span style={{ color: "#3D32CF", fontWeight: "500" }}>Add Admin</span>
        </div>
      </div>
    </div>
  );
};

export default AdministratorsForm;
