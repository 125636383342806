import React, { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import strings from "../../../localization";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  getAllTemplates,
  getAllTemplatesByPackage,
} from "../../../Services/Template/TemplateService";
import { getAllTemplateContractCategoriesByContractCategory } from "../../../Services/Template/TemplateContractCategoryService";
import Tooltip from "@mui/material/Tooltip";
import { hasAdminRole } from "../../../Util/PermissionUtil";
import { useSelector } from "react-redux";
import ConfirmModal from "../../../Components/Modals/ConfirmModal";
import Modal from "@mui/material/Modal";
import DocumentForm from "../../../Components/Forms/Pages/ContractList/DocumentForm";

const List = ({ itemClass, category, search }) => {
  const user = useSelector((state) => state.auth.user);
  const [templates, setTemplates] = useState([]);
  const [allowedTemplates, setAllowedTemplates] = useState([]);
  const [drop, setDrop] = useState({ id: 0, toggle: false });
  const [openNotAllowedModal, setOpenNotAllowedModal] = useState(false);
  const [packageDisabledModal, setPackageDisabledModal] = useState(false);
  const navigate = useNavigate();
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({ name: "Home", id: 0 });
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    fetch();
  }, [category]);

  const fetch = () => {
    getAllTemplatesByPackage().then((response) => {
      if (!response || !response.ok) {
        return;
      }

      setAllowedTemplates(response.data);
    });

    if (!category) {
      setTemplates([]);
      return;
    }

    getAllTemplateContractCategoriesByContractCategory(category.id).then(
      (response) => {
        if (!response || !response.ok) {
          return;
        }

        if (category.id === 13 || category.name === "e-Signature") {
          handleOpenDocumentModal();
        }

        setTemplates(response.data);
      }
    );
  };
  const handleOpenDocumentModal = () => {
    setOpenDocumentModal(true);
  };

  const handleCloseDocumentModal = () => {
    setOpenDocumentModal(false);
    setSelectedFolder({ name: 'Home', id: 0 });
    setFolders([]);
  };

  const handleConfirmDocumentModal = async (data) => {
    setOpenDocumentModal(false);
    setSelectedFolder({ name: 'Home', id: 0 });
    setFolders([]);
    navigate('/contract-list')
  };

  
  const handleDrop = (id) => {
    if (id !== drop.id) {
      setDrop({
        id: id,
        toggle: true,
      });
    } else {
      setDrop({
        id: id,
        toggle: !drop.toggle,
      });
    }
  };

  const canUseTemplate = (template) => {
    if (hasAdminRole(user)) {
      return true;
    }

    return allowedTemplates.find((x) => x.id === template.id);
  };

  const handleCreate = (template) => {
    if (!canUseTemplate(template)) {
      setOpenNotAllowedModal(true);
      return;
    }

    if (!user.company?.package?.active && !hasAdminRole(user)) {
      setPackageDisabledModal(true);
      return;
    }

    navigate("/add-contract", { state: { templateId: template.id } });
  };

  const renderContracts = () => {
    let result = [];
    let orderedTemplates = templates.sort((a, b) =>
      a.rowOrder > b.rowOrder ? 1 : -1
    );
    let renderTemplates = orderedTemplates.map((a) => a.template);

    if (search) {
      renderTemplates = allowedTemplates.filter((x) =>
        x.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    for (let item of renderTemplates) {
      result.push(
        <div
          key={"contract-" + result.length}
          className={"item"}
          style={{
            borderLeft: "5px solid " + (category ? category.color : "#FFF"),
          }}
          onClick={() => handleDrop(item.id)}
        >
          <div className="item-header">
            <h1 className="title">{item.name}</h1>

            {drop.toggle && item.id === drop.id ? (
              <Tooltip
                title={strings.commonTooltips.collapse}
                placement="top"
                arrow
              >
                <RemoveIcon className="toggle-icon" />
              </Tooltip>
            ) : (
              <Tooltip
                title={strings.commonTooltips.expand}
                placement="top"
                arrow
              >
                <AddIcon className="toggle-icon" />
              </Tooltip>
            )}
          </div>

          <Collapse
            in={drop.id === item.id && drop.toggle}
            timeout="auto"
            unmountOnExit
          >
            <div className="item-content">
              <p dangerouslySetInnerHTML={{ __html: item.description }} />
              <Button
                onClick={() => handleCreate(item)}
                className="default-btn btn"
              >
                {strings.pages.contractDashboard.create}
              </Button>
            </div>
          </Collapse>
        </div>
      );
    }
    return result;
  };

  return (
    <div className="contract-list">
      {renderContracts()}

      <Modal
        open={openNotAllowedModal}
        onClose={() => setOpenNotAllowedModal(false)}
        className="confirm-modal-container"
      >
        <ConfirmModal
          contentText={strings.modals.contractNotAllowed.confirmText}
          cancelBtn={strings.modals.contractNotAllowed.no}
          confirmBtn={strings.modals.contractNotAllowed.yes}
          handleCloseModal={() => setOpenNotAllowedModal(false)}
          handleConfirm={() => {
            setOpenNotAllowedModal(false);
            navigate("/pricing-and-package");
          }}
        />
      </Modal>

      <Modal
        open={packageDisabledModal}
        onClose={() => setPackageDisabledModal(false)}
        className="confirm-modal-container"
      >
        <ConfirmModal
          contentText={strings.modals.packageDisabled.contentText}
          cancelBtn={strings.modals.packageDisabled.no}
          confirmBtn={strings.modals.packageDisabled.yes}
          handleCloseModal={() => setPackageDisabledModal(false)}
          handleConfirm={() => {
            setPackageDisabledModal(false);
            navigate("/pricing-and-package");
          }}
        />
      </Modal>
      {/* <Modal
        open={openDocumentModal}
        onClose={handleCloseDocumentModal}
        className="confirm-modal-container"
      >
        <DocumentForm
          selectedFolder={selectedFolder}
          folders={folders}
          handleCloseModal={handleCloseDocumentModal}
          handleConfirm={handleConfirmDocumentModal}
          mergedDocument={true}
          buttonText ={strings.forms.contractList.sign}
        />
        
      </Modal> */}
    </div>
  );
};

export default List;
