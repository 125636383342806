import React, {useState, useEffect} from "react";
import {useForm, FormProvider} from "react-hook-form";
import {Button} from "@mui/material";
import strings from "../../../../../localization";
import FileUploadComponent from '../../../../Controls/FileUploadComponent';
import CloseIcon from '@mui/icons-material/Close';
import { handleCovertFromWordToHtml } from "../../../../../Util/CKEditorUtil";

const TemplateDocumentForm = (props) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [fileName, setFileName] = useState('')
    const [file, setFile] = useState(null);

    const uploadFile = async (files) => {
        setDisabledSubmit(false)
        setFile(files[0]);
    }

    const handleRemoveFile = () => {
        setFileName('')
        setFile(null)
    }

    const getExtension = (filename) => {
        let parts = filename.split('.');
        return parts[parts.length - 1];
    }

    const onSubmit = async (data) => {

        const extension = getExtension(file.path);

        if (!file || extension === 'pdf') {
            return;
        }

        let result = await handleCovertFromWordToHtml(file);
        props.handleConfirm(result.html)

        // let postData = new FormData();
        // postData.append('file', file)

        // let api = 'api/converters/docToHtml?format=json';
        // if (extension === 'pdf') {
        //     api = 'api/converters/PDFToHtml?format=json'
        // }

        // fetch(process.env.REACT_APP_CONVERTER_API + api, {
        //     method: 'POST',
        //     body: postData
        // }).then(response => response.json())
        //     .then(json => {

        //         if (!json) {
        //             return;
        //         }

        //         props.handleConfirm(json.content)
        //     })
    }

    return (
        <FormProvider {...form}>
            <div id="modal-form" className="confirm-modal modal-form">
                <h1 className="title">{strings.forms.contractList.documentForm.title}</h1>


                <div className="file-upload-container">
                    <FileUploadComponent accept='.docx' hideUploadButton={true} onDrop={uploadFile}/>
                    {file &&
                    <div className="file-name">
                        <span>{file.name}</span>
                        <CloseIcon className="close-icon" onClick={handleRemoveFile}/>
                    </div>
                    }
                </div>

                <div className="buttons-container">
                    <Button
                        className="neutral-btn btn cancel-btn"
                        variant="contained"
                        onClick={props.handleCloseModal}>
                        {strings.forms.contractList.cancel}
                    </Button>
                    <Button
                        className="default-btn btn"
                        variant="contained"
                        disabled={disabledSubmit}
                        onClick={handleSubmit(onSubmit)}>
                        {strings.forms.contractList.submit}
                    </Button>
                </div>

            </div>
        </FormProvider>
    )
}

export default TemplateDocumentForm;