import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import CloseIcon from '@mui/icons-material/Close';
import AutocompleteSelectControl from '../../../Controls/AutocompleteSelectControl';
import {getAllUsers} from "../../../../Services/User/UserService";
import {createFilterOptions} from '@mui/material/Autocomplete';
import strings from '../../../../localization';
import {Button} from "@mui/material";
import Modal from "@mui/material/Modal";
import SimpleConfirmModal from '../../../Modals/SimpleConfirmModal';
import {
    getSharedUsersForContract, getSharedUsersForFolder,
    shareContractOrFolder,
    unshareContractOrFolder
} from "../../../../Services/Contract/SharingService";
import SharedType from "../../../../Constants/Contract/SharedType";
import {useSelector} from "react-redux";

const ShareForm = ({
                       handleCloseModal,
                       selectedContracts,
                       shareMode,
                       selectedFolder
                   }) => {

    const user = useSelector((state) => state.auth.user)
    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [allUsers, setAllUsers] = useState([])
    const [afterSubmitMsg, setAfterSubmitMsg] = useState(false)
    const [submitErrMsg, setSubmitErrMsg] = useState(false)
    const [openUnshareModal, setOpenUnshareModal] = useState(false)
    const [userToUnshare, setUserToUnshare] = useState({})
    const [usersToShare, setUsersToShare] = useState([]);
    const [sharedUsers, setSharedUsers] = useState([])
    const [text, setText] = useState('')

    useEffect(() => {
        fetch();
    }, [selectedContracts])

    const fetch = () => {

        getAllUsers().then(response => {

            if (!response || !response.ok) {
                return;
            }

            setAllUsers(response.data)
        })

        if (shareMode === SharedType.CONTRACT) {
            getSharedUsersForContract(selectedContracts[0].id).then(response => {

                if (!response || !response.ok) {
                    return;
                }

                setSharedUsers(response.data);
            })
        } else {
            getSharedUsersForFolder(selectedFolder.id).then(response => {

                if (!response || !response.ok) {
                    return;
                }

                setSharedUsers(response.data);
            })
        }
    }

    const handleInputChange = (user) => {

        setSubmitErrMsg(false)

        setUsersToShare([
            ...usersToShare,
            user
        ])
    }

    const filterOptions = createFilterOptions({
        stringify: ({fullName, email}) => `${fullName} ${email}`
    });

    const handleCloseTag = (user) => {

        setUsersToShare(usersToShare.filter(e => e.id !== user.id))
    }

    const renderNewUsersToShare = () => {
        let result = [];

        for (let user of usersToShare) {

            result.push(
                <div className="user" key={'user-stage-' + result.length}>
                    <div className="name">
                        <span>{user.fullName}</span>
                        <span>{user.email}</span>
                    </div>
                    <div className="state">
                        <CloseIcon className="close-icon" onClick={() => handleCloseTag(user)}/>
                    </div>
                </div>
            )
        }

        return result
    }

    const renderUsersWithDocAccess = () => {
        let result = [];

        for (let shared of sharedUsers) {

            result.push(
                <div className="user" key={'user-stage-' + result.length}>
                    <div className="name">
                        <span>{shared.userTo?.fullName}</span>
                        <span>{shared.emailTo}</span>
                    </div>
                    <div className="state unshare">
                        <span onClick={() => handleUnshare(shared)}>
                            {strings.forms.contractList.shareForm.unshare}</span>
                    </div>
                </div>
            )
        }

        return result
    }

    const renderInvitedUsers = () => {
        let result = []

        for (let item of usersToShare) {

            result.push(
                <div className="name">
                    <span>{item.fullName}</span>
                    <span>{item.email}</span>
                </div>
            )
        }

        return result
    }

    const handleUnshare = (shared) => {

        if (!shared) {
            return;
        }

        setOpenUnshareModal(true)
        setUserToUnshare(shared)
    }

    const handleCloseUnshareModal = () => {
        setOpenUnshareModal(false)
    }

    const handleUnshareConfirm = () => {
        setOpenUnshareModal(false)
        setUserToUnshare(undefined)
        unshareContractOrFolder(userToUnshare.id).then(response => {
            fetch()
        })
    }

    const onSubmit = () => {
        setSubmitErrMsg(false)

        for (let user of usersToShare) {
            shareContractOrFolder({
                contractId: selectedContracts[0] ? selectedContracts[0].id : -1,
                folderId: selectedFolder ? selectedFolder.id : -1,
                type: shareMode,
                userToId: user.id ? user.id : -1,
                emailTo: user.email
            }).then(response => {
            })
        }

        if (usersToShare.length > 0) {
            setAfterSubmitMsg(true)
        } else {
            setSubmitErrMsg(true)
        }
    }

    const getAutocompleteUsers = () => {

        let users = [];

        for (let user of allUsers) {
            let localUser = usersToShare.find(x => x.id === user.id)

            if (localUser) {
                continue;
            }

            localUser = sharedUsers.find(x => x.id === user.id)

            if (localUser) {
                continue;
            }

            users.push(user);
        }

        return users;
    }

    return (
        <FormProvider {...form}>
            <div id="modal-form" className="confirm-modal share-form-modal">
                <div className="close-btn-container">
                    <CloseIcon className="close-icon" onClick={handleCloseModal}/>
                </div>

                <div className="share-form-content">

                    {afterSubmitMsg
                        ? <React.Fragment>
                            <h1 className="title">{strings.forms.contractList.shareForm.inviteSent}</h1>
                            <div className="invited-container">
                                <div className="list">
                                    {renderInvitedUsers()}
                                </div>
                            </div>
                        </React.Fragment>
                        : <React.Fragment>
                            <h1 className="title">{shareMode === SharedType.CONTRACT ?
                                strings.forms.contractList.shareForm.title : strings.forms.contractList.shareForm.titleFolder}</h1>
                            <div className="field-container">
                                <span className="label">{strings.forms.contractList.shareForm.nameOrEmail}</span>
                                <AutocompleteSelectControl
                                    open={text != ''}
                                    name={'users'}
                                    control={data}
                                    nameKey={'email'}
                                    noOptionsText={'Press enter to invite'}
                                    options={getAutocompleteUsers()}
                                    handleInputChange={handleInputChange}
                                    onInputChange={(e) => {setText(e)}}
                                    onKeyDown={(e) => {

                                        if(e.key !== 'Enter') {
                                            return
                                        }

                                        setUsersToShare([
                                            ...usersToShare,
                                            {
                                                email: text
                                            }
                                        ])

                                        setText('')
                                    }}
                                    filterOptions={filterOptions}
                                    placeholder={strings.forms.contractList.shareForm.enterNameOrEmail}
                                />
                            </div>

                            <div className="tag-list">
                                {renderNewUsersToShare()}

                                <div className="border"></div>

                                {selectedContracts.length > 0 &&
                                    <div className="user owner">
                                        <div className="name">
                                            <span>{selectedContracts[0].user.fullName}</span>
                                            <span>{selectedContracts[0].user.email}</span>
                                        </div>
                                        <div className="state">
                                            <span>{strings.forms.contractList.shareForm.owner}</span>
                                        </div>
                                    </div>
                                }

                                {renderUsersWithDocAccess()}
                            </div>

                            {submitErrMsg &&
                                <p className="error-msg">{strings.forms.contractList.shareForm.submitErrMsg}</p>
                            }

                            <div className="buttons-container">
                                <Button
                                    className="neutral-btn btn cancel-btn"
                                    variant="contained"
                                    onClick={handleCloseModal}>
                                    {strings.forms.contractList.shareForm.cancel}
                                </Button>
                                <Button
                                    className="default-btn btn"
                                    variant="contained"
                                    disabled={disabledSubmit}
                                    onClick={handleSubmit(onSubmit)}>
                                    {strings.forms.contractList.shareForm.share}
                                </Button>
                            </div>
                        </React.Fragment>
                    }
                </div>

                <Modal
                    open={openUnshareModal}
                    onClose={handleCloseUnshareModal}
                    className='confirm-modal-container'
                >
                    <SimpleConfirmModal
                        title={strings.modals.contractList.unshare.confirmText}
                        cancelBtn={strings.modals.contractList.unshare.cancel}
                        confirmBtn={strings.modals.contractList.unshare.yes}
                        handleCloseModal={handleCloseUnshareModal}
                        handleConfirm={handleUnshareConfirm}
                    />
                </Modal>

            </div>
        </FormProvider>
    )
}

export default ShareForm;