import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import AIChat from "./AIChat";
import AIHistory from "./AIHistory";
import { getAllQuestions } from "../../Services/AI/AIQuestionService";
import HeaderButtons from "../../Components/Layout/HeaderButtons";
import MenuState from "../../Constants/Base/MenuState";

const AITab = () => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const menuState = useSelector((state) => state.navigation.menuState);

  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [chatID, setChatID] = useState("");
  const [choice, setChoice] = useState("");
  const [historyLength, setHistoryLength] = useState(0);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = () => {
    getAllQuestions().then((response) => {
      if (!response || !response.ok) {
        return;
      }
      setQuestions(response.data);
    });
  };

  const handleClickQuestion = (question) => {
    setChatID(question.chatId);
    setSelectedQuestion(question);
  };

  const getHeaderClass = (menuState) => {
    if (menuState === MenuState.SHORT) {
      return "short";
    }

    return "";
  };

  return (
    <Fragment>
      <div id="header" className={getHeaderClass(menuState)}>
        <div>
          <h1>AI Assist</h1>
        </div>
        <HeaderButtons />
      </div>
      <div className="ai-tab-container">
        <AIChat
          handleClickQuestion={handleClickQuestion}
          question={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          chatID={chatID}
          setChatID={setChatID}
          fetchQuestions={fetchQuestions}
          questions={questions}
          name={choice}
          setChoice={setChoice}
          historyLength={historyLength}
          setHistoryLength={setHistoryLength}
        />
        <AIHistory
          handleClickQuestion={handleClickQuestion}
          handleNewQuestion={() => setChatID(null)}
          chatID={chatID}
          question={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          questions={questions}
          fetchQuestions={fetchQuestions}
        />
      </div>
    </Fragment>
  );
};

export default AITab;
