import {Controller} from "react-hook-form";
import React, {useState} from "react";
import {Box, FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import {getDropdownOptions} from "../../../Util/DropdownUtil";

const SelectControl = (props) => {

    const [currentValue, setCurrentValue] = useState(props.defaultValue ? props.defaultValue.id : null)

    const getValue = (value) => {
        if(!props.valueKey || !props.options) {
            return value;
        }

        setCurrentValue(value)

        return  props.options.find(x => x[props.valueKey] === value)
    }

    return <Box sx={{ minWidth: 120 }} className={'select-control-container'}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
            <Controller
                name={props.name}
                control={props.data}
                rules={props.rules}
                render={({ field }) =>
                    <Select
                        onChange={e => props.setValue(props.name, getValue(e.target.value))}
                        defaultValue={props.valueKey && props.defaultValue ? props.defaultValue[props.valueKey] : props.defaultValue}
                        label={props.label}
                        size={props.size ? props.size : 'small'}
                        error={props.error}
                        className={currentValue == -2 ? "select-control disabled" : "select-control"}
                    >
                        {getDropdownOptions(props.options, props.nameKey)}
                    </Select>
                }
            />
            {
                props.error &&
                <span className="error-msg">{props.helperText}</span>
            }
        </FormControl>
    </Box>
}

export default SelectControl;