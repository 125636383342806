import React, {useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import TextFieldControl from '../../../../Controls/Inputs/TextFieldControl';
import TextareaControl from '../../../../Controls/Inputs/TextareaControl';
import RadioGroupControl from '../../../../Controls/Inputs/RadioGroupControl';
import { Button } from "@mui/material";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import {getAllPackagesForSubscription} from "../../../../../Services/ContractCategory/PackageService";
import {addCustomPackageRequest} from "../../../../../Services/ContractCategory/CustomPackageRequestService";
import {useSelector} from "react-redux";
import Modal from "@mui/material/Modal";
import ConfirmModal from "../../../../Modals/ConfirmModal";

const uploadTemplateRadio = [
    {id: 1, name: strings.forms.dashboard.pricingAndPackage.customPackageForm.yes},
    {id: 0, name: strings.forms.dashboard.pricingAndPackage.customPackageForm.no}
]

const CustomPackageForm = ({pack, setCustomPackForm, setShowPackList}) => {

    const form = useForm();
    const user = useSelector((state) => state.auth.user)
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [packages, setPackages] = useState([])
    const [showInfo, setShowInfo] = useState(false)

    useEffect(() => {
        setValue('ownTemplates', 1)

        fetchPackages();
    }, [])

    const onSubmit = (data) => {

        addCustomPackageRequest({
            ...data,
            packageId: data.package ? data.package.id : -1,
            ownTemplates: data.ownTemplates === 1
        }).then(response => {
            setShowInfo(true)
        })
    }

    const fetchPackages = () => {

        getAllPackagesForSubscription({}).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setPackages(response.data.map(x => x.package));
        })
    }

    const handleCancel = () => {
        setShowPackList(true)
        setCustomPackForm(false)
    }

    return (
    <div id="dashboard" className="dashboard pricing-and-packages">
        <FormProvider {...form}>
            <div id="dashboard" className="dashboard buy-package-form">

                <div className="left ">

                    <div className="dashboard-box user-details">
                        <h1 className="header">{strings.forms.dashboard.pricingAndPackage.customPackageForm.customPackage}</h1>

                        <div className="item-field-container">
                            <span className="info-icon-text">
                                {strings.forms.dashboard.pricingAndPackage.customPackageForm.fullCompanyName}
                            </span>
                            <TextFieldControl
                                name='companyName'
                                control={data}
                                defaultValue={user.company.companyName}
                                rules={{required: true}}
                                fullWidth
                                margin="normal"
                                error={Boolean(errors.companyName)}
                                helperText={errors.companyName && strings.forms.common.required}
                                placeholder={strings.forms.dashboard.pricingAndPackage.customPackageForm.enterCompanyName}
                            />
                        </div>

                        <div className="item-field-container">
                            <span className="info-icon-text">
                                {strings.forms.dashboard.pricingAndPackage.customPackageForm.email}
                            </span>
                            <TextFieldControl
                                name='email'
                                control={data}
                                defaultValue={user.email}
                                rules={{required: true}}
                                fullWidth
                                margin="normal"
                                error={Boolean(errors.email)}
                                helperText={errors.email && strings.forms.common.required}
                                placeholder={strings.forms.dashboard.pricingAndPackage.customPackageForm.enterEmail}
                            />
                        </div>

                        <div className="item-field-container">
                            <span className="info-icon-text">
                                {strings.forms.dashboard.pricingAndPackage.customPackageForm.numberOfEmployees}
                            </span>
                            <TextFieldControl
                                name='numberOfEmployees'
                                control={data}
                                rules={{required: true}}
                                type='number'
                                fullWidth
                                margin="normal"
                                error={Boolean(errors.numberOfEmployees)}
                                helperText={errors.numberOfEmployees && strings.forms.common.required}
                                placeholder={strings.forms.dashboard.pricingAndPackage.customPackageForm.enterNumberOfEmployees}
                            />
                        </div>

                        <div className="item-field-container">
                            <span className="info-icon-text radio-group-title">
                                {strings.forms.dashboard.pricingAndPackage.customPackageForm.selectPackage}
                            </span>
                            <SelectControl
                                rules={{required: true}}
                                name='package'
                                options={packages}
                                setValue={setValue}
                                nameKey='name'
                                valueKey='id'
                                error={Boolean(errors.package)}
                                helperText={errors.package && strings.forms.common.required}
                                className="select-control-container"
                            />
                        </div>

                        <div className="item-field-container">
                            <span className="info-icon-text radio-group-title">
                                {strings.forms.dashboard.pricingAndPackage.customPackageForm.uploadingYourTemplate}
                            </span>
                            <RadioGroupControl
                                name={'ownTemplates'}
                                setValue={setValue}
                                radioButtonList={uploadTemplateRadio}
                                defaultValue={1}
                            />
                        </div>
                    </div>
                </div>

                <div className="right dashboard-box">
                    <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.dashboard.pricingAndPackage.customPackageForm.additionalInformation}
                        </span>
                        <TextareaControl
                            name='additionalInformation'
                            defaultValue=''
                            rules={{required: true}}
                            fullWidth
                            margin="normal"
                            placeholder={strings.forms.dashboard.pricingAndPackage.customPackageForm.enterAdditionalInformation}
                            rows={8}
                            maxRows={16}
                            error={Boolean(errors.additionalInformation)}
                            helperText={errors.additionalInformation && strings.forms.common.required}
                        />
                    </div>

                    <div className="submit-container">
                        <Button className="secondary-neutral-btn btn" variant="contained" 
                            onClick={handleCancel}>{strings.forms.dashboard.pricingAndPackage.customPackageForm.cancel}</Button>
                        <Button className="secondary-btn btn" variant="contained" 
                            onClick={handleSubmit(onSubmit)}>{strings.forms.dashboard.pricingAndPackage.customPackageForm.submit}</Button>
                    </div>

                </div>
            </div>

            <Modal
                open={showInfo}
                onClose={() => setShowInfo(false)}
                className='confirm-modal-container'
            >
                <ConfirmModal
                    contentText={'Thank you for requesting a custom package with Logical Contracts. Your request has been received by our team who will be in touch shortly to discuss your request in further detail.'}
                    confirmBtn={'Ok'}
                    handleConfirm={() => {
                        setShowInfo(false)
                        setShowPackList(true)
                        setCustomPackForm(false)
                    }}
                />
            </Modal>

        </FormProvider>
    </div>
    )
}

export default CustomPackageForm;

