import React, {useEffect, useState} from "react";
import strings from "../../../../../localization";
import CloseIcon from '@mui/icons-material/Close';
import AutocompleteSelectControl from '../../../../Controls/AutocompleteSelectControl';
import {
    getAllContractCategories,
    getContractCategory
} from "../../../../../Services/ContractCategory/ContractCategoryService";

const CategoryForm = ({
                        data,
                        fetch,
                        onCategoryChanged
    }) => {

    const [allCategories, setAllCategories] = useState([])
    const [otherCategories, setOtherCategories] = useState([])
    const [categoriesIdList, setCategoriesIdList] = useState([])
    const [categoriesFullObjList, setCategoriesFullObjList] = useState([])

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        onCategoryChanged(categoriesIdList)
    }, [categoriesIdList])

    const fetchCategories = () => {

        getAllContractCategories({}).then(response => {

            if(!response || !response.ok) {
                return;
            }

            let result = [];
            for(let category of response.data) {
                if(!category.parent) {
                    result.push(category);
                }
            }

            let resultActive = result.filter(e => e.active == true)

            setOtherCategories(resultActive);
            setAllCategories(resultActive);
        })
    }

    const handleCloseTag = (id) => {

        setCategoriesFullObjList(categoriesFullObjList.filter(e => e.id !== id))

        let category = allCategories.find(e => e.id === id)

        setOtherCategories([
            ...otherCategories,
            category
        ])

        setCategoriesIdList([
            ...categoriesIdList,
            category.id
        ])
    }

    const getDataItem = () => {
        let result = [];

        if (categoriesFullObjList.length > 0) {
            for (let item of categoriesFullObjList) {

                result.push(
                    <div className="tag" key={item.id}>
                        <span className="tag-text">{item.name}</span>
                        <CloseIcon className="close-icon" onClick={() => handleCloseTag(item.id)} />
                    </div>
                )
            }
        } else {
            return []
        }
        return result
    }

    const handleInputChange = (category) => {
        
        setCategoriesFullObjList([
            ...categoriesFullObjList,
            category
        ])
        
        setOtherCategories(otherCategories.filter(e => e.id !== category.id))
                
        setCategoriesIdList([
            ...categoriesIdList,
            category.id
        ])
    }

    return (
        <div className="item second">
            <div className="item-title">
                <span>{strings.forms.admin.adminPackageForm.categories.categories}</span>
            </div>

            <AutocompleteSelectControl 
                name={'categories'}
                control={data}
                options={otherCategories}
                handleInputChange={handleInputChange}
                placeholder={strings.forms.admin.adminPackageForm.categories.selectCategories}
            />

            <div className="tag-list">
                {getDataItem()}
            </div>
        </div>
    )
}

export default CategoryForm;