import strings from "../../localization";

const QuestionFormat = {
    TEXT_FIELD: 1,
    TOGGLE: 2,
    AUTOCOMPLETE: 3,
    CHECKBOX: 4,
    DATE_PICKER: 5,
    TEXTAREA: 6,
    RADIO_BUTTON: 7,
    SELECT: 8,
    SLIDER: 9,
    NUMBER_INPUT: 10,
    TIME_PICKER: 11,
    SWITCH: 12,
    COMPANY_HOUSE: 13,
    CHECKBOX_GROUP: 14
};

export function getQuestionFormat() {
    return [
        {
            id: QuestionFormat.TEXT_FIELD,
            name: getQuestionFormatString(QuestionFormat.TEXT_FIELD)
        },
        {
            id: QuestionFormat.TOGGLE,
            name: getQuestionFormatString(QuestionFormat.TOGGLE)
        },
        {
            id: QuestionFormat.AUTOCOMPLETE,
            name: getQuestionFormatString(QuestionFormat.AUTOCOMPLETE)
        },
        {
            id: QuestionFormat.DATE_PICKER,
            name: getQuestionFormatString(QuestionFormat.DATE_PICKER)
        },
        {
            id: QuestionFormat.TEXTAREA,
            name: getQuestionFormatString(QuestionFormat.TEXTAREA)
        },
        {
            id: QuestionFormat.RADIO_BUTTON,
            name: getQuestionFormatString(QuestionFormat.RADIO_BUTTON)
        },
        {
            id: QuestionFormat.SELECT,
            name: getQuestionFormatString(QuestionFormat.SELECT)
        },
        {
            id: QuestionFormat.SLIDER,
            name: getQuestionFormatString(QuestionFormat.SLIDER)
        },
        {
            id: QuestionFormat.NUMBER_INPUT,
            name: getQuestionFormatString(QuestionFormat.NUMBER_INPUT)
        },
        {
            id: QuestionFormat.TIME_PICKER,
            name: getQuestionFormatString(QuestionFormat.TIME_PICKER)
        },
        {
            id: QuestionFormat.SWITCH,
            name: getQuestionFormatString(QuestionFormat.SWITCH)
        },
        {
            id: QuestionFormat.COMPANY_HOUSE,
            name: getQuestionFormatString(QuestionFormat.COMPANY_HOUSE)
        },
        {
            id: QuestionFormat.CHECKBOX_GROUP,
            name: getQuestionFormatString(QuestionFormat.CHECKBOX_GROUP)
        }
    ]
}

export function questionFormatDefault() {
    return {
        id: QuestionFormat.TEXT_FIELD,
            name: getQuestionFormatString(QuestionFormat.TEXT_FIELD)
    }
}

export function getQuestionFormatString(questionFormat) {
    switch(questionFormat) {
        case QuestionFormat.TEXT_FIELD: return strings.constants.admin.questionFormat.textField;
        case QuestionFormat.TOGGLE: return strings.constants.admin.questionFormat.toggle;
        case QuestionFormat.AUTOCOMPLETE: return strings.constants.admin.questionFormat.autocomplete;
        case QuestionFormat.CHECKBOX: return strings.constants.admin.questionFormat.checkbox;
        case QuestionFormat.DATE_PICKER: return strings.constants.admin.questionFormat.datePicker;
        case QuestionFormat.TEXTAREA: return strings.constants.admin.questionFormat.textarea;
        case QuestionFormat.RADIO_BUTTON: return strings.constants.admin.questionFormat.radioButton;
        case QuestionFormat.SELECT: return strings.constants.admin.questionFormat.dropdown;
        case QuestionFormat.SLIDER: return strings.constants.admin.questionFormat.slider;
        case QuestionFormat.NUMBER_INPUT: return strings.constants.admin.questionFormat.numberInput;
        case QuestionFormat.TIME_PICKER: return strings.constants.admin.questionFormat.timePicker;
        case QuestionFormat.SWITCH: return strings.constants.admin.questionFormat.switchButton;
        case QuestionFormat.COMPANY_HOUSE: return strings.constants.admin.questionFormat.company;
        case QuestionFormat.CHECKBOX_GROUP: return strings.constants.admin.questionFormat.checkboxGroup;
    }
}

export default QuestionFormat;