import { createSlice } from "@reduxjs/toolkit";

const aiDataSlice = createSlice({
    name: 'aiData',
    initialState: {
        aiDataRow: '',
        aiRequestRow: '',
        taggedData:[],
        aiAllData:[]
    },
    reducers: {
        setAiData(state, action) {
            state.aiDataRow = action.payload
        },
        setAiAllData(state, action) {
            state.aiAllData = action.payload
        },
        setInputData(state, action) {
            state.aiDataRow[action.payload.name] = action.payload.value
        },
        setArrayVarData(state, action) {
            state.aiDataRow[action.payload.name] = [...state.aiDataRow[action.payload.name], action.payload.value]
        },
        setDeleteVarData(state, action) {
            state.aiDataRow[action.payload.name] = action.payload.value
        },
        editArrayVarData(state, action) {
            state.aiDataRow[action.payload.name] = action.payload.value
        },
        setaiRequestRow(state, action) {
            state.aiRequestRow = action.payload
        },
        setAiRequestInput(state, action) {
            state.aiRequestRow[action.payload.name] = action.payload.value
        },
        settaggedData(state, action){
            state.taggedData = [...state.taggedData, action.payload.data]
        },
        setEmptyTaggedData(state, action){
            state.taggedData = action.payload
        }
}});

export const aiDataActions = aiDataSlice.actions

export default aiDataSlice.reducer