import React, {useState, useEffect} from "react";
import strings from "../../../localization";
import TextFieldControl from '../../../Components/Controls/Inputs/TextFieldControl';
import TextareaControl from '../../../Components/Controls/Inputs/TextareaControl';
import ImageUploadComponent from '../../../Components/Controls/ImageUploadComponent';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import {Button} from "@mui/material";
import {getFeaturesByPackage} from '../../../Services/ContractCategory/PackageService';
import CheckBoxControl2 from "../../../Components/Controls/Inputs/CheckBoxControl2";

const SubPackage = ({
                        name,
                        pack,
                        editIcon,
                        data,
                        errors,
                        featuresFieldsNames,
                        setFeaturesFieldsNames,
                        additionalFeaturesFieldsNames,
                        setAdditionalFeaturesFieldsNames,
                        icon,
                        setIcon,
                        setValue
                    }) => {

    let feturesFields = []
    let additionalFeaturesFields = []
    const [featuresCount, setFeaturesCount] = useState(null)
    const [additionalFeaturesCount, setAdditionalFeaturesCount] = useState(null)

    useEffect(() => {
        setIcon(pack.icon)
        setValue('name', pack.name)
        setValue('description', pack.description)
        setValue('featureTitle', pack.description)
        setValue('additionalFeatureTitle', pack.additionalFeatureTitle)
        setValue('color', pack.color)
        setValue('baseFeatures', pack.baseFeatures)
        setValue('dataRoomNoSignature', pack.dataRoomNoSignature)
        setValue('dataRoomSignature', pack.dataRoomSignature)
        fetch()
    }, [])

    useEffect(() => {
        setFeaturesFieldsNames(feturesFields)
    }, [featuresCount])

    useEffect(() => {
        setAdditionalFeaturesFieldsNames(additionalFeaturesFields)
    }, [additionalFeaturesCount])

    const fetch = () => {
        getFeaturesByPackage(pack.id).then(response => {
            if (!response || !response.ok) {
                return
            }

            let resFeatures = response.data.filter(e => e.type == 1).map(e => e.content)
            setFeaturesFieldsNames(resFeatures)
            setFeaturesCount(resFeatures.length)

            for (let i = 0; i < resFeatures.length; i++) {
                setValue('feature' + i, resFeatures[i])
            }

            let resAddFeatures = response.data.filter(e => e.type == 2).map(e => e.content)
            setAdditionalFeaturesFieldsNames(resAddFeatures)
            setAdditionalFeaturesCount(resAddFeatures.length)

            for (let i = 0; i < resAddFeatures.length; i++) {
                setValue('additionalFeature' + i, resAddFeatures[i])
            }
        })
    }

    const addFeature = () => {
        setFeaturesCount(featuresCount + 1)
    }

    const addAdditionalFeature = () => {
        setAdditionalFeaturesCount(additionalFeaturesCount + 1)
    }

    const renderFeatures = () => {
        let result = []

        for (let i = 0; i < featuresCount; i++) {
            result.push(
                editIcon
                    ? <div className="item-field-row">
                        <div className="icon-container">
                            <CheckIcon className="icon check-icon"/>
                        </div>
                        <TextFieldControl
                            name={'feature' + i}
                            control={data}
                            defaultValue={featuresCount > 0 ? featuresFieldsNames[i] : ''}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.feature)}
                            helperText={errors.feature && strings.forms.admin.adminPackageForm.packages.feature}
                            placeholder={strings.forms.admin.adminPackageForm.packages.enterFeature}
                        />
                    </div>
                    : <div className="item-field-row disabled">
                        <div className="icon-container">
                            <CheckIcon className="icon check-icon"/>
                        </div>
                        <TextFieldControl
                            defaultValue={featuresCount > 0 ? featuresFieldsNames[i] : ''}
                            readOnly
                        />
                    </div>
            )
            feturesFields.push('feature' + i)
        }
        return result
    }

    const renderAdditionalFeatures = () => {
        let result = []

        for (let i = 0; i < additionalFeaturesCount; i++) {
            result.push(
                editIcon
                    ? <div className="item-field-row">
                        <div className="icon-container">
                            <AddIcon className="icon plus-icon"/>
                        </div>
                        <TextFieldControl
                            name={'additionalFeature' + i}
                            control={data}
                            defaultValue={additionalFeaturesCount > 0 ? additionalFeaturesFieldsNames[i] : ''}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.additionalFeature)}
                            helperText={errors.additionalFeature && strings.forms.admin.adminPackageForm.packages.additionalFeature}
                            placeholder={strings.forms.admin.adminPackageForm.packages.enterAdditionalFeature}
                        />
                    </div>
                    : <div className="item-field-row disabled">
                        <div className="icon-container">
                            <AddIcon className="icon plus-icon"/>
                        </div>
                        <TextFieldControl
                            defaultValue={additionalFeaturesCount > 0 ? additionalFeaturesFieldsNames[i] : ''}
                            readOnly
                        />
                    </div>
            )
            additionalFeaturesFields.push('additionalFeature' + i)
        }
        return result
    }

    return (
        <div className="item left drop">
            <div className="item-title">
                <span>{name}</span>
            </div>

            {
                editIcon
                    ? <React.Fragment>
                        <div className="upload-icon-container">
                            <div className="icon">
                                <ImageUploadComponent
                                    accept={['.jpeg', '.jpg', '.png', '.svg']}
                                    hideUploadButton={true}
                                    setImage={setIcon}
                                    image={icon}
                                    icon={"/images/icons/icon-add.png"}
                                />
                            </div>
                            <div className="icon-text">
                                <p className="subtitle">{strings.forms.admin.adminPackageForm.packages.addIcon}</p>
                                <p className="text">{strings.forms.admin.adminPackageForm.packages.iconDescription}</p>
                            </div>
                        </div>

                        <div className={'feature-checkbox-container'}>
                            <CheckBoxControl2
                                name={'baseFeatures'}
                                control={data}
                                label={'Create Contracts '}
                                setValue={setValue}
                                checked={pack.baseFeatures}
                            />

                            <CheckBoxControl2
                                name={'dataRoomNoSignature'}
                                control={data}
                                label={'Dataroom'}
                                setValue={setValue}
                                checked={pack.dataRoomNoSignature}
                            />

                            <CheckBoxControl2
                                name={'dataRoomSignature'}
                                control={data}
                                label={'Dataroom and E-signature'}
                                setValue={setValue}
                                checked={pack.dataRoomSignature}
                            />
                        </div>

                        <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.admin.adminPackageForm.packages.color}
                        </span>
                            <TextFieldControl
                                name='color'
                                control={data}
                                defaultValue={pack.color ? pack.color : ''}
                                rules={{required: true}}
                                fullWidth
                                margin="normal"
                                error={Boolean(errors.color)}
                                helperText={errors.color && strings.forms.admin.adminPackageForm.packages.color}
                                placeholder={strings.forms.admin.adminPackageForm.packages.enterColor}
                            />
                        </div>

                        <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.admin.adminPackageForm.packages.description}
                        </span>
                            <TextareaControl
                                name='description'
                                control={data}
                                defaultValue={pack.description ? pack.description : ''}
                                rules={{required: true}}
                                fullWidth
                                margin="normal"
                                error={Boolean(errors.description)}
                                helperText={errors.description && strings.forms.admin.adminPackageForm.packages.description}
                                placeholder={strings.forms.admin.adminPackageForm.packages.enterDescription}
                                rows={3}
                                maxRows={5}
                            />
                        </div>

                        <div className="border"></div>

                        <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.admin.adminPackageForm.packages.featuresTitle}
                        </span>
                            <TextFieldControl
                                name='featureTitle'
                                control={data}
                                defaultValue={pack.featureTitle ? pack.featureTitle : ''}
                                rules={{required: true}}
                                fullWidth
                                margin="normal"
                                error={Boolean(errors.featureTitle)}
                                helperText={errors.featureTitle && strings.forms.admin.adminPackageForm.packages.featuresTitle}
                                placeholder={strings.forms.admin.adminPackageForm.packages.enterFeaturesTitle}
                            />
                        </div>

                        {renderFeatures()}

                        <Button className="neutral-btn btn feature-btn"
                                startIcon={<AddIcon/>}
                                variant="contained" onClick={addFeature}>
                            <span className="btn-txt">{strings.forms.admin.adminPackageForm.packages.newFeature}</span>
                        </Button>

                        <div className="border"></div>

                        <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.admin.adminPackageForm.packages.additionalFeaturesTitle}
                        </span>
                            <TextFieldControl
                                name='additionalFeatureTitle'
                                control={data}
                                defaultValue={pack.additionalFeatureTitle ? pack.additionalFeatureTitle : ''}
                                rules={{required: true}}
                                fullWidth
                                margin="normal"
                                error={Boolean(errors.additionalFeatureTitle)}
                                helperText={errors.additionalFeatureTitle && strings.forms.admin.adminPackageForm.packages.additionalFeaturesTitle}
                                placeholder={strings.forms.admin.adminPackageForm.packages.enterAdditionalFeaturesTitle}
                            />
                        </div>

                        {renderAdditionalFeatures()}

                        <Button className="neutral-btn btn"
                                startIcon={<AddIcon/>}
                                variant="contained" onClick={addAdditionalFeature}>
                            <span
                                className="btn-txt">{strings.forms.admin.adminPackageForm.packages.newAdditionalFeature}</span>
                        </Button>

                    </React.Fragment>

                    : <React.Fragment>
                        <div className="upload-icon-container">
                            <div className="icon">
                                <ImageUploadComponent
                                    accept={['.jpeg', '.jpg', '.png', '.svg']}
                                    hideUploadButton={true}
                                    setImage={setIcon}
                                    image={icon}
                                    icon={"/images/icons/icon-add.png"}
                                    disabled
                                />
                            </div>
                            <div className="icon-text">
                                <p className="subtitle">{strings.forms.admin.adminPackageForm.packages.addIcon}</p>
                                <p className="text">{strings.forms.admin.adminPackageForm.packages.iconDescription}</p>
                            </div>
                        </div>

                        <div className={'feature-checkbox-container disabled'}>
                            <CheckBoxControl2
                                name={'baseFeatures'}
                                control={data}
                                label={'Create Contracts '}
                                setValue={setValue}
                                disabled={true}
                                defaulValue={pack.baseFeatures}
                            />

                            <CheckBoxControl2
                                name={'dataRoomNoSignature'}
                                control={data}
                                label={'Dataroom'}
                                setValue={setValue}
                                disabled={true}
                                defaulValue={pack.dataRoomNoSignature}
                            />

                            <CheckBoxControl2
                                name={'dataRoomSignature'}
                                control={data}
                                label={'Dataroom and E-signature'}
                                setValue={setValue}
                                disabled={true}
                                defaulValue={pack.dataRoomSignature}
                            />
                        </div>

                        <div className="item-field-container disabled">
                    <span className="info-icon-text">
                        {strings.forms.admin.adminPackageForm.packages.color}
                    </span>
                            <TextFieldControl
                                defaultValue={pack.color ? pack.color : ''}
                                readOnly
                            />
                        </div>

                        <div className="item-field-container disabled">
                    <span className="info-icon-text">
                        {strings.forms.admin.adminPackageForm.packages.description}
                    </span>
                            <TextareaControl
                                defaultValue={pack.description ? pack.description : ''}
                                rows={3}
                                maxRows={5}
                                readOnly
                            />
                        </div>

                        <div className="border"></div>

                        <div className="item-field-container disabled">
                    <span className="info-icon-text">
                        {strings.forms.admin.adminPackageForm.packages.featuresTitle}
                    </span>
                            <TextFieldControl
                                defaultValue={pack.featureTitle ? pack.featureTitle : ''}
                                readOnly
                            />
                        </div>

                        {renderFeatures()}

                        <Button className="neutral-btn btn feature-btn disabled"
                                startIcon={<AddIcon/>}
                                variant="contained">
                            <span className="btn-txt">{strings.forms.admin.adminPackageForm.packages.newFeature}</span>
                        </Button>

                        <div className="border"></div>

                        <div className="item-field-container disabled">
                    <span className="info-icon-text">
                        {strings.forms.admin.adminPackageForm.packages.additionalFeaturesTitle}
                    </span>
                            <TextFieldControl
                                defaultValue={pack.additionalFeatureTitle ? pack.additionalFeatureTitle : ''}
                                readOnly
                            />
                        </div>

                        {renderAdditionalFeatures()}

                        <Button className="neutral-btn btn disabled"
                                startIcon={<AddIcon/>}
                                variant="contained">
                            <span
                                className="btn-txt">{strings.forms.admin.adminPackageForm.packages.newAdditionalFeature}</span>
                        </Button>

                    </React.Fragment>
            }

        </div>
    )
}

export default SubPackage;