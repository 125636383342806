import HttpMethod from "../../Constants/Base/HttpMethod";
import { request } from "../../Base/HTTP";

export async function getDealRoomData(id) {
  return await request(`/api/dealRoom/${id}`);
}

export async function createDealRoom(data) {
  return await request("/api/dealRoom", data, HttpMethod.POST);
}

export async function addNewUserInDealRoom(projectId, data) {
  return await request(`api/dealRoom/${projectId}/user`, data, HttpMethod.POST);
}

export async function updateUserInDealRoom(projectId, userId, data) {
  return await request(
    `api/dealRoom/${projectId}/user/${userId}`,
    data,
    HttpMethod.PATCH
  );
}

export async function deleteDealRoomUser(projectId, userId) {
  return await request(
    `api/dealRoom/${projectId}/user/${userId}`,
    {},
    HttpMethod.DELETE
  );
}

export async function updateDealSummaryData(projectId, data) {
  return await request(`api/dealRoom/${projectId}`, data, HttpMethod.PATCH);
}

export async function getAllDealRoom() {
  return await request("/api/dealRoom/all");
}

export async function dueDeligenceAddSection(projectId, data) {
  return await request(
    `/api/dealRoom/${projectId}/section`,
    data,
    HttpMethod.POST
  );
}

export async function dueDeligenceUpdateSection(projectId, sectionId, data) {
  return await request(
    `/api/dealRoom/${projectId}/section/${sectionId}`,
    data,
    HttpMethod.PATCH
  );
}

export async function getDueDeligenceSections(projectId, type) {
  return await request(
    `/api/dealRoom/${projectId}/sections/type/${type}`,
    HttpMethod.GET
  );
}

export async function dueDeligenceAddQuestion(projectId, sectionId, data) {
  return await request(
    `/api/dealRoom/${projectId}/section/${sectionId}/question`,
    data,
    HttpMethod.POST
  );
}

export async function dueDeligenceEditQuestion(projectId, questionId, data) {
  return await request(
    `/api/dealRoom/${projectId}/section/question/${questionId}`,
    data,
    HttpMethod.PATCH
  );
}

export async function dueDeligenceDeleteQuestion(
  projectId,
  sectionId,
  questionId
) {
  return await request(
    `/api/dealRoom/${projectId}/section/${sectionId}/question/${questionId}`,
    {},
    HttpMethod.DELETE
  );
}

export async function deleteDueDiligenceSection(projectId, sectionId) {
  return await request(
    `/api/dealRoom/${projectId}/section/${sectionId}`,
    {},
    HttpMethod.DELETE
  );
}

export async function dueDeligenceSaveOrUpdateResponse(projectId, data) {
  return await request(
    `/api/dealRoom/${projectId}/sections/question/answer`,
    data,
    HttpMethod.POST
  );
}

export async function dueDeligenceShareResponse(projectId) {
  return await request(
    `/api/dealRoom/${projectId}/sections/share`,
    {},
    HttpMethod.PATCH
  );
}

export async function dueDeligenceUploadFilesToQuestion(
  projectId,
  questionId,
  data
) {
  return await request(
    `/api/dealRoom/${projectId}/section/question/${questionId}/upload`,
    data,
    HttpMethod.POST
  );
}

export async function DueDeligencGetAllDocument(projectId) {
  return await request(`api/dealRoom/${projectId}/contracts`, HttpMethod.GET);
}

export async function DueDeligencTagDocument(projectId, questionId, data) {
  return await request(
    `api/dealRoom/${projectId}/section/question/${questionId}/contracts/tag`,
    data,
    HttpMethod.PATCH
  );
}

export async function dueDiligenceDeleteContractFromQuestion(
  projectId,
  questionId,
  contractId
) {
  return await request(
    `api/dealRoom/${projectId}/section/question/${questionId}/contracts/${contractId}`,
    {},
    HttpMethod.DELETE
  );
}

export async function postNewComment(projectId, data) {
  return await request(
    `/api/dealRoom/${projectId}/comment`,
    data,
    HttpMethod.POST
  );
}

export async function replyOnComment(projectId, threadId, data) {
  return await request(
    `/api/dealRoom/${projectId}/comment/${threadId}`,
    data,
    HttpMethod.POST
  );
}

export async function getAllComments(projectId, commentPerPage = 10) {
  return await request(
    `/api/dealRoom/${projectId}/comments/all?page=0&perPage=${commentPerPage}`
  );
}

export async function likeComment(projectId, commentId) {
  return await request(
    `api/dealRoom/${projectId}/comment/${commentId}/like`,
    {},
    HttpMethod.PATCH
  );
}

export async function unlikeComment(projectId, commentId) {
  return await request(
    `api/dealRoom/${projectId}/comment/${commentId}/unlike`,
    {},
    HttpMethod.PATCH
  );
}

export async function getAllPackages(projectId) {
  return await request("api/dealRoom/package/prices");
}

export async function subscribe(projectId, data) {
  return await request(
    `/api/dealRoom/${projectId}/subscribe`,
    data,
    HttpMethod.POST
  );
}

export async function addNewShareholder(projectId, data) {
  return await request(
    `api/capTable/${projectId}/shareholder`,
    data,
    HttpMethod.POST
  );
}

export async function updateShareholder(projectId, id, data) {
  return await request(
    `api/capTable/${projectId}/shareholder/${id}`,
    data,
    HttpMethod.PUT
  );
}

export async function deleteShareholder(projectId, id) {
  return await request(
    `api/capTable/${projectId}/shareholder/${id}`,
    {},
    HttpMethod.DELETE
  );
}

export async function getAllShareholders(projectId) {
  return await request(`api/capTable/${projectId}/shareholders`);
}

export async function makeDealroomSubscription(projectId, data) {
  return await request(
    `api/dealRoom/${projectId}/subscribe`,
    data,
    HttpMethod.POST
  );
}

export async function connectLegalSupport(projectId, data) {
  return await request(
    `api/dealRoom/${projectId}/connect/legal/support`,
    data,
    HttpMethod.POST
  );
}

export async function connectFundingSupport(projectId, data) {
  return await request(
    `api/dealRoom/${projectId}/connect/funding/support`,
    data,
    HttpMethod.POST
  );
}

export async function connectBusinessSupport(projectId, data) {
  return await request(
    `api/dealRoom/${projectId}/connect/business/support`,
    data,
    HttpMethod.POST
  );
}

export async function getProjectSubscription(projectId) {
  return await request(
    `api/dealRoom/${projectId}/subscription`,
    HttpMethod.GET
  );
}

export async function downloadDueDiligenceDoc(questionId, contractId) {
  return await request(
    `api/dealRoom/section/question/${questionId}/contracts/${contractId}`
  );
}

export async function downloadDealroomData(projectId) {
  return await request(`api/dataRoom/${projectId}/export/zip`);
}

export async function downloadAllQuestionnaire(projectId) {
  return await request(`api/dealRoom/${projectId}/questionnaire/download`);
}

export async function closeDealroom(projectId) {
  return await request(
    `/api/dealRoom/${projectId}/close`,
    {},
    HttpMethod.PATCH
  );
}
