import React, {useState, useEffect, useContext} from "react";
import strings from "../../../../../../localization";
import TextFieldControl from "../../../../../Controls/Inputs/TextFieldControl";
import {useForm} from "react-hook-form";
import {FormProvider} from "react-hook-form";
import SnackbarContext from "../../../../../../Context/SnackbarContext";
import Modal from '@mui/material/Modal';
import ConfirmModal from '../../../../../Modals/ConfirmModal';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {reorder, sortByStepOrder, sortById} from "../../../../../../Util/DnDUtil";
import IconButton from '@mui/material/IconButton';
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {Collapse} from "@mui/material";
import QuestionList from "../../../../../../Pages/Admin/AdminTemplates/QuestionList";
import {deleteTemplateStep, editTemplateStep, reorderSections} from "../../../../../../Services/Template/TemplateStepService";
import Tooltip from "@mui/material/Tooltip";

const SubSections = ({
                        search,
                        sectionId,
                        editor,
                        sectionStages,
                        setSectionStages,
                        setIsSubSectionFormHidden,
                        selectedSubSection,
                        setSelectedSubSection,
                        template,
                        subSectionsForTemplate,
                        setSubSectionsForTemplate,
                        sectionsForTemplate,
                        handleConfirmSection,
                        refreshSections
    }) => {

    const [editSubSectionTitle, setEditSubSectionTitle] = useState(-1)
    const [openModal, setOpenModal] = useState(false)
    const [otherSubSections, setOtherSubSections] = useState([])
    const [subSectionsForSection, setSubSectionsForSection] = useState([])

    const form = useForm();
    const {data, handleSubmit, setValue, formState: {errors}} = form;
    const { showMessage } = useContext(SnackbarContext);
    const [drop, setDrop] = useState({ id: 0, toggle: false})

    const handleDrop = (id) => {

        if(drop.id === id) {
            setDrop({
                id: id,
                toggle: !drop.toggle
            });
        }
        else {
            setDrop({
                id: id,
                toggle: true
            });
        }
    }

    useEffect(() => {
        if (!sectionStages) {
            return;
        }

        let subSections = sortByStepOrder(subSectionsForTemplate.filter(e => e.parent && e.parent.id === sectionId))
        let otherSections = subSectionsForTemplate.filter(e => e.parent === null || e.parent.id !== sectionId)

        setSubSectionsForSection(subSections)
        setOtherSubSections(otherSections)
    }, [sectionStages])

    const handleEditSubSection = (subSection) => {
        setIsSubSectionFormHidden(false)
        setSelectedSubSection(subSection)
    }

    const handleEditSubSectionTitle = (subSection) => {
        setEditSubSectionTitle(subSection.id)
        setValue('subSectionName', subSection.name)
    }

    const submitEditSubSection = (data) => {

        editTemplateStep({
            name: data.subSectionName,
            id: editSubSectionTitle
        }).then(response => {

        })

        let newSubSections = subSectionsForTemplate.map(e => {
            if (e.id === editSubSectionTitle) {
                e.name = data.subSectionName
            }
            return e
        })
    
        setSubSectionsForTemplate(newSubSections)
        showMessage(strings.common.edited)
        setEditSubSectionTitle(-1)
        
        setValue('subSectionName', '')
    }

    const handleCloseEditSubSection = () => {
        setEditSubSectionTitle(-1)
    }

    const handleOpenModal = (subSection) => {
        setOpenModal(true)
        setSelectedSubSection(subSection.id)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleConfirm = subSection => {
        handleConfirmSection(subSection) 
        setOpenModal(false)
    }

    const handleClearSubSectionField = () => {
        setValue('subSectionName', '')
    }
    
    const onDragEnd = (result) => {
        if (!result.source || !result.destination) {
            return;
        }

        const stages = reorder(subSectionsForSection, result.source.index, result.destination.index);
        
        const stagesModified = stages.map((e, index) => {
            e.templateStepOrder = index
            return e
        })

        setSubSectionsForSection(sortByStepOrder(stagesModified))

        setSubSectionsForTemplate([
            ...subSectionsForSection,
            ...otherSubSections
        ])

        setSectionStages(sortById([...subSectionsForTemplate, ...sectionsForTemplate]))

        reorderSections(template.id, [...subSectionsForTemplate, ...sectionsForTemplate]).then(response => {
            if(!response || !response.ok) {
                return 
            }
        })
    }

    const renderSubSections = () => {
        let result = [];

        for (let subSection of subSectionsForSection) {

            if(search && !subSection.name.toLowerCase().includes(search.toLowerCase())) {
                continue
            }

            result.push(
                <Draggable
                    key={subSection.id}
                    draggableId={'substage-' + subSection.id}
                    index={result.length}
                >
                    
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >

                        <div key={'subsection-stage-' + result.length} className="template-page-item content-item">
                            <div {...provided.dragHandleProps} className="item-header">
                                <div className="item-title">

                                    { editSubSectionTitle === subSection.id
                                        ?
                                        <React.Fragment>
                                            <FormProvider {...form}>
                                                <div className="form-row form-row-with-inputs">
                                                    <div className="item input-wrap long-input-wrap">
                                                        <TextFieldControl
                                                            name='subSectionName'
                                                            control={data}
                                                            rules={{required: true}}
                                                            fullWidth
                                                            margin="normal"
                                                            error={Boolean(errors.subSectionName)}
                                                            helperText={errors.subSectionName && strings.forms.admin.adminTemplateForm.subSectionNameRequired}
                                                            placeholder={strings.forms.admin.adminTemplateForm.subSectionName}
                                                            inputRef
                                                        />
                                                    </div>
                                                </div>

                                                <Tooltip 
                                                    title={strings.forms.admin.adminTemplateForm.tooltips.save}
                                                    placement="top" 
                                                    arrow>
                                                    <IconButton className="item-icon save-icon" onClick={handleSubmit(submitEditSubSection)}>
                                                        <img src="/images/icons/save.png" />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip 
                                                    title={strings.forms.admin.adminTemplateForm.tooltips.cancel}
                                                    placement="top" 
                                                    arrow>
                                                    <IconButton className="item-icon" onClick={handleCloseEditSubSection}>
                                                        <img src="/images/icons/close.png" className="close" />
                                                    </IconButton>
                                                </Tooltip>

                                            </FormProvider>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <img className="title-icon" src="/images/icons/subsection-title-icon.png" />
                                            <h1 className="title">{subSection.name}</h1>

                                            <Tooltip 
                                                title={strings.forms.admin.adminTemplateForm.tooltips.editSubSectionName}
                                                placement="top" 
                                                arrow>
                                                <IconButton className="item-icon" onClick={() => handleEditSubSectionTitle(subSection)}>
                                                    <img src="/images/icons/pen.png" />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip 
                                                title={strings.forms.admin.adminTemplateForm.tooltips.addQuestion}
                                                placement="top" 
                                                arrow>
                                                <IconButton className="item-icon" onClick={() => handleEditSubSection(subSection)}>
                                                    <img src="/images/icons/question.png" />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip 
                                                title={strings.forms.admin.adminTemplateForm.tooltips.delete}
                                                placement="top" 
                                                arrow>
                                                <IconButton className="item-icon" onClick={() => handleOpenModal(subSection)}>
                                                    <img src="/images/icons/delete.png" />
                                                </IconButton>
                                            </Tooltip>
                                        </React.Fragment>
                                        }
                                    </div>

                                    {
                                        subSection.childrenCount > 0 &&
                                        <React.Fragment>
                                            {drop.toggle && subSection.id === drop.id
                                                ? <Tooltip 
                                                    title={strings.commonTooltips.collapse}
                                                    placement="top" 
                                                    arrow>
                                                    <RemoveIcon className="toggle-icon" onClick={() => handleDrop(subSection.id)}/>
                                                </Tooltip>
                                                : <Tooltip 
                                                    title={strings.commonTooltips.expand}
                                                    placement="top" 
                                                    arrow>
                                                    <AddIcon className="toggle-icon" onClick={() => handleDrop(subSection.id)}/>
                                                </Tooltip>
                                            }
                                        </React.Fragment>
                                    }
                                </div>

                                <Collapse in={drop.id === subSection.id && drop.toggle} timeout="auto" unmountOnExit>
                                    <div className="subitems">
                                        <QuestionList
                                            template={template}
                                            section={subSection}
                                            editor={editor}
                                            refreshSections={refreshSections}
                                        />
                                    </div>
                                </Collapse>
                            </div>

                            {openModal && selectedSubSection === subSection.id &&
                            <Modal
                                open={openModal}
                                onClose={handleCloseModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                className='confirm-modal-container'
                            >
                                <ConfirmModal
                                    title={strings.modals.adminTemplates.title}
                                    contentText={strings.modals.adminTemplates.confirmTextSubSection}
                                    cancelBtn={strings.modals.adminTemplates.cancelBtn}
                                    confirmBtn={strings.modals.adminTemplates.deleteBtn}
                                    handleCloseModal={handleCloseModal}
                                    handleConfirm={() => handleConfirm(subSection)}
                                />
                            </Modal>
                            }

                        </div>
                    )}
                </Draggable>
            )
        }
        return result;
    }

    return (
        <div className="tab-page-content admin-template">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable-container' direction="vertical">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {renderSubSections()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}

export default SubSections;