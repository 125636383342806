import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePageSizeState } from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import strings from "../../localization";
import { convertUTCToLocalTime, formatDateInText, formatDateWithTime } from '../../Util/DateUtil';
import { CardMedia, MenuItem, Modal, Tooltip } from '@mui/material';
import { getPodcasts, getVideos } from "../../Services/Content/ContentService";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPath } from "../../route";
import { getNotifications, readAllNotifications, setNotificationRead } from "../../Services/Notification/NotificationService";
import { reducerLogout, setAuth } from "../../Slices/AuthSlice";
import { clearUserData } from "../../Base/OAuth";
import { getSubscriptionData } from "../../Services/Subscribe/SubscribeService";
import { getContractCount } from "../../Services/Company/CompanyService";
import UserType from "../../Constants/User/UserType";
import WelcomeInfo from "../../Components/Modals/WelcomeInfo";
import { isTrial, isTrialPackageValid, isTrialUsed } from "../../Util/PermissionUtil";
import { discardFirstLogin } from "../../Services/User/UserService";
import ConfirmModal from "../../Components/Modals/ConfirmModal";

const Dashboard = () => {
    const dispatch = useDispatch();
    const [podcasts, setPodcasts] = useState([])
    const [videos, setVideos] = useState([])
    const user = useSelector((state) => state.auth.user)

    const navigate = useNavigate();

    const location = useLocation();
    const needAuth = checkPath(location.pathname);

    const [dashboardNotifications, setDashboardNotifications] = useState([]);
    const [openFirstSignupModal, setOpenFirstSignupModal] = useState(user?.firstLogin)
    const [openNonValidSubscription, setOpenNonValidSubscription] = useState(false);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        fetchData();

        if (location.state) {
            fetchLogin();
        }

    }, [])

    useEffect(() => {

        if ((!user || (user?.deleted && user?.enabled)) && needAuth) {
            navigate('/login')

            if (user?.deleted && user?.enabled) {
                clearUserData();
                dispatch(reducerLogout());
                navigate('/login');
            }
        }
    }, [user])

    const fetchLogin = () => {

        getContractCount().then(response => {
            if (!isTrialPackageValid(user, response.data) && !user?.firstLogin) {
                setOpenNonValidSubscription(true);
            }
        })
    }

    const fetchNotifications = () => {
        getNotifications({
            page: 0,
            perPage: 100,
        }).then(response => {

            let notifications = [];

            if (!response || !response.ok) {
                return;
            }


            response.data.map(notification => {
                notifications = [...notifications, notification]
            });

            setDashboardNotifications(notifications);
        });
    }

    const fetchData = () => {

        getVideos().then(response => {

            if (!response || !response.ok) {
                return
            }

            setVideos(response.data);
        })

        fetchNotifications();

        // getPodcasts().then(response => {

        //     if(!response || !response.ok) {
        //         return
        //     }

        //     setPodcasts(response.data);
        // })
    }

    const renderPodcasts = () => {
        let result = []

        for (let video of podcasts) {
            result.push(
                <div className="dashboard-item">

                    <div className="item-content">
                        <a href={video.acf.video_link} target={'_blank'}>
                            <h2 className="title">{video.title.rendered}</h2>
                        </a>
                        <span className="date">{formatDateInText(new Date(video.modified))}</span>
                        <div className="description" dangerouslySetInnerHTML={{ __html: video.content.rendered }} />
                    </div>
                </div>
            )
        } return result
    }

    const getNotReadNotifications = (notifications) => {

        if (!notifications) {
            return [];
        }

        return notifications.filter(x => !x.isRead);
    }

    const renderVideos = () => {
        let result = []

        if (videos === [] || videos?.data?.status === 401) {
            return result;
        }

        for (let video of videos) {
            result.push(
                <div className="video-container">

                    <CardMedia
                        allowfullscreen="allowfullscreen"
                        className="video"
                        component="iframe"
                        src={video.acf.video_link}
                        alt={video.title.rendered}
                    />

                    <h2 className="title">{video.title.rendered}</h2>
                    {/* <span className="date">{formatDateInText(new Date(video.modified))}</span> */}
                    <div className="description" dangerouslySetInnerHTML={{ __html: video.content.rendered }} />
                </div>
            )
        } return result
    }

    const handleClickOnNotification = (notification) => {
        if (!notification.isRead) {
            setNotificationRead(notification.id).then((response) => {

                if (!response || !response.ok) {
                    return;
                }

                fetchNotifications();
            });
        }
    }

    const renderNotifications = () => {
        let result = []

        for (let notification of dashboardNotifications) {

            result.push(
                <div onClick={() => handleClickOnNotification(notification)} className="notification">
                    <div className="content-wrap">
                        <div className="content">
                            <div className="profile">
                                {
                                    notification.fromUser &&
                                    <React.Fragment>
                                        {notification.fromUser.profileImage ?
                                            <img className="image" src={notification.fromUser.profileImage} /> :
                                            <img className="image" src={'images/icons/profile-default-small.png'} />
                                        }

                                        <Tooltip title={notification.fromUser.fullName}>
                                            <span className="name">{notification.fromUser.fullName}</span>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                                {
                                    !notification.fromUser &&
                                    <React.Fragment>
                                        <img className="image" src={'images/icons/profile-default-small.png'} />
                                        <Tooltip title={'Logical Contracts'}>
                                            <span className="name">Logical Contracts</span>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="text">
                                <p className="notification-text" dangerouslySetInnerHTML={{ __html: notification.title }} />
                                <span className={!notification.isRead ? "created active" : "created"}>
                                    {formatDateWithTime(convertUTCToLocalTime(new Date(notification.dateCreated)))}
                                </span>
                            </div>
                        </div>
                        <div className="notification-border" />
                    </div>

                    <div className={!notification.isRead ? "notification-dot active" : "notification-dot"} />
                </div>
            )
        }
        return result
    }

    const handleMarkAllAsRead = () => {
        readAllNotifications().then(() => {
            fetchNotifications();
        });
    }

    const closeFirstSignupModal = () => {

        discardFirstLogin().then(response => {

            if (!response || !response.ok) {
                return;
            }

            dispatch(setAuth(response.data));
        });

        setOpenFirstSignupModal(false)
    }

    return (
        <div className="tab-page-content">
            <div id="dashboard" className="dashboard dashboard-container">
                <div className="left dashboard-box">
                    <h1 className="header" style={{ marginBottom: 0 }}>{strings.pages.dashboard.dashboard.notifications}</h1>
                    <div className="notifications-header">
                        <span>{strings.header.recent}({getNotReadNotifications(dashboardNotifications).length})</span>
                        <span className="all-read-link" onClick={() => handleMarkAllAsRead()}>{strings.header.markAllAsRead}</span>
                    </div>
                    {renderNotifications()}
                </div>
                <div className="right dashboard-box">
                    <h1 className="header">{strings.pages.dashboard.dashboard.watchVideos}</h1>
                    <div className="videos-list">
                        {renderVideos()}
                    </div>
                </div>
            </div>

            <Modal
                open={openFirstSignupModal && user?.firstLogin}
                onClose={closeFirstSignupModal}
                className='confirm-modal-container'
            >
                <WelcomeInfo onClose={closeFirstSignupModal} />
            </Modal>

            {user && user.userProfileType === UserType.COMPANY_ADMIN &&
                <Modal
                    open={openNonValidSubscription}
                    onClose={() => setOpenNonValidSubscription(false)}
                    className='confirm-modal-container'
                >
                    <ConfirmModal
                        contentText={
                            isTrial(user) ? strings.modals.contractList.nonValidSubscription.confirmTextFreeTrial : strings.modals.contractList.nonValidSubscription.confirmTextSubscriptionEnd
                        }
                        confirmBtn={strings.modals.contractList.nonValidSubscription.ok}
                        handleCloseModal={() => setOpenNonValidSubscription(false)}
                        handleConfirm={() => { navigate('/dashboard'); setOpenNonValidSubscription(false) }}
                    />
                </Modal>}
            {user && user.userProfileType === UserType.COMPANY_USER &&
                <Modal
                    open={openNonValidSubscription}
                    onClose={() => setOpenNonValidSubscription(false)}
                    className='confirm-modal-container'
                >
                    <ConfirmModal
                        contentText={
                            !isTrialUsed(user) ? strings.modals.contractList.subscriptionRequired.freeTrialSubscriptionText : strings.modals.contractList.subscriptionRequired.subscriptionText
                        }
                        confirmBtn={strings.modals.contractList.ok}
                        handleConfirm={() => setOpenNonValidSubscription(false)}
                    />
                </Modal>}
        </div>
    )
}

export default Dashboard;