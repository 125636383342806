import React, { useContext, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form";
import strings from '../../../../../localization';
import FileUploadComponent from '../../../../Controls/FileUploadComponent';
import { Button } from "@mui/material";
import FileRenderComponent from './FileRenderComponent';
import LoaderContext from '../../../../../Context/LoaderContext';
import SnackbarContext from '../../../../../Context/SnackbarContext';
import { handleCovertFromHTMLtoPDF, handleCovertFromWordToHtml } from '../../../../../Util/CKEditorUtil';
import { uploadContractByFolderId } from '../../../../../Services/DealRoom/DataRoomService';


const FileUploadForm = (props) => {
    const uploadButtonText = strings.forms.contractList.upload;
    const form = useForm();
    const {
        data,
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = form;
    const { showLoader, setLoaderTitle } = useContext(LoaderContext);
    const { showMessage } = useContext(SnackbarContext);

    const [files, setFiles] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(true);


    const uploadFile = (newFiles) => {
        const duplicateFiles = newFiles.filter((newFile) =>
            files.some((existingFile) => existingFile.name === newFile.name)
        );

        if (duplicateFiles.length > 0) {
            showMessage("Duplicate files are not allowed", "error");
            return;
        }
        setFiles((prevFiles) => [...newFiles, ...prevFiles]);

        setDisabledSubmit([...newFiles, ...files].length === 0);
    };

    const onSubmit = async (data) => {
        if (!files) {
            return;
        }

        let formData = new FormData();
        setLoaderTitle("Please wait while your documents upload");
        showLoader(true);

        if (files?.length > 0) {
            files.forEach((file, index) => {
                console.log(file, index)
                formData.append(`files`, file);
            });
            await uploadContractByFolderId(props?.projectId, props?.folderId, formData).then((response) => {
                props.handleConfirm();
                console.log("response", response)
                showLoader(false);
            });
        }

        console.log("formData", formData);
    };
    return (
        <FormProvider {...form}>
            <div id="" className="confirm-modal document-upload-form">
                {!(props && (props.docName === "" || props.docName)) && (
                    <h1 className="title">
                        {strings.forms.contractList.documentForm.title}
                    </h1>
                )}

                <div className="file-upload-container">
                    <FileUploadComponent
                        accept=".pdf, .docx, .doc"
                        hideUploadButton={true}
                        onDrop={uploadFile}
                    />
                    <div className="document-list">
                        <FileRenderComponent
                            files={files}
                            setFiles={setFiles}
                        />
                    </div>
                </div>

                <div className="buttons-container">
                    {!(props && (props.docName === "" || props.docName)) && (
                        <Button
                            className="neutral-btn btn cancel-btn"
                            variant="contained"
                            onClick={props.handleCloseModal}
                        >
                            {strings.forms.contractList.cancel}
                        </Button>
                    )}
                    <Button
                        className="default-btn btn"
                        variant="contained"
                        // disabled={disabledSubmit || (props && props.docName === "")}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {props.buttonText || strings.forms.contractList.upload}
                    </Button>
                </div>
            </div>
        </FormProvider>
    )
}

export default FileUploadForm