import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'dataRoom',
    initialState: {
        index: {
            currentFolderId: '',
            parentFolderId: ''
        }
    },
    reducers: {
        setIndex: (state, action) => {
            state.index = action.payload
        },

    }
});

export const dataRoomActions = authSlice.actions


export default authSlice.reducer
