import {useState} from "react";
import TextField from "@mui/material/TextField";
import {Controller} from "react-hook-form";
import React from "react";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

const TextFieldPasswordControl = (props) => {

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    return <Controller
                name={props.name}
                control={props.data}
                defaultValue={props.defaultValue}
                rules={props.rules}
                render={({ field }) =>
                    <TextField {...field}
                        InputLabelProps={{ shrink: Boolean(field.value) }}
                        size={props.size ? props.size : 'small'}
                        fullWidth={props.fullWidth ? props.fullWidth : true}
                        type={ showPassword ? "text" : "password"}
                        margin={props.margin ? props.margin : 'normal'}
                        error={props.error}
                        helperText={props.helperText}
                        label={props.label}
                        placeholder={props.placeholder}
                        className="textfield-control"
                        InputProps={{ 
                            endAdornment: (
                                <InputAdornment className="textfield-icon password-icon" 
                                    onClick={() => handleClickShowPassword()} 
                                >
                                    { showPassword 
                                        ? <IconButton><img src="/images/icons/password-visible.png" /></IconButton>
                                        : <IconButton><img src="/images/icons/password-hidden.png" /></IconButton>
                                    }
                                </InputAdornment> 
                            ),
                            readOnly: props.readOnly ? props.readOnly : false
                        }}
                    />
                }
            />
}

export default TextFieldPasswordControl;