import React, { useEffect, useState } from 'react';
import { Box, Button } from "@mui/material";
import strings from '../../../../localization';
import styles from "../../../../Pages/Admin/AdminAIData/AIData.module.css"
import styles1 from "../../../../Pages/dealRoom/DealRoom.module.css"
import { DueDeligencGetAllDocument, DueDeligencTagDocument } from '../../../../Services/DealRoom/DealRoomService';
import { useParams } from 'react-router-dom'


const TagView = ({ handleCloseModal, questionRow, showMessage, getSectionListHandler }) => {
    const params = useParams()

    const [filesData, setFilesData] = useState([])
    const [taggedIds, setTaggedIds] = useState([])
    const [showFilesData, setShowFilesData] = useState([])
    const [searchText, setSearchText] = useState('')

    const dateConverter = (backendDateString) => {
        const date = new Date(backendDateString);

        // Function to pad single-digit numbers with leading zeros
        const padWithZero = (num) => num.toString().padStart(2, '0');

        const formattedDate = `${padWithZero(date.getDate())}/${padWithZero(date.getMonth() + 1)}/${date.getFullYear().toString().slice(-2)}`;

        return formattedDate
    }

    const idCollectHandler = (id) => {
        const findId = taggedIds?.includes(id)
        if (findId) {
            const filterIds = taggedIds?.filter((tagId) => tagId !== id)
            setTaggedIds(filterIds)
        }
        else {
            setTaggedIds([...taggedIds, id])
        }
    }

    const tagDocHandler = () => {
        let projectId = params?.id
        DueDeligencTagDocument(projectId, questionRow?.id, taggedIds)
            .then(res => {
                console.log("resssss", res)
                showMessage("Document tagged successfully!")
                getSectionListHandler()
                handleCloseModal()
            })
    }

    useEffect(() => {
        if(searchText){
            const tempData = filesData?.filter((file) => file.name.includes(searchText))
            setShowFilesData(tempData)
        }
        else{
            setShowFilesData(filesData)
        }

    }, [searchText,filesData])

    useEffect(() => {
        let projectId = params?.id
        DueDeligencGetAllDocument(projectId)
            .then(res => {
                console.log(res)
                setFilesData(res.data)
            })
    }, [])


    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={4}
            
        >
            <div
                className={styles.inputContainer}
                style={{ width: "50%" }}
            >
                <input
                    className={styles.inputBox}
                    value={searchText}
                    name="question"
                    type="text"
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Add the Question"
                />
            </div>
            <div className={styles1.tagDocContainer}>
                {showFilesData?.map((file) => {
                    const findId = taggedIds?.includes(file?.id)
                    return (
                        <div className={styles1.tagDocBox}
                            style={{ backgroundColor: findId ? "#82F5DE" : '#F8F8F9' }}
                            onClick={() => idCollectHandler(file?.id)} >
                            <label>{file?.name}</label>
                            <span>{dateConverter(file?.dateCreated)}</span>
                        </div>
                    )
                })}

            </div>

            <Button
                className="neutral-btn btn cancel-btn"
                variant="contained"
                onClick={taggedIds?.length > 0 ? tagDocHandler : handleCloseModal}
            >
                {taggedIds?.length > 0 ? "Tag Document" : strings.forms.contractList.cancel}
            </Button>

        </Box>

    )
}

export default TagView