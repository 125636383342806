import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import MenuState from "../../Constants/Base/MenuState";
import strings from "../../localization";
import SnackbarContext from "../../Context/SnackbarContext";
import TextFieldControl from './../../Components/Controls/Inputs/TextFieldControl';
import SelectControl from "../../Components/Controls/Inputs/SelectControl";
import { Button } from "@mui/material";
import HeaderButtons from '../../Components/Layout/HeaderButtons';
import List from './List';
import { getSortContractFilter } from './../../Constants/Contract/SortContract'
import ContractStatus, { getContractStatusFilter } from '../../Constants/Contract/ContractStatus';
import IconButton from '@mui/material/IconButton';
import Modal from "@mui/material/Modal";
import SimpleConfirmModal from './../../Components/Modals/SimpleConfirmModal';
import MoveToFolderModal from './../../Components/Modals/Pages/ContractList/MoveToFolderModal';
import ShareForm from '../../Components/Forms/Pages/ContractList/ShareForm';
import DocumentForm from '../../Components/Forms/Pages/ContractList/DocumentForm';
import { getAllFolderByParent, getAllFolders, getSharedFolders, moveFolderToFolder } from "../../Services/Folder/FolderService";
import {
  deleteContract,
  getAllContractByFolder,
  getAllContractsAndSharedsContractByUser,
  getContractByName,
  getContractCount,
  moveContract
} from "../../Services/Company/CompanyService";
import { getActiveContractContent } from "../../Services/Contract/ContractContentService";
import { getSubscriptionData } from "../../Services/Subscribe/SubscribeService";
import { isTrial, isTrialPackageValid } from "../../Util/PermissionUtil";
import { handleCovertFromHTMLtoWord, removeSuggestionTags } from "../../Util/CKEditorUtil";
import ConfirmModal from "../../Components/Modals/ConfirmModal";
import { useNavigate } from "react-router-dom";
import FoldersList from './FoldersList';
import SharedType from "../../Constants/Contract/SharedType";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import UserType from "../../Constants/User/UserType";
import ContractContentType from "../../Constants/Contract/ContractContentType";
import { debounce } from "lodash";
import LoaderContext from "../../Context/LoaderContext";

const contractStatusFilterTitle = { id: -1, name: strings.constants.contract.contractStatus.status }
const sortContractFilterTitle = { id: -1, name: strings.constants.contract.sortContract.sortBy }

const ContractList = () => {

  const menuState = useSelector((state) => state.navigation.menuState)
  const { showMessage } = useContext(SnackbarContext);
  const { showLoader, setLoaderTitle } = useContext(LoaderContext);

  const form = useForm();
  const { data, control, watch, handleSubmit, getValues, setValue, formState: { errors } } = form;
  const searchData = useWatch({ name: 'search', control })
  const [contracts, setContracts] = useState([])
  const [contractsForSearch, setContractsForSearch] = useState([])
  const [selectedContracts, setSelectedContracts] = useState([])
  const [contractStatusFilter, setContractStatusFilter] = useState([])
  const [sortContractFilter, setSortContractFilter] = useState([])
  const [folders, setFolders] = useState([])
  const [selectedFolder, setSelectedFolder] = useState({ name: 'Home', id: 0 })
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openMoveModal, setOpenMoveModal] = useState(false)
  const [openShareModal, setOpenShareModal] = useState(false)
  const [openShareModalContract, setOpenShareModalContract] = useState(false)
  const [openSubscribeWarningModal, setOpenSubscribeWarningModal] = useState(false)
  const [openDocumentModal, setOpenDocumentModal] = useState(false)
  const [contractsInFolder, setContractsInFolder] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [hasSubscription, setHasSubscription] = useState(false)
  const user = useSelector((state) => state.auth.user)
  const [openNonValidSubscription, setOpenNonValidSubscription] = useState(false);
  const [contractCount, setContractCount] = useState(0);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [contractStatus, setContractStatus] = useState(ContractStatus.ALL);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  // const searchTimeout = useRef(null);
  // const isFirstRun = useRef(true);
  // useEffect(() => {
  //     if (isFirstRun.current) {
  //         isFirstRun.current = false;
  //         return;
  //     }

  //     clearTimeout(searchTimeout.current);
  //     searchTimeout.current = setTimeout(() => {
  //         setContracts([]);       

  //         if(search) {
  //             searchContactsByName(search.toLowerCase()).then(response => {
  //                 setContracts(response.data)
  //             });
  //             return;
  //         }

  //         fetchContracts();

  //     }, 500);
  //   }, [search]);

  useEffect(() => {
    setSearch('');
    setContractStatus(ContractStatus.ALL);

    form.setValue('contractStatus', contractStatusFilterTitle);

    let result = [];
    result = buildBreadcrumbs(selectedFolder, result)
    setBreadcrumbs(result)
  }, [selectedFolder])

  useEffect(() => {
    getSubscriptionData().then(response => {

      if (response?.data?.product == null) {
        setHasSubscription(false)
      } else {
        setHasSubscription(true)
      }
    })
  }, [])

  useEffect(() => {

    if (selectAll) {
      setSelectedContracts(getContracts())
    } else {
      setSelectedContracts([])
    }

  }, [selectAll, search])

  useEffect(() => {
    fetchAllContractsAndShareds();

    setContractStatusFilter([
      contractStatusFilterTitle,
      ...getContractStatusFilter()
    ])

    setSortContractFilter([
      sortContractFilterTitle,
      ...getSortContractFilter()
    ])

  }, [])

  useEffect(() => {
    fetchFolders();
  }, [contracts])

  useEffect(() => {
    if (selectedFolder || searchData) {
      fetchContracts()
    }
  }, [searchData, selectedFolder])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {

      if (value.contractStatus) {
        setContractStatus(value.contractStatus.id)
      }

    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const buildBreadcrumbs = (folder, result) => {

    if (!folder) {
      return result;
    }

    if (!folder?.parent && folder.id !== -2 && folder.id !== -1) {
      let folderWithoutParent = folders.find(x => x.id === folder.id);

      if (folderWithoutParent?.parent) {
        folder = { ...folder, parent: folderWithoutParent?.parent };
      }
    }

    result.push(folder)

    return buildBreadcrumbs(folder.parent, result)
  }

  const fetchAllContractsAndShareds = () => {
    getAllContractsAndSharedsContractByUser().then(response => {
      if (!response || !response.data) {
        return;
      }

      setContractsForSearch(response.data)
    });
  }
  const fetchContracts = () => {

    getContractCount().then(response => {

      if (!response || !response.ok) {
        return
      }

      setContractCount(response.data);
    });

    if (!selectedFolder) {
      setContracts([]);
      return;
    }

    getAllContractByFolder(selectedFolder.id).then(response => {

      if (!response || !response.ok) {
        return;
      }
      setContracts(response.data);
    })
  }

  const fetchFolders = () => {

    getAllFolders().then(response => {

      if (!response || !response.ok) {
        return;
      }

      let mergedFolders = [{
        name: 'My Documents',
        id: -1,
        showAdd: true
      }, ...response.data, { name: 'Shared with me', id: -2, showAdd: true }];
      const sharedFolder = mergedFolders.find(x => x.id === -2);
      const myDocumentsFolder = mergedFolders.find(x => x.id === -1);

      filterContracts();

      getSharedFolders().then(sharedResponse => {

        let sharedFolders = [];

        Promise.all(sharedResponse.data.map(async shared => {
          const folderResponse = await getAllFolderByParent(shared.id);
          let folders = folderResponse.data.map(folder => ({ ...folder }));
          return [{ ...shared, inMyDocumentsFolder: false, inSharedFolder: true, parent: sharedFolder }, ...folders];
        })).then(results => {
          sharedFolders = results.flat();
          mergedFolders = [...mergedFolders, ...sharedFolders];

          mergedFolders = mergedFolders.map(folder => {
            if (folder.inSharedFolder) {
              return { ...folder, parent: sharedFolder };
            } else if (folder.inMyDocumentsFolder) {
              return { ...folder, parent: myDocumentsFolder };
            } else {
              return folder;
            }
          });

          setFolders(mergedFolders);
        });
      });
    })
  }

  const isOkBySearch = (contract, search) => {

    if (!search) {
      return true;
    }

    return contract.name.toLowerCase().includes(search.toLowerCase());
  }

  const filterContracts = () => {

    if (!selectedFolder) {
      return;
    }

    if (selectedFolder.id === -1) {
      setContractsInFolder(contracts.filter(x => !x.folder && isOkBySearch(x, searchData)))
      return;
    }

    setContractsInFolder(contracts.filter(x => (x.folder && x.folder.id === selectedFolder.id && isOkBySearch(x, searchData))))
    setSelectAll(false)
  }

  const handleOpenDocumentModal = () => {

    if (!isTrialPackageValid(user, getContracts().length)) {
      setOpenNonValidSubscription(true);
      return;
    }

    setOpenDocumentModal(true)
  }

  const handleCloseDocumentModal = () => {
    setOpenDocumentModal(false)
  }

  const handleConfirmDocumentModal = (data) => {
    setOpenDocumentModal(false)
    fetchContracts();
    fetchAllContractsAndShareds();
  }

  const handleOpenMoveModal = () => {

    if (!isTrialPackageValid(user, getContracts().length)) {
      setOpenNonValidSubscription(true);
      return;
    }

    setOpenMoveModal(true)
  }

  const handleMoveConfirm = (folder) => {
    setOpenMoveModal(false)

    for (let contract of selectedContracts) {
      moveContract({
        folderId: folder.id,
        contractId: contract.id
      }).then(response => {
        fetchContracts()
      })
    }

    if (selectedFolder.id == folder.id) {
      showMessage(strings.pages.contractList.contractMovedToItsFolderMsg + folder.name)
    } else {
      showMessage(strings.pages.contractList.contractMovedToFolderMsg + folder.name)
    }

    setSelectedContracts([]);
  }

  const handleCloseMoveModal = () => {
    setOpenMoveModal(false)
  }

  const handleOpenShareModalContract = () => {

    if (!isTrialPackageValid(user, getContracts().length)) {
      setOpenNonValidSubscription(true);
      return;
    }

    setOpenShareModalContract(true)
  }

  const handleCloseShareModalContract = () => {
    setOpenShareModalContract(false)
  }

  const handleOpenShareModal = () => {

    if (!isTrialPackageValid(user, getContracts().length)) {
      setOpenNonValidSubscription(true);
      return;
    }

    setOpenShareModal(true)
  }

  const handleCloseShareModal = () => {
    setOpenShareModal(false)
  }

  const handleCloseSubscribeWarningModal = () => {
    setOpenSubscribeWarningModal(false);
  }

  const handleDownload = async () => {
    if (selectedContracts.length === 0) {
      return
    }
    const validUntilDate = new Date(user.company.packageValidUntil)
    const currentDate = new Date();
    if (isTrial(user) || (validUntilDate < currentDate)) {
      setOpenSubscribeWarningModal(true);
      return
    }

    for (let contract of selectedContracts) {

      await getActiveContractContent(contract.id).then(async response => {

        if (!response || !response.ok) {
          return;
        }

        if (contract.contentType === ContractContentType.UPLOADED || contract.state > ContractStatus.PENDING_REVIEW) {

          let pdfData = response.data.uploadedContent;

          if (contract.state > ContractStatus.REVIEWED) {
            pdfData = response.data.uploadedContentSignature
          } else {
            pdfData = response.data.uploadedContentSignaturePlaceholder
          }

          await fetch(pdfData).then(b => b.blob()).then(r => {
            const file = new Blob([r], { type: 'application/pdf' })
            const fileURL = URL.createObjectURL(file)

            const pdfWindow = window.open()
            pdfWindow.location.href = fileURL
          });
        } else {
          let responseConvert = await handleCovertFromHTMLtoWord(removeSuggestionTags(response.data.content), contract.name);
          const blob = await responseConvert.blob();
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', contract?.name + '.docx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // await fetch(process.env.REACT_APP_CONVERTER_API + 'api/converters/htmlToPDF?format=json', {
          //     method: 'POST',
          //     headers: {'Content-Type': 'application/json'},
          //     body: JSON.stringify({
          //         content: response.data.content
          //     })
          // })
          //     .then(blob => blob.blob())
          //     .then(response => {

          //         const file = new Blob([response], {type: 'application/pdf'})
          //         const fileURL = URL.createObjectURL(file)
          //         window.open(fileURL);
          // })
        }
      })

    }
  }

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteConfirm = () => {

    setOpenDeleteModal(false)

    for (let contract of selectedContracts) {
      deleteContract(contract.id).then(response => {
        fetchContracts()
        fetchAllContractsAndShareds();
        showMessage(strings.common.deleted)
      })
    }

    setSelectedContracts([]);
  }

  const getHeaderClass = (menuState) => {

    if (menuState === MenuState.SHORT) {
      return 'short';
    }

    return '';
  }

  const canDeleteContracts = () => {

    for (let contract of selectedContracts) {
      if (contract?.user.id !== user?.id) {
        return false;
      }
    }

    return true;
  }

  const getContracts = () => {
    if (search || (contractStatus && contractStatus !== ContractStatus.ALL)) {

      if (contractStatus && contractStatus !== ContractStatus.ALL) {
        return contractsForSearch.filter(x =>
          x.name.toLowerCase().includes(search.toLowerCase()) &&
          x.state === contractStatus
        );
      }

      return contractsForSearch.filter(x =>
        x.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    return contracts;
  }

  const clearSelectedContracts = () => {
    setSelectedContracts([])
  }

  const renderBreadcrumbs = (breadcrumbs) => {
    let result = [];

    if (!breadcrumbs) {
      return ''
    }

    for (let i = breadcrumbs.length - 1; i >= 0; i--) {

      if (i === 0) {
        result.push(<span key={'breadcrumbs-' + i}>{breadcrumbs[i].name} </span>)
      } else {
        result.push(<span key={'breadcrumbs-' + i}>{breadcrumbs[i].name} &gt; </span>)
      }
    }

    return result
  }

  const onDragEnd = (result) => {

    if (result.source.droppableId.includes('contracts') && result.destination.droppableId.includes('folder')) {
      if (result.destination.index === -2) {
        showMessage('Unable to move to shared with me folder', 'error')
        return
      }

      if (result.destination.index === -1) {
        showMessage('Unable to move to my documents folder', 'error')
        return
      }

      moveContract({
        folderId: result.destination.index,
        contractId: result.source.index
      }).then(response => {
        const folder = folders.find(x => x.id === result.destination.index)
        if (folder) {
          showMessage('Moved to folder ' + folder.name)
        }
        fetchContracts();
      });
    }

    if (result.source.droppableId.includes('folder-') && result.destination.droppableId.includes('folder-')) {
      if (result.destination.index === -2) {
        showMessage('Unable to move to shared with me folder', 'error')
        return
      }

      if (result.destination.index === -1) {
        showMessage('Unable to move to my documents folder', 'error')
        return
      }

      if (result.source.index !== result.destination.index) {
        moveFolderToFolder(result.source.index, result.destination.index).then(response => {
          const folder = folders.find(x => x.id === result.destination.index)
          if (folder) {
            showMessage('Moved to folder ' + folder.name)
          }
          fetchFolders();
        });
      }
    };
  }

  return (
    <FormProvider {...form}>
      <React.Fragment>
        <div id="header" className={getHeaderClass(menuState)}>
          <div>
            <h1>{strings.pages.contractList.title}</h1>
          </div>
          <HeaderButtons />
        </div>

        <div id="contract-list" className="contract-list-container">

          <div className="folders-and-contracts">
            <DragDropContext onDragEnd={onDragEnd}>
              <FoldersList clearSelectedContracts={clearSelectedContracts} contracts={getContracts()}
                fetchContracts={fetchContracts} setParentSelectedFolder={setSelectedFolder}
                contractCount={contractCount} folders={folders}
                fetchFolders={fetchFolders} shareFolder={handleOpenShareModal}
                setOpenNonValidSubscription={setOpenNonValidSubscription} />

              <div className={getContracts().length === 0 ? 'contract-list-wrapper empty' : 'contract-list-wrapper'}>
                {renderBreadcrumbs(breadcrumbs)}
                <div className="subheader">
                  <Checkbox
                    className='checkbox'
                    checked={selectAll}
                    onChange={(e) => setSelectAll(!selectAll)}
                  />
                  {selectedContracts.length > 0 &&
                    <div className="buttons-container">
                      <Tooltip title={strings.pages.contractList.moveToFolder}>
                        <Button className="custom-btn folder-btn"
                          startIcon={<IconButton><img
                            src="/images/icons/folder.png" /></IconButton>}
                          variant="contained" onClick={handleOpenMoveModal}>
                        </Button>
                      </Tooltip>

                      <Tooltip title={strings.pages.contractList.share}>
                        <Button className="custom-btn"
                          startIcon={<IconButton><img
                            src="/images/icons/share.png" /></IconButton>}
                          variant="contained" onClick={handleOpenShareModalContract}>
                        </Button>
                      </Tooltip>

                      <Tooltip title={strings.pages.contractList.download}>
                        <Button disabled={isTrial(user)} className="custom-btn download-btn"
                          startIcon={<IconButton><img
                            src="/images/icons/download.png" /></IconButton>}
                          variant="contained" onClick={handleDownload}>
                        </Button>
                      </Tooltip>

                      <Tooltip title={strings.pages.contractList.delete}>
                        <Button className="custom-btn delete-btn"
                          // disabled={!canDeleteContracts()}
                          startIcon={<IconButton><img
                            src="/images/icons/delete.png" /></IconButton>}
                          variant="contained" onClick={handleOpenDeleteModal}>
                        </Button>
                      </Tooltip>

                    </div>
                  }
                  <div className="filters-container">

                    <div className='filter-serach-item'>
                      <TextField
                        name='search'
                        className='textfield-control'
                        control={data}
                        defaultValue=''
                        margin="normal"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value)
                        }}
                        placeholder={strings.pages.contractList.search}
                        icon={
                          <IconButton><img src="/images/icons/search.png" /></IconButton>
                        }
                      />
                    </div>

                    {
                      (user?.company?.package?.dataRoomNoSignature || user?.userProfileType === UserType.SYSTEM_ADMIN || user?.userProfileType === UserType.PORTAL_ADMIN) &&
                      <Button disabled={isTrial(user)} className="neutral-btn btn upload-btn"
                        startIcon={<IconButton><img
                          src="/images/attachment.png" /></IconButton>}
                        variant="contained" onClick={handleOpenDocumentModal}>
                        <span
                          className="btn-txt">{strings.pages.contractList.uploadDocument}</span>
                      </Button>
                    }


                    {/* <div className='filter-item frameless-filter-item sort-filter'>
                                            <SelectControl
                                                setValue={setValue}
                                                name='sortContract'
                                                options={sortContractFilter}
                                                nameKey='name'
                                                valueKey='id'
                                                defaultValue={sortContractFilterTitle}
                                                className="select-control-container"
                                            />
                                        </div> */}

                    <div className='desktop-filter-item' style={{ marginLeft: '20px' }}>
                      <SelectControl
                        setValue={setValue}
                        name='contractStatus'
                        options={contractStatusFilter}
                        nameKey='name'
                        valueKey='id'
                        defaultValue={contractStatusFilterTitle}
                        className="select-control-container"
                      />
                    </div>
                  </div>
                  <div className='mobile-filter-item'>
                    <SelectControl
                      setValue={setValue}
                      name='contractStatus'
                      options={contractStatusFilter}
                      nameKey='name'
                      valueKey='id'
                      defaultValue={contractStatusFilterTitle}
                      className="select-control-container"
                    />
                  </div>
                </div>
                <Droppable droppableId={'contracts-droppable'} direction="vertical">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <List
                        folderContracts={getContracts()}
                        selectedFolder={selectedFolder}
                        selectedContracts={selectedContracts}
                        setSelectedContracts={setSelectedContracts}
                        setSelectAll={setSelectAll}
                        hasSubscription={true}
                        handleOpenMoveModal={handleOpenMoveModal}
                        handleOpenShareModal={handleOpenShareModal}
                        handleDownload={handleDownload}
                        handleOpenDeleteModal={handleOpenDeleteModal}
                      />
                    </div>
                  )}
                </Droppable>

              </div>
            </DragDropContext>
          </div>
        </div>

        <Modal
          open={openDocumentModal}
          onClose={handleCloseDocumentModal}
          className='confirm-modal-container'
        >
          <DocumentForm
            selectedFolder={selectedFolder}
            folders={folders}
            handleCloseModal={handleCloseDocumentModal}
            handleConfirm={handleConfirmDocumentModal}
            mergedDocument={false}
          />
        </Modal>

        <Modal
          open={openMoveModal}
          onClose={handleCloseMoveModal}
          className='confirm-modal-container'
        >
          <MoveToFolderModal
            handleCloseModal={handleCloseMoveModal}
            folders={folders}
            handleConfirm={handleMoveConfirm}
          />
        </Modal>

        <Modal
          open={openShareModal}
          onClose={handleCloseShareModal}
          className='confirm-modal-container'
        >
          <ShareForm
            shareMode={SharedType.FOLDER}
            handleCloseModal={handleCloseShareModal}
            selectedContracts={selectedContracts}
            selectedFolder={selectedFolder}
          />
        </Modal>

        <Modal
          open={openShareModalContract}
          onClose={handleCloseShareModalContract}
          className='confirm-modal-container'
        >
          <ShareForm
            shareMode={SharedType.CONTRACT}
            handleCloseModal={handleCloseShareModalContract}
            selectedContracts={selectedContracts}
            selectedFolder={selectedFolder}
          />
        </Modal>

        <Modal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          className='confirm-modal-container'
        >
          <SimpleConfirmModal
            title={strings.modals.contractList.deleteContract.confirmText}
            cancelBtn={strings.modals.contractList.deleteContract.no}
            confirmBtn={strings.modals.contractList.deleteContract.yes}
            handleCloseModal={handleCloseDeleteModal}
            handleConfirm={handleDeleteConfirm}
          />
        </Modal>

        <Modal
          open={openSubscribeWarningModal}
          onClose={handleCloseSubscribeWarningModal}
          className='confirm-modal-container'
        >
          <SimpleConfirmModal
            title={strings.modals.contractList.subscribeWarningText}
            confirmBtn={strings.modals.contractList.ok}
            handleCloseModal={handleCloseSubscribeWarningModal}
            handleConfirm={() => {
              handleCloseSubscribeWarningModal();
              navigate('/contract-list');
            }}
          />
        </Modal>

        <Modal
          open={openNonValidSubscription}
          onClose={() => setOpenNonValidSubscription(false)}
          className='confirm-modal-container'
        >
          <ConfirmModal
            contentText={
              isTrial(user) ? strings.modals.contractList.nonValidSubscription.confirmTextFreeTrial : strings.modals.contractList.nonValidSubscription.confirmTextSubscriptionEnd
            }
            cancelBtn={strings.modals.contractList.nonValidSubscription.no}
            confirmBtn={strings.modals.contractList.nonValidSubscription.yes}
            handleCloseModal={() => setOpenNonValidSubscription(false)}
            handleConfirm={() => navigate('/pricing-and-package')}
          />
        </Modal>

      </React.Fragment>
    </FormProvider>
  )
}

export default ContractList;