import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clauseAllChoices: [],
};

export const clauseAllChoicesSlice = createSlice({
  name: "clauseAllChoices",
  initialState,
  reducers: {
    setClauseAllChoices: (state, action) => {
      state.clauseAllChoices = action.payload;
    },
  },
});

export const { setClauseAllChoices } = clauseAllChoicesSlice.actions;

export default clauseAllChoicesSlice.reducer;
