import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import ReactHtmlParser from "react-html-parser";
import ReactDOMServer from "react-dom/server";
import strings from "../../localization";
import {
  getContract,
  deleteContract,
  moveContract,
  editContract,
  changeContractState,
  getContractCount,
} from "../../Services/Company/CompanyService";
import ContractUserType from "../../Constants/Contract/ContractUserType";
import { useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { Editor } from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SnackbarContext from "../../Context/SnackbarContext";
import DetailsForm from "./../../Components/Forms/Pages/ContractList/DetailsForm";
import CommentList from "./CommentList";
import CollaboratorsForm from "./../../Components/Forms/Pages/ContractList/CollaboratorsForm";
import FoldersForm from "./../../Components/Forms/Pages/ContractList/FoldersForm";
import HistoryList from "./HistoryList";
import SimpleConfirmModal from "./../../Components/Modals/SimpleConfirmModal";
import IconButton from "@mui/material/IconButton";
import {
  getActiveContractContent,
  saveContract,
  saveContractMetadata,
  saveSuggestion,
} from "../../Services/Contract/ContractContentService";
import { Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ContractStatus from "../../Constants/Contract/ContractStatus";
import { isTrial, isTrialPackageValid } from "../../Util/PermissionUtil";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../Components/Modals/ConfirmModal";
import {
  esignContract,
  addContractUser,
  getAllContractUsersByContract,
  getAllContractOwnersByContract,
  placePlaceholderContractUser,
} from "../../Services/Contract/ContractUserService";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Menu,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import ContractContentType from "../../Constants/Contract/ContractContentType";
import LoaderContext from "../../Context/LoaderContext";
import UserType from "../../Constants/User/UserType";
import AdoptSignatureForm from "../../Components/Forms/Pages/ContractList/AdoptSignatureForm";
import { useElementSize } from "usehooks-ts";
import MenuState from "../../Constants/Base/MenuState";
import { changeMenuState } from "../../Slices/NavigationSlice";
import { TrackChangesIntegration } from "../../Util/CKEditorUtil";
import AIChat from "../AI/AIChat";
import { ResizableBox } from "react-resizable";
import { getUserFromLocalStorage } from "../../Base/OAuth";
import {
  addComment,
  deleteComment,
  editComment,
  changeCommentResolve,
} from "../../Services/Contract/CommentService";

let dummyConversationsExample = [
  {
    id: 1,
    name: "Can you draft me a Pre-emptive Rights and Anti-dilution clause for a shareholders agreement. This agreement is for a company with the Fintech industy.",
  },
  {
    id: 2,
    name: "Draft me a non-compete clause for an employment agreement. The hiring company as a builder.",
  },
  {
    id: 3,
    name: "Can you draft me a termination clause for a terms and conditions agreement? The company sales goods on their E-commerce website.",
  },
];

const ContractPreview = () => {
  let { id } = useParams();
  const user = useSelector((state) => state.auth.user);

  const menuState = useSelector((state) => state.navigation.menuState);
  const [contract, setContract] = useState(null);
  const navigate = useNavigate();
  const [editor, setEditor] = useState(null);
  const { showMessage } = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const [tempPDFContent, setTempPDFContent] = useState();
  const [signMode, setSignMode] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openInfoModalText, setOpenInfoModalText] = useState("");
  const [detailsTab, setDetailsTab] = useState(true);
  const [commentsTab, setCommentsTab] = useState(false);
  const [collaboratorsTab, setCollaboratorsTab] = useState(false);
  const [foldersTab, setFoldersTab] = useState(false);
  const [historyTab, setHistoryTab] = useState(false);
  const [downloadTab, setDownloadTab] = useState(false);
  const [deleteTab, setDeleteTab] = useState(false);
  const [displayForms, setDisplayForms] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [content, setContent] = useState("");
  const [pdfContent, setPDFContent] = useState("");
  const [contractCount, setContractCount] = useState(0);
  const [openNonValidSubscription, setOpenNonValidSubscription] =
    useState(false);
  const [owners, setOwners] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pdfScale, setPdfScale] = useState(1.3);
  const pdfViewer = useRef();
  const [placeAllSignatures, setPlaceAllSignatures] = useState(false);
  const [acceptReviewOpen, setAcceptReviewOpen] = useState(false);
  const [placePDFSignaturePlaceholder, setPlacePDFSignaturePlaceholder] =
    useState(false);
  const [placePDFNamePlaceholder, setPlacePDFNamePlaceholder] = useState(false);
  const [placePDFDatePlaceholder, setPlacePDFDatePlaceholder] = useState(false);
  const [addTextPlaceholder, setAddTextPlaceholder] = useState(false);
  const [placePDFSignature, setPlacePDFSignature] = useState(false);
  const [collaboratorForPlacement, setCollaboratorForPlacement] =
    useState(null);
  const [openAdoptSignModal, setOpenAdoptSignModal] = useState(false);
  const [openAiAssistModal, setOpenAiAssistModal] = useState(false);
  const [showSignBanner, setShowSignBanner] = useState(true);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });
  const [squareRef, size] = useElementSize();
  const [canvasLeftPosition, setCanvasLeftPosition] = useState(0);
  const [currentCanvasLeftPosition, setCurrentCanvasLeftPosition] = useState(0);
  const [forceValue, setForceValue] = useState(0);
  const { showLoader, setLoaderTitle } = useContext(LoaderContext);
  const [readyAutoSave, setReadyAutoSave] = useState(true);
  const [isUserReviewer, setIsUserReviewer] = useState(false);
  const [signatoryColor, setSignatoryColor] = useState({});
  const [isOpenDisplayForm, setIsOpenDisplayForm] = useState(false);
  const [isOpenDisplayFormAndSidebar, setIsOpenDisplayFormAndSidebar] =
    useState(false);
  const [numberPagesToRender, setNumberPagesToRender] = useState(0);
  const [contractContentLoading, setContractContentLoading] = useState(true);
  const [conversationsExample, setConversationsExample] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const aiAssistModal = useRef(null);
  const [removedPermissioModal, setRemovedPermissioModal] = useState(false);
  const [isOpenContractOptionMenu, setIsOpenContractOptionMenu] =
    useState(false);
  const [signState, setSignState] = useState(false);
  const [signatures, setSignatures] = useState([]);
  const [boxPosition, setBoxPosition] = useState({ x: 10, y: 10 });
  const [rectangles, setRectangles] = useState([]);
  const [isOpenCommentSideBar, setIsOpenCommentSideBar] = useState(false);
  const [channelId, setChannelId] = useState("");
  const [activeThreadId, setActiveThreadId] = useState("");
  const [showAddSignatureButton, setShowAddSignatureButton] = useState(true);
  const [isPdfContentUpdated, setIsPdfContentUpdated] = useState(false);
  const [signedPageNumber, setSignedPageNumber] = useState(null);
  const [docChange, setDocChange] = useState(false);
  const [showLocalLoader, setShowLocalLoader] = useState(false);
  const [showLocalLoaderText, setShowLocalLoaderText] = useState("");
  const[chatID,setChatID] = useState('')

  const addRectangle = (text) => {
    const newRectangle = {
      id: rectangles.length + 1,
      x: 10,
      y: 10,
      width: 150,
      height: 80,
      text: text,
    };
    setRectangles([newRectangle]);
  };

  const updatePdfContent = async (boxPlacement) => {
    try {
      const pdfDoc = await PDFDocument.load(pdfContent);
      const pngImage = await pdfDoc.embedPng(user.signature);
      const pages = pdfDoc.getPages();
      const page = pages[boxPlacement.pageNumber - 1];
      const ratio = pngImage.width / pngImage.height;
      const heightImg = 23;
      const fontSize = 8;
      const widthImg = ratio * heightImg;
      const { height } = page.getSize();
      const scaledWidth = widthImg / 1.3;
      const x = boxPlacement.x;
      const y = boxPlacement.y;
      const imgX = x * (1 / 2) - scaledWidth / 2;
      const imgY = height - y * (1 / 2) - heightImg / 2;
      let font = await pdfDoc.embedFont(StandardFonts.Helvetica);

      for (const rect of rectangles) {
        let { width, text } = rect;
        width = text.split("").length * 6 + 10;

        page.drawText(text, {
          x: imgX,
          y: imgY + 10,
          font: font,
          size: fontSize * 1.5,
          color: rgb(0, 0, 0),
        });

        page.drawRectangle({
          x: imgX - 5,
          y: imgY,
          width: width,
          height: fontSize * 1.5 + 15,
          borderColor: rgb(0, 0, 0),
          borderWidth: 1,
        });
      }
      const pdfBytes = await pdfDoc.saveAsBase64();
      setPDFContent("data:application/pdf;base64," + pdfBytes);
      setIsPdfContentUpdated(true);
    } catch (error) {
      console.error("Error updating PDF content:", error);
    }
  };

  const forceRender = () => {
    setForceValue(forceValue + 1);
  };

  const handleScroll = () => {
    const wrappers = document.getElementsByClassName("react-pdf__Page__canvas");

    const visibleWrappers = Array.from(wrappers).filter((wrapper) => {
      const rect = wrapper.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const visibleHeight = windowHeight * 0.5;
      return rect.top >= -visibleHeight && rect.bottom;
    });

    if (visibleWrappers.length > 0 && visibleWrappers.length === 2) {
      let difference = numPages - wrappers.length;
      if (difference > 0) {
        if (difference > 10) {
          setNumberPagesToRender(wrappers.length + 10);
          return;
        }
        setNumberPagesToRender(numPages);
      }
    }
  };

  useEffect(() => {
    const saveContractAndUpdate = async () => {
      if (isPdfContentUpdated) {
        const response = await saveContract(contract.id, {
          content: content,
          uploadedContent: pdfContent,
          metadata: JSON.stringify(contract.metadata),
          force: true,
          userId: user.id,
        });
        setIsPdfContentUpdated(false);
      }
    };
    saveContractAndUpdate();
  }, [isPdfContentUpdated, pdfContent]);

  useEffect(() => {
    if (numPages > 0) {
      setNumberPagesToRender(numPages > 10 ? 10 : numPages);
      document
        .querySelector(".contract-container")
        .addEventListener("scroll", handleScroll);
      return () =>
        document
          .querySelector(".contract-container")
          .removeEventListener("scroll", handleScroll);
    }
  }, [numPages]);

  const debounce = useCallback((fn, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      dispatch(changeMenuState(MenuState.SHORT));
      updateCanvasLeftPosition();
    });

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [debounce]);

  useEffect(() => {
    setConversationsExample(dummyConversationsExample);

    dispatch(changeMenuState(MenuState.SHORT));
    fetchContract();
  }, []);

  useEffect(() => {
    return () => {
      // if(editor && editor?.getData()) {
      //     saveContract(contract.id, {
      //         content: editor.getData(),
      //         uploadedContent: '',
      //         metadata: JSON.stringify(contract.metadata),
      //         force: false,
      //         userId: user.id
      //     });
      // }
    };
  }, [editor]);

  useEffect(() => {
    if (menuState === 2 && !displayForms) {
      setCurrentCanvasLeftPosition(canvasLeftPosition);
    }
  }, [canvasLeftPosition]);

  useEffect(() => {
    if (displayForms) {
      setIsOpenDisplayForm(true);
      if (menuState === 1) {
        setIsOpenDisplayFormAndSidebar(true);
        setCurrentCanvasLeftPosition(canvasLeftPosition - 350);
      } else {
        setIsOpenDisplayFormAndSidebar(false);
        setCurrentCanvasLeftPosition(canvasLeftPosition - 283);
      }
      return;
    }

    if (isOpenDisplayForm && !displayForms) {
      setIsOpenDisplayForm(false);
      updateCanvasLeftPosition();
    }

    if (menuState === 1 && !displayForms) {
      setCurrentCanvasLeftPosition(canvasLeftPosition - 67.5);
    }

    if (menuState === 2 && !displayForms) {
      if (isOpenDisplayFormAndSidebar) {
        setCurrentCanvasLeftPosition(canvasLeftPosition + 67.5);
        setCanvasLeftPosition(canvasLeftPosition + 67.5);
        setIsOpenDisplayFormAndSidebar(false);
        return;
      }
      setCurrentCanvasLeftPosition(canvasLeftPosition);
    }
  }, [menuState, displayForms]);

  // useEffect(() => {
  //     const interval = setInterval(() => {
  //         if(readyAutoSave) {
  //             if(!isTrialPackageValid(user, contractCount) || !editor?.getData()) {
  //                 return;
  //             }
  //             save(false);
  //             setTime(Date.now())
  //         }
  //     }, 10000);
  //     return () => {
  //         clearInterval(interval);
  //     };
  // }, [readyAutoSave, editor])

  const isOpenCommentSideBarHandler = () => {
    // const annotationsUIs = editor.plugins.get('AnnotationsUIs');

    // // Deactivate all UIs first as the `activate()` method might not deactivate all UIs.
    // annotationsUIs.deactivateAll();

    // annotationsUIs.activate('wideSidebar', annotation => annotation.type === 'comment');
    // annotationsUIs.activate('inline', annotation => annotation.type !== 'comment');

    if (isOpenCommentSideBar) {
      editor.plugins.get("AnnotationsUIs").switchTo("narrowSidebar");
    } else {
      editor.plugins.get("AnnotationsUIs").switchTo("wideSidebar");
    }

    setIsOpenCommentSideBar(!isOpenCommentSideBar);
  };

  const bgColorsForComments = [
    "#ffb3ba",
    "#ffdfba",
    "#ffffba",
    "#baffc9",
    "#bae1ff",
  ];
  const bgColorsForActiveComments = [
    "##e5a1a7",
    "##e5c8a7",
    "#e5e5a7",
    "#a7e5b4",
    "#a7cae5",
  ];

  useEffect(() => {
    const initializeCommentColor = () => {
      const commentsRepository = editor.plugins.get("CommentsRepository");

      const commentThreadsData = commentsRepository.getCommentThreads({
        skipNotAttached: true,
        skipEmpty: true,
        toJSON: true,
      });

      const authorIds = [];

      commentThreadsData.forEach((thread) => {
        const findAuthorId = authorIds.find(
          (authorId) => authorId === thread.comments[0].authorId
        );
        if (!findAuthorId) {
          authorIds.push(thread.comments[0].authorId);
        }
      });

      let authorAndIds = [];

      authorIds.forEach((authorId) => {
        const authorAndId = { author: authorId, threadIds: [] };
        authorAndIds.push(authorAndId);
      });

      commentThreadsData.forEach((thread) => {
        authorAndIds.forEach((authorAndId) => {
          if (authorAndId.author === thread.comments[0].authorId) {
            authorAndId.threadIds.push(thread.threadId);
          }
        });
      });

      let idBackgroundColorMap = {};
      let idActiveBackgroundColorMap = {};

      authorAndIds.forEach((data, index) => {
        data?.threadIds?.forEach((id) => {
          idBackgroundColorMap[id] = bgColorsForComments[index];
          idActiveBackgroundColorMap[id] = bgColorsForActiveComments[index];
        });
      });

      const targetSpans = document.querySelectorAll(
        ".ck-comment-marker[data-comment]"
      );

      targetSpans.forEach((span) => {
        const id = span.getAttribute("data-comment");
        const backgroundColor = idBackgroundColorMap[id] || "white"; // Default color if not found in the map

        if (span.classList.contains("ck-comment-marker--active")) {
          const activeBackgroundColor =
            idActiveBackgroundColorMap[id] || backgroundColor;
          span.style.backgroundColor = activeBackgroundColor;
        } else {
          span.style.backgroundColor = backgroundColor; // Apply the background color to the span
        }
      });
    };

    const commentMarkers = document.querySelectorAll(".ck-content");

    // Add event listener to each element
    commentMarkers.forEach((marker) => {
      marker.addEventListener("click", initializeCommentColor);
    });
    const changesTrackers = document.querySelectorAll(".ck-suggestion-marker");

    // Add event listener to each element
    changesTrackers.forEach((change) => {
      change.addEventListener("click", initializeCommentColor);
    });

    if (editor) {
      setTimeout(() => {
        initializeCommentColor();
      }, 1000);
    }

    return () => {
      commentMarkers.forEach((marker) => {
        marker.removeEventListener("click", initializeCommentColor);
      });
      changesTrackers.forEach((change) => {
        change.addEventListener("click", initializeCommentColor);
      });
    };
  }, [editor, docChange]);

  useEffect(() => {
    const initializeEditor = () => {
      const commentsRepository = editor.plugins.get("CommentsRepository");

      const allthreadss = commentsRepository.getCommentThreads();

      let thread;
      if (allthreadss[0]?.id) {
        thread = commentsRepository.getCommentThread(allthreadss[0]?.id);
      }

      commentsRepository.on(
        `addComment:${channelId}:${activeThreadId}`,
        (evt, data) => {
          let saveData = {
            id: activeThreadId,
            contractId: contract.id,
            content: data.content,
            contractContent: "",
            userId: user?.externalUserInviteID ? -1 : user.id,
            externalUserFullName: user?.externalUserInviteID
              ? user.fullName
              : null,
            externalUserEmail: user?.externalUserInviteID ? user.email : null,
          };

          addComment(saveData)
            .then((res) => {
              console.log("resp", res);
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      );

      commentsRepository.on(
        `removeComment:${channelId}:${activeThreadId}`,
        (evt, data) => {
          let saveData = {
            contractId: contract.id,
            content: data.content,
            userId: user?.externalUserInviteID ? -1 : user.id,
            externalUserFullName: user?.externalUserInviteID
              ? user.fullName
              : null,
            externalUserEmail: user?.externalUserInviteID ? user.email : null,
          };
          deleteComment(activeThreadId, saveData)
            .then((res) => {
              console.log("delete resp", res);
            })
            .catch((err) => {
              console.log("delete err", err);
            });
        }
      );

      commentsRepository.on(`removeCommentThread:${channelId}`, (evt, data) => {
        let saveData = {
          contractId: contract.id,
          content: data.content,
          userId: user?.externalUserInviteID ? -1 : user.id,
          externalUserFullName: user?.externalUserInviteID
            ? user.fullName
            : null,
          externalUserEmail: user?.externalUserInviteID ? user.email : null,
        };
        deleteComment(activeThreadId, saveData)
          .then((res) => {
            console.log("delete resp", res);
          })
          .catch((err) => {
            console.log("delete err", err);
          });
      });

      commentsRepository.on(
        `resolveCommentThread:${channelId}`,
        (evt, data) => {
          let saveData = {
            contractId: contract.id,
            content: data.content,
            userId: user?.externalUserInviteID ? -1 : user.id,
            externalUserFullName: user?.externalUserInviteID
              ? user.fullName
              : null,
            externalUserEmail: user?.externalUserInviteID ? user.email : null,
          };
          changeCommentResolve(activeThreadId, saveData)
            .then((res) => {
              console.log("delete resp", res);
            })
            .catch((err) => {
              console.log("delete err", err);
            });
        }
      );

      commentsRepository.on(
        `updateComment:${channelId}:${activeThreadId}`,
        (evt, data) => {
          let saveData = {
            contractId: contract.id,
            content: data.content,
            contractContent: "",
            userId: user?.externalUserInviteID ? -1 : user.id,
            externalUserFullName: user?.externalUserInviteID
              ? user.fullName
              : null,
            externalUserEmail: user?.externalUserInviteID ? user.email : null,
          };
          editComment(activeThreadId, saveData)
            .then((res) => {
              console.log("edit resp", res);
            })
            .catch((err) => {
              console.log("edit err", err);
            });
        }
      );

      return () => {
        commentsRepository.off(`addComment:${channelId}:${activeThreadId}`);
        commentsRepository.off(`removeComment:${channelId}:${activeThreadId}`);
        commentsRepository.off(`removeCommentThread:${channelId}`);
        commentsRepository.off(`resolveCommentThread:${channelId}`);
        commentsRepository.off(`updateComment:${channelId}:${activeThreadId}`);
      };
    };

    if (editor) {
      initializeEditor();
    }

    return () => {
      if (editor) {
        const commentsRepository = editor.plugins.get("CommentsRepository");
        commentsRepository.off(`addComment:${channelId}:${activeThreadId}`);
        commentsRepository.off(`removeComment:${channelId}:${activeThreadId}`);
        commentsRepository.off(`removeCommentThread:${channelId}`);
        commentsRepository.off(`resolveCommentThread:${channelId}`);
        commentsRepository.off(`updateComment:${channelId}:${activeThreadId}`);
      }
    };
  }, [editor, activeThreadId, channelId]);

  useEffect(() => {
    if (
      !contract ||
      isUserSignedContract() ||
      !isUserSignatory() ||
      contract.state !== ContractStatus.PENDING_E_SIGN
    ) {
      return;
    }
  }, [contract, signMode]);

  useEffect(() => {
    setForceValue(forceValue + 1);
  }, [size]);

  const handleClickToScrollRelatedComment = (event) => {
    const targetSpan = event.target.closest(".ck-comment-marker");

    if (targetSpan) {
      const commentId = targetSpan.getAttribute("data-comment");

      const targetDiv = document.querySelector(
        `[data-thread-id="${commentId}"]`
      );

      if (targetDiv) {
        // Scroll the div into view
        targetDiv.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });

        // Apply the box-shadow class or any other logic
        // targetDiv.classList.add('ck-thread--active');
      }
    }
  };

  useEffect(() => {
    const commonAncestor = document.querySelector(".ckEditorContainer");

    if (editor) {
      if (commonAncestor) {
        commonAncestor.addEventListener(
          "click",
          handleClickToScrollRelatedComment
        );
      }
    }

    return () => {
      if (commonAncestor) {
        commonAncestor.removeEventListener(
          "click",
          handleClickToScrollRelatedComment
        );
      }
    };
  }, [editor]);

  const updateCanvasLeftPosition = () => {
    let canvas = document.querySelector(".document-upload-wrapper");

    if (!canvas || canvas.length === 0) {
      return 0;
    }

    if (canvas && getCanvasWidth()) {
      setCanvasLeftPosition((canvas.offsetWidth - getCanvasWidth()) / 2);
      setCurrentCanvasLeftPosition((canvas.offsetWidth - getCanvasWidth()) / 2);
    }
  };

  const overlayClick = (event, page) => {
    let overlay = document.getElementsByClassName("pdf-overlay");
    const rect = overlay[page - 1].getBoundingClientRect();
    let x = event.clientX - rect.left;
    let y = event.clientY - rect.top;
    setBoxPosition({ x: x, y: y });
    if (signatures.length > 0 && !placePDFSignaturePlaceholder && !signMode) {
      updatePdfContent({ x: x, y: y, pageNumber: page });
    }
    if (
      !overlay ||
      overlay.length === 0 ||
      (!placePDFSignaturePlaceholder &&
        !placePDFNamePlaceholder &&
        !placePDFDatePlaceholder)
    ) {
      return;
    }
    if (x < 0) {
      x = 0;
    }
    if (y < 0) {
      y = 0;
    }
    const canvasWidth = getCanvasWidth();
    const canvasHeight = getCanvasHeight();
    let metadata = contract.metadata;

    if (!metadata.placeholders) {
      metadata.placeholders = [];
    }

    let eventType = "signature";
    if (placePDFNamePlaceholder) {
      eventType = "name";
    }

    if (placePDFDatePlaceholder) {
      eventType = "date";
    }

    metadata.placeholders.push({
      id: metadata.placeholders.length + 1,
      x: x,
      y: y,
      canvasWidth: canvasWidth,
      canvasHeight: canvasHeight,
      pdfScale: 1.3,
      pageNumber: page,
      event: eventType,
      collaborator: collaboratorForPlacement,
    });
    setContract({
      ...contract,
      metadata: metadata,
    });

    let color;
    switch (Object.keys(signatoryColor).length) {
      case 0:
        color = "#BECCEE";
        break;
      case 1:
        color = "#EEDEBE";
        break;
      case 2:
        color = "#b8f1e4";
        break;
      case 3:
        color = "#DCFFFB";
        break;
      case 4:
        color = "#AFF8D8";
        break;
      case 5:
        color = "#FFABAB";
        break;
      default:
        color = null;
        break;
    }

    if (!signatoryColor[collaboratorForPlacement.email]) {
      setSignatoryColor({
        ...signatoryColor,
        [collaboratorForPlacement.email]: color,
      });
    }

    placePlaceholderContractUser(collaboratorForPlacement.id).then(
      (response) => {
        fetchCollaborators();
      }
    );

    saveContractMetadata(contract.id, { metadata: JSON.stringify(metadata) });
  };

  const renderPlaceholders = (pageNumber) => {
    let result = [];

    if (
      !contract ||
      !contract.metadata?.placeholders ||
      contract.state <= ContractStatus.PENDING_REVIEW
    ) {
      return result;
    }

    const width = 100;
    let height = 50;
    const canvasWidth = getCanvasWidth();
    const canvasHeight = getCanvasHeight();

    contract.metadata.placeholders.map((placeholder) => {
      const collaboratorExist = collaborators.find(
        (x) => placeholder?.collaborator?.id === x?.id
      );

      if (!collaboratorExist) {
        return result;
      }

      if (placeholder.pageNumber !== pageNumber) {
        return result;
      }

      let color;
      switch (Object.keys(signatoryColor).length) {
        case 0:
          color = "#BECCEE";
          break;
        case 1:
          color = "#EEDEBE";
          break;
        case 2:
          color = "#b8f1e4";
          break;
        case 3:
          color = "#DCFFFB";
          break;
        case 4:
          color = "#AFF8D8";
          break;
        case 5:
          color = "#FFABAB";
          break;
        default:
          color = "#DCDCDCE6";
          break;
      }

      if (!signatoryColor[placeholder.collaborator.email]) {
        setSignatoryColor({
          ...signatoryColor,
          [placeholder.collaborator.email]: color,
        });
      }

      let left =
        (placeholder.x - width / 2) * (canvasWidth / placeholder.canvasWidth);
      let top =
        (placeholder.y - height / 2) *
        (canvasHeight / placeholder.canvasHeight);
      const spiltedNames = placeholder?.collaborator?.fullName
        .split(" ")
        .filter((name) => name !== "");
      const longestName = spiltedNames?.reduce((longest, current) => {
        return current.length > longest.length ? current : longest;
      }, "");

      let updatedHeight;
      switch (spiltedNames.length) {
        case 1:
          updatedHeight = height * 1.5;
          break;
        case 2:
          updatedHeight = height * 2;
          break;
        case 3:
          updatedHeight = height * 2.5;
          break;
        case 4:
          updatedHeight = height * 3;
          break;
        case 5:
          updatedHeight = height * 3.5;
          break;
        case 6:
          updatedHeight = height * 4;
          break;
        case 7:
          updatedHeight = height * 4.5;
          break;
        case 8:
          updatedHeight = height * 5;
          break;
        case 9:
          updatedHeight = height * 5.5;
          break;
        case 10:
          updatedHeight = height * 6;
          break;
        default:
          updatedHeight = height * spiltedNames.length - 3;
          break;
      }

      result.push(
        <>
          {placeholder.event === "signature" && (
            <div
              className={
                signMode && isUserSignatory()
                  ? "placeholder can-sign"
                  : "placeholder"
              }
              id="ele"
              style={{
                width:
                  longestName.length > 8
                    ? longestName.length * 10 + 60
                    : width * 1.25,
                height: updatedHeight,
                left: left,
                top: top,
                paddingBottom: "6px",
                transform: "scale(" + 1 + ")",
                backgroundColor:
                  isUserOwner(user, owners) || isUserSignatory()
                    ? signatoryColor[placeholder.collaborator.email]
                    : "#EEDEBE",
                border: "4px solid #3D32CF",
                opacity:
                  (contract.state === ContractStatus.REVIEWED &&
                    isUserOwner(user, owners)) ||
                    placeholder?.collaborator?.user?.id === user.id
                    ? 1
                    : 0.25,
              }}
              onClick={() => placeholderSign(user, placeholder, pageNumber)}
            >
              <h4
                style={{
                  fontSize: (width * 1) / 5,
                  position: "absolute",
                  top: 1.25,
                  color: "#283959",
                  fontWeight: "500",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {spiltedNames.map((item) => (
                  <span>{item}</span>
                ))}
                <h5 style={{ margin: "0", fontWeight: "500" }}>SIGN</h5>
              </h4>
              <img
                src="/images/download.png"
                style={{
                  width: "auto",
                  height: "1.5rem",
                }}
                alt="download icon"
              ></img>
            </div>
          )}
          {placeholder.event === "name" && (
            <div
              className={
                signMode && isUserSignatory()
                  ? "date-placeholder placeholder can-sign"
                  : "date-placeholder placeholder"
              }
              style={{
                width: width * 1.25,
                height: "32px",
                left: left,
                top: top,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "scale(" + 1 + ")",
                border: "4px solid #3D32CF",
                borderRadius: "5px !important",
                backgroundColor:
                  isUserOwner(user, owners) || isUserSignatory()
                    ? signatoryColor[placeholder.collaborator.email]
                    : "#EEDEBE",
                opacity:
                  (contract.state === ContractStatus.REVIEWED &&
                    isUserOwner(user, owners)) ||
                    placeholder?.collaborator?.user?.id === user.id
                    ? 1
                    : 0.25,
              }}
            >
              <h5
                style={{
                  fontSize: 16,
                  color: "#283959",
                  margin: 0,
                  padding: "6px",
                }}
              >
                Full Name
              </h5>
            </div>
          )}
          {placeholder.event === "date" && (
            <div
              className={
                signMode && isUserSignatory()
                  ? "date-placeholder placeholder can-sign"
                  : "date-placeholder placeholder"
              }
              style={{
                width: width * 1.25,
                height: "32px",
                left: left,
                top: top,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "scale(" + 1 + ")",
                border: "4px solid #3D32CF",
                borderRadius: "5px !important",
                backgroundColor:
                  isUserOwner(user, owners) || isUserSignatory()
                    ? signatoryColor[placeholder.collaborator.email]
                    : "#EEDEBE",
                opacity:
                  (contract.state === ContractStatus.REVIEWED &&
                    isUserOwner(user, owners)) ||
                    placeholder?.collaborator?.user?.id === user.id
                    ? 1
                    : 0.25,
              }}
            >
              <h5
                style={{
                  fontSize: 16,
                  color: "#283959",
                  margin: 0,
                  padding: "6px",
                }}
              >
                Date Signed
              </h5>
            </div>
          )}
        </>
      );
    });

    return result;
  };

  const placeholderSign = async (user, placeholder, pageNumber) => {
    if (!signMode) {
      return;
    }

    if (placeholder?.collaborator?.user?.id !== user.id) {
      return;
    }

    setPosition({
      x: placeholder.x,
      y: placeholder.y,
    });

    if (!user.signature) {
      setOpenAdoptSignModal(true);
      return;
    }

    await adoptSignature(
      user.signature,
      placeholder.x,
      placeholder.y,
      placeholder
    );

    let placeholders = contract.metadata.placeholders;

    const index = placeholders.indexOf(placeholder);
    placeholders.splice(index, 1);

    setContract({
      ...contract,
      metadata: {
        placeholders: placeholders,
      },
    });
    setSignedPageNumber(pageNumber);
    setSignState(true);
  };

  const getCanvasWidth = () => {
    let canvas = document.querySelector(".react-pdf__Page__canvas");

    if (!canvas || canvas.length === 0) {
      return 0;
    }

    const rect = canvas.getBoundingClientRect();
    return rect.width;
  };

  const getCanvasLeft = () => {
    let canvas = document.querySelector(".document-upload-wrapper");

    if (!canvas || canvas.length === 0) {
      return 0;
    }

    if (canvas && canvasLeftPosition === 0 && getCanvasWidth()) {
      setCanvasLeftPosition((canvas.offsetWidth - getCanvasWidth()) / 2);
      setCurrentCanvasLeftPosition((canvas.offsetWidth - getCanvasWidth()) / 2);
    }

    return (canvas.offsetWidth - getCanvasWidth()) / 2;
  };

  const getCanvasHeight = () => {
    let canvas = document.getElementsByClassName("react-pdf__Page__canvas");

    if (!canvas || canvas.length === 0) {
      return 0;
    }

    const rect = canvas[0].getBoundingClientRect();

    return rect.height;
  };

  const adoptSignature = async (signature, x, y, placeholder) => {
    const pdfDoc = await PDFDocument.load(pdfContent);
    const pages = pdfDoc.getPages();
    const page = pages[placeholder.pageNumber - 1];

    const { width, height } = page.getSize();

    const pngImage = await pdfDoc.embedPng(signature);

    const ratio = pngImage.width / pngImage.height;
    const heightImg = 23;
    const widthImg = ratio * heightImg;

    const scaledWidth = widthImg / 1.3;

    const imgX = x * (1 / 1.3) - scaledWidth / 2;
    const imgY = height - y * (1 / 1.3) - heightImg / 2;

    page.drawImage(pngImage, {
      x: imgX,
      y: imgY,
      width: scaledWidth,
      height: heightImg,
    });
    const lineX = imgX - 5;
    const lineStartY = imgY - 7;
    const lineEndY = imgY + heightImg + 5;

    page.drawLine({
      start: { x: lineX, y: lineStartY },
      end: { x: lineX, y: lineEndY },
      thickness: 3,
      color: rgb(0.50980392, 0.96078431, 0.87058824),
      opacity: 1,
    });

    const fontSizePt = 5;
    const textX = imgX;
    const textY = imgY + heightImg;
    page.drawText("signed via Logical Contracts:", {
      x: textX,
      y: textY,
      size: fontSizePt,
    });

    const alphanumericId = generateAlphanumericId();
    const date = new Date().toLocaleDateString("en-GB").replace(/\//g, ".");
    const bottomText = `${alphanumericId} ${date}`;
    const bottomTextX = imgX;
    const bottomTextY = imgY - fontSizePt;
    page.drawText(bottomText, {
      x: bottomTextX,
      y: bottomTextY,
      size: fontSizePt,
    });

    const pdfBytes = await pdfDoc.saveAsBase64();
    setPDFContent("data:application/pdf;base64," + pdfBytes);
  };

  const adoptName = async (
    nameEventFilteredPlaceholders,
    dateEventFilteredPlaceholders
  ) => {
    if (!user.signature) {
      setOpenAdoptSignModal(true);
    }
    const pdfDoc = await PDFDocument.load(pdfContent);
    const pages = pdfDoc.getPages();
    const name = user.fullName;
    const pngImage = await pdfDoc.embedPng(user.signature);
    let width = name.split("").filter((name) => name !== "").length * 6.5 + 20;
    let font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const ratio = pngImage.width / pngImage.height;
    const heightImg = 23;
    const widthImg = ratio * heightImg;
    const scaledWidth = widthImg / 1.3;
    const fontSize = 8;

    nameEventFilteredPlaceholders.length > 0 &&
      nameEventFilteredPlaceholders?.forEach((placeholder) => {
        if (placeholder?.collaborator?.user?.id !== user.id) {
          return;
        }
        const x = placeholder.x;
        const y = placeholder.y;
        const page = pages[placeholder.pageNumber - 1];
        const { height } = page.getSize();
        const imgX = x * (1 / 1.3) - scaledWidth / 2;
        const imgY = height - y * (1 / 1.3) - heightImg / 2;

        setPosition({
          x: x,
          y: y,
        });

        page.drawText(name, {
          x: imgX,
          y: imgY + 10,
          font: font,
          size: fontSize * 1.5,
          color: rgb(0, 0, 0),
        });

        page.drawRectangle({
          x: imgX - 5,
          y: imgY,
          width: width,
          height: fontSize * 1.5 + 15,
          borderColor: rgb(0, 0, 0),
          borderWidth: 1,
        });

        let placeholders = contract.metadata.placeholders;

        const index = placeholders.indexOf(placeholder);
        placeholders.splice(index, 1);

        setContract({
          ...contract,
          metadata: {
            placeholders: placeholders,
          },
        });

        setSignedPageNumber(placeholder.pageNumber);
      });

    const pdfBytes = await pdfDoc.saveAsBase64();

    if (!dateEventFilteredPlaceholders) {
      setPDFContent("data:application/pdf;base64," + pdfBytes);
      return;
    }
    await adoptDate(
      dateEventFilteredPlaceholders,
      "data:application/pdf;base64," + pdfBytes
    );
  };

  const adoptDate = async (dateEventFilteredPlaceholders, pdfContent) => {
    if (!user.signature) {
      setOpenAdoptSignModal(true);
    }
    const pdfDoc = await PDFDocument.load(pdfContent);
    const pages = pdfDoc.getPages();
    const pngImage = await pdfDoc.embedPng(user.signature);
    let font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const ratio = pngImage.width / pngImage.height;
    const heightImg = 23;
    const widthImg = ratio * heightImg;
    const scaledWidth = widthImg / 1.3;
    const fontSize = 8;
    const date = new Date();
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    let width = formattedDate.split("").length * 6 + 10;

    dateEventFilteredPlaceholders.length > 0 &&
      dateEventFilteredPlaceholders.forEach((placeholder) => {
        if (placeholder?.collaborator?.user?.id !== user.id) {
          return;
        }

        const x = placeholder.x;
        const y = placeholder.y;
        const page = pages[placeholder.pageNumber - 1];
        const { height } = page.getSize();
        const imgX = x * (1 / 1.3) - scaledWidth / 2;
        const imgY = height - y * (1 / 1.3) - heightImg / 2;

        setPosition({
          x: placeholder.x,
          y: placeholder.y,
        });

        page.drawText(formattedDate, {
          x: imgX,
          y: imgY + 10,
          font: font,
          size: fontSize * 1.5,
          color: rgb(0, 0, 0),
        });

        page.drawRectangle({
          x: imgX - 5,
          y: imgY,
          width: width,
          height: fontSize * 1.5 + 15,
          borderColor: rgb(0, 0, 0),
          borderWidth: 1,
        });

        let placeholders = contract.metadata.placeholders;
        const index = placeholders.indexOf(placeholder);
        placeholders.splice(index, 1);

        setContract({
          ...contract,
          metadata: {
            placeholders: placeholders,
          },
        });
        setSignedPageNumber(placeholder.pageNumber);
      });

    const pdfBytes = await pdfDoc.saveAsBase64();
    setPDFContent("data:application/pdf;base64," + pdfBytes);
  };

  const generateAlphanumericId = () => {
    const alphanumericCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const idLength = 10;
    let id = "";
    for (let i = 0; i < idLength; i++) {
      const randomIndex = Math.floor(
        Math.random() * alphanumericCharacters.length
      );
      id += alphanumericCharacters.charAt(randomIndex);
    }
    return id;
  };

  const onDocumentLoadSuccess = (numPages) => {
    setNumPages(numPages.numPages);
    setTimeout(() => {
      if (signedPageNumber) {
        addSignatureFunct();
      }
    }, 500);
  };

  const fetchContract = () => {
    getContract(id).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      setContract({
        ...response.data,
      });

      getAllContractUsersByContract(id).then((response) => {
        if (!response || !response.ok) {
          return;
        }

        let contractUserExists = response.data.filter(
          (x) => x.email === user.email
        );

        if (!contractUserExists.length > 0) {
          setRemovedPermissioModal(true);
        }

        response.data.map((contractUser) => {
          if (contractUser.email === user.email) {
            setIsUserReviewer(contractUser.reviewer);
          }
        });
      });
      getActiveContractContent(id).then(async (responseContent) => {
        showLoader(false);

        setContract({
          ...response.data,
          metadata: responseContent.data.metadata
            ? JSON.parse(responseContent.data.metadata)
            : {},
        });

        setContent(responseContent.data.content);

        if (editor && responseContent?.data?.content) {
          editor.data.set(responseContent.data.content, {
            suppressErrorInCollaboration: true,
          });
        }

        setContractContentLoading(false);

        if (response.data.state < ContractStatus.REVIEWED) {
          setReadyAutoSave(true);
        }

        if (response.data.state > ContractStatus.REVIEWED) {
          setPDFContent(responseContent.data.uploadedContentSignature);
        } else {
          setPDFContent(
            responseContent.data.uploadedContentSignaturePlaceholder
          );
        }
      });
    });

    getContractCount().then((response) => {
      if (!response || !response.ok) {
        return;
      }

      setContractCount(response.data);
    });

    getAllContractOwnersByContract(id).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      setOwners(response.data);
    });

    getAllContractUsersByContract(id).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      setCollaborators(response.data);
    });
    window.scrollTo(position.x, position.y);
  };

  const fetchCollaborators = () => {
    getAllContractUsersByContract(id).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      setCollaborators(response.data);
    });
  };

  const isUserOwner = (user, owners) => {
    for (let owner of owners) {
      if (user.id === owner.user.id) {
        return true;
      }
    }

    return false;
  };

  const resetTabs = () => {
    setDisplayForms(true);
    setDetailsTab(false);
    setCommentsTab(false);
    setCollaboratorsTab(false);
    setFoldersTab(false);
    setDownloadTab(false);
    setDeleteTab(false);
    setHistoryTab(false);
    setForceValue(forceValue + 1);
  };

  const contractOptionMenuHandler = () => {
    setIsOpenContractOptionMenu(!isOpenContractOptionMenu);
    resetTabs();
    setDetailsTab(true);
  };

  const handleDetailsTab = () => {
    if (!isTrialPackageValid(user, contractCount)) {
      setOpenNonValidSubscription(true);
      return;
    }

    resetTabs();
    setDetailsTab(true);
    setSignatures([]);
  };

  const handleCommentsTab = () => {
    if (!isUserReviewer) {
      if (!isTrialPackageValid(user, contractCount)) {
        setOpenNonValidSubscription(true);
        return;
      }
    }

    resetTabs();
    setCommentsTab(true);
  };

  const collaboratorExists = async (data) => {
    let exists = false;
    await Promise.all(
      collaborators.map((collaborator) => {
        if (collaborator.email === data.email) {
          exists = true;
        }
      })
    );
    return exists;
  };

  const addOwnerAsCollaborator = () => {
    collaboratorExists(user).then((exists) => {
      if (!exists) {
        addContractUser({
          userId: user.id,
          contractId: contract.id,
          contractUserType: ContractUserType.COLLABORATORS,
          reviewer:
            contract.state === ContractStatus.PENDING_REVIEW ||
            contract.state === ContractStatus.CREATED,
          signatory: contract.state > ContractStatus.PENDING_REVIEW,
          email: user.email,
          fullName: user.fullName,
          companyName: user.company ? user.company.companyName : "",
        }).then((response) => {
          if (!response || !response.ok) {
            return;
          }

          fetch();
          fetchContract();
        });
      }
    });
  };

  const handleCollaboratorsTab = () => {
    if (!isUserReviewer) {
      if (!isTrialPackageValid(user, contractCount)) {
        setOpenNonValidSubscription(true);
        return;
      }
    }

    resetTabs();
    // addOwnerAsCollaborator();
    setCollaboratorsTab(true);
  };

  const handleFoldersTab = () => {
    if (!isTrialPackageValid(user, contractCount)) {
      setOpenNonValidSubscription(true);
      return;
    }

    resetTabs();
    setFoldersTab(true);
    setSignatures([]);
  };
  const handleSignatureTab = () => {
    if (!isTrialPackageValid(user, contractCount)) {
      setOpenNonValidSubscription(true);
      return;
    }

    resetTabs();
  };

  const handleHistoryTab = () => {
    if (!isTrialPackageValid(user, contractCount)) {
      setOpenNonValidSubscription(true);
      return;
    }

    resetTabs();
    setHistoryTab(true);
    setSignatures([]);
  };

  const handleDownloadTab = () => {
    if (!isTrialPackageValid(user, contractCount)) {
      setOpenNonValidSubscription(true);
      return;
    }

    setDownloadTab(true);
    setSignatures([]);
  };

  const handleCloseDownloadTab = () => {
    if (!isTrialPackageValid(user, contractCount)) {
      setOpenNonValidSubscription(true);
      return;
    }

    setDownloadTab(false);
  };

  const handleDownloadConfirm = async () => {
    setDownloadTab(false);

    if (pdfContent && contract.state > ContractStatus.PENDING_REVIEW) {
      fetch(pdfContent)
        .then((b) => b.blob())
        .then((r) => {
          const file = new Blob([r], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);

          const link = document.createElement("a");
          link.href = fileURL;
          link.download = contract?.name ? contract?.name : "LC Contract.pdf";
          link.click();
        });
      return;
    } else if (
      editor &&
      (contract.state === ContractStatus.CREATED ||
        contract.state === ContractStatus.PENDING_REVIEW)
    ) {
      const config = editor.config.get("exportWord");
      editor.execute("exportWord", config);
    }

    // if(contract.state > ContractStatus.PENDING_REVIEW) {
    //     let response = await handleCovertFromHTMLtoPDF(editor.getData(), contract.name);
    //     const pdfBlob = await response.blob();
    //     const pdfUrl = URL.createObjectURL(pdfBlob);
    //     window.open(pdfUrl);
    //     return;
    // }

    // fetch(process.env.REACT_APP_CONVERTER_API + 'api/converters/htmlToPDF?format=json', {
    //     method: 'POST',
    //     headers: {'Content-Type': 'application/json'},
    //     body: JSON.stringify({
    //         content: editor.getData(),
    //     })
    // })
    //     .then(blob => blob.blob())
    //     .then(response => {

    //         const file = new Blob([response], {type: 'application/pdf'})
    //         const fileURL = URL.createObjectURL(file)

    //         const pdfWindow = window.open()
    //         pdfWindow.location.href = fileURL
    // })
  };

  const handleDeleteTab = () => {
    setDeleteTab(true);
    setSignatures([]);
  };

  const handleCloseDeleteTab = () => {
    setDeleteTab(false);
  };

  const handleDeleteConfirm = () => {
    deleteContract(contract.id).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      navigate("/contract-list");
      showMessage(strings.common.deleted);
    });

    setDeleteTab(false);
  };

  const handleEditContract = (data) => {
    let updateData = {};
    updateData.id = contract.id;

    if (data.name) {
      updateData.name = data.name;
      updateData.email = contract.user.email;
    }

    if (data.email) {
      updateData.email = data.email;
    }

    if (data.email === null) {
      updateData.email = null;
    }

    editContract(updateData).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      showMessage(strings.common.edited);
      fetchContract();
    });
  };

  const submitMoveToFolder = (data) => {
    moveContract({
      contractId: contract.id,
      folderId: data.id,
    }).then((response) => {
      fetch();
    });

    if (data.id == contract.folderId) {
      showMessage(
        strings.pages.contractPreview.contractMovedToItsFolderMsg + data.name
      );
    } else {
      showMessage(
        strings.pages.contractPreview.contractMovedToFolderMsg + data.name
      );
    }
  };

  const submitNewCollaborator = (collaborator) => {
    setContract({
      ...contract,
      collaborators: collaborators,
    });
  };

  const signContract = () => {
    const collaborator = collaborators.find(
      (x) => x?.user?.id === user.id && x.signatory
    );
    setSignMode(false);

    const placeholders = contract.metadata?.placeholders?.filter(
      (x) => x?.collaborator?.user?.id === user.id
    );

    if (placeholders.length > 0) {
      setPlaceAllSignatures(true);
    } else {
      setShowLocalLoader(true);
      setShowLocalLoaderText("Saving your document - just a moment...");
      saveContract(contract.id, {
        content: content,
        uploadedContent: pdfContent,
        metadata: JSON.stringify(contract.metadata),
        force: true,
        userId: user.id,
      }).then((response) => {
        esignContract({
          fullName: collaborator.user.fullName,
          initials: collaborator.user.fullName
            .split(" ")
            .map((item) => {
              return item[0];
            })
            .join(""),
          newSignature: false,
          contractUserId: collaborator.id,
        }).then(() => {
          setShowLocalLoader(false);
          setShowLocalLoaderText("");
          fetchContract();
        });
      });
    }
  };

  const save = async (force, uploadedContent) => {
    if (!force && !isTrialPackageValid(user, contractCount)) {
      setOpenNonValidSubscription(true);
      return;
    }

    if (!contract || contract.state === ContractStatus.APPROVED) {
      return;
    }

    if (force) {
      showLoader(true);
    }

    saveContract(contract.id, {
      content: editor ? editor.getData() : content,
      uploadedContent: uploadedContent,
      metadata: JSON.stringify(contract.metadata),
      force: force,
      userId: user.id,
    }).then((response) => {
      showLoader(false);

      if (!response || !response.ok) {
        return;
      }

      if (force) {
        fetchContract();
      }

      // if(editor?.getData()) {
      //     setContent(editor.getData());
      // }
    });
  };

  const updateContractState = async (contract, state) => {
    setLoaderTitle("");
    setShowSignBanner(true);
    if (!isTrialPackageValid(user, contractCount)) {
      setOpenNonValidSubscription(true);
      setContractContentLoading(false);
      return;
    }

    if (state === ContractStatus.PENDING_E_SIGN) {
      setShowLocalLoader(true);
      setShowLocalLoaderText("Sending for signature - just a moment...");
      saveContract(contract.id, {
        content,
        uploadedContent: pdfContent,
        metadata: JSON.stringify(contract.metadata),
        force: false,
      }).then((response) => {
        if (!response || !response.ok) {
          setShowLocalLoader(false);
          setShowLocalLoaderText("");
          return;
        }

        changeContractState(contract.id, {
          state: state,
        }).then((response) => {
          setPlacePDFSignature(true);
          fetchContract();
          setShowLocalLoader(false);
          setShowLocalLoaderText("");
        });
      });

      return;
    }

    showLoader(true);
    saveContract(contract.id, {
      content: editor?.getData() ? editor.getData() : content,
      uploadedContent: pdfContent,
      metadata: JSON.stringify(contract.metadata),
      force: false,
    }).then((response) => {
      if (!response || !response.ok) {
        return;
      }
      changeContractState(contract.id, {
        state: state,
      }).then((response) => {
        showLoader(false);
        fetchContract();
      });
    });
  };

  const canSaveContract = (contract) => {
    return (
      isUserOwner(user, owners) && contract.state <= ContractStatus.REVIEWED
    );
  };

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
  };

  const zoomIn = () => {
    if (pdfScale + 0.1 > 5) {
      setPdfScale(5);
      return;
    }
    setPdfScale(pdfScale + 0.1);
  };

  const zoomOut = () => {
    if (pdfScale - 0.1 < 1.3) {
      setPdfScale(1.3);
      return;
    }
    setPdfScale(pdfScale - 0.1);
  };

  const isEditDisabled = (contract, user, contractCount) => {
    const collaborator = collaborators.find((x) => x?.email === user?.email);

    if (!collaborator && !isUserOwner(user, owners)) {
      return true;
    }

    return (
      contract.state >= ContractStatus.REVIEWED ||
      !isTrialPackageValid(user, contractCount)
    );
  };

  const isUserSignedContract = () => {
    const collaborator = collaborators.find((x) => x?.user?.id === user.id);
    if (!collaborator) {
      return false;
    }

    return collaborator.isSigned;
  };

  const isUserSignatory = () => {
    const collaboratorList = collaborators.filter(
      (x) => x?.user?.id === user.id
    );

    if (!collaboratorList) {
      return false;
    }

    for (let coll of collaboratorList) {
      if (coll?.signatory) {
        return true;
      }
    }

    return false;
  };

  const commentsDisabled = () => {
    if (
      user?.userProfileType === UserType.PORTAL_ADMIN ||
      user?.userProfileType === UserType.SYSTEM_ADMIN
    ) {
      return contract.state >= ContractStatus.REVIEWED;
    }

    if (!user || !user.company || !user.company.package) {
      return true;
    }

    if (
      (user.company.package.dataRoomSignature ||
        user.company.package.dataRoomNoSignature) &&
      !user.company.package.baseFeatures
    ) {
      return true;
    }

    return contract.state >= ContractStatus.REVIEWED;
  };

  const collaboratorsDisabled = () => {
    if (
      user?.userProfileType === UserType.PORTAL_ADMIN ||
      user?.userProfileType === UserType.SYSTEM_ADMIN
    ) {
      return false;
    }

    if (!user || !user.company || !user.company.package) {
      return true;
    }

    if (
      user.company.package.dataRoomSignature ||
      user.company.package.baseFeatures
    ) {
      return false;
    }

    return true;
  };

  const allPlaceholdersPlaced = () => {
    let counter = 0;

    for (let collaborator of collaborators) {
      if (collaborator.signatory && collaborator.placeholderPlaced) {
        counter++;
      }
    }

    return counter > 0 && collaborators.length !== 0;
  };

  const signatoriesExist = () => {
    if (!collaborators || collaborators.length === 0) {
      return false;
    }

    for (let collaborator of collaborators) {
      if (collaborator.signatory) {
        return true;
      }
    }

    return false;
  };

  const discardPlaceholder = () => {
    setPlacePDFSignaturePlaceholder(false);
    setPlacePDFNamePlaceholder(false);
    setPlacePDFDatePlaceholder(false);
    setSignState(false);
    setContract({
      ...contract,
      metadata: { placeholders: [] },
    });

    saveContractMetadata(contract.id, {
      metadata: JSON.stringify({ placeholders: [] }),
    });
    setSignatoryColor({});
  };

  const requestSign = () => {
    if (!signatoriesExist()) {
      setOpenInfoModal(true);
      setOpenInfoModalText("Add recipients signatures to the document");
      return;
    }

    if (!allPlaceholdersPlaced()) {
      setOpenInfoModal(true);
      setOpenInfoModalText("Add recipients signatures to the document");
      return;
    }

    if (contract.metadata?.placeholders?.length === 0) {
      setOpenInfoModal(true);
      setOpenInfoModalText("Add recipients signatures to the document");
      return;
    }

    setPlacePDFSignaturePlaceholder(false);
    setPlacePDFNamePlaceholder(false);
    setPlacePDFDatePlaceholder(false);
    updateContractState(contract, ContractStatus.PENDING_E_SIGN);
  };

  const handleChangeHistoryView = (contractContent) => {
    setReadyAutoSave(false);
    editor.config._config.autosave.extraOptions.readyAutoSave = false;
    editor.data.set(contractContent.content, {
      suppressErrorInCollaboration: true,
    });

    if (contractContent.active) {
      setReadyAutoSave(true);
      editor.config._config.autosave.extraOptions.firstLoad = true;
    }
  };

  const renderPdfPages = () => {
    setLoaderTitle("Your document is loading for review - just a moment..");
    showLoader(true);
    let result = [];

    if (numPages <= 1 && !!numPages) {
      showLoader(false);
      return result;
    }

    for (let page = 2; page <= numberPagesToRender; page++) {
      result.push(
        <div
          className={
            showSignBanner &&
              contract.state === ContractStatus.PENDING_E_SIGN &&
              isUserSignatory()
              ? "document-upload-wrapper banner "
              : "document-upload-wrapper " +
              ((placePDFSignaturePlaceholder || placePDFSignature) && signMode
                ? "place-active"
                : "")
          }
        >
          {(contract.state === ContractStatus.REVIEWED ||
            contract.state === ContractStatus.PENDING_E_SIGN) && (
              <div
                className={
                  !displayForms
                    ? "pdf-overlay transition"
                    : "pdf-overlay transition"
                }
                style={{
                  width: getCanvasWidth(),
                  height: getCanvasHeight(),
                  left: getCanvasLeft() >= 0 ? currentCanvasLeftPosition : 0,
                }}
                onClick={(e) => overlayClick(e, page)}
              >
                {renderPlaceholders(page)}
              </div>
            )}
          <Document
            inputRef={pdfViewer}
            file={pdfContent}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              scale={pdfScale}
              pageNumber={page}
              onRenderSuccess={() => {
                if (contract.user.id !== user.id) {
                  if (
                    isUserSignedContract() ||
                    contract.state !== ContractStatus.PENDING_E_SIGN
                  ) {
                    return;
                  }
                }
                setPlacePDFSignature(true);
              }}
            ></Page>
          </Document>
        </div>
      );
      showLoader(false);
    }

    return result;
  };

  const handleAutoSave = (editor) => {
    const trackChanges = editor.plugins.get("TrackChanges");
    const suggestionsData = trackChanges.getSuggestions({
      skipNotAttached: true,
      toJSON: true,
    });

    saveContract(contract.id, {
      content: editor.getData(),
      metadata: JSON.stringify(contract.metadata),
      force: true,
      userId: user.id,
    }).then(() => {
      if (suggestionsData.length > 0) {
        suggestionsData.map((suggestion) => {
          let submitSuggestionData = {
            ...suggestion,
            contractId: contract.id,
            suggestionId: suggestion.id,
            type: suggestion.type,
            createdAt: suggestion.createdAt,
            data: suggestion?.data ? JSON.stringify(suggestion.data) : null,
          };

          saveSuggestion(submitSuggestionData);
        });
      }
      fetchContract();
    });
  };

  const addSignatureFunct = async (id) => {
    let elem;
    if (id) {
      elem = document.querySelector(`[data-comment="${id}"]`);
    } else {
      elem = document.querySelectorAll("#ele[style*='opacity: 1']");
    }

    elem[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });

    const filteredPlaceholders = contract.metadata.placeholders.filter(
      (placeholder) => placeholder.collaborator.user?.id === user?.id
    );

    if (!filteredPlaceholders) {
      return;
    }

    const nameEventFilteredPlaceholders = filteredPlaceholders.filter(
      (placeholder) => placeholder.event === "name"
    );

    const dateEventFilteredPlaceholders = filteredPlaceholders?.filter(
      (placeholder) => placeholder.event === "date"
    );

    if (nameEventFilteredPlaceholders.length > 0) {
      await adoptName(
        nameEventFilteredPlaceholders,
        dateEventFilteredPlaceholders
      );
    } else {
      if (dateEventFilteredPlaceholders.length > 0) {
        await adoptDate(dateEventFilteredPlaceholders, pdfContent);
      }
    }
  };

  const addNewDataInContract = (newhtmlContent) => {
    const parsedHtml = ReactHtmlParser(content);
    const parsedNewHtml = ReactHtmlParser(newhtmlContent);

    const filteredPageBreakText = parsedNewHtml.filter((item) => {
      if (
        typeof item === "object" &&
        item !== null &&
        Object.keys(item).length > 0
      ) {
        return true;
      }
      if (typeof item === "string" && item.trim() !== "") {
        return true;
      }
      return false;
    });

    const pageBreakText = parsedHtml.filter((item) => {
      return item?.props?.className === "page-break";
    });

    let updatedHtmlString;
    if (pageBreakText && pageBreakText.length > 0) {
      const lastPageBreakIndex = pageBreakText[pageBreakText.length - 1].key;

      const updatedHtmlArray = [
        ...parsedHtml.slice(0, lastPageBreakIndex),
        <p style={{ fontWeight: "bold" }}>
          {filteredPageBreakText[0]?.props?.children[0]}
        </p>,
        ...filteredPageBreakText.slice(1).map((point, index) => (
          <p
            key={lastPageBreakIndex + index + 1}
            style={
              point.type === "h4"
                ? { columnBreakAfter: "always", fontWeight: "bold" }
                : { columnBreakAfter: "always" }
            }
          >
            {point?.props?.children[0]}
          </p>
        )),
        ...parsedHtml.slice(lastPageBreakIndex),
      ];

      updatedHtmlString = ReactDOMServer.renderToString(
        <>{updatedHtmlArray}</>
      );
    } else {
      const updatedHtmlArray = [
        ...parsedHtml,
        <p style={{ fontWeight: "bold" }}>
          {filteredPageBreakText[0]?.props?.children[0]}
        </p>,
        ...filteredPageBreakText.slice(1).map((point, index) => (
          <p
            key={index + 1}
            style={
              point.type === "h4"
                ? { columnBreakAfter: "always", fontWeight: "bold" }
                : { columnBreakAfter: "always" }
            }
          >
            {point?.props?.children[0]}
          </p>
        )),
      ];

      updatedHtmlString = ReactDOMServer.renderToString(
        <>{updatedHtmlArray}</>
      );
    }

    setContent(updatedHtmlString);
    editor.data.set(updatedHtmlString, { suppressErrorInCollaboration: true });
    const scrollNewData = document.querySelector(".ck-content");
    scrollNewData.scrollTo({
      top: scrollNewData.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div id="contract-preview" className="contract-preview-container">
      {contract && (
        <>
          <div className="contract-container">
            <div
              className={
                contract?.state > ContractStatus.REVIEWED
                  ? "save-container space-between"
                  : "save-container"
              }
              ref={squareRef}
            >
              {contract.state < ContractStatus.REVIEWED && (
                <React.Fragment>
                  <Button
                    className="default-btn ai-assist-button"
                    variant="contained"
                    onClick={() => setOpenAiAssistModal(true)}
                  >
                    AI Assist&nbsp;&nbsp;&nbsp;
                    <img src={"/images/icons/ai-assist.png"} alt="" />
                  </Button>
                </React.Fragment>
              )}
              {isUserOwner(user, owners) &&
                contract.state > ContractStatus.PENDING_REVIEW &&
                contract.state < ContractStatus.E_SIGNED &&
                contract.contentType === ContractContentType.GENERATED && (
                  <React.Fragment>
                    <Button
                      className="default-btn white"
                      variant="contained"
                      onClick={() =>
                        updateContractState(
                          contract,
                          ContractStatus.PENDING_REVIEW
                        )
                      }
                    >
                      Edit
                    </Button>
                  </React.Fragment>
                )}
              <div className="presence-list-container" />

              <div
                className={
                  contract.state > ContractStatus.PENDING_REVIEW
                    ? displayForms
                      ? "save-buttons-container fixed move-left"
                      : "save-buttons-container fixed"
                    : "save-buttons-container"
                }
              >
                <React.Fragment>
                  {!collaboratorsTab &&
                    contract.state <= ContractStatus.REVIEWED &&
                    isUserOwner(user, owners) && (
                      <Button
                        className="default-btn white"
                        variant="contained"
                        onClick={handleCollaboratorsTab}
                      >
                        Share&nbsp;&nbsp;&nbsp;
                        <img src={"/images/icons/share.png"} alt="" />
                      </Button>
                    )}
                  {(contract.state === ContractStatus.CREATED ||
                    contract.state === ContractStatus.PENDING_REVIEW) &&
                    isUserOwner(user, owners) && (
                      <React.Fragment>
                        <Button
                          className="default-btn"
                          variant="contained"
                          onClick={() => setAcceptReviewOpen(true)}
                        >
                          Complete
                        </Button>
                      </React.Fragment>
                    )}

                  {/*{*/}
                  {/*    (contract.state > ContractStatus.PENDING_REVIEW && contract.state < ContractStatus.E_SIGNED && contract.contentType === ContractContentType.GENERATED) &&*/}
                  {/*    <React.Fragment>*/}
                  {/*        <Button className="default-btn" variant="contained"*/}
                  {/*                onClick={() => updateContractState(contract, ContractStatus.PENDING_REVIEW)}>*/}
                  {/*            Return to review stage*/}
                  {/*        </Button>*/}

                  {/*        <Tooltip*/}
                  {/*            title={'If you or any party are unhappy with the contract, you can skip back to the review stage and this will reinvite the reviewees to review the contract, suggest amendments and add comments.'}>*/}
                  {/*            <img className={'tooltip-icon'} src={'/images/info_circle.svg'}/>*/}
                  {/*        </Tooltip>*/}
                  {/*    </React.Fragment>*/}
                  {/*}*/}

                  {/* {
                                    {
                                        contract.state === ContractStatus.E_SIGNED &&
                                        <React.Fragment>
                                            <Button className="default-btn" variant="contained"
                                                onClick={() => updateContractState(contract, ContractStatus.REJECTED)}>
                                                Reject signature
                                            </Button>

                                            {/*<Tooltip*/}
                  {/*    title={'By Rejecting the E-signature the signee signature will be rejected. This will notify the signee. Once the signature has been rejected you can allow new or current signees to sign the contract or you can return back to the review stage.'}>*/}
                  {/*    <img className={'tooltip-icon'} src={'/images/info_circle.svg'}/>*/}
                  {/*</Tooltip>*/}
                  {/* </React.Fragment>
                                    } */}

                  {/* {
                                        (contract.state === ContractStatus.E_SIGNED && isUserOwner(user, owners)) &&
                                        <React.Fragment>
                                            <Button className="default-btn" variant="contained"
                                                onClick={() => updateContractState(contract, ContractStatus.APPROVED)}>
                                                Accept signature
                                            </Button>
                                            {/*<Tooltip*/}
                  {/*    title={'By accepting the E-signature you are happy that the contract has been adequately signed.'}>*/}
                  {/*    <img className={'tooltip-icon'} src={'/images/info_circle.svg'}/>*/}
                  {/*</Tooltip>*/}
                  {/* </React.Fragment>
                                    } */}

                  {contract.state === ContractStatus.REVIEWED && (
                    <React.Fragment>
                      <Button
                        className="default-btn white"
                        variant="contained"
                        onClick={discardPlaceholder}
                      >
                        Discard
                      </Button>
                      <Button
                        className="default-btn"
                        variant="contained"
                        onClick={() => requestSign()}
                      >
                        Send for signing &nbsp;&nbsp;
                        <img src={"/images/icons/plane.png"} alt="" />
                      </Button>
                    </React.Fragment>
                  )}
                  {/*  <div className="icons" style={{ marginTop: isOpenContractOptionMenu ? '475px' : '0px' }}> */}
                  {/*      <IconButton */}
                  {/*          aria-haspopup="true" */}
                  {/* onClick={() => setIsOpenContractOptionMenu(!isOpenContractOptionMenu)} */}
                  {/* style={{ color: '#3D32CF' }} */}
                  {/* > */}
                  {/* {isOpenContractOptionMenu ? <Close /> : <MoreVert style={{ fontSize: '30px' }} />} */}
                  {!isOpenContractOptionMenu && (
                    <div className="icons">
                      <IconButton
                        aria-haspopup="true"
                        onClick={() => contractOptionMenuHandler()}
                        style={{ color: "#3D32CF" }}
                      >
                        <Menu style={{ fontSize: "30px" }} />
                      </IconButton>

                      {/* {isOpenContractOptionMenu && (
                                            <>
                                                <Tooltip
                                                    title={strings.pages.contractPreview.templateDetailsTooltip}
                                                    placement="left"
                                                    arrow>
                                                    <div className="icon">
                                                        <IconButton disabled={!isUserOwner(user, owners)} onClick={handleDetailsTab}>
                                                            <img src="/images/icons/document.png" />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>

                                                <Tooltip
                                                    title={strings.pages.contractPreview.commentsTooltip}
                                                    placement="left"
                                                    arrow>
                                                    <div className="icon">
                                                        <IconButton disabled={commentsDisabled()} onClick={handleCommentsTab}>
                                                            <img src="/images/icons/comment.png" />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>

                                                <Tooltip
                                                    title={strings.pages.contractPreview.collaboratorsTooltip}
                                                    placement="left"
                                                    arrow>
                                                    <div className="icon">
                                                        <IconButton disabled={collaboratorsDisabled()} onClick={handleCollaboratorsTab}>
                                                            <img src="/images/icons/share.png" />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>

                                                <Tooltip
                                                    title={strings.pages.contractPreview.moveToFolderTooltip}
                                                    placement="left"
                                                    arrow>
                                                    <div className="icon">
                                                        <IconButton onClick={handleFoldersTab} disabled={!isUserOwner(user, owners)}>
                                                            <img src="/images/icons/folder.png" />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>

                                                <Tooltip
                                                    title={strings.pages.contractPreview.downloadTooltip}
                                                    placement="left"
                                                    arrow>
                                                    <div className="icon">
                                                        <IconButton onClick={handleDownloadTab} disabled={isTrial(user)}>
                                                            <img src="/images/icons/download.png" />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>

                                                <Tooltip
                                                    title={strings.pages.contractPreview.revisionHistoryTooltip}
                                                    placement="left"
                                                    arrow>
                                                    <div className="icon" onClick={handleHistoryTab}>
                                                        <IconButton>
                                                            <img src="/images/icons/history.png" />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>

                                                <Tooltip
                                                    title={strings.pages.contractPreview.deleteTooltip}
                                                    placement="left"
                                                    arrow>
                                                    <div className="icon">
                                                        <IconButton disabled={!isUserOwner(user, owners)} onClick={handleDeleteTab}>
                                                            <img src="/images/icons/delete.png" />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            </>)} */}
                    </div>
                  )}
                </React.Fragment>

                {contract.state === ContractStatus.PENDING_E_SIGN &&
                  !signMode &&
                  isUserSignatory() &&
                  !isUserSignedContract() &&
                  showAddSignatureButton && (
                    <Button
                      className="default-btn"
                      variant="contained"
                      onClick={() => {
                        setSignMode(true);
                        setTempPDFContent(pdfContent);
                        addSignatureFunct();
                        setShowAddSignatureButton(false);
                      }}
                    >
                      Add Signature
                    </Button>
                  )}

                {signMode && (
                  <React.Fragment>
                    <Button
                      className="default-btn white"
                      variant="contained"
                      onClick={() => {
                        setSignMode(false);
                        setPDFContent(tempPDFContent);
                        fetchContract();
                        setSignState(false);
                        setShowAddSignatureButton(true);
                      }}
                    >
                      Discard
                    </Button>
                    <Button
                      className="default-btn"
                      variant="contained"
                      onClick={() => {
                        signContract();
                        setShowAddSignatureButton(false);
                      }}
                    >
                      Finish
                    </Button>
                  </React.Fragment>
                )}

                {/*{*/}
                {/*    (contract.state === ContractStatus.REVIEWED) &&*/}
                {/*    <Button className="default-btn" variant="contained"*/}
                {/*            onClick={() => discardChanges()}>*/}
                {/*        Discard changes*/}
                {/*    </Button>*/}
                {/*}*/}
              </div>
            </div>

            {(contract.contentType === ContractContentType.UPLOADED ||
              (contract.contentType === ContractContentType.GENERATED &&
                contract.state >= ContractStatus.REVIEWED)) && (
                <div className="pdf-content">
                  <div className="pdf-top-navigation">
                    <Tooltip title={"Zoom in"} placement="top">
                      <IconButton onClick={zoomIn} color="info">
                        <ZoomIn />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Zoom out"} placement="top">
                      <IconButton onClick={zoomOut} color="info">
                        <ZoomOut />
                      </IconButton>
                    </Tooltip>
                  </div>

                  <div
                    className={
                      showSignBanner &&
                        contract.state === ContractStatus.PENDING_E_SIGN &&
                        isUserSignatory()
                        ? "document-upload-wrapper banner "
                        : "document-upload-wrapper " +
                        ((placePDFSignaturePlaceholder || placePDFSignature) &&
                          signMode
                          ? "place-active"
                          : "")
                    }
                  >
                    {(contract.state === ContractStatus.REVIEWED ||
                      contract.state === ContractStatus.PENDING_E_SIGN) && (
                        <div
                          className={
                            !displayForms
                              ? "pdf-overlay transition"
                              : "pdf-overlay transition"
                          }
                          style={{
                            width: getCanvasWidth(),
                            height: getCanvasHeight(),
                            left:
                              getCanvasLeft() >= 0 ? currentCanvasLeftPosition : 0,
                          }}
                          onClick={(e) => overlayClick(e, 1)}
                        >
                          {renderPlaceholders(1)}
                        </div>
                      )}
                    <Document
                      inputRef={pdfViewer}
                      file={pdfContent}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page
                        scale={pdfScale}
                        pageNumber={1}
                        onRenderSuccess={() => {
                          if (contract.user.id !== user.id) {
                            if (
                              isUserSignedContract() ||
                              contract.state !== ContractStatus.PENDING_E_SIGN
                            ) {
                              return;
                            }
                          }
                          setPlacePDFSignature(true);
                        }}
                      ></Page>
                    </Document>
                  </div>

                  {renderPdfPages()}

                  {/* <nav className='pdf-navigation'>
                                <Button onClick={goToPrevPage} disabled={pageNumber === 1} className="default-btn"
                                        variant="contained">Prev</Button>
                                <Button onClick={goToNextPage} disabled={pageNumber === numPages}
                                        className="default-btn" variant="contained">Next</Button>
                                <p>
                                    Page {pageNumber} of {numPages}
                                </p>
                            </nav> */}
                </div>
              )}
            {contract.contentType === ContractContentType.GENERATED &&
              contract.state < ContractStatus.REVIEWED &&
              !contractContentLoading && (
                <div className="ckEditorContainer" id="container">
                  <div
                    className="commentSideBar"
                    style={{ minWidth: isOpenCommentSideBar ? "20vw" : "3vw" }}
                  >
                    <div
                      className="commentBoxHeader"
                      style={{
                        justifyContent: isOpenCommentSideBar
                          ? "flex-start"
                          : "center",
                      }}
                      onClick={isOpenCommentSideBarHandler}
                      title={isOpenCommentSideBar ? "hide" : "show"}
                    >
                      {isOpenCommentSideBar ? (
                        <ArrowForwardIos />
                      ) : (
                        <ArrowBackIos />
                      )}
                    </div>
                    {/* <div id="editor"><p>Hllo body</p></div> */}

                    <div
                      style={{
                        // display: isOpenCommentSideBar ? "flex" : "none",
                        maxWidth: isOpenCommentSideBar ? "100%" : "30px",
                      }}
                      className="sidebar"
                    ></div>
                  </div>
                  <div
                    className={
                      "contract " +
                      (!isTrialPackageValid(user, contractCount)
                        ? "disable-scroll"
                        : "")
                    }
                    style={{ maxWidth: isOpenCommentSideBar ? "70vw" : "88vw" }}
                  >
                    <CKEditor
                      onReady={(editor) => {
                        editor.execute("trackChanges");
                        editor.getData({ showSuggestionHighlights: true });
                        editor.plugins
                          .get("AnnotationsUIs")
                          .switchTo("narrowSidebar");

                        const commentsRepository =
                          editor.plugins.get("CommentsRepository");

                        commentsRepository.on(
                          `change:activeCommentThread`,
                          (evt2, data2, value) => {
                            const commonAncestor =
                              document.querySelector(".ckEditorContainer");
                            commonAncestor.addEventListener(
                              "click",
                              handleClickToScrollRelatedComment
                            );
                            commonAncestor.removeEventListener(
                              "click",
                              handleClickToScrollRelatedComment
                            );
                            addSignatureFunct(value?.id);
                            value?.id && setActiveThreadId(value.id);
                            value?.channelId && setChannelId(value.channelId);
                          }
                        );

                        // commentsRepository.on(
                        //   `set:activeCommentThread`,
                        //   (evt2, data2, value) => {
                        //     setIsOpenCommentSideBar(true);
                        //     setActiveThreadId(value?.id ?? "");
                        //     setChannelId(value?.channelId ?? "");
                        //   }
                        // );

                        editor?.ui
                          ?.getEditableElement()
                          ?.parentElement?.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement()
                          );
                        setEditor(editor);
                        if (content) {
                          editor.data.set(content, {
                            suppressErrorInCollaboration: true,
                          });
                        }
                      }}
                      onError={(error, { willEditorRestart }) => {
                        if (willEditorRestart) {
                          editor.ui.view.toolbar.element.remove();
                        }
                      }}
                      onChange={(event, editor) => {
                        const commentsRepository =
                          editor.plugins.get("CommentsRepository");
                        setDocChange(!docChange);

                        commentsRepository.on(
                          `addCommentThread:${channelId}`,
                          (evt1, data1, value) => {
                            setActiveThreadId(data1?.threadId);
                          }
                        );
                      }}
                      on
                      disabled={isEditDisabled(contract, user, contractCount)}
                      editor={Editor}
                      config={{
                        extraPlugins: [TrackChangesIntegration],
                        toolbar: [
                          "heading",
                          "|",
                          "fontfamily",
                          "fontsize",
                          "|",
                          "wproofreader",
                          "|",
                          "alignment",
                          "|",
                          "fontColor",
                          "fontBackgroundColor",
                          "|",
                          "bold",
                          "italic",
                          "strikethrough",
                          "underline",
                          "|",
                          "link",
                          "|",
                          "outdent",
                          "indent",
                          "|",
                          "bulletedList",
                          "numberedList",
                          "|",
                          "insertTable",
                          "|",
                          "undo",
                          "redo",
                          "|",
                          "trackChanges",
                          "comment",
                          "commentsArchive",
                          "|",
                          "pageBreak",
                          "highlight",
                        ],
                        link: { addTargetToExternalLinks: true },
                        collaboration: {
                          channelId: "contract-" + contract.id,
                        },
                        presenceList: {
                          container: document.querySelector(
                            ".presence-list-container"
                          ),
                          collapseAt: 3,
                          onClick: (user, element) =>
                            console.log(user, element),
                        },
                        sidebar: {
                          container: document.querySelector(".sidebar"),
                        },
                        users: {
                          colorsCount: 10,
                        },
                        autosave: {
                          waitingTime: 2000, // in ms
                          extraOptions: {
                            readyAutoSave: false,
                            firstLoad: true,
                          },
                          save(editor) {
                            if (editor) {
                              if (
                                editor?.config?._config?.autosave?.extraOptions
                                  ?.firstLoad
                              ) {
                                editor.config._config.autosave.extraOptions.firstLoad = false;
                                editor.config._config.autosave.extraOptions.readyAutoSave = true;
                                return;
                              }

                              if (
                                editor.config._config.autosave.extraOptions
                                  .readyAutoSave
                              ) {
                                handleAutoSave(editor);
                              }
                            }
                          },
                        },
                        cloudServices: {
                          tokenUrl:
                            process.env.REACT_APP_BASE_URL +
                            "api/user/get-cs-token/" +
                            getUserFromLocalStorage()?.email +
                            "/" +
                            getUserFromLocalStorage()?.fullName,
                        },
                        importWord: {
                          tokenUrl:
                            process.env.REACT_APP_BASE_URL +
                            "api/user/get-cs-token/" +
                            getUserFromLocalStorage()?.email +
                            "/" +
                            getUserFromLocalStorage()?.fullName,
                        },
                        exportPdf: {
                          tokenUrl:
                            process.env.REACT_APP_BASE_URL +
                            "api/user/get-cs-token/" +
                            getUserFromLocalStorage()?.email +
                            "/" +
                            getUserFromLocalStorage()?.fullName,
                        },
                        exportWord: {
                          tokenUrl:
                            process.env.REACT_APP_BASE_URL +
                            "api/user/get-cs-token/" +
                            getUserFromLocalStorage()?.email +
                            "/" +
                            getUserFromLocalStorage()?.fullName,
                        },
                      }}
                    />
                  </div>
                </div>
              )}

            {showSignBanner &&
              contract.state === ContractStatus.PENDING_E_SIGN &&
              isUserSignatory() &&
              !isUserSignedContract() &&
              !signMode &&
              showAddSignatureButton && (
                <div
                  className={
                    menuState === MenuState.FULL
                      ? "sign-banner reduce"
                      : "sign-banner"
                  }
                >
                  <img
                    src={"/images/icons/close.png"}
                    onClick={() => setShowSignBanner(false)}
                    alt="close"
                  />
                  <div className={"sign-banner-content"}>
                    <div className={"left"}>
                      <h2>Ready to sign?</h2>
                      <p>
                        Click <b>Add signature</b> and place your signature on
                        the contract
                      </p>
                    </div>
                    <div className={"button"}>
                      <Button
                        className="default-btn"
                        variant="contained"
                        onClick={() => {
                          setSignMode(true);
                          setTempPDFContent(pdfContent);
                          addSignatureFunct();
                          setShowAddSignatureButton(false);
                        }}
                      >
                        Add Signature
                      </Button>
                    </div>
                  </div>
                </div>
              )}

            {showSignBanner &&
              contract.state === ContractStatus.PENDING_E_SIGN &&
              signMode &&
              isUserSignatory() &&
              !isUserSignedContract() &&
              !signState && (
                <div
                  className={
                    menuState === MenuState.FULL
                      ? "sign-banner reduce"
                      : "sign-banner"
                  }
                >
                  <img
                    src={"/images/icons/close.png"}
                    onClick={() => setShowSignBanner(false)}
                    alt="close"
                  />
                  <div className={"sign-banner-content"}>
                    <div className={"left"}>
                      <h2>Ready to sign?</h2>
                      <p>Place your signature on the contract</p>
                    </div>
                    <div className={"button"}>
                      <Button
                        className="default-btn"
                        variant="contained"
                        onClick={() => {
                          signContract();
                          setShowAddSignatureButton(false);
                        }}
                      >
                        Finish
                      </Button>
                    </div>
                  </div>
                </div>
              )}

            {showSignBanner &&
              contract.state === ContractStatus.PENDING_E_SIGN &&
              signMode &&
              isUserSignatory() &&
              signState && (
                <div
                  className={
                    menuState === MenuState.FULL
                      ? "sign-banner reduce"
                      : "sign-banner"
                  }
                >
                  <img
                    src={"/images/icons/close.png"}
                    onClick={() => setShowSignBanner(false)}
                    alt="close"
                  />
                  <div className={"sign-banner-content"}>
                    <div className={"left"}>
                      <h2>Ready to finish?</h2>
                      <p>
                        You’ve added your signature. Review your work and then
                        select Finish
                      </p>
                    </div>

                    <div className={"button"}>
                      <Button
                        className="default-btn"
                        variant="contained"
                        onClick={() => {
                          signContract();
                          setShowAddSignatureButton(false);
                        }}
                      >
                        Finish
                      </Button>
                    </div>
                  </div>
                </div>
              )}
          </div>

          {displayForms && (
            <React.Fragment>
              {detailsTab && (
                <DetailsForm
                  handleEditContract={handleEditContract}
                  setDisplayForms={setDisplayForms}
                  contract={contract}
                  setContract={setContract}
                  setIsOpenContractOptionMenu={setIsOpenContractOptionMenu}
                // allContracts={allContracts}
                />
              )}

              {commentsTab && (
                <CommentList
                  setDisplayForms={setDisplayForms}
                  contract={contract}
                  editor={editor}
                  contractContent={content}
                  currentUser={user}
                />
              )}

              {collaboratorsTab && (
                <CollaboratorsForm
                  setCollaboratorForPlacement={setCollaboratorForPlacement}
                  collaboratorForPlacement={collaboratorForPlacement}
                  uploadedContent={pdfContent}
                  submitNewCollaborator={submitNewCollaborator}
                  setDisplayForms={setDisplayForms}
                  setCollaboratorsTab={setCollaboratorsTab}
                  collaborators={collaborators}
                  setParentCollaborators={setCollaborators}
                  contract={contract}
                  fetchContract={fetchContract}
                  owners={owners}
                  isUserOwner={isUserOwner(user, owners)}
                  editor={editor}
                  setPlacePDFSignaturePlaceholder={
                    setPlacePDFSignaturePlaceholder
                  }
                  placePDFSignaturePlaceholder={placePDFSignaturePlaceholder}
                  setPlacePDFNamePlaceholder={setPlacePDFNamePlaceholder}
                  placePDFNamePlaceholder={placePDFNamePlaceholder}
                  setPlacePDFDatePlaceholder={setPlacePDFDatePlaceholder}
                  placePDFDatePlaceholder={placePDFDatePlaceholder}
                  addTextPlaceholder={addTextPlaceholder}
                  setAddTextPlaceholder={setAddTextPlaceholder}
                  forceRender={forceRender}
                  setIsOpenContractOptionMenu={setIsOpenContractOptionMenu}
                  setSignatures={setSignatures}
                  signatures={signatures}
                  addRectangle={addRectangle}
                />
              )}

              {foldersTab && (
                <FoldersForm
                  contract={contract}
                  submitMoveToFolder={submitMoveToFolder}
                  setDisplayForms={setDisplayForms}
                  setIsOpenContractOptionMenu={setIsOpenContractOptionMenu}
                />
              )}

              {historyTab && (
                <HistoryList
                  isOwner={isUserOwner(user, owners)}
                  fetch={fetchContract}
                  setContent={handleChangeHistoryView}
                  setDisplayForms={setDisplayForms}
                  contract={contract}
                  setIsOpenContractOptionMenu={setIsOpenContractOptionMenu}
                />
              )}
              {/* {signatureTab && (
                <OwnerSignatureForm
                  setDisplayForms={setDisplayForms}
                  contract={contract}
                  setSignatures={setSignatures}
                  signatures={signatures}
                  addRectangle={addRectangle}
                  setIsOpenContractOptionMenu={setIsOpenContractOptionMenu}
                  forceRender={forceRender}
                />
              )} */}
            </React.Fragment>
          )}

          {isOpenContractOptionMenu && (
            <div className="icons">
              <Tooltip
                title={strings.pages.contractPreview.templateDetailsTooltip}
                placement="left"
                arrow
              >
                <div className="icon">
                  <IconButton
                    disabled={!isUserOwner(user, owners)}
                    onClick={handleDetailsTab}
                  >
                    <img src="/images/icons/document.png" alt="" />
                  </IconButton>
                </div>
              </Tooltip>

              {/* <Tooltip
                                title={strings.pages.contractPreview.commentsTooltip}
                                placement="left"
                                arrow>
                                <div className="icon">
                                    <IconButton disabled={commentsDisabled()} onClick={handleCommentsTab}>
                                        <img src="/images/icons/comment.png" />
                                    </IconButton>
                                </div>
                            </Tooltip> */}

              <Tooltip
                title={strings.pages.contractPreview.collaboratorsTooltip}
                placement="left"
                arrow
              >
                <div className="icon">
                  <IconButton
                    disabled={collaboratorsDisabled()}
                    onClick={handleCollaboratorsTab}
                  >
                    <img src="/images/icons/share.png" alt="" />
                  </IconButton>
                </div>
              </Tooltip>

              <Tooltip
                title={strings.pages.contractPreview.moveToFolderTooltip}
                placement="left"
                arrow
              >
                <div className="icon">
                  <IconButton
                    onClick={handleFoldersTab}
                    disabled={!isUserOwner(user, owners)}
                  >
                    <img src="/images/icons/folder.png" alt="" />
                  </IconButton>
                </div>
              </Tooltip>

              <Tooltip
                title={strings.pages.contractPreview.downloadTooltip}
                placement="left"
                arrow
              >
                <div className="icon">
                  <IconButton
                    onClick={handleDownloadTab}
                    disabled={isTrial(user)}
                  >
                    <img src="/images/icons/download.png" alt="" />
                  </IconButton>
                </div>
              </Tooltip>

              <Tooltip
                title={strings.pages.contractPreview.revisionHistoryTooltip}
                placement="left"
                arrow
              >
                <div className="icon">
                  <IconButton
                    disabled={isUserSignedContract()}
                    onClick={handleHistoryTab}
                  >
                    <img src="/images/icons/history.png" />
                  </IconButton>
                </div>
              </Tooltip>

              <Tooltip
                title={strings.pages.contractPreview.deleteTooltip}
                placement="left"
                arrow
              >
                <div className="icon">
                  <IconButton
                    disabled={!isUserOwner(user, owners)}
                    onClick={handleDeleteTab}
                  >
                    <img src="/images/icons/delete.png" alt="" />
                  </IconButton>
                </div>
              </Tooltip>
              {/* <Tooltip
                title={strings.pages.contractPreview.text}
                placement="left"
                arrow
              >
                <div className="icon">
                  <IconButton
                    disabled={!isUserOwner(user, owners)}
                    onClick={handleSignatureTab}
                  >
                    <img src="/images/icons/signature.png" />
                  </IconButton>
                </div>
              </Tooltip> */}
            </div>
          )}

          <Modal
            open={openInfoModal}
            onClose={() => setOpenInfoModal(false)}
            className="confirm-modal-container"
          >
            <ConfirmModal
              contentText={openInfoModalText}
              confirmBtn={"Ok"}
              handleConfirm={() => {
                setOpenInfoModal(false);
                resetTabs();
                // addOwnerAsCollaborator();
                setCollaboratorsTab(true);
              }}
            />
          </Modal>

          <Modal open={showLocalLoader} className="confirm-modal-container">
            <div className="loader-container">
              <p style={{ textAlign: "center" }}>{showLocalLoaderText} </p>
              <img src={"/images/LC.gif"} alt="" />
            </div>
          </Modal>

          <Modal
            open={downloadTab}
            onClose={handleCloseDownloadTab}
            className="confirm-modal-container"
          >
            <SimpleConfirmModal
              title={strings.modals.contractList.downloadContract.confirmText}
              cancelBtn={strings.modals.contractList.downloadContract.cancel}
              confirmBtn={strings.modals.contractList.downloadContract.yes}
              handleCloseModal={handleCloseDownloadTab}
              handleConfirm={handleDownloadConfirm}
            />
          </Modal>

          <Modal
            open={deleteTab}
            onClose={handleCloseDeleteTab}
            className="confirm-modal-container"
          >
            <SimpleConfirmModal
              title={strings.modals.contractList.deleteContract.confirmText}
              cancelBtn={strings.modals.contractList.deleteContract.no}
              confirmBtn={strings.modals.contractList.deleteContract.yes}
              confirmBtnClass={true}
              handleCloseModal={handleCloseDeleteTab}
              handleConfirm={handleDeleteConfirm}
            />
          </Modal>

          <Modal
            open={openNonValidSubscription}
            onClose={() => setOpenNonValidSubscription(false)}
            className="confirm-modal-container"
          >
            <ConfirmModal
              contentText={
                isTrial(user)
                  ? strings.modals.contractList.nonValidSubscription
                    .confirmTextFreeTrial
                  : strings.modals.contractList.nonValidSubscription
                    .confirmTextSubscriptionEnd
              }
              cancelBtn={strings.modals.contractList.nonValidSubscription.no}
              confirmBtn={strings.modals.contractList.nonValidSubscription.yes}
              handleCloseModal={() => setOpenNonValidSubscription(false)}
              handleConfirm={() => navigate("/pricing-and-package")}
            />
          </Modal>

          <Modal
            open={acceptReviewOpen}
            onClose={() => setAcceptReviewOpen(false)}
            className="confirm-modal-container"
          >
            <ConfirmModal
              contentText={
                "Completing the contract accepts all amendments made by each party. It is suggested that you review and accept each amendment on its own merit."
              }
              cancelBtn={"Cancel"}
              confirmBtn={"Complete"}
              handleCloseModal={() => setAcceptReviewOpen(false)}
              handleConfirm={() => {
                setContractContentLoading(true);
                updateContractState(contract, ContractStatus.REVIEWED);
                setAcceptReviewOpen(false);
              }}
            />
          </Modal>

          <Modal
            open={placeAllSignatures}
            onClose={() => setPlaceAllSignatures(false)}
            className="confirm-modal-container"
          >
            <ConfirmModal
              contentText={"Please place all signatures"}
              confirmBtn={"Ok"}
              handleCloseModal={() => {
                setPlaceAllSignatures(false);
                setShowAddSignatureButton(true);
              }}
              handleConfirm={() => {
                setPlaceAllSignatures(false);
                setShowAddSignatureButton(true);
              }}
            />
          </Modal>

          <Modal
            open={openAdoptSignModal}
            onClose={() => setOpenAdoptSignModal(false)}
            className="confirm-modal-container"
          >
            <AdoptSignatureForm setOpenAdoptSignModal={setOpenAdoptSignModal} />
          </Modal>

          <Modal
            open={removedPermissioModal}
            onClose={() => navigate("/dashboard")}
            className="confirm-modal-container"
          >
            <ConfirmModal
              contentText={
                "Your permission to review this contract has been revoked"
              }
              confirmBtn={"Ok"}
              handleCloseModal={() => navigate("/dashboard")}
              handleConfirm={() => navigate("/dashboard")}
            />
          </Modal>

          {/* <Modal
                    open={openAiAssistModal}
                    onClose={() => setOpenAiAssistModal(false)}
                    className='ai-assist-modal-container'
                >
                    <AIChat 
                        className='ai-popup-modal'
                        handleClickQuestion={(question) => setSelectedQuestion(question)}
                        question={selectedQuestion}
                        setSelectedQuestion={setSelectedQuestion}
                        exampleQuestions={conversationsExample}
                        onClose={() => setOpenAiAssistModal(false)} 
                        onNewTab={() => navigate('/ai-assist')}
                    />
                </Modal> */}

          {openAiAssistModal && (
            <div ref={aiAssistModal}>
              <ResizableBox width={600} onResize={""} resizeHandles={["w"]}>
                <AIChat
                  className="ai-popup-modal"
                  handleClickQuestion={(question) =>
                    setSelectedQuestion(question)
                  }
                  chatID={chatID}
                  setChatID={setChatID}
                  question={selectedQuestion}
                  setSelectedQuestion={setSelectedQuestion}
                  exampleQuestions={conversationsExample}
                  onClose={() => setOpenAiAssistModal(false)}
                  template={contract?.template}
                  addNewDataInContract={addNewDataInContract}
                // onNewTab={() => navigate('/ai-assist')}
                // onNewConversation={() => setSelectedQuestion(null)}
                />
              </ResizableBox>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default ContractPreview;
