import React from 'react';
import strings from '../../../../../localization';
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import {FormProvider} from "react-hook-form";
import PackageForm from './PackageForm';
import ContractForm from './ContractForm';
import {Button} from "@material-ui/core";
import {toBase64} from "../../../../../Util/ImageUtil";

const readFile = async (file, setValue) => {
    const image = await toBase64(file);
    setValue('icon', image);
}

const AdminCategoryForm = ({
                               values,
                               errors,
                               data,
                               form,
                               onPackagesChange,
                               setValue
                           }) => {

    return (
        <FormProvider {...form}>

            <>
                <div className="form-row form-row-with-inputs">
                    <div className="item input-wrap long-input-wrap right-margin">
                        <TextFieldControl
                            name='name'
                            control={data}
                            defaultValue=''
                            rules={{required: true}}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.name)}
                            helperText={errors.name && strings.forms.admin.adminCategoryForm.category}
                            placeholder={strings.forms.admin.adminCategoryForm.categoryName}
                            inputRef
                        />
                    </div>
                    <div className="item input-wrap right-margin">
                        <TextFieldControl
                            name='color'
                            control={data}
                            defaultValue=''
                            rules={{required: true}}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.color)}
                            helperText={errors.color && strings.forms.admin.adminCategoryForm.color}
                            placeholder={strings.forms.admin.adminCategoryForm.colorName}
                        />
                    </div>
                    <div className="item input-wrap right-margin">
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={(e) => {

                                readFile(e.target.files[0], setValue);
                            }}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="raised" component="span">
                                Icon
                            </Button>
                        </label>
                    </div>
                    <div className="item input-wrap long-input-wrap right-margin">
                        <TextFieldControl
                            name='description'
                            control={data}
                            defaultValue=''
                            rules={{required: true}}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.description)}
                            helperText={errors.description && strings.forms.admin.adminCategoryForm.description}
                            placeholder={strings.forms.admin.adminCategoryForm.description}
                        />
                    </div>
                </div>

            </>

        </FormProvider>
    )
}

export default AdminCategoryForm;