import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
    Box,
    Modal,
    Checkbox,
    TextField,
    IconButton,
    Button,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Stack,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import strings from '../../../../localization';
import { useNavigate, useParams } from "react-router-dom";
import ConfirmModal from '../../../../Components/Modals/SimpleConfirmModal';
import { deleteContractById, getAllContractByFolderId, getContractById, getMultipleContractZip } from '../../../../Services/DealRoom/DataRoomService';
import SnackbarContext from '../../../../Context/SnackbarContext';
import FileUploadForm from '../../../../Components/Forms/Pages/DealRoom/DataRoom/FileUploadForm';
import { useSelector } from 'react-redux';


const RightSideBar = () => {
    const params = useParams()
    const navigate = useNavigate();
    const { showMessage } = useContext(SnackbarContext);

    const index = useSelector(state => state.dataRoom.index)

    const [optionMenuAnchorEl, setOptionMenuAnchorEl] = useState(null);
    const [menuId, setMenuId] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [contractId, setContractId] = useState(null)
    const [selectedContracts, setSelectedContracts] = useState([]);
    const [searchText, setSearchText] = useState("")
    const [showcontractsData, setShowContractsData] = useState([])
    const [openDocumentModal, setOpenDocumentModal] = useState(false);
    const [contractsData, setContractsData] = useState([]);


    useEffect(() => {
        if (searchText) {
            const tempData = contractsData?.filter((item) => item?.name?.toLowerCase().includes(searchText?.toLowerCase()))
            setShowContractsData(tempData)
        } else {
            setShowContractsData(contractsData)
        }
    }, [searchText, contractsData])





    const handleMenuOpen = (event, menuId) => {
        setOptionMenuAnchorEl(event.currentTarget);
        setMenuId(menuId);
    };

    const handleMenuClose = () => {
        setOptionMenuAnchorEl(null);
        setMenuId(null);
    };

    const handleOpenDeleteModal = (id) => {
        console.log("deleteid", id)
        setOpenDeleteModal(true);
        setContractId(id)
        setOptionMenuAnchorEl(null);

    }
    const handleDeleteContract = () => {
        deleteContractById(params?.id, contractId)
            .then(res => {
                setOpenDeleteModal(false)
                getContractsHandler()
                showMessage(strings.common.deleted)
            })
    }

    const handleOpenAndDownload = (contractId, action) => {
        getContractById(params?.id, contractId)
            .then(res => {
                if (action === 'open') {
                    navigate(`/virtualDealRoom/DocPreview/${params?.id}`, { state: res.data })
                    // viewPDF(res?.data);
                } else if (action === 'download') {
                    downloadPDF(res?.data);
                }
                setOptionMenuAnchorEl(null);
            });
    };

    const viewPDF = (res) => {
        downloadPDF(res, true);
    };

    const downloadPDF = (res, openInNewTab = false) => {
        // Extract base64 data from the data URI
        const base64Data = res.split(',')[1];

        // Convert base64 to binary data
        const binaryData = atob(base64Data);
        const byteArray = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
        }

        // Create Blob from binary data
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        if (openInNewTab) {
            // Open PDF in a new browser tab
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        } else {
            // Create download link
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'downloaded_pdf.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
    };

    const handleCheckboxChange = (event, contractId) => {
        if (event.target.checked) {
            setSelectedContracts([...selectedContracts, contractId]);
        } else {
            setSelectedContracts(selectedContracts.filter(id => id !== contractId));
        }
    };

    const handleExport = () => {
        getMultipleContractZip(params?.id, selectedContracts)
            .then((res) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([res.data]));
                link.setAttribute('download', 'download.zip');
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                console.error("err", err);
            })
    };

    const handleAllContractSelected = (e) => {
        if (e.target.checked === true) {
            setSelectedContracts(contractsData?.map(item => item?.id))
        } else {
            setSelectedContracts([]);
        }
    }

    const handleCloseDocumentModal = () => {
        setOpenDocumentModal(false);
    };
    const handleConfirmDocumentModal = (data) => {
        setOpenDocumentModal(false);
        getContractsHandler()
    };

    const getContractsHandler = useCallback(() => {
        getAllContractByFolderId(params?.id, index.currentFolderId)
            .then((response) => {
                setContractsData(response?.data);
            })
    }, [index])


    useEffect(() => {
        if (index.currentFolderId) {
            getContractsHandler()
        }
        else {
            setContractsData([]);
        }
    }, [index])


    return (
        <>
            <Box className="dataroom-right-content">
                <Box
                    className="dataroom-right-header"
                    display="flex"
                    alignItems="center"
                    mb={4}
                >
                    <Checkbox ml={2}
                        onChange={(e) => handleAllContractSelected(e)} />
                    <TextField
                        name="search"
                        className="textfield-control dataroom-search"
                        defaultValue=""
                        margin="normal"
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        placeholder={strings.pages.dealRoom.dataRoom.searchDocument}
                        icon={
                            <IconButton>
                                <img src="/images/icons/search.png" alt="search" />
                            </IconButton>
                        }
                    />
                    <Button
                        className="default-btn btn"
                        startIcon={<FileUploadIcon />}
                        variant="contained"
                        sx={{ marginRight: "3rem" }}
                        onClick={() => {
                            setOpenDocumentModal(true);
                        }}
                        disabled={index.currentFolderId ? false : true}
                    >
                        <span className="btn-txt">
                            {strings.pages.dealRoom.dataRoom.uploadDocument}
                        </span>
                    </Button>
                    <Button
                        className="neutral-btn btn"
                        startIcon={
                            <IconButton>
                                <img src="/images/icons/menu/Download.png" alt="upload" />
                            </IconButton>
                        }
                        variant="contained"
                        onClick={() => handleExport()}
                        disabled={selectedContracts.length === 0}
                        sx={{ color: '#283959' }}
                    >
                        {strings.pages.dealRoom.dataRoom.exportDocument}
                    </Button>
                </Box>

                {showcontractsData?.map((contract, index) => (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        bgcolor="white"
                        className="data-room-files-list"
                        key={index}
                    >
                        <Box display="flex">
                            <Checkbox
                                checked={selectedContracts.includes(contract.id)}
                                onChange={(e) => handleCheckboxChange(e, contract.id)}
                            />
                            <Box display="flex" flexDirection="column" ml={2}>
                                <Typography variant="body2" fontWeight={500}>
                                    {contract?.name}
                                </Typography>
                                <Typography variant="caption" color="#8494B1">
                                    {contract?.dateCreated.split("T")[0]}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <IconButton onClick={(event) => handleMenuOpen(event)}>
                                <img src="/images/icons/gray-deactivate.png" alt="upload" />
                            </IconButton>
                            <Menu
                                id="contract-list-menu"
                                anchorEl={optionMenuAnchorEl}
                                open={Boolean(optionMenuAnchorEl)}
                                onClose={() => handleMenuClose()}
                            >
                                <MenuItem id="menuItem-icon" onClick={() => handleOpenAndDownload(contract?.id, "open")}>
                                    <ListItemIcon>
                                        <img src={"/images/icons/dealRoomEye.png"} alt="open" />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={
                                            <span
                                                style={{
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    lineHeight: "0",
                                                }}
                                            >
                                                Open
                                            </span>
                                        }
                                    />
                                </MenuItem>

                                <MenuItem
                                    id="menuItem-icon"
                                    onClick={() => handleOpenDeleteModal(contract?.id)}
                                >
                                    <ListItemIcon>
                                        <img
                                            src={"/images/icons/dataRoomDelete.png"}
                                            alt="delete"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={
                                            <span
                                                style={{
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    lineHeight: "0",
                                                }}
                                            >
                                                Delete
                                            </span>
                                        }
                                    />
                                </MenuItem>

                                <MenuItem
                                    id="menuItem-icon"
                                    onClick={() => handleOpenAndDownload(contract?.id, "download")}
                                >
                                    <ListItemIcon>
                                        <img
                                            src={"/images/icons/dataRoomDownload.png"}
                                            alt="download"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={
                                            <span
                                                style={{
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    lineHeight: "0",
                                                }}
                                            >
                                                Download
                                            </span>
                                        }
                                    />
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                ))}


            </Box>
            <Modal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                className="confirm-modal-container"
            >
                <ConfirmModal
                    title={"Are you sure you want to delete ?"}
                    cancelBtn={strings.modals.adminDealRoom.cancel}
                    confirmBtn={strings.modals.adminDealRoom.ok}
                    handleCloseModal={() => setOpenDeleteModal(false)}
                    handleConfirm={() => handleDeleteContract()}
                    contentTextStyle={{ fontWeight: "600", fontSize: "22px" }}
                    buttonsStyles={{ borderRadius: "10px !important" }}
                />
            </Modal>
            <Modal
                open={openDocumentModal}
                onClose={handleCloseDocumentModal}
                className="confirm-modal-container"
            >
                <FileUploadForm
                    handleCloseModal={handleCloseDocumentModal}
                    handleConfirm={handleConfirmDocumentModal}
                    projectId={params?.id}
                    folderId={index.currentFolderId}
                />
            </Modal>
        </>
    )
}

export default RightSideBar