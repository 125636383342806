import React, { useEffect, useState } from "react";
import { Button, Modal, Box } from "@mui/material";
import { Add } from "@mui/icons-material";
import strings from "../../../../localization";
import { dueDeligenceRowObj } from "../../../../Constants/Admin/DealRoomModel";
import SectionRow from "./SectionRow";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { editSectionsAdmin } from "../../../../Services/DealRoom/AdminDealRoomService";

const SectionList = ({
  sectionList,
  sectionsType,
  getSectionListHandler,
  showMessage,
  activeOpenSection,
  setActiveOpenSection,
}) => {
  const [rows, setRows] = useState(sectionList);

  const addNewRowHandler = () => {
    let tempData = dueDeligenceRowObj;
    tempData.orderNumber = rows.length + 1;
    setRows([...rows, tempData]);
  };

  const onDragEnd = (result) => {
    let tempSection;
    if (!result.destination) return;
    const newItems = Array.from(rows);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    newItems.forEach((item) => {
      if (item.id === result.draggableId) {
        item.orderNumber = result.destination.index + 1;
        tempSection = item;
      }
    });

    editSectionsAdmin(tempSection)
      .then((res) => {
        if (res.status === 204) {
          // getSectionListHandler()
        }
      })
      .catch((err) => {
        console.log("error in editSectionsAdmin", err);
      });

    setRows(newItems);
  };

  useEffect(() => {
    if (sectionList?.length > 0) {
      setRows(sectionList);
    }
  }, [sectionList]);

  return (
    <div className="due-diligence-accordion-table">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {rows?.map((row, index) => {
                return (
                  <SectionRow
                    key={index}
                    row={row}
                    sectionIndex={index}
                    setRows={setRows}
                    sectionsType={sectionsType}
                    getSectionListHandler={getSectionListHandler}
                    showMessage={showMessage}
                    setActiveOpenSection={setActiveOpenSection}
                    activeOpenSection={activeOpenSection}
                  />
                );
              })}
              <Box display={"flex"} justifyContent="center">
                <Button
                  className="new-section-button"
                  onClick={addNewRowHandler}
                >
                  <Add />
                  {strings.pages.adminDealRoom.newSection}
                </Button>
              </Box>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SectionList;
