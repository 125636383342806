import React from "react";
import {Link} from "react-router-dom";
import {Grid, Paper} from "@mui/material";
import strings from "../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import Button from '@mui/material/Button';

const ResetPasswordSuccess = () => {

    const dispatch = useDispatch();
    dispatch(changePageSizeState(PageSizeState.FULL));

    return <div id='reset-password-request' className={'full-form-page reset-password-page password-succes-page'}>
        <Grid item md={3.5} className="reset-password-grid">
            <Paper className='paper'>

                <img className="logo-img" src='/images/logo.png' />

                <h1>{ strings.pages.resetPasswordSuccess.success }</h1>
                <p>{strings.pages.resetPasswordSuccess.description}</p>
            
                <Link to={'/login'}> 
                    <Button className="auth-btn btn" variant="contained">
                        {strings.pages.resetPasswordSuccess.signIn}
                    </Button>
                </Link>
            
            </Paper>
        </Grid>
    </div>
};
export default ResetPasswordSuccess;
