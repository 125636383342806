import React, { useContext, useEffect, useState } from "react";
import { Box, Modal, Button, Typography } from "@mui/material";
import SwitchControl from "../../../Components/Controls/Inputs/SwitchControl";
import SectionList from "./Components/SectionList";
import DealRoomPrice from "./DealRoomPrice";
import SnackbarContext from "../../../Context/SnackbarContext";
import {
  getSectionsAdmin,
  publishQuestionairAdmin,
} from "../../../Services/DealRoom/AdminDealRoomService";

const AdminDealRoom = () => {
  const { showMessage } = useContext(SnackbarContext);
  const [sectionsType, setSectionsType] = useState(0);
  const [sectionList, setSectionList] = useState([]);
  const [activeOpenSection, setActiveOpenSection] = useState([]);
  const [openEditDealRoomPriceModal, setOpenEditDealRoomPriceModal] =
    useState(false);

  const getSectionListHandler = () => {
    getSectionsAdmin(sectionsType)
      .then((res) => {
        if (res?.data) {
          let tempData = res?.data?.map((obj) => {
            obj.iseditable = false;
            if (activeOpenSection.includes(obj.id)) {
              obj.isOpen = true;
            } else {
              obj.isOpen = false;
            }
            obj.questions = obj.questions.map((queObj) => {
              queObj.iseditable = false;
              return queObj;
            });
            return obj;
          });
          tempData.sort((a, b) => a.orderNumber - b.orderNumber);
          setSectionList(tempData);
        } else {
          showMessage("Error in fetching admin section details", "error");
        }
      })
      .catch((err) => {
        console.log("error in getSectionsAdmin", err);
      });
  };

  const toggleDueDeligence = () => {
    if (sectionsType === 0) {
      setSectionsType(1);
    } else {
      setSectionsType(0);
    }
  };

  const publishHandler = () => {
    publishQuestionairAdmin()
      .then((res) => {
        if (res.status === 204) {
          showMessage("Published Successfully!");
        } else {
          showMessage("Error in Publishing", "error");
        }
      })
      .catch((err) => {
        console.log("error in publishQuestionairAdmin", err);
      });
  };

  useEffect(() => {
    getSectionListHandler();
  }, [sectionsType]);

  return (
    <>
      <Box display="flex" flexDirection="column" className="admin-deal-room">
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ width: "100%" }}
          mt={4}
        >
          <Box sx={{ width: "40%" }}>
            <Button
              className="default-btn btn"
              variant="contained"
              onClick={() => setOpenEditDealRoomPriceModal(true)}
            >
              Edit Price
            </Button>
          </Box>

          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={1}
          >
            <Typography
              variant="h5"
              fontWeight={500}
              sx={{ margin: "0 0.5rem" }}
              className="left-toggle-value"
            >
              Legal
            </Typography>
            <SwitchControl
              onClick={toggleDueDeligence}
              noLeftMargin={true}
              className="switch-control"
              style={{
                marginTop: "0px",
                height: "30px",
                width: "78px",
                marginRight: "5px",
                marginLeft: "0px !important",
              }}
              controlCustomStyle={{
                height: "30px",
                width: "76px",
                marginLeft: "0px",
              }}
              tabActiveStyle={{
                marginLeft: "auto",
                height: "30px",
                width: "30px",
              }}
              tabWhiteStyle={{ height: "30px", width: "30px" }}
              showValues={false}
              value={sectionsType}
            />
            <Typography
              variant="h5"
              fontWeight={500}
              className="left-toggle-value"
              sx={{ margin: "0 0.5rem" }}
            >
              Financial
            </Typography>
            <Button
              className="default-btn btn"
              variant="contained"
              sx={{ marginLeft: "0.5rem" }}
              onClick={publishHandler}
            >
              Publish
            </Button>
          </Box>
        </Box>

        <Box>
          <SectionList
            style={{ marginTop: "10rem", width: "100%" }}
            sectionList={sectionList}
            sectionsType={sectionsType}
            getSectionListHandler={getSectionListHandler}
            showMessage={showMessage}
            setActiveOpenSection={setActiveOpenSection}
            activeOpenSection={activeOpenSection}
          />
        </Box>
      </Box>

      <Modal
        open={openEditDealRoomPriceModal}
        className="confirm-modal-container"
        style={{ top: "20% !important" }}
        onClose={() => setOpenEditDealRoomPriceModal(false)}
      >
        <DealRoomPrice
          title="Edit deal room price"
          handleCloseModal={() => setOpenEditDealRoomPriceModal(false)}
          showMessage={showMessage}
        />
      </Modal>
    </>
  );
};

export default AdminDealRoom;
