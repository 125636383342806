import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {Alert, Grid, Paper} from "@mui/material";
import strings from "../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import ResetPasswordRequestForm from "../../Components/Forms/Pages/User/ResetPasswordRequestForm";
import {resetPasswordRequest, userExist} from "../../Services/User/UserService";
import {Snackbar} from "@material-ui/core";
import ValidationPatterns from '../../Constants/Base/ValidationPatterns';

const formRules = {
    'email': {
        required: {value: true, message: strings.forms.common.required},
        pattern: {value: ValidationPatterns.EMAIL, message: strings.forms.resetPassword.emailError}
    }
}

const ResetPasswordRequest = ({expired}) => {

    const form = useForm();
    const {data, handleSubmit, getValues, formState: {errors}} = form;
    const [step, setStep] = useState(1);

    const dispatch = useDispatch();
    dispatch(changePageSizeState(PageSizeState.FULL));

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [notRegisteredMsg, setNotRegisteredMsg] = useState(false)

    const showMessage = (message, alertType) => {
        setMessageSnackbar(message);
        setAlertType(alertType)
        setShowSnackbar(true);
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };

    const onSubmit = (data) => {

        setNotRegisteredMsg(false)

        userExist(data.email).then(response => {

            if (!response.data) {
                setNotRegisteredMsg(true)
                return
            }

            resetPasswordRequest(data).then(response => {

                if (!response || !response.ok) {
                    showMessage(strings.pages.resetPasswordRequest.passwordResetAlreadyRequested, 'error')
                    return;
                }

                setStep(2);

                showMessage(strings.pages.resetPasswordRequest.passwordResetRequester, 'success')
            });
        })
    }

    return <div id='reset-password-request' className={'full-form-page reset-password-page'}>
        <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
            <Alert severity={alertType} onClose={handleCloseSnackbar}>
                {messageSnackbar}
            </Alert>
        </Snackbar>
        <Grid item md={3.5} className="reset-password-grid">
            <Paper className='paper'>

                <img className="logo-img" src='/images/logo.png'/>
                
                { step === 1 && 
                <div>
                    <h1>{expired ? strings.pages.resetPasswordRequest.resetPasswordExpired : strings.pages.resetPasswordRequest.resetPassword}</h1>

                    <p>{expired ? strings.pages.resetPasswordRequest.descriptionExpired : strings.pages.resetPasswordRequest.description}</p>
                
                
                    <ResetPasswordRequestForm
                        errors={errors}
                        data={data}
                        form={form}
                        onSubmit={handleSubmit(onSubmit)}
                        notRegisteredMsg={notRegisteredMsg}
                        formRules={formRules}
                    /> 
                </div> }

                {step === 2 && 
                <div>
                    <h1>{strings.pages.resetPasswordRequest.weSentAResetLink}</h1>
                    <p>{strings.pages.resetPasswordRequest.weSentAResetLinkLinkSentTo} 
                        <span style={{ fontWeight: '600'}}>{getValues().email}</span> 
                        {strings.pages.resetPasswordRequest.pleaseBeSureToCheckSpam}
                    </p>
                </div> }

                <div className="text-with-link-container">
                    <p>{strings.pages.resetPasswordRequest.backTo}</p>
                    <Link to={'/login'} className="middle-text-link">
                        {strings.pages.resetPasswordRequest.signIn}
                    </Link>
                </div>

            </Paper>
        </Grid> 
    </div>
};
export default ResetPasswordRequest;
