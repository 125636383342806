import HttpMethod from "../../Constants/Base/HttpMethod";
import { request } from "../../Base/HTTP";

export async function getSectionsAdmin(type) {
  return await request(`api/dueDiligence/sections/type/${type}`, {}, HttpMethod.GET);
}


export async function addNewSectionsAdmin(data) {
  return await request(`api/dueDiligence/section`, data, HttpMethod.POST);
}

export async function editSectionsAdmin(data) {
  return await request(`api/dueDiligence/section`, data, HttpMethod.PUT);
}

export async function deleteSectionsAdmin(sectionId) {
  return await request(`api/dueDiligence/section/${sectionId}`, {}, HttpMethod.DELETE);
}

export async function createQuestionAdmin(sectionId, data) {
  return await request(`api/dueDiligence/section/${sectionId}/question`, data, HttpMethod.POST);
}

export async function editQuestionAdmin(data) {
  return await request(`api/dueDiligence/question`, data, HttpMethod.PUT);
}

export async function deleteQuestionAdmin(questionId) {
  return await request(`api/dueDiligence/question/${questionId}`, {}, HttpMethod.DELETE);
}
export async function publishQuestionairAdmin() {
  return await request(`api/dueDiligence/publish`, {}, HttpMethod.PATCH);
}

export async function getAllPriceAvailableAdmin() {
  return await request(`api/dealRoom/package/prices`, {}, HttpMethod.GET);
}

export async function updatePriceAvailableAdmin(data) {
  return await request(`api/dealRoom/package/price/update`, data, HttpMethod.PATCH);
}



