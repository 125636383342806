import TextField from "@mui/material/TextField";
import {Controller} from "react-hook-form";
import React from "react";

const TextareaControl = (props) => {

    return <Controller
                name={props.name}
                control={props.data}
                defaultValue={props.defaultValue}
                rules={props.rules}
                render={({ field }) =>
                    <TextField {...field}
                        InputLabelProps={{ shrink: Boolean(field.value) }}
                        size={props.size ? props.size : 'small'}
                        fullWidth={props.fullWidth ? props.fullWidth : true}
                        type={props.type}
                        margin={props.margin ? props.margin : 'normal'}
                        error={props.error}
                        helperText={props.helperText}
                        label={props.label}
                        className="textarea-control"
                        placeholder={props.placeholder}
                        multiline
                        rows={props.rows}
                        maxRows={props.maxRows}
                        InputProps={{
                            readOnly: props.readOnly ? props.readOnly : false
                        }}
                    />
                }
            />
}

export default TextareaControl;