import {createSlice} from "@reduxjs/toolkit";
import MenuState from "../Constants/Base/MenuState";

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        menuState: MenuState.SHORT
    },
    reducers: {
        changeMenuState: (state, action) => {
            state.menuState = action.payload
        }
    }
});

export const { changeMenuState } = navigationSlice.actions

export default navigationSlice.reducer
