import React from 'react';
import {useNavigate, Link} from "react-router-dom";
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";

const ResetPasswordRequestForm = ({
                       onSubmit,
                       errors,
                       data,
                       form,
                       notRegisteredMsg,
                       formRules
                   }) => (
    <FormProvider {...form}>
        <form id="reset-password-request-form" onSubmit={ onSubmit } action = "#">

            <TextFieldControl
                name='email'
                control={data}
                defaultValue=''
                rules={formRules['email']}
                fullWidth
                margin="normal"
                error={Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
                placeholder={strings.forms.login.email}
                icon={<img src="/images/icons/email.png" />}
            />

            {notRegisteredMsg &&
                <p>{strings.pages.resetPasswordRequest.youAreNotRegistered}
                    <Link to={'/registration'} className="main-link middle-text-link">
                        {strings.pages.resetPasswordRequest.createAccountNow}
                    </Link>
                </p>
            }

            <div className='submit-container'>
                <Button className="reset-pass-btn btn" variant="contained" color="primary" onClick={ onSubmit }>
                    { strings.forms.resetPasswordRequest.requestResetLink }
                </Button>
            </div>

        </form>
    </FormProvider>
);

export default ResetPasswordRequestForm;
