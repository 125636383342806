import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../../Pages/dealRoom/DealRoom.module.css";
import { Box, Typography, Button } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const TeamMemberForm = ({
  btnText,
  heading,
  selectedUser,
  setIsModalOpen,
  setSelectedUser,
  handleCloseModal,
  addUserInDealRoom,
  updateDealRoomUser,
}) => {
  const inputRef = useRef();

  const [userData, setUserData] = useState(
    selectedUser !== null
      ? {
          name: selectedUser.name,
          email: selectedUser.email,
          party: selectedUser.party,
          userProfileType: selectedUser.type,
        }
      : { name: "", email: "", party: 0, userProfileType: 1 }
  );

  const [isOpenChoiceBox, setisOpenChoiceBox] = useState({});

  useEffect(() => {
    return () => {
      setUserData({
        name: "",
        email: "",
        party: 0,
        userProfileType: 1,
      });
      setSelectedUser(null);
    };
  }, []);

  const onChangeInputHandler = (e) => {
    let { name, value } = e.target;
    if (name === "party") {
      value === "buyer" ? (value = 0) : (value = 1);
    }
    if (name === "userProfileType") {
      value === "adminUser" ? (value = 1) : (value = 3);
    }
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitBtnClick = () => {
    btnText === "Add"
      ? addUserInDealRoom(userData)
      : updateDealRoomUser(userData, selectedUser.id);
  };

  const handleMouseLeave = () => {
    inputRef?.current?.blur();
    setisOpenChoiceBox({ ...isOpenChoiceBox, party: false });
  };

  return (
    <Box
      id="confirm-modal"
      className="confirm-modal"
      sx={{ backgroundColor: "#FFFFFF !important" }}
    >
      <Box
        className={styles.dealRoom}
        sx={{
          color: "#283959",
          width: "calc(100% - 2rem)",
          padding: "0rem 1rem",
        }}
      >
        <Box className={styles.newTeamFormHeading}>
          <Box className={styles.newTeamFormHeadingBox}>
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              {heading}
            </Typography>
          </Box>
        </Box>

        <Box mt={1} mb={1} width={"100%"}>
          <Box
            sx={{
              backgroundColor: "#D9D9D930",
              borderRadius: "1rem",
              padding: "1rem",
              border: "none",
            }}
          >
            <input
              className={styles.teamMemberForminputBox}
              value={userData?.name}
              sty
              name="name"
              onChange={(e) => onChangeInputHandler(e)}
              type="text"
              placeholder="Full name"
            />
          </Box>
        </Box>

        <Box mt={1} mb={1} width={"100%"}>
          <Box
            sx={{
              backgroundColor: "#D9D9D930",
              borderRadius: "1rem",
              padding: "1rem",
              border: "none",
            }}
          >
            <input
              className={styles.teamMemberForminputBox}
              value={userData?.email}
              name="email"
              onChange={(e) => onChangeInputHandler(e)}
              type="email"
              placeholder="Email"
            />
          </Box>
        </Box>

        <Box mt={1} mb={1} width={"100%"} onMouseLeave={handleMouseLeave}>
          <Box
            sx={{
              backgroundColor: "#D9D9D930",
              borderRadius: "1rem",
              padding: "1rem",
              border: "none",
              position: "relative",
            }}
          >
            <input
              readOnly
              ref={inputRef}
              name="party"
              value={userData?.party === 0 ? "Buyer" : "Seller"}
              className={styles.teamMemberForminputBox}
              // onChange={onChangeInputHandler}
              onFocus={() =>
                setisOpenChoiceBox({ ...isOpenChoiceBox, party: true })
              }
              placeholder="Please Select Party"
              style={{
                WebkitAppearance: "none",
                background: `url(/images/icons/downArrow.png)`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right center",
              }}
            />

            {isOpenChoiceBox?.party && (
              <div className={styles.options} style={{ top: "60px" }}>
                <ul onMouseLeave={handleMouseLeave}>
                  <li
                    onClick={() =>
                      onChangeInputHandler({
                        target: { name: "party", value: "buyer" },
                      })
                    }
                  >
                    Buyer
                  </li>
                  <li
                    onClick={() =>
                      onChangeInputHandler({
                        target: { name: "party", value: "seller" },
                      })
                    }
                  >
                    Seller
                  </li>
                </ul>
              </div>
            )}
          </Box>
        </Box>

        <Box mt={1} mb={1} width={"100%"}>
          <Box
            className={styles.labelText}
            sx={{ justifyContent: "space-between" }}
          >
            <Typography
              variant="body2"
              display={"flex"}
              alignItems={"center"}
              width={"20%"}
            >
              User Type :
            </Typography>
            <RadioGroup
              aria-labelledby="userProfileType-buttons-group-label"
              defaultValue={
                userData?.userProfileType === 1 ? "adminUser" : "user"
              }
              name="userProfileType"
              onChange={onChangeInputHandler}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                width: "70%",
              }}
            >
              <FormControlLabel
                value="adminUser"
                control={<Radio />}
                label="Admin User"
              />
              <FormControlLabel value="user" control={<Radio />} label="User" />
            </RadioGroup>
          </Box>
        </Box>

        <Box className={styles.payButtonRow}>
          <Button
            className="neutral-btn"
            sx={{ marginRight: "1rem" }}
            onClick={() => {
              handleCloseModal();
            }}
          >
            Cancel
          </Button>
          <Button
            className="default-btn"
            onClick={() => handleSubmitBtnClick()}
          >
            {btnText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TeamMemberForm;
