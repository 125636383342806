import React, {useState, useEffect, useContext} from "react";
import strings from "../../../localization";
import CloseIcon from '@mui/icons-material/Close';
import  AutocompleteSelectControl from '../../../Components/Controls/AutocompleteSelectControl';
import {getContractCategoryByPackage} from "../../../Services/ContractCategory/PackageService";
import {getAllContractCategories} from "../../../Services/ContractCategory/ContractCategoryService";

const Category = ({ 
                    name, 
                    pack, 
                    setCategoriesIds,
                    categoriesIds,
                    editIcon
                }) => {

    const [otherCategories, setOtherCategories] = useState([])
    const [categoriesFullObjList, setCategoriesFullObjList] = useState([])

    useEffect(() => {
        fetchCategories();
    }, [])

    const fetchCategories = () => {
        
        let all = [];
        let forPack = [];

        getAllContractCategories({}).then(response => {

            if(!response || !response.ok) {
                return;
            }

            for(let category of response.data) {
                if(!category.parent) {
                    all.push(category);
                }
            }
            
            getContractCategoryByPackage(pack.id).then(response => {
                
                for(let item of response.data) {
                    if(!item.contractCategory.parent) {
                        forPack.push(item.contractCategory);
                    }
                }

                let other = all.filter(n => {
                    if (forPack.find(e => e.id == n.id)) {
                        return
                    } else {
                        return n
                    }
                })

                let ids = []

                for (let i of forPack) {
                    ids.push(i.id)
                }

                setOtherCategories(other)
                setCategoriesFullObjList(forPack)  
                setCategoriesIds(ids)
            })
        });
    }

    const handleCloseTag = (category) => {

        setCategoriesFullObjList(categoriesFullObjList.filter(e => e.id !== category.id))

        setOtherCategories([
            ...otherCategories,
            category
        ])

        let idListUpdate = categoriesIds.filter(e => e !== category.id)
        setCategoriesIds(idListUpdate)
    }

    const getDataItem = () => {
        let result = [];

        if (categoriesFullObjList.length > 0) {
            for (let item of categoriesFullObjList) {

                result.push(
                    <div className={editIcon ? "tag" : "tag disabled"} key={item.id}>
                        <span className="tag-text">{item.name}</span>

                        {editIcon &&
                            <CloseIcon className="close-icon" onClick={() => handleCloseTag(item)} />
                        }
                    </div>
                )
            }
        } else {
            return []
        }
        return result
    }

    const handleInputChange = (category) => {
        
        setCategoriesFullObjList([
            ...categoriesFullObjList,
            category
        ])
        
        setOtherCategories(otherCategories.filter(e => e.id !== category.id))
        
        setCategoriesIds([
            ...categoriesIds,
            category.id
        ])
    }

    return (
        <div className="item second drop">
            <div className="item-title">
                <span>{name}</span>      
            </div>

            {
                editIcon &&            
                <AutocompleteSelectControl
                    options={otherCategories}
                    handleInputChange={handleInputChange}
                    placeholder={strings.pages.adminPackages.searchCategory}
                />
            }

            <div className="tag-list">
                {categoriesFullObjList.length > 0 && getDataItem()}
            </div>
        </div>
    )
}

export default Category;