import React from 'react'
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";


const FileRenderComponent = ({ files, setFiles }) => {
    const removeFile = (file) => {
        const updatedFiles = files.filter((x) => x.name !== file.name);
        setFiles(updatedFiles);
        // setDisabledSubmit(updatedFiles.length === 0);
    };
    return files.map((file) => (
        <div className="file-name" key={file.name}>
            <TextField
                name="name"
                // control={data}
                defaultValue={file.newName ? file.newName : file.name}
                rules={{ required: true }}
                margin="normal"
                className={"textfield-control"}
                onChange={(e) => {
                    const index = files.indexOf(file);
                    if (index < 0) {
                        return;
                    }

                    let changedFiles = files.slice();
                    changedFiles[index].newName = e.target.value;

                    setFiles(changedFiles);
                }}
                fullWidth
                placeholder={"Name"}
            />
            <CloseIcon className="close-icon" onClick={() => removeFile(file)} />
        </div>
    ));
}

export default FileRenderComponent