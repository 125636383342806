import { request } from "../../Base/HTTP";
import { request as requestAI } from "../../Base/HTTPAI";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllConversationsForQuestion(id) {
  return await request("/api/aiconversation/all/" + id);
}

export async function getAllChatConversationsForQuestion(id) {
  return await requestAI("/chat/history/" + id);
}

export async function addConversation(data) {
  return await request("/api/aiconversation/add", data, HttpMethod.POST);
}

export async function addChatWithClauseConversation(data) {
  return await requestAI("/chat/",
    data,
    HttpMethod.POST
  );
}
export async function addChatConversation(data) {
  return await requestAI("/chat/", data, HttpMethod.POST);
}

export async function getAllAIData() {
  return await requestAI("/clause/all", HttpMethod.GET);
}

export async function getAllAIRequestData() {
  return await requestAI("/chat-message/history/all", HttpMethod.GET);
}
export async function getClauseOptions() {
  return await requestAI("/clause/options", HttpMethod.GET);
}
export async function clauseUpdateById(data) {
  return await requestAI("/clause/update/" + data.id, data, HttpMethod.PUT);
}
export async function clauseDeleteById(id) {
  return await requestAI("/clause/delete/" + id, {}, HttpMethod.DELETE);
}
export async function createClause(data) {
    return await requestAI('/clause/', data, HttpMethod.POST);
}
export async function aiRequestDeleteById(id) {
    return await requestAI('/chat-message/delete/' + id, {}, HttpMethod.DELETE);
}

export async function getClauseFromChoice(data) {
  return await requestAI("/clause/", data, HttpMethod.GET);
}
export async function aiRequestUpdateById(data){
  return await requestAI("/chat-message/update/"+ data.id, data, HttpMethod.PUT);
}
export async function getClauseById(id) {
  return await requestAI("/clause/" + id, {}, HttpMethod.GET);
}
export async function getAiRequestById(id){
  return await requestAI("/chat-message/"+id,{},HttpMethod.GET);
}
export async function aiClauseUpdateMany(data){
  return await requestAI("/clause/update/many/", data, HttpMethod.PUT);
}
export async function aiFineTuneClause(data){
  return await requestAI("/admin/train-clause-generation-ai?model_name=clause_fine_tune_model", data, HttpMethod.POST);
}