import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Menu, MenuItem, Tooltip} from "@mui/material";
import strings from "../../localization";
import {getNotifications} from '../../Services/NotificationsFakeApi';
import {formatDateWithTime} from '../../Util/DateUtil';
import {useSelector} from "react-redux";
import {readAllNotifications, setNotificationRead} from "../../Services/Notification/NotificationService";
import { datediff } from "../../Util/DateUtil";
import { convertUTCToLocalTime } from "../../Util/DateUtil";

const NotificationMenu = ({
                              notificationMenuAnchorEl,
                              closeNotificationMenu
                          }) => {

    const notifications = useSelector((state) => state.notifications.notifications)
    const navigate = useNavigate();

    const handleMarkAllAsRead = () => {
        readAllNotifications();
    }

    const onClose = () => {
        closeNotificationMenu()
    }

    const getNotReadNotifications = (notifications) => {

        if(!notifications) {
            return [];
        }

        return notifications.filter(x => !x.isRead);
    }

    const handleClickOnNotification = (notification) => {
        setNotificationRead(notification.id);
    }

    const renderNotifications = () => {
        let result = []

        for (let notification of notifications) {

            result.push(
                <div onClick={() => handleClickOnNotification(notification)} className="notification">
                    <div className="content-wrap">
                        <div className="content">
                            <div className="profile">
                                { 
                                    notification.fromUser &&
                                    <React.Fragment>
                                        { notification.fromUser.profileImage ?
                                            <img className="image" src={notification.fromUser.profileImage} /> :
                                            <img className="image" src={'images/icons/profile-default-small.png'} />
                                        }
                                        <Tooltip title={notification.fromUser.fullName}>
                                            <span className="name">{notification.fromUser.fullName}</span>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                                {
                                    !notification.fromUser &&
                                    <React.Fragment>
                                        <img className="image" src={'images/icons/profile-default-small.png'} />
                                        <Tooltip title='Logical Contracts'>
                                            <span className="name">Logical Contracts</span>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="text">
                                <p className="notification-text" dangerouslySetInnerHTML={{ __html: notification.title }} />
                                <span className={!notification.isRead ? "created active" : "created"}>
                                    {formatDateWithTime(convertUTCToLocalTime(new Date(notification.dateCreated)))}
                                </span>
                            </div>
                        </div>
                        <div className="notification-border"/>
                    </div>

                    <div className={!notification.isRead ? "notification-dot active" : "notification-dot"}/>
                </div>
            )
        }
        return result
    }

    return (
        <Menu
            id='notification-menu'
            anchorEl={notificationMenuAnchorEl}
            open={Boolean(notificationMenuAnchorEl)}
            onClose={() => onClose()}
            className="header-popup-menu notification-menu"
            PaperProps={{
                elevation: 0,
                sx: {
                    width: 520,
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 19,
                        width: 10,
                        height: 10,
                        bgcolor: '#6193D7',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                    },
                },
            }}
            MenuListProps={{
                overflowY: 'scroll',
                sx: {
                    '& .MuiMenuItem-root': {
                        whiteSpace: 'normal',
                    },
                },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
            <div className="notifications-header">
                <span>{strings.header.recent}({getNotReadNotifications(notifications).length})</span>
                <span className="all-read-link" onClick={() => handleMarkAllAsRead()}>{strings.header.markAllAsRead}</span>
            </div>
            <div className="list">
                {renderNotifications()}
            </div>
            <div className="all-activity" onClick={() => { navigate('/dashboard'); onClose(); }}>
                See all activity
            </div>
        </Menu>
    )
}

export default NotificationMenu;