import React, { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";
import strings from "../../../../localization";
import { Add } from "@mui/icons-material";
import { deligenceQuestionObj } from "../../../../Constants/Admin/DealRoomModel";
import QuestionRow from "./QuestionRow";

const QuestionsList = ({
  questions,
  sectionIndex,
  sectionRow,
  getSectionListHandler,
  showMessage,
}) => {
  const [questionsList, setQuestionsList] = useState([]);

  const addNewQuestionHandler = () => {
    let tempQuestionsList = structuredClone(questionsList);
    tempQuestionsList = [...questionsList, deligenceQuestionObj];
    setQuestionsList(tempQuestionsList);
  };

  useEffect(() => {
    questions.sort((a, b) => a.orderNumber - b.orderNumber);
    setQuestionsList(questions);
  }, [questions]);

  return (
    <>
      {questionsList?.map((questionObj, index) => {
        return (
          <QuestionRow
            questionObj={questionObj}
            sectionIndex={sectionIndex}
            questionIndex={index}
            showMessage={showMessage}
            sectionRow={sectionRow}
            getSectionListHandler={getSectionListHandler}
          />
        );
      })}

      <Box display={"flex"} justifyContent="center" alignItems="center" mb={2}>
        <Button
          onClick={() => addNewQuestionHandler()}
          className="new-section-button"
        >
          <Add />
          {strings.pages.adminDealRoom.newQuestion}
        </Button>
      </Box>
    </>
  );
};

export default QuestionsList;
