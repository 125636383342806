import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allConversations: [],
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setAllConversations: (state, action) => {
      state.allConversations.push(action.payload);
    },
    setValueForAllConversations:(state, action) =>{
      state.allConversations = action.payload;
    },
    updateConversation: (state, action) => {
      state.allConversations.map((conversation) => {
        if (conversation.response === "Loading...") {
          conversation.response = action.payload.response;
          conversation.status = action.payload.status;
          conversation.question = action.payload.question;

        }
      }); 
    },
  },
});

export const { setAllConversations, updateConversation, setValueForAllConversations } =
  conversationSlice.actions;

export default conversationSlice.reducer;
