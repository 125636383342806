import React, { useState } from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import ConfirmModal from "../../Components/Modals/ConfirmModal";
import UserType from "../../Constants/User/UserType";
import strings from "../../localization";

const VirtualRoomTable = ({
  rows,
  column,
  closeDealroom,
  downloadAllDealroomData,
  downloadAllQuestionnaireHandler,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isSubscribeForDealroom, setIsSubscribeForDealroom] = useState(false);
  const [isDealRoomSubscriptionExpired, setIsDealRoomSubscriptionExpired] =
    useState(false);
  const [openDownloadDealroomDataModal, setOpenDownloadDealroomDataModal] =
    useState(false);

  const closeDealRoomHandler = () => {
    setIsDealRoomSubscriptionExpired(false);
    closeDealroom(selectedRow?.id);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {column.map((col) => {
                return (
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      fontFamily: "nobile",
                      borderColor: "#9DAEC6",
                      color: "#2b3d55",
                    }}
                  >
                    {col.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                onClick={() => {
                  setSelectedRow(row);
                  if (row?.isClosed) {
                    setOpenDownloadDealroomDataModal(true);
                  } else if (row?.subscription === null) {
                    setIsSubscribeForDealroom(true);
                  } else {
                    if (row?.isSubscriptionRequired) {
                      setIsDealRoomSubscriptionExpired(true);
                    } else {
                      navigate(
                        `/virtualDealRoom/dashboard/overview/${row?.id}`,
                        {
                          state: {
                            projectName: row.name,
                            companyName: row.targetCompanyName,
                          },
                        }
                      );
                    }
                  }
                }}
                key={row.name}
                sx={
                  row?.isSubscriptionRequired || row?.isClosed
                    ? {
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                        backgroundColor: "#f0f0f0",
                      }
                    : {
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }
                }
              >
                {column.map((col) => {
                  return (
                    <TableCell
                      sx={
                        row?.isSubscriptionRequired ||  row?.isClosed
                          ? {
                              fontWeight: "700",
                              font: "nobile",
                              color: "#7F868F",
                              borderColor: "#9DAEC6",
                            }
                          : {
                              fontWeight: "700",
                              font: "nobile",
                              borderColor: "#9DAEC6",
                            }
                      }
                    >
                      {row[col.name]}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {user?.userProfileType === UserType.COMPANY_ADMIN &&
      selectedRow?.ownerUserId === user.id ? (
        <Modal
          open={isDealRoomSubscriptionExpired}
          onClose={() => closeDealRoomHandler()}
          className="confirm-modal-container"
        >
          <ConfirmModal
            contentText={`Your Subscription for ${selectedRow?.name} has expired`}
            cancelBtn={"Close DealRoom"}
            confirmBtn={"Extend Subscription"}
            handleCloseModal={() => {
              closeDealRoomHandler();
              setOpenDownloadDealroomDataModal(true);
            }}
            handleConfirm={() =>
              navigate(`/virtualDealRoom/payment/${selectedRow?.id}`, {
                state: {
                  extendSubscription: true,
                },
              })
            }
            handleCloseModalUsingCloseIcon={() =>
              setIsDealRoomSubscriptionExpired(false)
            }
          />
        </Modal>
      ) : (
        <Modal
          open={isDealRoomSubscriptionExpired}
          onClose={() => closeDealRoomHandler()}
          className="confirm-modal-container"
        >
          <ConfirmModal
            contentText={`Your Subscription for ${
              selectedRow?.name
            } has expired. Please contact ${
              selectedRow?.owneFullName ? selectedRow?.owneFullName : `admin`
            } to extend the deal room.`}
            confirmBtn={strings.modals.contractList.nonValidSubscription.ok}
            handleCloseModal={() => closeDealRoomHandler()}
            handleConfirm={() => {
              closeDealRoomHandler();
              navigate(`/virtualDealRoom`);
            }}
            handleCloseModalUsingCloseIcon={() =>
              setIsDealRoomSubscriptionExpired(false)
            }
          />
        </Modal>
      )}
      {selectedRow?.isAdmin ? (
        <>
          <Modal
            open={isSubscribeForDealroom}
            onClose={() => setIsSubscribeForDealroom(false)}
            className="confirm-modal-container"
          >
            <ConfirmModal
              contentText={"Please Subscribe for the Deal room"}
              cancelBtn={"Cancel"}
              confirmBtn={"Subscribe"}
              handleCloseModal={() => {
                setIsSubscribeForDealroom(false);
              }}
              handleConfirm={() =>
                navigate(`/virtualDealRoom/payment/${selectedRow?.id}`)
              }
              handleCloseModalUsingCloseIcon={() =>
                setIsSubscribeForDealroom(false)
              }
            />
          </Modal>
        </>
      ) : (
        <Modal
          open={isSubscribeForDealroom}
          onClose={() => setIsSubscribeForDealroom(false)}
          className="confirm-modal-container"
        >
          <ConfirmModal
            contentText={`Please contact ${
              selectedRow?.owneFullName ? selectedRow?.owneFullName : `admin`
            } to subscribe for the dealroom.`}
            confirmBtn={"Ok"}
            handleConfirm={() => setIsSubscribeForDealroom(false)}
            handleCloseModalUsingCloseIcon={() =>
              setIsSubscribeForDealroom(false)
            }
          />
        </Modal>
      )}
      <Modal
        open={openDownloadDealroomDataModal}
        onClose={() => setOpenDownloadDealroomDataModal(false)}
        className="confirm-modal-container"
      >
        <ConfirmModal
          contentText={`You can download all the documents in this deal room up until ${selectedRow?.validUntilDate}.`}
          confirmBtn={
            strings.pages.dealRoom.downloadDealRoomData.downloadButtonText
          }
          cancelBtn={"Close"}
          handleCloseModal={() => setOpenDownloadDealroomDataModal(false)}
          handleConfirm={() => {
            downloadAllDealroomData(selectedRow?.id, selectedRow?.name);
            downloadAllQuestionnaireHandler(selectedRow?.id, selectedRow?.name);
            setOpenDownloadDealroomDataModal(false);
          }}
          handleCloseModalUsingCloseIcon={() =>
            setOpenDownloadDealroomDataModal(false)
          }
        />
      </Modal>
    </>
  );
};

export default VirtualRoomTable;
