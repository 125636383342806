import strings from "../../localization";

const ContractStatus = {
    ALL: -1,
    CREATED: 1,
    PENDING_REVIEW: 2,
    REVIEWED: 3,
    PENDING_E_SIGN: 4,
    E_SIGNED: 5,
    APPROVED: 6,
    REJECTED: 7
};

export function getContractStatusFilter() {
    return [
        {
            id: ContractStatus.ALL,
            name: getContractStatusString(ContractStatus.ALL)
        },
        {
            id: ContractStatus.CREATED,
            name: getContractStatusString(ContractStatus.CREATED)
        },
        {
            id: ContractStatus.PENDING_REVIEW,
            name: getContractStatusString(ContractStatus.PENDING_REVIEW)
        },
        {
            id: ContractStatus.REVIEWED,
            name: getContractStatusString(ContractStatus.REVIEWED)
        },
        {
            id: ContractStatus.PENDING_E_SIGN,
            name: getContractStatusString(ContractStatus.PENDING_E_SIGN)
        },
        {
            id: ContractStatus.E_SIGNED,
            name: getContractStatusString(ContractStatus.E_SIGNED)
        }
    ]
}

export function getContractStatusString(contractStatus) {

    switch(contractStatus) {
        case ContractStatus.ALL: return strings.constants.contract.contractStatus.all;
        case ContractStatus.CREATED: return strings.constants.contract.contractStatus.created;
        case ContractStatus.PENDING_REVIEW: return strings.constants.contract.contractStatus.pendingReview;
        case ContractStatus.REVIEWED: return strings.constants.contract.contractStatus.reviewed;
        case ContractStatus.PENDING_E_SIGN: return strings.constants.contract.contractStatus.pendingSign;
        case ContractStatus.E_SIGNED: return strings.constants.contract.contractStatus.signed;
        case ContractStatus.APPROVED: return strings.constants.contract.contractStatus.approved;
        case ContractStatus.REJECTED: return strings.constants.contract.contractStatus.rejected;
    }
}

export function getContractStatusClass(contractStatus) {

    switch(contractStatus) {
        case ContractStatus.CREATED: return 'created';
        case ContractStatus.PENDING_REVIEW: return 'pending-review';
        case ContractStatus.REVIEWED: return 'reviewed';
        case ContractStatus.PENDING_E_SIGN: return 'pending-e-sign';
        case ContractStatus.E_SIGNED: return 'e-signed';
        case ContractStatus.APPROVED: return 'approved';
        case ContractStatus.REJECTED: return 'rejected';

    }
}

export default ContractStatus;