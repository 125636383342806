import React, {useEffect, useState, useContext, useRef} from "react";
import {FormProvider, useForm} from "react-hook-form";
import strings from "../../../../localization";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import Button from '@mui/material/Button';
import FileUploadComponent from './../../../Controls/FileUploadComponent';
import CloseIcon from "@mui/icons-material/Close";
import {toBase64} from "../../../../Util/ImageUtil";
import {updateUserProfile, updateUserSignature} from "../../../../Services/User/UserService";
import SnackbarContext from "../../../../Context/SnackbarContext";
import {useDispatch, useSelector} from "react-redux";
import {setAuth, setUser} from "../../../../Slices/AuthSlice";

const AdoptSignatureForm = ({
                                setOpenAdoptSignModal,
                                adoptSignature,
                                externalUser,
                            }) => {

    const form = useForm();
    const dispatch = useDispatch();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const {showMessage} = useContext(SnackbarContext);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [file, setFile] = useState(null)
    const [fileTypeErr, setFileTypeErr] = useState(false)
    const [fileRequiredErr, setFileRequiredErr] = useState(false)
    const [signatureType, setSignatureType] = useState(1);

    const user = useSelector((state) => state.auth.user)

    const signatureCanvas = useRef(null);

    useEffect(() => {
        if(signatureType === 1) {
            const signatureFullName = externalUser ? externalUser.fullName : user.fullName;
            const canvas = signatureCanvas.current;
            const ctx = canvas.getContext("2d");
            ctx.font = "50px Brush Script MT";
            canvas.width = ctx.measureText(signatureFullName).width + 8;
            ctx.clearRect(0, 0, canvas.width, canvas.height); 
            ctx.font = "50px Brush Script MT";
            ctx.fillText(signatureFullName, 0, 45);
        }
    }, [signatureType])

    const handleCancel = () => {
        setOpenAdoptSignModal(false)
    }

    const uploadFile = async (files) => {
        setFileTypeErr(false)
        setFileRequiredErr(false)
        setDisabledSubmit(true)
        const file = files[0];

        if (file === undefined) {
            setFileTypeErr(true)
        } else {
            setDisabledSubmit(false);
            setFile(file)
        }
    }

    const handleRemoveFile = () => {
        setFile(null)
    }

    const onSubmit = async (data) => {

        setOpenAdoptSignModal(false)

        if (signatureType !== 1 && !(signatureType === 2 && file)) {
            return;
        }

        const signature = signatureType === 1 ? signatureCanvas.current.toDataURL() : await toBase64(file);

        if (externalUser) {
            adoptSignature(signature);
            return;
        }

        updateUserSignature({signature}).then(response => {
            dispatch(setUser(response.data));
        });
    }

    return (<FormProvider {...form}>
        <div id="user-form-container" className="confirm-modal user-form-container adopt-sign-modal">
            <h1 className="header">{strings.forms.contractList.signForm.title}</h1>
            <p>{strings.forms.contractList.signForm.confirmYourNameAndSignature}</p>
            <div className="form-content">
                <div className="item">

                    <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.contractList.signForm.fullName}
                        </span>
                        <TextFieldControl
                            name='fullName'
                            control={data}
                            defaultValue={externalUser ? externalUser.fullName : user.fullName}
                            fullWidth
                            disabled={true}
                            margin="normal"
                            helperText={errors.fullName && strings.forms.common.required}
                            placeholder={strings.forms.contractList.signForm.enterFullName}
                        />
                    </div>

                    <div className='buttons-container'>
                        <Button variant="contained" className={signatureType === 1 ? 'active' : ''} onClick={() => setSignatureType(1)}> 
                            Select a signature
                        </Button>
                        <Button variant="contained" className={signatureType === 2 ? 'active' : ''} onClick={() => setSignatureType(2)}>
                            Upload a signature
                        </Button>
                    </div>

                    { signatureType === 2 && 
                        <div className="item-field-container">

                            {
                                <React.Fragment>
                                    <div className="file-upload-container">
                                        {file
                                            ? <div className="file-name">
                                                <span>{file.name}</span>
                                                <CloseIcon className="close-icon" onClick={handleRemoveFile}/>
                                            </div>
                                            :
                                            <FileUploadComponent accept='.png' hideUploadButton={true} onDrop={uploadFile}/>
                                        }
                                    </div>

                                    {fileTypeErr &&
                                        <span className="error-msg">
                                    {strings.forms.contractList.signForm.signatureFileErr}</span>
                                    }

                                    {fileRequiredErr &&
                                        <span className="error-msg">
                                    {strings.forms.common.required}</span>
                                    }

                                </React.Fragment>
                            }

                            {
                                user?.signature && !!externalUser?.user?.signForm &&
                                <img src={user.signature} className={'signature-image'}/>
                            }
                        </div> 
                    }

                    { signatureType === 1 && 
                        <div className="item-field-container preview-sign">
                            <span className="info-icon-text">
                                {strings.forms.contractList.signForm.preview}
                            </span>
                            <div className="preview-sign-canvas">
                                <canvas ref={signatureCanvas} width="500px" height="60px" />
                            </div>
                    </div>
                    }
                </div>
            </div>

            <div className="submit-container">
                <Button className="secondary-neutral-btn btn"
                        onClick={handleCancel}>{strings.forms.contractList.signForm.cancel}</Button>

                <Button className="secondary-btn btn submit-btn" onClick={onSubmit}>
                    {strings.forms.contractList.signForm.adoptSignature}
                </Button>

            </div>
        </div>
    </FormProvider>)
}

export default AdoptSignatureForm;