import React, {useEffect, useState} from "react";
import strings from "../../../../../localization";
import CheckBoxControl2 from '../../../../Controls/Inputs/CheckBoxControl2';
import TextFieldControl from '../../../../Controls/Inputs/TextFieldControl';
import ValidationPatterns from '../../../../../Constants/Base/ValidationPatterns';

const formRules = {
    'price': {
        required: {value: true, message: strings.forms.common.required},
        pattern: {
            value: ValidationPatterns.NUMERIC,
            message: strings.forms.common.onlyNumeric
        }
    },
    'annualPrice': {
        required: {value: true, message: strings.forms.common.required},
        pattern: {
            value: ValidationPatterns.NUMERIC,
            message: strings.forms.common.onlyNumeric
        }
    }
}

const PriceForm = ({
                    data,
                    setValue,
                    errors
                }) => {

    return (
        <div className="item">
            <div className="item-title">
                <span>{strings.forms.admin.adminPackageForm.price.price}</span>
            </div>

            <div className="short-input-field">
                <span className="label">{strings.forms.admin.adminPackageForm.price.monthlyPrice}</span>
                <TextFieldControl
                    name='price'
                    control={data}
                    defaultValue=''
                    rules={formRules['price']}
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.price)}
                    helperText={errors.price && errors.price.message}
                    placeholder={'0.00 £'}
                />
            </div>

            <div className="short-input-field">
                <span className="label">{strings.forms.admin.adminPackageForm.price.annualPrice}</span>
                <TextFieldControl
                    name='annualPrice'
                    control={data}
                    defaultValue=''
                    rules={formRules['annualPrice']}
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.annualPrice)}
                    helperText={errors.annualPrice && errors.annualPrice.message}
                    placeholder={'0.00 £'}
                />
            </div>

            <CheckBoxControl2
                name={'onceOf'}
                control={data}
                label={strings.forms.admin.adminPackageForm.price.onceOffFee}
                setValue={setValue}
            />

            <CheckBoxControl2
                name={'recommended'}
                control={data}
                label={strings.forms.admin.adminPackageForm.price.setAsRecommendedPackage}
                setValue={setValue}
            />
        </div>
    )
}

export default PriceForm;