import strings from "../../localization";

const Payment = {
    ALL: 1,
    PAID: 2,
    UNPAID: 3,
    TRANSACTION_DATE: 4
};

export function getPaymentFilter() {
    return [
        {
            id: Payment.ALL,
            name: getPaymentString(Payment.ALL)
        },
        {
            id: Payment.PAID,
            name: getPaymentString(Payment.PAID)
        },
        {
            id: Payment.UNPAID,
            name: getPaymentString(Payment.UNPAID)
        },
        {
            id: Payment.TRANSACTION_DATE,
            name: getPaymentString(Payment.TRANSACTION_DATE)
        }
    ]
}

export function getPaymentFilterDefault() {
    return {
            id: Payment.ALL,
            name: getPaymentString(Payment.ALL)
        }
}

export function getPaymentString(payment) {
    switch(payment) {
        case Payment.ALL: return strings.constants.dashboard.payment.all;
        case Payment.PAID: return strings.constants.dashboard.payment.paid;
        case Payment.UNPAID: return strings.constants.dashboard.payment.unpaid;
        case Payment.TRANSACTION_DATE: return strings.constants.dashboard.payment.transactionDate;
    }
}

export default Payment;