import React, { useEffect } from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Button} from "@mui/material";
import strings from "../../../../localization";
import TextareaControl from '../../../Controls/Inputs/TextareaControl';
import {addComment, editComment} from "../../../../Services/Contract/CommentService";
import {getSelectedText} from "../../../../Util/CKEditorUtil";

const formRules = {
    'comment': {required: true}
}

const CommentsForm = ({
                          contract,
                          fetch,
                          editor,
                          user,
                          comment,
                          onCancel
                      }) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;

    const onSubmit = (data) => {

        let saveData = {
            id: comment?.id && !comment?.parent ? comment.id : null,
            contractId: contract.id,
            content: data.comment,
            contractContent: getSelectedText(editor),
            userId: user?.externalUserInviteID ? -1 : user.id,
            externalUserFullName: user?.externalUserInviteID ? user.fullName : null,
            externalUserEmail: user?.externalUserInviteID ? user.email : null,
        };

        setValue('comment', '')

        if(comment?.id && !comment?.parent) {
            editComment(saveData).then(response => {
                fetch();
            });
        } else {
            addComment(saveData).then(response => {
                fetch();
            });
        }
    }

    useEffect(() => {
        if(comment) {
            setValue('comment', comment?.parent ? '' : comment.content);
            return;
        }

        setValue('comment', '')

    }, [comment])

    return (<FormProvider {...form}>
            <TextareaControl
                name='comment'
                control={data}
                defaultValue=''
                fullWidth
                margin="normal"
                rules={formRules['comment']}
                error={Boolean(errors.comment)}
                helperText={errors.comment && strings.forms.common.required}
                placeholder={strings.forms.contractList.commentsForm.commentText}
                rows={3}
                maxRows={10}
            />

            <div className="submit-container">
                { comment && !comment.parent &&
                    <Button className="default-btn white" style={{ marginRight: '10px' }} variant="contained" onClick={onCancel}>
                    {strings.forms.contractList.commentsForm.cancel}
                    </Button>
                }
                <Button className="default-btn" variant="contained" onClick={handleSubmit(onSubmit)}>
                    {comment && !comment.parent ? strings.forms.contractList.commentsForm.save : strings.forms.contractList.commentsForm.submit}
                </Button>
            </div>

        </FormProvider>
    )
}

export default CommentsForm;