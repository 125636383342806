import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {Alert, Drawer, Button, Paper, Snackbar} from "@mui/material";
import TextFieldControl from "../Controls/Inputs/TextFieldControl";
import {useForm} from "react-hook-form";
import {FormProvider} from "react-hook-form";
import PageState from "../../Constants/Base/PageState";
import strings from "../../localization";
import TabPageContext from "../../Context/TabPageContext";
import YesNoDialog, {YesNoDialogResult} from "../Dialogs/YesNoDialog";
import SelectControl from "../Controls/Inputs/SelectControl";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

const TabPage = (props) => {

    const {
        tabPageOptions,
        setSelectedItems,
        selectionModel, setSelectionModel,
        pageState, setPageState, filter, setFilter, showDeleteDialog, setShowDeleteDialog,
        showSnackbar, setShowSnackbar, messageSnackbar, setMessageSnackbar, alertType, setAlertType
    } = useContext(TabPageContext);
    const [drawerTitle, setDrawerTitle] = useState('');
    const form = useForm();
    const {data, watch, setValue} = form;

    let watchValues = ['search', 'year'];

    if (props.filters) {
        for (let filter of props.filters) {
            watchValues.push(filter.name)
        }
    }

    watch(watchValues);

    const value = {drawerTitle, setDrawerTitle}

    useEffect(() => {
        const subscription = watch((data) => setFilter({
            ...filter,
            ...getFilterValues(data),
            term: data.search,
            year: data.year ? data.year.id : undefined,
        }));
    }, [watch]);

    const getFilterValues = (data) => {

        let result = {}

        for (let filter of props.filters) {
            result = {
                ...result,
                [filter.name]: data[filter.name] ? data[filter.name][filter.valueKey] : undefined
            }
        }

        return result
    }

    const onPageSizeChange = (perPage) => {
        setFilter({
            ...filter,
            perPage: perPage
        });
    }

    const onPageChange = (page) => {
        setFilter({
            ...filter,
            page: page
        });
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };

    const isDrawerOpen = () => {
        return pageState !== PageState.View;
    }

    const deleteItem = (id) => {
        setShowDeleteDialog(true)
    }

    const handleOpenModal = () => {
        props.setOpenModal(true)
    }

    const handleCloseModal = () => {
        props.setOpenModal(false)
    }

    const handleDeleteDialogResult = (result, payload) => {

        if (result === YesNoDialogResult.NO || result === YesNoDialogResult.CANCEL) {
            setShowDeleteDialog(false);
            return;
        }

        if (!props.deleteItem || !selectionModel || selectionModel.length === 0) {
            setShowDeleteDialog(false);
            return;
        }

        props.deleteItem(selectionModel[0]).then(response => {
            if (!response || !response.ok) {
                setMessageSnackbar("strings.components.tabPage.errorDeletingItem");
                setAlertType('error')
                setShowSnackbar(true)
                setShowDeleteDialog(false);
                setSelectionModel([]);
                props.onFinish();
                return;
            }

            setMessageSnackbar("strings.components.tabPage.itemDeleted");
            setAlertType('success')
            setShowSnackbar(true)
            setShowDeleteDialog(false);
            setSelectionModel([]);
            props.onFinish();
        })
    }

    const getItemById = (id, data) => {
        if (!id || !data) {
            return undefined;
        }

        return data.find(x => x.id === id)
    }

    const handleSelectionChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);

        if (!newSelectionModel || newSelectionModel.length === 0) {
            setSelectedItems([]);
        }

        let result = [];

        for (let id of newSelectionModel) {
            result.push(getItemById(id, props.tabData.data));
        }

        setSelectedItems(result);
    }

    const renderFilters = () => {
        let result = [];

        for (let filter of props.filters) {
            result.push(
                <div className='filter-item' key={filter.id}>
                    <SelectControl
                        key={'table-filter-' + result.length}
                        setValue={setValue}
                        name={filter.name}
                        defaultValue={filter.defaultValue}
                        options={filter.options}
                        nameKey={filter.nameKey}
                        valueKey={filter.valueKey}
                        className="select-control-container"
                    />
                </div>
            )
        }

        return result;
    }
    return ( <div>

        <div id={'tab-page'}>
            <div className={'filters-container'}>
                <FormProvider {...form}>
                    {
                        props.filters && props.filters.length > 0 &&
                        renderFilters()
                    }
                    <div className='filter-serach-item'>
                        <TextFieldControl
                            name='search'
                            control={data}
                            defaultValue=''
                            margin="normal"
                            placeholder={strings.forms.searchForm.search}
                            icon={<img src="/images/icons/search.png" />}
                        />
                    </div>
                </FormProvider>

                <Button className='default-btn filter-btn btn' variant='contained' onClick={() => handleOpenModal()}>
                    <AddIcon />
                    <span>{props.addBtnText}</span>
                </Button>
                
                <Modal
                    open={props.openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <form className='form wide-form' action="#">
                        <div className="title">
                            <h1>{props.formTitle}</h1>
                            <IconButton className="close-form-icon" onClick={handleCloseModal}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <div className="form-content">
                            {props.formComponent}

                            <div className='submit-container'>
                                <Button className="secondary-neutral-btn btn" variant="contained" onClick={handleCloseModal}> 
                                    {props.cancelBtn}
                                </Button>
                                <Button className="secondary-btn btn" variant="contained" onClick={() =>{
                                    props.onSubmit()
                                }}>
                                    {props.createBtn}
                                </Button>
                            </div>
                        </div>
                    </form>

                </Modal>

            </div>

            {props.tabData}

            <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert severity={alertType} onClose={handleCloseSnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

        </div>
    </div>
    )
}

export default TabPage;