import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import AdminTemplateForm from "../../../Components/Forms/Pages/Admin/AdminTemplateForm/AdminTemplateForm";
import { getAllContractCategories } from '../../../Services/ContractCategory/ContractCategoryService';
import SnackbarContext from "../../../Context/SnackbarContext";
import { changePageSizeState } from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import { changeHeaderTitle } from "../../../Slices/HeaderSlice";
import strings from '../../../localization';
import { addTemplate, editTemplate, getTemplate, getAllTemplates } from "../../../Services/Template/TemplateService";
import { useLocation } from "react-router-dom";
import { Modal } from '@mui/material';
import ConfirmModal from '../../../Components/Modals/SimpleConfirmModal';
import InfoModal from '../../../Components/Modals/InfoModal';

const formRules = {
    'name': { required: true }
}

const AddAdminTemplate = (props) => {

    const dispatch = useDispatch();
    const form = useForm();
    const { data, control, handleSubmit, getValues, setValue, formState: { errors } } = form;
    const [isSubSectionFormHidden, setIsSubSectionFormHidden] = useState(true)
    const [contractCategories, setContractCategories] = useState([]);
    const { showMessage } = useContext(SnackbarContext);
    const [filter, setFilter] = useState({});
    const [template, setTemplate] = useState(null);
    const [templateContent, setTemplateContent] = useState(null);
    const location = useLocation();
    const [templateNextRowOrder, setTemplateNextRowOrder] = useState(null)

    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeHeaderTitle(strings.pages.adminCategories.title));
        fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    const fetch = () => {

        if (location.state && location.state.id) {
            getTemplate(location.state.id).then(response => {

                if (!response && !response.ok) {
                    return;
                }

                setValue('name', response.data.name);
                setValue('description', response.data.description);
                setTemplate(response.data);
            })
        }

        getAllContractCategories(filter).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setContractCategories(response.data);
        });

        getAllTemplates({}).then(response => {

            if (!response || !response.ok) {
                return;
            }

            if (response.data.length === 0) {
                setTemplateNextRowOrder(0)
            } else {
                let lastElement = response.data[response.data.length - 1]
                setTemplateNextRowOrder(lastElement.rowOrder + 1)
            }
        });
    }

    const onFinish = () => {
        fetch();
    }



    const onSubmit = (data) => {

        if (!template) {
            addTemplate({
                ...data,
                active: true,
                force: true,
                rowOrder: templateNextRowOrder
            }).then(response => {

                if (!response && !response.ok) {
                    return;
                }

                showMessage(strings.common.added);
                setTemplate(response.data);
            })
        }
        else {
            if (data.content === "") {
                setOpenModal(!openModal)
            }
            else {
                editTemplate({
                    ...data,
                    id: template.id,
                    active: templateContent ? false : true,
                    force: true,
                    templateContent: templateContent ? templateContent.id : -1,
                }).then(response => {

                    if (!response && !response.ok) {
                        return;
                    }

                    showMessage(strings.common.saved);
                    setTemplate(response.data);
                })
            }
        }
    }

    const onAutoSubmit = (data) => {
        editTemplate({ ...data, id: template.id, templateContent: templateContent.id, active: false, force: false });
    }

    const onPublish = (data) => {

        if (data.content === "") {
            setOpenModal(!openModal)
        }
        else {
            editTemplate({
                ...data,
                id: template.id,
                templateContent: templateContent.id,
                active: true,
                force: false
            }).then((response) => {
                showMessage(strings.common.published);
                setTemplate(response.data);
            });
        }
    }


    return (
        <div id="admin-dashboard" className="admin-dashboard-container add-admin-template">
            <AdminTemplateForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                control={control}
                errors={errors}
                data={data}
                form={form}
                onSubmit={handleSubmit(onSubmit)}
                autoSave={handleSubmit(onAutoSubmit)}
                onPublish={handleSubmit(onPublish)}
                isSubSectionFormHidden={isSubSectionFormHidden}
                setIsSubSectionFormHidden={setIsSubSectionFormHidden}
                categoriesData={contractCategories}
                template={template}
                templateContent={setTemplateContent}
            />
            <Modal
                open={openModal}
                onClose={() => setOpenModal(!openModal)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='confirm-modal-container'
            >
                <ConfirmModal
                    title={strings.modals.addAdmintemplate.title}
                    cancelBtn={strings.modals.adminTemplates.cancelBtn}
                    handleCloseModal={() => setOpenModal(!openModal)}
                    handleConfirm={() => setOpenModal(!openModal)}
                />
            </Modal>
        </div>
    )
};

export default AddAdminTemplate;
