export function getCategories() {
    return [
        {id: 1, name: "Business 1", isActive: true},
        {id: 2, name: "Business 2", isActive: true},
        {id: 3, name: "Business 3", isActive: false},
        {id: 4, name: "Business 4", isActive: true},
        {id: 5, name: "Business 5", isActive: true},
        {id: 6, name: "Business 6", isActive: true},
        {id: 7, name: "Business 7", isActive: false},
        {id: 8, name: "Business 8", isActive: true}
    ]
}

export function getSubCategories() {
    return [
        {id: 1, categoryId: 1, name: "Sub-category 1", isChecked: false},
        {id: 2, categoryId: 1, name: "Sub-category 2", isChecked: true},
        {id: 3, categoryId: 1, name: "Sub-category 3", isChecked: true},
        {id: 4, categoryId: 1, name: "Sub-category 4", isChecked: false},
        {id: 5, categoryId: 2, name: "Sub-category 5", isChecked: true},
        {id: 6, categoryId: 3, name: "Sub-category 6", isChecked: true},
        {id: 7, categoryId: 4, name: "Sub-category 7", isChecked: false},
        {id: 8, categoryId: 2, name: "Sub-category 8", isChecked: false},
        {id: 9, categoryId: 3, name: "Sub-category 9", isChecked: true},
        {id: 10, categoryId: 2, name: "Sub-category 10", isChecked: true},
        {id: 11, categoryId: 3, name: "Sub-category 11", isChecked: false},
        {id: 12, categoryId: 4, name: "Sub-category 12", isChecked: true},
        {id: 13, categoryId: 5, name: "Sub-category 13", isChecked: true}
    ]
}

export function getContracts() {
    return [
        {id: 1, categoryId: 1, name: "Contract Name 1"},
        {id: 2, categoryId: 1, name: "Contract Name 2"},
        {id: 3, categoryId: 1, name: "Contract Name 3"},
        {id: 4, categoryId: 1, name: "Contract Name 4"},
        {id: 5, categoryId: 2, name: "Contract Name 5"},
        {id: 6, categoryId: 3, name: "Contract Name 6"},
        {id: 7, categoryId: 4, name: "Contract Name 7"},
        {id: 8, categoryId: 1, name: "Contract Name 8"},
        {id: 9, categoryId: 2, name: "Contract Name 9"},
        {id: 10, categoryId: 3, name: "Contract Name 10"},
        {id: 11, categoryId: 4, name: "Contract Name 11"}
    ]
}

export function getPackages() {
    return [
        {id: 1, name: "Launch", categoryId: 1, price:'49,99$', isActive: true},
        {id: 2, name: "Grow", categoryId: 1, price:'99,99$', isActive: true},
        {id: 3, name: "ID:102BATH", categoryId: 4, price:'49,99$', isActive: false},
        {id: 4, name: "ID:1024FOOT", categoryId: 2, price:'49,99$', isActive: true}
    ]
}

export function getTemplates() {
    return [
        {id: 1, name: 'Template 1', description: 'Template 1 description'},
        {id: 2, name: 'Template 2', description: 'Template 2 description'},
        {id: 3, name: 'Template 3', description: 'Template 3 description'},
        {id: 4, name: 'Template 4', description: 'Template 4 description'},
        {id: 5, name: 'Template 5', description: 'Template 5 description'},
        {id: 6, name: 'Template 6', description: 'Template 6 description'},
        {id: 7, name: 'Template 7', description: 'Template 7 description'}
    ]
}

export function getCategoriesForPackages() {
    return [
        {id: 1, categoryId: 1, packageId: 1},
        {id: 2, categoryId: 2, packageId: 1},
        {id: 3, categoryId: 3, packageId: 1},
        {id: 4, categoryId: 4, packageId: 1},
        {id: 5, categoryId: 5, packageId: 1},
        {id: 6, categoryId: 6, packageId: 4},
        {id: 7, categoryId: 7, packageId: 2},
        {id: 8, categoryId: 8, packageId: 3},
        {id: 9, categoryId: 8, packageId: 4},
    ]
}

export function getSubCategoriesForPackages() {
    return [
        {id: 1, subCategoryId: 1, packageId: 1},
        {id: 2, subCategoryId: 2, packageId: 1},
        {id: 3, subCategoryId: 3, packageId: 1},
        {id: 4, subCategoryId: 4, packageId: 1},
        {id: 5, subCategoryId: 5, packageId: 1},
        {id: 6, subCategoryId: 6, packageId: 1},
        {id: 7, subCategoryId: 8, packageId: 2},
        {id: 8, subCategoryId: 10, packageId: 3},
        {id: 9, subCategoryId: 9, packageId: 4},
    ]
}

export function getContractsForPackages() {
    return [
        {id: 1, contractId: 1, packageId: 2},
        {id: 2, contractId: 2, packageId: 1},
        {id: 3, contractId: 3, packageId: 2},
        {id: 4, contractId: 4, packageId: 1},
        {id: 5, contractId: 5, packageId: 4},
        {id: 6, contractId: 6, packageId: 3},
        {id: 7, contractId: 7, packageId: 1},
        {id: 8, contractId: 9, packageId: 3},
        {id: 9, contractId: 10, packageId: 1},
    ]
}
  
    

export function getUsers() {
    return [
        {id: 1, name: "User 1"},
        {id: 2, name: "User 2"},
        {id: 3, name: "User 3"},
        {id: 4, name: "User 4"},
        {id: 5, name: "User 5"},
        {id: 6, name: "User 6"},
        {id: 7, name: "User 7"},
        {id: 8, name: "User 8"},
        {id: 9, name: "User 9"},
        {id: 10, name: "User 10"},
        {id: 11, name: "User 11"},
        {id: 12, name: "User 12"},
        {id: 13, name: "User 13"},
        {id: 14, name: "User 14"}
    ]
}

export function getUsersForPackages() {
    return [
        {id: 1, userId: 1, packageId: 2},
        {id: 2, userId: 2, packageId: 1},
        {id: 3, userId: 3, packageId: 2},
        {id: 4, userId: 4, packageId: 1},
        {id: 5, userId: 5, packageId: 4},
        {id: 6, userId: 6, packageId: 3},
        {id: 7, userId: 7, packageId: 1},
        {id: 8, userId: 8, packageId: 3},
        {id: 9, userId: 9, packageId: 1},
        {id: 10, userId: 10, packageId: 3},
        {id: 11, userId: 11, packageId: 1},
        {id: 12, userId: 12, packageId: 3},
        {id: 13, userId: 13, packageId: 1},
        {id: 1, userId: 14, packageId: 1}
    ]
}

export function getSections() {
    return [
        {id: 1, name: "Section name 1", updated: new Date()},
        {id: 2, name: "Section name 2", updated: new Date()},
        {id: 3, name: "Section name 3", updated: new Date()},
        {id: 4, name: "Section name 4", updated: new Date()},
        {id: 5, name: "Section name 5", updated: new Date()}
    ]
}

export function getSubSections() {
    return [
        {id: 1, name: "Sub-section name 1", sectionId: 1, updated: new Date()},
        {id: 2, name: "Sub-section name 2", sectionId: 2, updated: new Date()},
        {id: 3, name: "Sub-section name 3", sectionId: 3, updated: new Date()},
        {id: 4, name: "Sub-section name 4", sectionId: 1, updated: new Date()},
        {id: 5, name: "Sub-section name 5", sectionId: 2, updated: new Date()},
        {id: 6, name: "Sub-section name 6", sectionId: 3, updated: new Date()},
        {id: 7, name: "Sub-section name 7", sectionId: 3, updated: new Date()},
        {id: 8, name: "Sub-section name 8", sectionId: 4, updated: new Date()},
        {id: 9, name: "Sub-section name 9", sectionId: 1, updated: new Date()},
        {id: 10, name: "Sub-section name 10", sectionId: 2, updated: new Date()},
        {id: 11, name: "Sub-section name 11", sectionId: 1, updated: new Date()},
        {id: 12, name: "Sub-section name 12", sectionId: 3, updated: new Date()},
        {id: 13, name: "Sub-section name 13", sectionId: 2, updated: new Date()},
        {id: 14, name: "Sub-section name 14", sectionId: 4, updated: new Date()}
    ]
}

export function getSubSectionQuestions() {
    return [
        {id: 1, name: "Sub-section question 1"},
        {id: 2, name: "Sub-section question 2"},
        {id: 3, name: "Sub-section question 3"},
        {id: 4, name: "Sub-section question 4"},
        {id: 5, name: "Sub-section question 5"}
    ]
}
  