const isFormValid = (formData) => {
    const errors = {};
    let isValid = true;

    if (!formData.fullName || formData.fullName.trim() === "") {
        errors.fullName = "Full Name is required";
        isValid = false;
    }

    if (!formData.mobile || formData.mobile.trim() === "") {
        errors.mobile = "Mobile Number is required";
        isValid = false;
    }
    
    if (!formData.query || formData.query.trim() === "") {
        errors.query = "Query is required";
        isValid = false;
    }

    return { isValid, errors };
};

export default isFormValid;