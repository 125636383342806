import React, {useState, useEffect, useContext} from "react";
import strings from "../../../localization";
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import  AutocompleteSelectControl from '../../../Components/Controls/AutocompleteSelectControl';
import {
    addUserPackage,
    deleteUserPackage,
    editPackage,
    getUsersByPackage
} from "../../../Services/ContractCategory/PackageService";
import PackageType from "../../../Constants/Admin/PackageType";
import {getAllUsers, getAllUsersWithoutImageAndSignature} from "../../../Services/User/UserService";
import UserType from "../../../Constants/User/UserType";
import { createFilterOptions, Tooltip } from "@mui/material";

const radioButtonList = [
    {name: strings.pages.adminPackages.allUsers, value: 'allUsers' },
    {name: strings.pages.adminPackages.selectedUsers, value: 'selectedUsers' }
]

const User = ({ 
                name, 
                pack, 
                usersIds,
                setUsersIds, 
                editIcon,
                setPackageType,
                maximumNumber,
                setMaximumNumber
            }) => {
    
    const [selectedRadio, setSelectedRadio] = useState(radioButtonList[0].value)
    const [showTags, setShowTags] = useState(true)
    const [otherUsers, setOtherUsers] = useState([])
    const [usersFullObjList, setUsersFullObjList] = useState([])
    
    useEffect(() => {
        setSelectedRadio(pack.packageType === PackageType.SELECTED_USERS ? 'selectedUsers' : 'allUsers')
        setPackageType(pack.packageType)
        setShowTags(pack.packageType === PackageType.SELECTED_USERS)
        setMaximumNumber(pack.maxNumberOfUsers)

        fetch();
    }, [])

    const fetch = () => {

        let all = [];
        let forPack = [];

        getAllUsersWithoutImageAndSignature().then(response => {

            response.data.map(user => {
                if(user.userProfileType === UserType.COMPANY_ADMIN) {
                    all = [...all, user];
                }
            });
        
            getUsersByPackage(pack.id).then(response => {
    
                if(!response || !response.ok) {
                    return;
                }

                if (response.data.length > 0) {

                    forPack = response.data.map(e => e.user)
                    
                    let other = all.filter(n => {
                        if (forPack.find(e => e.id == n.id)) {
                            return
                        } else {
                            return n
                        }
                    })
                    
                    setUsersFullObjList(forPack)
                    setOtherUsers(other)

                    let usersIds = []

                    for (let i of forPack) {
                        usersIds.push(i.id)
                    }

                    setUsersIds(usersIds)
                } else {
                    setOtherUsers(all)
                }
            })
        })
    }

    const handleCloseTag = (user) => {

        setUsersFullObjList(usersFullObjList.filter(e => e.id !== user.id))

        setOtherUsers([
            ...otherUsers,
            user
        ])

        let idListUpdate = usersIds.filter(e => e !== user.id)
        setUsersIds(idListUpdate)
    }

    const getDataItem = () => {
        let result = [];

        if(!usersFullObjList) {
            return  result;
        }

        for (let item of usersFullObjList) {

            result.push(
                <div className={editIcon ? "tag" : "tag disabled"} key={item.id}>
                    <Tooltip title={item.fullName + ' (' + item.email + ')'}>
                        <span className="tag-text">{item.fullName} ({item.email})</span>
                    </Tooltip>
                    {
                        editIcon &&
                        <CloseIcon className="close-icon" onClick={() => handleCloseTag(item)} />
                    }
                </div>
            )
        }
        return result
    }

    const handleInputChange = (user) => {

        setUsersFullObjList([
            ...usersFullObjList,
            user
        ])
        
        setOtherUsers(otherUsers.filter(e => e.id !== user.id))

        setUsersIds([
            ...usersIds,
            user.id
        ])
    }

    const handleRadio = (event) => {

        if (event.target.value == 'selectedUsers') {
            setPackageType(PackageType.SELECTED_USERS)
        } else {
            setPackageType(PackageType.ALL)
        }

        setSelectedRadio(event.target.value)
        setShowTags(event.target.value === 'selectedUsers')
    };

    const filterOptions = createFilterOptions({
        stringify: ({ fullName, email }) => `${fullName} ${email}`
      });

    return (
        <div className="item first drop">
            <div className="item-title">
                <span>{name}</span>
            </div>

            <FormControl>
                <RadioGroup
                    aria-label={strings.pages.adminPackages.users}
                    name="radio-buttons-group"
                    onChange={(event) => handleRadio(event)} 
                    className="radio-group-control"
                >
                    {
                        radioButtonList.map(e => 
                            <FormControlLabel
                                checked={selectedRadio === e.value}
                                value={e.value}
                                control={<Radio />} 
                                label={e.name}
                                disabled={!editIcon}
                            />
                        )
                    }
                </RadioGroup>
            </FormControl>
            
            <div className="max-num-container">
                <p className={!editIcon && "disabled"}>{strings.pages.adminPackages.maxNumOfInternalUsers}</p>
                <TextField
                    onChange={(event) => {

                        if(!editIcon) {
                            return;
                        }

                        setMaximumNumber(event.target.value)
                    }}
                    className={editIcon ? "textfield-custom" : "textfield-custom disabled"}
                    variant="standard"
                    InputProps={{
                        disableUnderline: true,
                    }}
                    value={maximumNumber !== -1 ? maximumNumber : null}
                    readOnly
                />
            </div>

            { (editIcon && showTags)  &&
                <AutocompleteSelectControl 
                    options={otherUsers}
                    nameKey='email'
                    handleInputChange={handleInputChange}
                    placeholder={strings.pages.adminPackages.enterEmailAddress}
                    filterOptions={filterOptions}
                />
            }

            { showTags &&
                <div className="tag-list">
                    {usersFullObjList.length > 0 && getDataItem()}
                </div>
            }

        </div>
    )
}

export default User;