import strings from "../../localization";

const UserProfileType = {
    COMPANY: 1,
    INDIVIDUAL: 2
}

export function getUserProfileType() {
    return [
        {
            id: UserProfileType.COMPANY,
            name: getUserProfileTypeString(UserProfileType.COMPANY)
        },
        {
            id: UserProfileType.INDIVIDUAL,
            name: getUserProfileTypeString(UserProfileType.INDIVIDUAL)
        }
    ];
}

export function getUserProfileTypeString(userProfileType) {
    switch(userProfileType) {
        case UserProfileType.COMPANY: return strings.constants.user.userProfileType.company;
        case UserProfileType.INDIVIDUAL: return strings.constants.user.userProfileType.individual;
    }
}

export default UserProfileType;