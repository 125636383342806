import React, { useEffect } from "react";
import {FormProvider, useForm} from "react-hook-form";
import strings from "../../../../localization";
import TextareaControl from '../../../Controls/Inputs/TextareaControl';
import {Button} from "@mui/material";
import {addComment, editComment} from "../../../../Services/Contract/CommentService";

const formRules = {
    'replay': {required: true}
}

const ReplaysForm = ({
                         contract,
                         fetch,
                         parent,
                         user,
                         comment,
                         onCancel
                     }) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;

    const onSubmit = (data) => {

        let saveData = {
            id: comment?.id && comment?.parent ? comment.id : null,
            contractId: contract.id,
            content: data.replay,
            parentId: parent.id,
            userId: user?.externalUserInviteID ? -1 : user.id,
            externalUserFullName: user?.externalUserInviteID ? user.fullName : null,
            externalUserEmail: user?.externalUserInviteID ? user.email : null,
        };

        setValue('replay', '')

        if(comment?.id && comment?.parent) {
            editComment(saveData).then(response => {
                fetch();
            });
        } else {
            addComment(saveData).then(response => {
                fetch();
            });
        }
    }

    useEffect(() => {
        if(comment) {
            setValue('replay', comment?.parent ? comment.content : '');
            return;
        }

        setValue('replay', '')

    }, [comment])

    return (
        <FormProvider {...form}>
            <TextareaControl
                name='replay'
                control={data}
                defaultValue=''
                rules={formRules['replay']}
                fullWidth
                margin="normal"
                error={Boolean(errors.replay)}
                helperText={errors.replay && strings.forms.common.required}
                placeholder={strings.forms.contractList.commentsForm.replayText}
                rows={3}
                maxRows={10}
            />
            <div className="submit-container">
                { comment && comment.parent &&
                    <Button className="default-btn white" style={{ marginRight: '10px' }} variant="contained" onClick={onCancel}>
                    {strings.forms.contractList.commentsForm.cancel}
                    </Button>
                }
                <Button className="default-btn" variant="contained" onClick={handleSubmit(onSubmit)}>
                    {comment && comment.parent ? strings.forms.contractList.commentsForm.save : strings.forms.contractList.commentsForm.submit}
                </Button>
            </div>
        </FormProvider>
    )
}

export default ReplaysForm;