import React, {useEffect, useState} from "react";
import strings from "../../../../../localization";
import TextFieldControl from '../../../../Controls/Inputs/TextFieldControl';
import TextareaControl from '../../../../Controls/Inputs/TextareaControl';
import ImageUploadComponent from '../../../../Controls/ImageUploadComponent';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import {Button} from "@mui/material";
import CheckBoxControl2 from "../../../../Controls/Inputs/CheckBoxControl2";

const SubPackageForm = ({
                            data,
                            setValue,
                            getValues,
                            errors,
                            setFeaturesFieldsNames,
                            setAdditionalFeaturesFieldsNames,
                            icon,
                            setIcon
                    }) => {
        
    let feturesFields = []
    let additionalFeaturesFields = []
    const [featuresCount, setFeaturesCount] = useState(1)
    const [additionalFeaturesCount, setAdditionalFeaturesCount] = useState(1)
    
    useEffect(() => {
        setFeaturesFieldsNames(feturesFields)
    }, [featuresCount])

    useEffect(() => {
        setAdditionalFeaturesFieldsNames(additionalFeaturesFields)
    }, [additionalFeaturesCount])

    const addFeature = () => {
        setFeaturesCount(featuresCount + 1)
    }

    const addAdditionalFeature = () => {
        setAdditionalFeaturesCount(additionalFeaturesCount + 1)
    }

    const renderFeatures = () => {
        let result = []

        for (let i = 0; i < featuresCount; i++) {
            result.push(
                <div className="item-field-row">
                    <div className="icon-container">
                        <CheckIcon className="icon check-icon" />
                    </div>
                    <TextFieldControl
                        name={'feature_' + i}
                        control={data}
                        defaultValue=''
                        rules={{required: false}}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.feature)}
                        helperText={errors.feature && strings.forms.admin.adminPackageForm.packages.feature}
                        placeholder={strings.forms.admin.adminPackageForm.packages.enterFeature}
                    />
                </div>
            )
            feturesFields.push('feature_' + i)
        } return result
    }

    const renderAdditionalFeatures = () => {
        let result = []

        for (let i = 0; i < additionalFeaturesCount; i++) {
            result.push(
                <div className="item-field-row">
                    <div className="icon-container">
                        <AddIcon className="icon plus-icon" />
                    </div>
                    <TextFieldControl
                        name={'additionalFeature_' + i}
                        control={data}
                        defaultValue=''
                        rules={{required: false}}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.additionalFeature)}
                        helperText={errors.additionalFeature && strings.forms.admin.adminPackageForm.packages.additionalFeature}
                        placeholder={strings.forms.admin.adminPackageForm.packages.enterAdditionalFeature}
                    />
                </div>
            )
            additionalFeaturesFields.push('additionalFeature_' + i)
        } return result
    }

    return (
        <div className="item left">
            <div className="item-title">
                <span>{strings.forms.admin.adminPackageForm.packages.packages}</span>
            </div>

            <div className="upload-icon-container">
                <div className="icon">
                    <ImageUploadComponent
                        accept={['.jpeg', '.jpg', '.png', '.svg']}
                        hideUploadButton={true}
                        setImage={setIcon}
                        image={icon}
                        icon={"/images/icons/icon-add.png"}
                    />
                </div>
                <div className="icon-text">
                    <p className="subtitle">{strings.forms.admin.adminPackageForm.packages.addIcon}</p>
                    <p className="text">{strings.forms.admin.adminPackageForm.packages.iconDescription}</p>
                </div>
            </div>

            <div className="item-field-container">
                <span className="info-icon-text">
                    {strings.forms.admin.adminPackageForm.packages.packageName}
                </span>
                <TextFieldControl
                    name='name'
                    control={data}
                    defaultValue=''
                    rules={{required: true}}
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.admin.adminPackageForm.packages.packageName}
                    placeholder={strings.forms.admin.adminPackageForm.packages.enterPackageName}
                />
            </div>

            <div className={'feature-checkbox-container'}>
                <CheckBoxControl2
                    name={'baseFeatures'}
                    control={data}
                    label={'Create Contracts '}
                    setValue={setValue}
                />

                <CheckBoxControl2
                    name={'dataRoomNoSignature'}
                    control={data}
                    label={'Dataroom'}
                    setValue={setValue}
                />

                <CheckBoxControl2
                    name={'dataRoomSignature'}
                    control={data}
                    label={'Dataroom and E-signature'}
                    setValue={setValue}
                />
            </div>

            <div className="item-field-container">
                <span className="info-icon-text">
                    {strings.forms.admin.adminPackageForm.packages.color}
                </span>
                <TextFieldControl
                    name='color'
                    control={data}
                    defaultValue=''
                    rules={{required: true}}
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.color)}
                    helperText={errors.color && strings.forms.admin.adminPackageForm.packages.color}
                    placeholder={strings.forms.admin.adminPackageForm.packages.enterColor}
                />
            </div>

            <div className="item-field-container">
                <span className="info-icon-text">
                    {strings.forms.admin.adminPackageForm.packages.description}
                </span>
                <TextareaControl
                    name='description'
                    control={data}
                    defaultValue=''
                    rules={{required: true}}
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.description)}
                    helperText={errors.description && strings.forms.admin.adminPackageForm.packages.description}
                    placeholder={strings.forms.admin.adminPackageForm.packages.enterDescription}
                    rows={3}
                    maxRows={5}
                />
            </div>

            <div className="border"></div>

            <div className="item-field-container">
                <span className="info-icon-text">
                    {strings.forms.admin.adminPackageForm.packages.featuresTitle}
                </span>
                <TextFieldControl
                    name='featureTitle'
                    control={data}
                    defaultValue=''
                    rules={{required: true}}
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.featureTitle)}
                    helperText={errors.featureTitle && strings.forms.admin.adminPackageForm.packages.featuresTitle}
                    placeholder={strings.forms.admin.adminPackageForm.packages.enterFeaturesTitle}
                />
            </div>

            {renderFeatures()}

            <Button className="neutral-btn btn feature-btn"
                startIcon={<AddIcon />}
                variant="contained" onClick={addFeature}>
                <span className="btn-txt">{strings.forms.admin.adminPackageForm.packages.newFeature}</span>
            </Button>

            <div className="border"></div>
            
            <div className="item-field-container">
                <span className="info-icon-text">
                    {strings.forms.admin.adminPackageForm.packages.additionalFeaturesTitle}
                </span>
                <TextFieldControl
                    name='additionalFeatureTitle'
                    control={data}
                    defaultValue=''
                    rules={{required: true}}
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.additionalFeatureTitle)}
                    helperText={errors.additionalFeatureTitle && strings.forms.admin.adminPackageForm.packages.additionalFeaturesTitle}
                    placeholder={strings.forms.admin.adminPackageForm.packages.enterAdditionalFeaturesTitle}
                />
            </div>

            {renderAdditionalFeatures()}

            <Button className="neutral-btn btn"
                startIcon={<AddIcon />}
                variant="contained" onClick={addAdditionalFeature}>
                <span className="btn-txt">{strings.forms.admin.adminPackageForm.packages.newAdditionalFeature}</span>
            </Button>

        </div>
    )
}

export default SubPackageForm;