import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Box, Modal } from "@mui/material";
import styles from "../../../Admin/AdminAIData/AIData.module.css";
import {
  downloadDueDiligenceDoc,
  dueDeligenceAddQuestion,
  dueDeligenceDeleteQuestion,
  dueDeligenceEditQuestion,
  dueDeligenceSaveOrUpdateResponse,
  dueDiligenceDeleteContractFromQuestion,
} from "../../../../Services/DealRoom/DealRoomService";
import { useNavigate, useParams } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DueDiligenceUploadModal from "../../../../Components/Modals/Pages/DealRoom/DueDiligenceModal";
import strings from "../../../../localization";
import SnackbarContext from "../../../../Context/SnackbarContext";

const QuestionRow = ({
  questionObj,
  sectionIndex,
  questionIndex,
  sectionRow,
  getSectionListHandler,
}) => {

  const inputRef = useRef(null)
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params?.id;
  const { showMessage } = useContext(SnackbarContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [questionRow, setQuestionRow] = useState({});
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const open = Boolean(anchorEl);

  const [isOpenChoiceBox, setisOpenChoiceBox] = useState({});


  const handleMouseLeave = (id) => {
    inputRef?.current?.blur();
    setisOpenChoiceBox({ ...isOpenChoiceBox, [id]: false })
    // setisOpenChoiceBox({ ...isOpenChoiceBox, sector: false })
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveQuestionHandler = () => {
    if (!questionRow?.question) {
      showMessage("Question can not be empty!", "error");
      return;
    }

    if (editMode) {
      let data = { question: questionRow?.question };
      dueDeligenceEditQuestion(projectId, questionRow?.id, data)
        .then((res) => {
          if (res.status === 204) {
            saveResponseHandler(questionRow?.id);
            toggleIseditableHandler();
          } else {
            showMessage("Error in updating", "error");
          }
        })
        .catch((err) => {
          console.log("error in dueDeligenceEditQuestion", err);
        });
    } else {
      let sectionId = sectionRow.id;
      let data = { question: questionRow.question };
      dueDeligenceAddQuestion(projectId, sectionId, data)
        .then((res) => {
          if (res.status === 204) {
            getSectionListHandler();
            showMessage("New request added");
          } else {
            showMessage("Error in adding new request", "error");
          }
        })
        .catch((err) => {
          console.log("error in dueDeligenceAddQuestion", err);
        });
    }
  };

  const saveResponseHandler = (questionId) => {
    if (questionRow?.id) {
      let data = {
        sectionQuestionId: questionId,
        answer: questionRow?.answer,
      };
      dueDeligenceSaveOrUpdateResponse(projectId, data)
        .then((res) => {
          if (res.status === 204) {
            getSectionListHandler();
            showMessage("Response Saved");
          } else {
            showMessage("Error in saving response", "error");
          }
        })
        .catch((err) => {
          console.log("error in dueDeligenceSaveOrUpdateResponse", err);
        });
    }
  };

  const deleteQuestionRowHandler = () => {
    dueDeligenceDeleteQuestion(projectId, sectionRow?.id, questionRow?.id)
      .then((res) => {
        if (res.status === 204) {
          getSectionListHandler();
          handleClose();
          showMessage("Request deleted");
        } else {
          showMessage("Error in deleting request", "error");
        }
      })
      .catch((err) => {
        console.log("error in dueDeligenceDeleteQuestion", err);
      });
  };

  const setFieldHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let tempData = structuredClone(questionRow);
    tempData[name] = value;
    setQuestionRow(tempData);
  };

  const toggleIseditableHandler = () => {
    let tempQuestionRow = structuredClone(questionRow);
    tempQuestionRow.iseditable = !tempQuestionRow.iseditable;
    setQuestionRow(tempQuestionRow);
    setEditMode(!editMode);
    handleClose();
  };

  const deleteDocumentHandler = (contractId) => {
    dueDiligenceDeleteContractFromQuestion(
      projectId,
      questionRow?.id,
      contractId
    )
      .then((res) => {
        if (res.status === 204) {
          getSectionListHandler();
          showMessage("Document deleted");
        } else {
          showMessage("Error in deleting document", "error");
        }
      })
      .catch((err) => {
        console.log("error in dueDiligenceDeleteContractFromQuestion", err);
      });
  };

  const docViewHandler = (docId) => {
    downloadDueDiligenceDoc(questionRow?.id, docId)
      .then((res) => {
        if (res?.status === 200) {
          navigate(`/virtualDealRoom/DocPreview/${params?.id}`, {
            state: res?.data,
          });
        } else {
          showMessage("Something went wrong", "error");
        }
      })
      .catch((err) => {
        console.log("error in downloadDueDiligenceDoc", err);
      });
  };

  useEffect(() => {
    setQuestionRow(questionObj);
  }, [questionObj]);

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        fontFamily: "Nobile",
        fontWeight: "bold",
        padding: "0rem 0.5rem",
        width: "calc(100% - 1rem)",
        margin: "0.5rem 0rem 1rem 0rem",
      }}
    >
      <Box display={"flex"} width={"30%"} justifyContent={"flex-start"}>
        <div className="question-serial-number">
          {sectionIndex + 1}.{questionIndex + 1}
        </div>
        {questionRow?.iseditable ? (
          <>
            <div
              className={styles.inputContainer}
              style={{
                margin: "5px 00px",
                height: "max-content",
                width: "346px",
              }}
            >
              <input
                className={styles.inputBox}
                name="question"
                value={questionRow?.question}
                onChange={setFieldHandler}
                type="text"
                disabled={sectionRow.isDefault && editMode}
              />
            </div>
            <div>
              <Button
                className="default-btn btn"
                variant="contained"
                style={{ margin: "10px" }}
                onClick={() => {
                  sectionRow.isDefault && editMode
                    ? saveResponseHandler(questionRow?.id)
                    : saveQuestionHandler();
                }}
              >
                {editMode ? "Update" : "Save"}
              </Button>
            </div>
          </>
        ) : (
          <div
            style={{ display: "flex", alignItems: "center", fontWeight: "500" }}
          >
            {questionRow?.question}
          </div>
        )}
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        sx={{ padding: "0rem 1rem", width: "calc(50% - 2rem)" }}
      >
        {questionRow?.id &&
          (questionRow?.iseditable ? (
            <div
              className={styles.inputContainer}
              style={{
                margin: "0px",
                height: "max-content",
                width: "100%",
              }}
            >
              <input
                className={styles.inputBox}
                value={questionRow?.answer}
                onChange={setFieldHandler}
                name="answer"
                type="text"
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "500",
              }}
            >
              {questionRow?.answer}
            </div>
          ))}
      </Box>

      <Box
        width={"20%"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {questionRow?.contracts?.length > 0 ? (
          <div className={[styles.inputContainer]} style={{ width: "150px", position: "relative" }}>
            
            <input
              readOnly
              name="docName"
              className={styles.inputBox}
              // onChange={(e) => docViewHandler(e.target.value)}
              type="text"
              value={questionRow?.contracts[0].name}
              ref={inputRef}
              onFocus={() => setisOpenChoiceBox({ ...isOpenChoiceBox, [questionRow.id]: true })}
              style={{
                WebkitAppearance: "none",
                // background: `url(/images/icons/downArrow.png)`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right center",
              }}
            />
            {isOpenChoiceBox?.[questionRow.id] && (
              <div className={styles.options} style={{ top: '50px', left: '0px', zIndex: "100" }}>
                <ul onMouseLeave={() => handleMouseLeave(questionRow.id)}>
                  {questionRow?.contracts?.map((contract, id) => {
                    return (
                      <li key={id} onClick={() => docViewHandler(contract.id)}>
                        {contract.name}
                      </li>
                    );
                  })}

                </ul>
              </div>
            )}
          </div>
        ) : (
          <img
            style={{ cursor: "pointer" }}
            onClick={() => setOpenUploadModal(true)}
            src={"/images/icons/UploadIcon.png"}
            alt="upload"
          />
        )}

        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <img src={"/images/icons/threeDotMenuIcon.png"} alt="three dot" />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {questionRow?.contracts?.length > 0 ? (
            <>
              {!sectionRow?.isDefault && (
                <MenuItem onClick={deleteQuestionRowHandler}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  Delete Question
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  deleteDocumentHandler(questionRow?.contracts[0]?.id);
                }}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                Delete Document
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem onClick={toggleIseditableHandler}>
                <ListItemIcon>
                  <ModeEditOutlineOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Edit
              </MenuItem>
              {!sectionRow.isDefault && (
                <MenuItem onClick={deleteQuestionRowHandler}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              )}
            </>
          )}
        </Menu>
      </Box>
      <Modal
        open={openUploadModal}
        onClose={() => setOpenUploadModal(false)}
        className="confirm-modal-container"
      >
        <DueDiligenceUploadModal
          contentText={strings.modals.adminDealRoom.deleteModalTitle}
          cancelBtn={strings.modals.adminDealRoom.no}
          confirmBtn={strings.modals.adminDealRoom.yes}
          handleCloseModal={() => setOpenUploadModal(false)}
          handleConfirm={() => setOpenUploadModal(false)}
          contentTextStyle={{ fontWeight: "600", fontSize: "22px" }}
          buttonsStyles={{ borderRadius: "10px !important" }}
          questionRow={questionRow}
          getSectionListHandler={getSectionListHandler}
        />
      </Modal>
    </Box>
  );
};

export default QuestionRow;
