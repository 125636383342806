import strings from "../../../localization";
import React from "react";
import StepQuestionList from "./StepQuestionList";

const AddContractStep = ({steps, watch, template, data, setValue, getValues, errors, relations}) => {

    const renderSteps = () => {

        let result = [];

        if(!steps) {
            return result;
        }

        for(let step of steps) {
            result.push(
                <div key={'sub-step-' + step.id} className="item">
                    <h1>{step.name}</h1>
                    <div className="border"/>
                    <StepQuestionList relations={relations} watch={watch} template={template} step={step} data={data} setValue={setValue} getValues={getValues} errors={errors}/>
                </div>
            )
        }

        return result;
    }


    return <div className='items'>
        {renderSteps(steps)}
    </div>
}

export default AddContractStep;