import React, {useEffect, useState} from 'react';
import { FormProvider, useForm } from "react-hook-form";
import strings from '../../../../../localization';
import Button from '@mui/material/Button';
import TextFieldControl from '../../../../Controls/Inputs/TextFieldControl';
import TextFieldPasswordControl from '../../../../Controls/Inputs/TextFieldPasswordControl';
import SelectControl from '../../../../Controls/Inputs/SelectControl';
import FileUploadComponent from '../../../../Controls/FileUploadComponent';
import ImageUploadComponent from '../../../../Controls/ImageUploadComponent';
import {getAllFolders} from '../../../../../Services/Folder/FolderService';
import CloseIcon from '@mui/icons-material/Close';
import ValidationPatterns from '../../../../../Constants/Base/ValidationPatterns';
import BaseUserDetails from "../../../../../Pages/Dashboard/BaseUserDetails";
import ChangePassword from "../../../../../Pages/Dashboard/ChangePassword";

const GeneralInformationForm = ({ handleAddInfo, userProfileType }) => {

    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
    }

    return (
        <React.Fragment>
            <BaseUserDetails 
                handleAddInfo={handleAddInfo} 
                userProfileType={userProfileType}
            />
            <ChangePassword/>
        </React.Fragment>
    )
}

export default GeneralInformationForm;