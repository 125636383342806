import strings from "../../localization";

const UserType = {
    ALL: -1,
    COMPANY_ADMIN: 1,
    INDIVIDUAL: 2,
    COMPANY_USER: 3,
    PORTAL_ADMIN: 4,
    SYSTEM_ADMIN: 5,
    GUEST: 6,
}

export function getUserTypes(userProfileType) {

    let types = [
        {
            id: UserType.COMPANY_ADMIN,
            name: getUserTypeString(UserType.COMPANY_ADMIN),
            tooltip: getUserTypeTooltip(UserType.COMPANY_ADMIN)
        },
        {
            id: UserType.COMPANY_USER,
            name: getUserTypeString(UserType.COMPANY_USER),
            tooltip: getUserTypeTooltip(UserType.COMPANY_USER)
        }
    ]

    if(userProfileType === UserType.COMPANY_ADMIN) {
        return types;
    }

    return [
        ...types,
        {
            id: UserType.INDIVIDUAL,
            name: getUserTypeString(UserType.INDIVIDUAL),
            tooltip: getUserTypeTooltip(UserType.INDIVIDUAL)
        },
        {
            id: UserType.PORTAL_ADMIN,
            name: getUserTypeString(UserType.PORTAL_ADMIN),
            tooltip: getUserTypeTooltip(UserType.PORTAL_ADMIN)
        },
        {
            id: UserType.SYSTEM_ADMIN,
            name: getUserTypeString(UserType.SYSTEM_ADMIN),
            tooltip: getUserTypeTooltip(UserType.SYSTEM_ADMIN)
        },
        {
            id: UserType.GUEST,
            name: getUserTypeString(UserType.GUEST),
            tooltip: getUserTypeTooltip(UserType.GUEST)
        }
    ];
}

export function getUserTypesWithAll(userProfileType) {
    return [
        {
            id: UserType.ALL,
            name: getUserTypeString(UserType.ALL),
            tooltip: getUserTypeTooltip(UserType.ALL)
        },
        ...getUserTypes(userProfileType)
    ]
}

export function getUserTypesDefault(userProfileType) {

    if(userProfileType === UserType.COMPANY_ADMIN) {
        return UserType.COMPANY_USER;
    }

    return UserType.GUEST;
}

export function getUserTypeString(userType) {
    switch(userType) {
        case UserType.ALL: return strings.constants.user.userType.all;
        case UserType.COMPANY_ADMIN: return strings.constants.user.userType.companyAdminUser;
        case UserType.INDIVIDUAL: return strings.constants.user.userType.individualUser;
        case UserType.COMPANY_USER: return strings.constants.user.userType.companyUser;
        case UserType.PORTAL_ADMIN: return strings.constants.user.userType.portalAdminUser;
        case UserType.SYSTEM_ADMIN: return strings.constants.user.userType.systemAdminUser;
        case UserType.GUEST: return strings.constants.user.userType.guestUser;
    }
}

export function getUserTypeTooltip(userType) {
    switch(userType) {
        case UserType.COMPANY_ADMIN: return strings.constants.user.userType.companyAdminUserTooltip;
        case UserType.INDIVIDUAL: return strings.constants.user.userType.individualUserTooltip;
        case UserType.COMPANY_USER: return strings.constants.user.userType.companyUserTooltip;
        case UserType.PORTAL_ADMIN: return strings.constants.user.userType.portalAdminUserTooltiop;
        case UserType.SYSTEM_ADMIN: return strings.constants.user.userType.systemAdminUserTooltip;
        case UserType.GUEST: return strings.constants.user.userType.guestUserTooltip;
    }
}

export function getUserTypeStringAndClass(userType) {
    switch(userType) {
        case UserType.COMPANY_ADMIN: return {name: strings.constants.user.userType.companyAdminUser, customClass: 'system-admin user-type'};
        case UserType.INDIVIDUAL: return {name: strings.constants.user.userType.individualUser, customClass: 'portal-admin user-type'}
        case UserType.COMPANY_USER: return {name: strings.constants.user.userType.companyUser, customClass: 'business-user user-type'};
        case UserType.PORTAL_ADMIN: return {name: strings.constants.user.userType.portalAdminUser, customClass: 'individual-user user-type'}
        case UserType.SYSTEM_ADMIN: return {name: strings.constants.user.userType.systemAdminUser, customClass: 'guset-user user-type'};
        case UserType.GUEST: return {name: strings.constants.user.userType.guestUser, customClass: 'business-company-user user-type'}
    }
}

export default UserType;