import React, { useEffect, useState, useContext, useRef } from "react";
import styles from "./AIData.module.css";
import { Close, Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import strings from "../../../localization";
import {
  clauseUpdateById,
  createClause,
  getClauseOptions,
} from "../../../Services/AI/AIConversationService";
import SnackbarContext from "../../../Context/SnackbarContext";
import Modal from "@mui/material/Modal";
import SimpleConfirmModal from "./../../../Components/Modals/SimpleConfirmModal";
import { Button } from "@mui/material";
import { aiDataActions } from "../../../Slices/AiDataSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllContractCategories } from "../../../Services/ContractCategory/ContractCategoryService";
import { getAllTemplates } from "../../../Services/Template/TemplateService";
import { getSectors } from "../../../Constants/User/Sector";

const defaultOptions = {
  category: false,
  contract: false,
  contract_variation: false,
  sector: false,
  smart_tag: false,
  clause_codes: false,
};

function AIDataCreateViewComponent({
  aiDataRow,
  choiceBoxName,
  handleDeleteTab,
  pageName,
  deleteAiRequestHandler,
}) {
  const { showMessage } = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);

  const focusChangeHandler = () => {
    if (
      inputRef1.current ||
      inputRef2.current ||
      inputRef3.current ||
      inputRef4.current ||
      inputRef5.current ||
      inputRef6.current
    ) {
      inputRef1.current.blur();
      inputRef2.current.blur();
      inputRef3.current.blur();
      inputRef4.current.blur();
      inputRef5.current.blur();
      inputRef6.current.blur();
    }
  };

  const [rowData, setRowData] = useState("");
  const [questionInput, setQuestionInput] = useState("");
  const [isQuestionEditable, setIsQuestionEditable] = useState(false);
  const [index, setIndex] = useState(null);
  const [autoSaveEnable, setAutoSaveEnable] = useState(false);

  const [clauseOptions, setClauseOptions] = useState([]);
  const [searchMatchOptions, setSearchMatchOptions] = useState({});
  const [isOptionOpen, setIsOptionOpen] = useState(defaultOptions);
  const [isOpenQuesDeleteModal, setIsOpenQuesDeleteModal] = useState(false);

  const [nameToDelete, setNameToDelete] = useState("");
  const [valueToDelete, setValueToDelete] = useState("");
  const [optionText, setOptionText] = useState("");
  const [showLoader, setShowLoader] = useState({
    publish: false,
  });
  const [showOptionForm, setShowOptionForm] = useState(false);
  const [optionName, setOptionName] = useState("");
  const [clauseCode, setClauseCode] = useState("");
  const [isClauseOptionAvailable, setIsClauseOptionAvailable] = useState(false);
  const [isOptionEditable, setIsOptionEditable] = useState(false);

  const QuestionWithOption = "question_with_options";

  const questionDeleteModelOpen = (name, value) => {
    // setDeleteId(id);
    setNameToDelete(name);
    setValueToDelete(value);
    setIsOpenQuesDeleteModal(true);
  };

  const handleCloseDeleteTab = () => {
    setNameToDelete("");
    setValueToDelete("");
    setIsOpenQuesDeleteModal(false);
  };

  const setSearchMatchOptionsHandler = () => {
    setSearchMatchOptions({
      category: clauseOptions.category,
      contract: clauseOptions.contract,
      contract_variation: clauseOptions.contract_variation,
      sector: clauseOptions.sector,
      smart_tag: clauseOptions.smart_tag,
      clause_codes: clauseOptions.clause_codes,
    });
  };

  const optionListHandler = (e) => {
    let name = e.target.name;
    setOptionText(e.target.value);
    let value = e.target.value.toLowerCase();
    if(name === "clause_codes"){
      setClauseCode(value)
    }
    if (value) {
      setSearchMatchOptions((preData) => {
        let updatedData = { ...preData };
        updatedData[name] = preData[name].filter((ele) =>
          ele.toLowerCase().includes(value)
        );
        return updatedData;
      });
    } else {
      setSearchMatchOptionsHandler();
    }
  };
  const focusHandler = (name) => {
    if (name) {
      setIsOptionOpen((prevState) => {
        const updatedOptions = { ...defaultOptions };
        updatedOptions[name] = true;
        return updatedOptions;
      });
    } else {
      setIsOptionOpen(defaultOptions);
      focusChangeHandler();
    }
  };

  const onSubmitHandler = () => {
    if (rowData?.id) {
      clauseUpdateById(rowData)
        .then((res) => {
          if (res.data) {
            showMessage(strings.common.saved);
          }
        })
        .catch((err) => {
          console.log("err");
        });
      setAutoSaveEnable(false);
    }
  };

  const arrayInputHandler = (e) => {
    if (e?.target?.name === "question") {
      setQuestionInput(e.target.value);
    }
  };

  const addArrayVarDataHandler = (name, value) => {
    if (
      name === "category" ||
      "contract" ||
      "contract_variation" ||
      "sector" ||
      "smart_tag"
    ) {
      setAutoSaveEnable(true);
      setOptionText("");
    }

    if (isQuestionEditable && questionInput) {
      let tempArrayEle = [...rowData[name]];

      tempArrayEle[index] = questionInput;

      let tempData = structuredClone(rowData);
      tempData[name] = tempArrayEle;
      setRowData(tempData);
      setIsQuestionEditable(false);
      setIndex(null);
      setQuestionInput("");
    } else {
      // console.log("else")
      if (value) {
        if (rowData[name]?.includes(value)) {
          showMessage("Already Exist!");
        } else {
          let tempData = structuredClone(rowData);
          tempData[name] = [...rowData[name], value];
          setRowData(tempData);
        }
      } else {
        showMessage("Please write some text!", "error");
      }
    }
    setQuestionInput("");
  };

  const handleAddbuttonForOptionForm = () => {
    if (clauseCode && optionName) {
      // if (!isOptionEditable) {
      setRowData((previousData) => {
        const newData = {
          ...previousData,
          linked_clauses: {
            ...previousData.linked_clauses,
            [clauseCode]: optionName,
          },
        };
        return newData;
      });
      setAutoSaveEnable(true);
      setClauseCode("");
      setOptionName("");
      setSearchMatchOptionsHandler()
      // } else {
      //   console.log("I am hereeee")
      //   const updatedLinkedClause = rowData?.linked_clauses
      //   let tempData = structuredClone(rowData);

      //   let position = 0;
      //   for (const key in tempData) {
      //     if (position === index) {
      //       delete updatedLinkedClause[key];
      //       tempData[clauseCode] = updatedLinkedClause;
      //       break;
      //     }
      //     position++;
      // }

      // Update the state with the modified object
      // setRowData(tempData);
      // }
    } else {
      showMessage("Please enter both clause code and option name.", "error");
    }
  };

  const deleteArrayEleHandler = (name, value) => {
    setAutoSaveEnable(true);
    const remainEles = rowData[name].filter((ele) => ele !== value);
    let tempData = structuredClone(rowData);
    tempData[name] = remainEles;
    setRowData(tempData);
    setIsOpenQuesDeleteModal(false);
    showMessage(strings.common.deleted);
    setNameToDelete("");
    setValueToDelete("");
  };
  const deleteOptionObjectHandler = (obj, keyToDelete) => {
    setAutoSaveEnable(true);
    let updatedLinkedClauseObj = structuredClone(rowData?.linked_clauses);
    delete updatedLinkedClauseObj[keyToDelete];
    let tempData = structuredClone(rowData);
    tempData.linked_clauses = updatedLinkedClauseObj;
    setRowData(tempData);
  };

  const onChangeInputHandler = (e, tempName) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "data_type") {
      setAutoSaveEnable(true);
    }
    if (value === "question_with_options") {
      setShowOptionForm(true);
    }
    if (value === "Question" || value === "Clause") {
      setShowOptionForm(false);
    }

    if (tempName === "response") {
      name = tempName;
      value = e.currentTarget.innerHTML;
    }

    let tempData = structuredClone(rowData);
    tempData[name] = value;
    setRowData(tempData);
  };

  const editHandler = (index, que) => {
    setQuestionInput(que);
    setIsQuestionEditable(true);
    setIndex(index);
  };

  // const optionNameEditHandler = (index, optNameValue, clauseCodeValue) => {
  //   setIsOptionEditable(true);
  //   setOptionName(optNameValue);
  //   setClauseCode(clauseCodeValue);
  // const updatedLinkedClauses = { ...rowData.linked_clauses };

  // let position = 0;
  // for (const prop in updatedLinkedClauses) {
  //   if (position === index) {
  //     delete updatedLinkedClauses[prop]; // Delete the key at the specified index
  //     updatedLinkedClauses[clauseCodeValue] = prop; // Replace it with the new key
  //     console.log("Position of", clauseCodeValue, ":", position);
  //     break;
  //   }
  //   position++;
  // }

  // // Update the state with the modified object
  // setRowData((prevState) => ({
  //   ...prevState,
  //   linked_clauses: updatedLinkedClauses,
  // }));
  // };

  // const replaceKey = (obj, oldKey, newKey)

  const createNewHandler = () => {
    if (
      (showOptionForm &&
        rowData?.name &&
        Object.keys(rowData?.linked_clauses)?.length > 0 &&
        rowData?.question?.length > 0) ||
      (rowData?.name && rowData?.response && rowData?.question?.length > 0)
    ) {
      const tempShowLoader = structuredClone(showLoader);
      tempShowLoader.publish = true;
      setShowLoader(tempShowLoader);
      createClause(rowData)
        .then((res) => {
          if (res.data) {
            dispatch(aiDataActions.setAiData(res.data));
            // setSubOption("AIDataCreate");
            deleteAiRequestHandler();
            setShowLoader((preData) => {
              preData.publish = false;
              return preData;
            });
            if (pageName === "AIDataCreate") {
              navigate(`/AIData/${res.data.id}`);
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      showMessage(
        "Name, Response or Response Question, Option Name or code and Question can not be empty!",
        "error"
      );
    }
  };
  function convertToCamelCase(text) {
    if (text.toLowerCase() === "question_with_options") {
      text = text.replace(/_/g, " ");
    }

    return text.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  useEffect(() => {
    setRowData(aiDataRow);
  }, [aiDataRow]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (autoSaveEnable) {
        onSubmitHandler();
      }
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [rowData]);

  useEffect(() => {
    getClauseOptions()
      .then((res) => {
        getAllContractCategories().then((response) => {
          res.data.category = response.data?.map((cat) => cat?.name);
          getAllTemplates().then((response1) => {
            res.data.contract = response1.data?.map((temp) => temp?.name);
            res.data.sector = getSectors()?.map((sec) => sec?.name);
            res.data.sector.unshift("General");
            setClauseOptions(res.data);
            setIsClauseOptionAvailable(true);
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(rowData).length > 0 && isClauseOptionAvailable === true) {
      if (rowData?.id) {
        if (rowData?.data_type === QuestionWithOption) {
          setShowOptionForm(true);

          let tempClauseOptions = structuredClone(clauseOptions);
          tempClauseOptions.data_type = [rowData?.data_type];
          setClauseOptions(tempClauseOptions);
        } else {
          let tempClauseOptions = structuredClone(clauseOptions);
          let index = clauseOptions.data_type.indexOf(QuestionWithOption);
          tempClauseOptions.data_type.splice(index, 1);
          setClauseOptions(tempClauseOptions);
        }
      }
    }
  }, [rowData, isClauseOptionAvailable]);

  useEffect(() => {
    setSearchMatchOptionsHandler();
  }, [clauseOptions,]);
  return (
    <div
      className={`${styles.AIDataCreate2ndCol} ${
        choiceBoxName === "NewRequest" ? styles.newRequestStyles : ""
      }`}
    >
      <div className={styles.whiteBox}>
        <div
          className={styles.formRow}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className={styles.filledBox}>
            <div className={styles.labelText}>
              <span>
                {strings.pages.adminAIData.aiData.createPage.dataTypeHeading}
              </span>
            </div>

            <div className={[styles.inputContainer]}>
              <select
                value={rowData?.data_type}
                name="data_type"
                onChange={(e) => onChangeInputHandler(e)}
                className={styles.inputBox}
                type="text"
                style={{
                  WebkitAppearance: "none",
                  background: `url(/images/icons/downArrow.png)`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "right center",
                }}
              >
                {clauseOptions?.data_type?.map((data, id) => {
                  if (data) {
                    return (
                      <option key={id} value={data}>
                        {convertToCamelCase(data)}
                      </option>
                    );
                  }
                })}
              </select>
              {/* <ArrowDropDown /> */}
            </div>
          </div>
          <div className={styles.codeAndDelete}>
            {rowData?.id ? (
              <>
                <label>code :</label>
                <span>{rowData?.code}</span>
                <img
                  onClick={() => handleDeleteTab(rowData?.id)}
                  src="/images/icons/deleteNewRequest.png"
                  alt="deleteIcon"
                />
              </>
            ) : showLoader.publish ? (
              <Button
                className="default-btn filter-btn btn"
                variant="contained"
              >
                <div className={styles.loader}> </div>
              </Button>
            ) : (
              <Button
                className="default-btn filter-btn btn"
                variant="contained"
                onClick={createNewHandler}
              >
                <span>Publish</span>
              </Button>
            )}
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.filledBox}>
            <div className={styles.labelText}>
              <span>Name</span>
            </div>
            <div className={styles.inputContainer}>
              <input
                className={styles.inputBox}
                value={rowData?.name}
                name="name"
                onChange={(e) => onChangeInputHandler(e, rowData.id)}
                type="text"
                placeholder="Add the Name"
              />
              {rowData?.id && (
                <div
                  className={styles.inputContainerButton}
                  onClick={onSubmitHandler}
                >
                  <span>Save</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {!showOptionForm && (
          <div className={styles.formRow}>
            <div>
              <div
                className={styles.labelText}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span>Response</span>
                {rowData?.id && (
                  <div
                    className={styles.inputContainerButton}
                    onClick={onSubmitHandler}
                  >
                    <span style={{ fontWeight: "100px", fontStyle: "normal" }}>
                      Save
                    </span>
                  </div>
                )}
              </div>
              <div className={styles.inputContainer}>
                <div
                  contentEditable
                  dangerouslySetInnerHTML={{ __html: rowData?.response }}
                  className={styles.textarea}
                  name="response"
                  onBlur={(e) => onChangeInputHandler(e, "response")}
                  // onChange={(e) => onChangeInputHandler(e)}
                  // value={rowData?.response}
                  type="text"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        )}

        <div className={styles.formRow}>
          <div>
            <div
              className={styles.labelText}
              style={{ flexDirection: "column" }}
            >
              <span>Questions</span>
              <span className={styles.labelNoticeText}>
                {strings.pages.adminAIData.aiData.createPage.questionNote}
              </span>
            </div>
            <div className={styles.questionBox}>
              <div
                className={styles.inputContainer}
                style={{ width: "100%", marginRight: "30px" }}
              >
                <input
                  className={styles.inputBox}
                  value={questionInput}
                  name="question"
                  onChange={(e) => arrayInputHandler(e)}
                  type="text"
                  placeholder="Add the Question"
                />
              </div>
              <div
                className={styles.inputContainerButton}
                onClick={() =>
                  addArrayVarDataHandler("question", questionInput)
                }
              >
                <AddIcon />
                <span>{isQuestionEditable ? "Save" : "Add"}</span>
              </div>
            </div>

            {rowData?.question?.map((que, index) => {
              return (
                <div
                  key={index}
                  className={styles.questionBox}
                  style={{ backgroundColor: "#F8F8F9" }}
                >
                  <div className={styles.questionBoxText}>
                    <span>{que}</span>
                  </div>
                  <div className={styles.iconContainer}>
                    <div
                      className={styles.iconBox}
                      onClick={() => editHandler(index, que)}
                    >
                      <Edit />
                    </div>
                    <div
                      className={styles.iconBox}
                      onClick={() => questionDeleteModelOpen("question", que)}
                    >
                      <img
                        className={styles.iconImg}
                        src="/images/icons/delete.png"
                        alt="delete"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {showOptionForm && (
          <div className={styles.formRow}>
            <div>
              <div
                className={styles.labelText}
                style={{ flexDirection: "column" }}
              >
                <span>Response Question</span>
                <span className={styles.labelNoticeText}>
                  {
                    strings.pages.adminAIData.aiData.createPage
                      .responseQuestionNote
                  }
                </span>
              </div>
              <div className={styles.questionBox}>
                <div
                  className={styles.inputContainer}
                  style={{ width: "100%", marginRight: "30px" }}
                >
                  <input
                    className={styles.inputBox}
                    value={rowData?.response_question}
                    name="response_question"
                    onChange={(e) => onChangeInputHandler(e, rowData?.id)}
                    type="text"
                    placeholder="Add the Question"
                  />
                </div>
                {/* <div
                  className={styles.inputContainerButton}
                  // onClick={onSubmitHandler}
                  onClick={() =>
                    addArrayVarDataHandler(
                      "response_question",
                      responseQuestionInput
                    )
                  }
                >
                  <span>Save</span>
                </div> */}
              </div>
            </div>

            <div
              className={styles.labelText}
              style={{ flexDirection: "column", marginTop: "25px" }}
            >
              <span>OPTIONS</span>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5rem",
                flexDirection: "column",
              }}
            >
              <div
                className={styles.questionBox}
                style={{
                  justifyContent: "none",
                  height: "8rem",
                  alignItems: "unset",
                  columnGap: "6rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40%",
                  }}
                >
                  <div
                    className={styles.labelText}
                    style={{ margin: "0px 0px 10px" }}
                  >
                    <span>
                      {
                        strings.pages.adminAIData.aiData.createPage.options
                          .optionsName
                      }
                    </span>
                  </div>
                  <div className={styles.inputContainer}>
                    <input
                      className={styles.inputBox}
                      value={optionName}
                      type="text"
                      name="option_name"
                      onChange={(e) => setOptionName(e.target.value)}
                      placeholder="Add Option Name"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40%",
                  }}
                >
                  <div
                    className={styles.labelText}
                    style={{ margin: "0px 0px 10px" }}
                  >
                    <span>
                      {strings.pages.adminAIData.aiData.createPage.options.clauseOptionCode}
                    </span>
                  </div>
                  <div className={styles.inputContainer}>
                    <input
                      ref={inputRef6}
                      className={styles.inputBox}
                      type="text"
                      name="clause_codes"
                      onFocus={() => focusHandler("clause_codes")}
                      onChange={(e) => optionListHandler(e)}
                      placeholder="Add Option Code"
                      value={clauseCode}
                      
                    />
                  </div>
                  {isOptionOpen?.clause_codes && (
                    <div
                      className={styles.options}
                      style={{ top: "0%", position: "relative", zIndex: 1 }}
                    >
                      <ul
                        onMouseEnter={() => focusHandler("clause_codes")}
                        onMouseLeave={() => focusHandler()}
                      >
                        {searchMatchOptions?.clause_codes?.map(
                          (clause_codes, index) => {
                            return (
                              <li
                                id={index}
                                onClick={(e) =>
                                  setClauseCode(e.target.innerText)
                                }
                              >
                                {clause_codes}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div
                  className={styles.inputContainerButton}
                  style={{
                    width: "85px",
                    alignSelf: "center",
                    marginBottom: "20px",
                  }}
                  onClick={() => handleAddbuttonForOptionForm()}
                >
                  <AddIcon />
                  <span>Add</span>
                </div>
              </div>
              {rowData?.linked_clauses &&
                Object.entries(rowData?.linked_clauses).map(([key, value]) => {
                  return (
                    <div
                      key={key}
                      className={styles.questionBox}
                      style={{ backgroundColor: "#F8F8F9" }}
                    >
                      <div className={styles.questionBoxText}>
                        <span>
                          {key} : {value}
                        </span>
                      </div>
                      <div className={styles.iconContainer}>
                        {/* <div
                          className={styles.iconBox}
                          onClick={() =>
                            optionNameEditHandler(index, value, key)
                          }
                        >
                          <Edit />
                        </div> */}
                        <div
                          className={styles.iconBox}
                          onClick={() =>
                            deleteOptionObjectHandler(
                              rowData.linked_clauses,
                              key
                            )
                          }
                        >
                          <img
                            className={styles.iconImg}
                            src="/images/icons/delete.png"
                            alt="delete"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>

      <div className={styles.choiceBoxContainer}>
        <div className={styles.box}>
          <div className={styles.whiteBox}>
            <div className={styles.formRow}>
              <div className={styles.labelText}>
                <span>
                  {strings.pages.adminAIData.aiData.createPage.options.category}
                </span>
              </div>
              <hr className={styles.hrline} />
              <div
                className={styles.inputContainer}
                style={{ margin: "0", padding: "0px" }}
              >
                <input
                  ref={inputRef1}
                  className={styles.inputBox}
                  type="text"
                  name="category"
                  onChange={(e) => optionListHandler(e)}
                  onFocus={() => focusHandler("category")}
                  placeholder="Search categories"
                />
                {isOptionOpen?.category && (
                  <div className={styles.options}>
                    <ul
                      onMouseEnter={() => focusHandler("category")}
                      onMouseLeave={() => focusHandler()}
                    >
                      {searchMatchOptions?.category?.map((category, index) => {
                        return (
                          <li
                            id={index}
                            onClick={() =>
                              addArrayVarDataHandler("category", category)
                            }
                          >
                            {category}
                          </li>
                        );
                      })}
                      {/* <li
                        id={"add"}
                        onClick={() =>
                          addArrayVarDataHandler("category", optionText)
                        }
                      >
                        Add +
                      </li> */}
                    </ul>
                  </div>
                )}
              </div>
              <div className={styles.selectedOptionsContainer}>
                {rowData?.category?.map((cate, id) => {
                  return (
                    <div key={id} className={styles.selectedOptionsBox}>
                      <span> {cate}</span>
                      <Close
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteArrayEleHandler("category", cate)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.whiteBox}>
            <div className={styles.formRow}>
              <div className={styles.labelText}>
                <span>
                  {strings.pages.adminAIData.aiData.createPage.options.contract}
                </span>
              </div>
              <hr className={styles.hrline} />
              <div
                className={styles.inputContainer}
                style={{ marign: "0", padding: "0px" }}
              >
                <input
                  ref={inputRef2}
                  className={styles.inputBox}
                  type="text"
                  name="contract"
                  onChange={(e) => optionListHandler(e)}
                  onFocus={() => focusHandler("contract")}
                  placeholder="Search contracts"
                />
                {isOptionOpen?.contract && (
                  <div className={styles.options}>
                    <ul
                      onMouseEnter={() => focusHandler("contract")}
                      onMouseLeave={() => focusHandler()}
                    >
                      {searchMatchOptions?.contract?.map((contract, index) => {
                        return (
                          <li
                            id={index}
                            onClick={() =>
                              addArrayVarDataHandler("contract", contract)
                            }
                          >
                            {contract}
                          </li>
                        );
                      })}
                      <li
                        id={"add"}
                        onClick={() =>
                          addArrayVarDataHandler("contract", optionText)
                        }
                      >
                        Add +
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className={styles.selectedOptionsContainer}>
                {rowData?.contract?.map((cont, id) => {
                  return (
                    <div key={id} className={styles.selectedOptionsBox}>
                      <span> {cont}</span>
                      <Close
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteArrayEleHandler("contract", cont)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.whiteBox}>
            <div className={styles.formRow}>
              <div className={styles.labelText}>
                <span>
                  {
                    strings.pages.adminAIData.aiData.createPage.options
                      .contractVariation
                  }
                </span>
              </div>
              <hr className={styles.hrline} />
              <div
                className={styles.inputContainer}
                style={{ margin: "0", padding: "0px" }}
              >
                <input
                  ref={inputRef3}
                  className={styles.inputBox}
                  type="text"
                  name="contract_variation"
                  onChange={(e) => optionListHandler(e)}
                  onFocus={() => focusHandler("contract_variation")}
                  placeholder="Search contract variation"
                />
                {isOptionOpen?.contract_variation && (
                  <div className={styles.options}>
                    <ul
                      onMouseEnter={() => focusHandler("contract_variation")}
                      onMouseLeave={() => focusHandler()}
                    >
                      {searchMatchOptions?.contract_variation?.map(
                        (contractVar, index) => {
                          return (
                            <li
                              id={index}
                              onClick={() =>
                                addArrayVarDataHandler(
                                  "contract_variation",
                                  contractVar
                                )
                              }
                            >
                              {contractVar}
                            </li>
                          );
                        }
                      )}
                      <li
                        id={"add"}
                        onClick={() =>
                          addArrayVarDataHandler(
                            "contract_variation",
                            optionText
                          )
                        }
                      >
                        Add +
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className={styles.selectedOptionsContainer}>
                {rowData?.contract_variation?.map((cont_Var, id) => {
                  return (
                    <div key={id} className={styles.selectedOptionsBox}>
                      <span> {cont_Var}</span>
                      <Close
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          deleteArrayEleHandler("contract_variation", cont_Var)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.whiteBox}>
            <div className={styles.formRow}>
              <div className={styles.labelText}>
                <span>
                  {strings.pages.adminAIData.aiData.createPage.options.sector}
                </span>
              </div>
              <hr className={styles.hrline} />
              <div
                className={styles.inputContainer}
                style={{ margin: "0", padding: "0px" }}
              >
                <input
                  ref={inputRef4}
                  className={styles.inputBox}
                  type="text"
                  name="sector"
                  onChange={(e) => optionListHandler(e)}
                  onFocus={() => focusHandler("sector")}
                  placeholder="Search Sector"
                />
                {isOptionOpen?.sector && (
                  <div className={styles.options}>
                    <ul
                      onMouseEnter={() => focusHandler("sector")}
                      onMouseLeave={() => focusHandler()}
                    >
                      {searchMatchOptions?.sector?.map((sector, index) => {
                        return (
                          <li
                            id={index}
                            onClick={() =>
                              addArrayVarDataHandler("sector", sector)
                            }
                          >
                            {sector}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
              <div className={styles.selectedOptionsContainer}>
                {rowData?.sector?.map((sec, id) => {
                  return (
                    <div key={id} className={styles.selectedOptionsBox}>
                      <span> {sec}</span>
                      <Close
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteArrayEleHandler("sector", sec)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.box} style={{ width: "66%" }}>
          <div className={styles.whiteBox}>
            <div className={styles.formRow}>
              <div className={styles.labelText}>
                <span>
                  {strings.pages.adminAIData.aiData.createPage.options.smartTag}
                </span>
              </div>
              <hr className={styles.hrline} />
              <span className={styles.labelNoticeText}>
                {
                  strings.pages.adminAIData.aiData.createPage.options
                    .smartTag_Note
                }
              </span>

              <div
                className={styles.inputContainer}
                style={{ margin: "0", padding: "0px" }}
              >
                <input
                  ref={inputRef5}
                  className={styles.inputBox}
                  type="text"
                  name="smart_tag"
                  onChange={(e) => optionListHandler(e)}
                  onFocus={() => focusHandler("smart_tag")}
                  placeholder="Enter Smart Tags"
                />
                {isOptionOpen?.smart_tag && (
                  <div className={styles.options}>
                    <ul
                      onMouseEnter={() => focusHandler("smart_tag")}
                      onMouseLeave={() => focusHandler()}
                    >
                      {searchMatchOptions?.smart_tag?.map((tag, index) => {
                        return (
                          <li
                            id={index}
                            onClick={() =>
                              addArrayVarDataHandler("smart_tag", tag)
                            }
                          >
                            {tag}
                          </li>
                        );
                      })}
                      <li
                        id={"add"}
                        onClick={() =>
                          addArrayVarDataHandler("smart_tag", optionText)
                        }
                      >
                        Add +
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className={styles.selectedOptionsContainer}>
                {rowData?.smart_tag?.map((smartTag, index) => {
                  return (
                    <div id={index} className={styles.selectedOptionsBox}>
                      <span> {smartTag}</span>
                      <Close
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          deleteArrayEleHandler("smart_tag", smartTag)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isOpenQuesDeleteModal}
        onClose={handleCloseDeleteTab}
        className="confirm-modal-container"
      >
        <SimpleConfirmModal
          title={strings.modals.contractList.deleteContract.confirmTextForAI}
          cancelBtn={strings.modals.contractList.deleteContract.no}
          confirmBtn={strings.modals.contractList.deleteContract.yes}
          confirmBtnClass={true}
          handleCloseModal={handleCloseDeleteTab}
          handleConfirm={() =>
            deleteArrayEleHandler(nameToDelete, valueToDelete)
          }
        />
      </Modal>
    </div>
  );
}
export default AIDataCreateViewComponent;
