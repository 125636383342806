import React, {useState, useContext, useEffect} from "react";
import {Collapse} from "@mui/material";
import TextFieldControl from "../../../../../Controls/Inputs/TextFieldControl";
import {FormProvider} from "react-hook-form";
import {Link} from 'react-router-dom'
import {Button} from "@mui/material";
import {useForm} from "react-hook-form";
import SnackbarContext from "../../../../../../Context/SnackbarContext";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {reorder, sortByOrder} from "../../../../../../Util/DnDUtil";
import strings from "../../../../../../localization";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {getSections} from "../../../../../../Services/AdminFakeApi";
import HistorySubSections from './HistorySubSections';
import {formatDateInText} from '../../../../../../Util/DateUtil';
import {
    getAllTemplateContent,
    restoreTemplateContent
} from "../../../../../../Services/Template/TemplateContentService";

const HistorySections = ({
                             template,
                             fetchActiveTemplateContent,
                             fetchTemplateContent
                         }) => {
    const [versions, setVersions] = useState([]);

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const {showMessage} = useContext(SnackbarContext);

    useEffect(() => {
        fetch();

        return function clean() {
            fetchActiveTemplateContent()
        }
    }, [template])

    const fetch = () => {
        getAllTemplateContent(template.id).then(response => {

            if (!response || !response.ok) {
                return;
            }
            setVersions(response.data)
        })
    }

    const handleView = (id) => {
        fetchTemplateContent(id)
    }

    const handleRestore = (id) => {
        restoreTemplateContent(template.id, id).then(response => {
            showMessage('Version restored', 'success')
            fetch()
            fetchActiveTemplateContent()
        })
    }

    const renderVersions = () => {
        let result = [];

        for (let version of versions) {

            result.push(
                <div key={'section-stage-' + result.length} className="template-page-item content-item">
                    <div className="item-header">
                        <div className="item-title">
                            <h1 className="title">Version {version.version}</h1>
                            <p className="modified-date">{strings.forms.admin.adminTemplateForm.modified}
                                <Link to="#">{formatDateInText(new Date(version.dateUpdated))}</Link>
                            </p>


                            <div className="history-btns-container">

                                <Button className="history-btn view-btn btn" variant="contained"
                                        onClick={() => handleView(version.id)}>
                                    {strings.forms.admin.adminTemplateForm.view}
                                </Button>
                                {
                                    !version.active &&
                                    <Button className="history-btn restore-btn btn" variant="contained"
                                            onClick={() => handleRestore(version.id)}>
                                        {strings.forms.admin.adminTemplateForm.restore}
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return result;
    }

    return (
        <div className="template-page-content admin-template">

            <div className="search-container">
                <FormProvider {...form}>
                    <div className='serach-item'>
                        <TextFieldControl
                            name='search'
                            control={data}
                            defaultValue=''
                            margin="normal"
                            placeholder={'Search history'}
                            icon={<img src="/images/icons/search.png"/>}
                        />
                    </div>
                </FormProvider>
            </div>
            {renderVersions()}
        </div>
    )
}

export default HistorySections;