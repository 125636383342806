import React, {useState, useEffect, useContext, useRef} from "react";
import { FormProvider, useForm } from "react-hook-form";
import strings from "../../../../../localization";
import Button from '@mui/material/Button';
import TextFieldControl from '../../../../Controls/Inputs/TextFieldControl';
import {useDispatch, useSelector} from "react-redux";
import {getCurrentUser, updateUserCompanyProfile} from "../../../../../Services/User/UserService";
import {logout} from "../../../../../Base/OAuth";
import {setAuth} from "../../../../../Slices/AuthSlice";
import SnackbarContext from "../../../../../Context/SnackbarContext";
import {Autocomplete} from "@mui/material";
import AutocompleteSelectControl from "../../../../Controls/AutocompleteSelectControl";
import AddIcon from '@mui/icons-material/Add';
import UserType from "../../../../../Constants/User/UserType";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import {getCompanyTypes} from "../../../../../Constants/User/CompanyType";
import { getSectors } from "../../../../../Constants/User/Sector";

const CompanyProfileForm = ({ handleAddInfo }) => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user)
    const form = useForm();
    const {data, watch, setValue, control, handleSubmit, formState: {errors}} = form;
    const [companies, setCompanies] = useState([]);
    const {showMessage} = useContext(SnackbarContext);
    const [openCompanySelect, setOpenCompanySelect] = useState(false)
    const dropdownRef = useRef(null);

    const onSubmit = (data) => {
        updateUserCompanyProfile({
            ...data,
            companyType: data?.companyType?.id
        }).then(response => {
            if (!response || !response.ok) {
                showMessage('Error updating company profile', 'error');
                return;
            }

            showMessage('Company profile updated');

            getCurrentUser().then(response => {
                dispatch(setAuth(response.data));
            })
        });
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {

            if(name === 'companyName') {
                handleInputChange(value.companyName)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        const handleClickOutside = (e) => {
          if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setOpenCompanySelect(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

    const handleInputChange = (term) => {

        if(!term || term.length < 2) {
            return;
        }

        setOpenCompanySelect(true);
        companyHouseSearch(term);
    }

    const companyHouseSearch = (term) => {

        fetch('https://api.company-information.service.gov.uk/search/companies?q=' + term, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': '76e6bfbb-e044-4b6b-b5f5-fd49072b21bd'
            }
        }).then(response => response.json())
            .then(data => {
                setCompanies(data ? data.items : [])
            });
    }

    const companySelected = (company) => {

        if(!company) {
            return;
        }

        setValue('companyName', company.title);
        setValue('address', company.address_snippet)
        setValue('number', company.company_number)
        setValue('companyCountry', company.address.country)
        setOpenCompanySelect(false)
    }

    const renderCompanies = () => {
        let result = []

        for(let company of companies) {
            result.push(
                <div className={'company-select-item'} onClick={() => companySelected(company)}>
                    <p>{company.title}</p>
                </div>
            )
        }

        return result
    }

    const handleOnKeyPress = (event) => {
        if(event.key.toLowerCase() === 'enter') {
            setValue('companyName', event.target.value);
            setOpenCompanySelect(false);
        }
    }

    return (
        <FormProvider {...form}>
            <h1 className="header">{strings.forms.dashboard.companyProfile.companyProfileForm.aboutCompany}</h1>
            {/* <div className="add-info-btn-container">
                <Button className="neutral-btn btn add-info-btn" onClick={handleAddInfo}>
                    <AddIcon />

                    {
                        user.userProfileType === UserType.INDIVIDUAL ? strings.forms.dashboard.myDetails.generalInformationForm.additionalInfo :
                            strings.forms.dashboard.myDetails.generalInformationForm.editCompanyInfo
                    }
                </Button>
            </div> */}
            
            <div className="dashboard-box">
                <div className="dashboard-item company-item">

                <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.dashboard.companyProfile.companyProfileForm.companyName}
                        </span>
                        <TextFieldControl
                            name='companyName'
                            control={data}
                            defaultValue={user?.company.companyName}
                            rules={{required: true}}
                            fullWidth
                            margin="normal"
                            onKeyPress={handleOnKeyPress}
                            error={Boolean(errors.companyName)}
                            helperText={errors.companyName && strings.forms.common.required}
                            placeholder={strings.forms.dashboard.companyProfile.companyProfileForm.enterCompanyName}
                        />
                        {
                            openCompanySelect && companies?.length > 0 &&
                            <div className={'company-select-container'} ref={dropdownRef}>
                                {renderCompanies()}
                            </div>
                        }
                    </div>

                    <div className="item-field-container">
                        <label>{strings.forms.dashboard.companyProfile.companyProfileForm.companyType}</label>
                        <SelectControl
                            name='companyType'
                            defaultValue={getCompanyTypes().find(x => x.name === user?.company.companyType)}
                            options={getCompanyTypes()}
                            setValue={setValue}
                            nameKey='name'
                            valueKey='id'
                            className="select-control-container"
                            error={Boolean(errors.companyType)}
                            helperText={errors.companyType && errors.companyType.message}
                        />
                    </div>

                    <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.dashboard.companyProfile.companyProfileForm.companyAddress}
                        </span>
                        <TextFieldControl
                            name='address'
                            control={data}
                            defaultValue={user?.company.address}
                            rules={{required: true}}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.address)}
                            helperText={errors.address && strings.forms.common.required}
                            placeholder={strings.forms.dashboard.companyProfile.companyProfileForm.enterCompanyAddress}

                        />
                    </div>


                    <div className="item-field-container">
                        <label>{strings.forms.dashboard.companyProfile.companyProfileForm.companySector}</label>
                        <SelectControl
                            name='companySector'
                            defaultValue={getSectors().find(x => x.name === user?.company?.sector)}
                            options={getSectors()}
                            setValue={setValue}
                            nameKey='name'
                            valueKey='id'
                            className="select-control-container"
                            error={Boolean(errors.sector)}
                            helperText={errors.sector && errors.sector.message}
                        />
                    </div>

                    <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.dashboard.companyProfile.companyProfileForm.companyWebsite}
                        </span>
                        <TextFieldControl
                            name='companyWebsite'
                            control={data}
                            defaultValue={user?.company.companyWebsite}
                            rules={{required: false}}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.companyWebsite)}
                            helperText={errors.companyWebsite && strings.forms.common.required}
                            placeholder={strings.forms.dashboard.companyProfile.companyProfileForm.enterCompanyWebsite}
                        />
                    </div>

                    <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.dashboard.companyProfile.companyProfileForm.companyNumber}
                        </span>
                        <TextFieldControl
                            name='number'
                            control={data}
                            defaultValue={user?.company.number}
                            rules={{required: true}}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.number)}
                            helperText={errors.number && strings.forms.common.required}
                            placeholder={strings.forms.dashboard.companyProfile.companyProfileForm.enterCompanyNumber}
                        />
                    </div>

                    <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.dashboard.companyProfile.companyProfileForm.countryOfIncorporation}
                        </span>
                        <TextFieldControl
                            name='companyCountry'
                            control={data}
                            defaultValue={user?.company.companyCountry}
                            rules={{required: true}}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.companyCountry)}
                            helperText={errors.companyCountry && strings.forms.common.required}
                            placeholder={strings.forms.dashboard.companyProfile.companyProfileForm.enterCountryOfIncorporation}
                        />
                    </div>

                    <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.dashboard.companyProfile.companyProfileForm.vatNumber}
                        </span>
                        <TextFieldControl
                            name='companyVAT'
                            control={data}
                            defaultValue={user?.company.companyVAT}
                            rules={{required: false}}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.companyVAT)}
                            helperText={errors.companyVAT && strings.forms.common.required}
                            placeholder={strings.forms.dashboard.companyProfile.companyProfileForm.enterVatNumber}
                        />
                    </div>
                </div>
            </div>

            <div className="submit-container">
                <Button className="secondary-btn btn" onClick={handleSubmit(onSubmit)}>{strings.forms.dashboard.companyProfile.companyProfileForm.save}</Button>
            </div>
        </FormProvider>
    )
}

export default CompanyProfileForm;