import React from 'react';
import {useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import strings from '../../localization';

const AdminAllTemplates = (props) => {

    const menuState = useSelector((state) => state.navigation.menuState)
    const pageSizeState = useSelector((state) => state.page.pageSizeState)
    const location = useLocation();

    const isCurrentPath = (path) => {
        return location.pathname === path;
    }

    return (
        <div id="admin-dashboard" className="admin-dashboard-container">
            <Link to={'/admin-template-management-categories'} className={ isCurrentPath('/admin-categories') ? 'admin-dashboard-part' : 'admin-dashboard-part'} >
                <p>{strings.pages.adminAllTemplates.templateMenagement}</p>
            </Link>

            {/*<Link to={'/admin-questionnaire-to-users'} className={ isCurrentPath('/admin-questionnaire-to-users') ? 'admin-dashboard-part' : 'admin-dashboard-part'} >*/}
            {/*    <p>{strings.pages.adminAllTemplates.createPublishQuestionnaire}</p>*/}
            {/*</Link>*/}

            {/*<Link to={'/admin-manage-content'} className={ isCurrentPath('/admin-manage-content') ? 'admin-dashboard-part' : 'admin-dashboard-part'} >*/}
            {/*    <p>{strings.pages.adminAllTemplates.manageContent}</p>*/}
            {/*</Link>*/}

            {/*<Link to={'/admin-manage-admin'} className={ isCurrentPath('/admin-manage-admin') ? 'admin-dashboard-part' : 'admin-dashboard-part'} >*/}
            {/*    <p>{strings.pages.adminAllTemplates.manageAdmin}</p>*/}
            {/*</Link>*/}
        </div>
    )
};

export default AdminAllTemplates;
