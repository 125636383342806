import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuState from "../../Constants/Base/MenuState";
import styles from "./DealRoom.module.css";
import {
  subscribe,
  getAllPackages,
} from "../../Services/DealRoom/DealRoomService";
import HeaderButtons from "../../Components/Layout/HeaderButtons";
import SnackbarContext from "../../Context/SnackbarContext";

const DealRoomPaymentPage = () => {
  const inputRef = useRef(null);
  const { showMessage } = useContext(SnackbarContext);
  const { id } = useParams();
  const navigation = useNavigate();
  const location = useLocation();

  const { extendSubscription = false } =
    location?.state !== null && location?.state;
  const [packages, setPackages] = useState();
  const menuState = useSelector((state) => state.navigation.menuState);
  const [selectedOption, setSelectedOption] = useState();
  const [isOpenChoiceBox, setisOpenChoiceBox] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({
    card: "",
    expiryDate: "",
    cvc: "",
    name: "",
  });

  useEffect(() => {
    const getPackages = () => {
      getAllPackages(id)
        .then((res) => {
          if (res.status === 200) {
            res.data.map((value, index) => {
              if (extendSubscription) {
                if (value.intervalCountInMonths === 1) {
                  setSelectedOption(value);
                }
              } else {
                if (value.intervalCountInMonths === 3) {
                  setSelectedOption(value);
                }
              }
            });
            const packages = !extendSubscription
              ? res.data.filter((item) => item.intervalCountInMonths !== 1)
              : res.data;
            setPackages(packages);
          } else {
            showMessage("Accessing package Failed", "error");
          }
        })
        .catch((err) => {
          console.log("error in getting Packages", err);
        });
    };
    getPackages();
  }, []);

  const splitExpiryDate = (expiryDate) => {
    const [month, year] = expiryDate.split("/");
    return { month, year };
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPaymentDetails({
      ...paymentDetails,
      [name]: value,
    });
  };

  const handleCardDataInputChange = (event) => {
    const { name, value } = event.target;
    const newValue = value.replace(/\D/g, "");

    if (name === "card") {
      const formattedValue = newValue
        .match(/.{1,4}/g)
        ?.join(" ")
        .substr(0, 19);

      setPaymentDetails({
        ...paymentDetails,
        [name]: formattedValue,
      });
    } else if (name === "expiryDate") {
      let formattedValue = newValue;

      if (newValue.length > 2) {
        formattedValue = `${newValue.slice(0, 2)}/${newValue.slice(2, 4)}`;
      }

      setPaymentDetails({
        ...paymentDetails,
        [name]: formattedValue,
      });
    } else if (name === "cvc") {
      let formattedValue = newValue.substr(0, 3);

      setPaymentDetails({
        ...paymentDetails,
        [name]: formattedValue,
      });
    }
  };

  const handleChange = (event) => {
    packages.map((value, index) => {
      if (value.intervalCountInMonths == event.target.value) {
        setSelectedOption(value);
      }
      return "";
    });
  };

  const selectChoiceHandler = (value) => {
    packages.map((value1, index) => {
      if (
        value1.intervalCountInMonths == parseInt(value?.intervalCountInMonths)
      ) {
        setSelectedOption(value);
      }
      return "";
    });
  };

  const handlePaymentClick = () => {
    const { month, year } = splitExpiryDate(paymentDetails.expiryDate);
    const cardNumberWithoutSpaces = paymentDetails.card.replace(/\s/g, "");
    const expYear = parseInt(year, 10);

    const currentYearLastTwoDigits = new Date()
      .getFullYear()
      .toString()
      .slice(-2);

    const currentMonthLastTwoDigits = (new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")
      .slice(-2);

    if (cardNumberWithoutSpaces.length !== 16) {
      showMessage("Invalid card number", "error");
      return;
    }

    if (expYear === parseInt(currentYearLastTwoDigits, 10)) {
      if (parseInt(month, 10) <= parseInt(currentMonthLastTwoDigits, 10)) {
        showMessage("Invalid expiry month", "error");
        return;
      }
    }

    if (expYear < parseInt(currentYearLastTwoDigits, 10)) {
      showMessage("Invalid Expiry year", "error");
      return;
    }

    if (paymentDetails.cvc.length !== 3) {
      showMessage("Invalid CVC", "error");
      return;
    }

    const data = {
      card: cardNumberWithoutSpaces,
      expMonth: parseInt(month, 10),
      expYear: expYear,
      cvc: paymentDetails.cvc,
      interval: extendSubscription ? 1 : selectedOption.intervalCountInMonths,
    };

    subscribe(id, data)
      .then((res) => {
        if (res.status === 204) {
          navigation(`/virtualDealRoom/dashboard/overview/${id}`);
          showMessage("Payment Success", "success");
        } else {
          showMessage("Payment Failed", "error");
        }
      })
      .catch((err) => {
        console.log("error in subscribing", err);
      });
  };

  const handleMouseLeave = () => {
    inputRef?.current?.blur();
    setisOpenChoiceBox({ ...isOpenChoiceBox, months: false });
  };

  const getHeaderClass = (menuState) => {
    if (menuState === MenuState.SHORT) {
      return "short";
    }

    return "";
  };

  return (
    <div className={styles.dealRoom}>
      <div id="header" className={getHeaderClass(menuState)}>
        <div>{/* <h1>{strings.pages.contractList.title}</h1> */}</div>
        <HeaderButtons />
      </div>
      <div className={styles.paymentformContainer}>
        <div
          className={styles.payPageHeading}
          style={{ textAlign: "center", fontSize: "30px" }}
        >
          <span>
            Start Strong and Close Stronger with Our Virtual Deal Room
          </span>
        </div>
        <div className={styles.subPaymentformContainer}>
          <div className={styles.paySubHeadingBox}>
            <span className={styles.paySubHeading}> Order Summary</span>
          </div>
          <div className={styles.payItemsBox}>
            <span>Deal Room</span>
            <span>£ {selectedOption?.price}</span>
          </div>
          <div className={styles.payItemsBox}>
            <span>VAT</span>
            <span>£ {selectedOption?.vat}</span>
          </div>
          <div className={styles.payItemsBox}>
            <span style={{ fontWeight: "600" }}>ORDER TOTAL</span>
            <span style={{ fontWeight: "600" }}>
              £ {selectedOption?.price + selectedOption?.vat}
            </span>
          </div>

          {!extendSubscription && (
            <div className={styles.filledBox} onMouseLeave={handleMouseLeave}>
              <div className={styles.labelText}>
                <span>Subscription Length</span>
              </div>
              <div
                className={styles.inputContainer}
                style={{ position: "relative" }}
              >
                <input
                  readOnly
                  ref={inputRef}
                  value={selectedOption?.intervalCountInMonths + " Months"}
                  name="months"
                  // onChange={(e) => handleChange(e)}
                  className={styles.inputBox}
                  type="text"
                  onFocus={() =>
                    setisOpenChoiceBox({ ...isOpenChoiceBox, months: true })
                  }
                  placeholder="Please Select Months"
                  style={{
                    WebkitAppearance: "none",
                    background: `url(/images/icons/downArrow.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right center",
                  }}
                />
                {isOpenChoiceBox?.months && (
                  <div className={styles.options}>
                    <ul onMouseLeave={handleMouseLeave}>
                      {packages?.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => selectChoiceHandler(item)}
                          >
                            {item.intervalCountInMonths + "Months"}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={styles.filledBox}>
            <div className={styles.labelText}>
              <span>Full Name</span>
            </div>
            <div className={styles.inputContainer}>
              <input
                className={styles.inputBox}
                name="name"
                value={paymentDetails.name}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter Full Name"
              />
            </div>
          </div>
          <div className={styles.filledBox}>
            <div className={styles.labelText}>
              <span>Card Payment Detail</span>
            </div>
            <div className={styles.inputContainer}>
              <img
                style={{ width: "5%" }}
                src={"/images/icons/payCardIcon.png"}
                alt="payCardIcon"
              />
              <input
                style={{ width: "55%" }}
                className={styles.inputBox}
                name="card"
                value={paymentDetails.card}
                onChange={handleCardDataInputChange}
                type="text"
                placeholder="XXXX XXXX XXXX XXXX"
              />
              <input
                style={{ width: "25%" }}
                className={styles.inputBox}
                name="expiryDate"
                value={paymentDetails.expiryDate}
                onChange={handleCardDataInputChange}
                type="text"
                placeholder="MM/YY"
              />
              <input
                style={{ width: "15%" }}
                className={styles.inputBox}
                name="cvc"
                value={paymentDetails.cvc}
                onChange={handleCardDataInputChange}
                type="text"
                placeholder="CVC"
              />
            </div>
          </div>
          <div className={styles.payButtonRow}>
            <div
              className={styles.addButton}
              style={{ width: "100px" }}
              onClick={() => handlePaymentClick()}
            >
              <span>Pay</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealRoomPaymentPage;
