import strings from "../../localization";

const Category = {
    ALL: -1,
    ACTIVE: 1,
    DEACTIVATED: 2
};

export function getAdminCategoryFilter() {
    return [
        {
            id: Category.ALL,
            name: getCategoryString(Category.ALL)
        },
        {
            id: Category.ACTIVE,
            name: getCategoryString(Category.ACTIVE)
        },
        {
            id: Category.DEACTIVATED,
            name: getCategoryString(Category.DEACTIVATED)
        }
    ]
}

export function adminCategoryDefaultValue() {
    return {
        id: Category.ALL,
        name: getCategoryString(Category.ALL)
    }
}

export function getCategoryString(category) {
    switch(category) {
        case Category.ALL: return strings.constants.admin.adminCategory.all;
        case Category.ACTIVE: return strings.constants.admin.adminCategory.active;
        case Category.DEACTIVATED: return strings.constants.admin.adminCategory.deactivated;
    }
}

export default Category;