import React from "react";
import {ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import strings from "../../localization";
import IconButton from '@mui/material/IconButton';

const ProfileMenu = ({
                    profileMenuAnchorEl, 
                    fullName, 
                    email, 
                    userType, 
                    handleLogout, 
                    handleMyProfile, 
                    closeProfileMenu
                }) => {
    return (
        <Menu
            id='person-menu'
            anchorEl={profileMenuAnchorEl}
            open={Boolean(profileMenuAnchorEl)}
            onClose={() => closeProfileMenu()}
            className="header-popup-menu profile-menu"
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 19,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <div className="user-info">
                <span className="name">{fullName}</span>
                <span className="email">{email}</span>
                <span className="user-type">{userType}</span>
            </div>

            <MenuItem className="link" onClick={() => handleMyProfile()}>
                <ListItemIcon>
                    <IconButton><img src="/images/icons/profile2.png" /></IconButton>
                </ListItemIcon>
                <ListItemText className="link-text" inset primary={strings.header.myProfile}/>
            </MenuItem>
            <MenuItem className="link logout" onClick={() => handleLogout()}>
                <ListItemIcon>
                    <IconButton><img src="/images/icons/menu-logout.png" /></IconButton>
                </ListItemIcon>
                <ListItemText className="link-text" inset primary={strings.header.logout}/>
            </MenuItem>
        </Menu>
    )
}

export default ProfileMenu;