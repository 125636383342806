import React, {useState, useEffect} from "react";
import strings from "../../../localization";
import CloseIcon from '@mui/icons-material/Close';
import  AutocompleteSelectControl from '../../../Components/Controls/AutocompleteSelectControl';
import {getAllTemplates} from '../../../Services/Template/TemplateService';
import {getTemplatesByPackage} from '../../../Services/ContractCategory/PackageService';

const Contract = ({ 
                    name, 
                    pack, 
                    setTemplatesIds, 
                    templatesIds,
                    editIcon
                }) => {

    const [otherContracts, setOtherContracts] = useState([])
    const [contractsFullObjList, setContractsFullObjList] = useState([])

    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {

        let all = [];
        let forPack = [];
        
        getAllTemplates({}).then(response => {

            if(!response || !response.ok) {
                return;
            }

            if (response.data.length > 0) {
                all = response.data
            }

            getTemplatesByPackage(pack.id).then(response => {
                if(!response || !response.ok) {
                    return;
                }
                
                if (response.data.length > 0) {

                    forPack = response.data.map(e => e.template)
                    
                    let other = all.filter(n => {
                        if (forPack.find(e => e.id == n.id)) {
                            return
                        } else {
                            return n
                        }
                    })
                    
                    setContractsFullObjList(forPack)
                    setOtherContracts(other)

                    let contractsIds = []

                    for (let i of forPack) {
                        contractsIds.push(i.id)
                    }

                    setTemplatesIds(contractsIds)
                } else {
                    setOtherContracts(all)
                }
            })
        })
    }

    const handleCloseTag = (contract) => {

        setContractsFullObjList(contractsFullObjList.filter(e => e.id !== contract.id))

        setOtherContracts([
            ...otherContracts,
            contract
        ])

        let idListUpdate = templatesIds.filter(e => e !== contract.id)
        setTemplatesIds(idListUpdate)
    }

    const getDataItem = () => {
        let result = [];

        if (contractsFullObjList.length > 0) {
            for (let item of contractsFullObjList) {

                result.push(
                    <div className={editIcon ? "tag" : "tag disabled"} key={item.id}>
                        <span className="tag-text">{item.name}</span>
                        
                        {editIcon &&
                            <CloseIcon className="close-icon" onClick={() => handleCloseTag(item)} />
                        }
                    </div>
                )
            }
        } else {
            return []
        }
        return result
    }

    const handleInputChange = (contract) => {

        setContractsFullObjList([
            ...contractsFullObjList,
            contract
        ])
        
        setOtherContracts(otherContracts.filter(e => e.id !== contract.id))

        setTemplatesIds([
            ...templatesIds,
            contract.id
        ])
    }

    return (
        <div className="item drop">
            <div className="item-title">
                <span>{name}</span>
            </div>
            
            {
                editIcon &&
                <AutocompleteSelectControl 
                    options={otherContracts}
                    handleInputChange={handleInputChange}
                    placeholder={strings.pages.adminPackages.searchContracts}
                />
            }

            <div className="tag-list">
                {contractsFullObjList.length > 0 && getDataItem()}
            </div>
        </div>
    )
}

export default Contract;