import React, {useState, useEffect, useContext} from "react";
import strings from "../../../localization";
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import ConfirmModal from '../../../Components/Modals/ConfirmModal';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {reorder, sortByOrder} from "../../../Util/DnDUtil";
import {deleteTemplate, reorderTemplates, changeActiveTemplate} from "../../../Services/Template/TemplateService";
import {useForm} from "react-hook-form";
import SnackbarContext from "../../../Context/SnackbarContext";
import {useNavigate} from "react-router-dom";
import {changeActivePackage} from "../../../Services/ContractCategory/PackageService";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {ListItemText, Menu, MenuItem} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const Template = (props) => {
    
    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const { showMessage } = useContext(SnackbarContext);
    const navigate = useNavigate();
    const [activateMenuAnchorEl, setActivateMenuAnchorEl] = useState(null)
    const [editTemplateTitle, setEditTemplateTitle] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [templates, setTemplates] = useState([]);
    const [templateStages, setTemplateStages] = useState([]);
    const [templateToEdit, setTemplateToEdit] = useState(-1);

    useEffect(() => {
        setTemplates(props.templates);
    }, [])

    useEffect(() => {
        setTemplates(props.templates);
    }, [props.templates])

    const handleEditTemplateTitle = (template) => {
        setEditTemplateTitle(true)
    }

    const handleActivateClick = event => {
        setActivateMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setActivateMenuAnchorEl(null);
    };

    const handleOpenModal = (template) => {
        setOpenModal(true)
        setSelectedTemplate(template.id)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const editTemplate = (data) => {

        // editTemplate({
        //     id: templateToEdit,
        //     name: data.name
        // }).then(response => {
        //
        //     if(!response || !response.ok) {
        //         return;
        //     }
        //     setTemplateToEdit(-1);
        //     showMessage(strings.common.edited)
        //     props.fetch();
        // })
    }

    const handleConfirm = template => {

        changeActiveTemplate({
            id: template.id,
            active: !template.active
        }).then(response => {

            if(!response || !response.ok) {
                showMessage(strings.common.saved);
                return;
            }

            props.fetch();
            setActivateMenuAnchorEl(null);
            setOpenModal(false)
        })

        if (template.active == true) {
            showMessage(strings.common.deactivated)
        } else {
            showMessage(strings.common.activated)
        }
    }

    const onDragEnd = (result) => {

        if(!result.source || !result.destination) {
            return;
        }

        const temps = reorder(templates, result.source.index, result.destination.index);

        const tempsModified = temps.map((e, index) => {
            e.rowOrder = index
            return e
        })

        setTemplates(sortByOrder(tempsModified));

        reorderTemplates(tempsModified).then(response => {
            if(!response || !response.ok) {
                return 
            }
        })
    }

    const deleteTemplateClick = (template) => {

        deleteTemplate(template.id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            setActivateMenuAnchorEl(null);
            props.fetch();
        })
    }

    const renderTemplates = () => {
        let result = [];

        for (let template of templates) {

            result.push(
                <Draggable
                    key={template.id}
                    draggableId={'stage-' + template.id}
                    index={result.length}
                >

                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >

                        <div key={'template-stage-' + result.length} className="tab-page-item content-item">
                            <div className="item-header">
                                <div className="item-title">
                                    {
                                        templateToEdit !== template.id &&
                                        <>
                                            {
                                                template.active &&
                                                <React.Fragment>
                                                    <h1 className="title">{template.name}</h1>

                                                    <Tooltip 
                                                        title={strings.pages.adminTemplates.editTemplate}
                                                        placement="top" 
                                                        arrow>
                                                        <IconButton className="item-title-icon" onClick={() => navigate("/add-admin-template",
                                                            { state: { id: template.id}}
                                                        )}>
                                                            <img src="/images/icons/pen.png" />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip 
                                                        title={strings.pages.adminTemplates.deactivateTemplate}
                                                        placement="top" 
                                                        arrow>
                                                        <IconButton className="item-title-icon delete-deactivate-icon" onClick={() => handleOpenModal(template)}>
                                                            <img src="/images/icons/bag.png" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </React.Fragment>
                                            }
                                            {
                                                !template.active &&
                                                <div className='deactivated-state'>
                                                    <h1 className="title">{template.name}</h1>
                                                    <React.Fragment>
                                                        <MoreVertIcon className="toggle-icon" onClick={(event) => handleActivateClick(event)} />

                                                        <Menu
                                                            id='activate-menu'
                                                            className="activate-menu"
                                                            anchorEl={activateMenuAnchorEl}
                                                            open={ Boolean(activateMenuAnchorEl) }
                                                            onClose={() => handleClose()}
                                                        >
                                                            <MenuItem className="activate-menu-list">
                                                                <ListItemText className="item" inset primary={strings.pages.adminPackages.activate} onClick={() => handleConfirm(template)}/>
                                                                <ListItemText className="item" inset primary={strings.pages.adminPackages.delete} onClick={() => deleteTemplateClick(template)}/>
                                                            </MenuItem>
                                                        </Menu>
                                                    </React.Fragment>
                                                </div>
                                                
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                        { openModal && selectedTemplate === template.id &&
                            <Modal
                                open={openModal}
                                onClose={handleCloseModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                className='confirm-modal-container'
                            >
                                <ConfirmModal
                                    title={strings.modals.adminTemplates.title}
                                    contentText={strings.modals.adminTemplates.confirmTextTemplate}
                                    cancelBtn={strings.modals.adminTemplates.cancelBtn}
                                    confirmBtn={strings.modals.adminTemplates.deleteBtn}
                                    handleCloseModal={handleCloseModal}
                                    handleConfirm={() => handleConfirm(template)}
                                />
                            </Modal>
                        }

                    </div>
                    )}
                </Draggable>
            )
        }
        return result;
    }

    return (
        <div id="admin-tab-content" className="tab-page-content admin-template">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable  droppableId='droppable-container' direction="vertical">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {renderTemplates()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>      
        </div>
    )
}

export default Template;