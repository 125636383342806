import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Controller } from 'react-hook-form';

const CheckBoxControl = ({value, setValue, name, label, control, defaultValue, disabled}) => {
    const [checked, setChecked] = useState(value);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setValue(name, event.target.checked)
      };

    return <Controller
                name={name}
                defaultValue={defaultValue}
                control={control}
                render={({ field: props }) => (
                    <FormControlLabel
                        label={label}
                        className='checkbox-control'
                        control={
                            <Checkbox
                                checked={props.value}
                                {...props}
                                disabled={disabled}
                                onChange={(e) => props.onChange(e.target.checked)}
                            />
                        }
                    />
                )}
            />
}

export default CheckBoxControl;