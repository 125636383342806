import React, { useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "@mui/material";
import strings from "../../../../localization";
import FileUploadComponent from "../../../Controls/FileUploadComponent";
import CloseIcon from "@mui/icons-material/Close";
import { toBase64 } from "../../../../Util/ImageUtil";
import TextField from "@mui/material/TextField";
import {
  uploadContract,
  uploadWordFile,
} from "../../../../Services/Company/CompanyService";
import LoaderContext from "../../../../Context/LoaderContext";
import {
  handleCovertFromHTMLtoPDF,
  handleCovertFromWordToHtml,
} from "../../../../Util/CKEditorUtil";
import { useNavigate } from "react-router-dom";
import { PDFDocument } from "pdf-lib";
import SnackbarContext from "../../../../Context/SnackbarContext";

const folderDefault = {
  id: -1,
  name: strings.forms.contractList.documentForm.selectFolder,
};

const DocumentForm = (props) => {
  const uploadButtonText = strings.forms.contractList.upload;
  const form = useForm();
  const {
    data,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [files, setFiles] = useState([]);
  const { showLoader, setLoaderTitle } = useContext(LoaderContext);
  const navigate = useNavigate();
  const { showMessage } = useContext(SnackbarContext);

  const uploadFile = (newFiles) => {
    const duplicateFiles = newFiles.filter((newFile) =>
      files.some((existingFile) => existingFile.name === newFile.name)
    );

    if (duplicateFiles.length > 0) {
      showMessage("Duplicate files are not allowed", "error");
      return;
    }
    setFiles((prevFiles) => [...newFiles, ...prevFiles]);

    setDisabledSubmit([...newFiles, ...files].length === 0);
  };

  const renderFiles = (files) => {
    return files.map((file) => (
      <div className="file-name" key={file.name}>
        <TextField
          name="name"
          control={data}
          defaultValue={file.newName ? file.newName : file.name}
          rules={{ required: true }}
          margin="normal"
          className={"textfield-control"}
          onChange={(e) => {
            const index = files.indexOf(file);
            if (index < 0) {
              return;
            }

            let changedFiles = files.slice();
            changedFiles[index].newName = e.target.value;

            setFiles(changedFiles);
          }}
          fullWidth
          placeholder={"Name"}
        />
        <CloseIcon className="close-icon" onClick={() => removeFile(file)} />
      </div>
    ));
  };

  const removeFile = (file) => {
    const updatedFiles = files.filter((x) => x.name !== file.name);
    setFiles(updatedFiles);
    setDisabledSubmit(updatedFiles.length === 0);
  };

  const combinePDFs = async (pdfFiles) => {
    const mergedPdf = await PDFDocument.create();

    for (const pdfFile of pdfFiles) {
      const pdfBytes = await pdfFile.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices()
      );
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }
    return mergedPdf;
  };

  const combineAndSavePDFs = async (files) => {
    const hasPDFFiles = files.some((file) =>
      file.name.toLowerCase().endsWith(".pdf")
    );
    let formDataforMergedFiles = new FormData();

    if (hasPDFFiles && files.length === 1) {
      formDataforMergedFiles.append("files", files[0], props.docName + ".pdf");
      formDataforMergedFiles.append(
        "folderId",
        props.selectedFolder ? props.selectedFolder.id : -1
      );

      await uploadContract(formDataforMergedFiles).then((response) => {
        props.handleConfirm(response);
        showLoader(false);
      });
    } else {
      const pdfDoc = await combinePDFs(files);
      const mergedPdfBytes = await pdfDoc.save();
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });
      const mergedPdfFile = new File([mergedPdfBlob], props.docName + ".pdf", {
        type: "application/pdf",
      });

      formDataforMergedFiles.append("files", mergedPdfFile);
      formDataforMergedFiles.append(
        "folderId",
        props.selectedFolder ? props.selectedFolder.id : -1
      );

      await uploadContract(formDataforMergedFiles).then((response) => {
        props.handleConfirm(response);
        showLoader(false);
      });
    }
  };

  const onSubmit = async (data) => {
    if (!files) {
      return;
    }

    let formData = new FormData();

    let items = [];
    let eSignatureFile = [];
    setLoaderTitle("Please wait while your documents upload");
    showLoader(true);

    await Promise.all(
      files.map(async (file) => {
        if (file.name.includes(".docx") || file.name.includes(".doc")) {
          let result = await handleCovertFromWordToHtml(file);
          let resultPdf = await handleCovertFromHTMLtoPDF(
            result.html,
            file.name
          );
          console.log("resultPdf", resultPdf)
          const pdfbyte = await resultPdf.blob();
          const pdfBlob = new Blob([pdfbyte], { type: "application/pdf" });

          //For E-signature file
          const byteToPdf = new File(
            [pdfBlob],
            file.newName
              ? file.newName.replace(/\.[^/.]+$/, ".pdf")
              : file.name.replace(/\.[^/.]+$/, ".pdf"),
            { type: "application/pdf" }
          );

          // let applicationFile = new File(pdfBlob, file.newName ? file.newName.replace(/\.[^/.]+$/, '.pdf') : file.name.replace(/\.[^/.]+$/, '.pdf'));
          // // applicationFile.append('name', file?.newName ? file.newName : file.name.includes('.docx') ? file.name.replace('.docx', '') : file.name.replace('.pdf', ''));
          // // applicationFile.append('folderId', props.selectedFolder ? props.selectedFolder.id : -1);
          // // applicationFile.append('file', file);
          // // applicationFile.append('pdfContent', await toBase64(pdfBlob));

          // items.push({
          //   name: file.newName
          //     ? file.newName.replace(/\.[^/.]+$/, ".pdf")
          //     : file.name.replace(/\.[^/.]+$/, ".pdf"),
          //   content: await toBase64(pdfBlob),
          // });

          formData.append("files", byteToPdf);
          formData.append(
            "folderId",
            props.selectedFolder ? props.selectedFolder.id : -1
          );
          console.log("formData", formData);
          //For E-signature file
          eSignatureFile.push(byteToPdf);

          showLoader(false);
        } else {
          formData.append("files", file);
          formData.append(
            "folderId",
            props.selectedFolder ? props.selectedFolder.id : -1
          );
          //For E-signature file
          eSignatureFile.push(file);
        }
      })
    );

    //For E-signature file
    if (props.mergedDocument === true) {
      setLoaderTitle("Please wait while your documents upload");
      showLoader(true);
      await combineAndSavePDFs(eSignatureFile);
    } else {
      if (files?.length > 0) {
        await uploadContract(formData).then((response) => {
          props.handleConfirm(data);
          showLoader(false);
        });
      }
    }
  };

  return (
    <FormProvider {...form}>
      <div id="" className="confirm-modal document-upload-form">
        {!(props && (props.docName === "" || props.docName)) && (
          <h1 className="title">
            {strings.forms.contractList.documentForm.title}
          </h1>
        )}

        <div className="file-upload-container">
          <FileUploadComponent
            accept=".pdf, .docx, .doc"
            hideUploadButton={true}
            onDrop={uploadFile}
          />
          <div className="document-list">{renderFiles(files)}</div>
        </div>

        <div className="buttons-container">
          {!(props && (props.docName === "" || props.docName)) && (
            <Button
              className="neutral-btn btn cancel-btn"
              variant="contained"
              onClick={props.handleCloseModal}
            >
              {strings.forms.contractList.cancel}
            </Button>
          )}
          <Button
            className="default-btn btn"
            variant="contained"
            disabled={disabledSubmit || (props && props.docName === "")}
            onClick={handleSubmit(onSubmit)}
          >
            {props.buttonText || strings.forms.contractList.upload}
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default DocumentForm;
