import React, {useEffect, useState} from "react";
import {getPackageByContractCategory} from "../../../Services/ContractCategory/PackageService";
import {
    getTemplatesByContractCategory,
    reorderCategoryTemplates
} from "../../../Services/ContractCategory/TemplateContractCategoryService";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {reorder, sortByOrder} from "../../../Util/DnDUtil";
import {reorderTemplates} from "../../../Services/Template/TemplateService";

const Contract = (props) => {

    const [templates, setTemplates] = useState([]);

    useEffect(() => {

        fetchTemplates();

    }, [])

    const fetchTemplates = () => {
        getTemplatesByContractCategory(props.category.id).then(response => {
            if(!response || !response.ok) {
                return;
            }

            setTemplates(response.data);
        });
    }

    const getDataItem = () => {
        let result = [];

        for (let item of templates) {
            result.push(
                <Draggable
                    key={item.id}
                    draggableId={'template-' + item.id}
                    index={result.length}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <div className="row" key={item.id}>
                                <p className="row-text">{item.template.name}</p>
                            </div>
                        </div>
                    )}
                </Draggable>
            )
        }

        return result
    }

    const onDragEnd = (result) => {

        if(!result.source || !result.destination) {
            return;
        }

        const temps = reorder(templates, result.source.index, result.destination.index);

        const tempsModified = temps.map((e, index) => {
            e.rowOrder = index
            return e
        })

        setTemplates(sortByOrder(tempsModified));

        reorderCategoryTemplates(props.category.id, sortByOrder(tempsModified)).then(response => {
            if(!response || !response.ok) {
                return
            }
        })
    }

    return (
        <div className="subitem subitem-2">
            <div className="header">
                <h1>{props.name}</h1>
            </div>
            <div className="content">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable  droppableId='droppable-container' direction="vertical">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                { getDataItem()}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    )
}

export default Contract;