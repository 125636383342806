import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import styles from "../../../../../Pages/dealRoom/DealRoom.module.css";
import { connectLegalSupport } from "../../../../../Services/DealRoom/DealRoomService";
import SnackbarContext from "../../../../../Context/SnackbarContext";
import isFormValid from "../../../../../Pages/dealRoom/DealRoomDashboard/ConnectTab/Validation";

const SupportForm = ({ setPageName }) => {
  const { id } = useParams();
  const { showMessage } = useContext(SnackbarContext);
  const [formData, setFormData] = useState({
    fullName: "",
    mobile: "",
    query: "",
  });
  const [formErrors, setFormErrors] = useState({
    fullName: "",
    mobile: "",
    query: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      if (/^\d*$/.test(value) && value.length <= 10) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setFormErrors((prevState) => ({
          ...prevState,
          [name]: "",
        }));
      }
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleSubmit = () => {
    const { isValid, errors } = isFormValid(formData);
    if (isValid) {
      connectLegalSupport(id, formData)
        .then((res) => {
          if (res.status === 204) {
            setPageName("requestMsgPage");
          } else {
            showMessage("Error in Connecting Legal Support", "error");
          }
        })
        .catch((err) => {
          console.log("err111", err.response);
        });
    } else {
      setFormErrors(errors);
    }
  };


  return (
    <div className={styles.dealRoom}>
      <div className={styles.filledBox}>
        <div className={styles.labelText}>
          <span>Full Name*</span>
        </div>
        <div className={styles.inputContainer}>
          <input
            className={styles.inputBox}
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            type="text"
            placeholder="Enter Full Name"
          />
        </div>
        {formErrors.fullName && (
          <span className={styles.errorMessage}>{formErrors.fullName}</span>
        )}
      </div>
      <div className={styles.filledBox}>
        <div className={styles.labelText}>
          <span>Mobile Number*</span>
        </div>
        <div className={styles.inputContainer}>
          <input
            className={styles.inputBox}
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            type="text"
            placeholder="Enter Mobile Number"
          />
        </div>
        {formErrors.mobile && (
          <span className={styles.errorMessage}>{formErrors.mobile}</span>
        )}
      </div>
      <div className={styles.filledBox} style={{ height: "300px" }}>
        <div className={styles.labelText}>
          <span>What Legal assistance do you require?*</span>
        </div>
        <div className={styles.inputContainer}>
          <textarea
            style={{ height: "180px", padding: "25px 20px" }}
            className={styles.inputBox}
            name="query"
            value={formData.query}
            onChange={handleChange}
            type="text"
            placeholder="Enter Your Query"
          ></textarea>
        </div>
        {formErrors.query && (
          <span className={styles.errorMessage}>{formErrors.query}</span>
        )}
      </div>

      <div className={styles.payButtonRow}>
        <div
          className={styles.addButton}
          style={{ borderRadius: "7px" }}
          onClick={() => handleSubmit()}
        >
          <span>Submit</span>
        </div>
      </div>
    </div>
  );
};

export default SupportForm;
