import React, { useEffect, useState } from 'react';
import MenuState from "../Constants/Base/MenuState";
import Navigation from "../Components/Layout/Navigation";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";
import { useSelector } from "react-redux";
import PageSizeState from "../Constants/Base/PageSizeState";
import { Link, matchPath, useLocation, useSearchParams } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import SnackbarContext from "../Context/SnackbarContext";
import { getContract } from '../Services/Company/CompanyService';
import ContractStatus from '../Constants/Contract/ContractStatus';

const getMainContainerClass = (isCurrentPath, state, pageSizeState, user) => {
    const isAuthPath = isCurrentPath('/login')
        || isCurrentPath('/registration') || isCurrentPath('/registration-details');

    if (pageSizeState !== PageSizeState.SHORT) {
        return isAuthPath ? 'full white' : 'full';
    }

    if (state === 1) {
        return isAuthPath ? 'main-container short-container white' : 'main-container short-container';
    } else if (state === 2) {
        return isAuthPath ? 'main-container wide-container white' : 'main-container wide-container'
    }
}

const BaseLayout = (props) => {

    const menuState = useSelector((state) => state.navigation.menuState)
    const pageSizeState = useSelector((state) => state.page.pageSizeState)
    const location = useLocation();
    const user = useSelector((state) => state.auth.user)
    const [searchParams, setSearchParams] = useSearchParams();

    const getContentClass = (state, pageSizeState) => {

        if (pageSizeState === PageSizeState.FULL) {

            if (isCurrentPath('/login')
                || isCurrentPath('/registration')
                || isCurrentPath('/registration-details')
                || isCurrentPath('/user-type')
                || isCurrentPath('/individual-details')
                || isCurrentPath('/company-details')
                || isCurrentPath('/lock')
                || isCurrentPath('/reset-password')
                || isCurrentPath('/reset-password-request')
                || isCurrentPath('/reset-password-request-expired')
                || isCurrentPath('/reset-password-success')) {
                return 'content-container short auth-pages';
            }

            return 'content-container short';
        }

        if (state === MenuState.SHORT) {


            if (isCurrentPath('/contract-preview') || isCurrentPath('/add-contract') || isCurrentPath('/user-list')) {
                return 'content-container short hide-header'
            } else {
                return 'content-container short';
            }
        }

        if (isCurrentPath('/contract-preview') || isCurrentPath('/add-contract') || isCurrentPath('/user-list')) {
            return 'content-container hide-header'
        } else {
            return 'content-container';
        }
    }

    const isCurrentPath = (path) => {
        return location.pathname === path;
    }

    const isCurrentPathMatch = (path) => {
        if (location.pathname.includes(path)) {
            return true;
        }
        return false;
    }

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false);
    };

    const showMessage = (message, alertType = 'success') => {
        setAlertType(alertType)
        setMessageSnackbar(message)
        setShowSnackbar(true);
    }

    const value = { showMessage }

    return <React.Fragment>

        <SnackbarContext.Provider value={value}>
            <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert severity={alertType} onClose={handleCloseSnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

            {/* <div id='main-container' className={ getMainContainerClass(pageSizeState) }> */}
            <div id='main-container' className={getMainContainerClass(isCurrentPath, menuState, pageSizeState, user)}>
                {user && <div className='navigation-container content-item'>
                    <Navigation />
                </div>}
                <div className={getContentClass(menuState, pageSizeState)} style={!user ? { width: '100%', left: 10 } : null}>
                    <div className={'header-container'}>
                        {!(isCurrentPath('/contract-dashboard')
                            || isCurrentPath('/contract-list')
                            || isCurrentPath('/add-contract')
                            || isCurrentPath('/user-list')
                            || isCurrentPathMatch('/virtualDealRoom')
                            || !user
                            || isCurrentPathMatch('/contract-preview')
                            || isCurrentPath('/ai-assist')) &&
                            <Header />
                        }
                    </div>
                    {props.children}
                    <div className={'footer-container'}>
                        <Footer />
                    </div>
                </div>
            </div>
        </SnackbarContext.Provider>

    </React.Fragment>
};

export default BaseLayout;
