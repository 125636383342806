import strings from "../../localization";

const CompanyTurnover = {
    LESS_THAN_500K: 1,
    BETWEEN_500K_AND_1M: 2,
    BETWEEN_1M_AND_2M: 3,
    MORE_THAN_1M: 4
}

export function getCompanyTurnover() {
    return [
        {
            id: CompanyTurnover.LESS_THAN_500K,
            name: getCompanyTurnoverString(CompanyTurnover.LESS_THAN_500K)
        },
        {
            id: CompanyTurnover.BETWEEN_500K_AND_1M,
            name: getCompanyTurnoverString(CompanyTurnover.BETWEEN_500K_AND_1M)
        },
        {
            id: CompanyTurnover.BETWEEN_1M_AND_2M,
            name: getCompanyTurnoverString(CompanyTurnover.BETWEEN_1M_AND_2M)
        },
        {
            id: CompanyTurnover.MORE_THAN_1M,
            name: getCompanyTurnoverString(CompanyTurnover.MORE_THAN_1M)
        }
    ];
}

export function getCompanyTurnoverString(companyTurnover) {
    switch(companyTurnover) {
        case CompanyTurnover.LESS_THAN_500K: return strings.constants.user.companyTurnover.lessThan500k;
        case CompanyTurnover.BETWEEN_500K_AND_1M: return strings.constants.user.companyTurnover.between500kAnd1m;
        case CompanyTurnover.BETWEEN_1M_AND_2M: return strings.constants.user.companyTurnover.between1mAnd2m;
        case CompanyTurnover.MORE_THAN_1M: return strings.constants.user.companyTurnover.moreThan1m;
    }
}

export default CompanyTurnover;