import {createContext} from "react";
import PageState from "../Constants/Base/PageState";

const TabPageContext = createContext({
    page: 1,
    pageState: PageState.View,
    showSnackbar: false,
    messageSnackbar: '',
    alertType: 'success',
    setPage: () => {},
    setPageState: () => {},
    setShowSnackbar: () => {},
    setMessageSnackbar: () => {},
    setAlertType: () => {}
});

export default TabPageContext;

export const FilterDefaults = {

    term: ''
}

export const TabDataDefaults = {
    data: [],
    total: 0,
    loading: false
};

export const TabPageOptionDefaults = {
    showFilter: false,
    showActionEdit: true,
    showActionView: true,
    showActionDelete: true,
}