import React, { useEffect, useState } from "react";
import { AccordionDetails, Button, Tooltip, Modal, Box } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import strings from "../../../../localization";
import styles from "../../../Admin/AdminAIData/AIData.module.css";
import ConfirmModal from "../../../../Components/Modals/ConfirmModal";
import { sectionObj } from "../../../../Constants/Admin/DealRoomModel";
import QuestionsList from "./QuestionsList";
import {
  addNewSectionsAdmin,
  deleteSectionsAdmin,
  editSectionsAdmin,
} from "../../../../Services/DealRoom/AdminDealRoomService";
import { Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  accordion: {
    marginBottom: "10px",
    boxShadow: "0 0 10px 0 rgba(236, 240, 243, 0.5)",
    borderRadius: "5px!important",
    position: "relative",
    width: "98%",
  },
  accordionSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "calc(100% - 3rem)",
  },
  coloredStrip: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: "4px",
    backgroundColor: "rgba(229, 192, 117, 1)",
    transition: "all 0.3s ease",
    height: "100% !important",
    borderRadius: "10px, 0px, 0px, 10px",
  },
  toggleIcon: {},
  dueDeligenceSection: {
    fontFamily: "Nobile",
    fontWeight: "bold",
  },
}));

const SectionRow = ({
  sectionsType,
  getSectionListHandler,
  row,
  sectionIndex,
  showMessage,
  activeOpenSection,
  setActiveOpenSection,
}) => {
  const colors = ["#E5C075", "#CEDE6D", "#77DCBC", "#72C4D7", "#8FA8E2"];
  const classes = useStyles();

  const [sectionRow, setSectionRow] = useState(row);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const headingFieldHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let tempSectionRow = structuredClone(sectionRow);

    tempSectionRow[name] = value;
    setSectionRow(tempSectionRow);
  };

  const submitSectionHandler = () => {
    if (!sectionRow?.name) {
      showMessage("Section name can not be empty!", "error");
      return;
    }
    if (editMode) {
      editSectionsAdmin(sectionRow)
        .then((res) => {
          if (res?.status === 204) {
            getSectionListHandler();
            toggleHeadingEditable();
            showMessage("Section updated");
          } else {
            showMessage("Error in saving Section", "error");
          }
        })
        .catch((err) => {
          console.log("error in editSectionsAdmin", err);
        });
    } else {
      let tempData = sectionObj;
      tempData.name = sectionRow.name;
      tempData.type = sectionsType;
      tempData.color = sectionRow.color ?? "";
      tempData.orderNumber = sectionRow.orderNumber ?? 0;
      addNewSectionsAdmin(tempData)
        .then((res) => {
          if (res?.status === 204) {
            getSectionListHandler();
            toggleHeadingEditable();
            showMessage("New Section added");
          } else {
            showMessage("Error in adding Section", "error");
          }
        })
        .catch((err) => {
          console.log("error in addNewSectionsAdmin", err);
        });
    }
  };

  const deleteSectionHandler = (sectionId) => {
    deleteSectionsAdmin(sectionId)
      .then((res) => {
        if (res?.status === 204) {
          setOpenDeleteModal(false);
          getSectionListHandler();
          showMessage("Section Deleted");
        } else {
          showMessage("Error in deleting Section", "error");
        }
      })
      .catch((err) => {
        console.log("error in deleteSectionsAdmin", err);
      });
  };

  const toggleRowHandler = () => {
    let tempSectionRow = structuredClone(sectionRow);
    tempSectionRow.isOpen = !tempSectionRow.isOpen;
    setSectionRow(tempSectionRow);
    if (activeOpenSection.includes(sectionRow?.id)) {
      const filterData = activeOpenSection.filter(
        (id) => id !== sectionRow?.id
      );
      setActiveOpenSection(filterData);
    } else {
      setActiveOpenSection([...activeOpenSection, sectionRow?.id]);
    }
  };

  const toggleHeadingEditable = () => {
    let tempSectionRow = structuredClone(sectionRow);
    tempSectionRow.iseditable = !tempSectionRow.iseditable;
    setSectionRow(tempSectionRow);
    setEditMode(true);
  };

  useEffect(() => {
    setSectionRow(row);
  }, [row]);

  return (
    <Draggable
      key={sectionRow.id}
      draggableId={sectionRow?.id?.toString()}
      index={sectionIndex}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
          }}
        >
          <div
            className={classes.accordion}
            style={{ backgroundColor: "#FFF" }}
          >
            <div
              className={classes.accordionSummary}
              style={{ position: "relative", padding: "0 1.5rem", margin: "0" }}
            >
              <div
                className={classes.coloredStrip}
                style={{ backgroundColor: `${sectionRow?.color}` }}
              />

              <div style={{ display: "flex", margin: "0px 0px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>{sectionIndex + 1}.</span>

                  {sectionRow?.iseditable ? (
                    <div className="questionnaire-fields">
                      <div
                        className={styles.inputContainer}
                        style={{
                          margin: " 8px 10px",
                          height: "max-38px",
                          width: "367px",
                        }}
                      >
                        <input
                          className={styles.inputBox}
                          value={sectionRow?.name}
                          name="name"
                          type="text"
                          onChange={(e) => headingFieldHandler(e)}
                        />
                      </div>
                      <div>
                        <Button
                          onClick={() => submitSectionHandler(sectionRow?.name)}
                          className="save-btn"
                          variant="contained"
                          sx={{
                            margin: "15px 10px",
                            width: "90px",
                            textTransform: "none",
                          }}
                        >
                          {editMode ? "Update" : "Save"}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <span
                        className="deligenceSecHeading"
                        style={{ fontFamily: "Nobile", fontWeight: "500" }}
                      >
                        {sectionRow?.name}
                      </span>
                      <Tooltip
                        title={strings.pages.adminDealRoom.editHeading}
                        onClick={() => toggleHeadingEditable()}
                      >
                        <img
                          className="item-iconWithBg"
                          style={{ width: "75px", height: "75px" }}
                          src="/images/icons/penWithBg.png"
                          alt="edit"
                        />
                      </Tooltip>
                      <Tooltip
                        title={strings.pages.adminDealRoom.deleteAccordion}
                        onClick={() => setOpenDeleteModal(true)}
                      >
                        <img
                          className="item-iconWithBg"
                          style={{ marginTop: "7px" }}
                          src="/images/icons/deleteWithBg.png"
                          alt="delete"
                        />
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
              <Box
                width={"25%"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box display={"flex"} alignItems={"center"}>
                  Color:
                  {sectionRow.iseditable ? (
                    <div
                      className={styles.inputContainer}
                      style={{
                        margin: "8px 10px",
                        height: "max-38px",
                        width: "100%",
                      }}
                    >
                      <input
                        className={styles.inputBox}
                        value={sectionRow?.color}
                        name="color"
                        type="text"
                        onChange={(e) => headingFieldHandler(e)}
                      />
                    </div>
                  ) : (
                    <span
                      className="deligenceSecHeading"
                      style={{ fontFamily: "Nobile", fontWeight: "500" }}
                    >
                      {sectionRow?.color}
                    </span>
                  )}
                </Box>

                <div
                  className={classes.toggleIcon}
                  style={{ cursor: "pointer" }}
                >
                  {sectionRow?.isOpen ? (
                    <RemoveIcon onClick={() => toggleRowHandler()} />
                  ) : (
                    <AddIcon onClick={() => toggleRowHandler()} />
                  )}
                </div>
              </Box>
            </div>
            {sectionRow?.isOpen && (
              <AccordionDetails>
                <div
                  className={classes.coloredStrip}
                  style={{ backgroundColor: `${sectionRow?.color}` }}
                />
                <Box
                  display={"flex"}
                  width={"100%"}
                  mt={1}
                  mb={2}
                  justifyContent={"space-between"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    sx={{ paddingLeft: "4rem", width: "calc(30% - 4rem)" }}
                  >
                    Question
                  </Box>
                </Box>

                <QuestionsList
                  questions={sectionRow?.questions}
                  sectionIndex={sectionIndex}
                  sectionRow={sectionRow}
                  getSectionListHandler={getSectionListHandler}
                  showMessage={showMessage}
                />
              </AccordionDetails>
            )}

            <Modal
              open={openDeleteModal}
              onClose={() => setOpenDeleteModal(false)}
              className="confirm-modal-container"
            >
              <ConfirmModal
                contentText={strings.modals.adminDealRoom.deleteModalTitle}
                cancelBtn={strings.modals.adminDealRoom.no}
                confirmBtn={strings.modals.adminDealRoom.yes}
                handleCloseModal={() => setOpenDeleteModal(false)}
                handleConfirm={() => deleteSectionHandler(sectionRow?.id)}
                contentTextStyle={{ fontWeight: "500", fontSize: "22px" }}
                buttonsStyles={{ borderRadius: "10px !important" }}
              />
            </Modal>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default SectionRow;
