export const virtualDealRoomCol = [
    { id: 1, name: "name", label: "Project Name" },
    { id: 2, name: "targetCompanyName", label: "Company Name" },
    { id: 3, name: "party", label: "Party" },
]


export const administratorFormObj = { name: "", email: "", party: 0, disabled: false }

export const dealRoomParty = [
    { name: "Buyer", value: 0 },
    { name: "Seller", value: 1 }
]

export const dealRoomProjectFormData = {
    targetCompany: {
        name: "",
        type: "private",
        address: "",
        sector: "Legal Services/Solicitor"
    },
    project: {
        name: "",
        party: 0
    },
    administrators: [administratorFormObj]
}


export const dueDeligenceRowObj = {
    "type": 0,
    "name": "",
    "dateCreated": "",
    "isOpen": false,
    "iseditable": true,
    "questions": []
}

export const deligenceQuestionObj = {
    iseditable : true,
    "question": "",
    "answer": "",
    "contractIds": null,
    "dateCreated": ""
}


export const sectionObj = {
    "name": "Others",
    "type": 1,
    "color":""
}



