import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import {useSelector} from "react-redux";
import { Button } from "@mui/material";
import strings from "../../../../../localization";
import TextFieldControl from '../../../../Controls/Inputs/TextFieldControl';
import TextareaControl from '../../../../Controls/Inputs/TextareaControl';
import ValidationPatterns from '../../../../../Constants/Base/ValidationPatterns';
import UserType from "../../../../../Constants/User/UserType";

const ContractRequestForm = ({onSubmit, handleCloseRequestModal}) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const user = useSelector((state) => state.auth.user)

    const formRules = {
        'email': {required: { value: true, message: strings.forms.common.required},
            pattern: { value: ValidationPatterns.EMAIL, 
            message: strings.forms.dashboard.myDetails.generalInformationForm.emailError}},
    }
    
    return(
        <FormProvider {...form}>

            <div id="modal-form" className="confirm-modal contract-request-modal ">
                <h1 className="title">{strings.forms.contract.contractRequestForm.description}</h1>

                {user.userProfileType === UserType.COMPANY_ADMIN &&
                    <div className="field-container">
                        <span className="label">{strings.forms.contract.contractRequestForm.companyName}</span>
                        <TextFieldControl
                            name='companyName'
                            control={data}
                            defaultValue={user.companyName}
                            rules={{required: true}}
                            margin="normal"
                            fullWidth
                            error={Boolean(errors.companyName)}
                            helperText={errors.companyName && strings.forms.contract.contractRequestForm.fieldError}
                            placeholder={strings.forms.contract.contractRequestForm.enterCompanyName}
                        />
                    </div>
                }

                {user.userProfileType === UserType.INDIVIDUAL &&
                    <div className="field-container">
                        <span className="label">{strings.forms.contract.contractRequestForm.fullName}</span>
                        <TextFieldControl
                            name='fullName'
                            control={data}
                            defaultValue={user.fullName}
                            rules={{required: true}}
                            margin="normal"
                            fullWidth
                            error={Boolean(errors.fullName)}
                            helperText={errors.fullName && strings.forms.contract.contractRequestForm.fieldError}
                            placeholder={strings.forms.contract.contractRequestForm.enterFullName}
                        />
                    </div>
                }


                <div className="field-container">
                    <span className="label">{strings.forms.contract.contractRequestForm.email}</span>
                    <TextFieldControl
                        name='email'
                        control={data}
                        defaultValue={user.email}
                        rules={formRules['email']}
                        margin="normal"
                        fullWidth
                        error={Boolean(errors.email)}
                        helperText={errors.email && errors.email.message}
                        placeholder={strings.forms.contract.contractRequestForm.enterEmail}
                    />
                </div>

                <div className="field-container">
                    <span className="label">{strings.forms.contract.contractRequestForm.mobileNumber}</span>
                    <TextFieldControl
                        name='mobileNumber'
                        control={data}
                        defaultValue={user.number}
                        rules={{required: true}}
                        margin="normal"
                        fullWidth
                        error={Boolean(errors.mobileNumber)}
                        helperText={errors.mobileNumber && strings.forms.contract.contractRequestForm.fieldError}
                        placeholder={strings.forms.contract.contractRequestForm.enterMobileNumber}
                    />
                </div>

                <div className="field-container">
                    <span className="label">{strings.forms.contract.contractRequestForm.contractName}</span>
                    <TextFieldControl
                        name='contractName'
                        control={data}
                        defaultValue=''
                        rules={{required: true}}
                        margin="normal"
                        fullWidth
                        error={Boolean(errors.contractName)}
                        helperText={errors.contractName && strings.forms.contract.contractRequestForm.fieldError}
                        placeholder={strings.forms.contract.contractRequestForm.enterContractName}
                    />
                </div>

                <div className="field-container">
                    <span className="label">{strings.forms.contract.contractRequestForm.yourQuery}</span>
                    <TextareaControl
                        name='query'
                        control={data}
                        defaultValue=''
                        rules={{required: true}}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.query)}
                        helperText={errors.query && strings.forms.contract.contractRequestForm.fieldError}
                        placeholder={strings.forms.contract.contractRequestForm.enterQuery}
                        rows={4}
                        maxRows={8}
                    />
                </div>
                
                <div className="submit-container">
                    <Button 
                        className="neutral-btn btn cancel-btn" 
                        variant="contained" 
                        onClick={handleCloseRequestModal}>
                            {strings.forms.contract.contractRequestForm.cancel}
                    </Button>
                    <Button 
                        className="default-btn btn" 
                        variant="contained" 
                        onClick={handleSubmit(onSubmit)}>
                            {strings.forms.contract.contractRequestForm.submit}
                    </Button>
                </div>
                
            </div>                     
        </FormProvider>
    )
}

export default ContractRequestForm;