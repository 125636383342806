export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const sortByOrder = (list) => {
    return list.sort((a, b) => (a.rowOrder > b.rowOrder) ? 1 : -1)
}

export const sortById = (list) => {
    return list.sort((a, b) => (a.id > b.id) ? 1 : -1)
}

export const sortByStepOrder = (list) => {
    return list.sort((a, b) => (a.templateStepOrder > b.templateStepOrder) ? 1 : -1)
}

export const sortByQuestionOrder = (list) => {
    return list.sort((a, b) => (a.templateQuestionOrder > b.templateQuestionOrder) ? 1 : -1)
}