const Permissions = {
    MANAGE_USERS: {
        NAVIGATION: 1
    },
    ADMIN: {
        NAVIGATION: 2
    },
    PRICE_AND_PACKAGE: {
        HEADER: 3
    },
    SUBSCRIPTION: {
        HEADER: 4
    },
    ADD_USER: {
        CAN_SELECT_PARTY: 5
    }
}

export default Permissions;