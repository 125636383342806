import React, { useEffect, useState } from "react";
import CreditCardInput from 'react-credit-card-input';
import { Controller } from "react-hook-form";
import strings from "../../../localization";

const cardDefault = {number: '', expiry: '', cvc: ''};

const CreditCardField = (props) => {

    const [creditCard, setCreditCard] = useState(cardDefault)
    const [numberFieldErr, setNumberFieldError] = useState(true)

    const onChangeNumber = (e) => {

        if (e.target.value.length === 19) {
            setNumberFieldError(false)

            setCreditCard({
                ...creditCard, 
                number: e.target.value,
            })

            props.setValue('creditCard', {...creditCard, number: e.target.value})
        }
    }

    const onErrorNumber = (error) => {

        setNumberFieldError(true)

        setCreditCard({
            ...creditCard, 
            number: ''
        })

        props.setValue('creditCard', {...creditCard, number: ''})
    }

    const onChangeExpiry = (e) => {

        if (e.target.value.length === 7) {
            setCreditCard({
                ...creditCard, 
                expiry: e.target.value
            })

            props.setValue('creditCard', {...creditCard, expiry: e.target.value})
        }
    }

    const onErrorExpiry = (error) => {

        if (error) {
            setCreditCard({
                ...creditCard, 
                expiry: ''
            })

            props.setValue('creditCard', {...creditCard, expiry: ''})
        }
    }

    const onChangeCvc = (e) => {

        if (e.target.value.length === 3) {
            setCreditCard({
                ...creditCard, 
                cvc: e.target.value
            })

            props.setValue('creditCard', {...creditCard, cvc: e.target.value})
        }
    }

    const onErrorCvc = (error) => {

        if (error) {
            setCreditCard({
                ...creditCard, 
                cvc: ''
            })

            props.setValue('creditCard', {...creditCard, cvc: ''})
        }
    }
    
	return (
        <CreditCardInput
            fieldClassName='credit-card-field'
            cardNumberInputProps={{
                onChange: onChangeNumber,
                onError: onErrorNumber,
                required: true
            }}
            cardExpiryInputProps={{
                onChange: onChangeExpiry,
                onError: onErrorExpiry,
                disabled: numberFieldErr ? true : false,
                className: numberFieldErr ? 'credit-card-input disabled-field' : 'credit-card-input'
            }}
            cardCVCInputProps={{
                onChange: onChangeCvc,
                onError: onErrorCvc,
                disabled: numberFieldErr ? true : false,
                className: numberFieldErr ? 'credit-card-input disabled-field' : 'credit-card-input'
            }}
            customTextLabels={{
                invalidCardNumber: strings.components.creditCardField.invalidCardNumber,
                expiryError: {
                    invalidExpiryDate: strings.components.creditCardField.invalidExpiryDate,
                    monthOutOfRange: strings.components.creditCardField.monthOutOfRange,
                    yearOutOfRange: strings.components.creditCardField.yearOutOfRange,
                    dateOutOfRange: strings.components.creditCardField.dateOutOfRange
                },
                invalidCvc: strings.components.creditCardField.invalidCvc,
                invalidZipCode: strings.components.creditCardField.invalidZipCode,
                cardNumberPlaceholder: strings.components.creditCardField.cardNumberPlaceholder,
                expiryPlaceholder: strings.components.creditCardField.expiryPlaceholder,
                cvcPlaceholder: strings.components.creditCardField.cvcPlaceholder,
                zipPlaceholder: strings.components.creditCardField.zipPlaceholder
            }}
        />
    )
};

export default CreditCardField;