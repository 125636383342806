import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import strings from "../../../../localization";
import { Add } from "@mui/icons-material";
import { deligenceQuestionObj } from "../../../../Constants/Admin/DealRoomModel";
import QuestionRow from "./QuestionRow";

const QuestionsList = ({
  questions,
  sectionRow,
  sectionIndex,
  getSectionListHandler,
}) => {
  const [questionsList, setQuestionsList] = useState([]);

  const addNewQuestionHandler = () => {
    let tempQuestionsList = structuredClone(questionsList);
    tempQuestionsList = [...questionsList, deligenceQuestionObj];
    setQuestionsList(tempQuestionsList);
  };

  useEffect(() => {
    setQuestionsList(questions);
  }, [questions]);

  return (
    <>
      {questionsList.map((questionObj, index) => {
        return (
          <QuestionRow
            questionObj={questionObj}
            sectionIndex={sectionIndex}
            questionIndex={index}
            sectionRow={sectionRow}
            getSectionListHandler={getSectionListHandler}
          />
        );
      })}

      <Box
        display={"flex"}
        justifyContent="center"
        alignItems="center"
        mb={2}
        sx={{ fontFamily: "Nobile" }}
        onClick={() => addNewQuestionHandler()}
      >
        <Button className="new-section-button">
          <Add />
          {strings.pages.adminDealRoom.newQuestion}
        </Button>
      </Box>
    </>
  );
};

export default QuestionsList;
