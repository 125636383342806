import React, { useEffect, useState, useContext } from 'react';
import styles from './AIData.module.css';
import { ArrowDropDown, Close, Edit } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import strings from '../../../localization';
import { useDispatch, useSelector } from 'react-redux';
import { clauseDeleteById, clauseUpdateById, getClauseById, getClauseOptions } from '../../../Services/AI/AIConversationService';
import { aiDataActions } from '../../../Slices/AiDataSlice';
import SnackbarContext from '../../../Context/SnackbarContext';
import AIDataCreateViewComponent from './AIDataCreateViewComponent';
import Modal from "@mui/material/Modal";
import SimpleConfirmModal from "./../../../Components/Modals/SimpleConfirmModal";
import { useNavigate } from 'react-router-dom';

function AIDataCreate() {

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const pathArray = window.location.pathname.split('/')
  const pageName = pathArray[pathArray.length - 1]

  const { showMessage } = useContext(SnackbarContext);
  const aiDataRow = useSelector(state => state.aiData.aiDataRow)
  // console.log("aiDataRow",aiDataRow)

  const [deleteTab, setDeleteTab] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteTab = (id) => {
    setDeleteId(id);
    setDeleteTab(true);
  };

  const handleCloseDeleteTab = () => {
    setDeleteId(null);
    setDeleteTab(false);
  };


  const deleteHandler = () => {
    if (deleteId !== null) {
      clauseDeleteById(deleteId)
        .then((res) => {
          setDeleteTab(false);
          showMessage(strings.common.deleted);
          navigate(`/AIData`)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (!aiDataRow) {
      const dataId = pathArray[pathArray.length - 1]
      getClauseById(dataId)
        .then(res => {
          if (res.data) {
            dispatch(aiDataActions.setAiData(res.data));
          }
        })
        .catch(err => {
          console.log("err", err)
        })

    }
  }, [])

  return (
    <div className={styles.mainContainer} >
      <div className={styles.AIDataCreatecontainer}>
        <div className={styles.AIDataCreate1stCol}>
          <div className={styles.button} onClick={() => navigate('/AIData')}>
            <span>Back</span>
          </div>
        </div>
        
        <AIDataCreateViewComponent
          aiDataRow={aiDataRow}
          handleDeleteTab={handleDeleteTab}
          pageName={pageName}
        />
      </div>

      <Modal
        open={deleteTab}
        onClose={handleCloseDeleteTab}
        className="confirm-modal-container"
      >
        <SimpleConfirmModal
          title={strings.modals.contractList.deleteContract.confirmTextForAI}
          cancelBtn={strings.modals.contractList.deleteContract.no}
          confirmBtn={strings.modals.contractList.deleteContract.yes}
          confirmBtnClass={true}
          handleCloseModal={handleCloseDeleteTab}
          handleConfirm={deleteHandler}
        />
      </Modal>
    </div >
  )
}

export default AIDataCreate