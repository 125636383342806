
export function getCompanyDescription() {
    return [
        {
            id: 'Sole Trader Individual',
            name: 'Sole Trader Individual'
        },
        {
            id: 'Freelance consultant',
            name: 'Freelance consultant'
        },
        {
            id: 'Private limited company',
            name: 'Private limited company'
        },
        {
            id: 'Public limited company',
            name: 'Public limited company'
        },
        {
            id: 'Partnership',
            name: 'Partnership'
        },
        {
            id: 'Browsing or looking',
            name: 'Browsing or looking'
        }
    ];
}

export function getIndividualDescription() {
    return [
        {
            id: 'Sole Trader Individual',
            name: 'Sole Trader Individual'
        },
        {
            id: 'Freelance consultant',
            name: 'Freelance consultant'
        },
        {
            id: 'Employee',
            name: 'Employee'
        },
        {
            id: 'Browsing or looking',
            name: 'Browsing or looking'
        },
        {
            id: 'Other',
            name: 'Other'
        }
    ];
}
