import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllContractContent(contractId, hash = "") {
    let url = '/api/contractcontent/all/' + contractId;
    if (hash !== '') {
        url += `?hash=${hash}`;
    }
    return await request(url);
}

export async function getContractContent(id) {
    return await request('/api/contractcontent/' + id);
}

export async function restoreContractContent(contractId, contractContentId, hash = "") {
    let url = '/api/contractcontent/restore/' + contractId + '/' + contractContentId;
    if (hash !== '') {
        url += `?hash=${hash}`;
    }
    return await request(url, {}, HttpMethod.PATCH);
}

export async function saveSuggestion(data) {
    return await request('/api/contractcontent/suggestion/add', data, HttpMethod.POST);
}

export async function getSuggestions(id, hash="") {
    let url = '/api/contractcontent/suggestion/get-by-contract/' + id;
    if (hash !== '') {
        url += `?hash=${hash}`;
    }
    return await request(url);
}

export async function getActiveContractContent(contractId, hash = '') {
    return await request('/api/contractcontent/contract/' + contractId + '?' + hash);
}

export async function saveContract(id, data, hash = '') {
    let url = '/api/contract/save/' + id;
    if (hash !== '') {
        url += `?hash=${hash}`;
    }
    return await request(url, data, HttpMethod.POST);
}

export async function saveContractMetadata(id, data) {
    return await request('/api/contract/save/metadata/' + id, data, HttpMethod.POST);
}

