import React from "react";
import styles from "./AIData.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ExpandableTableViewComponent from "./ExpandableTableViewComponent";
import { isHTML } from "../../../Util/Validation";

function AiDataTable({
  columns,
  rows,
  columnFilterToggle,
  selectColumnFilterDataHandler,
  submitSelectedColumnFilterHandler,
  choiceBoxName,
  filterOptionSearchText,
  optionSearchHandler,
  toggleRowHandler,
  handleClick,
  aiRequestDataRow,

}) {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(rows?.length);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };


  return (
    <div
      className={`${styles.tableContainer} ${choiceBoxName === "TagQuestion" ? styles.aiRequestTableContainer : ""
        }`}
    >
      <TableContainer sx={{
        maxHeight: "90%",
        width: "100%",
        overflowX: "auto",
        minHeight: "70vh",
        background: " #FFF"
      }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow key="column">
              <>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      width: (choiceBoxName === "TagQuestion" &&
                        column.id === "buttons") || column.id === 'data_type' ? column.minWidth + 30 : column.minWidth,
                      backgroundColor: "#F1F3F9",
                      fontWeight: column.fontWeight,
                      zIndex: '0 '
                    }}
                  >
                    <div className={styles.tableCell} title={column.label}>
                      {column.label}
                      {column.id !== "buttons" && (
                        <img
                          src="/images/icons/sort.png"
                          style={{ marginLeft: "0.25rem" }}
                          onClick={() => columnFilterToggle(column.id)}
                          alt="filter"
                        ></img>
                      )}
                    </div>
                    {column.isFilterBoxShown && (
                      <div className={styles.colSearchFilterBox}>
                        <div className={styles.filterBox}>
                          <input
                            className={styles.filterInput}
                            name="filterOptionSearchText"
                            value={filterOptionSearchText}
                            onChange={(e) => optionSearchHandler(e, column.id)}
                            placeholder="Search Option"
                          />
                          <div className={styles.optionsBox}>
                            <ul>
                              <li>
                                <input
                                  style={{ marginTop: '7px' }}
                                  type="checkbox"
                                  name={`${column.id}+selectAll`}
                                  checked={column.isSelectAll}
                                  onChange={() => selectColumnFilterDataHandler(column.id, "selectAll")}
                                />
                                (Select All)
                              </li>
                              {column.options?.map((col) => {
                                if (col.isShown) {
                                  return (
                                    <li>
                                      <input
                                        style={{ marginTop: '7px' }}
                                        type="checkbox"
                                        name={col.label}
                                        checked={col.checked}
                                        onChange={() => selectColumnFilterDataHandler(column.id, col.label)}
                                      />
                                      <span> {col.label}</span>
                                    </li>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className={styles.filterButtonRow}>
                          <button onClick={() => submitSelectedColumnFilterHandler(column)}>
                            Ok
                          </button>
                          <button onClick={() => columnFilterToggle(column.id)}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </TableCell>
                ))}
                {choiceBoxName === "TagQuestion" &&
                  <TableCell
                    key="buttonCell"
                    style={{
                      backgroundColor: "#F1F3F9",
                    }}
                  ></TableCell>
                }
              </>
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: "#FFF" }}>
            {rows
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, id) => {
                return (
                  <React.Fragment key={id} >
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code} style={{ backgroundColor: (id % 2 !== 0) ? '#F8F9FC' : "#FFF" }}>
                      <>
                        {columns?.map((column, id) => {
                          const value = row[column.id];
                          return (
                            <TableCell sx={{ border: "none" }} key={column.id} align={column.align} >
                              {column.id === "data_type" ? (
                                <div
                                  className={`${styles.tableCell} ${styles.tableCellTextOverflow}`}
                                  style={{
                                    display: "flex",
                                    backgroundColor:
                                      row[column.id] === "Clause"
                                        ? "#82F5DE"
                                        : "#F8D58D",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    fontWeight: "800",
                                  }}
                                  title={value}
                                >
                                  {value !== "question_with_options" ? value : "Question With Options"}
                                </div>
                              ) : (
                                isHTML(value)
                                  ? <div
                                    className={`${styles.tableCell} ${styles.tableCellTextOverflow}`}

                                    dangerouslySetInnerHTML={{ __html: value }}
                                  />
                                  :
                                  <div
                                    className={`${styles.tableCell} ${styles.tableCellTextOverflow}`}
                                    style={{ textAlign: "left" }}
                                    title={column.id === "buttons" ? "View" : value}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </div>
                              )}

                            </TableCell>
                          );
                        })}

                        {choiceBoxName === "TagQuestion" &&
                          <TableCell sx={{ border: "none" }}  >
                            <div className={styles.tableCell} style={{ display: "flex" }}>


                              {row?.data?.question?.includes(aiRequestDataRow?.request)
                                ? <img
                                  className={styles.iconImg}
                                  style={{ cursor: "pointer", width: "90px" }}
                                  src="/images/icons/Tagged.png"
                                  alt="TagQuestion"
                                />
                                : <img
                                  className={styles.iconImg}
                                  style={{ cursor: "pointer", width: "90px" }}
                                  src="/images/icons/TagQuestion.png"
                                  onClick={() => handleClick(row?.data)}
                                  alt="TagQuestion"
                                />
                              }

                              <img
                                className={styles.iconImg}
                                style={{ cursor: "pointer", marginLeft: "0.4rem", width: "15px" }}
                                src="/images/icons/plusIcon.png"
                                onClick={() => toggleRowHandler(row?.data?.id)}
                                alt="plus"
                              />
                            </div>
                          </TableCell>
                        }

                      </>
                    </TableRow>
                    {row?.isOpen &&

                      <ExpandableTableViewComponent row={row} />

                    }
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </div>
  );
}

export default AiDataTable;
