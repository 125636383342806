import React from "react";
import Button from "@mui/material/Button";
import {useDropzone} from "react-dropzone";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {toBase64} from "../../Util/ImageUtil";

const ImageUploadComponent = (props) => {

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: props.accept,
        onDrop: file => onDrop(file),
    });

    const onDrop = async (files)  => {
        const image = await toBase64(files[0]);
        props.setImage(image)
        
        if (props.onDrop) {
            props.onDrop(props.image)
        }
    }

    const removeImage = () => {
        props.setImage('')
    }

    return (
        <div className='image-upload-component'>
            <section className={props.disabled ? "drop-zone disabled" : "drop-zone"}>
                {props.image && !props.disabled &&
                    <Button className="close-btn" onClick={removeImage}>
                        <CloseIcon />
                    </Button>
                }

                {props.disabled 
                    ?
                        <div className='dropzone'>
                            {props.image
                                ? <img className="image" src={props.image} />
                                : <IconButton className="icon"><img src={props.icon ? props.icon : "/images/icons/profile-big.png"} /></IconButton>
                            }
                        </div>
                    :
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            { props.image
                            ?    <div className='image' style={{ backgroundImage: `url(${props.image})` }} />
                                : <IconButton><img src={props.icon ? props.icon : "/images/icons/profile-big.png"} /></IconButton>
                            }
                        </div>
                }
            </section>
        </div>
    );

}
export default ImageUploadComponent