import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import strings from "../../localization";
import CloseIcon from '@mui/icons-material/Close';

const WelcomeInfo = ({ onClose }) => {

    const currentUser = useSelector((state) => state.auth.user)

    return(
        <div id="info-modal" className="confirm-modal welcome-modal">
            <div className="close-btn-container">
                <CloseIcon className="icon" onClick={onClose} />
            </div>
            <div className="welcome-content">
                <div className="icon-container">
                    <img src="/images/logo.png" />
                </div>
                <h1 className="title">
                    <span>{strings.modals.welcomeInfo.hello}</span>
                    <b> {currentUser.fullName}! </b>
                </h1>
                <p className="welcome-text">{strings.modals.welcomeInfo.welcomeTo}
                    <b> {strings.modals.welcomeInfo.logicalContracts}</b>
                </p>
                {/*<p className="welcome-text">{strings.modals.welcomeInfo.msg}</p>*/}
            </div>
        </div>                     
    )
}

export default WelcomeInfo;