import { getUserFromLocalStorage } from "../Base/OAuth";
import { getUserCsToken } from "../Services/User/UserService";
import { getSuggestions } from "../Services/Contract/ContractContentService";
import { convertUTCToLocalTime } from "./DateUtil";

export const getSelectedText = (editor) => {

    if(!editor) {
        return '';
    }

    let selection = editor.model.document.selection
    const range = selection.getFirstRange();
    let selectedText = '';
    for (const item of range.getItems()) {

        if(!item.data || item.data.trim() === '') {
            continue;
        }

        selectedText = item.data
    }

    return selectedText;
}

export const removeEmptyRows = (text) => {

    return text.replaceAll("<p>&nbsp;</p>", '');

}

export const handleCovertFromHTMLtoWord = async (htmlData, name) => {
    const data = {
        fileName: name + '.docx',
        html: htmlData,
        default_styles: true,
        stylesheets: [
            'EDITOR_STYLES'
        ],
        options: {
            format: 'A4',
            margin_top: '20mm',
            margin_bottom: '20mm',
            margin_right: '12mm',
            margin_left: '12mm'
        }
    };

    let token = await getUserCsToken(getUserFromLocalStorage()?.email, getUserFromLocalStorage()?.fullName);

    const response = await fetch('https://docx-converter.cke-cs.com/v1/convert', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type' : 'application/json',
            'Authorization' : token.data
        }
    });
    return response;
}

export const handleCovertFromHTMLtoPDF = async (htmlData, name) => {
    const data = {
        fileName: name + '.pdf',
        html: htmlData,
        stylesheets: [
            'EDITOR_STYLES'
        ],
        options: {
            format: 'A4',
            margin_top: '20mm',
            margin_bottom: '20mm',
            margin_right: '12mm',
            margin_left: '12mm',
            page_orientation: 'portrait',
        }
    };

    let token = await getUserCsToken(getUserFromLocalStorage()?.email, getUserFromLocalStorage()?.fullName);

    const response = await fetch('https://pdf-converter.cke-cs.com/v1/convert', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type' : 'application/json',
            'Authorization' : token.data
        }
    });
    return response;
}

export const handleCovertFromWordToHtml = async (file) => {
    const conversionConfig = {
        default_styles: true,
        collaboration_features: {
            user_id: getUserFromLocalStorage()?.email,
            comments: true,
            comments_styles: 'full',
            track_changes: false
        }
    };

    let formData = new FormData();
    formData.append('config', JSON.stringify(conversionConfig));
    formData.append('file', file, file.name);
    
    let token = await getUserCsToken(getUserFromLocalStorage()?.email, getUserFromLocalStorage()?.fullName);
    
    const response = await fetch('https://docx-converter.cke-cs.com/v2/convert/docx-html', {
        method: 'POST',
        body: formData,
        headers: {
            'Authorization' : token.data
        }
    });

    return response.json();
}


export const removeSuggestionTags = (text) => {
    return text.replace(/(<suggestion-start.*?><\/suggestion-start>.*?<suggestion-end.*?><\/suggestion-end>)|(<suggestion-end.*?><\/suggestion-end>)/g, '');
}

export class TrackChangesIntegration {

	constructor(editor) {
		this.editor = editor;
	}

	init() {
		const trackChangesPlugin = this.editor.plugins.get('TrackChanges');
        let contractId = this.editor?.config?._config?.collaboration?.channelId.split('-')[1];
        let locationHash = window.location.hash.substring(1);

        getSuggestions(contractId, locationHash).then((response) => {
            response.data.map(suggestion => {
                let suggestionData = {
                    id: suggestion.suggestionId, 
                    type: suggestion.type, 
                    authorId: suggestion.authorId, 
                    createdAt: convertUTCToLocalTime(new Date(suggestion.createdAt)),
                    data: suggestion.data ? JSON.parse(suggestion.data) : null
                }
                trackChangesPlugin.addSuggestion(suggestionData)
            })
        });
	}
}