import React, {useEffect, useState} from "react";
import DatePicker from '@mui/lab/DatePicker';
import {Box, TextField} from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {Controller} from "react-hook-form";
import {dateToString} from "../../../Util/DateUtil";

const DatePickerControl = ({data, value, setValue, size, name, disabled, format, onChange}) => {

    const [date, setDate] = useState(value);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setDate(value)
    }, [value])


    return <Controller
        control={data}
        render={({ field }) =>
            <Box className='datetime-control textfield-control'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        value={date}
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        inputFormat={format ? format : "dd MMMM yyyy"}
                        disabled={disabled}
                        onChange={(newValue) => {
                            setValue(name, dateToString(newValue, format ? format : "dd MMMM yyyy"));
                            setValue(name + '-raw', newValue);
                            setDate(newValue);

                            if(onChange) {
                                onChange(newValue)
                            }
                        }}
                        renderInput={(params) => 
                            <TextField {...params}
                                onClick={e => setOpen(true)}
                                size={size ? size : 'small'}
                                fullWidth
                            />
                        }
                    />
                </LocalizationProvider>
            </Box>
        }
    >
    </Controller>
}

export default DatePickerControl;