import React, {useEffect, useState} from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import strings from "../../localization";

const useStyles = makeStyles(theme => ({
    clearIndicator: {
        marginRight: '-40px',
        backgroundColor: "gray",
        "& span": {
          "& svg": {
            backgroundColor: "red",
          }
        }
      },
}));

const AutocompleteSelectControl = (props) => {

    const classes = useStyles();
    const [value, setValue] = useState('')

    useEffect(() => {

        if(!props.onInputChange) {
            return;
        }

        props.onInputChange(value);

    }, [value])

    useEffect(() => {

        setValue(props.value)

    }, [props.value])

    return (
            <Autocomplete
                {...props}
                inputValue={value}
                className="autocomplete-control"
                classes={{
                    clearIndicatorDirty: classes.clearIndicator,
                    paper: props.paperClass && props.paperClass
                }}
                disableClearable
                options={props.options}
                getOptionLabel={option => option[props.nameKey ? props.nameKey : 'name']}
                onChange={(event, searchValue) => {
                    props.handleInputChange(searchValue)
                }}
                filterOptions={props.filterOptions}
                noOptionsText={props.noOptionsText ? props.noOptionsText : strings.components.autocompleteControl.noOptions}
                onInputChange={(event, newInputValue, reason) => {
                    if (reason === 'reset') {
                        setValue('')
                        return
                    } else {
                        setValue(newInputValue)
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        className="textfield-control"
                        {...params}
                        placeholder={props.placeholder}
                        onKeyDown={(e) => props.onKeyDown(e)}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: null,
                            type: 'search',
                        }}
                    />
                )}
            />
    )   
}

export default AutocompleteSelectControl;