import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllCommentsByContract(id) {
    return await request('/api/comment/all/contract/' + id);
}

export async function addComment(data) {
    return await request('/api/commentNotify/add', data, HttpMethod.POST);
}

export async function editComment(id, data) {
    return await request('/api/commentNotify/edit/' + id, data, HttpMethod.POST);
}

export async function deleteComment(id, data) {
    return await request('/api/commentNotify/delete/' + id, data, HttpMethod.POST);
}

export async function changeCommentResolve(id, data) {
    return await request('/api/commentNotify/resolve/' + id, data, HttpMethod.POST);
}