import React, {useContext, useEffect, useState} from "react";
import {Button, Collapse} from "@mui/material";
import {ListItemText, Menu, MenuItem} from "@mui/material";
import TextFieldControl from "../../../Components/Controls/Inputs/TextFieldControl";
import SelectControl from "../../../Components/Controls/Inputs/SelectControl";
import strings from "../../../localization";
import {FormProvider, useForm} from "react-hook-form";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import User from './User';
import Category from "./Category";
import Contract from "./Contract";
import Price from './Price';
import SubPackage from './SubPackage';
import Modal from '@mui/material/Modal';
import ConfirmModal from '../../../Components/Modals/ConfirmModal';
import {changeActivePackage, deletePackage, editPackage} from "../../../Services/ContractCategory/PackageService";
import SnackbarContext from "../../../Context/SnackbarContext";
import {
    changeActiveContractCategories,
    editContractCategory
} from "../../../Services/ContractCategory/ContractCategoryService";
import {getCurrency, currencyDefaultValue} from '../../../Constants/Base/Currency';
import Tooltip from "@mui/material/Tooltip";
import LoaderContext from "../../../Context/LoaderContext";


const Package = (props) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, setError, formState: {errors}} = form;

    const { showMessage } = useContext(SnackbarContext);
    const [packages, setPackages] = useState(props.packages)
    const [drop, setDrop] = useState({ id: 0, toggle: false})
    const [openModal, setOpenModal] = useState(false)
    const [activateMenuAnchorEl, setActivateMenuAnchorEl] = useState(null)

    const [packageToEdit, setPackageToEdit] = useState(-1);
    const [categoriesIds, setCategoriesIds] = useState([])
    const [templatesIds, setTemplatesIds] = useState([])
    const [usersIds, setUsersIds] = useState([])
    const [packageType, setPackageType] = useState(-1)
    const [maximumNumber, setMaximumNumber] = useState();
    const [icon, setIcon] = useState();
    const [featuresFieldsNames, setFeaturesFieldsNames] = useState([])
    const [additionalFeaturesFieldsNames, setAdditionalFeaturesFieldsNames] = useState([])
    const [disableSubmit, setDisableSubmit] = useState(false)
    const {showLoader, setLoaderTitle} = useContext(LoaderContext);

    useEffect(() => {
        setPackages(props.packages)
    }, [props.packages])

    const handleDrop = (id) => {
        
        if (id !== drop.id) {
            setUsersIds([]);
            setDrop({
                id: id,
                toggle: true
            });
        } else {
            setPackageToEdit(-1);
            setDrop({
                id: id,
                toggle: !drop.toggle
            });
        }
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleConfirm = pack => {
        setOpenModal(false)
        activatePackage(pack)
    }

    const activatePackage = pack => {

        setActivateMenuAnchorEl(null);
        showLoader(true);

        changeActivePackage({
            id: pack.id,
            active: !pack.active
        }).then(response => {

            if(!response || !response.ok) {
                showMessage(strings.common.saved);
                return;
            }

            showMessage(response.data.active ? strings.common.activated : strings.common.deactivated)

            setDrop({ id: 0, toggle: false})
            props.fetch();
            showLoader(false);
        });
    }

    const removePackage = pack => {

        setActivateMenuAnchorEl(null);

        deletePackage(pack.id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            showMessage(strings.common.deleted);
            props.fetch();
        })
    }

    const handleEditPackage = (data) => {

        if (data.price < 1 || data.annualPrice < 1) {
            if (data.price < 1) {
                setError('price', {'message': 'The monthly price cannot be less than 1£'})
            }
            if (data.annualPrice < 1) {
                setError('annualPrice', {'message': 'The annual price cannot be less than 1£'})
            }
            return;
        }

        setDisableSubmit(true)

        let features = []

        for (let el of featuresFieldsNames) {
            if(data[el]?.length > 0) {
                features.push(data[el])
            }
        }
        
        let additionalFeatures = []

        for (let el of additionalFeaturesFieldsNames) {
            if(data[el]?.length > 0) {
                additionalFeatures.push(data[el])
            }
        }

        let finalData = {
            ...data,
            id: packageToEdit,
            name: data.name,
            color: data.color,
            description: data.description,
            featureTitle: data.featureTitle,
            features: features,
            additionalFeatureTitle: data.additionalFeatureTitle,
            additionalFeatures: additionalFeatures,
            icon: icon,
            price: data.price,
            annualPrice: data.annualPrice,
            onceOf: data.onceOf,
            recommended: data.recommended,
            templateIds: templatesIds,
            categoryIds: categoriesIds,
            userIds: packageType == 1 ? null : usersIds,
            maxNumberOfUsers: maximumNumber ? maximumNumber : -1,
            packageType: packageType
        }

        editPackage(finalData).then(response => {

            if(!response || !response.ok) {
                return;
            }
            setPackageToEdit(-1);
            setDisableSubmit(false)
            showMessage(strings.common.edited)
            props.fetch();
        })
    }

    const handleActivateClick = event => {
        setActivateMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setActivateMenuAnchorEl(null);
    };

    const renderPackage = () => {
        let result = [];

        for (let pack of packages) {
            result.push(
            <FormProvider {...form}>
                <div className="tab-page-item content-item admin-package" key={pack.id}>
                    <div className="item-header">
                        <div className="item-title">
                            {
                                packageToEdit !== pack.id &&
                                <h1 className={pack.active ? "title" : "title deactivated-text"}>{pack.name} {pack.currency} {pack.price} {pack.currency} {pack.annualPrice} {!pack.active  && strings.pages.adminPackages.deactivated}</h1>
                            }
                            {
                                packageToEdit !== pack.id && drop.toggle && pack.id === drop.id &&
                                <React.Fragment>
                                    <Tooltip 
                                        title={strings.pages.adminPackages.editPackage}
                                        placement="top" 
                                        arrow>
                                        <IconButton className="item-title-icon" 
                                            onClick={() => setPackageToEdit(pack.id)}>
                                            <img src="/images/icons/pen.png" /></IconButton>
                                    </Tooltip>

                                    <Tooltip 
                                        title={strings.pages.adminPackages.deactivatePackage}
                                        placement="top" 
                                        arrow>
                                        <IconButton className="delete-deactivate-icon item-title-icon" onClick={() => handleOpenModal()}>
                                            <img src="/images/icons/bag.png" /></IconButton>                                    
                                    </Tooltip>
                                 </React.Fragment>
                            }
                            {
                                packageToEdit === pack.id && drop.toggle && pack.id === drop.id &&
                                <React.Fragment>
                                    <div className="long-input-wrap">
                                        <TextFieldControl
                                            name='name'
                                            control={data}
                                            defaultValue={pack.name}
                                            rules={{required: true}}
                                            fullWidth
                                            margin="normal"
                                            error={Boolean(errors.name)}
                                            helperText={errors.name && strings.forms.admin.adminCategoryForm.category}
                                            placeholder={strings.forms.admin.adminCategoryForm.categoryName}
                                        />
                                    </div>

                                    {/* <div className="short-input-wrap">
                                        <SelectControl
                                            setValue={setValue}
                                            name='currency'
                                            defaultValue={currencyDefaultValue()}
                                            options={getCurrency()}
                                            nameKey='name'
                                            valueKey='id'
                                            className="select-control-container"
                                        />
                                    </div>*/}
                                    <Button className="secondary-btn btn" variant="contained" 
                                        onClick={handleSubmit(handleEditPackage)}
                                        disabled={disableSubmit}
                                    >
                                        {strings.pages.adminPackages.save}
                                    </Button>
                                </React.Fragment>
                            }
                        </div>

                        {
                            !pack.active
                            ? 
                                <React.Fragment>
                                    <Tooltip 
                                        title={strings.commonTooltips.deactivated}
                                        placement="top" 
                                        arrow>
                                        <MoreVertIcon className="toggle-icon" onClick={(event) => handleActivateClick(event)} />
                                    </Tooltip>

                                    <Menu
                                        id='activate-menu'
                                        className="activate-menu"
                                        anchorEl={activateMenuAnchorEl}
                                        open={ Boolean(activateMenuAnchorEl) }
                                        onClose={() => handleClose()}
                                    >
                                        <MenuItem className="activate-menu-list">
                                            <ListItemText className="item" inset primary={strings.pages.adminPackages.activate} onClick={() => activatePackage(pack)}/>
                                            <ListItemText className="item" inset primary={strings.pages.adminPackages.delete} onClick={() => removePackage(pack)}/>
                                        </MenuItem>
                                    </Menu>
                                </React.Fragment>

                            :   drop.toggle && pack.id === drop.id
                                ? <Tooltip 
                                    title={strings.commonTooltips.collapse}
                                    placement="top" 
                                    arrow>
                                    <RemoveIcon className="toggle-icon" onClick={() => handleDrop(pack.id)} />
                                </Tooltip>
                                : <Tooltip 
                                    title={strings.commonTooltips.expand}
                                    placement="top" 
                                    arrow>
                                    <AddIcon className="toggle-icon" onClick={() => handleDrop(pack.id)} />
                                </Tooltip>
                        }
                    </div>

                    {
                        pack.active &&

                        <Collapse in={drop.id === pack.id && drop.toggle} timeout="auto" unmountOnExit>
                            <div className="package-form-content">
                                <SubPackage 
                                    name={strings.pages.adminPackages.packages} 
                                    pack={pack}
                                    featuresFieldsNames={featuresFieldsNames}
                                    setFeaturesFieldsNames={setFeaturesFieldsNames}
                                    additionalFeaturesFieldsNames={additionalFeaturesFieldsNames}
                                    setAdditionalFeaturesFieldsNames={setAdditionalFeaturesFieldsNames}
                                    icon={icon}
                                    setIcon={setIcon}
                                    data={data}
                                    setValue={setValue}
                                    errors={errors}
                                    editIcon={packageToEdit === pack.id && drop.toggle && pack.id === drop.id ? true : false}
                                />
                                <div className="right">
                                    <User 
                                        name={strings.pages.adminPackages.users} 
                                        pack={pack} 
                                        usersIds={usersIds}
                                        setUsersIds={setUsersIds}
                                        packageType={packageType}
                                        setPackageType={setPackageType}
                                        maximumNumber={maximumNumber}
                                        setMaximumNumber={setMaximumNumber}
                                        editIcon={packageToEdit === pack.id && drop.toggle && pack.id === drop.id ? true : false}
                                    />
                                    <Category 
                                        name={strings.pages.adminPackages.categories} 
                                        pack={pack} 
                                        setCategoriesIds={setCategoriesIds}
                                        categoriesIds={categoriesIds}
                                        editIcon={packageToEdit === pack.id && drop.toggle && pack.id === drop.id ? true : false}
                                    />
                                    <Price
                                        name={strings.pages.adminPackages.price} 
                                        pack={pack}
                                        editIcon={packageToEdit === pack.id && drop.toggle && pack.id === drop.id ? true : false}
                                        setValue={setValue}
                                        data={data}
                                        errors={errors}
                                    />
                                    <Contract 
                                        name={strings.pages.adminPackages.templates} 
                                        pack={pack} 
                                        setTemplatesIds={setTemplatesIds}
                                        templatesIds={templatesIds}
                                        editIcon={packageToEdit === pack.id && drop.toggle && pack.id === drop.id ? true : false}
                                    />
                                </div>
                                
                                <Modal
                                    open={openModal}
                                    onClose={handleCloseModal}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    className='confirm-modal-container'
                                >
                                    <ConfirmModal
                                        title={strings.modals.adminPackages.title}
                                        contentText={strings.modals.adminPackages.confirmText}
                                        cancelBtn={strings.modals.adminPackages.cancelBtn}
                                        confirmBtn={strings.modals.adminPackages.deactivateBtn}
                                        handleCloseModal={handleCloseModal}
                                        handleConfirm={() => handleConfirm(pack)}
                                    />
                                </Modal>
                            </div>
                        </Collapse>
                    }

                </div>
            </FormProvider>
            )
        }
        return result;
    }

    return (
        <div id="admin-tab-content" className="tab-page-content">
           {renderPackage()}
        </div>
    )
}

export default Package;