import { Button } from "@mui/material";
import React from "react";

const ConfirmModal = (props) => {
    return(
        <div id="simple-confirm-modal" className="confirm-modal simple-confirm-modal">
            <h1 className="simple-confirm-title">{props.title}</h1>
            <div className="confirm-buttons">
                {
                    props.cancelBtn &&

                    <Button
                        className="neutral-btn btn cancel-btn"
                        variant="contained"
                        onClick={props.handleCloseModal}>
                        {props.cancelBtn}
                    </Button>
                }
                {
                    props.confirmBtn &&
                    <Button
                        className={props.confirmBtnClass ? "default-btn btn warning-btn" :"default-btn btn"}
                        variant="contained"
                        onClick={props.handleConfirm}>
                        {props.confirmBtn}
                    </Button>
                }

            </div>
        </div>                     
    )
}

export default ConfirmModal;