import React, {useState} from "react";

import {useSelector} from "react-redux";
import {isTrial} from "../../Util/PermissionUtil";
import {dateInPast, datediff} from "../../Util/DateUtil";

const Footer = () => {

    const user = useSelector((state) => state.auth.user)

    let dateDiff = 0;

    if(user) {
        dateDiff = datediff(new Date(), new Date(user?.company?.packageValidUntil))
    }

    return <React.Fragment>
        {
            user && isTrial(user) && dateDiff >= 0 &&
            <div className='free-trial-banner'>
                <p>Your Free Trial expires in {dateDiff} days</p>
            </div>
        }
    </React.Fragment>
};
export default Footer;
