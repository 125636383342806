import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import strings from '../../localization';
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { dateToString } from "../../Util/DateUtil";
import IconButton from '@mui/material/IconButton';
import ShareForm from '../../Components/Forms/Pages/ContractList/ShareForm';
import UnsubscribeInfo from '../../Components/Modals/Pages/Dashboard/UnsubscribeInfo';
import { getContractStatusClass, getContractStatusString } from "../../Constants/Contract/ContractStatus";
import SharedType from "../../Constants/Contract/SharedType";
import { MoreVert } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Draggable } from "react-beautiful-dnd";
import Tooltip from "@mui/material/Tooltip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmModal from "../../Components/Modals/ConfirmModal";

const List = ({
    selectedContracts,
    setSelectedContracts,
    setSelectAll,
    folderContracts,
    hasSubscription,
    selectedFolder,
    handleOpenMoveModal,
    handleDownload,
    handleOpenDeleteModal,
}) => {
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.auth.user)
    const [isChanged, setIsChanged] = useState(false)
    const [contracts, setContracts] = useState([]);
    const [openShareModal, setOpenShareModal] = useState(false)
    const [contractToShare, setContractToShare] = useState({})
    const [openUnsubscriptionInfo, setOpenUnsubscriptionInfo] = useState(false)
    const [optionMenuAnchorEl, setOptionMenuAnchorEl] = useState(null);
    const [menuId, setMenuId] = useState(null);

    useEffect(() => {

        let contracts = folderContracts.map(e => e = { ...e, users: [] })
        setContracts(contracts)

    }, [folderContracts]);

    useEffect(() => {
        handleIsSelectedAll()
    }, [isChanged, selectedContracts])

    const handleIsSelectedAll = () => {

        setIsChanged(true)

        if (selectedContracts.length === contracts.length && selectedContracts.length > 0) {
            setSelectAll(true)
        } else {
            setSelectAll(false)
        }
    }

    const handleCheckContract = (isChecked, contract) => {

        if (isChecked) {
            if (!selectedContracts.find(x => x.id === contract.id)) {
                setSelectedContracts([
                    ...selectedContracts,
                    contract
                ])
            }
        } else {

            setSelectedContracts(selectedContracts.filter(x => x.id !== contract.id))
        }
    }

    const isChecked = (id) => {

        const contract = selectedContracts.find(x => x.id === id);

        if (contract) {
            return true;
        }

        return false;
    }

    const open = (id) => {
        navigate('/contract-preview/' + id)
    }

    const handleOpenShareModal = (contract) => {
        setOpenShareModal(true)
        setContractToShare(contract)
    }

    const handleCloseShareModal = () => {
        setOpenShareModal(false)
    }

    const handleOpenUnsubscriptionInfo = () => {
        setOpenUnsubscriptionInfo(true)
    }

    const closeUnsubscriptionInfo = () => {
        setOpenUnsubscriptionInfo(false)
    }

    const confirmUnsubscriptionInfo = () => {
        navigate('/pricing-and-package')
    }

    const editContract = (contract) => {
        navigate('/add-contract', { state: { contract: contract } })
    }

    const handleMenuOpen = (event, menuId, contract) => {
        setSelectedContracts([contract])
        setOptionMenuAnchorEl(event.currentTarget);
        setMenuId(menuId);
    };

    const handleMenuClose = () => {
        setOptionMenuAnchorEl(null);
        setMenuId(null);
    };

    const renderContracts = () => {
        let result = []

        if (contracts.length > 0) {
            for (let contract of contracts) {
                let rowId = 'action-menu-' + contract.id;

                result.push(
                    <Draggable
                        key={'contract-' + contract.id}
                        draggableId={'contract-' + contract.id}
                        index={contract.id}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={snapshot.isDragging ? 'contract-dragging' : ''}
                            >
                                {
                                    hasSubscription
                                        ? <div key={'contract-' + contract.id} className='item'>
                                            <Checkbox
                                                className='checkbox'
                                                checked={isChecked(contract.id)}
                                                onChange={(e) => handleCheckContract(e.target.checked, contract)}
                                            />
                                            <div className="item-title">
                                                <Tooltip title={contract.name}>
                                                    <h1 className="title">{contract.name}</h1>
                                                </Tooltip>
                                                <span className="created">{dateToString(new Date(contract.dateCreated))}</span>
                                            </div>
                                            <div className="status-container">
                                                <span
                                                    className={'status ' + getContractStatusClass(contract.state)}>{getContractStatusString(contract.state)}</span>
                                            </div>
                                            <div className={'more-container'}>
                                                
                                                <IconButton
                                                    aria-haspopup="true"
                                                    onClick={(event) => handleMenuOpen(event, rowId, contract)}
                                                >
                                                    <MoreVert />
                                                </IconButton>
                                                {
                                                    menuId === rowId &&
                                                    <Menu
                                                        id='contract-list-menu'
                                                        anchorEl={optionMenuAnchorEl}
                                                        open={Boolean(optionMenuAnchorEl)}
                                                        onClose={() => handleMenuClose()}
                                                    >
                                                        <MenuItem onClick={() => open(contract.id)}>
                                                            <ListItemIcon>
                                                                <img src={'images/icons/menu/Open.png'} />
                                                            </ListItemIcon>
                                                            <ListItemText inset primary={'Open'} />
                                                        </MenuItem>
                                                        {
                                                            contract.template &&
                                                            currentUser.id == contract.user.id &&
                                                            <MenuItem onClick={() => {
                                                                editContract(contract);
                                                                handleMenuClose()
                                                            }}>
                                                                <ListItemIcon>
                                                                    <img src={'images/icons/menu/Edit.png'} />
                                                                </ListItemIcon>
                                                                <ListItemText inset primary={'Edit questionnaire'} />
                                                            </MenuItem>
                                                        }

                                                        {
                                                            currentUser.id == contract.user.id &&
                                                            <MenuItem onClick={() => {
                                                                handleOpenShareModal(contract);
                                                                handleMenuClose()
                                                            }}>
                                                                <ListItemIcon>
                                                                    <img src={'images/icons/menu/Share.png'} />
                                                                </ListItemIcon>
                                                                <ListItemText inset primary={'Share'} />
                                                            </MenuItem>
                                                        }

                                                        <MenuItem onClick={() => {
                                                            setSelectedContracts([contract]);
                                                            handleOpenMoveModal();
                                                            handleMenuClose()
                                                        }}>
                                                            <ListItemIcon>
                                                                <img src={'images/icons/menu/Folder.png'} />
                                                            </ListItemIcon>
                                                            <ListItemText inset primary={'Move Contract'} />
                                                        </MenuItem>
                                                        <MenuItem onClick={() => {
                                                            setSelectedContracts([contract]);
                                                            handleDownload();
                                                            handleMenuClose()
                                                        }}>
                                                            <ListItemIcon>
                                                                <img src={'images/icons/menu/Download.png'} />
                                                            </ListItemIcon>
                                                            <ListItemText inset primary={'Download'} />
                                                        </MenuItem>
                                                        <MenuItem onClick={() => {
                                                            setSelectedContracts([contract]);
                                                            handleOpenDeleteModal();
                                                            handleMenuClose()
                                                        }}>
                                                            <ListItemIcon>
                                                                <img src={'images/icons/menu/Delete.png'} />
                                                            </ListItemIcon>
                                                            <ListItemText inset primary={'Delete'} />
                                                        </MenuItem>
                                                    </Menu>
                                                }
                                            </div>

                                        </div>

                                        : <div key={'contract-' + contract.id} className='item'>
                                            <div className="item-title">
                                                <h1 className="title">{contract.name}</h1>
                                                <span className="created">{dateToString(new Date(contract.dateCreated))}</span>
                                            </div>
                                            <div className="status-container">
                                                <span className="status">Status</span>
                                            </div>
                                            <Button className="default-btn btn open-btn disabled"
                                                onClick={handleOpenUnsubscriptionInfo}>{strings.pages.contractList.open}</Button>

                                            {currentUser.id == contract.user.id &&
                                                <Button className="neutral-btn btn share-btn disabled"
                                                    startIcon={<IconButton><img src="/images/icons/share-gray.png" /></IconButton>}
                                                    variant="contained" onClick={handleOpenUnsubscriptionInfo}>
                                                    <span className="btn-txt">{strings.pages.contractList.share}</span>
                                                </Button>
                                            }
                                        </div>
                                }
                            </div>
                        )}
                    </Draggable>

                )
            }
        } else {
            result.push(
                <div className='logo-img' style={{ backgroundImage: "url(/images/logo-big.png)" }} />
            )
        }
        return result;
    }

    return (
        <div className={"contract-list"}>
            {/*{*/}
            {/*    selectedFolder &&*/}
            {/*    <div className='contract-list-header'>*/}
            {/*        <h1>{selectedFolder.name} Folder</h1>*/}
            {/*        <p>You have {contracts.length} contracts in {selectedFolder.name} Folder</p>*/}
            {/*    </div>*/}
            {/*}*/}

            {renderContracts()}

            <Modal
                open={openShareModal}
                onClose={handleCloseShareModal}
                className='confirm-modal-container'
            >
                <ShareForm
                    shareMode={SharedType.CONTRACT}
                    handleCloseModal={handleCloseShareModal}
                    selectedContracts={[contractToShare]}
                    folderContracts={folderContracts}
                />
            </Modal>

            <Modal
                open={openUnsubscriptionInfo}
                onClose={closeUnsubscriptionInfo}
                className='confirm-modal-container'
            >
                <UnsubscribeInfo
                    title={strings.modals.subscriptionDetails.unsubscribeInfoViewContracts.infoText}
                    value={false}
                    confirmBtn={strings.modals.subscriptionDetails.unsubscribeInfoViewContracts.infoBtn}
                    onClose={closeUnsubscriptionInfo}
                    handleConfirmModal={confirmUnsubscriptionInfo}
                    style='unsubscribe-info'
                    btnStyle='default-btn btn'
                />
            </Modal>
        </div>
    )
}

export default List;