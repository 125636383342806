import React, { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";
import styles from "../../../Admin/AdminAIData/AIData.module.css";
import strings from "../../../../localization";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  editQuestionAdmin,
  createQuestionAdmin,
  deleteQuestionAdmin,
} from "../../../../Services/DealRoom/AdminDealRoomService";

const QuestionRow = ({
  questionObj,
  sectionIndex,
  questionIndex,
  sectionRow,
  showMessage,
  getSectionListHandler,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [questionRow, setQuestionRow] = useState({});
  const [editMode, setEditMode] = useState(false);

  const saveQuestionHandler = () => {
    if (!questionRow?.question) {
      showMessage("Question can not be empty!", "error");
      return;
    }

    if (editMode) {
      editQuestionAdmin(questionRow)
        .then((res) => {
          if (res?.status === 204) {
            toggleIseditableHandler();
            showMessage("Question updated");
          } else {
            showMessage("Error in saving question", "error");
          }
        })
        .catch((err) => {
          console.log("error in editQuestionAdmin", err);
        });
    } else {
      let sectionId = sectionRow.id;
      let data = {
        question: questionRow.question,
        orderNumber: questionRow.orderNumber,
      };
      createQuestionAdmin(sectionId, data)
        .then((res) => {
          if (res?.status === 204) {
            getSectionListHandler();
            showMessage("New Question added");
          } else {
            showMessage("Error in saving question", "error");
          }
        })
        .catch((err) => {
          console.log("error in createQuestionAdmin", err);
        });
    }
  };

  const deleteQuestionRowHandler = () => {
    deleteQuestionAdmin(questionRow?.id)
      .then((res) => {
        if (res?.status === 204) {
          getSectionListHandler();
          handleClose();
          showMessage("Question deleted");
        } else {
          showMessage("Error in saving question", "error");
        }
      })
      .catch((err) => {
        console.log("error in deleteQuestionAdmin", err);
      });
  };

  const setFieldHandler = (e, orderNumber) => {
    let name = e.target.name;
    let value = e.target.value;

    let tempData = structuredClone(questionRow);
    tempData[name] = value;
    tempData["orderNumber"] = orderNumber;
    setQuestionRow(tempData);
  };

  const toggleIseditableHandler = () => {
    let tempQuestionRow = structuredClone(questionRow);
    tempQuestionRow.iseditable = !tempQuestionRow.iseditable;
    setQuestionRow(tempQuestionRow);
    setEditMode(!editMode);
    handleClose();
  };

  useEffect(() => {
    setQuestionRow(questionObj);
  }, [questionObj]);

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      sx={{
        fontFamily: "Nobile",
        padding: "1rem",
        width: "calc(100% - 2rem)",
        margin: "2rem 0rem",
      }}
    >
      <Box display={"flex"} width={"50%"} justifyContent={"flex-start"}>
        <div className="question-serial-number">
          {sectionIndex + 1}.{questionIndex + 1}
        </div>
        {questionRow?.iseditable ? (
          <>
            <div
              className={styles.inputContainer}
              style={{
                margin: "5px 10px",
                height: "max-content",
                width: "346px",
              }}
            >
              <input
                className={styles.inputBox}
                name="question"
                value={questionRow?.question}
                onChange={(e) => setFieldHandler(e, questionIndex + 1)}
                type="text"
              />
            </div>
            <div>
              <Button
                className="default-btn btn"
                variant="contained"
                sx={{ margin: "10px", textTransform: "none" }}
                onClick={saveQuestionHandler}
              >
                {editMode ? "Update" : "Save"}
              </Button>
            </div>
          </>
        ) : (
          <div
            style={{ display: "flex", alignItems: "center", fontWeight: "500" }}
          >
            {questionRow?.question}
            <Box ml={2}>
              <Tooltip
                title={strings.pages.adminDealRoom.editHeading}
                onClick={() => toggleIseditableHandler()}
              >
                <IconButton
                  className="item-title-icon"
                  style={{
                    width: "40px",
                    height: "40px",
                    boxShadow: "-6px -6px 10px #FFFFFF, 3px 2px 10px #D1D9E6",
                    borderRadius: "10px",
                    margin: "-9px 10px",
                  }}
                >
                  <img src="/images/icons/pen.png" alt="edit" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={strings.pages.adminDealRoom.deleteAccordion}
                onClick={() => deleteQuestionRowHandler()}
              >
                <IconButton
                  style={{
                    width: "40px",
                    height: "40px",
                    boxShadow: "-6px -6px 10px #FFFFFF, 3px 2px 10px #D1D9E6",
                    borderRadius: "10px",
                    margin: "-9px 10px",
                  }}
                >
                  <img src="/images/icons/delete.png" alt="delete" />
                </IconButton>
              </Tooltip>
            </Box>
          </div>
        )}
      </Box>
      <Box
        width={"50%"}
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      ></Box>
      <Box
        width={"20%"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      ></Box>
    </Box>
  );
};

export default QuestionRow;
