import React from "react";
import {useState} from "react";
import Button from "@mui/material/Button";
import strings from "../../localization";
import {useDropzone} from "react-dropzone";

const FileUploadComponent = (props) => {

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: props.accept,
        onDrop: files => onDrop(files),
    });
    const [files, setFiles] = useState([]);

    const onDrop = (files) =>
    {
        setFiles(files)
        if (props.onDrop) {
            props.onDrop(files)
        }
    }

    const  canUpload = () =>
    {
        return files && files.length > 0;
    }

    const renderFiles = () => {
        return files.map(file => (
            <li key={file.name}>
                {file.name} - {file.size} bytes
            </li>
        ));
    }

    return (
        <div className='file-upload-component'>
            <section className="drop-zone">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p> {props.placeholder ? props.placeholder : strings.components.fileUploadComponent.upload}</p>
                </div>
                { !props?.hideUploadButton &&
                    <div className='meta-data'>
                        <Button variant="contained" color="primary"
                                onClick={() => props.upload(files)}
                                disabled={!canUpload()}>
                        </Button>
                        <aside>
                            <h4>{strings.components.fileUploadComponent.files}</h4>
                            <ul>{renderFiles()}</ul>
                        </aside>
                    </div>
                }
            </section>
        </div>
    );

}
export default FileUploadComponent