import {request} from './HTTP';
import history from '../history';
import HttpMethod from "../Constants/Base/HttpMethod";

/** OAUTH **/

export async function login(username, password) {

    clearUserData();

    let data = {
        clientId: process.env.REACT_APP_CLIENT_ID,
        clientSecret: process.env.REACT_APP_CLIENT_SECRET,
        grantType: 'password',
        email: username,
        password: password
    };

    return await request('/api/auth/login', data, HttpMethod.POST).then((response) => {

            if (!response || !response.ok) {
                return response;
            }

            setTokenToLocalStorage(response.data, response.data);

            return request('/api/user').then((response) => {

                if(response.data) {
                    setUserToLocalStorage(response.data)
                }

                return response;
            });
        }
    );
}

export async function getCurrentUser() {
    return request('/api/user').then((response) => {

        if(response.data) {
            setUserToLocalStorage(response.data)
        }

        return response;
    });
}

export async function unlock(username, password) {

    clearUserDataLock();

    let data = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: 'password',
        username: username,
        password: password
    };

    return await request('/oauth/v2/token', data, HttpMethod.GET).then((response) => {

            if (!response.ok) {
                return response;
            }

            setTokenToLocalStorage(response.data.access_token, response.data.refresh_token);

            return response;
        }
    );
}

export function logout() {
    clearUserData();
    history.push("/");
}

export function lock() {
    clearUserDataLock();
    history.push("/");
}

/** LOCAL STORAGE  **/

export function setUserToLocalStorage(user) {
    localStorage.setItem('user', JSON.stringify(user));
}

export function getUserFromLocalStorage() {

    let user = localStorage.getItem('user');
    if(user === undefined) {
        logout();
    }
    return user ? JSON.parse(user) : null;
}

export function setTokenToLocalStorage(access_token, refresh_token) {
    localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, access_token);
    localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN_KEY, refresh_token);
}

export function getRefreshToken() {
    return localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_KEY);
}

export function getToken() {
    return localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
}

export function clearUserData() {
    localStorage.removeItem('user');
    clearUserDataLock();
}

export function clearUserDataLock() {
    localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
    localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN_KEY);
}

export function isUserLoggedIn() {
    return getUserFromLocalStorage() != null && getToken();
}

export function isUserLocked() {
    return getUserFromLocalStorage() && !getToken();
}
