import React, {useContext} from 'react';
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import TabPage from "../../../Components/DataPage/TabPage";
import TabPageContext, {TabPageOptionDefaults} from "../../../Context/TabPageContext";
import strings from '../../../localization';
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeHeaderTitle} from "../../../Slices/HeaderSlice";
import PageState from "../../../Constants/Base/PageState";
import {getAdminCategoryFilter, adminCategoryDefaultValue} from "../../../Constants/Admin/Category";
import Category  from './Category';
import {getContracts, getPackages} from "../../../Services/AdminFakeApi";
import {
    addContractCategory,
    getAllContractCategories
} from "../../../Services/ContractCategory/ContractCategoryService";
import AdminCategoryForm from "../../../Components/Forms/Pages/Admin/AdminCategoryForm/AdminCategoryForm";
import {useForm} from "react-hook-form";
import SnackbarContext from "../../../Context/SnackbarContext";

const filters = [
    {
        name: 'status',
        label: 'Active',
        options: getAdminCategoryFilter(),
        nameKey: 'name',
        valueKey: 'id',
        defaultValue: adminCategoryDefaultValue()
    }
]

const formRules = {
    'name': {required: true}
}

const AdminCategories = (props) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const { showMessage } = useContext(SnackbarContext);
    const dispatch = useDispatch();
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState({});
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tabPageOptions, setTabPageOptions] = useState(TabPageOptionDefaults);
    const [contractCategories, setContractCategories] = useState([]);
    const [selectedPackages, setSelectedPackages] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [openModal, setOpenModal] = useState(false);

    const value = {
        tabPageOptions, setTabPageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeHeaderTitle(strings.pages.adminCategories.title));
        fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    const fetch = () => {

        getAllContractCategories(filter).then(response => {

            if(!response || !response.ok) {
                return;
            }

            setContractCategories(response.data);
        });
    }

    const onFinish = () => {
        fetch();
    }

    const onSubmit = (data) => {

        setOpenModal(false)

        addContractCategory({
            ...data,
            active: true,
            packageIds: selectedPackages,
            categoryIds: selectedCategories
        }).then(response => {

            if(!response && !response.ok) {
                return;
            }

            showMessage(strings.common.added)

            fetch();
        })
    }

    const onPackagesChange = (packages) => {
        let result = []

        if(!packages) {
            return;
        }

        for(let pack of packages) {
            result.push(pack.id);
        }

        setSelectedPackages(result)
    }

    const onCategoriesChange = (categories) => {
        let result = []

        if(!categories) {
            return;
        }

        for(let category of categories) {
            result.push(category.id);
        }

        setSelectedCategories(result)
    }

    return (
        <TabPageContext.Provider value={value}>
            <TabPage 
                onFinish={() => onFinish()}
                filter={filter} 
                filters={filters}
                addBtnText={strings.pages.adminCategories.newCategory}
                formTitle={strings.forms.admin.adminCategoryForm.title}
                cancelBtn={strings.forms.admin.adminCategoryForm.cancel}
                createBtn={strings.forms.admin.adminCategoryForm.createCategory}
                openModal={openModal}
                setOpenModal={setOpenModal}
                onSubmit={handleSubmit(onSubmit)} 
                formComponent={
                    <AdminCategoryForm 
                        formRules={formRules}
                        values={getValues()}
                        setValue={setValue}
                        control={control}
                        errors={errors} 
                        data={data} 
                        form={form}
                        onSubmit={handleSubmit(onSubmit)}
                        onPackagesChange={onPackagesChange}
                        onCategoriesChange={onCategoriesChange}
                        categories={contractCategories}
                    />
                }
                tabData={
                    <Category
                        fetch={() => fetch()}
                        onFinish={() => onFinish()}
                        categories={contractCategories}
                        contracts={getContracts()}
                        packages={getPackages()}
                    />
                }
            />
        </TabPageContext.Provider>
    )
};

export default AdminCategories;

