import React, {useState, useEffect, useContext} from "react";
import {useForm, FormProvider} from "react-hook-form";
import {Button} from "@mui/material";
import Modal from "@mui/material/Modal";
import strings from "../../../../../localization";
import SelectControl from '../../../../Controls/Inputs/SelectControl';
import TextFieldControl from '../../../../Controls/Inputs/TextFieldControl';
import CreditCardField from '../../../../Controls/Inputs/CreditCardField';
import InfoModal from '../../../../Modals/InfoModal';
import {getBillingCycle, getBillingCycleDefault} from './../../../../../Constants/Dashboard/BillingCycle';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import {getAllPackages, getAllPackagesForSubscription} from "../../../../../Services/ContractCategory/PackageService";
import {useDispatch, useSelector} from "react-redux";
import {packageSubscribe, updatePackageValidation} from "../../../../../Services/Subscribe/SubscribeService";
import PackageFeatureType from "../../../../../Constants/Dashboard/PackageFeatureType";
import {getCurrentUser} from "../../../../../Base/OAuth";
import {setAuth} from "../../../../../Slices/AuthSlice";
import LoaderContext from "../../../../../Context/LoaderContext";
import navigation from "../../../../Layout/Navigation";
import {useNavigate} from "react-router-dom";
import { dateInPast, dateToString, getDifferentBetweenDateInDays } from "../../../../../Util/DateUtil";
import SnackbarContext from "../../../../../Context/SnackbarContext";
import { useStripe } from "@stripe/react-stripe-js";

const vat = 10;

const BuyPackageForm = ({pack, setShowPackList, setShowPackForm, setPaidPack, paidPack, isAnnual}) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, watch, setValue, formState: {errors}} = form;
    const [errorCard, setErrorCard] = useState(false)
    const [errorFullName, setErrorFullName] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [payInfoModal, setPayInfoModal] = useState(false)
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const user = useSelector((state) => state.auth.user)
    const dispatch = useDispatch();
    const {showLoader, setLoaderTitle} = useContext(LoaderContext);
    const navigate = useNavigate();
    const [upgradePrice, setUpgradePrice] = useState(null);
    const [isDowngrade, setIsDowngrade] = useState(false);
    const {showMessage} = useContext(SnackbarContext);

    const stripe = useStripe();

    setLoaderTitle('')

    useEffect(() => {
        fetchPackages();
        setValue('fullName', user.fullName)
        setValue('email', user.email);
    }, [])

    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if(value.package) {
                setSelectedPackage(value.package)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const fetchPackages = () => {

        getAllPackagesForSubscription({}).then(response => {

            if (!response || !response.ok) {
                return;
            }

            const packages = transformPackages(response.data.filter(e => e.package.active === true));
            setPackages(packages)

            if (pack) {
                setSelectedPackage( packages.find(x => x.id === (pack.id + (isAnnual ? '-2' : '-1'))))
            }

        })
    }

    const transformPackages = (packages) => {

        let result = [];

        for (let item of packages) {

            if(pack.id !== item.package.id) {
                continue
            }

            result.push({
                ...item.package,
                features: item.features,
                id: item.package.id + '-1',
                name: 'Monthly',
                interval: 'month',
            })

            result.push({
                ...item.package,
                features: item.features,
                id: item.package.id + '-2',
                name: 'Yearly',
                interval: 'year',
                price: item.package.annualPrice
            })
        }

        return result;
    }

    const renderFeatures = () => {

        if (!selectedPackage) {
            return ''
        }

        const filtered = selectedPackage.features.filter(x => x.type === PackageFeatureType.REGULAR)

        let result = []

        for (let feature of filtered) {
            result.push(
                <div className="feature" key={'feature-' + feature.id}>
                    <CheckIcon className="icon check-icon"/>
                    <p className="text">{feature.content}</p>
                </div>
            )
        }

        return result
    }

    const renderAdditionalFeatures = () => {

        if (!selectedPackage) {
            return ''
        }

        const filtered = selectedPackage.features.filter(x => x.type === PackageFeatureType.ADDITIONAL)

        let result = []

        for (let feature of filtered) {
            result.push(
                <div className="feature" key={'feature-' + feature.id}>
                    <CheckIcon className="icon check-icon"/>
                    <p className="text">{feature.content}</p>
                </div>
            )
        }

        return result
    }

    const onSubmitValidation = (data) => {

        if(!user.company.package) {
            onSubmit(data)
            return
        }

        let errorExists = false;

        if (data.creditCard == null
            || data.creditCard.number.length != 19
            || data.creditCard.expiry.length != 7
            || data.creditCard.cvc.length != 3) {
            setErrorCard(true)
            errorExists = true;
        } else {
            setErrorCard(false)
        }

        if (data.email == undefined || data.email == '') {
            setErrorEmail(true)
            errorExists = true;
        } else {
            setErrorEmail(false)
        }

        if (data.fullName == undefined || data.fullName == '') {
            setErrorFullName(true)
            errorExists = true;
        } else {
            setErrorFullName(false)
        }

        if(!errorExists) {
            onSubmit(data)
        }
    }

    const onSubmit = (data) => {
        
        showLoader(true);

        const expMonth = data.creditCard?.expiry?.split('/')[0].trim()
        const expYear = data.creditCard?.expiry?.split('/')[1].trim()

        packageSubscribe({
            card: data.creditCard?.number,
            expMonth: expMonth,
            expYear: expYear,
            cvc: data.creditCard?.cvc,
            packageId: selectedPackage.id.split('-')[0],
            interval: selectedPackage.interval
        }).then(async (response) => {

            showLoader(false);

            if(!response || !response.ok) {
                showMessage('Error subscribe in to package', 'error');
                return;
            }
            
            showLoader(true);

            try {
                const confirmPayment = await stripe.confirmCardPayment(response.data.paymentIntent.clientSecret);
                const { paymentIntent } = confirmPayment;
                                
                if(confirmPayment) {
                    updatePackageValidation({card: data.creditCard?.number, downgrade: response.data?.downgrade ? true : false}).then(packageValidationResponse => {
                        getCurrentUser().then(response => {

                            showLoader(false);

                            if(!packageValidationResponse || !packageValidationResponse.ok) {
                                showMessage("Error subscribe in to package", 'error');
                            } else {
                                setPayInfoModal(true)
                                setPaidPack(selectedPackage.id)
                            }

                            dispatch(setAuth(response.data));
                        })
                    });
                } 
            } catch (err) {
                updatePackageValidation({card: data.creditCard?.number, downgrade: response.data?.downgrade ? true : false}).then(packageValidationResponse => {
                    getCurrentUser().then(response => {

                        showLoader(false);

                        if(!packageValidationResponse || !packageValidationResponse.ok) {
                            showMessage("Error subscribe in to package", 'error');
                        } else {
                            setPayInfoModal(true)
                            setPaidPack(selectedPackage.id)
                        }

                        dispatch(setAuth(response.data));
                    })
                });
            } 
        });
    }

    const handleCancel = () => {
        setShowPackList(true)
        setShowPackForm(false)
        setPayInfoModal(false)
        navigate('/')
    }

    const handleRenewal = () => {
        setPaidPack(-1)
    }

    const canUpgrade = (user, pack) => {
        const userPackage = user.company?.package;

        if (!userPackage || !pack) {
            return false
        }

        if(dateInPast(new Date(user.company.packageValidUntil)) || user.company?.isTrial || !user.company?.packageValid) {
            return false
        }

        if(userPackage.price < pack.price) {
            let price = calculateUpgradePrice(user, pack);
            if(!upgradePrice) {
                setUpgradePrice(price);
            }
            return true;
        }

        if(!isDowngrade && userPackage.id !== parseInt(pack.id.split('-')[0])) {
            setIsDowngrade(true);
        }

        return false;

    }

    const calculateUpgradePrice = (user, pack) => {
        const userPackage = user.company?.package;

        if (!userPackage || !pack) {
            return false
        }

        let price = pack.price;
        
        if(!dateInPast(new Date(user.company?.packageValidUntil))) {
            const pricePerDay = (pack.price - userPackage.price) / 30;
            const daysLeft = getDifferentBetweenDateInDays(new Date(), new Date(user.company?.packageValidUntil));
            price = (pricePerDay.toFixed(2) * daysLeft).toFixed(2);
        }

        return price;
    }

    const calculateVat = (price, precent) => {
        return price === 0 ? 0 : ((precent / 100) * parseFloat(price)).toFixed(2);
    }

    return (


        <div id="dashboard" className="dashboard pricing-and-packages">
            <FormProvider {...form}>
                <div id="dashboard" className="dashboard buy-package-form">

                    <div className="left ">

                        <div className="dashboard-box your-package-box">
                            <h1 className="header">{strings.forms.dashboard.pricingAndPackage.buyPackageForm.yourPackage}</h1>
                            <h2 className="title">{pack.name}</h2>

                            <div className="field-container">
                                <span
                                    className="label">{strings.forms.dashboard.pricingAndPackage.buyPackageForm.billingCycle}</span>
                                {
                                    selectedPackage &&
                                    <SelectControl
                                        name='package'
                                        options={packages}
                                        setValue={setValue}
                                        defaultValue={selectedPackage}
                                        nameKey='name'
                                        valueKey='id'
                                        className="select-control-container"
                                    />
                                }

                            </div>
                            {/* <div className="price-breakdown">
                            <span className="label">{strings.forms.dashboard.pricingAndPackage.buyPackageForm.priceBreakdown}</span>
                            <p>{pack.priceCurrency}{pack.priceBreakDown}
                                <span className="bold-uppercase">{strings.constants.dashboard.pricingAndPackages.annualSavingsMissed}:</span>
                                <span className="bold-uppercase">{pack.priceCurrency}{pack.annualSavingsMissed}</span>
                            </p>
                        </div> */}
                        </div>

                        <div className="dashboard-box user-details">
                            <h1 className="header">{strings.forms.dashboard.pricingAndPackage.buyPackageForm.userDetails}</h1>

                            <div className="item-field-container">
                            <span className="info-icon-text">
                                {strings.forms.dashboard.pricingAndPackage.buyPackageForm.name}
                            </span>
                                <TextFieldControl
                                    name='fullName'
                                    control={data}
                                    fullWidth
                                    margin="normal"
                                    error={Boolean(errors.fullName)}
                                    helperText={errors.fullName && strings.forms.common.required}
                                    placeholder={strings.forms.dashboard.pricingAndPackage.buyPackageForm.enterName}
                                />
                                {errorFullName &&
                                <span className="error-msg">{strings.forms.common.required}</span>
                                }
                            </div>

                            <div className="item-field-container">
                            <span className="info-icon-text">
                                {strings.forms.dashboard.pricingAndPackage.buyPackageForm.email}
                            </span>
                                <TextFieldControl
                                    name='email'
                                    control={data}
                                    fullWidth
                                    margin="normal"
                                    error={Boolean(errors.email)}
                                    helperText={errors.email && strings.forms.common.required}
                                    placeholder={strings.forms.dashboard.pricingAndPackage.buyPackageForm.enterEmail}
                                />
                                {errorEmail &&
                                <span className="error-msg">{strings.forms.common.required}</span>
                                }
                            </div>
                            <div className="item-field-container">
                            <span className="info-icon-text">
                                {strings.forms.dashboard.pricingAndPackage.buyPackageForm.card}
                            </span>
                                {/* <CardElement onChange={setCardDetails} options={cardElementOptions} className="credit-card-custom"/> */}

                                <CreditCardField
                                    name='creditCard'
                                    control={data}
                                    setValue={setValue}
                                />
                                { errorCard &&
                                    <span className="error-msg">{strings.forms.common.required}</span>}
                            </div>

                        </div>
                    </div>

                    <div className="right dashboard-box">
                        <h1 className="header">{strings.forms.dashboard.pricingAndPackage.buyPackageForm.yourPlanIncludes}</h1>

                        <div className="features-container">
                            <h3 className="title">{pack.featuresTitle}</h3>
                            {renderFeatures()}
                        </div>

                        <div className="features-container">
                            <h3 className="title">{pack.additionalFeaturesTitle}</h3>
                            {renderAdditionalFeatures()}
                        </div>

                        {
                            selectedPackage &&
                            <div className="summary-container">
                                <h3 className="title">{strings.forms.dashboard.pricingAndPackage.buyPackageForm.orderSummary}</h3>

                                <div className="text-row">
                                    <span className="label">{selectedPackage.name}</span>
                                    <span>{pack.currency}{canUpgrade(user, selectedPackage) && selectedPackage.interval === 'month' ? upgradePrice : isDowngrade ? 0 : parseFloat(selectedPackage.price).toFixed(2)}</span>
                                </div>
                                <div className="text-row">
                                    <span
                                        className="label">{strings.forms.dashboard.pricingAndPackage.buyPackageForm.vat}</span>
                                        <span>{pack.currency}{canUpgrade(user, selectedPackage) && selectedPackage.interval === 'month' ? calculateVat(upgradePrice, 20) : isDowngrade ? 0 : calculateVat(selectedPackage?.price, 20)}</span>
                                </div>

                                <div className="item-field-container code-filed-container">
                                <span className="info-icon-text">
                                    {strings.forms.dashboard.pricingAndPackage.buyPackageForm.referralCode}
                                </span>
                                    <TextFieldControl
                                        name='discountCode'
                                        control={data}
                                        margin="normal"
                                        error={Boolean(errors.discountCode)}
                                        helperText={errors.discountCode}
                                        placeholder={strings.forms.dashboard.pricingAndPackage.buyPackageForm.enterReferralCode}
                                    />
                                </div>

                                <div className="oder-total-container">
                                    <span
                                        className="bold-text">{strings.forms.dashboard.pricingAndPackage.buyPackageForm.orderTotal}</span>
                                    <span className="bold-text">{pack.currency}{upgradePrice && selectedPackage.interval === 'month' ? (parseFloat(upgradePrice) + parseFloat(calculateVat(upgradePrice, 20))).toFixed(2) : isDowngrade ? 0 : (parseFloat(selectedPackage.price) + parseFloat(calculateVat(selectedPackage.price, 20))).toFixed(2)}</span>
                                </div>

                                <div className="submit-container">
                                    <Button className="secondary-neutral-btn btn" variant="contained"
                                            onClick={handleCancel}>{strings.forms.dashboard.pricingAndPackage.buyPackageForm.cancel}</Button>
                                    <Button className="secondary-btn btn" variant="contained"
                                            onClick={handleSubmit(onSubmitValidation)}>{strings.forms.dashboard.pricingAndPackage.buyPackageForm.pay}</Button>
                                </div>
                            </div>
                        }


                        <Modal
                            open={payInfoModal}
                            onClose={handleCancel}
                            className='confirm-modal-container'
                        >
                            <InfoModal
                                title={ !isDowngrade ? (user.company.isTrial ? 'Trial has started for ' : strings.modals.pricingAndPackage.buyPackage.infoText) + user.company?.package?.name : "You have subscribed to " + user.company?.package?.name + strings.modals.pricingAndPackage.downgradedPackage.infoText}
                                cancelBtn={strings.modals.pricingAndPackage.buyPackage.infoBtn}
                                handleCloseModal={handleCancel}
                                style='buy-package-info'
                                btnStyle='default-btn btn'
                            />
                        </Modal>

                    </div>
                </div>

            </FormProvider>
        </div>
    )
}

export default BuyPackageForm;

