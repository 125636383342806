import React, { useRef, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, IconButton, TextField } from "@mui/material";
import NorthOutlinedIcon from "@mui/icons-material/NorthOutlined";
import Person2Icon from "@mui/icons-material/Person2";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import RemoveIcon from "@mui/icons-material/Remove";
import FavoriteIcon from "@mui/icons-material/Favorite";
import InputAdornment from "@mui/material/InputAdornment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { commentTimestamp } from "../../../../Util/DateUtil";
import {
  likeComment,
  unlikeComment,
  getAllComments,
  postNewComment,
  replyOnComment,
} from "../../../../Services/DealRoom/DealRoomService";
import SnackbarContext from "../../../../Context/SnackbarContext";

const OverviewCommentSection = ({ user }) => {
  const { showMessage } = useContext(SnackbarContext);
  const params = useParams();
  const dealRoomId = params?.id;
  const [selectedCommentThread, setSelectedCommentThread] = useState(null);
  const [commentInputValue, setCommentInputValue] = useState("");
  const [commentsList, setCommentsList] = useState([]);

  const commentInputRef = useRef(null);

  useEffect(() => {
    getAllCommentsHandler();
  }, []);

  const handleCommentInputChange = (event) => {
    setCommentInputValue(event.target.value);
  };

  const getAllCommentsHandler = () => {
    getAllComments(dealRoomId)
      .then((res) => {
        if (res.status === 200) {
          setCommentsList(res.data);
        } else {
          showMessage("Error in Fetching comments", "error");
        }
      })
      .catch((err) => {
        console.log("error in getAllComments", err);
      });
  };

  const postNewCommentHandler = () => {
    if (commentInputValue.trim().length === 0) {
      showMessage("Comment can not be empty", "error");
      return;
    }
    postNewComment(dealRoomId, { content: commentInputValue })
      .then((res) => {
        if (res.status === 204) {
          getAllCommentsHandler();
        } else {
          showMessage("Error in Posting comment", "error");
        }
      })
      .catch((err) => {
        console.log("error in postNewComment", err);
      });
    setCommentInputValue("");
  };

  const replyToCommentHandler = () => {
    if (commentInputValue.trim().length === 0) {
      showMessage("Comment can not be empty", "error");
      return;
    }
    replyOnComment(dealRoomId, selectedCommentThread.id, {
      content: commentInputValue,
    })
      .then((res) => {
        if (res.status === 204) {
          getAllCommentsHandler();
        } else {
          showMessage("Error in Replying comment", "error");
        }
      })
      .catch((err) => {
        console.log("error in replyOnComment", err);
      });
    setCommentInputValue("");
    setSelectedCommentThread(null);
  };

  const likeCommentHandler = (commentId) => {
    likeComment(dealRoomId, commentId)
      .then((res) => {
        console.log("res likeComment", res);
        if (res.status === 204) {
          getAllCommentsHandler();
          showMessage("Comment Liked");
        } else {
          showMessage("Error in Like comment", "error");
        }
      })
      .catch((err) => {
        console.log("error in likeComment", err);
      });
  };

  const unlikeCommentHandler = (commentId) => {
    unlikeComment(dealRoomId, commentId)
      .then((res) => {
        if (res.status === 204) {
          getAllCommentsHandler();
          showMessage("Comment Unliked");
        } else {
          showMessage("Error in removing like", "error");
        }
      })
      .catch((err) => {
        console.log("error in unlikeComment", err);
      });
  };

  const RenderComments = ({ comment, index }) => {
    const [isCommentLiked, setIsCommentLiked] = useState(
      comment?.likes?.some((item) => item.userId === user.id)
    );
    const [openViewReplies, setOpenViewReplies] = useState(false);

    return (
      <Box
        p={2}
        display="flex"
        flexDirection="row"
        sx={
          selectedCommentThread?.id === comment?.id
            ? {
                backgroundColor: "#e4fff9",
                width: "calc(100% - 2rem)",
                borderBottom: "1px solid #ececec",
              }
            : {
                width: "calc(100% - 2rem)",
                borderBottom: "1px solid #ececec",
              }
        }
        key={comment?.id}
      >
        <Box sx={{ width: "15%" }}>
          <Box
            className={"comment-profile-wrapper"}
            sx={{
              background: "#c8fcf1",
            }}
          >
            <Person2Icon
              sx={{
                fontSize: "1.5rem",
                color: "#82F5DE",
              }}
            />
          </Box>
        </Box>
        <Box sx={{ width: "calc(85% - 1rem)", padding: "0 0.5rem" }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "500", wordBreak: "break-all" }}
                mr={1}
              >
                {comment?.fullName === null && comment?.isNotification
                  ? "Logical Contracts"
                  : comment.fullName}
              </Typography>
              <Typography variant="body2" color={"#8b8b8b"}>
                {commentTimestamp(comment?.dateCreated)}
              </Typography>
            </Box>
            {!comment?.isNotification && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {isCommentLiked ? (
                  <IconButton
                    sx={{ color: "red" }}
                    onClick={() => {
                      unlikeCommentHandler(comment?.id);
                    }}
                  >
                    <FavoriteIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ color: "#8b8b8b", paddingBottom: "0px !important" }}
                    onClick={() => {
                      likeCommentHandler(comment?.id);
                      setIsCommentLiked(true);
                    }}
                  >
                    <FavoriteBorderIcon />
                  </IconButton>
                )}
                {comment?.likesCount > 0 && (
                  <Typography>{comment?.likesCount}</Typography>
                )}
              </Box>
            )}
          </Box>
          <Box mt={1}>
            <Typography color={"#8b8b8b"}>{comment?.content}</Typography>
          </Box>
          {!comment?.isNotification && (
            <Box mt={2}>
              <Typography
                color="#3D32CF"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedCommentThread(comment);
                  commentInputRef.current.focus();
                }}
              >
                Reply
              </Typography>
            </Box>
          )}
          {comment?.replies?.length > 0 && (
            <Typography
              mt={2}
              display={"flex"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenViewReplies(!openViewReplies)}
            >
              <RemoveIcon /> &nbsp;{" "}
              {openViewReplies ? "Hide replies" : "View replies"}
            </Typography>
          )}
          {comment?.replies?.length > 0 &&
            openViewReplies &&
            comment?.replies?.map((repliedComment, index) => (
              <Box
                p={2}
                display="flex"
                flexDirection="row"
                sx={
                  comment?.replies.length > 1 &&
                  comment?.replies?.length - 1 !== index
                    ? {
                        borderBottom: "1px solid #ececec",
                        width: "calc(100% - 2rem)",
                      }
                    : {
                        width: "calc(100% - 2rem)",
                      }
                }
                key={repliedComment?.userId}
              >
                <Box sx={{ width: "15%" }}>
                  <Box
                    className={"comment-profile-wrapper"}
                    sx={{
                      background: "#c8fcf1",
                      width: "1rem",
                      height: "1rem",
                    }}
                  >
                    <Person2Icon
                      sx={{
                        fontSize: "1rem",
                        color: "#82F5DE",
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "calc(85% - 1rem)", padding: "0 0.5rem" }}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box display={"flex"}>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "500", wordBreak: "break-all" }}
                        mr={1}
                      >
                        {repliedComment?.fullName}
                      </Typography>
                      <Typography variant="body2" color={"#8b8b8b"}>
                        {commentTimestamp(repliedComment?.dateCreated)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={1}>
                    <Typography color={"#8b8b8b"}>
                      {repliedComment?.content}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <Typography variant="h5" p={2}>
          Comments
        </Typography>
        {commentsList?.map((comment, index) => (
          <RenderComments comment={comment} index={index} key={index} />
        ))}
      </Box>

      <Box p={2} display="flex" flexDirection="column">
        {selectedCommentThread && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="caption">
              Replying to {selectedCommentThread?.fullName}
            </Typography>
            <IconButton onClick={() => setSelectedCommentThread(null)}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
        )}
        <TextField
          inputRef={commentInputRef}
          variant="standard"
          hiddenLabel
          placeholder="Write a message..."
          className="overview-comment-textfield"
          value={commentInputValue}
          onChange={handleCommentInputChange}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              selectedCommentThread
                ? replyToCommentHandler()
                : postNewCommentHandler();
            }
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment>
                <IconButton
                  sx={{
                    background: "#3D32CF",
                    "&:hover": {
                      background: "#3D32CF",
                    },
                  }}
                  onClick={() => {
                    selectedCommentThread
                      ? replyToCommentHandler()
                      : postNewCommentHandler();
                  }}
                >
                  <NorthOutlinedIcon sx={{ color: "white" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default OverviewCommentSection;
