import React, {useContext} from 'react';
import {useForm} from "react-hook-form";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import TabPage from "../../../Components/DataPage/TabPage";
import TabPageContext, {
    FilterDefaults,
    TabPageOptionDefaults
} from "../../../Context/TabPageContext";
import strings from '../../../localization';
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeHeaderTitle} from "../../../Slices/HeaderSlice";
import PageState from "../../../Constants/Base/PageState";
import Package  from './Package';
import AdminPackageForm from "../../../Components/Forms/Pages/Admin/AdminPackageForm/AdminPackageForm";
import {addPackage, getAllPackages} from "../../../Services/ContractCategory/PackageService";
import {adminPackageDefaultValue, getAdminPackageFilter} from "../../../Constants/Admin/Package";
import SnackbarContext from "../../../Context/SnackbarContext";

const filters = [
    {
        name: 'status',
        label: 'Active',
        options: getAdminPackageFilter(),
        nameKey: 'name',
        valueKey: 'id',
        defaultValue: adminPackageDefaultValue()
    }
]

const formRules = {
    'name': {required: true},
    'price': {required: true}
}

const AdminPackages = (props) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const dispatch = useDispatch();
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tabPageOptions, setTabPageOptions] = useState(TabPageOptionDefaults);
    const [packages, setPackages] = useState([]);
    const [userData, setUserData] = useState({})
    const [categories, setCategories] = useState({})
    const [contracts, setContracts] = useState({})
    const [featuresFieldsNames, setFeaturesFieldsNames] = useState([])
    const [additionalFeaturesFieldsNames, setAdditionalFeaturesFieldsNames] = useState([])
    const [icon, setIcon] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const value = {
        tabPageOptions, setTabPageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog
    }

    const { showMessage } = useContext(SnackbarContext);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeHeaderTitle(strings.pages.adminCategories.title));
        fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    useEffect(() => {
        if(!openModal) {
            form.reset();
        }
    }, [openModal])

    const fetch = () => {
        getAllPackages(filter).then(response => {

            if(!response || !response.ok) {
                setPackages([])
                return;
            }

            setPackages(response.data);
        })
    }

    const onFinish = () => {
        fetch();
    }

    const onSubmit = (data) => {

        setOpenModal(false)

        let features = []

        for (let el of featuresFieldsNames) {
            if (data[el] != '') {
                features.push(data[el])
            }
        }
        
        let additionalFeatures = []

        for (let el of additionalFeaturesFieldsNames) {
            if (data[el] != '') {
                additionalFeatures.push(data[el])
            }
        }

        let packEl = {
            icon: icon,
            name: data.name,
            color: data.color,
            description: data.description,
            featureTitle: data.featureTitle,
            features: features,
            additionalFeatureTitle: data.additionalFeatureTitle,
            additionalFeatures: additionalFeatures,
            maxNumberOfUsers: data.maxNumberOfUsers ? parseInt(data.maxNumberOfUsers) : -1,
            userIds: userData.users,
            categoryIds: categories,
            price: parseFloat(data.price),
            annualPrice: parseFloat(data.annualPrice),
            onceOf: data.onceOf,
            recommended: data.recommended,
            templateIds: contracts,
            currency: data.currency,
            active: true,
            packageType: userData.packageType,
        }

        addPackage(packEl).then(response => {

            if(!response || !response.ok) {
                return;
            }

            showMessage(strings.common.added)
            fetch();
        })
    }

    const onUserDataChanged = (data) => {
        setUserData(data)
    }

    const onCategoryChanged = (ids) => {
        let result = [];

        for(let id of ids) {
            // if(categories.indexOf(id) !== -1) {
            //     continue
            // }

            result.push(id)
        }

        setCategories(result)
    }

    const onContractChanged = (ids) => {
        let result = []

        for (let id of ids) {
            result.push(id)
        }

        setContracts(result)
    }

    return (
        <TabPageContext.Provider value={value}>
            <TabPage 
                onFinish={() => onFinish()} 
                filter={filter} 
                filters={filters}
                addBtnText={strings.pages.adminPackages.newPackages}
                formTitle={strings.forms.admin.adminPackageForm.title}
                cancelBtn={strings.forms.admin.adminPackageForm.cancel}
                createBtn={strings.forms.admin.adminPackageForm.createPackage}
                openModal={openModal}
                setOpenModal={setOpenModal}
                onSubmit={handleSubmit(onSubmit)} 
                formComponent={
                    <AdminPackageForm 
                        formRules={formRules}
                        values={getValues()}
                        setValue={setValue}
                        getValues={getValues}
                        control={control}
                        errors={errors} 
                        data={data} 
                        form={form}
                        onSubmit={handleSubmit(onSubmit)}
                        onUserDataChanged={onUserDataChanged}
                        fetch={fetch}
                        onCategoryChanged={onCategoryChanged}
                        onContractChanged={onContractChanged}
                        setFeaturesFieldsNames={setFeaturesFieldsNames}
                        setAdditionalFeaturesFieldsNames={setAdditionalFeaturesFieldsNames}
                        icon={icon}
                        setIcon={setIcon}
                    />
                }
                tabData={
                    <Package 
                        onFinish={() => onFinish()}
                        packages={packages}
                        data={data}
                        fetch={() => fetch()}
                    />
                }
            />
        </TabPageContext.Provider>
    )
};

export default AdminPackages;
