import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './AIData.module.css';
import { aiDataActions } from '../../../Slices/AiDataSlice';

function AIRequestDataViewComponent({ choiceBoxName, onChangeInputHandler }) {
  const dispatch = useDispatch()
  const aiRequestDataRow = useSelector(state => state.aiData.aiRequestRow);

  const inputFieldHandler = (e, tempName) => {
    let name = e.target.name
    let value = e.target.value
    if (choiceBoxName) {

      if (tempName === "response") {
        name = tempName
        value = e.currentTarget.innerHTML
      }

      dispatch(aiDataActions.setAiRequestInput({ name: name, value: value }))
    }
  }

  return (
    <div className={styles.AIDataCreate2ndCol} style={{ width: '70%', margin: '2rem 10rem' }}>
      <div className={styles.whiteBox}>
        {choiceBoxName &&
          (<div className={styles.formRow}>
            <div className={styles.filledBox}>
              <div className={styles.labelText}>
                <span>Why do you want to report this data</span>
              </div>
              <div className={[styles.inputContainer]}>
                <select
                  name="report"
                  onChange={(e) => inputFieldHandler(e)}
                  className={styles.inputBox}
                  type="text"
                  value={aiRequestDataRow?.report}
                  style={{
                    WebkitAppearance: "none",
                    background: `url(/images/icons/downArrow.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right center",
                  }}
                >
                  <option value='null'>None</option>
                  <option value='Harmful'>Harmful</option>
                </select>
                {/* <ArrowDropDown /> */}
              </div>
            </div>
          </div>)
        }
        <div className={styles.formRow}>
          <div className={styles.reqQuestionBox}>
            <div className={styles.labelText}>
              <span >Question asked by user</span>
            </div>
            <div className={styles.formRow}>
              <div className={styles.inputContainer}>
                <textarea className={styles.textarea} onChange={(e) => inputFieldHandler(e)} name='request' type="text" value={aiRequestDataRow?.request} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.reqQuestionBox}>
            <div className={styles.labelText}>
              <span >AI Response</span>
            </div>
            <div className={styles.formRow}>
              <div>
              </div>
              <div className={styles.inputContainer}>
                <div
                  contentEditable={choiceBoxName ? true : false}
                  dangerouslySetInnerHTML={{ __html: aiRequestDataRow?.response }}
                  // onChange={(e) => inputFieldHandler(e)}
                  className={styles.textarea}
                  onBlur={(e) => inputFieldHandler(e, "response")}
                  name='response'
                  type="text"
                // value={aiRequestDataRow?.response}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AIRequestDataViewComponent;