import {FormProvider, useForm} from "react-hook-form";
import strings from "../../localization";

import Button from "@mui/material/Button";
import React, {useContext, useEffect, useState} from "react";
import ValidationPatterns from "../../Constants/Base/ValidationPatterns";
import {useSelector} from "react-redux";
import {getAllFolders} from "../../Services/Folder/FolderService";
import TextFieldPasswordControl from "../../Components/Controls/Inputs/TextFieldPasswordControl";
import {changeUserPassword} from "../../Services/User/UserService";
import SnackbarContext from "../../Context/SnackbarContext";

const formRules = {
    'password': {pattern: { value: ValidationPatterns.PASSWORD,
            message: strings.forms.dashboard.myDetails.generalInformationForm.passwordError}},
    'newPassword': {pattern: { value: ValidationPatterns.PASSWORD,
            message: strings.forms.dashboard.myDetails.generalInformationForm.passwordError}},
    'confirmNewPassword': {pattern: { value: ValidationPatterns.PASSWORD,
            message: strings.forms.dashboard.myDetails.generalInformationForm.passwordError}},
}

const ChangePassword = () => {

    const user = useSelector((state) => state.auth.user)

    const form = useForm();
    const {data, setValue, getValues, control, handleSubmit, formState: {errors}, setError, trigger} = form;
    const {showMessage} = useContext(SnackbarContext);

    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
    }

    const onSubmit = (data) => {

        changeUserPassword({
            oldPassword: data.password,
            password: data.newPassword
        }).then(response => {
            if(!response || !response.ok) {
                showMessage('Error changing password', 'error');
                return;
            }

            showMessage('Password changed');
        });
    }


    return ( <FormProvider {...form}>
            <h1 className="header" style={{marginBottom: '10px'}}>Update Password</h1>
            <div className="dashboard-item">
                <div className="item-field-container">
                    <span className="info-icon-text">
                        {strings.forms.dashboard.myDetails.generalInformationForm.currentPassword}
                    </span>
                    <TextFieldPasswordControl
                        name='password'
                        control={data}
                        rules={formRules['password']}
                        defaultValue=''
                        fullWidth
                        type='password'
                        margin="normal"
                        error={Boolean(errors.password)}
                        helperText={errors.password && errors.password.message}
                        placeholder={strings.forms.dashboard.myDetails.generalInformationForm.currentPassword}
                    />
                </div>

                <div className="item-field-container">
                    <span className="info-icon-text">
                        {strings.forms.dashboard.myDetails.generalInformationForm.newPassword}
                    </span>
                    <TextFieldPasswordControl
                        name='newPassword'
                        control={data}
                        rules={formRules['newPassword']}
                        fullWidth
                        type='password'
                        margin="normal"
                        error={Boolean(errors.newPassword)}
                        helperText={errors.newPassword && errors.newPassword.message}
                        placeholder={strings.forms.dashboard.myDetails.generalInformationForm.enterNewPassword}
                    />
                </div>

                <div className="item-field-container">
                    <span className="info-icon-text">
                        {strings.forms.dashboard.myDetails.generalInformationForm.confirmNewPassword}
                    </span>
                    <TextFieldPasswordControl
                        name='confirmNewPassword'
                        control={data}
                        rules={formRules['confirmNewPassword']}
                        fullWidth
                        type='password'
                        margin="normal"
                        error={Boolean(errors.confirmNewPassword)}
                        helperText={errors.confirmNewPassword && errors.confirmNewPassword.message}
                        placeholder={strings.forms.dashboard.myDetails.generalInformationForm.confirmNewPassword}
                    />
                </div>
            </div>

            <div className="submit-container">
                <Button className="secondary-btn btn" onClick={handleSubmit(onSubmit)}>
                    {strings.forms.dashboard.myDetails.generalInformationForm.save}</Button>
            </div>
        </FormProvider>
    )
}

export default ChangePassword;