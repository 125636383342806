import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Box, Button, IconButton } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import styles from "../DealRoom.module.css";

const DocPreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state: resData } = location;
  const [numPages, setNumPages] = useState(null);
  const [pdfContent, setPdfContent] = useState(resData);
  const [scale, setScale] = useState(1.0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box className={styles.dealroomDocPreview}>
      <Box width={"100%"} display={"flex"} alignItems={"center"}>
        <Box width={"45%"}>
          <Button
            className={styles.dealroomDocPreviewBackButton}
            onClick={handleGoBack}
          >
            Back
          </Button>
        </Box>
        <Box width={"55%"}>
          <IconButton onClick={handleZoomIn}>
            <ZoomInIcon sx={{ color: "#0d99ff", fontSize: "2rem" }} />
          </IconButton>
          <IconButton onClick={handleZoomOut}>
            <ZoomOutIcon sx={{ color: "#0d99ff", fontSize: "2rem" }} />
          </IconButton>
        </Box>
      </Box>
      <Box mt={4}>
        <Document file={pdfContent} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={scale}
            />
          ))}
        </Document>
      </Box>
    </Box>
  );
};

export default DocPreview;
