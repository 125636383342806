import React from "react";
import { jsPDF } from "jspdf";

function CreateInvoicePdf({ invoice }) {
  const epohConvertor = (epochTime) => {
    const milliseconds = epochTime * 1000;
    const dateObject = new Date(milliseconds);
    return dateObject;
  };

  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  const smallPara = 8;
  const para = 10;
  const subHeading = 12;
  const heading = 15;
  const poundSign = "£";

  const DownloadPdf = () => {
    // Section 1: Invoice Heading and Details
    pdf.setFontSize(heading);
    pdf.setFont("bold");
    pdf.text("Invoice", 15, 15);

    pdf.setFontSize(para);
    pdf.text("Invoice number", 15, 25);
    pdf.text("Invoice number", 15, 25);
    pdf.text(invoice.number, 40, 25);
    pdf.text(invoice.number, 40, 25);
    pdf.setFont("");
    pdf.text("Date of issue", 15, 30);
    let dateIssue = new Date(invoice?.created);
    pdf.text(
      `${
        dateIssue?.toLocaleString("default", { month: "long" }) +
        " " +
        dateIssue?.getDate() +
        ", " +
        dateIssue?.getFullYear()
      }`,
      40,
      30
    );

    const imagePath = process.env.PUBLIC_URL + "/images/logo.png";
    pdf.addImage(imagePath, "PNG", 150, 10, 40, 10);

    // Section 2: Logical Contract Limited
    pdf.setFontSize(para);
    pdf.setFont("bold");
    pdf.text(invoice.accountName, 15, 40);
    pdf.text(invoice.accountName, 15, 40);

    pdf.setFontSize(para);
    pdf.setFont("");
    pdf.text("admin@logicalcontracts.co.uk", 15, 45);
    pdf.text("GB VAT GB376488933", 15, 50);

    // Billto Section
    pdf.setFontSize(para);
    pdf.setFont("bold");
    pdf.text("Bill to", 80, 40);
    pdf.text("Bill to", 80, 40);
    pdf.setFontSize(para);
    pdf.setFont("");
    pdf.text(invoice.customerName, 80, 45);
    pdf.text("United Kingdom", 80, 50);
    pdf.setTextColor(0, 0, 255);
    pdf.textWithLink(invoice.customerEmail, 80, 55, {
      url: `mailto:${invoice.customerEmail}`,
      align: "left",
    });

    // Section 3: Lines and Amounts
    pdf.setTextColor(0, 0, 0);
    pdf.setFontSize(smallPara);

    // TABLE ROW HEADER
    pdf.text("Description", 15, 70);
    pdf.text("Qty", 110, 70);
    pdf.text("Unit price", 130, 67);
    pdf.text("(excl. tax)", 130, 70);
    pdf.text("Tax", 150, 70);
    pdf.text("VAT", 167, 70);
    pdf.text("Amount", 183, 67);
    pdf.text("(incl. tax)", 183, 70);

    pdf.setLineWidth(0.4);
    pdf.line(15, 73, 195, 73);
    pdf.setFontSize(para);

    // FIRST TABLE ROW DATA
    pdf.text("Contracts", 15, 78);
    let startDate = epohConvertor(invoice.lines[0]?.period?.start);
    let endDate = epohConvertor(invoice.lines[0]?.period?.end);
    pdf.text(
      `${
        startDate?.toLocaleString("default", { month: "short" }) +
        " " +
        startDate?.getDate()?.toString() +
        " - " +
        endDate?.toLocaleString("default", { month: "short" }) +
        " " +
        endDate?.getDate()?.toString() +
        ", " +
        endDate?.getFullYear()?.toString()
      }`,
      15,
      83
    );
    pdf.setFontSize(smallPara);
    pdf.text(invoice.lines[0].quantity?.toString(), 110, 78);
    pdf.text(
      `${
        poundSign + parseFloat(invoice.lines[0].plan.amount / 100).toFixed(2)
      }`,
      130,
      78
    );
    pdf.text("20.00 %", 150, 78);
    pdf.text(
      `${
        poundSign +
        parseFloat(invoice.lines[0].taxAmounts[0].amount / 100).toFixed(2)
      }`,
      167,
      78
    );
    pdf.text(
      `${
        poundSign +
        parseFloat(
          (invoice.lines[0].plan.amount +
            invoice.lines[0].taxAmounts[0].amount) /
            100
        )?.toFixed(2)
      }`,
      183,
      78
    );

    // SECOND TABLE ROW DATA
    if (invoice?.lines[1] && invoice.lines[1].quantity > 0) {
      pdf.setFontSize(para);
      pdf.text("External User", 15, 90);
      let startDate = epohConvertor(invoice.lines[1]?.period?.start);
      let endDate = epohConvertor(invoice.lines[1]?.period?.end);
      pdf.text(
        `${
          startDate?.toLocaleString("default", { month: "short" }) +
          " " +
          startDate?.getDate()?.toString() +
          " - " +
          endDate?.toLocaleString("default", { month: "short" }) +
          " " +
          endDate?.getDate()?.toString() +
          ", " +
          endDate?.getFullYear()?.toString()
        }`,
        15,
        95
      );
      pdf.setFontSize(smallPara);
      pdf.text(invoice.lines[1].quantity?.toString(), 110, 90);
      pdf.text(
        `${
          poundSign + parseFloat(invoice.lines[1].plan.amount / 100).toFixed(2)
        }`,
        130,
        90
      );
      pdf.text("20.00 %", 150, 90);
      pdf.text(
        `${
          poundSign +
          parseFloat(invoice.lines[1].taxAmounts[0].amount / 100).toFixed(2)
        }`,
        167,
        90
      );
      pdf.text(
        `${
          poundSign +
          parseFloat(
            (invoice.lines[1].amount + invoice.lines[1].taxAmounts[0].amount) /
              100
          )?.toFixed(2)
        }`,
        183,
        90
      );
    }

    pdf.setFontSize(para);
    pdf.setLineWidth(0.01);
    pdf.line(110, 111, 195, 111);
    pdf.text("Total", 110, 116);
    pdf.text(
      `${poundSign + parseFloat(invoice.total / 100).toFixed(2)}`,
      183,
      116
    );
    pdf.line(110, 119, 195, 119);
    pdf.setFont("bold");
    pdf.text(
      "Amount" + `${invoice.status === "draft" ? " Due" : " paid"}`,
      110,
      124
    );
    pdf.text(
      "Amount" + `${invoice.status === "draft" ? " Due" : " paid"}`,
      110,
      124
    );
    pdf.text(
      `${poundSign + parseFloat(invoice.amountDue / 100).toFixed(2)}`,
      183,
      124
    );
    pdf.text(
      `${poundSign + parseFloat(invoice.amountDue / 100).toFixed(2)}`,
      183,
      124
    );

    let pdfName = `${"Invoice-" + invoice.number + ".pdf"}`;
    pdf.save(pdfName);
  };

  return (
    <a style={{ cursor: "pointer" }} target="_blank" onClick={DownloadPdf}>
      PDF
    </a>
  );
}

export default CreateInvoicePdf;
