import React, {useState, useEffect} from "react";
import {Paper} from "@mui/material";
import strings from "../../../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../../../Slices/PageSlice";
import PageSizeState from "../../../../Constants/Base/PageSizeState";
import {useLocation, useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {getUserProfileType} from '../../../../Constants/User/UserProfileType';

const UserType = ({
                    setCompanyDetailsPage,
                    setIndividualDetailsPage,
                    setUserTypePage,
                    deselectPages,
                    onSubmitUserType,
                    onSubmit,
                    onSkipSubmit
                }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const currentLocation = location.pathname;
    const [userTypes, setUserTypes] = useState(getUserProfileType())

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.FULL));
    }, [])

    const handleUserType = (useTypeId) => {
        deselectPages()
        onSubmitUserType(useTypeId)

        if (useTypeId === 1) {
            setCompanyDetailsPage(true)
        } else if (useTypeId === 2) {
            setIndividualDetailsPage(true)
        }
    }

    const renderUserTypes = () => {
        let result = []

        for (let userType of userTypes) {
            result.push(
                <Button key={'stage-' + result.lenght} 
                    className="neutral-btn btn" onClick={() => handleUserType(userType.id)}>
                    <span className="btn-txt">{userType.name}</span>
                </Button>
            )
        }
        return result;
    }

    const handleSkip = () => {

        if (currentLocation == '/registration-details') {
            navigate('/my-details');
        } else {
            onSkipSubmit()
        }
    }

    return <div id='half-page-container' className={'full-form-page auth-page user-type'}>

        <div className='form-container'>
            <Paper className='paper'>
                <img className="logo-img" src='/images/logo.png' />

                <div className="text-container">
                    <h1 className="">{strings.pages.registration.userType.selectUserType}</h1>

                    <div className="buttons-container">
                        {renderUserTypes()}
                    </div>
                    {/*<p className="skip-text" onClick={handleSkip}>{*/}
                    {/*    strings.forms.registration.userType.common.skipThisStep}</p>*/}
                </div>
            
            </Paper>
        </div>

        <div className="image-container image-container-registration">
            <div className='image background-image' style={{backgroundImage: "url('/images/reg.png')"}}></div>
            <div className="title"><h2>Create, edit, share and e-sign your contracts</h2></div>
        </div>
        
    </div>
};
export default UserType;