import React, {useEffect, useState} from "react";
import RegisterForm from "../../../Components/Forms/Pages/User/RegisterForm";
import {useForm} from "react-hook-form";
import {Paper, Button, Alert} from "@mui/material";
import strings from "../../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {login} from "../../../Base/OAuth";
import {setAuth} from "../../../Slices/AuthSlice";
import {useNavigate, Link} from "react-router-dom";
import {registration} from "../../../Services/User/UserService";
import {Snackbar} from "@material-ui/core";
import ValidationPatterns from '../../../Constants/Base/ValidationPatterns';

const formRules = {
    'fullName': {required: { value: true, message: strings.forms.common.required},
        pattern: {value: ValidationPatterns.NAME, message: strings.forms.registration.fullNameError}},
    'email': {required: { value: true, message: strings.forms.common.required},
        pattern: { value: ValidationPatterns.EMAIL, message: strings.forms.registration.emailError, }},
    'password': {required: { value: true, message: strings.forms.common.required},
        pattern: { value: ValidationPatterns.PASSWORD, message: strings.forms.registration.passwordError}}
}

const CreateAccount = ({onSubmitAccount}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const form = useForm();
    const { data,control, setValue, getValues, handleSubmit, handleCurrentPath, formState: { errors } } = form;

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const [messageSnackbar, setMessageSnackbar] = useState('');

    const showMessage = (message, alertType) => {
        setMessageSnackbar(message);
        setAlertType(alertType)
        setShowSnackbar(true);
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.FULL));
    }, [])

    const onSubmitData = (data) => {
        onSubmitAccount(data)
    }

    return <div id='half-page-container' className={'full-form-page auth-page'}>
        <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
            <Alert severity={alertType} onClose={handleCloseSnackbar}>
                {messageSnackbar}
            </Alert>
        </Snackbar>
        <div className='form-container'>
            <Paper className='paper register'>

                <img className="logo-img" src='/images/logo.png' />
                <h2 className="registration-text">{ strings.pages.registration.createAccount }</h2>

                <div className="text-with-link-container-login">
                    <p>{strings.pages.registration.alreadyHaveAccount}</p>
                    <Link to={'/login'} className="middle-text-link">
                        {strings.pages.registration.signIn}
                    </Link>
                </div>

                <RegisterForm 
                    errors={ errors } 
                    data={ data } 
                    form={ form } 
                    control={control}
                    values={getValues()}
                    setValue={setValue}
                    onSubmit={ handleSubmit(onSubmitData) } 
                    formRules={formRules}
                />

                {/* <div className="border-around-text">
                    <hr className="border" />
                    <p>{strings.pages.registration.or}</p>
                    <hr className="border" />
                </div>

                <div className="social-btn-container">
                    <Button 
                        startIcon={<img src='/images/google-icon.png' />} 
                        variant="contained" 
                        className="social-btn btn" 
                        onClick={handleGoogleAccount}
                    >
                        <span className="btn-txt">{strings.pages.registration.continueWithGoogle}</span>
                    </Button>
                </div>

                <div className="social-btn-container">
                    <Button 
                        startIcon={<img src='/images/linkedin-icon.png' />} 
                        variant="contained" 
                        className="social-btn btn" 
                        onClick={handleLinkedinAccount}
                    >
                        <span className="btn-txt">{strings.pages.registration.continueWithLinkedin}</span>
                    </Button>
                </div>

                <div className="social-btn-container">
                    <Button 
                        startIcon={<img src='/images/office-icon.png' />} 
                        variant="contained" 
                        className="social-btn btn" 
                        onClick={handleOfficeAccount}
                    >
                        <span className="btn-txt">{strings.pages.registration.continueWithOffice}</span>
                    </Button>
                </div> */}

            </Paper>
        </div>

        {/*<div className="image-container image-container-registration">*/}
        {/*    <div className='image background-image' style={{backgroundImage: "url('/images/registration-bg.png')"}}></div>*/}
        {/*    <div className="title"><h2>{strings.pages.registration.description}</h2></div>*/}
        {/*</div>*/}
        
    </div>
};
export default CreateAccount;