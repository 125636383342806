import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./AIData.module.css";
import strings from "../../../localization";
import AIDataCreate from "./AIDataCreate";
import AIRequestDataViewComponent from "./AIRequestDataViewComponent";
import AiDataTable from "./AiDataTable";
import AIDataCreateViewComponent from "./AIDataCreateViewComponent";
import AIRequestTagQuestion from "./AIRequestTagQuestion";
import {
  aiRequestDeleteById,
  aiRequestUpdateById,
  clauseDeleteById,
  clauseUpdateById,
  createClause,
  getAiRequestById,
  getClauseOptions,
} from "../../../Services/AI/AIConversationService";
import { caluseDataModal } from "../../../Constants/Admin/AIDataModel";
import { aiDataActions } from "../../../Slices/AiDataSlice";
import SnackbarContext from "../../../Context/SnackbarContext";
import { useNavigate } from "react-router-dom";
import { request } from "../../../Base/HTTP";
import { Modal } from "@mui/material";
import SimpleConfirmModal from "./../../../Components/Modals/SimpleConfirmModal";

function AIRequestDataView({ setSubOption, columns }) {

  const navigate = useNavigate()

  const dispatch = useDispatch();


  const defaultOptions = {
    category: false,
    contract: false,
    contract_variation: false,
    sector: false,
    smart_tag: false,
  }

  const aiDataRow = useSelector((state) => state.aiData.aiDataRow);
  const aiRequestDataRow = useSelector((state) => state.aiData.aiRequestRow);

  const { showMessage } = useContext(SnackbarContext);

  const [choiceBoxName, setChoiceBoxName] = useState("");
  const [autoSaveEnable, setAutoSaveEnable] = useState(false);
  const [clauseOptions, setClauseOptions] = useState([])
  const [searchMatchOptions, setSearchMatchOptions] = useState({})
  const [isOptionOpen, setIsOptionOpen] = useState(defaultOptions)
  const [isQuestionEditable, setIsQuestionEditable] = useState(false)
  const [questionInput, setQuestionInput] = useState('')
  const [index, setIndex] = useState(null)
  const [isExistingAiData, setIsExistingAiData] = useState(true)

  const [deleteTab, setDeleteTab] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteTab = (id) => {
    setDeleteId(id);
    setDeleteTab(true);
  };

  const handleCloseDeleteTab = () => {
    setDeleteId(null);
    setDeleteTab(false);
  };


  const deleteHandler = () => {
    if (deleteId !== null) {
      clauseDeleteById(deleteId)
        .then((res) => {
          setDeleteTab(false);
          showMessage(strings.common.deleted);
          setNewRequestHandler()
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };



  const onClauseSubmitHandler = () => {
    clauseUpdateById(aiDataRow)
      .then((res) => {
        showMessage(strings.common.saved);
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const aiRequestDataSubmitHandler = () => {
    aiRequestUpdateById(aiRequestDataRow)
      .then((res) => {
        if (res) {
          console.log("res22", res)
          showMessage(strings.common.saved);
          if (res.data.report === "Harmful") {
            navigate("/AIRequestData")
          }
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const setNewRequestHandler = () => {
    let tempClauseModal = structuredClone(caluseDataModal)
    tempClauseModal.response = aiRequestDataRow.response;
    tempClauseModal.question = [aiRequestDataRow.request];
    dispatch(aiDataActions.setAiData(tempClauseModal));
    setChoiceBoxName("NewRequest");

  };


  const deleteAiRequestHandler = (id) => {
    aiRequestDeleteById(aiRequestDataRow?.id)
      .then((res) => {
        // setDeleteTab(false);
        if (choiceBoxName === "ReportData") {
          showMessage(strings.common.deleted);

        }
        navigate("/AIRequestData")
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getClauseOptions()
      .then(res => {
        // console.log("clauseoptions", res.data)
        setClauseOptions(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    setSearchMatchOptionsHandler()
  }, [clauseOptions])

  useEffect(() => {
    // console.log("first 1")
    const delayDebounceFn = setTimeout(() => {
      if (autoSaveEnable) {
        // console.log("first 2")
        onClauseSubmitHandler()
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [aiDataRow]);

  const setSearchMatchOptionsHandler = () => {
    setSearchMatchOptions({
      category: clauseOptions.category,
      contract: clauseOptions.contract,
      contract_variation: clauseOptions.contract_variation,
      sector: clauseOptions.sector,
      smart_tag: clauseOptions.smart_tag
    })
  }



  const arrayInputHandler = (e) => {
    const name = e.target.name
    const value = e.target.value
    setQuestionInput(e.target.value)
  }

  const addArrayVarDataHandler = (name, value) => {

    if (name === 'category' || 'contract' || 'contract_variation' || 'sector' || 'smart_tag') {
      setAutoSaveEnable(true)
    }

    if (isQuestionEditable && questionInput) {
      let tempArrayEle = [...aiDataRow[name]]

      tempArrayEle[index] = questionInput

      dispatch(aiDataActions.editArrayVarData({ name: name, value: tempArrayEle }))
      setIsQuestionEditable(false)
      setIndex(null)
      setQuestionInput('')
    }
    else {
      // console.log("else")
      if (value) {
        if (!aiDataRow[name]?.includes(value)) {
          dispatch(aiDataActions.setArrayVarData({ name: name, value: value }))
        }
      }
    }
    setQuestionInput('')
  }


  useEffect(() => {
    if (!aiDataRow) {
      const pathArray = window.location.pathname.split('/')
      const id = pathArray[pathArray.length - 1]
      getAiRequestById(id)
        .then(res => {
          if (res) {
            dispatch(aiDataActions.setaiRequestRow(res.data));
          }
        })
        .catch(err => {
          console.log("err", err)
        })

    }
  }, [])


  return (
    <div className={styles.mainContainer} style={{ marginLeft: "2rem" }}>
      <div className={styles.firstRow} style={{ justifyContent: "flex-start", columnGap: "65rem" }}>
        <div className={styles.firstRow_Left}>
          <div
            className={styles.button}
            onClick={() => navigate(-1)}
          >
            <span>Back</span>
          </div>
        </div>
      </div>
      {
        choiceBoxName === 'ReportData' && (
          <div className={`${styles.aiRequestButtons} ${styles.buttonReportData}`}>
            <img
              className={styles.deleteIconImg}
              src="/images/icons/deleteNewRequest.png"
              alt="delete"
              onClick={() => deleteAiRequestHandler(aiRequestDataRow.id)}
              style={{ marginTop: "7px", width: '60px' }}
            />
            <div
              className={styles.saveIconImg}
              onClick={aiRequestDataSubmitHandler}
              style={{ marginTop: '0.6rem', height: '3rem' }}
            >
              <span style={{ fontWeight: "100px", fontStyle: "normal" }}>
                Save
              </span>
            </div>
          </div>
        )
      }


      <div className={styles.choiceRequestBoxContainer}>

        {aiRequestDataRow?.total_tokens === 0 && isExistingAiData
          ? <div className={styles.isExistingAiDataBox} >
            {aiRequestDataRow?.total_tokens === 0 && <span>{strings.pages.adminAIData.aiRequest.question.existingAIData}</span>}
            {aiRequestDataRow?.total_tokens === 0 && <div className={styles.isExistingAiDataBtnBox}>
              <div className={styles.inputContainerButton}
                onClick={() => navigate('/AIRequestData')}>
                <span style={{ fontWeight: "100px", fontStyle: "normal" }}>
                  Yes
                </span>
              </div>
              <div className={styles.inputContainerButton}
                onClick={() => setIsExistingAiData(false)}
              >
                <span style={{ fontWeight: "100px", fontStyle: "normal" }}>
                  No
                </span>
              </div>
            </div>}
          </div>
          : <>
            {choiceBoxName === "" && aiRequestDataRow?.total_tokens > 0 && <div className={styles.isExistingAiDataBox} >
              <span>{strings.pages.adminAIData.aiRequest.question.chatGPT}</span>
            </div>}
            <div className={styles.aiRequestBox}>
              <div
                className={styles.reqChoiceGrayBox}
                style={{
                  backgroundColor:
                    choiceBoxName === "NewRequest" ? "#82F5DE" : "#9DAEC6",
                }}
                onClick={() => setNewRequestHandler()}
              >
                <div className={styles.formRow}>
                  <div
                    className={styles.labelText}
                    style={{ marginBottom: "10px", fontSize: "20px" }}
                  >
                    <span>
                      {strings.pages.adminAIData.aiRequest.choices[0].name}
                    </span>
                  </div>
                  <div className={styles.reqChoiceDef}>
                    <span>
                      {strings.pages.adminAIData.aiRequest.choices[0].def}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.aiRequestBox}>
              <div
                className={styles.reqChoiceGrayBox}
                style={{
                  backgroundColor:
                    choiceBoxName === "TagQuestion" ? "#82F5DE" : "#9DAEC6",
                }}
                onClick={() => setChoiceBoxName("TagQuestion")}
              >
                <div className={styles.formRow}>
                  <div
                    className={styles.labelText}
                    style={{
                      marginBottom: "5px",
                      marginTop: "14px",
                      marginLeft: "0px",
                      fontSize: "16px",
                    }}
                  >
                    <span>
                      {strings.pages.adminAIData.aiRequest.choices[1].name}
                    </span>
                  </div>
                  <div className={styles.reqChoiceDef}>
                    <span>
                      {strings.pages.adminAIData.aiRequest.choices[1].def}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.aiRequestBox}>
              <div
                className={styles.reqChoiceGrayBox}
                style={{
                  backgroundColor:
                    choiceBoxName === "ReportData" ? "#82F5DE" : "#9DAEC6",
                }}
                onClick={() => setChoiceBoxName("ReportData")}
              >
                <div className={styles.formRow}>
                  <div
                    className={styles.labelText}
                    style={{
                      marginBottom: "5px",
                      width: "100%",
                      marginTop: "15px",
                      marginLeft: "0px",
                      fontSize: "16px",
                    }}
                  >
                    <span>
                      {" "}
                      {strings.pages.adminAIData.aiRequest.choices[2].name}
                    </span>
                  </div>
                  <div className={styles.reqChoiceDef}>
                    <span>
                      {strings.pages.adminAIData.aiRequest.choices[2].def}
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </>
        }
      </div>
      {choiceBoxName === "" && <AIRequestDataViewComponent />}
      {choiceBoxName === "NewRequest" && (
        <AIDataCreateViewComponent
          choiceBoxName={"NewRequest"}
          aiDataRow={aiDataRow}
          pageName={choiceBoxName}
          handleDeleteTab={handleDeleteTab}
          deleteAiRequestHandler={deleteAiRequestHandler}
        />
      )
      }
      {
        choiceBoxName === "TagQuestion" && (
          <AIRequestTagQuestion
            deleteAiRequestHandler={deleteAiRequestHandler}
          />
        )
      }
      {
        choiceBoxName === "ReportData" && (
          <AIRequestDataViewComponent
            choiceBoxName={"ReportData"}

          />
        )
      }
      <Modal
        open={deleteTab}
        onClose={handleCloseDeleteTab}
        className="confirm-modal-container"
      >
        <SimpleConfirmModal
          title={strings.modals.contractList.deleteContract.confirmTextForAI}
          cancelBtn={strings.modals.contractList.deleteContract.no}
          confirmBtn={strings.modals.contractList.deleteContract.yes}
          confirmBtnClass={true}
          handleCloseModal={handleCloseDeleteTab}
          handleConfirm={deleteHandler}
        />
      </Modal>
    </div >
  );
}

export default AIRequestDataView;
