import React from "react";
import styles from "../../DealRoom.module.css";
import strings from "../../../../localization";

const ConnectPage = ({ setPageName }) => {
  return (
    <div className={styles.connectContainer}>
      <div
        className={styles.connectBox}
        style={{ backgroundColor: "#EEDEBE" }}
        onClick={() => setPageName("legalSupportPage")}
      >
        <img src="/images/icons/Calling.png" alt="" />
        <div className={styles.paySubHeadingBox}>
          <span className={styles.paySubHeading}>
            {strings.forms.dealRoom.connect.legalSupport.title}
          </span>
        </div>
        <p className={styles.labelText} style={{ margin: 0 }}>
          {strings.forms.dealRoom.connect.legalSupport.para}
        </p>
      </div>
      <div
        className={styles.connectBox}
        style={{ backgroundColor: "#BEE5EE", margin: "0 50px" }}
        onClick={() => setPageName("fundingPage")}
      >
        <img src="/images/icons/Funding.png" alt="" />
        <div className={styles.paySubHeadingBox}>
          <span className={styles.paySubHeading}>
            {strings.forms.dealRoom.connect.funding.title}
          </span>
        </div>
        <p className={styles.labelText} style={{ margin: 0 }}>
          {strings.forms.dealRoom.connect.funding.para}
        </p>
      </div>
      <div
        className={styles.connectBox}
        style={{ backgroundColor: "#FFFFD1" }}
        onClick={() => setPageName("buyOrSellFormPage")}
      >
        <img
          src="/images/icons/BuyOrSell.png"
          alt=""
          width={"100px"}
          height={"auto"}
        />
        <div className={styles.paySubHeadingBox}>
          <span className={styles.paySubHeading}>
            {strings.forms.dealRoom.connect.buyOrSell.title}
          </span>
        </div>
        <p className={styles.labelText} style={{ margin: 0 }}>
          {strings.forms.dealRoom.connect.buyOrSell.para}
        </p>
      </div>
    </div>
  );
};

export default ConnectPage;
