import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllQuestions() {
    return await request('/api/aiquestion/all');
}

export async function getAllExamplesQuestions() {
    return await request('/api/aiquestion/examples/all');
}

export async function addQuestion(data) {
    return await request('/api/aiquestion/add', data, HttpMethod.POST);
}

export async function deleteQuestion(id) {
    return await request('/api/aiquestion/' + id, {}, HttpMethod.DELETE);
}
