import React, { useRef, useState } from 'react';
import styles from "../../../../../Pages/dealRoom/DealRoom.module.css";


const BorrowForm = ({ setPageName, setFormName, formData, handleChange, handleSubmit, setErrors, errors }) => {

    const inputRef = useRef(null);
    const [isOpenChoiceBox, setisOpenChoiceBox] = useState({});

    const typeFinanceOptions = [
        { id: 1, name: "Unsure - open to advice" },
        { id: 2, name: "Asset Finance" },
        { id: 3, name: "Business Loan" },
        { id: 4, name: "Commercial Property & Bridging" },
        { id: 5, name: "Credit insurance" },
        { id: 6, name: "Distress Finance" },
        { id: 7, name: "High Net Worth Finance" },
        { id: 8, name: "Invoice Finance/ Factoring" },
        { id: 9, name: "Tax Funding" },
        { id: 10, name: "Trade Finance" },
    ]

    const handleMouseLeave = () => {
        inputRef?.current?.blur();

        setisOpenChoiceBox({ ...isOpenChoiceBox, "typeOfFinance": false })
        // setisOpenChoiceBox({ ...isOpenChoiceBox, sector: false })
    };

    return (
        <div className={styles.dealRoom}>
            <div className={styles.filledBox} onMouseLeave={handleMouseLeave}>
                <div className={styles.labelText}>
                    <span>Type of finance (if known)*</span>
                </div>
                <div className={styles.inputContainer}
                    style={{ position: "relative" }}
                >
                    <input
                        readOnly
                        ref={inputRef}
                        onFocus={() => setisOpenChoiceBox({ ...isOpenChoiceBox, 'typeOfFinance': true })}
                        name="typeOfFinance"
                        value={formData.typeOfFinance}
                        // onChange={handleChange}
                        className={styles.inputBox}
                        placeholder='Please Select Type Of Finance'
                        type="text"
                        style={{
                            WebkitAppearance: "none",
                            background: `url(/images/icons/downArrow.png)`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "right center",
                        }}
                    />
                    {isOpenChoiceBox?.typeOfFinance && (
                        <div className={styles.options}>
                            <ul onMouseLeave={handleMouseLeave}>
                                {typeFinanceOptions?.map((item, index) => {
                                    return (
                                        <li key={index} onClick={() => handleChange({ target: { name: "typeOfFinance", value: item.name } })}>
                                            {item.name}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}

                </div>
                {errors.typeOfFinance && <span className={styles.errorMessage}>{errors.typeOfFinance}</span>}
            </div>
            <div className={styles.filledBox} >
                <div className={styles.labelText}>
                    <span>How much would you like to borrow?*</span>
                </div>
                <div className={styles.inputContainer} >
                    <input
                        className={styles.inputBox}
                        name="borrowAmount"
                        value={formData.borrowAmount}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter how much would you like to borrow"
                    />
                </div>
                {errors.borrowAmount && <span className={styles.errorMessage}>{errors.borrowAmount}</span>}
            </div>
            <div className={styles.filledBox} >
                <div className={styles.labelText}>
                    <span>How long would you like to borrow this amount?*</span>
                </div>
                <div className={styles.inputContainer} >
                    <input
                        className={styles.inputBox}
                        name="borrowDuration"
                        value={formData.borrowDuration}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter how long would you like to borrow this amount"
                    />
                </div>
                {errors.borrowDuration && <span className={styles.errorMessage}>{errors.borrowDuration}</span>}
            </div>
            <div className={styles.payButtonRow}>
                <div className={styles.addButton} style={{ borderRadius: "7px", backgroundColor: "#FFF" }} onClick={() => setFormName("businessInfoForm")} >
                    <span style={{ color: "#3D32CF" }}>Back</span>
                </div>
                <div className={styles.addButton} style={{ borderRadius: "7px" }} onClick={() => handleSubmit()} >
                    <span >Submit</span>
                </div>
            </div>
        </div>
    )
}

export default BorrowForm