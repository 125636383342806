import React, { useState } from "react";
import { Button } from "@mui/material";
import strings from '../../../localization';
import CheckIcon from '@mui/icons-material/Check';
import PackageFeatureType from "../../../Constants/Dashboard/PackageFeatureType";
import { useSelector } from "react-redux";
import { isTrial } from "../../../Util/PermissionUtil";
import { dateInPast } from "../../../Util/DateUtil";

const Package = ({ pack, features, handleSubscribe, isCustom, isAnnual, handleCustom }) => {

    const user = useSelector((state) => state.auth.user)

    const isCurrentPackage = (user, pack) => {
        const userPackage = user.company?.package;

        if (user.company?.packageValidUntil && dateInPast(new Date(user.company?.packageValidUntil)) || !user.company?.packageValid) {
            return false
        }

        if (!userPackage || !pack) {
            return false
        }

        return userPackage.name == pack.name;
    }

    const canUpgrade = (user, pack) => {
        const userPackage = user.company?.package;

        if ((user.company?.packageValidUntil && dateInPast(new Date(user.company?.packageValidUntil))) || !user.company?.packageValid) {
            return false
        }

        if (!userPackage || !pack) {
            return false
        }

        return userPackage.price < pack.price;
    }

    const canDowngrade = (user, pack) => {
        const userPackage = user.company?.package;

        if (user.company?.packageValidUntil && dateInPast(new Date(user.company?.packageValidUntil)) || !user.company?.packageValid) {
            return false
        }

        if (!userPackage || !pack) {
            return false
        }

        return userPackage.price > pack.price;
    }

    const renderFeatures = (featuresForRender) => {

        const filtered = featuresForRender.filter(x => x.type === PackageFeatureType.REGULAR)

        let result = []

        for (let feature of filtered) {
            result.push(
                <div className="feature" key={'feature-' + feature.id}>
                    <CheckIcon className="icon check-icon" />
                    <p className="text">{feature.content}</p>
                </div>
            )
        }

        return result
    }

    const renderAdditionalFeatures = (pack, featuresForRender) => {

        const filtered = featuresForRender.filter(x => x.type === PackageFeatureType.ADDITIONAL)

        let result = []

        for (let feature of filtered) {
            result.push(
                <div className="feature" key={'feature-' + feature.id}>
                    <CheckIcon className="icon check-icon" />
                    <p className="text">{feature.content}</p>
                </div>
            )
        }

        return result
    }

    return (

        <div className={pack.recommended ? 'item recommended' : 'item'}>

            {pack.recommended &&
                <div className="recommended-label-container" style={{ background: pack.color }}>
                    <p className="recommended-label">{strings.constants.dashboard.pricingAndPackages.recommended}</p>
                </div>
            }

            <h1 className="title" style={{ background: pack.color }}>{pack.name}</h1>

            <div className="item-content-container">
                <div className="item-content">
                    <div className="icon-container">
                        <div className="icon">
                            <img src={pack.icon} />
                        </div>
                    </div>

                    <p className="price">

                        <sup className="currency">{isCustom ? '' : '£'}</sup>
                        <span
                            className="amount">{isCustom ? 'Custom' : parseFloat(isAnnual ? pack.annualPrice : pack.price)}</span>
                        <sub
                            className="by-year">/{isAnnual ? strings.constants.dashboard.pricingAndPackages.year + " + VAT" : strings.constants.dashboard.pricingAndPackages.month + " + VAT" }</sub>
                    </p>

                    <p className="text description">{pack.description}</p>

                    <div className="section">
                        <h2 className="subtitle">{pack.featureTitle}</h2>
                        {renderFeatures(features)}
                    </div>

                    <div className="section">
                        <h2 className="subtitle">{pack.additionalFeatureTitle}</h2>
                        {renderAdditionalFeatures(pack, features)}
                    </div>
                </div>

                {
                    (((!user.company?.isTrial && dateInPast(new Date(user.company?.packageValidUntil)) && user.company?.packageValidUntil) || (user.company?.packageValidUntil && !user.company?.packageValid)) && !isCustom) &&
                    <Button className="current-plan-btn btn item-btn" onClick={() => handleSubscribe(pack)} style={{background: pack.color}} >
                        {strings.constants.dashboard.pricingAndPackages.subscribe}</Button>
                }
                {
                    (isTrial(user) && !isCustom && !canUpgrade(user, pack)) &&
                    <Button className="current-plan-btn btn item-btn"  style={{background: pack.color}} onClick={() => handleSubscribe(pack)}>
                        {strings.constants.dashboard.pricingAndPackages.subscribe}</Button>
                }

                {
                    (!user.company?.package && !isCustom) &&
                    <Button className="current-plan-btn btn item-btn"
                        style={{ backgroundColor: pack.color }}
                        onClick={() => handleSubscribe(pack, true)}
                    >
                        {strings.constants.dashboard.pricingAndPackages.startFreeTrial7Days}</Button>
                }

                {
                    isCustom &&
                    <Button className="subscribe-btn btn item-btn"
                        style={{ background: pack.color }}
                        onClick={handleCustom}>
                        {strings.constants.dashboard.pricingAndPackages.talkToUs}</Button>
                }

                {
                    !isTrial(user) && isCurrentPackage(user, pack) &&
                    <Button className="current-plan-btn btn item-btn"
                    style={{ background: pack.color }}
                    >
                        {strings.constants.dashboard.pricingAndPackages.currentPlan}</Button>
                }

                {
                    !isTrial(user) && canDowngrade(user, pack) &&
                    <Button className="downgrade-btn btn item-btn" 
                    style={{ background: pack.color }}
                    onClick={() => handleSubscribe(pack)}>
                        {strings.constants.dashboard.pricingAndPackages.downgrade}</Button>
                }

                {
                    canUpgrade(user, pack) &&
                    <Button className="subscribe-btn btn item-btn" onClick={() => handleSubscribe(pack)}  style={{ background: pack.color }}>
                        {strings.constants.dashboard.pricingAndPackages.upgradeNow}</Button>
                }


            </div>
        </div>
    )
}

export default Package;