import React, { useContext, useState } from "react";
import { Button, Collapse, ListItemText, Menu, MenuItem } from "@mui/material";
import strings from "../../../localization";
import Contract from "./Contract";
import Package from "./Package";
import SubCategory from "./SubCategory";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    changeActiveContractCategories,
    deleteContractCategory, editContractCategory
} from "../../../Services/ContractCategory/ContractCategoryService";
import { useForm } from "react-hook-form";
import TextFieldControl from "../../../Components/Controls/Inputs/TextFieldControl";
import TextareaControl from "../../../Components/Controls/Inputs/TextareaControl";
import { FormProvider } from "react-hook-form";
import SnackbarContext from "../../../Context/SnackbarContext";
import { changeActivePackage, deletePackage } from "../../../Services/ContractCategory/PackageService";
import ConfirmModal from "../../../Components/Modals/ConfirmModal";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import { toBase64 } from "../../../Util/ImageUtil";

const Category = (props) => {

    const [drop, setDrop] = useState({ id: 0, toggle: false })
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [categoryToEdit, setCategoryToEdit] = useState(-1);
    const [openModal, setOpenModal] = useState(false)
    const [activateMenuAnchorEl, setActivateMenuAnchorEl] = useState(null)
    const form = useForm();
    const { data, control, handleSubmit, getValues, setValue, formState: { errors } } = form;
    const { showMessage } = useContext(SnackbarContext);

    const handleDrop = (id) => {

        if (id !== drop.id) {
            setDrop({
                id: id,
                toggle: true
            });
        } else {
            setDrop({
                id: id,
                toggle: !drop.toggle
            });
        }

        form.reset();
        setCategoryToEdit(-1);
    }

    const editCategory = (data) => {

        editContractCategory({
            id: categoryToEdit,
            name: data.name,
            description: data.description,
            color: data.color,
            icon: data.icon
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }
            form.reset();
            setCategoryToEdit(-1);
            showMessage(strings.common.edited)
            props.fetch();
        })
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleConfirm = category => {
        setOpenModal(false)
        activateCategory(category)
    }

    const activateCategory = category => {

        setActivateMenuAnchorEl(null);

        changeActiveContractCategories({
            id: category.id,
            active: !category.active
        }).then(response => {

            if (!response || !response.ok) {
                showMessage(strings.common.saved);
                return;
            }
            setDrop({ id: 0, toggle: false })
            props.fetch();
        })

        if (category.active == true) {
            showMessage(strings.common.deactivated)
        } else {
            showMessage(strings.common.activated)
        }
    }

    const removeCategory = category => {

        setActivateMenuAnchorEl(null);

        deleteContractCategory(category.id).then(response => {

            if (!response || !response.ok) {
                return;
            }

            showMessage(strings.common.deleted);
            props.fetch();
        })
    }

    const deleteCategory = (item) => {

        changeActiveContractCategories({
            id: item.id,
            activate: !item.activate
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            props.onFinish();
        })
    }

    const handleActivateClick = event => {
        setActivateMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setActivateMenuAnchorEl(null);
    };

    const readFile = async (file, setValue) => {
        const image = await toBase64(file);
        setValue('icon', image);
    }

    const renderCategory = () => {
        let result = [];

        for (let category of props.categories) {

            if (category.parent) {
                continue;
            }

            result.push(
                <div key={'category-' + result.length} className="tab-page-item content-item">
                    <div className="item-header">
                        <div className="item-title">
                            {
                                categoryToEdit !== category.id &&
                                <h1 className={category.active ? "title" : "title deactivated-text"}>
                                    {category.name} {!category.active && strings.pages.adminCategories.deactivated}
                                </h1>
                            }
                            {
                                categoryToEdit !== category.id && drop.toggle && category.id === drop.id &&
                                <React.Fragment>
                                    <Tooltip
                                        title={strings.pages.adminCategories.editCategory}
                                        placement="top"
                                        arrow>
                                        <IconButton className="item-title-icon" onClick={() => setCategoryToEdit(category.id)}>
                                            <img src="/images/icons/pen.png" />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title={strings.pages.adminCategories.deactivateCategory}
                                        placement="top"
                                        arrow>
                                        <IconButton className="item-title-icon delete-deactivate-icon" onClick={() => handleOpenModal()}>
                                            <img src="/images/icons/bag.png" />
                                        </IconButton>
                                    </Tooltip>
                                </React.Fragment>
                            }
                            {
                                categoryToEdit === category.id && drop.toggle && category.id === drop.id &&
                                <React.Fragment>
                                    <FormProvider {...form}>
                                        <div className="long-input-wrap">
                                            <TextFieldControl
                                                name='name'
                                                control={data}
                                                defaultValue={category.name}
                                                rules={{ required: true }}
                                                fullWidth
                                                margin="normal"
                                                error={Boolean(errors.name)}
                                                helperText={errors.name && strings.forms.admin.adminCategoryForm.category}
                                                placeholder={strings.forms.admin.adminCategoryForm.categoryName}
                                            />
                                        </div>
                                        <div className="item input-wrap right-margin" style={{ minWidth: '130px' }} >
                                            <TextFieldControl
                                                name='color'
                                                control={data}
                                                defaultValue={category.color}
                                                rules={{ required: true }}
                                                fullWidth
                                                margin="normal"
                                                error={Boolean(errors.color)}
                                                helperText={errors.color && strings.forms.admin.adminCategoryForm.color}
                                                placeholder={strings.forms.admin.adminCategoryForm.colorName}
                                            />
                                        </div>
                                        <div className="item input-wrap right-margin">
                                            <input
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                id="raised-button-file"
                                                multiple
                                                type="file"
                                                onChange={(e) => {

                                                    readFile(e.target.files[0], setValue);
                                                }}
                                            />
                                            <label htmlFor="raised-button-file">
                                                <Button variant="raised" component="span">
                                                    Icon
                                                </Button>
                                            </label>
                                        </div>
                                        <div className="long-input-wrap">
                                            <TextareaControl
                                                name='description'
                                                control={data}
                                                defaultValue={category.description}
                                                rules={{ required: true }}
                                                fullWidth
                                                margin="normal"
                                                error={Boolean(errors.description)}
                                                helperText={errors.description && strings.forms.admin.adminCategoryForm.description}
                                                placeholder={strings.forms.admin.adminCategoryForm.description}
                                                rows={4}
                                                maxRows={6}
                                            />
                                        </div>
                                        <Button className="save-btn secondary-btn btn" variant="contained" onClick={handleSubmit(editCategory)}>
                                            {strings.pages.adminCategories.save}
                                        </Button>
                                    </FormProvider>
                                </React.Fragment>
                            }

                        </div>

                        {
                            !category.active
                                ?
                                <React.Fragment>
                                    <Tooltip
                                        title={strings.commonTooltips.deactivated}
                                        placement="top"
                                        arrow>
                                        <MoreVertIcon className="toggle-icon" onClick={(event) => handleActivateClick(event)} />
                                    </Tooltip>

                                    <Menu
                                        id='activate-menu'
                                        className="activate-menu"
                                        anchorEl={activateMenuAnchorEl}
                                        open={Boolean(activateMenuAnchorEl)}
                                        onClose={() => handleClose()}
                                    >
                                        <MenuItem className="activate-menu-list">
                                            <ListItemText className="item" inset primary={strings.pages.adminCategories.activate} onClick={() => activateCategory(category)} />
                                            <ListItemText className="item" inset primary={strings.pages.adminCategories.delete} onClick={() => removeCategory(category)} />
                                        </MenuItem>
                                    </Menu>
                                </React.Fragment>
                                : drop.toggle && category.id === drop.id
                                    ? <Tooltip
                                        title={strings.commonTooltips.collapse}
                                        placement="top"
                                        arrow>
                                        <RemoveIcon className="toggle-icon" onClick={() => handleDrop(category.id)} />
                                    </Tooltip>
                                    : <Tooltip
                                        title={strings.commonTooltips.expand}
                                        placement="top"
                                        arrow>
                                        <AddIcon className="toggle-icon" onClick={() => handleDrop(category.id)} />
                                    </Tooltip>
                        }
                    </div>

                    <Collapse in={drop.id === category.id && drop.toggle} timeout="auto" unmountOnExit>

                        <div className="subitems-container subitem-2">
                            {/* <SubCategory
                                fetch={props.fetch}
                                category={category}
                                name={strings.pages.adminCategories.subCategoriesList} data={subcategories}
                            /> */}
                            <Contract name={strings.pages.adminCategories.templates} category={category} />
                            <Package name={strings.pages.adminCategories.packages} category={category} />
                            <Modal
                                open={openModal}
                                onClose={handleCloseModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                className='confirm-modal-container'
                            >
                                <ConfirmModal
                                    title={strings.modals.adminPackages.title}
                                    contentText={strings.modals.adminPackages.confirmText}
                                    cancelBtn={strings.modals.adminPackages.cancelBtn}
                                    confirmBtn={strings.modals.adminPackages.deactivateBtn}
                                    handleCloseModal={handleCloseModal}
                                    handleConfirm={() => handleConfirm(category)}
                                />
                            </Modal>
                        </div>
                    </Collapse>

                </div>
            )
        }
        return result;
    }

    return (
        <div className="tab-page-content">
            {renderCategory()}
        </div>
    )
}

export default Category;