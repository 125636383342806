import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Modal, Button, Tooltip, AccordionDetails } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import strings from "../../../../localization";
import styles from "../../../Admin/AdminAIData/AIData.module.css";
import RemoveIcon from "@mui/icons-material/Remove";
import ConfirmModal from "../../../../Components/Modals/SimpleConfirmModal";
import { sectionObj } from "../../../../Constants/Admin/DealRoomModel";
import {
  deleteDueDiligenceSection,
  dueDeligenceAddSection,
  dueDeligenceUpdateSection,
} from "../../../../Services/DealRoom/DealRoomService";
import QuestionsList from "./QuestionsList";

const useStyles = makeStyles((theme) => ({
  accordion: {
    marginBottom: "10px",
    boxShadow: "0 0 10px 0 rgba(236, 240, 243, 0.5)",
    borderRadius: "5px!important",
    position: "relative",
    width: "98%",
  },
  accordionSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "70px",
    width: "calc(100% - 3rem)",
  },
  coloredStrip: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: "3px",
    backgroundColor: "rgba(229, 192, 117, 1)",
    transition: "all 0.3s ease",
    height: "100% !important",
    borderRadius: "10px, 0px, 0px, 10px",
  },
  toggleIcon: {},
  dueDeligenceSection: {
    fontFamily: "Nobile",
    fontWeight: "bold",
  },
}));

const SectionRow = ({
  row,
  projectId,
  showMessage,
  sectionsType,
  sectionIndex,
  activeOpenSection,
  setActiveOpenSection,
  getSectionListHandler,
}) => {
  const classes = useStyles();
  const params = useParams();
  const [editMode, setEditMode] = useState(false);
  const [sectionRow, setSectionRow] = useState(row);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const headingFieldHandler = (e) => {
    let value = e.target.value;

    let tempSectionRow = structuredClone(sectionRow);

    tempSectionRow.name = value;
    setSectionRow(tempSectionRow);
  };

  const submitSectionHandler = (name) => {
    if (!sectionRow?.name) {
      showMessage("Section name can not be empty!", "error");
      return;
    }
    if (editMode) {
      let updateSectionData = { name: sectionRow.name };
      dueDeligenceUpdateSection(projectId, sectionRow?.id, updateSectionData)
        .then((res) => {
          if (res.status === 204) {
            getSectionListHandler();
            toggleHeadingEditable();
            showMessage("Section updated");
          } else {
            showMessage("Error in saving section", "error");
          }
        })
        .catch((err) => {
          console.log("error in dueDeligenceUpdateSection", err);
        });
    } else {
      let tempData = sectionObj;
      tempData.name = name;
      tempData.type = sectionsType;
      dueDeligenceAddSection(projectId, tempData)
        .then((res) => {
          if (res.status === 200) {
            getSectionListHandler();
            toggleHeadingEditable();
            showMessage("Section added");
          } else {
            showMessage("Error in adding new section", "error");
          }
        })
        .catch((err) => {
          console.log("error in dueDeligenceAddSection", err);
        });
    }
  };

  const deleteSectionHandler = (sectionId) => {
    let projectId = params?.id;
    deleteDueDiligenceSection(projectId, sectionId)
      .then((res) => {
        if (res.status === 204) {
          setOpenDeleteModal(false);
          getSectionListHandler();
          showMessage("Section deleted");
        } else {
          showMessage("Error in deleting section", "error");
        }
      })
      .catch((err) => {
        console.log("error in deleteDueDiligenceSection", err);
      });
  };

  const toggleRowHandler = () => {
    let tempSectionRow = structuredClone(sectionRow);
    tempSectionRow.isOpen = !tempSectionRow.isOpen;
    setSectionRow(tempSectionRow);
    if (activeOpenSection.includes(sectionRow?.id)) {
      const filterData = activeOpenSection.filter(
        (id) => id !== sectionRow?.id
      );
      setActiveOpenSection(filterData);
    } else {
      setActiveOpenSection([...activeOpenSection, sectionRow?.id]);
    }
  };

  const toggleHeadingEditable = () => {
    let tempSectionRow = structuredClone(sectionRow);
    tempSectionRow.iseditable = !tempSectionRow.iseditable;
    setSectionRow(tempSectionRow);
    setEditMode(true);
  };

  useEffect(() => {
    setSectionRow(row);
  }, [row]);

  return (
    <div className={classes.accordion} style={{ backgroundColor: "#FFF" }}>
      <div
        className={classes.accordionSummary}
        style={{ position: "relative", padding: "0 1.5rem", margin: "0" }}
      >
        <div
          className={classes.coloredStrip}
          style={{ backgroundColor: `${sectionRow?.color}` }}
        />

        <div style={{ display: "flex", margin: "0px 0px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "700" }}>{sectionIndex + 1}.</span>

            {sectionRow?.iseditable ? (
              <div className="questionnaire-fields">
                <div
                  className={styles.inputContainer}
                  style={{
                    margin: " 8px 10px",
                    height: "max-38px",
                    width: "367px",
                  }}
                >
                  <input
                    className={styles.inputBox}
                    value={sectionRow?.name}
                    name="name"
                    type="text"
                    onChange={(e) => headingFieldHandler(e)}
                  />
                </div>
                <div>
                  <Button
                    onClick={() => submitSectionHandler(sectionRow?.name)}
                    className="save-btn"
                    variant="contained"
                    sx={{
                      margin: "15px 10px",
                      width: "90px",
                      textTransform: "none",
                    }}
                  >
                    {editMode ? "Update" : "Save"}
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <span
                  className="deligenceSecHeading"
                  style={{
                    fontFamily: "Nobile",
                    fontWeight: "600",
                    marginRight: "1rem",
                  }}
                >
                  {sectionRow?.name}
                </span>
                {!sectionRow?.isDefault && (
                  <>
                    <Tooltip
                      title={strings.pages.adminDealRoom.editHeading}
                      onClick={() => toggleHeadingEditable()}
                    >
                      <img
                        className="item-iconWithBg"
                        style={{ width: "75px", height: "75px" }}
                        src="/images/icons/penWithBg.png"
                        alt="edit"
                      />
                    </Tooltip>
                    <Tooltip
                      title={strings.pages.adminDealRoom.deleteAccordion}
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      <img
                        className="item-iconWithBg"
                        style={{ marginTop: "7px" }}
                        src="/images/icons/deleteWithBg.png"
                        alt="delete"
                      />
                    </Tooltip>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className={classes.toggleIcon} style={{ cursor: "pointer" }}>
          {sectionRow?.isOpen ? (
            <RemoveIcon onClick={() => toggleRowHandler()} />
          ) : (
            <AddIcon onClick={() => toggleRowHandler()} />
          )}
        </div>
      </div>
      {sectionRow?.isOpen && (
        <AccordionDetails>
          <div
            className={classes.coloredStrip}
            style={{ backgroundColor: `${sectionRow?.color}` }}
          />
          <Box
            display={"flex"}
            width={"100%"}
            mt={1}
            mb={2}
            justifyContent={"center"}
            className={classes.dueDeligenceSection}
          >
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              sx={{ paddingLeft: "4rem", width: "calc(30% - 4rem)" }}
            >
              Request
            </Box>
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              sx={{ padding: "0rem 1rem", width: "calc(50% - 2rem)" }}
            >
              Response
            </Box>
            <Box width={"20%"} display={"flex"} justifyContent={"flex-start"}>
              Reference
            </Box>
          </Box>

          <QuestionsList
            questions={sectionRow?.questions}
            sectionIndex={sectionIndex}
            sectionRow={sectionRow}
            getSectionListHandler={getSectionListHandler}
            showMessage={showMessage}
          />
        </AccordionDetails>
      )}

      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        className="confirm-modal-container"
      >
        <ConfirmModal
          title={strings.forms.dealRoom.dueDeligence.deleteSectionTitle}
          cancelBtn={strings.modals.adminDealRoom.cancel}
          confirmBtn={strings.modals.adminDealRoom.ok}
          handleCloseModal={() => setOpenDeleteModal(false)}
          handleConfirm={() => deleteSectionHandler(sectionRow?.id)}
          contentTextStyle={{ fontWeight: "600", fontSize: "22px" }}
          buttonsStyles={{ borderRadius: "10px !important" }}
        />
      </Modal>
    </div>
  );
};

export default SectionRow;
