import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getTemplateQuestion(id) {
    return await request('/api/templatequestion/' + id);
}

export async function getAllTemplateQuestions(stepId) {
    return await request('/api/templatequestion/all/' + stepId);
}

export async function getAllActiveTemplateQuestions(stepId) {
    return await request('/api/templatequestion/all/active/' + stepId);
}

export async function getAllTemplateQuestionsByTemplate(templateId) {
    return await request('/api/templatequestion/all/template/' + templateId);
}

export async function deleteTemplateQuestion(id) {
    return await request('/api/templatequestion/' + id, {}, HttpMethod.DELETE);
}

export async function addTemplateQuestion(data) {
    return await request('/api/templatequestion/add', data, HttpMethod.POST);
}

export async function editTemplateQuestion(data) {
    return await request('/api/templatequestion/edit/' + data.id, data, HttpMethod.PUT);
}

export async function reorderQuestions(stepId, data) {
    return await request('/api/templatequestion/reorder/' + stepId, data, HttpMethod.PUT)
}

function transformRequestData(data) {
    return data;
}