import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllContractUsersByContract(id, hash = "") {
    let url = '/api/contractuser/all/' + id;
    if (hash !== '') {
        url += `?hash=${hash}`;
    }
    return await request(url);
}

export async function getContractUserByHash(data) {
    return await request('/api/contractuser/get-contract-user-by-hash', data);
}

export async function getAllContractOwnersByContract(id, hash = "") {
    let url = '/api/contractuser/all/owners/' + id;
    if (hash !== '') {
        url += `?hash=${hash}`;
    }
    return await request(url);
}

export async function getAllContractCollaboratorsByContract(id) {
    return await request('/api/contractuser/all/collaborators/' + id);
}

export async function addContractUser(data) {
    return await request('/api/contractuser/add', data, HttpMethod.POST);
}

export async function deleteContractUser(id) {
    return await request('/api/contractuser/' + id, {}, HttpMethod.DELETE);
}

export async function contractUserChangeReviewer(data) {
    return await request('/api/contractuser/change/reviewer', data, HttpMethod.PATCH);
}

export async function contractUserChangeSignatory(data) {
    return await request('/api/contractuser/change/signatory', data, HttpMethod.PATCH);
}

export async function esignContract(data, hash = "") {
    let url = '/api/contractuser/esign';
    if (hash !== '') {
        url += `?hash=${hash}`;
    }
    return await request(url, data, HttpMethod.PATCH);
}

export async function rejectContractSignatory(id, data) {
    return await request('/api/contractuser/reject/' + id, data, HttpMethod.PATCH);
}

export async function placePlaceholderContractUser(id) {
    return await request('/api/contractuser/place/placeholder/' + id, {}, HttpMethod.PATCH);
}

export async function placeSignatureContractUser(id) {
    return await request('/api/contractuser/place/signature/' + id, {}, HttpMethod.PATCH);
}