import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  Typography,
  IconButton,
} from "@mui/material";
import SwitchControl from "../../../../../Components/Controls/Inputs/SwitchControl";
import PieChartComponent from "./PieChart";
import styles from "../../../DealRoom.module.css";
import {
  addNewShareholder,
  getAllShareholders,
} from "../../../../../Services/DealRoom/DealRoomService";
import RenderShareholder from "./RenderShareholder";

const CapTable = () => {
  const { id } = useParams();
  const [addShareholder, setAddShareholder] = useState(false);
  const [shareholderData, setShareholderData] = useState([]);
  const [shareholdingPiechartData, setShareholdingPiechartData] = useState([]);
  const [votingRightsPiechartData, setVotingRightsPiechartData] = useState([]);
  const [newShareholderData, setNewShareholderData] = useState({
    name: "",
    shares: null,
    class: "",
    nominalValue: null,
    votingRights: false,
  });

  useEffect(() => {
    getAllShareholdersHandler();
  }, []);

  const onChangeInputHandler = (e) => {
    let { name, value } = e.target;

    if (name === "votingRights") {
      setNewShareholderData((prevState) => ({
        ...prevState,
        [name]: e.target.checked,
      }));
      return;
    }

    setNewShareholderData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addColorInData = (array1, array2) => {
    if (array1.length !== array2.length) {
      return;
    }

    for (let i = 0; i < array1.length; i++) {
      if (typeof array1[i] === "object" && array1[i] !== null) {
        array1[i].color = array2[i];
      } else {
        return;
      }
    }
    return array1;
  };

  const getAllShareholdersHandler = () => {
    getAllShareholders(id)
      .then((res) => {
        if (res.status === 200) {
          let color = [
            "#003049",
            "#D62828",
            "#F77F00",
            "#FCBF49",
            "#EAE2B7",
            "#84b7ff",
            "#fbd234",
            "#DAF7A6",
            "#C70039",
            "#F7E019",
          ];
          setShareholderData(
            addColorInData(res.data, color.slice(0, res?.data?.length))
          );
          const mappedShareholdingPiechartData = res.data.map((item) => ({
            label: item.name,
            value: item.ownershipPercentage,
          }));
          const mappedVotingRightsPiechartData = res.data.map((item) => ({
            label: item.name,
            value: item.votingRightsPercentage,
          }));
          setShareholdingPiechartData([...mappedShareholdingPiechartData]);
          setVotingRightsPiechartData([...mappedVotingRightsPiechartData]);
        }
      })
      .catch((err) => {
        console.log("error in getAllShareholders", err);
      });
  };

  const addNewShareholderHandler = () => {
    addNewShareholder(id, newShareholderData)
      .then((res) => {
        if (res.status === 204) {
          getAllShareholdersHandler();
          setAddShareholder(false);
          setNewShareholderData({
            name: "",
            shares: null,
            class: "",
            nominalValue: null,
            votingRights: false,
          });
        }
      })
      .catch((err) => {
        console.log("error in addNewShareholder", err);
      });
  };

  return (
    <Box mt={3} display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" alignItems="center">
        <Typography variant="h5" fontWeight={500} mt={1}>
          Pre-Completion
        </Typography>
        <SwitchControl
          name="toggleName"
          defaultValue={0}
          showValues={false}
          style={{
            height: "30px",
            width: "78px",
            margin: "0px 0.5rem",
          }}
          controlCustomStyle={{
            height: "30px",
            width: "76px",
            marginLeft: "0px",
          }}
          tabActiveStyle={{
            marginLeft: "auto",
            height: "30px",
            width: "30px",
          }}
          tabWhiteStyle={{ height: "30px", width: "30px" }}
        />
        <Typography variant="h5" fontWeight={500} mt={1}>
          Post-Completion
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: "calc(100% - 2rem)" }}
      >
        <Box
          sx={{ width: "100%" }}
          display="flex"
          mt={4}
          justifyContent="center"
        >
          {shareholdingPiechartData.length > 0 && (
            <PieChartComponent
              data={shareholdingPiechartData}
              centerLabel={"Shareholding"}
            />
          )}
          {votingRightsPiechartData.length > 0 && (
            <PieChartComponent
              data={votingRightsPiechartData}
              centerLabel={"Voting Rights"}
            />
          )}
        </Box>
        <Box
          mt={10}
          mb={5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{ width: "calc(100% - 2rem)" }}
          className="capTable-shareholding-table"
        >
          <Box
            width={"80%"}
            display="flex"
            flexDirection="column"
            m={"auto"}
            justifyContent="center"
          >
            <Box
              display="flex"
              alignItems="center"
              className="capTable-shareholding-table-header"
              sx={{ width: "calc(100% + 1rem)", marginLeft: "-4rem" }}
            >
              <Typography
                display="flex"
                justifyContent="center"
                fontWeight={500}
                sx={{ width: "12.5rem !important" }}
              >
                Shareholder
              </Typography>
              <Typography
                display="flex"
                justifyContent="center"
                fontWeight={500}
                sx={{ width: "12.5rem !important" }}
              >
                Number
              </Typography>
              <Typography
                display="flex"
                justifyContent="center"
                fontWeight={500}
                sx={{ width: "12.5rem !important" }}
              >
                Class
              </Typography>
              <Typography
                display="flex"
                justifyContent="center"
                fontWeight={500}
                sx={{ width: "12.5rem !important" }}
              >
                Nominal Value
              </Typography>
              <Typography
                display="flex"
                justifyContent="center"
                fontWeight={500}
                sx={{ width: "12.5rem !important" }}
              >
                Total Nominal Value
              </Typography>
              <Typography
                display="flex"
                justifyContent="center"
                fontWeight={500}
                sx={{ width: "12.5rem !important" }}
              >
                Ownership %
              </Typography>
              <Typography
                display="flex"
                justifyContent="center"
                fontWeight={500}
                sx={{ width: "12.5rem !important" }}
              >
                Voting Rights
              </Typography>
            </Box>

            {shareholderData.length > 0 &&
              shareholderData.map((data, index) => (
                <RenderShareholder
                  data={data}
                  index={index}
                  id={id}
                  getAllShareholdersHandler={getAllShareholdersHandler}
                />
              ))}

            {addShareholder && (
              <Box
                display="flex"
                alignItems="center"
                pr={2}
                sx={{ width: "100%" }}
              >
                <Box className={styles.capTableInputWrapper}>
                  <Box className={styles.capTableInputContainer}>
                    <input
                      className={styles.inputBox}
                      value={newShareholderData.name}
                      name="name"
                      onChange={onChangeInputHandler}
                      type="text"
                      placeholder="Shareholder"
                    />
                  </Box>
                </Box>

                <Box className={styles.capTableInputWrapper}>
                  <Box className={styles.capTableInputContainer}>
                    <input
                      className={styles.inputBox}
                      value={newShareholderData.shares}
                      name="shares"
                      onChange={onChangeInputHandler}
                      type="number"
                      placeholder="Number"
                    />
                  </Box>
                </Box>

                <Box className={styles.capTableInputWrapper}>
                  <Box className={styles.capTableInputContainer}>
                    <input
                      className={styles.inputBox}
                      value={newShareholderData.class}
                      name="class"
                      onChange={onChangeInputHandler}
                      type="text"
                      placeholder="Class"
                    />
                  </Box>
                </Box>

                <Box className={styles.capTableInputWrapper}>
                  £ &nbsp;
                  <Box className={styles.capTableInputContainer}>
                    <input
                      className={styles.inputBox}
                      value={newShareholderData.nominalValue}
                      name="nominalValue"
                      onChange={onChangeInputHandler}
                      type="text"
                      placeholder="Nominal Value"
                    />
                  </Box>
                </Box>

                <Box
                  className="capTable-shareholder-input-div"
                  sx={{ width: "12.5%" }}
                >
                  £ 0
                </Box>
                <Box
                  className="capTable-shareholder-input-div"
                  sx={{ width: "12.5%" }}
                >
                  0 %
                </Box>
                <Box className="capTable-shareholder-input-div">
                  <Checkbox
                    checked={newShareholderData.votingRights}
                    name="votingRights"
                    onChange={onChangeInputHandler}
                  />
                  <IconButton
                    className="capTable-add-btn"
                    sx={{ marginLeft: "2rem" }}
                    onClick={() => addNewShareholderHandler()}
                  >
                    <img src="/images/icons/add.png" alt="add" />
                  </IconButton>
                </Box>
              </Box>
            )}

            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              mt={2}
            >
              <IconButton
                className="capTable-add-btn"
                onClick={() => setAddShareholder(!addShareholder)}
              >
                <img src="/images/icons/add.png" alt="add" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CapTable;
