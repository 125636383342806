import React, { useEffect, useState } from "react";
import LoginForm from "../../Components/Forms/Pages/User/LoginForm";
import { useForm } from "react-hook-form";
import { Paper, Button, Alert } from "@mui/material";
import strings from "../../localization";
import { useDispatch } from "react-redux";
import { changePageSizeState } from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import { login } from "../../Base/OAuth";
import { setAuth } from "../../Slices/AuthSlice";
import { useNavigate, Link } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import UserType from "../../Constants/User/UserType";
import { isProfileCompleted, isTrial } from "../../Util/PermissionUtil";

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const form = useForm();
    const { data, control, setValue, getValues, handleSubmit, handleCurrentPath, formState: { errors } } = form;
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const [messageSnackbar, setMessageSnackbar] = useState('');

    const showMessage = (message, alertType) => {
        setMessageSnackbar(message);
        setAlertType(alertType)
        setShowSnackbar(true);
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.FULL));
    }, [])


    const onSubmit = (data) => {

        login(data.email, data.password).then(response => {

            if (!response || !response.ok) {
                showMessage(strings.pages.login.wrongCredentials, 'error')
                return;
            }

            dispatch(setAuth(response.data));
            if (response.data.userProfileType === UserType.GUEST || !isProfileCompleted(response.data)) {
                navigate('/registration-details', { state: { userProfileType: response.data.userProfileType } });
            } else {
                if (isTrial(response.data) && response.data.userProfileType !== UserType.COMPANY_USER) {
                    navigate('/dashboard');
                    return;
                }

                navigate('/dashboard', { state: { firstTime: true } });
            }
        });
    }

    const handleGoogleAccount = () => {
        console.log('Continue with Google')
    }

    const handleLinkedinAccount = () => {
        console.log('Continue with Linkedin')
    }

    const handleOfficeAccount = () => {
        console.log('Continue with Office')
    }

    return <div id='half-page-container' className={'full-form-page auth-page'}>
        <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
            <Alert severity={alertType} onClose={handleCloseSnackbar}>
                {messageSnackbar}
            </Alert>
        </Snackbar>
        <div className='form-container'>
            <Paper className='paper login-paper'>

                <img className="logo-img" src='/images/logo.png' />
                <h1 className="login-signin-text">{strings.pages.login.signIn}</h1>

                <div className="text-with-link-container-login">
                    <p>{strings.pages.login.newToLogicalContracts}</p>
                    <Link to={'/registration'} className=" middle-text-link">
                        {strings.pages.login.createAccount}
                    </Link>
                </div>

                <LoginForm
                    errors={errors}
                    data={data}
                    form={form}
                    control={control}
                    values={getValues()}
                    setValue={setValue}
                    onSubmit={handleSubmit(onSubmit)}
                />

            </Paper>
        </div>
    </div>
};
export default Login;