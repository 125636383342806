import strings from "../../localization";

const Template = {
    ALL: -1,
    ACTIVE: 1,
    DEACTIVATED: 2
};

export function getAdminTemplateFilter() {
    return [
        {
            id: Template.ALL,
            name: getTemplateString(Template.ALL)
        },
        {
            id: Template.ACTIVE,
            name: getTemplateString(Template.ACTIVE)
        },
        {
            id: Template.DEACTIVATED,
            name: getTemplateString(Template.DEACTIVATED)
        }
    ]
}

export function adminTemplateDefaultValue() {
    return {
        id: Template.ALL,
        name: getTemplateString(Template.ALL)
    }
}

export function getTemplateString(template) {
    switch(template) {
        case Template.ALL: return strings.constants.admin.adminTemplate.all;
        case Template.ACTIVE: return strings.constants.admin.adminTemplate.active;
        case Template.DEACTIVATED: return strings.constants.admin.adminTemplate.deactivated;
    }
}

export default Template;