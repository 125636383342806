import React from 'react'
import styles from '../../DealRoom.module.css';
import strings from '../../../../localization'
import SupportForm from '../../../../Components/Forms/Pages/DealRoom/ConnectTabForms.js/SupportForm';

const LegalSupportPage = ({ setPageName }) => {
    return (
        <div className={styles.dealRoom}>
            <div className={styles.paymentformContainer}>
                <div className={styles.payPageHeading} >
                    <span > {strings.forms.dealRoom.connect.legalSupport.title}</span>
                </div>
                <div className={styles.legalSupportSubHeadingBox}>
                    <span className={styles.labelText}>  {strings.forms.dealRoom.connect.legalSupport.para}</span>
                </div>
                <div className={styles.legalSupportFormBox}>
                    
                <SupportForm setPageName={setPageName} />
                </div>
            </div>
        </div>
    )
}

export default LegalSupportPage