import React, { useEffect, useState, useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import strings from "../../../../localization";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import Button from "@mui/material/Button";
import FileUploadComponent from "./../../../Controls/FileUploadComponent";
import CloseIcon from "@mui/icons-material/Close";
import { toBase64 } from "../../../../Util/ImageUtil";
import SnackbarContext from "../../../../Context/SnackbarContext";
import { useSelector } from "react-redux";
import { esignContract } from "../../../../Services/Contract/ContractUserService";
import { useLocation } from "react-router-dom";

const SignForm = ({ setOpenSignModal, handleSignConfirm, collaborator }) => {
  const location = useLocation();
  const form = useForm();
  const {
    data,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;
  const { showMessage } = useContext(SnackbarContext);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [file, setFile] = useState(null);
  const [fileTypeErr, setFileTypeErr] = useState(false);
  const [fileRequiredErr, setFileRequiredErr] = useState(false);
  const currentUser = useSelector((state) => state.auth.user);

  useEffect(() => {}, []);

  const handleCancel = () => {
    setOpenSignModal(false);
  };

  const uploadFile = async (files) => {
    setFileTypeErr(false);
    setFileRequiredErr(false);
    setDisabledSubmit(true);
    const file = files[0];

    if (file === undefined) {
      setFileTypeErr(true);
    } else {
      setDisabledSubmit(false);
      setFile(file);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const onSubmit = async (data) => {
    let requestData = {
      fullName: data.fullName,
      initials: data.initials,
      newSignature: false,
      contractUserId: collaborator.id,
    };

    if (file) {
      requestData = {
        ...requestData,
        newSignature: true,
        signature: await toBase64(file),
        signatureName: data.signatureName,
      };
    }

    esignContract(requestData, location?.hash?.substring(1)).then(
      (response) => {
        handleSignConfirm();
      }
    );
  };

  return (
    <FormProvider {...form}>
      <div
        id="user-form-container"
        className="confirm-modal user-form-container sign-form"
      >
        <h1 className="header">{strings.forms.contractList.signForm.title}</h1>
        <div className="user-form-border"></div>

        <div className="form-content">
          <div className="item">
            <div className="item-field-container">
              <span className="info-icon-text">
                {strings.forms.contractList.signForm.fullName}
              </span>
              <TextFieldControl
                name="fullName"
                control={data}
                rules={{ required: true }}
                defaultValue={collaborator.fullName}
                fullWidth
                margin="normal"
                error={Boolean(errors.fullName)}
                helperText={errors.fullName && strings.forms.common.required}
                placeholder={strings.forms.contractList.signForm.enterFullName}
              />
            </div>

            <div className="item-field-container">
              <span className="info-icon-text">
                {strings.forms.contractList.signForm.initials}
              </span>
              <TextFieldControl
                name="initials"
                control={data}
                rules={{ required: true }}
                defaultValue={collaborator?.fullName
                  .split(" ")
                  .map((item) => {
                    return item[0];
                  })
                  .join("")}
                fullWidth
                margin="normal"
                error={Boolean(errors.initials)}
                helperText={errors.initials && strings.forms.common.required}
                placeholder={strings.forms.contractList.signForm.enterInitials}
              />
            </div>

            <div className="item-field-container">
              {!currentUser.signature && (
                <React.Fragment>
                  <span className="info-icon-text">
                    {strings.forms.contractList.signForm.uploadDocument}
                  </span>
                  <div className="file-upload-container">
                    {file ? (
                      <div className="file-name">
                        <span>
                          {collaborator.user && collaborator.user.signatureName
                            ? collaborator.user.signatureName
                            : file.name}
                        </span>
                        <CloseIcon
                          className="close-icon"
                          onClick={handleRemoveFile}
                        />
                      </div>
                    ) : (
                      <FileUploadComponent
                        accept=".png"
                        hideUploadButton={true}
                        onDrop={uploadFile}
                      />
                    )}
                  </div>

                  {fileTypeErr && (
                    <span className="error-msg">
                      {strings.forms.contractList.signForm.signatureFileErr}
                    </span>
                  )}

                  {fileRequiredErr && (
                    <span className="error-msg">
                      {strings.forms.common.required}
                    </span>
                  )}
                </React.Fragment>
              )}

              {currentUser.signature && (
                <img
                  src={currentUser.signature}
                  className={"signature-image"}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>

        <div className="submit-container">
          <Button className="secondary-neutral-btn btn" onClick={handleCancel}>
            {strings.forms.contractList.signForm.cancel}
          </Button>

          <Button
            className="secondary-btn btn"
            onClick={handleSubmit(onSubmit)}
          >
            {collaborator.user && collaborator.user.signature
              ? strings.forms.contractList.signForm.sign
              : strings.forms.contractList.signForm.adoptSignature}
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default SignForm;
