import {useDispatch, useSelector} from "react-redux";
import strings from "../../localization";
import ValidationPatterns from "../../Constants/Base/ValidationPatterns";
import {FormProvider, useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import ImageUploadComponent from "../../Components/Controls/ImageUploadComponent";
import TextFieldControl from "../../Components/Controls/Inputs/TextFieldControl";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadComponent from "../../Components/Controls/FileUploadComponent";
import Button from "@mui/material/Button";
import {getCurrentUser, updateUserProfile} from "../../Services/User/UserService";
import SnackbarContext from "../../Context/SnackbarContext";
import {setAuth} from "../../Slices/AuthSlice";
import {logout} from "../../Base/OAuth";
import {useNavigate} from "react-router-dom";
import {toBase64} from "../../Util/ImageUtil";
import AddIcon from '@mui/icons-material/Add';
import Modal from "@mui/material/Modal";
import AdoptSignatureForm from "../../Components/Forms/Pages/ContractList/AdoptSignatureForm";

const formRules = {
    'fullName': {required: true},
    'email': {
        required: {value: true, message: strings.forms.common.required},
        pattern: {
            value: ValidationPatterns.EMAIL,
            message: strings.forms.dashboard.myDetails.generalInformationForm.emailError
        }
    }
}

const BaseUserDetails = ({ handleAddInfo, userProfileType }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user)

    const form = useForm();
    const {data, setValue, getValues, control, handleSubmit, formState: {errors}, setError, trigger} = form;
    const [image, setImage] = useState('');
    const [file, setFile] = useState(null)
    const [fileErr, setFileErr] = useState(false)
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const {showMessage} = useContext(SnackbarContext);
    const [openAdoptSignModal, setOpenAdoptSignModal] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
        setImage(user.profileImage);
    }, [user])


    const fetch = () => {
    }

    const uploadFile = async (files) => {
        setFileErr(false)
        setDisabledSubmit(true)
        const file = files[0];

        if (file === undefined) {
            setFileErr(true)
        } else {
            setDisabledSubmit(false);
            setFile(file)
        }
    }

    const onSubmit = async (data) => {

        const signature = file ? await toBase64(file) : user.signature;

        updateUserProfile({
            ...data,
            profileImage: image,
            signature: signature ? signature : ''
        }).then(response => {
            if (!response || !response.ok) {
                showMessage('Error updating profile', 'error');
                return;
            }

            showMessage('Profile updated');
            setFile(null);

            if (user.email !== data.email) {
                logout();
                navigate('/login');
            } else {
                getCurrentUser().then(response => {
                    dispatch(setAuth(response.data));
                })
            }
        })
    }

    const handleRemoveFile = () => {
        setFile(null)
    }

    return (<FormProvider {...form}>
            <h1 className="header">{strings.forms.dashboard.myDetails.generalInformationForm.generalInformation}</h1>
            
            <div className="profile-picture-and-add-info-btn">
                <div className="profile-picture-container">
                    <div className="profile-picture-icon-container">
                        <ImageUploadComponent
                            accept={['.jpeg', '.jpg', '.png', '.svg']}
                            hideUploadButton={true}
                            setImage={setImage}
                            image={image}
                        />
                    </div>
                    <div className="profile-picture-text">
                        <h2 className="subtitle">{strings.forms.dashboard.myDetails.generalInformationForm.profilePicture}</h2>
                    </div>
                </div>

                {userProfileType == 2 &&
                    <Button className="neutral-btn btn add-info-btn" onClick={handleAddInfo}>
                        <AddIcon />
                        {strings.forms.dashboard.myDetails.generalInformationForm.additionalInfo}
                    </Button>
                }
            </div>

            <div className="dashboard-item">
                <div className="item-field-container">
                    <span className="info-icon-text">
                        {strings.forms.dashboard.myDetails.generalInformationForm.fullName}
                    </span>
                    <TextFieldControl
                        name='fullName'
                        control={data}
                        rules={formRules['fullName']}
                        defaultValue={user.fullName}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.fullName)}
                        helperText={errors.fullName && strings.forms.common.required}
                        placeholder={strings.forms.dashboard.myDetails.generalInformationForm.enterFullName}
                        icon={<img src="/images/icons/profile1.png"/>}
                    />
                </div>

                <div className="item-field-container">
                    <span className="info-icon-text">
                        {strings.forms.dashboard.myDetails.generalInformationForm.email}
                    </span>
                    <TextFieldControl
                        name='email'
                        control={data}
                        rules={formRules['email']}
                        defaultValue={user.email}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.email)}
                        helperText={errors.email && errors.email.message}
                        placeholder={strings.forms.dashboard.myDetails.generalInformationForm.enterEmail}
                        icon={<img src="/images/icons/email.png"/>}
                    />
                </div>


                <div className="item-field-container">
                    <span className="info-icon-text">
                        {strings.forms.dashboard.myDetails.generalInformationForm.addSignature}
                    </span>
                    <div className="file-upload-container" onClick={() => setOpenAdoptSignModal(true)}>
                        {strings.forms.dashboard.myDetails.generalInformationForm.addSignature}
                    </div>

                    {/* <div className="file-upload-container">
                        {file
                            ? <div className="file-name">
                                <span>{file.name}</span>
                                <CloseIcon className="close-icon" onClick={handleRemoveFile}/>
                            </div>
                            : <FileUploadComponent placeholder={'Upload signature'} accept='.png' hideUploadButton={true} onDrop={uploadFile}/>
                        }
                    </div>
                    {fileErr &&
                    <span className="error-msg">
                            {strings.forms.dashboard.myDetails.generalInformationForm.signatureFileErr}</span>
                    } */}
                    {
                        user.signature &&
                        <img src={user.signature} className={'signature-image'}/>
                    }
                </div>
            </div>

            <div className="submit-container">
                <Button className="secondary-btn btn" onClick={handleSubmit(onSubmit)} style={{marginBottom: '20px'}}>
                    {strings.forms.dashboard.myDetails.generalInformationForm.save}</Button>
            </div>

            <Modal
                    open={openAdoptSignModal}
                    onClose={() => setOpenAdoptSignModal(false)}
                    className='confirm-modal-container'
                >
                    <AdoptSignatureForm
                        setOpenAdoptSignModal={setOpenAdoptSignModal}
                    />
                </Modal>
        </FormProvider>
    )
}

export default BaseUserDetails;