import React, {useEffect, useState} from "react";
import strings from "../../../../../localization";
import CloseIcon from '@mui/icons-material/Close';
import {getContracts} from "../../../../../Services/AdminFakeApi";
import AutocompleteSelectControl from '../../../../Controls/AutocompleteSelectControl';
import {getAllTemplates} from '../../../../../Services/Template/TemplateService';
import {deleteContractPackage} from '../../../../../Services/ContractCategory/PackageService';

const ContractForm = ({
                        data,
                        values,
                        errors,
                        onContractChanged
                    }) => {
    const [otherContracts, setOtherContracts] = useState([])
    const [contractsIdList, setContractsIdList] = useState([])
    const [contractsFullObjList, setContractsFullObjList] = useState([])

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
        onContractChanged(contractsIdList)
    }, [contractsIdList])

    const fetch = () => {
        
        getAllTemplates({}).then(response => {

            if(!response || !response.ok) {
                return;
            }

            if (response.data.length > 0) {
                setOtherContracts(response.data)
            }
        })
    }

    const handleCloseTag = (id) => {

        setContractsFullObjList(contractsFullObjList.filter(e => e.id !== id))

        let contract = getContracts().find(e => e.id === id)

        setOtherContracts([
            ...otherContracts,
            contract
        ])

        setContractsIdList([
            ...contractsIdList,
            contract.id
        ])
    }

    const getDataItem = () => {
        let result = [];

        if (contractsFullObjList.length > 0) {
            for (let item of contractsFullObjList) {

                result.push(
                    <div className="tag" key={item.id}>
                        <span className="tag-text">{item.name}</span>
                        <CloseIcon className="close-icon" onClick={() => handleCloseTag(item.id)} />
                    </div>
                )
            }
        } else {
            return []
        }
        return result
    }

    const handleInputChange = (contract) => {
        
        setContractsFullObjList([
            ...contractsFullObjList,
            contract
        ])
        
        setOtherContracts(otherContracts.filter(e => e.id !== contract.id))
                
        setContractsIdList([
            ...contractsIdList,
            contract.id
        ])
    }

    return (
        <div className="item">
            <div className="item-title">
                <span>{strings.forms.admin.adminPackageForm.contracts.contracts}</span>
            </div>

            <AutocompleteSelectControl 
                name={'contracts'}
                control={data}
                options={otherContracts}
                handleInputChange={handleInputChange}
                placeholder={strings.forms.admin.adminPackageForm.contracts.selectContracts}
            />

            <div className="tag-list">
                { getDataItem()}
            </div>
        </div>
    )
}

export default ContractForm;