import { Button } from "@mui/material";
import React from "react";

const InfoModal = (props) => {
    return(
        <div id="info-modal" className={props.style ? props.style + " confirm-modal info-modal" : "confirm-modal info-modal"}>
            <p className="info-title">{props.title}</p>
            <div className="confirm-buttons">
                <Button className={props.btnStyle ? props.btnStyle : "neutral-btn btn"} variant="contained" 
                    onClick={props.handleCloseModal}>{props.cancelBtn}</Button>
            </div>
        </div>                     
    )
}

export default InfoModal;