import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import strings from '../../../../localization';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {getAllFolders} from "../../../../Services/Folder/FolderService";
import Tooltip from "@mui/material/Tooltip";

const FoldersForm = ({
                         submitMoveToFolder,
                         setDisplayForms,
                         contract,
                         setIsOpenContractOptionMenu
                     }) => {

    const form = useForm();
    const [folders, setFolders] = useState([])

    useEffect(() => {

        getAllFolders().then(response => {

            if (!response || !response.ok) {
                return;
            }

            setFolders([{name: 'Home', id: 0}, ...response.data]);
        })

    }, [])

    const handleCloseForms = () => {
        setDisplayForms(false)
        setIsOpenContractOptionMenu(false)
    }

    const submitFolder = (folder) => {
        submitMoveToFolder(folder)
    }

    const renderFolders = () => {
        let result = []

        for (let folder of folders) {
            result.push(
                <div className={ contract && contract.folder && contract.folder.id === folder.id ?
                    "folder selected" : "folder"} onClick={() => submitFolder(folder)}>
                    <span>{folder.name}</span>
                </div>
            )
        }
        return result
    }

    return (
        <div className="form-container folders-form">
            <FormProvider {...form}>
                <div className="header">
                    <h1 className="title info-icon-title">{strings.forms.contractList.foldersForm.title}
                        <Tooltip 
                            title={strings.forms.contractList.foldersForm.foldersTooltip}
                            placement="top" 
                            arrow>
                            <IconButton><img src="/images/icons/info.png" /></IconButton>
                        </Tooltip>
                        
                    </h1>
                    <CloseIcon className="close-icon" onClick={handleCloseForms}/>
                </div>
                <div className="border"></div>
                <p>{strings.forms.contractList.foldersForm.description}</p>
                <div className="folders-container">
                    {renderFolders()}
                </div>
            </FormProvider>
        </div>
    )
}

export default FoldersForm;