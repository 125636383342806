import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function resetPasswordRequest(data) {
    return await request('/api/user/request-password-reset', data, HttpMethod.POST);
}

export async function registration(data) {
    return await request('/api/user/register', data, HttpMethod.POST);
}

export async function getUserWithResetPasswordToken(token) {
    return await request('/api/user/reset-password-token/' + token);
}

export async function activateUser(token) {
    return await request('/api/user/activate/' + token, {}, HttpMethod.POST);
}

export async function getUser(id) {
    return await request('/api/user/' + id);
}

export async function getCurrentUser() {
    return await request('/api/user/get-current-user-data')
}

export async function editUser(data) {
    return await request('/api/user', data, HttpMethod.PUT);
}

export async function deleteUser(id) {
    return await request('/api/user/' + id, {}, HttpMethod.DELETE);
}

export async function changeUserPassword(data) {
    return await request('/api/user/change-password', data, HttpMethod.PUT);
}

export async function updateUserProfile(data) {
    return await request('/api/user/profile/update', data, HttpMethod.PUT);
}

export async function updateUserCompanyProfile(data) {
    return await request('/api/user/company/update', data, HttpMethod.PUT);
}

export async function confirmUserPassword(data) {
    return await request('/api/user/reset-password', data, HttpMethod.POST);
}

export async function getAllUsers() {
    return await request('/api/user/all');
}

export async function getAllUsersWithoutImageAndSignature() {
    return await request('/api/user/all/without-image-signature');
}

export async function sendUserConfirmEmail(id) {
    return await request('/api/user/admin/send-confirm-email/' + id, {}, HttpMethod.PATCH);
}

export async function adminAddUser(data) {
    return await request('/api/user/admin/add', data, HttpMethod.POST);
}

export async function adminEditUser(id, data) {
    return await request('/api/user/admin/edit/' + id + "/" + data.companyId, data, HttpMethod.PUT);
}

export async function adminDeactivateUser(id) {
    return await request('/api/user/admin/deactivate/' + id, {}, HttpMethod.PUT);
}

export async function adminDeleteUser(id) {
    return await request('/api/user/admin/delete/' + id, {}, HttpMethod.DELETE);
}

export async function discardFirstLogin() {
    return await request('/api/user/discard-first-login', {}, HttpMethod.PATCH);
}

export async function updateUserSignature(data) {
    return await request('/api/user/update-signature', data, HttpMethod.PATCH);
}

export async function userExist(email) {
    return await request('/api/user/exist/' + email, {}, HttpMethod.GET);
}

export async function getAllCompanyUsers(companyId) {
    return await request('/api/user/company/' + companyId);
}

export async function getUserCsToken(email, fullName) {
    return await request('/api/user/get-cs-token/' + email + "/" + fullName);
}

function transformRequestData(data) {
    return {
        ...data,
    };
}
