import React, {useEffect, useState} from "react";
import {Button, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import strings from "./../../localization";
import {getComments} from '../../Services/ContractFakeApi';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CommentsForm from './../../Components/Forms/Pages/ContractList/CommentsForm';
import ReplaysForm from './../../Components/Forms/Pages/ContractList/ReplaysForm';
import {formatDateWithTime} from '../../Util/DateUtil';
import {changeCommentResolve, deleteComment, getAllCommentsByContract} from "../../Services/Contract/CommentService";
import Tooltip from "@mui/material/Tooltip";
import ContractStatus from "../../Constants/Contract/ContractStatus";
import { MoreVert } from "@mui/icons-material";

const CommentList = ({
                         setDisplayForms,
                         contract,
                         editor,
                         contractContent,
                         currentUser
                     }) => {

    const [comments, setComments] = useState([])
    const [replayField, setReplayField] = useState(false)
    const [selectedCommentForReplay, setSelectedCommentForReplay] = useState(-1)
    const [optionMenuAnchorEl, setOptionMenuAnchorEl] = useState(null);
    const [selectedComment, setSelectedComment] = useState(null);
    const [selectedCommentForEdit, setSelectedCommentForEdit] = useState(null);

    useEffect(() => {
        fetch();
    }, [])

    useEffect(() => {
        renderComments()
        highlightContent(comments, contract)
    }, [comments, contract.state])

    const fetch = () => {

        getAllCommentsByContract(contract.id).then(response => {
            if (!response || !response.ok) {
                return;
            }

            setComments(response.data);
            
            setSelectedCommentForEdit(null);
            setSelectedComment(null);
        });
    }

    const highlightContent = (comments, contract, selectedComment = null) => {

        if (!editor) {
            return;
        }

        if (contract.state >= ContractStatus.REVIEWED) {
            editor.data.set(contractContent, { suppressErrorInCollaboration: true } )
            return;
        }

        let content = editor.getData();

        if (!content) {
            return;
        }

        for (let comment of comments) {
            if (comment.resolved || !comment.contractContent || comment.contractContent.trim() === '') {
                continue;
            }

            const opacity = selectedComment != null && selectedComment.id === comment.id ? 0.8 : 0.2

            let color = 58;

            if (contract.user && comment.user && contract.user.id === comment.user.id) {
                color = 88;
            }

            const markup = "<span style='background-color:hsla(" + color + ", 100%, 50%, " + opacity + ");'>" + comment.contractContent + "</span>";
            content = content.replace(comment.contractContent, markup)
            editor.data.set(content, { suppressErrorInCollaboration: true } )
        }
    }

    const handleCloseForms = () => {
        setDisplayForms(false)
    }

    const handleAddReplay = (commentId) => {
        if (commentId === selectedCommentForReplay) {
            setReplayField(!replayField)
            setSelectedCommentForReplay(-1)
        } else {
            setReplayField(true)
            setSelectedCommentForReplay(commentId)
        }
    }

    const handleResolveComment = (commentId) => {

        changeCommentResolve({
            id: commentId,
            resolve: true,
            userEmail: currentUser.email
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }

            fetch();
        })
    }

    const getReplays = (comment, comments) => {
        let result = [];

        for (let item of comments) {

            if (item.parent && item.parent.id === comment.id) {
                result.push(item);
            }
        }

        return result;
    }

    const handleMenuOpen = (event, comment) => {
        event.preventDefault();

        setSelectedComment(comment);
        setOptionMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setSelectedComment(null);
        setOptionMenuAnchorEl(null);
    };

    const handleCancelEditComment = () => {
        setSelectedComment(null);
        setSelectedCommentForEdit(null);
    };

    const handleEditComment = () => {  
        setSelectedCommentForEdit(selectedComment);
        handleMenuClose();
    }

    const handleDeleteComment = () => { 
        deleteComment(selectedComment.id).then(response => {
            if (!response || !response.ok) {
                return;
            }

            fetch();
        });

        handleMenuClose();
    }

    const renderComments = () => {
        let result = []

        for (let comment of comments) {

            if (comment.parent) {
                continue;
            }

            let replays = getReplays(comment, comments);

            result.push(
                <div onClick={() => {
                    highlightContent(comments, contract, comment)
                }} className="comment-container" key={'comment-' + result.length}>
                    <div className="header">
                        <div className="user">
                            <img className="image"
                                 src={comment?.user && comment.user.profileImage ? comment.user.profileImage : "/images/icons/profile-default-small.png"}/>
                            <div className="user-info">
                                <span className="name">{comment?.user ? comment?.user?.fullName : comment.externalUserFullName}</span>
                                <span className="date">{formatDateWithTime(new Date(comment.dateCreated))}</span>
                            </div>
                        </div>

                        <div className="resolved-container">
                            {comment.resolved
                                ? <span
                                    className="resolved resolved-info">{strings.forms.contractList.commentsForm.resolved}</span>
                                : <Button className="resolved mark-resolved-btn"
                                          disabled={contract.state >= ContractStatus.REVIEWED}
                                          onClick={() => handleResolveComment(comment.id)}>
                                    {strings.forms.contractList.commentsForm.markResolved}
                                </Button>
                            }
                            { (currentUser.email === comment?.user?.email || currentUser.email === comment?.externalUserEmail) &&
                                <div className={'more-container'}>
                                    <IconButton
                                        aria-haspopup="true"
                                        onClick={(event) => handleMenuOpen(event, comment)}
                                    >
                                        <MoreVert/>
                                    </IconButton>
                
                                    <Menu
                                        id='comment-list-menu'
                                        anchorEl={optionMenuAnchorEl}
                                        open={Boolean(optionMenuAnchorEl)}
                                        onClose={() => handleMenuClose()}
                                    >
                                        <MenuItem onClick={() => handleEditComment()}>
                                            <ListItemIcon>
                                                <img src="/images/icons/pen.png"/>
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.forms.contractList.commentsForm.edit}/>
                                        </MenuItem>
                                        
                                        <MenuItem onClick={() => handleDeleteComment()}>
                                            <ListItemIcon>
                                                <img src="/images/icons/delete.png"/>
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.forms.contractList.commentsForm.delete}/>
                                        </MenuItem>
                                    </Menu>
                                </div> 
                            }
                        </div>
                    </div>
                    <p className="comment-text">{comment.content}</p>

                    {replays.length > 0 &&
                        <React.Fragment>
                            <div className="replays-counter-container">

                                {replayField && selectedCommentForReplay === comment.id
                                    ? <>
                                        <span className="text">
                                            <span className="number">{replays.length}</span>
                                            {replays.length > 1
                                                ? strings.forms.contractList.commentsForm.replays
                                                : strings.forms.contractList.commentsForm.replay
                                            }
                                        </span>
                                        <span className="hide-btn" onClick={() => handleAddReplay(comment.id)}>
                                            {strings.forms.contractList.commentsForm.hide}
                                        </span>
                                    </>

                                    : <div className="replays-counter-link" onClick={() => handleAddReplay(comment.id)}>
                                        <img src="/images/icons/replay.svg" className="replay-icon"/>
                                        <span className="text">
                                            <span className="number">{replays.length}</span>
                                            {replays.length > 1
                                                ? strings.forms.contractList.commentsForm.replays
                                                : strings.forms.contractList.commentsForm.replay
                                            }
                                        </span>
                                    </div>
                                }
                            </div>

                            {replayField && selectedCommentForReplay === comment.id &&
                                renderCommentReplays(replays)
                            }
                        </React.Fragment>
                    }

                    {
                        contract.state < ContractStatus.REVIEWED &&
                        <React.Fragment>
                            {replayField && selectedCommentForReplay === comment.id
                                ? replays.length === 0
                                    ? <>
                                <span className="hide-btn" onClick={() => handleAddReplay(comment.id)}>
                                    {strings.forms.contractList.commentsForm.hide}
                                </span>
                                        <ReplaysForm
                                            parent={comment}
                                            contract={contract}
                                            fetch={fetch}
                                            user={currentUser}
                                            comment={selectedCommentForEdit}
                                            onCancel={handleCancelEditComment}
                                        />
                                    </>
                                    : <ReplaysForm
                                        parent={comment}
                                        contract={contract}
                                        fetch={fetch}
                                        user={currentUser}
                                        comment={selectedCommentForEdit}
                                        onCancel={handleCancelEditComment}
                                    />
                                : replays.length === 0 &&
                                <span className="replay-btn" onClick={() => handleAddReplay(comment.id)}>
                                {strings.forms.contractList.commentsForm.replay}
                            </span>
                            }
                        </React.Fragment>
                    }

                    <div className="border"></div>
                </div>
            )
        }
        return result
    }

    const renderCommentReplays = (replays) => {
        let result = []

        for (let replay of replays) {
            result.push(
                <div className="replay-container comment-container">
                    <div className="header">
                        <div className="user">
                            <img className="image"
                                src={replay?.user && replay.user.profileImage ? replay.user.profileImage : "/images/icons/profile-default-small.png"}/>
                            <div className="user-info">
                                <span className="name">{replay?.user ? replay?.user?.fullName : replay.externalUserFullName}</span>
                                <span className="date">{formatDateWithTime(new Date(replay.dateCreated))}</span>
                            </div>
                        </div>
                        { (currentUser.email === replay?.user?.email || currentUser.email === replay?.externalUserEmail) &&
                            <div className={'more-container'}>
                                <IconButton
                                    aria-haspopup="true"
                                    onClick={(event) => handleMenuOpen(event, replay)}
                                >
                                    <MoreVert/>
                                </IconButton>
            
                                <Menu
                                    id='comment-list-menu'
                                    anchorEl={optionMenuAnchorEl}
                                    open={Boolean(optionMenuAnchorEl)}
                                    onClose={() => handleMenuClose()}
                                >
                                    <MenuItem onClick={() => handleEditComment()}>
                                        <ListItemIcon>
                                            <img src="/images/icons/pen.png"/>
                                        </ListItemIcon>
                                        <ListItemText inset primary={strings.forms.contractList.commentsForm.edit}/>
                                    </MenuItem>
                                    
                                    <MenuItem onClick={() => handleDeleteComment()}>
                                        <ListItemIcon>
                                            <img src="/images/icons/delete.png"/>
                                        </ListItemIcon>
                                        <ListItemText inset primary={strings.forms.contractList.commentsForm.delete}/>
                                    </MenuItem>
                                </Menu>
                            </div> 
                        }
                        </div>
                    <p className="comment-text">{replay.content}</p>
                </div>
            )
        }
        return result
    }

    return (
        <div className="form-container comments-form">
            <div className="header">
                <h1 className="title info-icon-title">{strings.forms.contractList.commentsForm.title}
                    <Tooltip
                        title={strings.forms.contractList.commentsForm.commentsTooltip}
                        placement="top"
                        arrow>
                        <IconButton><img src="/images/icons/info.png"/></IconButton>
                    </Tooltip>
                </h1>
                <CloseIcon className="close-icon" onClick={handleCloseForms}/>
            </div>
            <div className="border"></div>

            {renderComments()}

            {
                contract.state < ContractStatus.REVIEWED &&
                <CommentsForm
                    editor={editor}
                    contract={contract}
                    fetch={fetch}
                    user={currentUser}
                    comment={selectedCommentForEdit}
                    onCancel={handleCancelEditComment}
                />
            }

        </div>
    )
}

export default CommentList;