import {
    Box
} from "@mui/material";
import React from 'react';
import MainFolders from './MainFolders';

const LeftSideBar = ({ mainFoldersData, setActiveTab}) => {

    return (
        <Box className="dataroom-left-sidebar" bgcolor="white">
            <Box
                display="flex"
                flexDirection="column"
                backgroundColor=""
                sx={{ position: "relative" }}
            >
                {Object.entries(mainFoldersData)?.map(([key, value], index) => {
                    return (
                        <MainFolders
                            folderName={key}
                            index={index}
                            subFoldersData={value}
                            key={index}
                            setActiveTab={setActiveTab}
                           
                        />
                    );
                })}
            </Box>
        </Box>
    )
}

export default LeftSideBar