import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import { FormProvider } from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import TextFieldPasswordControl from "../../../Controls/Inputs/TextFieldPasswordControl";
import CheckBoxControl from "../../../Controls/Inputs/CheckBoxControl";
import { Link } from "react-router-dom";

const RegisterForm = ({
    onSubmit,
    data,
    form,
    errors,
    control,
    setValue,
    formRules
}) => {

    return <FormProvider {...form}>
        <form id='auth-form' className='form register' action="#">

            <TextFieldControl
                name='fullName'
                control={data}
                defaultValue=''
                rules={formRules['fullName']}
                fullWidth
                margin="normal"
                error={Boolean(errors.fullName)}
                helperText={errors.fullName && errors.fullName.message}
                placeholder={strings.forms.registration.fullName}
                icon={<img src="/images/icons/profile1.png" />}
            />

            <TextFieldControl
                name='email'
                control={data}
                defaultValue=''
                rules={formRules['email']}
                fullWidth
                margin="normal"
                error={Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
                placeholder={strings.forms.login.email}
                icon={<img src="/images/icons/email.png" />}
            />

            <TextFieldPasswordControl
                name='password'
                control={data}
                defaultValue=''
                rules={formRules['password']}
                fullWidth
                type='password'
                margin="normal"
                error={Boolean(errors.password)}
                helperText={errors.password && errors.password.message}
                placeholder={strings.forms.login.password}
            />

            <p className='register-terms-service-and-privacy-policy-text'>{strings.forms.registration.creatingAccountAgree}
                <a target="_blank" href="https://logicalcontracts.io/terms">
                    {strings.forms.registration.serviceTerms}
                </a>
                {strings.forms.registration.and}
                <a target="_blank" href="https://logicalcontracts.io/privacy">
                    {strings.forms.registration.privacyPolicy}
                </a>
            </p>

            {/* <CheckBoxControl
                control={control}
                name={'subscribe'}
                control={data}
                label={strings.forms.registration.subscribe}
                setValue={setValue}
            /> */}
            <div className='submit-container'>
                <Button className="auth-btn btn" variant="contained" onClick={onSubmit}>
                    {strings.forms.registration.createAccount}
                </Button>
            </div>

        </form>
    </FormProvider>
}

export default RegisterForm;
