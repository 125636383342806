import React from "react";
import styles from "./DealRoom.module.css";

const RequestMsgPage = ({ setPageName }) => {
  return (
    <div className={styles.landingPageBody}>
      <img src={"/images/android-chrome-256x256.png"} alt="loading logo" />
      <div className={styles.payPageHeading} style={{ margin: "2rem 0" }}>
        <span>
          Your request has been submitted, we will be in touch shortly.
        </span>
      </div>
      <div
        className={styles.addButton}
        style={{ borderRadius: "7px" }}
        onClick={() => setPageName("connectPage")}
      >
        <span style={{ fontSize: "18px" }}>OK</span>
      </div>
    </div>
  );
};

export default RequestMsgPage;
