import React, { useState } from "react";
import strings from "../../../localization";
import { Link, useLocation } from "react-router-dom";
import AIRequestDataView from "./AIRequestDataView";
import AIData from './AIData';
import AIRequestData from './AIRequestData';
import AIDataCreate from './AIDataCreate';
import { useNavigate } from 'react-router-dom';
import { AIDatacolumns } from "../../../Constants/Admin/AIDataModel";

const minWidth = 80;
function AdminAIData() {
    const navigate = useNavigate()  

  const [subOption, setSubOption] = useState("");

    const setPathHandler = (path) => {
        navigate(path)
    }
 
    const subOptions = () => {
        if (subOption === '')
            return (
                <div id="admin-dashboard" className="admin-dashboard-container-ai">
                    <div className={'admin-dashboard-part'} onClick={() => setPathHandler("/AIData")} >
                        <p className="aiData" >{strings.pages.adminAIData.subOptions.firstOption}</p>
                    </div>
                    <div className={'admin-dashboard-part'} onClick={() => setPathHandler("/AIRequestData")} >
                        <p className="aiData" >{strings.pages.adminAIData.subOptions.secondOption}</p>
                    </div >
                </div>
            )
    }

  return (
    <>
      {subOptions()}
      {/* {subOption === "firstOption" && (
        <AIData setSubOption={setSubOption} />
      )}
      {subOption === "secondOption" && (
        <AIRequestData setSubOption={setSubOption} />
      )} */}
      {subOption === "AIDataCreate" && (
        <AIDataCreate setSubOption={setSubOption} />
      )}
      {subOption === "AIRequestDataView" && (
        <AIRequestDataView setSubOption={setSubOption} columns={AIDatacolumns} />
      )}
    </>
  );
}

export default AdminAIData;
