import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getFolder(id) {
    return await request('/api/folder/' + id);
}

export async function getAllFolders() {
    return await request('/api/folder/all');
}

export async function getSharedFolders() {
    return await request('/api/folder/shared');
}

export async function getAllFolderByParent(id) {
    return await request('/api/folder/getByParent/' + id);
}

export async function deleteFolder(id) {
    return await request('/api/folder/' + id, {}, HttpMethod.DELETE);
}

export async function addFolder(data) {
    return await request('/api/folder/add', data, HttpMethod.POST);
}

export async function editFolder(data) {
    return await request('/api/folder/', data, HttpMethod.PUT);
}

export async function moveFolderToFolder(sourceId, destinationId) {
    return await request('/api/folder/move/' + sourceId + '/' + destinationId, {}, HttpMethod.PUT);
}

function transformRequestData(data) {
    return data;
}