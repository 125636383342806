import React, {useEffect, useState} from "react";
import strings from "../../../../../localization";
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import ConfirmModal from '../../../../Modals/ConfirmModal';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {reorder} from "../../../../../Util/DnDUtil";
import AutocompleteSelectControl from '../../../../Controls/AutocompleteSelectControl';
import {
    addTemplateContractCategory, deleteTemplateContractCategory,
    getAllTemplateContractCategoriesByTemplate
} from "../../../../../Services/Template/TemplateContractCategoryService";
import Tooltip from "@mui/material/Tooltip";

const CategoriesForm = ({
                            formRules,
                            values,
                            setValue,
                            control,
                            errors,
                            data,
                            form,
                            categories, template
                        }) => {

    const [openModal, setOpenModal] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null)

    const [templateCategories, setTemplateCategories] = useState([]);

    useEffect(() => {

        fetch();

    }, [])

    const fetch = () => {

        getAllTemplateContractCategoriesByTemplate(template.id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            setTemplateCategories(response.data);
        });

    }

    const handleInputChange = (category) => {

        addTemplateContractCategory({
            templateId: template.id,
            contractCategoryId: category.id
        }).then(response => {

            fetch();
        })
    }

    const getAutoCompleteCategories = () => {

        let result = [];

        for (let category of categories) {

            if (templateCategories.find(x => x.contractCategory.id === category.id)) {
                continue
            }

            result.push(category)
        }

        return result;
    }

    const handleOpenModal = (category) => {
        setOpenModal(true)
        setSelectedCategory(category.id)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleConfirm = category => {

        deleteTemplateContractCategory(category.id).then(response => {
            fetch();
            setOpenModal(false)
        });
    }

    const onDragEnd = (result) => {
        if (!result.source || !result.destination) {
            return;
        }

        const stages = reorder(templateCategories, result.source.index, result.destination.index);
        setTemplateCategories(stages);
    }

    const renderCategories = () => {
        let result = [];

        if (templateCategories.length > 0) {
            for (let category of templateCategories) {
                result.push(
                    <Draggable
                        key={category.id}
                        draggableId={'stage-' + category.id}
                        index={result.length}
                    >

                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >

                                <div key={'section-stage-' + result.length} className="template-page-item content-item">
                                    <div className="item-header">
                                        <div className="item-title">
                                            <h1 className="title">{category.contractCategory.name}</h1>
                                            <Tooltip 
                                                title={strings.forms.admin.adminTemplateForm.tooltips.delete}
                                                placement="top" 
                                                arrow>
                                                <IconButton className="item-icon delete-icon" onClick={() => handleOpenModal(category)}>
                                                    <img src="/images/icons/delete.png" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>

                                {openModal && selectedCategory === category.id &&
                                <Modal
                                    open={openModal}
                                    onClose={handleCloseModal}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    className='confirm-modal-container'
                                >
                                    <ConfirmModal
                                        title={strings.modals.adminTemplates.title}
                                        contentText={strings.modals.adminTemplates.confirmTextCategory}
                                        cancelBtn={strings.modals.adminTemplates.cancelBtn}
                                        confirmBtn={strings.modals.adminTemplates.deleteBtn}
                                        handleCloseModal={handleCloseModal}
                                        handleConfirm={() => handleConfirm(category)}
                                    />
                                </Modal>
                                }

                            </div>
                        )}
                    </Draggable>
                )
            }
            return result;
        }
    }

    return (
        <div className="template-page-content admin-template">

            <div className="search-container">
                <div className='serach-item'>
                    <AutocompleteSelectControl
                        name={'categories'}
                        control={data}
                        options={getAutoCompleteCategories()}
                        handleInputChange={handleInputChange}
                        placeholder={strings.forms.admin.adminTemplateForm.addCategory}
                    />
                </div>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable-container' direction="vertical">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {renderCategories()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        </div>
    )
}

export default CategoriesForm;