import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getTemplateQuestionOption(id) {
    return await request('/api/templatequestionoption/' + id);
}

export async function getAllTemplateQuestionOptions(stepId) {
    return await request('/api/templatequestionoption/all/' + stepId);
}

export async function deleteTemplateQuestionOption(id) {
    return await request('/api/templatequestionoption/' + id, {}, HttpMethod.DELETE);
}

export async function addTemplateQuestionOption(data) {
    return await request('/api/templatequestionoption/add', data, HttpMethod.POST);
}

export async function editTemplateQuestionOption(data) {
    return await request('/api/templatequestionoption/edit/' + data.id, data, HttpMethod.PUT);
}

export async function makeDefaultTemplateQuestionOption(id) {
    return await request('/api/templatequestionoption/make-default/' + id, {}, HttpMethod.PUT);
}

function transformRequestData(data) {
    return data;
}