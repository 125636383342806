import React, {useContext, useEffect, useState} from "react";
import PackageList from './PackageList';
import BuyPackageForm from '../../../Components/Forms/Pages/Dashboard/PricingAndPackage/BuyPackageForm';
import CustomPackageForm from '../../../Components/Forms/Pages/Dashboard/PricingAndPackage/CustomPackageForm';
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch, useSelector} from "react-redux";
import {packageSubscribe} from "../../../Services/Subscribe/SubscribeService";
import {getCurrentUser} from "../../../Base/OAuth";
import {setAuth} from "../../../Slices/AuthSlice";
import LoaderContext from "../../../Context/LoaderContext";
import Modal from "@mui/material/Modal";
import InfoModal from "../../../Components/Modals/InfoModal";
import strings from "../../../localization";
import {useNavigate} from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_live_51JNwC9KvgB5NSzCpyKSq8MdnN0rqR1T0rwCSXyclx4Lq2aAeS2fUYUkj08gRtWL7RazigEDN8lztJYfGxm7qgdh200vkB0g1AH");

const PricingAndPackage = () => {

    const [showPackList, setShowPackList] = useState(true)
    const [showPackForm, setShowPackForm] = useState(false)
    const [customPackForm, setCustomPackForm] = useState(false)
    const [selectedPack, setSelectedPack] = useState({})
    const [isAnnual, setIsAnnual] = useState(false);
    const [paidPack, setPaidPack] = useState(-1)
    const dispatch = useDispatch();
    const {showLoader, setLoaderTitle} = useContext(LoaderContext);
    const [payInfoModal, setPayInfoModal] = useState(false)
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user)

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
    }, [])

    const handleSubscribe = (pack, isFree = false) => {
        if(!isFree) {
            setShowPackList(false)
            setSelectedPack(pack)

            setShowPackForm(true)
            setCustomPackForm(false)
        }
        else {
            showLoader(true);

            packageSubscribe({
                card: undefined,
                expMonth: undefined,
                expYear: undefined,
                cvc: undefined,
                packageId: pack.id,
                interval: 'month'
            }).then(response => {

                getCurrentUser().then(response => {
                    showLoader(false);
                    dispatch(setAuth(response.data));
                    setPayInfoModal(true)
                    setPaidPack(pack.id)
                })
            })
        }
    }
    const handleDowngrade = (pack) => {
    }

    const handleUpgrade = (pack) => {

    }

    const handleCustom = () => {
        setCustomPackForm(true)
        setShowPackForm(false)
        setShowPackList(false)
    }

    const handleCancel = () => {
        setShowPackList(true)
        setShowPackForm(false)
        setPayInfoModal(false)
        navigate('/')
    }

    return (
        <div className="tab-page-content">
            {showPackList &&
                <PackageList 
                    handleSubscribe={handleSubscribe}
                    handleDowngrade={handleDowngrade}
                    handleUpgrade={handleUpgrade}
                    handleCustom={handleCustom}
                    paidPack={paidPack}
                    parentIsAnnual={setIsAnnual}
                />
            }

            {showPackForm &&
                <Elements stripe={stripePromise}>
                    <BuyPackageForm 
                        pack={selectedPack}
                        setShowPackList={setShowPackList}
                        setShowPackForm={setShowPackForm}
                        setPaidPack={setPaidPack}
                        paidPack={paidPack}
                        isAnnual={isAnnual}
                    />
                </Elements>
            }

            {customPackForm &&
                <CustomPackageForm
                    pack={selectedPack}
                    setCustomPackForm={setCustomPackForm}
                    setShowPackList={setShowPackList}
                />
            }

            <Modal
                open={payInfoModal}
                onClose={handleCancel}
                className='confirm-modal-container'
            >
                <InfoModal
                    title={ (user.company.isTrial ? 'Trial has started for ' : strings.modals.pricingAndPackage.buyPackage.infoText) + user.company?.package?.name}
                    cancelBtn={strings.modals.pricingAndPackage.buyPackage.infoBtn}
                    handleCloseModal={handleCancel}
                    style='buy-package-info'
                    btnStyle='default-btn btn'
                />
            </Modal>
        </div>
    )
}

export default PricingAndPackage;