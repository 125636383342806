import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import MenuState from "../../../Constants/Base/MenuState";
import strings from "../../../localization";
import HeaderButtons from "../../../Components/Layout/HeaderButtons";
import TextFieldControl from "../../../Components/Controls/Inputs/TextFieldControl";
import List from "./List";
import { getAllContractCategories } from "../../../Services/ContractCategory/ContractCategoryService";
import Modal from "@mui/material/Modal";
import ContractRequestForm from "../../../Components/Forms/Pages/Contract/ContractDashboard/ContractRequestForm";
import { addContractRequest } from "../../../Services/Contract/ContractRequestService";
import { getContractCategoryByPackage } from "../../../Services/ContractCategory/PackageService";
import TextField from "@mui/material/TextField";
import { isTrial, isTrialPackageValid } from "../../../Util/PermissionUtil";
import { getContractCount } from "../../../Services/Company/CompanyService";
import ConfirmModal from "../../../Components/Modals/ConfirmModal";
import DocumentForm from "../../../Components/Forms/Pages/ContractList/DocumentForm";
import { useNavigate } from "react-router-dom";
import UserType from "../../../Constants/User/UserType";

const ContractDashboard = () => {
  const menuState = useSelector((state) => state.navigation.menuState);

  const form = useForm();
  const {
    data,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [search, setSearch] = useState("");
  const [docName, setDocName] = useState("");
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [openNonValidSubscription, setOpenNonValidSubscription] =
    useState(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({ name: "Home", id: 0 });
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    if (
      user.company.package &&
      user.userProfileType !== UserType.SYSTEM_ADMIN
    ) {
      getContractCategoryByPackage(user.company.package.id).then((response) => {
        if (!response || !response.ok) {
          return;
        }
        let categories = [];
        for (let item of response.data) {
          if (!item.contractCategory.parent) {
            categories.push(item.contractCategory);
          }
        }
        let filteredData = categories.filter(
          (category) => category.active === true
        );
        setCategories(filteredData);
      });
    } else {
      getAllContractCategories({}).then((response) => {
        if (!response || !response.ok) {
          return;
        }

        let categories = response.data.filter(
          (category) => category.active === true
        );
        setCategories(categories);
      });
    }

    getContractCount().then((response) => {
      if (!isTrialPackageValid(user, response.data) && !user.firstLogin) {
        setOpenNonValidSubscription(true);
      }
    });
  }, []);

  const getHeaderClass = (menuState) => {
    if (menuState === MenuState.SHORT) {
      return "short";
    }

    return "";
  };

  const handleOpenRequestModal = () => {
    setOpenRequestModal(true);
  };

  const handleCloseRequestModal = () => {
    setOpenRequestModal(false);
  };

  const onSubmitContractRequest = (data) => {
    addContractRequest({
      ...data,
      fullName: user.fullName,
    }).then((response) => {
      setOpenRequestModal(false);
    });
  };
  const handleCategoryClick = (category) => {
    if (category.id === 13 || category.name === "e-Signature") {
    }
    setSelectedCategory(category);
  };

  const handleCloseDocumentModal = () => {
    setOpenDocumentModal(false);
    setSelectedFolder({ name: "Home", id: 0 });
    setFolders([]);
  };

  const handleConfirmDocumentModal = async (response) => {
    setOpenDocumentModal(false);
    if (response.data && response.data.length > 0) {
      setSelectedFolder({ name: "Home", id: 0 });
      setFolders([]);
      navigate("/contract-preview/" + response.data[0].id);
    } else {
      console.error("Response data is undefined or empty.");
    }
  };

  const renderCategories = () => {
    let result = [];

    if (!categories) {
      return result;
    }

    for (let category of categories) {
      const isSelected =
        selectedCategory && selectedCategory.id === category.id;

      const darkenedBorderColor = isSelected
        ? darkenColorManually(category.color, 15)
        : "none";

      result.push(
        <div
          key={"category-" + category.id}
          className={`contract-type ${isSelected ? "selected" : ""}`}
          onClick={() => handleCategoryClick(category)}
          style={{
            background: category.color,
            border: isSelected ? `8px solid ${darkenedBorderColor}` : "none",
          }}
        >
          <div className="icon">
            <img src={category.icon} />
          </div>
          <h2 className="title">{category.name}</h2>
          <p className="description">{category.description}</p>
        </div>
      );
    }
    return result;
  };

  return (
    <React.Fragment>
      <div id="header" className={getHeaderClass(menuState)}>
        <h1>{strings.pages.contractDashboard.title}</h1>
        <HeaderButtons />
      </div>

      <div id="contract-dashboard" className="contract-dashboard">
        <div className="search-container">
          <FormProvider {...form}>
            <div className="filter-serach-item">
              <TextField
                className={"textfield-control"}
                name="search"
                value={search}
                fullWidth={true}
                margin="normal"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder={strings.pages.contractDashboard.search}
                icon={<img src="/images/icons/search.png" />}
              />
            </div>
          </FormProvider>
          <span className="link" onClick={handleOpenRequestModal}>
            {strings.pages.contractDashboard.cantFindSearchItem}
          </span>

          <Modal
            open={openRequestModal}
            onClose={handleCloseRequestModal}
            className="confirm-modal-container"
          >
            <ContractRequestForm
              onSubmit={onSubmitContractRequest}
              handleCloseRequestModal={handleCloseRequestModal}
            />
          </Modal>
        </div>
        <div className="contract-dashboard-content">
          <div className="contract-types-container">{renderCategories()}</div>

          <List
            search={search}
            itemClass={"item bussines-contract"}
            category={selectedCategory}
          />
          {selectedCategory &&
            (selectedCategory.id === 13 ||
              selectedCategory.name === "e-Signature") && (
              <div className="contract-doc-container-start-signing">
                <div className="icon-container">
                  <h3>{strings.forms.contractList.documentForm.eSignTitle}</h3>
                </div>
                <FormProvider {...form}>
                  <div className="filter-search-item">
                    <TextField
                      className="textfield-control"
                      name="docName"
                      value={docName}
                      fullWidth={true}
                      margin="normal"
                      onChange={(e) => setDocName(e.target.value)}
                      placeholder={strings.pages.contractDashboard.docName}
                      icon={
                        <img src="/images/icons/search.png" alt="Search Icon" />
                      }
                    />
                  </div>
                </FormProvider>
                <DocumentForm
                  selectedFolder={selectedFolder}
                  docName={docName}
                  folders={folders}
                  handleCloseModal={handleCloseDocumentModal}
                  handleConfirm={handleConfirmDocumentModal}
                  mergedDocument={true}
                  buttonText={strings.forms.contractList.sign}
                />
              </div>
            )}
        </div>

        <Modal
          open={openNonValidSubscription}
          onClose={() => navigate("/")}
          className="confirm-modal-container"
        >
          <ConfirmModal
            contentText={
              isTrial(user)
                ? strings.modals.contractList.nonValidSubscription
                    .confirmTextFreeTrial
                : strings.modals.contractList.nonValidSubscription
                    .confirmTextSubscriptionEnd
            }
            cancelBtn={strings.modals.contractList.nonValidSubscription.no}
            confirmBtn={strings.modals.contractList.nonValidSubscription.yes}
            handleCloseModal={() => navigate("/")}
            handleConfirm={() => navigate("/pricing-and-package")}
          />
        </Modal>
      </div>
    </React.Fragment>
  );
};
function darkenColorManually(color, percent) {
  const rgb = color.match(/\w\w/g).map((hex) => parseInt(hex, 16));
  return `rgb(${(rgb[0] * (100 - percent)) / 100},
                 ${(rgb[1] * (100 - percent)) / 100},
                 ${(rgb[2] * (100 - percent)) / 100})`;
}

export default ContractDashboard;
