import React, { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";
import { Add } from "@mui/icons-material";
import SectionRow from "./SectionRow";
import strings from "../../../../localization";
import { dueDeligenceRowObj } from "../../../../Constants/Admin/DealRoomModel";

const SectionList = ({
  sectionList,
  sectionsType,
  projectId,
  getSectionListHandler,
  showMessage,
  activeOpenSection,
  setActiveOpenSection,
}) => {
  const [rows, setRows] = useState(sectionList);

  const addNewRowHandler = () => {
    setRows([...rows, dueDeligenceRowObj]);
  };

  useEffect(() => {
    // if (sectionList?.length > 0) {
      setRows(sectionList);
    // }
  }, [sectionList]);

  return (
    <>
      <div className="due-diligence-accordion-table">
        {rows?.map((row, index) => {
          return (
            <SectionRow
              key={index}
              row={row}
              sectionIndex={index}
              setRows={setRows}
              sectionsType={sectionsType}
              projectId={projectId}
              getSectionListHandler={getSectionListHandler}
              showMessage={showMessage}
              setActiveOpenSection={setActiveOpenSection}
              activeOpenSection={activeOpenSection}
            />
          );
        })}
        <Box display={"flex"} justifyContent="center">
          <Button className="new-section-button" onClick={addNewRowHandler}>
            <Add />
            {strings.pages.adminDealRoom.newSection}
          </Button>
        </Box>
      </div>
    </>
  );
};

export default SectionList;
