import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getContractCategory(id) {
    return await request('/api/contractcategory/' + id);
}

export async function getAllContractCategories(data) {
    return await request('/api/contractcategory/all', data);
}

export async function changeActiveContractCategories(data) {
    return await request('/api/contractcategory/active/' + data.id, data, HttpMethod.PATCH);
}

export async function deleteContractCategory(id) {
    return await request('/api/contractcategory/' + id, {}, HttpMethod.DELETE);
}

export async function addContractCategory(data) {
    return await request('/api/contractcategory/add', data, HttpMethod.POST);
}

export async function editContractCategory(data) {
    return await request('/api/contractcategory/edit/' + data.id, data, HttpMethod.PUT);
}

function transformRequestData(data) {
    return data;
}