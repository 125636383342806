import strings from "../../localization";

const SortUser = {
    ALL: -1,
    ADMIN: 1, 
    USER: 2,
    BUSSINES_USER: 3
};

export function getSortUserFilter() {
    return [
        {
            id: SortUser.ALL,
            name: getSortUserString(SortUser.ALL)
        },
        {
            id: SortUser.ADMIN,
            name: getSortUserString(SortUser.ADMIN)
        },
        {
            id: SortUser.USER,
            name: getSortUserString(SortUser.USER)
        },
        {
            id: SortUser.BUSSINES_USER,
            name: getSortUserString(SortUser.BUSSINES_USER)
        }
    ]
}

export function getSortUserString(sortUser) {
    switch(sortUser) {
        case SortUser.ALL: return strings.constants.user.sortUser.all;
        case SortUser.ADMIN: return strings.constants.user.sortUser.admin;
        case SortUser.USER: return strings.constants.user.sortUser.user;
        case SortUser.BUSSINES_USER: return strings.constants.user.sortUser.bussinesUser;
    }
}

export default SortUser;