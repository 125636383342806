import React, { useState } from "react";
import {
  Box,
  Modal,
  Button,
  Checkbox,
  Typography,
  IconButton,
} from "@mui/material";
import {
  deleteShareholder,
  updateShareholder,
} from "../../../../../Services/DealRoom/DealRoomService";
import styles from "../../../DealRoom.module.css";
import ConfirmModal from "../../../../../Components/Modals/ConfirmModal";

const RenderShareholder = ({ data, index, id, getAllShareholdersHandler }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [selectedShareholder, setSelectedShareholder] = useState(null);
  const [openShareholderDeleteModal, setOpenShareholderDeleteModal] =
    useState(false);
  const [editableShareholderData, setEditableShareholderData] = useState({
    name: "",
    class: "",
    shares: null,
    nominalValue: null,
    votingRights: false,
  });

  const onChangeInputHandler = (e) => {
    let { name, value } = e.target;

    if (name === "votingRights") {
      setEditableShareholderData((prevState) => ({
        ...prevState,
        [name]: e.target.checked,
      }));
      return;
    }
    setEditableShareholderData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const deleteShareholderHandler = () => {
    deleteShareholder(id, selectedShareholder.id)
      .then((res) => {
        if (res.status === 204) {
          getAllShareholdersHandler();
          setOpenShareholderDeleteModal(false);
        }
      })
      .catch((err) => {
        console.log("error in deleteShareholderHandler", err);
      });
  };

  const updateShareholderHandler = () => {
    updateShareholder(id, selectedShareholder.id, editableShareholderData)
      .then((res) => {
        if (res.status === 204) {
          getAllShareholdersHandler();
          setEditableShareholderData({
            name: "",
            shares: null,
            class: "",
            nominalValue: null,
            votingRights: false,
          });
        }
      })
      .catch((err) => {
        console.log("error in updateShareholder", err);
      });
  };

  return (
    <Box
      key={index}
      display="flex"
      alignItems="center"
      flexDirection={"column"}
      width={"100%"}
    >
      <Box display="flex" alignItems="center" p={2} width={"100%"} mb={2}>
        <Box
          className="capTable-shareholder-input-div"
        >
          <Box
            sx={{ backgroundColor: data.color }}
            width={"1rem"}
            height={"1rem"}
            mr={1}
          ></Box>
          <Typography
            fontWeight={500}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {data.name}
          </Typography>
        </Box>
        <Box className="capTable-shareholder-input-div">
          <Typography fontWeight={500}>{data.shares}</Typography>
        </Box>
        <Box className="capTable-shareholder-input-div">
          <Typography fontWeight={500}>{data.class}</Typography>
        </Box>
        <Box className="capTable-shareholder-input-div">
          <Typography fontWeight={500}>£ {data.nominalValue}</Typography>
        </Box>
        <Box className="capTable-shareholder-input-div" sx={{ width: "12.5%" }}>
          <Typography fontWeight={500}>£ {data.totalNominalValue}</Typography>
        </Box>
        <Box className="capTable-shareholder-input-div" sx={{ width: "12.5%" }}>
          <Typography fontWeight={500}>{data.ownershipPercentage}%</Typography>
        </Box>
        <Box className="capTable-shareholder-input-div">
          <Checkbox checked={data.votingRights} />
          <Box display={"flex"} ml={2}>
            <IconButton
              className={styles.actionIcons}
              onClick={() => {
                setSelectedShareholder(data);
                setEditableShareholderData({
                  name: data.name,
                  shares: data.shares,
                  class: data.class,
                  nominalValue: data.nominalValue,
                  votingRights: data.votingRights,
                });
                setIsEditable((prevState) => !prevState);
              }}
            >
              <img src="/images/icons/pen.png" alt="edit-button" />
            </IconButton>
            <IconButton
              className={styles.actionIcons}
              onClick={() => {
                setSelectedShareholder(data);
                setOpenShareholderDeleteModal(true);
              }}
            >
              <img src="/images/icons/delete.png" alt="edit-button" />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {isEditable && selectedShareholder.id === data.id && (
        <Box
          display="flex"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Box className={styles.capTableInputWrapper}>
            <Box className={styles.capTableInputContainer}>
              <input
                className={styles.inputBox}
                value={editableShareholderData.name}
                name="name"
                onChange={onChangeInputHandler}
                type="text"
                placeholder="Shareholder"
              />
            </Box>
          </Box>

          <Box className={styles.capTableInputWrapper}>
            <Box className={styles.capTableInputContainer}>
              <input
                className={styles.inputBox}
                value={editableShareholderData.shares}
                name="shares"
                onChange={onChangeInputHandler}
                type="number"
                placeholder="Number"
              />
            </Box>
          </Box>

          <Box className={styles.capTableInputWrapper}>
            <Box className={styles.capTableInputContainer}>
              <input
                className={styles.inputBox}
                value={editableShareholderData.class}
                name="class"
                onChange={onChangeInputHandler}
                type="text"
                placeholder="Class"
              />
            </Box>
          </Box>

          <Box className={styles.capTableInputWrapper}>
            £ &nbsp;
            <Box className={styles.capTableInputContainer}>
              <input
                className={styles.inputBox}
                value={editableShareholderData.nominalValue}
                name="nominalValue"
                onChange={onChangeInputHandler}
                type="text"
                placeholder="Nominal Value"
              />
            </Box>
          </Box>

          <Box width={"25%"}></Box>

          <Box
            className="capTable-shareholder-input-div"
            justifyContent={"space-around"}
          >
            <Checkbox
              checked={editableShareholderData.votingRights}
              name="votingRights"
              onChange={onChangeInputHandler}
            />
            <Button
              className="neutral-btn"
              onClick={() => {
                updateShareholderHandler();
                setIsEditable(false);
                setEditableShareholderData({
                  name: "",
                  shares: null,
                  class: "",
                  nominalValue: null,
                  votingRights: false,
                });
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
      <Modal
        open={openShareholderDeleteModal}
        onClose={() => setOpenShareholderDeleteModal(false)}
        className="confirm-modal-container"
      >
        <ConfirmModal
          title="Are you sure you want to delete this Shareholder?"
          handleCloseModal={() => setOpenShareholderDeleteModal(false)}
          handleConfirm={deleteShareholderHandler}
          cancelBtn="No"
          confirmBtn="Yes"
        />
      </Modal>
    </Box>
  );
};

export default RenderShareholder;
