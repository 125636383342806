import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({

    en: {
        common: {
            saved: 'Saved',
            added: 'Added',
            edited: 'Edited',
            published: 'Published',
            deleted: 'Deleted',
            removed: 'Removed',
            created: 'Created',
            activated: 'Activated',
            deactivated: 'Deactivated'
        },
        header: {
            lock: 'Lock',
            logout: 'Logout',
            myProfile: 'My Profile',
            recent: 'Recent',
            markAllAsRead: 'Mark all as read',
            seeAllActivity: 'See all activity'
        },
        navigation: {
            newContract: 'New Contract',
            home: 'Home',
            contracts: 'My Contracts',
            users: 'Users',
            admin: 'Admin',
            logout: 'Logout',
            aiAssist: 'AI Assist',
            dealRoom: 'Deal Room'
        },
        commonTooltips: {
            collapse: '',
            expand: '',
            deactivated: 'Deactivated',
            delete: 'Delete',
            edit: 'Edit',
            share: 'Share'
        },
        components: {
            fileUploadComponent: {
                upload: "Click to upload or Drag and Drop a document",
                files: 'Files'
            },
            autocompleteControl: {
                noOptions: 'No options'
            },
            creditCardField: {
                invalidCardNumber: 'Card number is invalid',
                invalidExpiryDate: 'Expiry date is invalid',
                monthOutOfRange: 'Expiry month must be between 01 and 12',
                yearOutOfRange: 'Expiry year cannot be in the past',
                dateOutOfRange: 'Expiry date cannot be in the past',
                invalidCvc: 'CVC is invalid',
                invalidZipCode: 'Zip code is invalid',
                cardNumberPlaceholder: 'XXXX XXXX XXXX XXXX',
                expiryPlaceholder: 'MM/YY',
                cvcPlaceholder: 'CVC',
                zipPlaceholder: 'C.P.'
            }
        },
        dashboardHeader: {
            dashboard: 'Dashboard',
            myDetails: 'Profile',
            companyProfile: 'Company Profile',
            pricingAndPackage: 'Pricing & Packages',
            subscriptionDetails: 'Billing'
        },
        contractHeader: {
            addContract: 'Add contract',
            myContracts: 'My contracts',
            myFolders: 'My folders'
        },
        adminHeader: {
            categories: 'Categories',
            packages: 'Packages',
            templates: 'Templates',
            aiData: 'AI',
            dataRoom:'Data Room',
        },
        pages: {
            login: {
                wrongCredentials: 'Wrong credentials',
                signIn: 'Sign in',
                dontHaveAccount: "Don't have an account yet?",
                newToLogicalContracts: "New to Logical Contracts?",
                createAccount: 'Create your account',
                or: 'or',
                continueWithGoogle: 'Continue with Google',
                continueWithLinkedin: 'Continue with LinkedIn',
                continueWithOffice: 'Continue with Office 365',
                description: 'Create, collaborate, edit and e-sign your contracts today!',
            },
            registration: {
                userAlreadyExists: 'Email already exists',
                createAccount: 'Create your account',
                alreadyHaveAccount: 'Already have an account?',
                signIn: 'Sign in',
                description: 'Create, collaborate, edit and e-sign your contracts today!',
                or: 'or',
                continueWithGoogle: 'Continue with Google',
                continueWithLinkedin: 'Continue with LinkedIn',
                continueWithOffice: 'Continue with Office 365',
                userType: {
                    selectUserType: 'Select the user type that best suits your needs. You can always change this later!',
                    // company: 'Company',
                    // individual: 'Individual',
                    skipThisStep: 'Skip this step. I want to make a contract',
                    imgText: 'Create, collaborate, edit and e-sign your contracts today!'
                }
            },
            lock: {
                lock: 'Lock'
            },
            resetPassword: {
                linkExpired: 'Link expired',
                errorResettingPassword: 'Error resetting password',
                resetPassword: 'Reset your password',
                createPassword: 'Create your password'
            },
            resetPasswordRequest: {
                resetPasswordExpired: 'The password reset link has expired.',
                passwordResetAlreadyRequested: 'Password reset already requested',
                passwordResetRequester: 'Password reset requested',
                resetPassword: 'Forgot your password?',
                description: "Please enter your email address. We will send you a link to reset your password.",
                descriptionExpired: "Please enter your email address and we will send you a new link to reset your password.",
                backTo: 'Back to',
                signIn: 'Sign in',
                passwordLinkExpired: 'The password reset link has expired',
                youAreNotRegistered: 'Oops! Does not look like you are registered yet.',
                createAccountNow: 'Create your account now.',
                weSentAResetLink: 'We sent a reset link',
                weSentAResetLinkLinkSentTo: 'We sent a reset link sent to ',
                pleaseBeSureToCheckSpam: ' - please be sure to check spam folder to',
            },
            resetPasswordSuccess: {
                success: 'Successful password reset!',
                description: 'You can use your new password to sign in to your account!',
                signIn: 'Sign in'
            },
            errors: {
                internalServerError: 'Internal server error',
                dashboard: 'Dashboard',
                badRequest: 'Bad request',
                forbidden: 'Forbidden',
                notFound: 'Not found',
                unauthorized: 'Unauthorized'
            },
            dashboard: {
                dashboard: {
                    notifications: 'Notifications',
                    watchVideos: 'Watch Videos'
                },
                myDetails: {
                    getStarted: '*Get Started* Knowledge Hub video and Podcast features.',
                    statistic: 'maybe some statistic'
                },
                pricingAndPackages: {
                    annually: 'Annually',
                    annuallySave: 'Save 25% with an annual subscription',
                    monthly: 'Monthly',
                    monthlySave: 'Monthly save'
                },
                subscriptionDetails: {
                    editPaymentMethod: 'Manage Card',
                    unsubscribe: 'Unsubscribe',
                    packages: 'Packages',
                    transactionId: 'Transaction ID',
                    transactionDate: 'Transaction date',
                    expiryDate: 'Expiry date',
                    amount: 'Amount',
                    status: 'Status'
                },
            },
            addContract: {
                title: 'Complete questionnaire',
                documentName: 'Document Name',
                documentNameAlreadyExistMsg: 'Oops, it looks like you already have a contract with the same name',
                termsAndConditions: 'Terms & Conditions - Logical Contracts',
                enterDocumentName: 'Enter Document Name',
                completeQuestionnaireAndGenerateContract: 'Complete questionnaire and generate contract',
                subHeader: {
                    documentDetails: 'Document Details',
                    partiesDetails: 'Parties Details',
                    startDateTitleJobDescription: 'Start date, title & job description',
                    durationAndRightToWork: 'Duration & Right to work',
                    hoursAndLocation: 'Hours & location',
                    renumerationAndPerformanceBonus: 'Renumeration & Performance bonus',
                    holidayAndSickness: 'Holiday & Sickness',
                    probationAndTermination: 'Probation & Termination'
                }
            },
            adminAllTemplates: {
                templateMenagement: 'Template Management',
                createPublishQuestionnaire: 'Create and publish questionnaire to users on the portal',
                manageContent: 'Manage Content',
                manageAdmin: 'Manage Admin'
            },
            adminCategories: {
                templates: 'Templates',
                title: 'Admin Categories',
                newCategory: 'New Category',
                subCategoriesList: 'Sub-categories list',
                contracts: 'Contracts',
                packages: 'Packages',
                new: 'New',
                deactivated: '(Deactivated)',
                activate: 'Activate',
                delete: 'Delete',
                save: 'Save',
                add: 'Add',
                editCategory: 'Edit category name',
                deactivateCategory: 'Deactivate'
            },
            adminPackages: {
                enterEmailAddress: 'Enter email address',
                templates: 'Templates',
                newPackages: 'New Packages',
                users: 'Users',
                allUsers: 'All Users',
                selectedUsers: 'Selected Users',
                maxNumOfInternalUsers: 'Maximum number of internal users',
                enterUserId: 'Enter User ID',
                searchUsers: 'Search User ID',
                categories: 'Categories',
                searchCategory: 'Search category',
                price: 'Price',
                monthlyPrice: 'Monthly Price',
                annuallyPrice: 'Annually Price',
                onceOfFee: 'Once of fee',
                setAsRecommendedPackage: 'Set as a recommended package',
                contracts: 'Contracts',
                searchContracts: 'Search contracts',
                new: 'New',
                deactivated: '(Deactivated)',
                activate: 'Activate',
                delete: 'Delete',
                save: 'Save',
                undo: 'Undo',
                edit: 'Edit',
                editPackage: 'Edit package',
                deactivatePackage: 'Deactivate',
                packages: 'Packages'
            },
            adminTemplates: {
                newTemplate: 'New Template',
                editTemplate: 'Edit template',
                deactivateTemplate: 'Deactivate'
            },
            adminAIData: {
                subOptions: { firstOption: 'AI Data', secondOption: 'AI Requests Data' },
                aiRequest: {
                    question: {
                        chatGPT: "This answer was provided by chatGPT.",
                        existingAIData: "This answer was provided by existing AI data, was the response correct?"
                    },
                    choices: [
                        {
                            name: "New Request",
                            def: "This AI response will be saved as new data in the data base. Note you can update the response."
                        },
                        {
                            name: "Tag this question to an existing response",
                            def: "The question asked by this user will be added as a question an existing response (response 1) which means when another user asks this same question, response 1 will be given."
                        },
                        {
                            name: "Report this data",
                            def: "This could be a question that you do not want another user to ask again in the future."
                        },
                    ]
                },
                aiData: {
                    createPage: {
                        dataTypeHeading: "Select whether the data is a clause or question",
                        questionNote: "Please add the wording a user would use to ask this question",
                        responseQuestionNote:"Please add the wording of the response given to the user to enable them to select an option",
                        options: {
                            category: "Enter the category of law the clause is associated to",
                            contract: "Enter the Name of the contract the clause is associated to",
                            contractVariation: "Enter the Name of the contract variation",
                            sector: "Enter the Sector the clause is associated to",
                            smartTag: "Enter the smart tags",
                            smartTag_Note: "This could be another name for the clause or keyword associated with the clause",
                            optionsName:"Option Name",
                            clauseOptionCode:"Clause Option code",
                        },
                    }
                },
            },
            adminDealRoom:{
                editHeading:'Edit Heading',
                editQuestion:'Edit Question',
                deleteAccordion:'Delete the section',
                deleteQuestion:'Delete Question',
                newSection:'New Section',
                newQuestion:'New Question'                
            },

            contractDashboard: {
                title: 'Create new contract',
                docName: 'Enter Document Name',
                search: 'Quick search contract',
                cantFindSearchItem: "Can't find what you are looking for?",
                businessContractsTitle: 'Business Contracts',
                businessContractsDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                hrContractsTitle: 'HR Contracts',
                hrContractsDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                startUpPackTitle: 'Start up Pack',
                startUpPackDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                mergersAndAcquisitionsTitle: 'Mergers & Acquisitions',
                mergersAndAcquisitionsDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                intellectualPropertyTitle: 'Intellectual Property',
                intellectualPropertyDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                create: 'Create'
            },
            contractList: {
                title: 'My Contracts',
                youHave: 'You have',
                contracts: 'contracts',
                open: 'Open',
                uploadDocument: 'Upload document',
                search: 'Quick search contract',
                moveToFolder: 'Move to folder',
                share: 'Share',
                download: 'Download',
                selectAll: 'Select all',
                folderIsEmpty: "This folder doesn't contain any document", //to do
                contractMovedToFolderMsg: 'Moved to folder ',
                contractMovedToItsFolderMsg: 'Contract is already in ',
                folders: 'Folders',
                addFolder: 'Add folder',
                addSubfolder: 'Add subfolder',
                edit: 'Edit',
                delete: 'Delete',
            },
            contractPreview: {
                templateDetailsTooltip: 'Template details',
                commentsTooltip: 'Comments',
                collaboratorsTooltip: 'Collaborators',
                moveToFolderTooltip: 'Move to folder',
                downloadTooltip: 'Download',
                revisionHistoryTooltip: 'Revision history',
                deleteTooltip: 'Delete',
                text: ' Add Text',
                contractMovedToFolderMsg: 'Moved to folder ',
                contractMovedToItsFolderMsg: 'Contract is already in '
            },
            usersList: {
                title: 'User Management',
                newUser: 'New User',
                resendLink: 'Resend link',
                editUser: 'Edit user',
                delete: 'Delete'
            },
            aiTab: {
                examples: 'Examples',
                newConversation: 'New Conversation',
                questionInfo: 'The information provided does not constitute legal advice. It is important to consult with a solicitor to ensure any legal content you create meets your specific needs and complies with applicable laws and regulations.'
            },
            dealRoom: {
                dataRoom: {
                    searchDocument: 'Quick Search Document',
                    exportDocument: 'Export',
                    uploadDocument: 'Upload',
                },
                downloadDealRoomData: {
                    downloadDocumentsText: 'You can download all the documents in this deal room.',
                    downloadButtonText: 'Download',
                }
            }
        },
        forms: {
            common: {
                required: 'Required',
                onlyNumeric: 'Only numeric'
            },
            searchForm: {
                search: 'Search'
            },
            lock: {
                password: 'Password',
                unlock: 'Unlock'
            },
            login: {
                email: 'Email',
                password: 'Password',
                rememberMe: 'Remember me',
                signIn: 'Sign in',
                forgotPassword: 'I forgot my password',
                emailError: 'Please enter valid email',
                passwordError: 'Please enter valid password',
                serviceTerms: 'Terms of Service',
                privacyPolicy: 'Privacy Policy'
            },
            registration: {
                fullName: 'Full name',
                fullNameError: 'Oops, invalid full name, please ensure your full name contains alphabets only',
                email: 'Email',
                emailError: 'Oops, invalid email, please ensure your email address consists of an email prefix to the left of the @ and an email domain to the right of the @',
                password: 'Password',
                passwordError: 'Oops, invalid password. Password must use 7 or more characters in length, 1 uppercase letter, 1 lowercase letter, and one number.',
                createAccount: 'Create your account',
                creatingAccountAgree: 'By creating an account, you agree to our ',
                serviceTerms: 'Terms of Service',
                and: ' and ',
                privacyPolicy: 'Privacy Policy',
                subscribe: 'Stay up to date with our latest news',
                submit: 'Submit',
                userType: {
                    common: {
                        back: 'Back',
                        next: 'Next',
                        skip: 'Skip',
                        submit: 'Submit',
                        startFreeTrial7Days: 'Start 7-day Free Trial',
                        startFreeTrial: 'Start free trial',
                        skipThisStep: 'Skip this step. I want to make a contract',
                        goalFormTitle: 'What is your main goal with Logical Contract?',
                        whatIsYourGoal: 'What is your goal with Logical Contract?',
                        enterYourGoal: 'Enter your goal',
                        describesFormTitle: 'Which describes you best?',
                    },
                    individualDetailsForm: {
                        individualDetails: 'Individual Details',
                        tellUsAboutYourself: 'Tell us a bit about yourself, so we can customise your account and provide custom contracts.',
                        address: 'Address',
                        enterAddress: 'Enter address',
                        sectorFormTitle: 'What sector does your company operate within',
                        selectCompanySector: 'Select company’s sector',
                        enterCompanySubSector: "Enter company's sub-sector"
                    },
                    companyDetailsForm: {
                        companyDetails: 'Company Details',
                        tellUsAboutYourself: 'Tell us a bit about yourself so we can customise your account and provide custom contracts.',
                        fullCompanyName: 'Company name',
                        enterFullCompanyName: 'Company name',
                        jurisdiction: 'Jurisdiction',
                        enterJurisdiction: 'Enter jurisdiction',
                        companyAddress: 'Company address',
                        enterCompanyAddress: 'Enter company address',
                        companyNumber: 'Company number',
                        enterCompanyNumber: 'Enter company number',
                        yourDetails: 'Your details',
                        director: 'Director',
                        shareholder: 'Shareholder',
                        employee: 'Employee',
                        employees: 'Employees',
                        sectorFormTitle: 'What sector does your company operate within',
                        selectCompanySector: 'Select company’s sector',
                        turnoverFormTitle: 'What is your company’s yearly turnover?'
                    }
                },
            },
            resetPassword: {
                password: 'Enter a new password',
                passwordRepeat: 'Confirm your new password',
                resetPassword: 'Reset my password',
                passwordRepeatError: 'Password do not match',
                passwordError: 'Oops invalid password. Please ensure that your password is at least 7 characters and contain at least 1 number, 1 uppercase letter and 1 lower case letter.',
                emailError: 'Oops, invalid email, please ensure your email address consists of an email prefix to the left of the @ and an email domain to the right of the @'
            },
            resetPasswordRequest: {
                email: 'Email',
                requestResetLink: 'Request Reset Link'
                // resetPassword: 'Reset password',
                // resetPasswordRequest: 'Email is required'
            },
            admin: {
                adminCategoryForm: {
                    icon: 'Icon',
                    colorName: 'Color',
                    title: 'Create New Category',
                    categoryName: 'Name of Category',
                    subCategoryName: 'Name of Sub-category',
                    choosePackages: 'Choose packages for this category:',
                    chooseSubCategories: 'Choose sub-categories for this category:',
                    chooseContracts: 'Choose contracts for this category:',
                    cancel: 'Cancel',
                    createCategory: 'Create category',
                    selectPackages: 'Select packages',
                    selectSubCategories: 'Select sub-categories',
                    selectContracts: 'Select contracts',
                    category: 'Category is required',
                    color: 'Color is required',
                    description: 'Description'
                },
                adminPackageForm: {
                    title: 'Create New Package',
                    cancel: 'Cancel',
                    createPackage: 'Create package',
                    packageName: 'Name of Package',
                    packagePrice: 'Price of Package',
                    createCategory: 'Create category',
                    package: 'Package is required',
                    packages: {
                        packages: 'Packages',
                        addIcon: 'Add icon',
                        iconDescription: 'Click on the icon (photo) to upload an image or drag and drop on the same place',
                        packageName: 'Package name',
                        enterPackageName: 'Enter package name',
                        color: 'Color',
                        enterColor: 'Enter color',
                        description: 'Description',
                        enterDescription: 'Enter description',
                        featuresTitle: 'Features title',
                        enterFeaturesTitle: 'Enter features title',
                        feature: 'Feature',
                        enterFeature: 'Enter feature',
                        newFeature: 'New feature',
                        additionalFeaturesTitle: 'Additional features title',
                        enterAdditionalFeaturesTitle: 'Enter additional features title',
                        additionalFeature: 'Additional feature',
                        enterAdditionalFeature: 'Enter additional feature',
                        newAdditionalFeature: 'New additional feature',
                    },
                    users: {
                        users: 'Users',
                        allUsers: 'All Users',
                        selectedUsers: 'Selected Users',
                        maxNumberOfInternalUsers: 'Maximum number of internal users',
                        enterEmailAddress: 'Enter email address',
                        selectUserNumberMsg: 'Please select number of internal users',
                        maxUsersMsg: 'Maximum number of internal users is: ',
                    },
                    categories: {
                        categories: 'Categories',
                        selectCategories: 'Select categories',

                    },
                    price: {
                        price: 'Price',
                        annualPrice: 'Annual price',
                        monthlyPrice: 'Monthly price',
                        onceOffFee: 'Once off fee',
                        setAsRecommendedPackage: 'Set as a recommended package',
                    },
                    contracts: {
                        contracts: 'Contracts',
                        selectContracts: 'Select contracts',
                    },
                },
                adminTemplateForm: {
                    publish: 'Publish',
                    required: 'Required',
                    cancel: 'Cancel',
                    code: 'Merge key',
                    templateName: 'Template Name',
                    subSectionName: 'Sub-section name',
                    subSectionNameRequired: 'Sub-section name is required',
                    detailsAndDescription: 'Details & Description',
                    sections: 'Sections',
                    categories: 'Categories',
                    history: 'History',
                    name: 'Name',
                    description: 'Description',
                    sectionName: 'Section name',
                    sectionNameRequired: 'Section name is required',
                    newSections: 'New Sections',
                    searchSections: 'Search sections',
                    view: 'View',
                    restore: 'Restore',
                    modified: 'Modified: ',
                    uploadDocument: 'Upload document',
                    save: 'Save',
                    question: 'Question',
                    explanation: 'Explanation',
                    selectQuestionFormat: 'Select format of the question:',
                    template: 'Template is required',
                    addCategory: 'Add category',
                    questionFormatForm: {
                        option: 'Option',
                        nameOfAnswer: 'Name of answer',
                        explanation: 'Explanation',
                        nameOfMergeKey: 'Name Merge key',
                        mergeKey: 'Merge key',
                        selectAnswerPosition: 'Select where in the contract this answer will be added and after that click on this button:',
                        addClauseContent: 'Add the clause content',
                        selectClausePosition: 'Select where in the contract this clause will be added and after that click on this button:',
                        selectDatePickerPosition: 'Select where in the contract date picker will be added and after that click on this button:',
                        add: 'Add',
                        newOptions: 'New options',
                        save: 'Save'
                    },
                    tooltips: {
                        placeMergeKey: 'Place merge key',
                        relations: 'Add relation',
                        editSectionName: 'Edit section name',
                        addSubSection: 'Add sub-section',
                        editSubSectionName: 'Edit sub-section name',
                        addQuestion: 'Add question',
                        editQuestion: 'Edit question',
                        addOptionExplanation: 'You can add an "Explanation" for each option',
                        selectToAddMergeKey: 'Select to add the merge key',
                        selectMergeCLauseType: 'Select merge clause type',
                        delete: 'Delete',
                        cancel: 'Cancel',
                        save: 'Save'
                    }
                }
            },
            contract: {
                addContractForm: {
                    editConfirm: 'Your contract will be overwritten with new data if you change the answers',
                    generate: 'Generate',
                    back: 'Back',
                    next: 'Next',
                    //----------just for test
                    documentDetails: {
                        documentName: 'Document Name',
                        addDocumentName: 'Add Document Name',
                        enterDocumentName: 'Enter Document Name',
                        documentNameRequired: 'Document name is required',
                        reviewers: 'Reviewers',
                        addReviewerName: 'Add Reviewer Name',
                        enterReviewerName: 'Enter Reviewer Name',
                        signers: 'Signers',
                        addSignerName: 'Add Signer Name',
                        enterSignerName: 'Enter Signer Name'
                    },
                    partiesDetails: {
                        employerDetails: 'Employer details',
                        addMyDetails: 'Add my details',
                        employerName: 'Employer Name',
                        enterEmployerName: 'Enter Employer Name',
                        employerAddress: 'Employer Address',
                        enterEmployerAddress: 'Enter Employer Address',
                        employerCountry: 'Employer Country of Incorporation',
                        enterEmployerCountry: 'Enter Employer Country of Incorporation',
                        employerRegistrationNumber: 'Employer Registration Number',
                        enterEmployerRegistrationNumber: 'Enter Employer Registration Number',
                        employeeDetails: 'Employee Details',
                        employeeName: 'Employee Name',
                        enterEmployeeName: 'Enter Employee Name',
                        employeeAdress: 'Employee Address',
                        enterEmployeeAdress: 'Enter Employee Address',
                    },
                    jobDescription: {
                        jobDates: 'Job Dates',
                        jobStartDate: 'Job Start Date',
                        chooseJobStartDate: 'Choose Job Start Date',
                        jobTitleAndDescription: 'Job Title & Description',
                        jobTitle: 'Job Title',
                        enterJobTitle: 'Enter Job Title',
                        jobDescription: 'Job Description',
                        enterJobDescription: 'Enter Job Description',
                        lineManagerName: 'Name of Line Manager',
                        enterLineManagerName: 'Enter Name of Line Manager'
                    },
                    durationAndRightToWork: {
                        duration: 'Duration',
                        rightToWork: 'Right to work',
                        termOfEmployment: 'Term of Employment',
                        indefinitely: 'Indefinitely',
                        fixed: 'Fixed',
                        rightToWorkInUK: 'Does the employee have a right to work in UK?',
                        yes: 'Yes',
                        appliedForWorkVisa: 'The person has applied for a work visa',
                        notRelevant: 'Not relevant'
                    },
                    allFields: {
                        allFieldsTitle: 'All fields',
                        textfieldTitle: 'Textfield',
                        textfieldPlaceholder: 'Textfield Placeholder',
                        toggleTitle: 'Toggle',
                        toggleLeftText: 'Toggle Value 1',
                        toggleRightText: 'Toggle Value 2',
                        autocompleteTitle: 'Autocomplete',
                        toggleSimpleTitle: 'Toggle simple',
                        no: 'No',
                        yes: 'Yes',
                        autocmpletePlaceholder: 'Autocomplete placeholder',
                        checkboxTitle: 'Checkbox',
                        datepickerTitle: 'Datepicker',
                        timepickerTitle: 'Timepicker',
                        textareaTitle: 'Textarea',
                        textareaPlaceholder: 'Textarea Placeholder',
                        radioButtonTittle: 'Radio button',
                        radio1: 'Radio button 1',
                        radio2: 'Radio button 2',
                        radio3: 'Radio button 3',
                        selectboxTitle: 'Selectbox',
                        selectboxDefault: 'Selectbox default',
                        numberInput: 'Number input'
                    }
                    //----------just for test

                },
                contractRequestForm: {
                    description: 'If you cannot find the contract you are looking for, please submit a request for Logical Contracts to create a contract for you',
                    fullName: 'Full name',
                    enterFullName: 'Enter full name',
                    companyName: 'Company name',
                    enterCompanyName: 'Enter company name',
                    email: 'Email',
                    enterEmail: 'Enter email',
                    mobileNumber: 'Mobile number',
                    enterMobileNumber: 'Enter Mobile Number',
                    contractName: 'Name of contract',
                    enterContractName: 'Enter name of contract',
                    yourQuery: 'Your query',
                    enterQuery: 'Enter query',
                    fieldError: 'Oops, please answer this question',
                    cancel: 'Cancel',
                    submit: 'Submit'
                }
            },
            contractList: {
                rejectSignForm: {
                    rejectionReason: 'Rejection reason',
                    title: 'Reject signature',

                },
                cancel: 'Cancel',
                submit: 'Submit',
                upload: 'Upload',
                sign: 'Start signing',
                folderForm: {
                    createFolderTitle: 'Create new folder',
                    editFolderTitle: 'Edit folder name',
                    folderName: 'Folder Name',
                    enterFolderName: 'Enter Folder Name',
                    folderNameErr: 'Oops, it looks like you already have a folder with the same name'
                },
                documentForm: {
                    title: 'Upload a document',
                    eSignTitle: 'Upload a Document For e-Signing',
                    documentName: 'Document Name',
                    enterDocumentName: 'Enter Document Name',
                    selectFolder: 'Select Folder',
                    message: "When uploading Word documents, formatting issues may occur, including the alteration of the numbering to our document editor's default numbering format. To ensure optimal compatibility and preserve formatting, it is highly recommended to upload PDF documents when uploading files for e-Signature.",
                    clickToUpload: 'Click to upload or Drag and Drop files',
                },
                templateDetailsForm: {
                    title: 'Template details',
                    documentName: 'Document Name',
                    documentNameTooltip: 'You can rename the document',
                    documentNameAlreadyExistMsg: 'Oops, it looks like you already have a contract with the same name',
                    termsAndConditions: 'Terms & Conditions - Logical Contracts',
                    enterDocumentName: 'Enter Document Name',
                    documentOwner: 'Document Owner',
                    documentOwnerTooltip: 'You can change the owner of the document here',
                    enterName: 'Enter Name'
                },
                signingForm: {
                    title: 'Add Text',
                    name: 'Name',
                    nameToolTip: 'Add your Text here',
                },
                commentsForm: {
                    title: 'Comments',
                    commentsTooltip: 'Collaborate and discuss this contract by adding your comments in this section',
                    markResolved: 'Mark as resolved',
                    resolved: 'Resolved',
                    delete: 'Delete',
                    edit: 'Edit',
                    replay: 'Reply',
                    replays: 'Replies',
                    textMessage: 'Text message',
                    submit: 'Submit',
                    commentText: 'Leave your comment',
                    replayText: 'Reply',
                    hide: 'Hide',
                    cancel: 'Cancel',
                    save: 'Save'
                },
                collaboratorsForm: {
                    reject: 'Reject',
                    placeSignature: 'Place Signature',
                    placeName: 'Name',
                    placeDate: 'Date Signed',
                    title: 'Collaborators',
                    cannotAddOwnerForReviewer: 'You cannot add an owner for the reviewer',
                    collaboratorsTooltip: 'Collaborate with reviewers and e-signers',
                    addCollaborators: 'Add collaborators',
                    addCollaboratorsTooltip: 'You can add reviewers and e-signatories by entering their name or email address',
                    enterFullName: 'Enter full name',
                    reviewer: 'Reviewer',
                    reviewers: 'Reviewers',
                    signatory: 'Signatory',
                    signatories: 'Signatories',
                    addExternalUser: 'Add external user',
                    cantFindUser: 'We could not find a user with that name or email address.',
                    sendInviteLink: 'Send an invite.',
                    sendInviteBtn: 'Send invite',
                    inviteCollaborator: 'Invite collaborator',
                    fullName: 'Full Name',
                    email: 'Email',
                    parties: 'Parties',
                    inviteHasBeenSent: 'Invite has been sent to the person',
                    sign: 'Sign'
                },
                foldersForm: {
                    title: 'Move to folder',
                    foldersTooltip: 'Move the document to an existing folder on your Contracts Dashboard',
                    description: 'Select which folder you would like to add the document to:'
                },
                historyForm: {
                    title: 'Revision history',
                    historyTooltip: 'You can view your revision history and restore to a previous version here',
                    currentVersion: 'Current version',
                    view: 'View',
                    restore: 'Restore'
                },
                shareForm: {
                    title: 'Share selected contract with other users',
                    titleFolder: 'Share selected folder with other users',
                    nameOrEmail: 'Enter name or email of the person you want to share the documents',
                    enterNameOrEmail: 'Enter name or email address',
                    inviteSent: 'Invite has been sent to:',
                    submitErrMsg: 'Oops, there are no selected users to share the documents with',
                    alreadySharedWith: 'Already shared with:',
                    share: 'Share',
                    unshare: 'Unshare',
                    documentOwner: 'Document owner:',
                    owner: 'Owner',
                    cancel: 'Cancel'
                },
                signForm: {
                    title: 'Adopt Your Signature',
                    fullName: 'Full name',
                    enterFullName: 'Enter full name',
                    initials: 'Initials',
                    enterInitials: 'Enter initials',
                    signatureName: 'Signature name',
                    enterSignatureName: 'Enter signature name',
                    uploadDocument: 'Upload document',
                    signatureFileErr: 'Only .png files are allowed',
                    sign: 'Sign',
                    adoptSignature: 'Adopt signature',
                    cancel: 'Cancel',
                    preview: 'Preview',
                    confirmYourNameAndSignature: 'Confirm your name and signature.',
                    askUserToAdoptSignature: "You have not adopted your signature yet, please adopt your signature before adding Date or Name"
                }
            },
            userList: {
                userForm: {
                    fullName: 'Full name',
                    addTitle: 'Add new user',
                    editTitle: 'Edit user',
                    profilePicture: 'Profile Picture',
                    pictureDescription: 'Click on the icon (photo) to upload an image or drag and drop on the same place',
                    firstName: 'First Name',
                    enterFirstName: 'Enter first name',
                    lastName: 'Last Name',
                    enterLastName: 'Enter last name',
                    email: 'Email',
                    enterEmail: 'Enter email',
                    partie: 'Party (company)',
                    typeOfUser: 'Type of user:',
                    cancel: 'Cancel',
                    save: 'Save'
                }
            },
            dashboard: {
                myDetails: {
                    generalInformationForm: {
                        uploadSignature: 'Upload signature',
                        generalInformation: 'My Profile',
                        profilePicture: 'Profile picture',
                        profilePictureDescription: 'Click on the icon (photo) to upload an image or drag and drop on the same place',
                        fullName: 'Full Name',
                        enterFullName: 'Enter full name',
                        email: 'Email',
                        enterEmail: 'Enter email',
                        emailError: 'Oops, invalid email, please ensure your email address consists of an email prefix to the left of the @ and an email domain to the right of the @',
                        signatureName: 'Signature name',
                        enterSignatureName: 'Enter signature name',
                        selectFolder: 'Select folder',
                        selectFolderName: 'Select folder name',
                        uploadDocument: 'Upoad document',
                        currentPassword: 'Current password',
                        currentPasswordError: 'For changing password this field is required',
                        newPassword: 'New password',
                        enterNewPassword: 'Enter new password',
                        confirmNewPassword: 'Confirm new password',
                        passwordError: 'Oops invalid password. Please ensure that your password is at least 7 characters and contain at least 1 number, 1 uppercase letter and 1 lower case letter.',
                        newPasswordsError: 'Your new password and confirmation password do not match',
                        cancel: 'Cancel',
                        save: 'Save',
                        notMatchedPasswords: 'Reset passwords do not match',
                        signatureFileErr: 'Only .png files are allowed',
                        additionalInfo: 'Additional Info',
                        addSignature: 'Add signature',
                        editCompanyInfo: 'Edit company info'
                    }
                },
                companyProfile: {
                    companyProfileForm: {
                        jurisdiction: 'Jurisdiction',
                        searchCompany: 'Search company',
                        aboutCompany: 'About Company',
                        companyName: 'Name',
                        companySector: 'Sector',
                        companyType: 'Type',
                        enterCompanyName: 'Enter company name',
                        companyAddress: 'Address',
                        enterCompanyAddress: 'Enter company address',
                        companyWebsite: 'Website',
                        enterCompanyWebsite: 'Enter company website',
                        companyNumber: 'Company Registration Number',
                        enterCompanyNumber: 'Enter company registration number',
                        countryOfIncorporation: 'Country of Incorporation',
                        enterCountryOfIncorporation: 'Enter country of incorporation',
                        vatNumber: 'VAT number',
                        enterVatNumber: 'Enter vat number',
                        cancel: 'Cancel',
                        save: 'Save'
                    }
                },
                pricingAndPackage: {
                    buyPackageForm: {
                        yourPackage: 'Your Package',
                        billingCycle: 'Billing Cycle',
                        priceBreakdown: 'Price Breakdown',
                        userDetails: 'User Details',
                        name: 'Name',
                        enterName: 'Enter name',
                        email: 'Email',
                        enterEmail: 'Enter email address',
                        card: 'Card Payment Details',
                        cardNumber: 'Card Number',
                        enterCardNumber: 'Entre card number',
                        cvc: 'CVC',
                        enterCVC: 'Enter CVC',
                        expiry: 'Expiry',
                        yourPlanIncludes: 'Your Plan Includes',
                        orderSummary: 'Order Summary',
                        vat: 'VAT',
                        referralCode: 'Enter a referral code and get a discount',
                        enterReferralCode: 'Enter a referral code',
                        orderTotal: 'ORDER TOTAL',
                        pay: 'Pay',
                        cancel: 'Cancel',
                        annualPlan: 'Annual Plan',
                        cancelRenewal: 'Cancel renewal',
                        yourNextPayment: 'Your next payment is',
                        toBeCharged: 'to be charged on',
                        yourPaymentWillBeRenewed: 'Your payment will be automatically renewed each year'
                    },
                    customPackageForm: {
                        customPackage: 'Custom package',
                        fullCompanyName: 'Full Company Name',
                        enterCompanyName: 'Enter company name',
                        email: 'Email',
                        enterEmail: 'Enter email',
                        numberOfEmployees: 'Number of Employees',
                        enterNumberOfEmployees: 'Enter number of employees',
                        selectPackage: 'Which Package interests you?',
                        uploadingYourTemplate: 'Are you interested in uploading your own template?',
                        additionalInformation: 'Additional Information',
                        enterAdditionalInformation: 'Additional Information',
                        yes: 'Yes',
                        no: 'No',
                        cancel: 'Cancel',
                        submit: 'Submit'
                    },
                }
            },
            dealRoom: {
                freeTrialMsg:"Your Virtual Data Room Has been Set up. You have a 7-day free trial to trail these features.",
                connect: {
                    legalSupport: {
                        title: "Legal Support",
                        para: "Enjoy complimentary legal consultations with our expert legal partners.",
                    },
                    funding: {
                        title: "Funding",
                        para: "Explore flexible funding solutions for acquisitions, inventory purchases, and cashflow management with our dedicated finance partners.",
                    },
                    businessInfo: {
                        title: "Business information",
                        para: "Answer a few questions for us"
                    },
                    borrow: {
                        title: "Borrowing",
                        para: "Answer a few questions for us",
                    },
                    buyOrSell: {
                        title: "Buy or  Sell a Business",
                        para: "Explore opportunities to buy or sell a business, and receive expert guidance forclosing a deal."
                    },
                },
                dueDeligence:{
                    deleteSectionTitle: "Please note: Deleting this section will also remove its linked folder in the data room, along with all documents contained within.",
                    shareResponseTitle:"By sharing, all new questions, sections, responses, and documents added by your party will be visible to the other party."
                }

            }
        },
        modals: {
            contractNotAllowed: {
                no: 'No',
                yes: 'Yes',
                confirmText: 'Your current package do not support this contract. Do you want to check out our packages?'
            },
            contractCreatorNotAllowed: {
                ok: 'Ok',
                contentTextTrial: "To create a contract please register for a 7-day free trail.",
                contentText: "We're sorry, but it looks like you don't have access to contract creation under your current subscription package. To create new contracts, please upgrade your subscription plan or contact our support team for assistance."
            },
            trialModal: {
                ok: 'Ok',
                confirmText: 'You have exceeded the contract limit for your free trail, to generate a contract please subscribe',
                confirmTextChild: 'You have exceeded the contract limit for your free trail, to generate a contract the admin of your organisation shall be required to subscribe'
            },
            welcomeInfo: {
                hello: 'Hello',
                welcomeTo: 'Welcome to',
                logicalContracts: 'Logical Contracts.',
                msg: 'We are ready for you to create your first contract with us today.'
            },
            packageDisabled: {
                contentText: "We're sorry, but it looks like your current subscription package has been disabled. To create new contracts, please upgrade your subscription plan or contact our support team for assistance.",
                no: 'No',
                yes: 'Yes'
            },
            adminPackages: {
                title: 'Are you sure you want to deactivate?',
                confirmText: 'The packages will be removed from all linked categories.', //should be changed
                cancelBtn: 'Cancel',
                deactivateBtn: 'Deactivate'
            },
            adminCategories: {
                title: 'Are you sure you want to deactivate?',
                confirmText: 'The categories or sub-categories will be removed from all linked contracts and packages.',
                cancelBtn: 'Cancel',
                deactivateBtn: 'Deactivate'
            },
            addAdmintemplate: {
                title: "You can not save empty content!",
            },
            adminTemplates: {
                title: 'Are you sure you want to deactivate?',
                confirmTextTemplate: 'The template will be removed from all linked categories and packages.',
                cancelBtn: 'Cancel',
                deleteBtn: 'Deactivate',
                confirmTextSection: 'The section will be removed from all linked templates.',
                confirmTextNewSection: 'New created section will be removed.',
                confirmTextSubSection: 'The sub-section will be removed from all linked sections.',
                confirmTextCategory: 'The category will be removed from all linked templates.',
                confirmTextQuestion: 'The question will be removed from all linked sub-sections.'
            },
            adminSubCategory: {
                title: 'Are you sure you want to deactivate?',
                cancelBtn: 'Cancel',
                confirmText: 'The sub-category will be removed from all linked packages and sub-categories',
                deactivateBtn: 'Deactivate'
            },
            contractList: {
                nonValidSubscription: {
                    no: 'No',
                    yes: 'Yes',
                    ok: 'Ok',
                    confirmTextFreeTrial: 'To unlock all features company administrator is required to start a subscription.',
                    confirmTextSubscriptionEnd: 'Unlock all features by signing up for a complimentary 7-day trial.',
                },
                subscribeWarningText: 'Sorry, contract downloads are a subscriber only feature. To access this feature, your company administrator is required to subscribe to a package.',
                subscriptionRequired: {
                    freeTrialSubscriptionText: 'To unlock all features company admin is required to sign up for a complimentary 7-day trial.',
                    subscriptionText: 'To unlock all features your company administrator is required to start a subscription.',
                },
                infoText: 'There are no selected documents',
                ok: 'Ok',
                deleteContract: {
                    confirmText: 'Are you sure you want to delete selected documents?',
                    confirmTextForAI: 'Are you sure you want to delete selected data?',
                    confirmTextforTaggedQuestion: 'This will remove the tagged questions. Are you sure you want to remove them. Press yes to continue.',
                    no: 'No',
                    yes: 'Yes'
                },
                downloadContract: {
                    confirmText: 'Do you want to download contract?',
                    cancel: 'Cancel',
                    yes: 'Yes'
                },
                moveToFolderContract: {
                    confirmText: 'Select which folder you would like to add the document to:'
                },
                unshare: {
                    confirmText: 'Are you sure you want to unshare selected document with selected user?',
                    cancel: 'Cancel',
                    yes: 'Yes'
                },
                deleteFolder: {
                    confirmText: 'Are you sure you want to delete selected folder?',
                    no: 'No',
                    yes: 'Yes'
                },
            },
            userList: {
                ok: 'Ok',
                trialNotActivatedText: 'To unlock this feature please sign up for a complimentary 7-day trial.',
                subscriptionExpiredText: 'To unlock this feature your organisations administrator is required to subscribe.',
                freeTrialSubscriptionText: 'To unlock all features admin is required to sign up for a complimentary 7-day trial.',
                deleteUser: {
                    confirmText: 'Are you sure you want to delete selected user?',
                    cancel: 'Cancel',
                    yes: 'Yes'
                }
            },
            pricingAndPackage: {
                buyPackage: {
                    infoText: 'You have successfully subscribed to the ',
                    infoBtn: 'Go to Dashboard'
                },
                downgradedPackage: {
                    infoText: ', you will be able to access your current package until the end of your subscription month and then you will only be able to access contracts within your new package.',
                    infoBtn: 'Go to Dashboard'
                }
            },
            subscriptionDetails: {
                unsubscribe: {
                    confirmText: 'Are you sure you want to unsubscribe?',
                    cancel: 'Cancel',
                    yes: 'Yes',
                    update: 'Update',
                    add: 'Add'

                },
                unsubscribeInfo: {
                    infoText: 'You have unsubscribed from this package, your last day to generate a contract would be',
                    infoBtn: 'Subscribe Now'
                },
                unsubscribeInfoLogin: {
                    infoText: "Your company's subscription has expired, please reach out to your business admin"
                },
                unsubscribeInfoViewContracts: {
                    infoText: "Your subcription has expired",
                    infoBtn: 'Subscribe Now'
                },
                editPaymentMethod: {
                    editPaymentMethod: 'Enter Your Card Information',
                    updateCardInfo: 'Your Saved Card',
                    fullName: 'Full name',
                    enterFullName: 'Enter full name',
                    creditCard: 'Card Payment Detail',
                    cardNumber: 'Card Number',
                    expirytime: 'Valid Till',
                    creditCardChange: 'This will replace your existing card. Are you sure you want to replace it ?'
                }
            },
            adminDealRoom:{
                deleteModalTitle:"Are you sure you want to delete this Section?",
                yes:"Yes",
                no:"No",
                cancel:"Cancel",
                ok:"Ok",
                share:"Share"
            },
            dueDeligenceRedircet: {
                responseMessage: "You can not delete the folder associated with Due Diligence.",
                editResponseMessage: "You can not rename the folder associated with Due Diligence.",
                redirectConfirmationTitle: "Please delete this section in the Due Diligence questions"
            }
        },
        table: {
            actions: 'Actions',
            edit: 'Edit',
            view: 'View',
            delete: 'Delete',
            restore: 'Restore'
        },
        months: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December'
        },
        constants: {
            base: {
                currency: {
                    pound: '£'
                }
            },
            user: {
                userDescription: {
                    soleTraderIndividual: 'Sole Trader Individual',
                    freelanceConsultant: 'Freelance Consultant',
                    privateLimitedCompany: 'Private Limited Company',
                    publicLimitedCompany: 'Public Limited Company',
                    partnership: 'Partnership',
                    employee: 'Employee',
                    browsingOrLooking: 'Browsing or just looking',
                    other: 'Other',
                },
                companyEmployeesNumber: {
                    lessThan: 'Less than 10',
                    moreThan: '10+',
                },
                userGoal: {
                    createQuickly: 'Creating contracts more quickly and efficiently',
                    createResponsableCost: 'Creating contracts at a responsable cost',
                    createWithColleagues: 'Creating contracts with my colleagues, customer and suppliers',
                    learningUnderstanding: 'Learning and understanding more about contracts',
                    menagingStoring: 'Managing and storing all of my contracts',
                    none: 'None of these',
                },
                companyTurnover: {
                    lessThan500k: '£0 - £500k',
                    between500kAnd1m: '£500k - £1m',
                    between1mAnd2m: '£1m - £2m',
                    moreThan1m: '£1m+',
                },
                userProfileType: {
                    company: 'Company',
                    individual: 'Individual'
                },
                sortUser: {
                    all: 'All',
                    admin: 'Admin',
                    user: 'User',
                    bussinesUser: 'Bussines user'
                },
                userType: {
                    all: 'All',
                    systemAdminUser: 'System Admin User',
                    systemAdminUserTooltip: "System Admin Users are able to see all users’ documents within their organisation, manage their profiles and manage the organisation's payments.",
                    portalAdminUser: 'Portal Admin User',
                    portalAdminUserTooltiop: "Portal Admin Users are able to see all users’ documents within their organisation, manage their profiles and manage the organisation's payments.",
                    companyAdminUser: 'Admin',
                    companyAdminUserTooltip: 'Business Admin Users are the admin for the company and manages the child users and the organisation',
                    companyUser: 'User',
                    companyUserTooltip: 'Business Users set up the account for their business, using their business details, so their employees can use the portal.',
                    individualUser: 'Individual User',
                    individualUserTooltip: 'Individual Users have the same permissions as the business user, but can only set up 1 login.',
                    guestUser: 'Guest User',
                    guestUserTooltip: 'External users that will be invited review/e-sign.'
                },
                sector: {
                    accommodationAndFood: 'Accommodation and Food Service Activities',
                    administrativeAndSupport: 'Administrative and Support Service Activities',
                    agricultureForestryFishing: 'Agriculture, Forestry and Fishing',
                    artsAndEntertainment: 'Arts, Entertainment and Recreation',
                    construction: 'Construction',
                    education: 'Education',
                    electricityGasSteamAir: 'Electricity, Gas, Steam and Air Conditioning Supply',
                    financialAndInsurance: 'Financial and Insurance Activities',
                    helthAndSocialWork: 'Human Health and Social Work Activities',
                    informationAndCommunication: 'Information and Communication',
                    manufacturing: 'Manufacturing',
                    miningAndQuarrying: 'Mining and Quarrying',
                    otherService: 'Other Service Activities',
                    professionalScientificAndTechnical: 'Professional, Scientific and Technical Activities',
                    realEstate: 'Real Estate Activities',
                    transportationAndStorage: 'Transportation and Storage',
                    waterSupply: 'Water Supply; Sewerage, Waste Management and Remediation Activities',
                    wholesaleAndRetailTrade: 'Wholesale and Retail Trade',
                    advisoryAndFinancial: 'Advisory & Financial Services',
                    consumerGoods: 'Consumer Goods & Services',
                    onlineRetail: 'Online Retail',
                    engineeringInfrastructureAndContractors: 'Specialist Engineering, Infrastructure & Contractors',
                    technology: 'Technology'
                }
            },
            admin: {
                questionRelationType: {
                    equal: 'Equal',
                    lower: 'Lower',
                    lowerEqual: 'Lower or equal',
                    greater: 'Greater',
                    greaterEqual: 'Greater or equal',
                    notEqual: 'Not equal'
                },
                adminCategory: {
                    all: "All",
                    active: "Active",
                    deactivated: 'Deactivated'
                },
                adminPackage: {
                    all: "All",
                    active: "Active",
                    deactivated: 'Deactivated'
                },
                adminTemplate: {
                    all: "All",
                    active: "Active",
                    deactivated: 'Deactivated'
                },
                questionFormat: {
                    company: 'Company',
                    toggle: 'Toggle',
                    autocomplete: 'Autocomplete',
                    textarea: 'Textarea',
                    numberInput: 'Number input',
                    dropdown: 'Dropdown',
                    radioButton: "Radio Button",
                    switchButton: "Switch",
                    checkbox: "Checkbox",
                    timePicker: "Time Picker",
                    datePicker: "Date Picker",
                    dropdownBox: "Dropdown Box",
                    textField: 'Text field',
                    slider: 'Slider',
                    checkboxGroup: 'Checkbox'
                }
            },
            contract: {
                contractStatus: {
                    approved: 'Approved',
                    created: 'Created',
                    pendingReview: 'Pending review',
                    reviewed: 'Reviewed',
                    pendingSign: 'Pending sign',
                    status: 'Status',
                    signed: 'Signed',
                    all: 'All',
                    inNegotiations: 'In negotiations',
                    termsAgreed: 'Terms agreed',
                    outForSignature: 'Out for signature',
                    completed: 'Completed',
                    rejected: 'Rejected',
                },
                sortContract: {
                    sortBy: 'Sort By',
                    aToZ: 'A to Z',
                    zToA: 'Z to A',
                    lastUploaded: 'Last Uploaded',
                    firstUploaded: 'First Uploaded',
                    lastModified: 'Last Modified',
                    createdDate: 'Created Date'
                },
                parties: {
                    parties: 'Parties',
                    partieA: 'Party A',
                    partieB: 'Party B',
                    partieC: 'Party C'
                }
            },
            dashboard: {
                payment: {
                    all: 'All',
                    paid: 'Paid',
                    unpaid: 'Unpaid',
                    transactionDate: 'Transaction Date'
                },
                pricingAndPackages: {
                    startFreeTrial7Days: 'Start 7-day Free Trial',
                    recommended: 'Recommended',
                    subscribe: 'Subscribe',
                    talkToUs: 'Talk To Us',
                    downgrade: 'Downgrade',
                    currentPlan: 'Current plan',
                    upgradeNow: 'Upgrade now',
                    year: 'year',
                    month: 'month',
                    pricing: 'Pricing',
                    priceCurrency: '£',
                    annualSavingsMissed: 'Annual savings missed',
                    launch: {
                        name: 'Launch',
                        description: 'For startups or projects in early development',
                        features: {
                            title: 'Features',
                            feature1: 'Up to 6 contracts including Shareholders’ agreement',
                            feature2: 'Employment contract',
                            feature3: 'Confidentiality Agreement (NDA)'
                        },
                        additionalFeatures: {
                            title: 'Additional Features',
                            feature1: 'Use our Companies House Connector to submit and file online documents to the Register of Companies',
                            feature2: 'Receive general support from Logical team',
                            feature3: 'Use Logical A.I. Bot to understand contracts used in your indusry and trade'
                        }
                    },
                    grow: {
                        name: 'Grow',
                        description: 'For businesses accelerating in growth and scale after the Launch phase',
                        features: {
                            everythingInLaunch: 'Everything in Launch +',
                            feature1: 'Over 25+ contracts',
                            feature2: 'Human Resoure pack',
                            feature3: 'Intellectual Property Pack',
                            feature4: 'Growth Funding Pack',
                            feature5: 'Companies House Connector',
                            feature6: 'Access to Logical A.I. Bot'
                        },
                        additionalFeatures: {
                            additionalFeatures: 'Additional Features',
                            feature1: 'Use our Companies House Connector to submit and file online documents to the Register of Companies',
                            feature2: 'Receive priority support from Logical team',
                            feature3: 'Use Logical A.I. Bot to understand contracts used in your indusry and trade'
                        },
                        priceBreakDown: '£50.00/month ANNUAL SAVINGS MISSED:  £150', //to do
                    },
                    expansion: {
                        name: 'Expansion',
                        description: 'For businesses on top of their industry looking to expand by acquisition',
                        features: {
                            everythingInGrow: 'Everything in Grow +',
                            feature1: 'Due diligence checklist',
                            feature2: 'Share purchase agreement',
                            feature3: 'Asset purchase agreement',
                            feature4: 'Documents for closing the deal'
                        },
                        additionalFeatures: {
                            additionalFeatures: 'Additional Features',
                            feature1: 'Use our Companies House Connector to submit and file online documents to the Register of Companies',
                            feature2: 'Receive 24/7 support from Logical team',
                            feature3: 'Use Logical A.I. Bot to understand contracts used in your indusry and trade'
                        },
                        priceBreakDown: '£50.00/month ANNUAL SAVINGS MISSED:  £150', //to do
                    },
                    custom: {
                        name: 'Custom',
                        description: 'Choose a custom plan to suit your business needs and wants',
                        features: {
                            features: 'Features',
                            feature1: 'Create one custome contract not available in our plan Unlimited users to review, edit and approve your contracts'
                        },
                        additionalFeatures: {
                            additionalFeatures: 'Additional Features',
                            feature1: 'Use our Companies House Connector to submit and file online documents to the Register of Companies',
                            feature2: '24/7 unlimited support from Logical team',
                            feature3: 'Use Logical A.I. Bot to understand contracts used in your indusry and trade',
                            feature4: 'White label suport'
                        },
                        priceBreakDown: '£50.00/month ANNUAL SAVINGS MISSED:  £150', //to do
                    }
                },
                billingCycle: {
                    monthlyPackages: 'Monthly Packages - Save  £23 with annual'
                }
            }
        }
    }
});

export default strings;
