import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getDealRoomFolders(projectId) {
  return await request(
    `/api/dataRoom/${projectId}/folders`,
    [],
    HttpMethod.GET
  );
}
export async function addDataRoomFolder(projectId, folderId, data) {
  return await request(
    `api/dataRoom/${projectId}/folders/${folderId}`,
    data,
    HttpMethod.POST
  );
}

export async function editDataRoomFolder(projectId, folderId, data) {
  return await request(
    `api/dataRoom/${projectId}/folders/${folderId}/rename`,
    data,
    HttpMethod.PATCH
  );
}

export async function getAllSubFolders(projectId, folderId) {
  return await request(
    `api/dataRoom/${projectId}/folders/${folderId}/sub-folders`,
    [],
    HttpMethod.GET
  );
}
export async function getAllContractByFolderId(projectId, folderId) {
  return await request(
    `api/dataRoom/${projectId}/folders/${folderId}/contracts`,
    [],
    HttpMethod.GET
  );
}

export async function uploadContractByFolderId(projectId, folderId, data) {
  return await request(`api/dataRoom/${projectId}/folders/${folderId}/upload/contracts`, data, HttpMethod.POST);
}
export async function deleteSubFolder(projectId, folderId) {
  return await request(
    `api/dataRoom/${projectId}/folder/${folderId}`,
    [],
    HttpMethod.DELETE
  );
}

export async function deleteContractById(projectId, contractId) {
  return await request(`api/dataRoom/${projectId}/contracts/${contractId}`, [], HttpMethod.DELETE);
}

export async function getContractById(projectId, contractId) {
  return await request(
    `api/dataRoom/${projectId}/contracts/${contractId}`,
    [],
    HttpMethod.GET
  );
}

export async function getMultipleContractZip(projectId,ids) {
  return await request(
    `api/dataRoom/${projectId}/contracts/export/zip`,ids,HttpMethod.POST
  );
}
