import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Controller } from 'react-hook-form';

const CheckBoxControl2 = ({value, setValue, name, label, control, defaulValue, disabled}) => {

    const [checked, setChecked] = useState(defaulValue);

    const handleChange = (event) => {
        setValue(name, event)
        setChecked(event)
      };

    return <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <FormControlLabel
                        label={label}
                        className='checkbox-control'
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={(e) => handleChange(e.target.checked)}
                                disabled={disabled ? disabled : false}
                            />
                        }
                    />
                )}
            />
}

export default CheckBoxControl2;