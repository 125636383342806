import React from "react";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";

const PieChartComponent = ({ data, centerLabel = "" }) => {
  const size = {
    width: 600,
    height: 400,
  };

  const StyledText = styled("text")(() => ({
    fill: "#283959",
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 25,
    fontWeight: 700,
    fontFamily: "roboto",
  }));

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }
  const customColorScheme = [
    "#003049",
    "#D62828",
    "#F77F00",
    "#FCBF49",
    "#EAE2B7",
    "#84b7ff",
    "#fbd234",
    "#DAF7A6",
    "#C70039",
    "#F7E019",
  ];

  return (
    <Box>
      <PieChart
        series={[
          {
            data: data,
            innerRadius: 100,
          },
        ]}
        {...size}
        legend={{ hidden: true }}
        colors={customColorScheme}
      >
        <PieCenterLabel>{centerLabel}</PieCenterLabel>
      </PieChart>
    </Box>
  );
};

export default PieChartComponent;
