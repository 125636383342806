import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getPackage(id) {
    return await request('/api/package/' + id);
}

export async function getAllPackages(data) {
    return await request('/api/package/all', data);
}

export async function getAllPublicPackages() {
    return await request('/api/package/all/public');
}

export async function getAllPackagesForSubscription() {
    return await request('/api/package/all/subscription');
}

export async function changeActivePackage(data) {
    return await request('/api/package/active/' + data.id, data, HttpMethod.PATCH);
}

export async function deletePackage(id) {
    return await request('/api/package/' + id, {}, HttpMethod.DELETE);
}

export async function addPackage(data) {
    return await request('/api/package/add', data, HttpMethod.POST);
}

export async function addUserPackage(data) {
    return await request('/api/userpackage/add', data, HttpMethod.POST);
}

export async function editPackage(data) {
    return await request('/api/package/edit/' + data.id, data, HttpMethod.PUT);
}

export async function getUsersByPackage(id) {
    return await request('/api/userpackage/package/' + id);
}

export async function getPackagesByUser(id) {
    return await request('/api/userpackage/user/' + id);
}

export async function deleteUserPackage(id) {
    return await request('/api/userpackage/' + id, {}, HttpMethod.DELETE);
}

export async function getContractCategoryByPackage(id) {
    return await request('/api/contractcategorypackage/package/' + id);
}

export async function getPackageByContractCategory(id) {
    return await request('/api/contractcategorypackage/category/' + id);
}

export async function getTemplatesByPackage(id) {
    return await request('/api/packagetemplate/package/' + id);
}

export async function getFeaturesByPackage(id) {
    return await request('/api/packagefeature/package/' + id);
}

function transformRequestData(data) {
    return data;
}