import strings from "../../localization";

export function getSectors() {
    return [
        {
            id: 'Legal Services/Solicitor',
            name: 'Legal Services/Solicitor'
        },
        {
            id: 'Accounting/Bookkeeping',
            name: 'Accounting/Bookkeeping'
        },
        {
            id: 'Financial Services',
            name: 'Financial Services'
        },
        {
            id: 'Retail/Wholesale',
            name: 'Retail/Wholesale'
        },
        {
            id: 'Pub/Restaurant/Cafe',
            name: 'Pub/Restaurant/Cafe'
        },
        {
            id: 'Education/Childcare',
            name: 'Education/Childcare'
        },
        {
            id: 'Charity/Not-for-Profit',
            name: 'Charity/Not-for-Profit'
        },
        {
            id: 'Construction/Building Trades',
            name: 'Construction/Building Trades'
        },
        {
            id: 'Residential/Commercial Landlords',
            name: 'Residential/Commercial Landlords'
        },
        {
            id: 'Estate Agent/Property Management',
            name: 'Estate Agent/Property Management'
        },
        {
            id: 'Fitness/Gym/Beauty',
            name: 'Fitness/Gym/Beauty'
        },
        {
            id: 'Social Care',
            name: 'Social Care'
        },
        {
            id: 'Distribution/Transport',
            name: 'Distribution/Transport'
        },
        {
            id: 'Hire/Rental',
            name: 'Hire/Rental'
        },
        {
            id: 'Recruitment',
            name: 'Recruitment'
        },
        {
            id: 'Human Resources',
            name: 'Human Resources'
        },
        {
            id: 'Health & Safety',
            name: 'Health & Safety'
        },
        {
            id: 'IT & Web Services',
            name: 'IT & Web Services'
        },
        {
            id: 'Software/Web/App Development',
            name: 'Software/Web/App Development'
        },
        {
            id: 'Advertising/Marketing',
            name: 'Advertising/Marketing'
        },
        {
            id: 'Medical/Dental',
            name: 'Medical/Dental'
        },
        {
            id: 'Hotel/B & B',
            name: 'Hotel/B & B'
        },
        {
            id: 'Media/Publishing',
            name: 'Media/Publishing'
        },
        {
            id: 'Cleaning/Waste/Environmental Services',
            name: 'Cleaning/Waste/Environmental Services'
        },
        {
            id: 'Events/Hospitality/Catering',
            name: 'Events/Hospitality/Catering'
        },
        {
            id: 'Manufacturing/Workshop',
            name: 'Manufacturing/Workshop'
        },
        {
            id: 'Security',
            name: 'Security'
        },
        {
            id: 'Other',
            name: 'Other'
        },

    ]
}

export function getSectorById(id) {
    return getSectors().find(e => e.id === id)
}

