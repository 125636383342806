import React from 'react';

const TypingLoader = () => {
  const style = {
    loader: {
      textAlign: 'center',
    },
    dot: {
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '10px',
      height: '10px',
      background: '#5689ec',
      borderRadius: '50%',
      animation: 'loader 0.8s infinite alternate',
    },
    dot2: {
      animationDelay: '0.2s',
    },
    dot3: {
      animationDelay: '0.6s',
    },
    '@keyframes loader': {
      '0%': {
        opacity: 0.9,
        transform: 'scale(0.5)',
      },
      '100%': {
        opacity: 0.1,
        transform: 'scale(1)',
      },
    },
  };

  const styleTag = document.createElement('style');
  styleTag.innerHTML = `
    @keyframes loader {
      0% {
        opacity: 0.9;
        transform: scale(0.5);
      }
      100% {
        opacity: 0.1;
        transform: scale(1);
      }
    }
  `;
  document.head.appendChild(styleTag);

  return (
    <div style={style.loader}>
      <span style={{ ...style.dot }}></span>
      <span style={{ ...style.dot, ...style.dot2 }}></span>
      <span style={{ ...style.dot, ...style.dot3 }}></span>
    </div>
  );
};

export default TypingLoader;