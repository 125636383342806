import React, { useEffect, useState, useContext, useCallback } from "react";
import styles from "./AIData.module.css";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import SimpleConfirmModal from "./../../../Components/Modals/SimpleConfirmModal";
import {
  aiFineTuneClause,
  clauseDeleteById,
  createClause,
  getAllAIData,
} from "../../../Services/AI/AIConversationService";
import { VisibilityOutlined } from "@mui/icons-material";
import AiDataTable from "./AiDataTable";
import { aiDataActions } from "../../../Slices/AiDataSlice";
import { useDispatch } from "react-redux";
import SnackbarContext from "../../../Context/SnackbarContext";
import strings from "../../../localization";
import {
  AIDatacolumns,
  caluseDataModal,
} from "../../../Constants/Admin/AIDataModel";
import { useNavigate } from "react-router-dom";

function AIData() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { showMessage } = useContext(SnackbarContext);

  const [aiAllData, setAiAllData] = useState([]);
  const [showAiData, setShowAiData] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionsObject, setOptionsObject] = useState([]);
  const [isOptionSearchOptonsBox, setIsOpenSearchOptionBox] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [optionSearchText, setOptionSearchText] = useState("");
  const [checkedAllOptions, setCheckedAllOptions] = useState(false);
  const [showColumn, setShowColumn] = useState(AIDatacolumns);
  // const [columnFilterData, setColumnFilterData] = useState([]);
  // const [columnSelectedOption, setColumnSelectedOption] = useState([]);
  const [filterOptionSearchText, setFilterOptionSearchText] = useState("");

  const [deleteTab, setDeleteTab] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteTab = (id) => {
    setDeleteId(id);
    setDeleteTab(true);
  };

  const handleCloseDeleteTab = () => {
    setDeleteId(null);
    setDeleteTab(false);
  };

  const editHandler = (data) => {
    dispatch(aiDataActions.setAiData(data));
    // setSubOption("AIDataCreate");

    navigate(`/AIData/${data.id}`);
  };

  const deleteHandler = () => {
    if (deleteId !== null) {
      clauseDeleteById(deleteId)
        .then((res) => {
          setDeleteTab(false);
          showMessage(strings.common.deleted);
          getAllAIData()
            .then((res) => {
              setAiAllData(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const tableButtonsJsx = (data) => {
    return (
      <div className={styles.tableCell}>
        <VisibilityOutlined
          title={"View"}
          style={{ cursor: "pointer" }}
          onClick={() => editHandler(data)}
        />
        <img
          title={"Delete"}
          className={styles.iconImg}
          style={{ marginLeft: "10px", cursor: "pointer" }}
          src="/images/icons/delete.png"
          onClick={() => handleDeleteTab(data.id)}
          alt="delete"
        />
      </div>
    );
  };

  const submitSelectedOptionHandler = () => {
    let tempOptionsObj = structuredClone(optionsObject);
    setSelectedOptions(tempOptionsObj);
    setIsOpenSearchOptionBox(false);
  };

  const submitSelectedColumnFilterHandler = (column) => {
    let tempfilterObj = [];

    column.options.forEach((colOption) => {
      if (colOption.checked) {
        if (Array.isArray(aiAllData[0][column.id])) {
          aiAllData.forEach((aiData) => {
            if (aiData[column.id].length > 0) {
              aiData[column.id]?.forEach((aiDataValue) => {
                if (aiDataValue?.includes(colOption.label)) {
                  const find = tempfilterObj.find(
                    (obj) => obj.id === aiData.id
                  );
                  if (!find) {
                    tempfilterObj.push(aiData);
                  }
                }
              });
            }
          });
        } else {
          const filterData = aiAllData.filter((aiData) =>
            aiData[column.id]?.includes(colOption.label)
          );
          tempfilterObj = tempfilterObj.concat(filterData);
        }
      }
    });

    let filter_TempFilterObj = [];
    showColumn.forEach((col) => {
      col.options.forEach((colOption) => {
        if (colOption.checked) {
          if (Array.isArray(aiAllData[0][col.id])) {
            tempfilterObj.forEach((filterObj) => {
              if (filterObj[col.id].length > 0) {
                filterObj[col.id]?.forEach((aiDataValue) => {
                  if (aiDataValue?.includes(colOption.label)) {
                    const find = filter_TempFilterObj.find(
                      (obj) => obj.id === filterObj.id
                    );
                    if (!find) {
                      filter_TempFilterObj.push(filterObj);
                    }
                  }
                });
              }
            });
          } else {
            const filterData = tempfilterObj.filter((aiData) =>
              aiData[col.id]?.includes(colOption.label)
            );
            filter_TempFilterObj = filter_TempFilterObj.concat(filterData);
          }
        }
      });
    });

    let tempFilter_TempFilterObj = [];
    filter_TempFilterObj?.forEach((data) => {
      const find = tempFilter_TempFilterObj.find(
        (tempData) => tempData.id === data.id
      );
      if (!find) {
        tempFilter_TempFilterObj.push(data);
      }
    });

    columnFilterToggle(column.id);
    setShowAiData(tempFilter_TempFilterObj);
    setFilterOptionSearchText("");
  };

  const cancelSelectedOptionHandler = () => {
    if (selectedOptions.length > 0) {
      let tempSelectedOptions = structuredClone(selectedOptions);

      setOptionsObject(tempSelectedOptions);
    } else {
      setOptionsObj();
    }

    setIsOpenSearchOptionBox(false);
  };

  const selectSearchOptionsHandler = (e) => {
    if (e.target.name === "selectAll") {
      setCheckedAllOptions(e.target.checked);
      let obj = optionsObject.map((optionObj) => {
        optionObj.checked = e.target.checked;
        return optionObj;
      });
      setOptionsObject(obj);
    } else {
      let obj = optionsObject.map((optionObj) => {
        if (e.target.name === optionObj.name) {
          optionObj.checked = e.target.checked;
          return optionObj;
        }
        return optionObj;
      });
      setOptionsObject(obj);
    }
  };

  const selectColumnFilterDataHandler = (colId, optionLabel) => {
    if (optionLabel === "selectAll") {
      let obj = showColumn.filter((showCol) => {
        if (showCol.id === colId) {
          showCol.isSelectAll = !showCol.isSelectAll;
          showCol.options.forEach((option) => {
            option.checked = showCol.isSelectAll;
          });
        }
        return showCol;
      });
      setShowColumn(obj);
    } else {
      let obj = showColumn.filter((showCol) => {
        if (showCol.id === colId) {
          showCol.options.forEach((option) => {
            if (option.label === optionLabel) {
              option.checked = !option.checked;
            }
          });
        }
        return showCol;
      });
      setShowColumn(obj);
    }
  };

  const setOptionsObj = () => {
    if (showColumn) {
      let tempOptionsObj = [];
      showColumn.forEach((col) => {
        tempOptionsObj.push({
          name: col.id,
          label: col.label,
          checked: true,
          isShown: true,
        });
      });

      setOptionsObject(tempOptionsObj);
    }
  };

  useEffect(() => {
    setOptionsObj();
  }, []);

  useEffect(() => {
    getAllAIData()
      .then((res) => {
        setAiAllData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (aiAllData?.length > 0) {
      setShowAiData(aiAllData);
    }
    let tempShowColumn = AIDatacolumns?.filter((col) => {
      col.options = dataForColumnFilter(col.id);
      return col;
    });
    setShowColumn(tempShowColumn);
  }, [aiAllData]);

  useEffect(() => {
    if (showAiData?.length > 0) {
      const tempRows = showAiData.map((data) => {
        return createData(
          data["data_type"],
          data["question"][0],
          data["category"][0],
          data["contract"][0],
          data["contract_variation"][0],
          data["name"],
          data["sector"][0],
          data["smart_tag"][0],
          tableButtonsJsx(data)
        );
      });
      setRows(tempRows);
    }
  }, [showAiData]);

  const filterAiData = useCallback(() => {
    let tempFilteredAiData = [];
    optionsObject.forEach((option) => {
      if (option.checked) {
        const filteredData = aiAllData.filter((row) => {
          if (Array.isArray(row[option.name])) {
            const findData = row[option.name]?.find((aData) =>
              aData?.toLowerCase()?.includes(searchText?.toLowerCase())
            );

            if (findData) {
              return row;
            } else {
              return null;
            }
          } else {
            return row[option.name]
              ?.toLowerCase()
              ?.includes(searchText?.toLowerCase());
          }
        });

        filteredData?.forEach((data) => {
          const find = tempFilteredAiData.find(
            (tempData) => tempData.id === data.id
          );
          if (!find) {
            tempFilteredAiData.push(data);
          }
        });
      }
    });
    // console.log("tempFilteredAiData",tempFilteredAiData)
    setShowAiData(tempFilteredAiData);
  }, [aiAllData, searchText, optionsObject]);

  useEffect(() => {
    let debounce;
    if (searchText) {
      debounce = setTimeout(() => {
        filterAiData();
      }, 2000);
    } else {
      setShowAiData(aiAllData);
    }

    return () => clearTimeout(debounce);
  }, [searchText, aiAllData, filterAiData]);

  function createData(
    data_type,
    question,
    category,
    contract,
    contract_variation,
    name,
    sector,
    smart_tag,
    buttons
  ) {
    return {
      data_type,
      question,
      category,
      contract,
      contract_variation,
      name,
      sector,
      smart_tag,
      buttons,
    };
  }

  const setOptionSearchHandler = (e) => {
    let tempOptionObject = optionsObject.filter((obj) => {
      if (optionSearchText) {
        if (obj.label.includes(e.target.value)) {
          obj.isShown = true;
          return obj;
        } else {
          obj.isShown = false;
          return obj;
        }
      } else {
        obj.isShown = true;
        return obj;
      }
    });

    setOptionsObject(tempOptionObject);
    setOptionSearchText(e.target.value);
  };

  const optionSearchHandler = (e, id) => {
    setFilterOptionSearchText(e.target.value);
    const tempShowColumn = showColumn.filter((sCol) => {
      if (sCol.id === id) {
        sCol?.options.forEach((option) => {
          option.isShown = option.label
            ?.toLowerCase()
            ?.includes(e.target.value?.toLowerCase());
        });
        return sCol;
      } else {
        return sCol;
      }
    });
    setShowColumn(tempShowColumn);
  };

  const navigateHandler = () => {
    dispatch(aiDataActions.setAiData(caluseDataModal));
    navigate(`/AIDataCreate`);
  };

  const aiFineTuneHandler = async () => {
    const requestBody = {
      keys: {
        system: "Expert in answering legal questions and clauses",
        user: ["name", "question"],
        assistant: ["response"],
      },
      json_data: [],
    };
    try {
      const response = await aiFineTuneClause(requestBody);
      if (response.status === 200) {
        showMessage(
          `Successfully started fine tuning with id: ${response.data}`
        );
      } else if (response?.error?.response?.status === 500) {
        showMessage(`${response?.error?.response?.data}`, "error");
      } else {
        showMessage(`${response?.error?.response?.data?.detail}`, "error");
      }
      return response;
    } catch (error) {
      console.error("Error", error);
      throw error;
    }
  };

  const columnFilterToggle = (id) => {
    const tempShowColumn = showColumn.filter((col) => {
      if (col.id === id) {
        col.isFilterBoxShown = !col.isFilterBoxShown;
        col.options.forEach((option) => {
          option.isShown = true;
        });
        return col;
      } else {
        col.isFilterBoxShown = false;
        return col;
      }
    });
    setShowColumn(tempShowColumn);
    setFilterOptionSearchText("");
  };

  const dataForColumnFilter = (id) => {
    let tempArray = [];
    aiAllData?.forEach((data) => {
      if (Array.isArray(data[id])) {
        const uniqueArray = Array.from(new Set(data[id]));
        uniqueArray.forEach((item) => {
          const findData = tempArray.find((ele) => ele.label === item);
          if (!findData) {
            tempArray.push({
              label: item,
              checked: true,
              isShown: true,
            });
          }
        });
      } else {
        const findData = tempArray.find((ele) => ele.label === data[id]);
        if (!findData) {
          tempArray.push({
            label: data[id],
            checked: true,
            isShown: true,
          });
        }
      }
    });
    // console.log("tempArray", tempArray)
    return tempArray;
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.firstRow}>
        <div className={styles.firstRow_Left}>
          <div
            className={styles.button}
            onClick={() => navigate("/admin-template-management-AIAdminData")}
          >
            <span>Back</span>
          </div>
          <div
            className={styles.inputContainer}
            style={{ marginLeft: "25px", width: "400px" }}
          >
            <input
              className={styles.inputBox}
              onChange={(e) => setSearchText(e.target.value)}
              type="text"
              placeholder="Search"
            />
            <img src="/images/icons/search.png" alt="searchIcon" />
          </div>
          {isOptionSearchOptonsBox && (
            <div className={styles.searchFilterBox}>
              <div className={styles.filterBox}>
                <input
                  className={styles.filterInput}
                  name="optionSearch"
                  value={optionSearchText}
                  onChange={setOptionSearchHandler}
                  placeholder="Search Option"
                />
                <div className={styles.optionsBox}>
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        name="selectAll"
                        checked={checkedAllOptions}
                        onChange={selectSearchOptionsHandler}
                      />
                      (Select All)
                    </li>
                    {optionsObject?.map((col, index) => {
                      if (index < showColumn.length - 1 && col.isShown) {
                        return (
                          <li>
                            <input
                              type="checkbox"
                              name={col.name}
                              checked={col.checked}
                              onChange={selectSearchOptionsHandler}
                            />
                            {col.label}
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div className={styles.filterButtonRow}>
                <button onClick={submitSelectedOptionHandler}>Ok</button>
                <button onClick={cancelSelectedOptionHandler}>Cancel</button>
              </div>
            </div>
          )}

          <span className={styles.headingtext}>AI Data</span>
        </div>
        <div className={styles.fineTune_btn}>
          <Button
            className="default-btn btn"
            variant="contained"
            onClick={aiFineTuneHandler}
          >
            <span style={{ marginLeft: "20px" }}>Fine Tune</span>
          </Button>
        </div>
        <div className={styles.firstRow_Right}>
          <Button
            className="default-btn filter-btn btn"
            variant="contained"
            onClick={navigateHandler}
          >
            <AddIcon />
            <span style={{ marginLeft: "20px" }}>New</span>
          </Button>
        </div>
      </div>

      <AiDataTable
        columns={showColumn}
        rows={rows}
        columnFilterToggle={columnFilterToggle}
        selectColumnFilterDataHandler={selectColumnFilterDataHandler}
        submitSelectedColumnFilterHandler={submitSelectedColumnFilterHandler}
        filterOptionSearchText={filterOptionSearchText}
        optionSearchHandler={optionSearchHandler}
        setIsOpenSearchOptionBox={setIsOpenSearchOptionBox}
        isOptionSearchOptonsBox={isOptionSearchOptonsBox}
        // columnFilterData={columnFilterData}
        checkedAllOptions={checkedAllOptions}
        setFilterOptionSearchText={setFilterOptionSearchText}

        // setOptionSearchHandler={setOptionSearchHandler}
      />
      <Modal
        open={deleteTab}
        onClose={handleCloseDeleteTab}
        className="confirm-modal-container"
      >
        <SimpleConfirmModal
          title={strings.modals.contractList.deleteContract.confirmTextForAI}
          cancelBtn={strings.modals.contractList.deleteContract.no}
          confirmBtn={strings.modals.contractList.deleteContract.yes}
          confirmBtnClass={true}
          handleCloseModal={handleCloseDeleteTab}
          handleConfirm={deleteHandler}
        />
      </Modal>
    </div>
  );
}

export default AIData;
