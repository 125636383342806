import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getContract(id) {
    return await request('/api/contract/' + id);
}

export async function getAllContractByFolder(id) {
    return await request('/api/contract/all/folder/' + id);
}

export async function getAllContracts() {
    return await request('/api/contract/all');
}

export async function getContractByName(name) {
    return await request('/api/contract/all/' + name);
}

export async function getAllContractsAndSharedsContractByUser() {
    return await request('/api/contract/all-contracts-with-shareds');
}

export async function getContractCount() {
    return await request('/api/contract/count');
}

export async function getAllCompaniesForContract(id) {
    return await request('/api/contract/companies/' + id);
}

export async function moveContract(data) {
    return await request('/api/contract/move', data, HttpMethod.POST);
}

export async function deleteContract(id) {
    return await request('/api/contract/' + id, {}, HttpMethod.DELETE);
}

export async function addContract(data) {
    return await request('/api/contract/add', data, HttpMethod.POST);
}

export async function regenerateContract(data, id) {
    return await request('/api/contract/regenerate/' + id, data, HttpMethod.POST);
}

export async function uploadContract(data) {
    return await request(`/api/contract/upload/folder/${data.get('folderId')}`, data, HttpMethod.POST);
}

export async function uploadWordFile(data) {
    return await request('api/contract/upload/word', data, HttpMethod.POST);
}

export async function editContract(data) {
    return await request('/api/contract/edit', data, HttpMethod.PUT);
}

export async function changeContractState(id, data) {
    return await request('/api/contract/change/state/' + id, data, HttpMethod.POST);
}

export async function exportToPDF(data) {
    return await request('/api/pdf/export', data, HttpMethod.POST);
}

export async function getAllCompanies() {
    return await request('/api/company/all');
}

function transformRequestData(data) {
    return data;
}