import { Box, Button, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmModal = (props) => {
  return (
    <Box id="confirm-modal" className="confirm-modal">
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        sx={
          props?.title && {
            marginBottom: "2rem",
          }
        }
      >
        <h1
          className="confirm-title"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0rem",
            width: "90%",
          }}
        >
          {props?.title}
        </h1>
        <IconButton
          className={"cancel-btn-icon"}
          sx={{ alignSelf: "flex-end", marginLeft: "auto" }}
          onClick={props.handleCloseModalUsingCloseIcon}
        >
          <CloseIcon sx={{ color: "#283959" }} />
        </IconButton>
      </Box>
      {props.contentText && (
        <p className="confirm-text" style={props.contentTextStyle}>
          {props.contentText}
        </p>
      )}
      <Box className="confirm-buttons">
        {props.cancelBtn && (
          <Button
            className="cancel-btn btn"
            variant="contained"
            onClick={props.handleCloseModal}
            style={props.buttonsStyles}
          >
            {props.cancelBtn}
          </Button>
        )}
        <Button
          className="confirm-btn btn"
          variant="contained"
          onClick={props.handleConfirm}
          style={props.buttonsStyles}
        >
          {props.confirmBtn}
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmModal;
