import React, {useEffect, useState} from "react";

const ToggleControl = (props) => {

	const [toggleValue, setToggleValue] = useState(props.value)

	useEffect(() => {
		setToggleValue(props.value);
	}, [props.value])

	const handleToggle = (value) => {
		setToggleValue(value)
		props.setValue(props.name, value)
	}

	return (
		<div className="toggle-control-custom">
			<span className={toggleValue ? "tab active" : "tab"}
				onClick={() => handleToggle(true)}>{props.leftText}</span>
			<span className={!toggleValue ? "tab active" : "tab"}
				onClick={() => handleToggle(false)}>{props.rightText}</span>
		</div>
  	)
}

export default ToggleControl;