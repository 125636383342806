import React, { useContext, useEffect, useState } from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import strings from '../../localization';
import IconButton from '@mui/material/IconButton';
import { addFolder, deleteFolder, editFolder, getAllFolders } from "../../Services/Folder/FolderService";
import FolderForm from "../../Components/Forms/Pages/ContractList/FolderForm";
import Modal from "@mui/material/Modal";
import { isTrialPackageValid } from "../../Util/PermissionUtil";
import { useSelector } from "react-redux";
import SnackbarContext from "../../Context/SnackbarContext";
import SimpleConfirmModal from "../../Components/Modals/SimpleConfirmModal";
import { moveContract } from "../../Services/Company/CompanyService";
import { MoreVert } from "@mui/icons-material";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import TextFieldControl from "../../Components/Controls/Inputs/TextFieldControl";
import TextField from "@mui/material/TextField";
import { Draggable, Droppable } from "react-beautiful-dnd";
import CloseIcon from '@mui/icons-material/Close';

const FoldersList = ({
  contracts,
  setParentSelectedFolder,
  folders,
  fetchFolders,
  setOpenNonValidSubscription,
  contractCount,
  shareFolder,
  clearSelectedContracts
}) => {

  const user = useSelector((state) => state.auth.user)
  const { showMessage } = useContext(SnackbarContext);

  const [expanded, setExpanded] = useState(folders.map(x => x.id));
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [openFolderModal, setOpenFolderModal] = useState(false)
  const [openEditFolderModal, setOpenEditFolderModal] = useState(false)
  const [openDeleteFolderModal, setOpenDeleteFolderModal] = useState(false)
  const [optionMenuAnchorEl, setOptionMenuAnchorEl] = useState(null);
  const [menuId, setMenuId] = useState(null);
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [showAddSharedFolder, setShowAddSharedFolder] = useState(false);
  const [folderName, setFolderName] = useState('');

  useEffect(() => {
    if (selectedFolder) {
      return;
    }

    setSelectedFolder(folders.find(x => x.id === -1))

  }, [])

  useEffect(() => {
    if (selectedFolder) {
      setParentSelectedFolder(selectedFolder)
    }

  }, [selectedFolder])

  const handleCloseFolderModal = () => {
    setOpenFolderModal(false)
  }

  const handleFolderConfirm = (data) => {

    if (data.id) {

      editFolder(data).then(response => {

        if (!response || !response.ok) {
          return;
        }

        setOpenEditFolderModal(false);
        fetchFolders();
      })

      showMessage(strings.common.edited)
    } else {
      handleAddFolder(data.name, selectedFolder, false);
    }
  }

  const handleAddFolder = (name, parent, inSharedFolder = false, inMyDocumentsFolder = false) => {
    addFolder({
      name: name,
      parentId: parent ? parent.id : -1,
      inSharedFolder: inSharedFolder,
      inMyDocumentsFolder: inMyDocumentsFolder
    }).then(response => {

      if (!response || !response.ok) {
        return;
      }

      setOpenFolderModal(false);
      fetchFolders();
    })

    showMessage(strings.common.created)
  }

  const handleAddBtn = () => {

    if (!isTrialPackageValid(user, contractCount)) {
      setOpenNonValidSubscription(true);
      return;
    }

    setOpenFolderModal(!openFolderModal)
  }

  const handleEditFolder = () => {
    setOpenEditFolderModal(true)
  }

  const handleCloseEditFolderModal = () => {
    setOpenEditFolderModal(false)
  }

  const updateFolderAndSubfolders = () => {
  }

  const handleSelect = (event, ids) => {

    if (Boolean(optionMenuAnchorEl)) {
      return;
    }
    clearSelectedContracts()

    if (!ids || ids.length === 0) {
      return;
    }

    if (selectedFolder && selectedFolder.id === ids[0]) {
      setSelectedFolder(null);
      return;
    }

    setSelectedFolder(folders.find(x => x.id === ids[0]));
  };

  const handleToggle = (event, nodeIds) => {

    let className = event.target.className;

    if (className === '' || className.animVal || className?.includes('folder-name') || className?.includes('MuiTreeItem-content') || className?.includes('tree-item-wrapper') || className === 'tree-item-wrapper' || className === 'show-add' || className === 'folder-icon') {
      let id = nodeIds.find(x => expanded && !expanded.includes(x))

      let list = []
      getParents(id, list)
      setExpanded(id ? list : nodeIds);
    }
  };

  const isExpanded = (id) => {
    return expanded && expanded.includes(id);
  }

  const getParents = (id, result) => {

    let folder = folders.find(x => x.id === id)

    if (folder) {
      result.push(id)
    }

    if (folder && folder.parent) {
      return getParents(folder.parent.id, result)
    }

    return result
  }

  const handleMenuOpen = (event, menuId, folder) => {
    event.stopPropagation();

    setOptionMenuAnchorEl(event.currentTarget);
    setMenuId(menuId);
    setSelectedFolder(folder)
  };

  const handleMenuClose = () => {
    setOptionMenuAnchorEl(null);
    setMenuId(null);
  };

  const renderFoldersTree = (folders) => {

    let result = [];

    for (let folder of folders) {
      let rowId = 'action-menu-' + folder.id;

      if (folder.parent || folder.inSharedFolder) {
        continue;
      }

      result.push(
        <TreeItem
          className={folder.id === -2 ? 'shared-with-me-folder' : ''}
          nodeId={folder.id}
          label={
            <Droppable droppableId={'folder-droppable-' + folder.id} direction="horizontal" >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ width: '100%' }}
                  className={snapshot.isDraggingOver && (snapshot.draggingOverWith !== snapshot.draggingFromThisWith) ? 'is-over' : ''}
                >
                  <Draggable
                    key={'folder-' + folder.id}
                    draggableId={'folder-' + folder.id}
                    index={folder.id}
                  >
                    {(provided, snapshot) => (

                      <div
                        className={(folder.id === -1 || folder.id === -2) ? 'tree-item-wrapper main-folders' : 'tree-item-wrapper'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <img
                          src={isExpanded(folder.id) ? 'images/icons/open-folder.png' : 'images/icons/folder-new.png'}
                          className='folder-icon' />
                        <span className={folder.showAdd ? 'show-add' : ''}>
                          <span className='folder-name'>{folder.name}</span>
                        </span>
                        <div className={'more-container'}>
                          {
                            (folder.id !== -1 && folder.id !== -2) &&
                            <IconButton
                              className={'tree-icon-button'}
                              aria-haspopup="true"
                              onClick={(event) => handleMenuOpen(event, rowId, folder)}
                            >
                              <MoreVert />
                            </IconButton>
                          }
                          {
                            selectedFolder && menuId === rowId &&
                            <Menu
                              id='folder-context-menu'
                              anchorEl={optionMenuAnchorEl}
                              open={Boolean(optionMenuAnchorEl)}
                              onClose={() => handleMenuClose()}
                            >
                              <MenuItem onClick={() => {
                                handleAddBtn();
                                setOptionMenuAnchorEl(null)
                              }}>
                                <ListItemIcon>
                                  <img src={'images/icons/menu/Add.png'} />
                                </ListItemIcon>
                                <ListItemText inset primary={'Add sub-folder'} />
                              </MenuItem>
                              {
                                (selectedFolder && (selectedFolder.id !== -1 && selectedFolder.id !== -2)) &&
                                <React.Fragment>
                                  <MenuItem onClick={() => {
                                    handleShareFolder();
                                    setOptionMenuAnchorEl(null)
                                  }}>
                                    <ListItemIcon>
                                      <img src={'images/icons/menu/Share.png'} />
                                    </ListItemIcon>
                                    <ListItemText inset primary={'Share'} />
                                  </MenuItem>
                                  <MenuItem onClick={() => {
                                    handleEditFolder();
                                    setOptionMenuAnchorEl(null)
                                  }}>
                                    <ListItemIcon>
                                      <img src={'images/icons/menu/Edit.png'} />
                                    </ListItemIcon>
                                    <ListItemText inset primary={'Rename'} />
                                  </MenuItem>
                                  <MenuItem onClick={() => {
                                    handleDeleteFolder();
                                    setOptionMenuAnchorEl(null)
                                  }}>
                                    <ListItemIcon>
                                      <img src={'images/icons/menu/Delete.png'} />
                                    </ListItemIcon>
                                    <ListItemText inset primary={'Delete'} />
                                  </MenuItem>
                                </React.Fragment>
                              }
                            </Menu>
                          }
                        </div>
                        {
                          folder.showAdd &&
                          <Button className={'btn neutral-btn folder-add-button'}
                            onClick={() => {

                              if (folder.id === -1) {
                                setShowAddFolder(true);
                                setShowAddSharedFolder(false)
                              }

                              if (folder.id === -2) {
                                setShowAddFolder(false);
                                setShowAddSharedFolder(true)
                              }

                            }
                            }>
                            +
                          </Button>
                        }
                      </div>
                    )}
                  </Draggable>
                </div>
              )}
            </Droppable>

          }
        >
          {renderSubFoldersTree(folder, folders)}
        </TreeItem>
      )

      if ((folder.id === -1 && showAddFolder) || (folder.id === -2 && showAddSharedFolder)) {
        result.push(<TreeItem
          // nodeId={folder.id}
          className={'add-folder-container'}
          label={
            <React.Fragment>
              <img src={'images/icons/folder-new.png'} className='folder-icon' />
              <TextField
                placeholder={'Folder name'}
                defaultValue={folderName}
                value={folderName}
                className={'textfield-control'}
                onChange={(e) => {
                  setFolderName(e.target.value)
                }}
                onKeyDown={(e) => {

                  if (e.key === 'Escape') {
                    setShowAddFolder(false)
                    setShowAddSharedFolder(false)
                    setFolderName('')
                    return
                  }

                  if (e.key === 'Enter' && folderName) {
                    handleAddFolder(folderName, null, showAddSharedFolder, showAddFolder)
                    setShowAddFolder(false)
                    setShowAddSharedFolder(false)
                    setFolderName('')
                  }
                }}
              />
              <IconButton onClick={() => {
                setShowAddFolder(false)
                setShowAddSharedFolder(false)
                setFolderName('')
              }
              }>
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        >
        </TreeItem>)
      }
    }

    return result;
  }

  const renderSubFoldersTree = (parent, folders) => {

    let result = [];

    for (let folder of folders) {
      let rowId = 'action-menu-' + folder.id;

      if ((parent && folder.parent && folder.parent.id === parent.id) ||
        (parent.id === -2 && folder.inSharedFolder)) {
        result.push(
          <TreeItem
            nodeId={folder.id}
            className={folder?.inMyDocumentsFolder ? 'ml-15' : ''}
            label={
              <Droppable droppableId={'folder-droppable-' + folder.id} direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ width: '100%' }}
                    className={snapshot.isDraggingOver && (snapshot.draggingOverWith !== snapshot.draggingFromThisWith) ? 'is-over' : ''}
                  >
                    <Draggable
                      className={'tree-item-wrapper'}
                      key={'folder-' + folder.id}
                      draggableId={'folder-' + folder.id}
                      index={folder.id}
                    >
                      {(provided, snapshot) => (
                        <div className={'tree-item-wrapper'}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <img
                            src={isExpanded(folder.id) ? 'images/icons/open-folder.png' : 'images/icons/folder-new.png'}
                            className='folder-icon' />
                          <span className='folder-name'>{folder.name}</span>
                          <div className={'more-container'}>
                            <IconButton
                              className={'tree-icon-button'}
                              aria-haspopup="true"
                              onClick={(event) => handleMenuOpen(event, rowId, folder)}
                            >
                              <MoreVert />
                            </IconButton>
                            {
                              selectedFolder && menuId === rowId &&
                              <Menu
                                id='folder-context-menu'
                                anchorEl={optionMenuAnchorEl}
                                open={Boolean(optionMenuAnchorEl)}
                                onClose={() => handleMenuClose()}
                              >
                                <MenuItem onClick={() => {
                                  handleAddBtn();
                                  setOptionMenuAnchorEl(null)
                                }}>
                                  <ListItemIcon>
                                    <img src={'images/icons/menu/Add.png'} />
                                  </ListItemIcon>
                                  <ListItemText inset primary={'Add'} />
                                </MenuItem>
                                {
                                  (selectedFolder && (selectedFolder.id !== -1 && selectedFolder.id !== -2)) &&
                                  <React.Fragment>
                                    <MenuItem onClick={() => {
                                      handleShareFolder();
                                      setOptionMenuAnchorEl(null)
                                    }}>
                                      <ListItemIcon>
                                        <img
                                          src={'images/icons/menu/Share.png'} />
                                      </ListItemIcon>
                                      <ListItemText inset primary={'Share'} />
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                      handleEditFolder();
                                      setOptionMenuAnchorEl(null)
                                    }}>
                                      <ListItemIcon>
                                        <img
                                          src={'images/icons/menu/Edit.png'} />
                                      </ListItemIcon>
                                      <ListItemText inset primary={'Rename'} />
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                      handleDeleteFolder();
                                      setOptionMenuAnchorEl(null)
                                    }}>
                                      <ListItemIcon>
                                        <img
                                          src={'images/icons/menu/Delete.png'} />
                                      </ListItemIcon>
                                      <ListItemText inset primary={'Delete'} />
                                    </MenuItem>
                                  </React.Fragment>
                                }
                              </Menu>
                            }
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </div>
                )}
              </Droppable>
            }
          >
            {renderSubFoldersTree(folder, folders)}
          </TreeItem>
        )
      }
    }

    return result;
  }

  const renderFolders = () => {

    return <TreeView
      className="tree-component"
      defaultCollapseIcon={
        <div className="icons-container">
          <KeyboardArrowDownIcon className="arrow-icon arrow-down-icon" />
        </div>
      }
      defaultExpandIcon={
        <div className="icons-container">
          <ArrowForwardIosIcon className="arrow-icon arrow-right-icon" />
        </div>
      }
      selected={[selectedFolder ? selectedFolder.id : null]}
      expanded={expanded}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
      multiSelect
    >
      {renderFoldersTree(folders)}
    </TreeView>


  }

  const handleDeleteFolder = () => {
    setOpenDeleteFolderModal(true)
  }

  const handleCloseDeleteFolderModal = () => {
    setOpenDeleteFolderModal(false)
  }

  const handleDeleteFolderConfirm = () => {

    if (contracts.length > 0) {
      for (let contract of contracts) {
        moveContract({
          folderId: -1,
          contractId: contract.id
        }).then(response => {
        })
      }
    }

    deleteFolder(selectedFolder.id).then(response => {

      if (!response || !response.ok) {
        return;
      }

      setOpenDeleteFolderModal(false)
      fetchFolders()
      showMessage(strings.common.deleted)
    })
  }

  const handleShareFolder = () => {
    shareFolder()
  }

  return (
    <div className="folders-list-container">

      <div className="title-container">
        <h3>Folders</h3>
        <div className={'folder-divider'}></div>

      </div>

      <div className="folders-list">
        {folders.length > 0 &&
          renderFolders()
        }
      </div>

      <Modal
        open={openFolderModal}
        onClose={handleCloseFolderModal}
        className='confirm-modal-container'
      >
        <FolderForm
          handleCloseModal={handleCloseFolderModal}
          handleConfirm={handleFolderConfirm}
          folder={null}
          allFolders={folders}
        />
      </Modal>

      <Modal
        open={openEditFolderModal}
        onClose={handleCloseEditFolderModal}
        className='confirm-modal-container'
      >
        <FolderForm
          handleCloseModal={handleCloseEditFolderModal}
          handleConfirm={handleFolderConfirm}
          folder={selectedFolder}
          allFolders={folders}
        />
      </Modal>

      <Modal
        open={openDeleteFolderModal}
        onClose={handleCloseDeleteFolderModal}
        className='confirm-modal-container'
      >
        <SimpleConfirmModal
          title={strings.modals.contractList.deleteFolder.confirmText}
          cancelBtn={strings.modals.contractList.deleteFolder.no}
          confirmBtn={strings.modals.contractList.deleteFolder.yes}
          confirmBtnClass={true}
          handleCloseModal={handleCloseDeleteFolderModal}
          handleConfirm={handleDeleteFolderConfirm}
        />
      </Modal>
    </div>
  )
}

export default FoldersList;