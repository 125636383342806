import React, { useContext, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import AIDataCreateViewComponent from './AIDataCreateViewComponent'
import { Collapse, Modal } from "@mui/material";
import SimpleConfirmModal from "./../../../Components/Modals/SimpleConfirmModal";
import strings from "../../../localization";
import { clauseDeleteById, getAllAIData } from "../../../Services/AI/AIConversationService";
import SnackbarContext from '../../../Context/SnackbarContext';
import { useDispatch } from "react-redux";
import { aiDataActions } from "../../../Slices/AiDataSlice";

function ExpandableTableViewComponent({ row }) {
    const dispatch = useDispatch()

    const { showMessage } = useContext(SnackbarContext);

    const [deleteTab, setDeleteTab] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const handleDeleteTab = (id) => {
        setDeleteId(id);
        setDeleteTab(true);
    };

    const handleCloseDeleteTab = () => {
        setDeleteId(null);
        setDeleteTab(false);
    };


    const deleteHandler = () => {
        if (deleteId !== null) {
            clauseDeleteById(deleteId)
                .then((res1) => {
                    getAllAIData()
                        .then((res) => {
                            dispatch(aiDataActions.setAiAllData(res.data))
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    setDeleteTab(false);
                    showMessage(strings.common.deleted);
                    // navigate(`/AIData`)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };


    return (
        <TableRow className="expandable-row">
            <TableCell
                colSpan={12}
                className="expandable-row-cell"
            >
                <Collapse
                    in={row.isOpen}
                    timeout="auto"
                    unmountOnExit
                    className="expandable-row-collapse"
                    id="finddiv"
                >
                    <div style={{ display: "flex", justifyContent: 'center', backgroundColor: "#C2CDDF" }}>
                        <AIDataCreateViewComponent
                            aiDataRow={row?.data}

                            handleDeleteTab={handleDeleteTab}
                        />
                    </div>
                    <Modal
                        open={deleteTab}
                        onClose={handleCloseDeleteTab}
                        className="confirm-modal-container"
                    >
                        <SimpleConfirmModal
                            title={strings.modals.contractList.deleteContract.confirmTextForAI}
                            cancelBtn={strings.modals.contractList.deleteContract.no}
                            confirmBtn={strings.modals.contractList.deleteContract.yes}
                            confirmBtnClass={true}
                            handleCloseModal={handleCloseDeleteTab}
                            handleConfirm={deleteHandler}
                        />
                    </Modal>
                </Collapse>
            </TableCell>
        </TableRow>
    )
}

export default ExpandableTableViewComponent