import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../../../../Pages/dealRoom/DealRoom.module.css";
import { getSectors } from "../../../../../Constants/User/Sector";
import { getCompanyTypes } from "../../../../../Constants/User/CompanyType";
import { companyHouseData } from "../../../../../Constants/Admin/DealRoomModel";

const UpdateDealSummaryForm = ({
  formData,
  handleCloseModal,
  updateDealSummaryDataHandle,
  setOpenEditDealSummaryModal,
}) => {
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);

  const [isOpenChoiceBox, setisOpenChoiceBox] = useState({});

  const [dealSummaryData, setDealSummaryData] = useState({
    salePrice: formData?.salePrice,
    targetCompletionDate: formData?.targetCompletionDate,
    expiryDate: formData?.expiryDate,
    buyerCompany: {
      address: formData?.buyerCompany?.address,
      name: formData?.buyerCompany?.name,
      sector: formData?.buyerCompany?.sector,
      type: formData?.buyerCompany?.type,
    },
  });
  const [companiesList, setCompaniesList] = useState([]);
  const [sectorListJSX, setSectorListJSX] = useState([]);

  const [showCompanyHouseListBox, setShowCompanyListHouseBox] = useState(false);
  const [companySearchText, setCompanySearchText] = useState("");

  const handleMouseLeave = () => {
    inputRef?.current?.blur();
    inputRef1?.current?.blur();
    setisOpenChoiceBox({ ...isOpenChoiceBox, type: false, sector: false });
  };

  const setCompanyDetailsHandler = (e) => {
    let { name, value } = e.target;

    if (name === "salePrice") {
      value = parseInt(value);
    }

    setDealSummaryData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const selectChoiceHandler = (name, value) => {
    const { buyerCompany } = dealSummaryData;
    setDealSummaryData((prevState) => ({
      ...prevState,
      buyerCompany: {
        ...buyerCompany,
        [name]: value,
      },
    }));
  };

  const setBuyerCompanyDetailsHandler = (e) => {
    const { name, value } = e.target;
    const { buyerCompany } = dealSummaryData;

    if (name === "name") {
      setShowCompanyListHouseBox(true);
      setCompanySearchText(value);
    }

    setDealSummaryData((prevState) => ({
      ...prevState,
      buyerCompany: {
        ...buyerCompany,
        [name]: value,
      },
    }));
  };

  const companyHouseSearch = (term) => {
    fetch(
      "https://api.company-information.service.gov.uk/search/companies?q=" +
        term,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "76e6bfbb-e044-4b6b-b5f5-fd49072b21bd",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setCompaniesList(data ? data.items : []);
      });
  };

  const selectCompanyHandler = (item) => {
    const { buyerCompany } = dealSummaryData;

    setDealSummaryData((prevState) => ({
      ...prevState,
      buyerCompany: {
        ...buyerCompany,
        ["name"]: item.title,
        ["address"]: item.address_snippet,
      },
    }));
  };

  useEffect(() => {
    if (companySearchText) {
      companyHouseSearch(companySearchText);
    }
  }, [companySearchText]);

  const handleSubmitBtnClick = () => {
    updateDealSummaryDataHandle(dealSummaryData);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <Box
      id="confirm-modal"
      className="confirm-modal"
      sx={{
        borderRadius: "1rem",
        alignItems: "center",
        width: "600px !important",
        overflow: "auto",
        height: "95vh",
      }}
    >
      <Box className={styles.dealRoom} width={"100%"}>
        <Box className={styles.newTeamFormHeading}>
          <Box className={styles.newTeamFormHeadingBox}>
            <Typography variant="h5" sx={{ textDecoration: "underline" }}>
              Update Deal Summary
            </Typography>
          </Box>
          <Box className={styles.newTeamFormButtonBox}>
            <Box
              className={styles.closeButton}
              style={{ alignSelf: "flex-end" }}
              onClick={() => setOpenEditDealSummaryModal(false)}
            >
              <CloseIcon sx={{ color: "#FFF" }} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={styles.formRow} sx={{ width: "90%" }}>
        <Box className={styles.filledBox}>
          <Box className={styles.labelText}>
            <Typography>Company Name</Typography>
          </Box>
          <Box
            className={styles.inputContainer}
            sx={{
              padding: "4px 16px !important",
              position: "relative",
            }}
          >
            <input
              className={styles.inputBox}
              value={dealSummaryData?.buyerCompany?.name}
              name="name"
              onChange={setBuyerCompanyDetailsHandler}
              type="text"
              placeholder="Company Name"
            />
            {showCompanyHouseListBox && (
              <div className={styles.options}>
                <ul onMouseLeave={() => setShowCompanyListHouseBox(false)}>
                  {companiesList.map((item, index) => {
                    return (
                      <li id={index} onClick={() => selectCompanyHandler(item)}>
                        {item.title}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </Box>
        </Box>
      </Box>

      <Box className={styles.formRow} sx={{ width: "90%" }}>
        <div className={styles.filledBox} onMouseLeave={handleMouseLeave}>
          <div className={styles.labelText}>
            <span className="required">Company Sector</span>
          </div>
          <div
            className={styles.inputContainer}
            style={{ position: "relative" }}
          >
            <input
              readOnly
              ref={inputRef}
              value={dealSummaryData?.buyerCompany?.type || ""}
              name="type"
              // onChange={setBuyerCompanyDetailsHandler}
              className={styles.inputBox}
              type="text"
              onFocus={() =>
                setisOpenChoiceBox({ ...isOpenChoiceBox, type: true })
              }
              placeholder="Please Select Company Sector"
              style={{
                WebkitAppearance: "none",
                background: `url(/images/icons/downArrow.png)`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right center",
              }}
            />

            {isOpenChoiceBox?.type && (
              <div className={styles.options}>
                <ul onMouseLeave={handleMouseLeave}>
                  {getCompanyTypes().map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => selectChoiceHandler("type", item.name)}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </Box>

      <Box className={styles.formRow} sx={{ width: "90%" }}>
        <Box className={styles.filledBox}>
          <Box className={styles.labelText}>
            <Typography>Company Address</Typography>
          </Box>
          <Box
            className={styles.inputContainer}
            sx={{ padding: "4px 16px !important" }}
          >
            <input
              className={styles.inputBox}
              value={dealSummaryData?.buyerCompany?.address}
              name="address"
              onChange={setBuyerCompanyDetailsHandler}
              type="text"
              placeholder="Company Address"
            />
          </Box>
        </Box>
      </Box>

      <Box className={styles.formRow} sx={{ width: "90%" }}>
        <Box className={styles.filledBox} onMouseLeave={handleMouseLeave}>
          <Box className={styles.labelText}>
            <Typography>Company Sector</Typography>
          </Box>
          <Box
            className={styles.inputContainer}
            sx={{
              padding: "4px 16px !important",
              position: "relative",
            }}
          >
            <input
              readOnly
              ref={inputRef1}
              value={dealSummaryData?.buyerCompany?.sector || ""}
              name="sector"
              // onChange={setBuyerCompanyDetailsHandler}
              className={styles.inputBox}
              onFocus={() =>
                setisOpenChoiceBox({ ...isOpenChoiceBox, sector: true })
              }
              placeholder="Please Select Sector"
              style={{
                WebkitAppearance: "none",
                background: `url(/images/icons/downArrow.png)`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right center",
              }}
            />

            {isOpenChoiceBox?.sector && (
              <div className={styles.options}>
                <ul onMouseLeave={handleMouseLeave}>
                  {getSectors().map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => selectChoiceHandler("sector", item.name)}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </Box>
        </Box>
      </Box>

      <Box className={styles.formRow} sx={{ width: "90%" }}>
        <Box className={styles.filledBox}>
          <Box className={styles.labelText}>
            <Typography>Expiry Date</Typography>
          </Box>
          <Box
            className={styles.inputContainer}
            sx={{ padding: "4px 16px !important" }}
          >
            <input
              className={styles.inputBox}
              value={
                dealSummaryData?.expiryDate
                  ? formatDate(dealSummaryData.expiryDate.split("T")[0])
                  : ""
              }
              name="expiryDate"
              type="text"
              placeholder="Enter Expiry Date"
              min={new Date().toISOString().split("T")[0]}
            />
            <input
              style={{
                width: "1.5rem",
                border: "none",
                WebkitAppearance: "none",
                height: "1.5rem",
                backgroundColor: "transparent",
                fontSize: "1.25rem",
                cursor: "pointer",
              }}
              value={
                dealSummaryData?.expiryDate
                  ? formatDate(dealSummaryData.expiryDate.split("T")[0])
                  : ""
              }
              name="expiryDate"
              onChange={setCompanyDetailsHandler}
              type="date"
              placeholder=""
              min={new Date().toISOString().split("T")[0]}
            />
          </Box>
        </Box>
      </Box>

      <Box className={styles.formRow} sx={{ width: "90%" }}>
        <Box className={styles.filledBox}>
          <Box className={styles.labelText}>
            <Typography>Target Completion Date</Typography>
          </Box>
          <Box
            className={styles.inputContainer}
            sx={{ padding: "4px 16px !important" }}
          >
            <input
              className={styles.inputBox}
              value={
                dealSummaryData?.targetCompletionDate
                  ? formatDate(
                      dealSummaryData.targetCompletionDate.split("T")[0]
                    )
                  : ""
              }
              name="targetCompletionDate"
              onChange={setCompanyDetailsHandler}
              type="text"
              placeholder="Target Completion Date"
              min={new Date().toISOString().split("T")[0]}
            />
            <input
              style={{
                width: "1.5rem",
                border: "none",
                WebkitAppearance: "none",
                height: "1.5rem",
                backgroundColor: "transparent",
                fontSize: "1.25rem",
                cursor: "pointer",
              }}
              value={
                dealSummaryData?.targetCompletionDate
                  ? formatDate(
                      dealSummaryData.targetCompletionDate.split("T")[0]
                    )
                  : ""
              }
              name="targetCompletionDate"
              onChange={setCompanyDetailsHandler}
              type="date"
              placeholder="Target Completion Date"
              min={new Date().toISOString().split("T")[0]}
            />
          </Box>
        </Box>
      </Box>

      <Box className={styles.formRow} sx={{ width: "90%" }}>
        <Box className={styles.filledBox}>
          <Box className={styles.labelText}>
            <Typography>Sale Price</Typography>
          </Box>
          <Box
            className={styles.inputContainer}
            sx={{ padding: "4px 16px !important" }}
          >
            <input
              className={styles.inputBox}
              value={dealSummaryData?.salePrice}
              name="salePrice"
              onChange={setCompanyDetailsHandler}
              type="number"
              placeholder="Sale Price"
            />
          </Box>
        </Box>
      </Box>

      <Box mt={2} width={"100%"} display={"flex"} justifyContent={"center"}>
        <Button
          className="neutral-btn"
          sx={{ marginRight: "1rem" }}
          onClick={() => {
            handleCloseModal();
          }}
        >
          Cancel
        </Button>
        <Button className="default-btn" onClick={() => handleSubmitBtnClick()}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default UpdateDealSummaryForm;
