export function getJurisdiction() {
    return [
        {
            id: 'England & Wales',
            name: 'England & Wales'
        },
        {
            id: 'Scotland',
            name: 'Scotland'
        },
        {
            id: 'Other',
            name: 'Other'
        },
    ]
}