import {
  Box
} from "@mui/material";
import React, { useEffect, useState } from "react";


import { useParams } from "react-router-dom";
import {getDealRoomFolders} from "../../../../Services/DealRoom/DataRoomService";
import LeftSideBar from "./LeftSideBar";
import RightSideBar from "./RightSideBar";

const DataRoom = ({setActiveTab}) => {


  const [mainFoldersData, setMainFoldersData] = useState({});
 
  const params = useParams();

  const handleGetDataRoom = () => {
    getDealRoomFolders(params?.id)
      .then((res) => {
        if (res?.data) {
          setMainFoldersData(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    handleGetDataRoom();
  }, []);

  // useEffect(() => {
  //   makeDealroomSubscription(params?.id, {
  //     card: "5555555555554444",
  //     expMonth: 1,
  //     expYear: "27",
  //     cvc: "123",
  //     interval: 1,
  //   });
  // }, [])

 


  return (
    <Box
      className="overview-wrapper"
      display="flex"
      justifyContent="space-between"
    >
      <LeftSideBar
        mainFoldersData={mainFoldersData}
        setActiveTab={setActiveTab}

      />
      <RightSideBar/>


    </Box>
  );
};
export default DataRoom;
