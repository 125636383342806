import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import { Box, Typography } from "@mui/material";
import Overview from "./Components/Overview";
import DueDiligence from "./DueDeligence/DueDiligence";
import ConnectTab from "./ConnectTab/ConnectTab";
import CapTable from "./Components/CapTable/CapTable";
import { getDealRoomData } from "../../../Services/DealRoom/DealRoomService";
import DataRoom from "./DataRoom/DataRoom";
import MenuState from "../../../Constants/Base/MenuState";
import HeaderButtons from "../../../Components/Layout/HeaderButtons";
import { useSelector } from "react-redux";

const DealRoomDashboard = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const menuState = useSelector((state) => state.navigation.menuState);

  const { projectName, companyName } = location?.state !== null && location?.state;
  const [companyNameHeader, setCompanyNameHeader] = useState(companyName);
  const [projectNameHeader, setProjectNameHeader] = useState(projectName);
  const [activeTab, setActiveTab] = useState(params.tab || "overview");

  useEffect(() => {
    if (!companyNameHeader || !projectNameHeader) {
      getDealRoomData(params.id)
        .then((res) => {
          if (res.status === 200) {
            setProjectNameHeader(res.data?.name);
            setCompanyNameHeader(res.data?.targetCompany.name);
          }
        })
        .catch((err) => {
          console.log("error in dashboard getDealRoomData", err);
        });
    }
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`/virtualDealRoom/dashboard/${tab}/${params.id}`);
  };

  const getHeaderClass = (menuState) => {
    if (menuState === MenuState.SHORT) {
      return "short";
    }

    return "";
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="dealroom-parent-wrapper"
    >

      <div id="header" className={getHeaderClass(menuState)}>
        <div>
          <Box display={"flex"} alignItems={"center"} mt={2} p={2}>
            <WestIcon
              onClick={() => navigate("/virtualDealRoom")}
              sx={{ cursor: "pointer", fontSize: "2rem" }}
            />
            <Typography variant="h5" fontWeight={500} ml={2}>
              {projectNameHeader} - {companyNameHeader}
            </Typography>
          </Box></div>
        <HeaderButtons />
      </div>


      <Box display={"flex"} alignItems="center" p={2}>
        <Box display={"flex"} justifyContent="space-between" width="70%">
          <Typography
            onClick={() => {
              handleTabChange("overview");
            }}
            variant="h5"
            className="dealroom-tab-items"
            sx={{
              color: "rgba(28, 28, 28, 0.5)",
              ...(activeTab === "overview" && {
                color: "#3D32CF",
                borderBottom: "0.3rem #3D32CF solid",
                fontWeight: "700",
              })
            }}
          >
            Overview
          </Typography>
          <Typography
            onClick={() => {
              handleTabChange("due-deligence");
            }}
            className="dealroom-tab-items"
            variant="h5"
            sx={{
              color: "rgba(28, 28, 28, 0.5)",
              ...(activeTab === "due-deligence" && {
                color: "#3D32CF",
                borderBottom: "0.3rem #3D32CF solid",
                fontWeight: "700",
              })
            }}
          >
            Due Diligence
          </Typography>
          <Typography
            onClick={() => {
              handleTabChange("data-room");
            }}
            variant="h5"
            className="dealroom-tab-items"
            sx={{
              color: "rgba(28, 28, 28, 0.5)",
              ...(activeTab === "data-room" && {
                color: "#3D32CF",
                borderBottom: "0.3rem #3D32CF solid",
                fontWeight: "700",
              })
            }}
          >
            Data Room
          </Typography>
          <Typography
            onClick={() => {
              handleTabChange("cap-table");
            }}
            variant="h5"
            className="dealroom-tab-items"
            sx={{
              color: "rgba(28, 28, 28, 0.5)",
              ...(activeTab === "cap-table" && {
                color: "#3D32CF",
                borderBottom: "0.3rem #3D32CF solid",
                fontWeight: "700",
              })
            }}
          >
            Cap Table
          </Typography>
          <Typography
            onClick={() => {
              handleTabChange("connectTab");
            }}
            variant="h5"
            className="dealroom-tab-items"
            sx={{
              color: "rgba(28, 28, 28, 0.5)",
              ...(activeTab === "connectTab" && {
                color: "#3D32CF",
                borderBottom: "0.3rem #3D32CF solid",
                fontWeight: "700",
              })
            }}
          >
            Connect
          </Typography>
        </Box>
      </Box>
      {activeTab === "overview" && <Overview />}
      {activeTab === "due-deligence" && <DueDiligence />}
      {activeTab === "connectTab" && <ConnectTab />}
      {activeTab === "data-room" && <DataRoom setActiveTab={setActiveTab} />}
      {activeTab === "cap-table" && <CapTable />}
    </Box>
  );
};

export default DealRoomDashboard;
