export const caluseDataModal = {
    "name": "",
    "category": [],
    "contract": [],
    "contract_variation": [],
    "sector": [],
    "smart_tag": [],
    "data_type": "Question",
    "question": [],
    "clause": [],
    "response": "",
    "response_question":"",
    "linked_clauses":{},
}


const minWidth = 80;

export const AIDatacolumns = [
    {
      id: "data_type",
      label: "DATA",
      minWidth: minWidth,
      fontWeight: "900",
      isFilterBoxShown: false,
      isSelectAll: true,
      options: [],
    },
    {
      id: "question",
      label: "Question",
      align: "center",
      minWidth: minWidth,
      fontWeight: "900",
      isFilterBoxShown: false,
      isSelectAll: true,
      options: [],
    },
    {
      id: "category",
      label: "Category",
      minWidth: minWidth,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontWeight: "900",
      isFilterBoxShown: false,
      isSelectAll: true,
      options: [],
    },
    {
      id: "contract",
      label: "Contract",
      minWidth: minWidth,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontWeight: "900",
      isFilterBoxShown: false,
      isSelectAll: true,
      options: [],
    },
    {
      id: "contract_variation",
      label: "Contract.Variation",
      minWidth: minWidth,
      align: "center",
      format: (value) => value.toFixed(2),
      fontWeight: "900",
      isFilterBoxShown: false,
      isSelectAll: true,
      options: [],
    },
    {
      id: "name",
      label: "Clause Name",
      minWidth: minWidth,
      align: "center",
      format: (value) => value.toFixed(2),
      fontWeight: "900",
      isFilterBoxShown: false,
      isSelectAll: true,
      options: [],
    },
    {
      id: "sector",
      label: "Sector",
      minWidth: minWidth,
      align: "center",
      format: (value) => value.toFixed(2),
      fontWeight: "900",
      isFilterBoxShown: false,
      isSelectAll: true,
      options: [],
    },
    {
      id: "smart_tag",
      label: "Smart Tags",
      minWidth: minWidth,
      align: "center",
      format: (value) => value.toFixed(2),
      fontWeight: "900",
      isFilterBoxShown: false,
      isSelectAll: true,
      options: [],
    },
    {
      id: "buttons",
      label: "",
      minWidth: minWidth - 30,
      align: "center",
      format: (value) => value.toFixed(2),
    },
  ];