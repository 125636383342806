import React, { useRef, useState } from 'react';
import styles from "../../../../../Pages/dealRoom/DealRoom.module.css";


const BusinessInfoForm = ({ setFormName, formData, handleChange, setErrors, errors }) => {

    const inputRef = useRef(null);
    const [isOpenChoiceBox, setisOpenChoiceBox] = useState({});


    const optionsOfBusinessIs = [
        { id: 1, name: "Limited company" },
        { id: 2, name: "Limited liability partnership" },
        { id: 3, name: "Partnership" },
        { id: 4, name: "Sole trader" },
        { id: 5, name: "PLC" },
        { id: 6, name: "Start-up" },
    ]

    const handleMouseLeave = () => {
        inputRef?.current?.blur();

        setisOpenChoiceBox({ ...isOpenChoiceBox, "type": false })
        // setisOpenChoiceBox({ ...isOpenChoiceBox, sector: false })
    };
    const handleContinue = () => {
        const { name, type, annualTurnover, industry } = formData.businessDetails ?? {};
        const newErrors = {};

        if (!name) {
            newErrors.businessDetails = { ...newErrors.businessDetails, name: "Business Name is required" };
        }
        if (!type) {
            newErrors.businessDetails = { ...newErrors.businessDetails, type: "Business type is required" };
        }
        if (!annualTurnover) {
            newErrors.businessDetails = { ...newErrors.businessDetails, annualTurnover: "Annual Turnover is required" };
        }
        if (!industry) {
            newErrors.businessDetails = { ...newErrors.businessDetails, industry: "Business industry is required" };
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setFormName("borrowForm");
    };

    return (
        <div className={styles.dealRoom}>
            <div className={styles.filledBox} >
                <div className={styles.labelText}>
                    <span>Your business name*</span>
                </div>
                <div className={styles.inputContainer} >
                    <input
                        className={styles.inputBox}
                        name="businessDetails.name"
                        value={formData?.businessDetails?.name}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter Name"
                    />
                </div>
                {errors?.businessDetails?.name && <span className={styles.errorMessage}>{errors?.businessDetails?.name}</span>}
            </div>
            <div className={styles.filledBox} onMouseLeave={handleMouseLeave}>
                <div className={styles.labelText}>
                    <span>Your business is a*</span>
                </div>
                <div className={styles.inputContainer}
                    style={{ position: "relative" }}
                >
                    <input
                        readOnly
                        ref={inputRef}
                        onFocus={() => setisOpenChoiceBox({ ...isOpenChoiceBox, 'type': true })}
                        name="businessDetails.type"
                        value={formData?.businessDetails?.type}
                        // onChange={handleChange}
                        className={styles.inputBox}
                        placeholder='Please Select Business Type'
                        type="text"
                        style={{
                            WebkitAppearance: "none",
                            background: `url(/images/icons/downArrow.png)`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "right center",
                        }}
                    />
                    {isOpenChoiceBox?.type && (
                        <div className={styles.options}>
                            <ul onMouseLeave={handleMouseLeave}>
                                {optionsOfBusinessIs?.map((item, index) => {
                                    return (
                                        <li key={index} onClick={() => handleChange({ target: { name: "businessDetails.type", value: item.name } })}>
                                            {item.name}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    {/* {optionsOfBusinessIs.map((option) => {
                            return (
                                <option key={option.id} value={option.name}>{option.name}</option>
                            )
                        })} */}


                </div>
                {errors?.businessDetails?.type && <span className={styles.errorMessage}>{errors?.businessDetails?.type}</span>}
            </div>
            <div className={styles.filledBox} >
                <div className={styles.labelText}>
                    <span>Your business annual turnover*</span>
                </div>
                <div className={styles.inputContainer} >
                    <input
                        className={styles.inputBox}
                        name="businessDetails.annualTurnover"
                        value={formData?.businessDetails?.annualTurnover}
                        onChange={handleChange}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="Enter number"
                    />
                </div>
                {errors?.businessDetails?.annualTurnover && <span className={styles.errorMessage}>{errors?.businessDetails?.annualTurnover}</span>}
            </div>
            <div className={styles.filledBox} >
                <div className={styles.labelText}>
                    <span>Your business industry*</span>
                </div>
                <div className={styles.inputContainer} >
                    <input
                        className={styles.inputBox}
                        name="businessDetails.industry"
                        value={formData?.businessDetails?.industry}
                        onChange={handleChange}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="Enter Value"
                    />
                </div>
                {errors?.businessDetails?.industry && <span className={styles.errorMessage}>{errors?.businessDetails?.industry}</span>}
            </div>
            <div className={styles.payButtonRow}>
                <div className={styles.addButton} style={{ borderRadius: "7px", backgroundColor: "#FFF" }} onClick={() => setFormName("fundingForm")} >
                    <span style={{ color: "#3D32CF" }}>Back</span>
                </div>
                <div className={styles.addButton} style={{ borderRadius: "7px" }} onClick={() => handleContinue()} >
                    <span >Continue</span>
                </div>
            </div>
        </div>
    )
}

export default BusinessInfoForm