import React, { useEffect, useState } from "react";
import strings from "../../../../../localization";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import TextareaControl from "../../../../Controls/Inputs/TextareaControl";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Editor } from 'ckeditor5-custom-build/build/ckeditor';

const toolbarConfig = {
    items: [
        'heading', '|',
        'fontfamily', 'fontsize', '|',
        'alignment', '|',
        'fontColor', 'fontBackgroundColor', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
        'link', '|',
        'outdent', 'indent', '|',
        'bulletedList', 'numberedList', 'todoList', '|',
        'code', 'codeBlock', '|',
        'insertTable', '|',
        'uploadImage', 'blockQuote', '|',
        'undo', 'redo'
    ],
    link: { addTargetToExternalLinks: true },
    placeholder: strings.forms.admin.adminTemplateForm.description,
    shouldNotGroupWhenFull: true,
    removePlugins: ['RealTimeCollaborativeEditing', 'PresenceList', 'RealTimeCollaborativeComments', 'RealTimeCollaborativeTrackChanges']
}

const DetailsForm = ({
                            errors,
                            data,
                            values,
                            description,
                            setDescription,
                            isDescriptionLoaded
    }) => {

    const [editor, setEditor] = useState(null);
    
    return (
        <div className="form-part">
            <div style={{paddingBottom: '5px'}}>
                <TextFieldControl
                    name='name'
                    control={data}
                    defaultValue=''
                    rules={{required: true}}
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.admin.adminTemplateForm.name}
                    placeholder={strings.forms.admin.adminTemplateForm.name}
                />
            </div>
            { isDescriptionLoaded &&
            <CKEditor
                onReady={ editor => {
                    editor.ui.getEditableElement()?.parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                    );
                    setEditor(editor)
                } }
                // onError={ ( { willEditorRestart } ) => {
                //     if ( willEditorRestart ) {
                //         editor.ui.view.toolbar.element.remove();
                //     }
                // } }
                onChange={() => setDescription(editor.getData())}
                editor={ Editor }
                data={description ? description : ''}
                config={toolbarConfig}
            /> }
            {/* <TextareaControl
                name='description'
                control={data}
                defaultValue=''
                rules={{required: false}}
                fullWidth
                margin="normal"
                error={Boolean(errors.description)}
                helperText={errors.description && strings.forms.admin.adminTemplateForm.description}
                placeholder={strings.forms.admin.adminTemplateForm.description}
                rows={10}
                maxRows={15}
            /> */}
        </div>
    )
}

export default DetailsForm;