import React, {useEffect, useState} from "react";
import strings from "../../../../../localization";
import CloseIcon from '@mui/icons-material/Close';
import {getUsers} from "../../../../../Services/AdminFakeApi";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import AutocompleteSelectControl from '../../../../Controls/AutocompleteSelectControl';
import TextFieldControl from '../../../../Controls/Inputs/TextFieldControl';
import {getAllUsers} from "../../../../../Services/User/UserService";
import PackageType from "../../../../../Constants/Admin/PackageType";
import {createFilterOptions} from '@mui/material/Autocomplete';
import UserType from "../../../../../Constants/User/UserType";
import { Tooltip } from "@mui/material";

const radioButtonList = [
    {name: strings.forms.admin.adminPackageForm.users.allUsers, value: 'allUsers' },
    {name: strings.forms.admin.adminPackageForm.users.selectedUsers, value: 'selectedUsers' }
]

const UserForm = ({
                    data,
                    values,
                    errors,
                    onUserDataChanged,
                    getValues
                }) => {

    const [allUsers, setAllUsers] = useState([])
    const [otherUsers, setOtherUsers] = useState([])
    const [usersIdList, setUsersIdList] = useState([])
    const [usersFullObjList, setUsersFullObjList] = useState([])

    const [selectedRadio, setSelectedRadio] = useState(radioButtonList[0].value)
    const [showTags, setShowTags] = useState(false)
    const [maxNumMsg, setMaxNumMsg] = useState(false)
    const [maxNumUndefinedMsg, setMaxNumUndefinedMsg] = useState(false)

    useEffect(() => {
        fetch();
        setSelectedRadio('allUsers')
    }, [])

    useEffect(() => {

        if(!getValues('maxNumberOfUsers')) {
            setMaxNumMsg(false)
        }

        if (usersFullObjList.length < parseInt(getValues('maxNumberOfUsers'))) {
            setMaxNumMsg(false)
        }
    }, [usersFullObjList])

    useEffect(() => {
        onUserDataChanged({

            packageType: selectedRadio === 'selectedUsers' ? PackageType.SELECTED_USERS : PackageType.ALL,
            users: getUserIds()
        });
    }, [usersFullObjList, selectedRadio])

    const fetch = () => {

        let all = [];

        getAllUsers().then(response => {

            if(!response || !response.ok) {
                return;
            }

            setAllUsers(response.data);

            response.data.map(user => {
                if(user.userProfileType === UserType.COMPANY_ADMIN) {
                    all = [...all, user];
                }
            });
            
            setOtherUsers(all)
        })
    }

    const handleCloseTag = (id) => {

        setUsersFullObjList(usersFullObjList.filter(e => e.id !== id))

        let user = allUsers.find(e => e.id === id)

        setOtherUsers([
            ...otherUsers,
            user
        ])

        setUsersIdList([
            ...usersIdList,
            user.id
        ])
    }

    const getDataItem = () => {
        let result = [];

        if (usersFullObjList.length > 0) {
            for (let item of usersFullObjList) {

                result.push(
                    <div className="tag" key={item.id}>
                        <Tooltip title={item.fullName + ' (' + item.email + ')'}>
                            <span className="tag-text">{item.fullName} ({item.email})</span>
                        </Tooltip>
                        <CloseIcon className="close-icon" onClick={() => handleCloseTag(item.id)} />
                    </div>
                )
            }
        }

        return result
    }

    const setUserFullObject = (user) => {
        setUsersFullObjList([
            ...usersFullObjList,
            user
        ])
        
        setOtherUsers(otherUsers.filter(e => e.id !== user.id))
        
        setUsersIdList([
            ...usersIdList,
            user
        ]);
    }

    const handleInputChange = (user) => {
        setMaxNumMsg(false)
        setMaxNumUndefinedMsg(false)

        if (getValues('maxNumberOfUsers') === undefined) {
            setUserFullObject(user);
        } else if (usersFullObjList.length === parseInt(getValues('maxNumberOfUsers'))) {
            setMaxNumMsg(true)
        } else {
            setUserFullObject(user);
        }
    }

    const getUserIds = () => {

        let result = []

        for(let user of usersFullObjList) {
            result.push(user.id);
        }

        return result;
    }

    const handleRadio = (event) => {
        setSelectedRadio(event.target.value)

        if (event.target.value === 'selectedUsers') {
            setShowTags(true)
        } else {
            setShowTags(false)
        }
    };

    const filterOptions = createFilterOptions({
        stringify: ({ fullName, email }) => `${fullName} ${email}`
    });

    return (
        <div className="item first">
            <div className="item-title">
                <span>{strings.forms.admin.adminPackageForm.users.users}</span>
            </div>

            <FormControl className="radio-buttons-group-wrap">
                <RadioGroup
                    aria-label={strings.pages.adminPackages.users}
                    name="radio-buttons-group"
                    onChange={(event) => handleRadio(event)} 
                    className="radio-group-control" 
                >
                    {
                        radioButtonList.map(e => 
                            <FormControlLabel
                                // name="test"
                                checked={selectedRadio === e.value}
                                value={e.value}
                                control={<Radio />} 
                                label={e.name}
                            />
                        )
                    }
                </RadioGroup>
            </FormControl>

                <div className="max-num-container">
                    <p>{strings.forms.admin.adminPackageForm.users.maxNumberOfInternalUsers}</p>
                    <TextFieldControl
                        className="shortInput"
                        name='maxNumberOfUsers'
                        control={data}
                        rules={{required: false}}
                        margin="normal"
                        error={Boolean(errors.maxNumberOfUsers)}
                        helperText={errors.maxNumberOfUsers && strings.forms.admin.adminPackageForm.users.maxNumberOfInternalUsers}
                    />
                </div>

                { showTags  && 
                <React.Fragment>
                    <AutocompleteSelectControl 
                        name={'users'}
                        control={data}
                        nameKey={'email'}
                        options={otherUsers}
                        handleInputChange={handleInputChange}
                        filterOptions={filterOptions}
                        placeholder={strings.forms.admin.adminPackageForm.users.enterEmailAddress}
                    />

                    {maxNumUndefinedMsg &&
                        <p className="error-msg">{strings.forms.admin.adminPackageForm.users.selectUserNumberMsg}{getValues('maxNumberOfUsers')}</p>
                    }

                    {maxNumMsg &&
                        <p className="error-msg">{strings.forms.admin.adminPackageForm.users.maxUsersMsg}{getValues('maxNumberOfUsers')}</p>
                    }

                    <div className="tag-list">
                        {getDataItem()}
                    </div>
                </React.Fragment>   
            }

        </div>
    )
}

export default UserForm;