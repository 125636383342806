import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getSharedUsersForContract(id) {
    return await request('/api/shared/contract/' + id);
}

export async function getSharedUsersForFolder(id) {
    return await request('/api/shared/folder/' + id);
}

export async function shareContractOrFolder(data) {
    return await request('/api/shared/add/', data, HttpMethod.POST);
}

export async function unshareContractOrFolder(id) {
    return await request('/api/shared/' + id, {}, HttpMethod.DELETE);
}