export function getCompanyTypes() {
    return [
        {
            id: 'Sole Trader Individual',
            name: 'Sole Trader Individual'
        },
        {
            id: 'Freelance Consultant',
            name: 'Freelance Consultant'
        },
        {
            id: 'Private Limited Company',
            name: 'Private Limited Company'
        },
        {
            id: 'Public Limited Company',
            name: 'Public Limited Company'
        },
        {
            id: 'Partnership, Employee',
            name: 'Partnership, Employee'
        },
    ]
}