import {useForm} from "react-hook-form";
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Components/Controls/Inputs/TextFieldControl";
import strings from "../../../localization";
import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {getTemplate} from "../../../Services/Template/TemplateService";
import {getAllActivateTemplateSteps, getAllTemplateSteps} from "../../../Services/Template/TemplateStepService";
import AddContractStep from "./AddContractStep";
import {Button} from "@mui/material";
import {addContract, getContractCount, regenerateContract} from "../../../Services/Company/CompanyService";
import IconButton from '@mui/material/IconButton';
import LoaderContext from "../../../Context/LoaderContext";
import {getAllActiveTemplateQuestions, getAllTemplateQuestionsByTemplate} from "../../../Services/Template/TemplateQuestionService";
import {useSelector} from "react-redux";
import {isTrial} from "../../../Util/PermissionUtil";
import ConfirmModal from "../../../Components/Modals/ConfirmModal";
import Modal from "@mui/material/Modal";
import {getAllQuestionRelationsByTemplate} from "../../../Services/Template/QuestionRelationService";
import QuestionFormat from "../../../Constants/Admin/QuestionFormat";
import QuestionRelationType from "../../../Constants/Admin/QuestionRelationType";
import {getTemplateAnswersForContract} from "../../../Services/Contract/TemplateQuestionAnswerService";
import UserType from "../../../Constants/User/UserType";
import { dateTimeToString } from "../../../Util/DateUtil";
import { values } from "pdf-lib";

const AddContract = () => {

    const user = useSelector((state) => state.auth.user)
    const location = useLocation();
    const navigate = useNavigate();
    const form = useForm();
    const {data, control, watch, handleSubmit, getValues, setValue, setError, formState: {errors}} = form;
    const [activeStep, setActiveStep] = useState(null);
    const [percent, setPercent] = useState(0);
    const [template, setTemplate] = useState(null);
    const [steps, setSteps] = useState([]);
    const [tabSteps, setTabSteps] = useState([]);
    const [errorName, setErrorName] = useState(false)
    const [questions, setQuestions] = useState([]);
    const [openTrialModal, setOpenTrialModal] = useState(false);
    const {showLoader, setLoaderTitle} = useContext(LoaderContext);
    const [contractCount, setContractCount] = useState(0)
    const [relations, setRelations] = useState([]);
    const [contract, setContract] = useState(null)
    const [editContractModal, setEditContractModal] = useState(false);
    const [documentNameModal, setDocumentNameModal] = useState(false);
    const [requiredFieldsModal, setRequiredFieldsModal] = useState(false);

    setLoaderTitle('Please wait while your contract is generated')

    useEffect(() => {

        if (!location.state.templateId && !location.state.contract) {
            return;
        }
        setContract(location.state.contract)
        const templateId = location.state.contract ? location.state.contract.template.id : location.state.templateId;


        getTemplate(templateId).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTemplate(response.data);
        })

        getAllActivateTemplateSteps(templateId).then(response => {

            if (!response || !response.ok) {
                return;
            }

            response.data.map(step => {
                getAllActiveTemplateQuestions(step.id).then(response => {

                    if (!response || !response.ok) {
                        return;
                    }
        
                    response.data = response.data.map(question => {
                        if (question.options && question.options.length > 0) {
                          question.options = question.options.filter(option => !option.deleted);
                        }
                        return question;
                    });
        
                    setupDefaultValues(response.data);
                    setDefaultValuesQuestions(response.data);
                });
            });

            setSteps(response.data);
            extractTabSteps(response.data);
        })

        getAllTemplateQuestionsByTemplate(templateId).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setQuestions(response.data);
        })

        getContractCount().then(response => {
            if (!response || !response.ok) {
                return;
            }

            setContractCount(response.data)
        })

        getAllQuestionRelationsByTemplate(templateId).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setRelations(response.data)
        })

    }, []);

    useEffect(() => {
        if (!contract) {
            return
        }


        setValue('name', contract?.name)

        getTemplateAnswersForContract(contract.id).then(response => {

            if (!response || !response.ok) {
                return;
            }
            applyAnswers(response.data)
        })

        setEditContractModal(true)

    }, [contract])

    const applyAnswers = (answers) => {

        for (let answer of answers) {
            let name = '' + answer.templateQuestion.id;

            if (answer.templateQuestion.questionType === QuestionFormat.RADIO_BUTTON ||
                answer.templateQuestion.questionType === QuestionFormat.SELECT ||
                answer.templateQuestion.questionType === QuestionFormat.AUTOCOMPLETE) {
                setValue(name, answer.longValue)
            } else if (answer.templateQuestion.questionType === QuestionFormat.TEXT_FIELD
                || answer.templateQuestion.questionType === QuestionFormat.NUMBER_INPUT
                || answer.templateQuestion.questionType === QuestionFormat.TEXTAREA) {
                setValue(name, answer.stringValue)
            } else if (answer.templateQuestion.questionType === QuestionFormat.DATE_PICKER) {
                setValue(name + '-raw', answer.stringValue)
            } else if (answer.templateQuestion.questionType === QuestionFormat.CHECKBOX ||
                answer.templateQuestion.questionType === QuestionFormat.TOGGLE ||
                answer.templateQuestion.questionType === QuestionFormat.SWITCH) {
                setValue(name, answer.boolValue)
            }
        }
    }

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            calculatePercent(questions);
        });
        return () => subscription.unsubscribe();
    }, [watch, questions]);

    const extractTabSteps = (steps) => {

        if (!steps) {
            return;
        }

        let result = [];

        for (let step of steps) {

            if (step.parent) {
                continue;
            }

            result.push(step);
        }

        if (result.length > 0) {
            setActiveStep(result[0]);
        }

        setTabSteps(result);
    }

    const renderTabs = (steps) => {

        let result = [];

        if (!steps || !activeStep) {
            return result;
        }

        for (let step of steps) {

            if (step.parent) {
                continue;
            }

            let classValue = activeStep.id === step.id ? "tab active" : "tab";

            if (step.templateStepOrder < activeStep.templateStepOrder) {
                classValue += ' done'
            }

            result.push(
                <span key={'step-tab-' + result.length} className={classValue}
                      onClick={() => setActiveStep(step)}
                      >{step.name}</span>
            )
        }

        return result
    }

    const getSubSteps = (step, steps) => {

        let result = [];

        if (!step) {
            return result;
        }

        for (let s of steps) {
            if (!s.parent) {
                continue;
            }

            if (s.parent.id === step.id) {
                result.push(s);
            }
        }

        return result;
    }

    const calculatePercent = (questions) => {

        if (!questions) {
            return 0;
        }

        const data = getValues()
        let filled = 0.0;

        for (let question of questions) {
            if (data[question.id] !== undefined && data[question.id] !== '') {
                filled++;
            }
        }

        setPercent(parseFloat(filled / questions.length).toFixed(2) * 100)
    }

    const getCurrentIndexStep = () => {
        return tabSteps.findIndex(object => {
            return object.id === activeStep.id;
        });
    }

    const canBack = () => {
        return getCurrentIndexStep() > 0
    }

    const canNext = () => {
        return getCurrentIndexStep() < tabSteps.length - 1
    }

    const handleBack = () => {
        const index = getCurrentIndexStep();
        setActiveStep(tabSteps[index - 1]);
    }

    const handleNext = () => {
        const index = getCurrentIndexStep();
        setActiveStep(tabSteps[index + 1]);
    }

    const areRequiredFieldsFilled = (questions, data) => {

        let result = true;
        let stepLists = [];

        for (let question of questions) {
            if (!question.isRequired) {
                continue
            }

            const value = question.questionType === QuestionFormat.COMPANY_HOUSE ? data[question.id + "_Company-Name"] : data[question.id];
            if (value === null || value === undefined || value === '') {
                            
                stepLists = [...stepLists, steps.find(x => x.id === question.templateStep.id)?.parent]

                if(question.questionType === QuestionFormat.COMPANY_HOUSE) {
                    setError(question.id + '_Company-Name', {type: 'custom', message: 'You are required to provide an answer to this question'})
                } else {
                    setError(question.id + '', {type: 'custom', message: 'You are required to provide an answer to this question'})
                }
                result = false
            } 
        }

        if(!result) {
            const fistStep = stepLists.reduce((min, current) => current.templateStepOrder < min.templateStepOrder ? current : min);
            setActiveStep(fistStep)
        }

        return result;
    }

    const handleTrialModalYes = () => {
        setOpenTrialModal(false)
        if(user.userProfileType === UserType.COMPANY_ADMIN) {
            navigate('/pricing-and-package')
            return;
        }
        navigate('/dashboard')
    }

    const handleTrialModalNo = () => {
        setOpenTrialModal(false)
        // handleGenerate(getValues())
    }

    const isRelationSatisfied = (data, relation) => {

        const relationQuestionValue = data[relation.toQuestion.id]

        if (relation.toQuestion.questionType === QuestionFormat.SWITCH ||
            relation.toQuestion.questionType === QuestionFormat.CHECKBOX ||
            relation.toQuestion.questionType === QuestionFormat.TOGGLE) {
            if (relation.type === QuestionRelationType.EQUAL) {
                return relation.templateQuestionOption?.boolValue === relationQuestionValue;
            } else {

                return relationQuestionValue && relation.templateQuestionOption?.boolValue !== relationQuestionValue;
            }
        } else if (relation.toQuestion.questionType === QuestionFormat.RADIO_BUTTON) {
            if (relation.type === QuestionRelationType.EQUAL) {
                return relation.templateQuestionOption?.id === relationQuestionValue;
            } else {

                return relationQuestionValue && relation.templateQuestionOption?.id !== relationQuestionValue;
            }
        } else if (relation.toQuestion.questionType === QuestionFormat.SELECT) {
            if (relation.type === QuestionRelationType.EQUAL) {
                return relationQuestionValue && relation.templateQuestionOption?.id === relationQuestionValue.id;
            } else {

                return relationQuestionValue && relation.templateQuestionOption?.id !== relationQuestionValue.id;
            }
        }

        return true;
    }

    const isQuestionVisible = (data, question, relations) => {
        for (let relation of relations) {
            if (question && relation && relation.question && question.id === relation.question.id) {
                const result = isRelationSatisfied(data, relation)
                if (!result) {
                    return false;
                }
            }
        }

        return true;
    }

    const companyHouseItemsFilter = (items) => {
        let result = [];

        for(const item of items) {

            if(item.description === 'Jurisdiction-other') {
                continue
            }

            if(item.value === 'Other' && item.description === 'Jurisdiction') {

                const jurisdictionItem = items.find(x => x.id === item.id && x.description === 'Jurisdiction-other')

                if(jurisdictionItem) {
                    result.push({
                        id: jurisdictionItem.id,
                        value: jurisdictionItem.value,
                        description: 'Jurisdiction'
                    })
                }

                continue
            }

            result.push(item)
        }

        return result;
    }

    const setDefaultValuesQuestions = (questions) => {
        for (let question of questions) {
            if (question.questionType === QuestionFormat.TIME_PICKER) {

                setValue('' + question.id, dateTimeToString(new Date(), 'h:mm a'));
            }

            if (question.questionType === QuestionFormat.DATE_PICKER) {

                setValue('' + question.id, dateTimeToString(new Date(), 'dd MMMM yyyy'));
            }
        }
    }

    const setupDefaultValues = (questions) => {
        for(let question of questions) {

            if(!question.options) {
                continue;
            }
            
            for(let option of question.options) {

                if(!option.isDefault) {
                    continue;
                }

                const name = question.id + '';

                if(question.questionType === QuestionFormat.SWITCH ||
                    question.questionType === QuestionFormat.TOGGLE ||
                    question.questionType === QuestionFormat.CHECKBOX) {
                    if(getValues()[name] === undefined || getValues()[name] === null) {
                        setValue(question.id + '', option.boolValue)
                    }
                }
                else if(question.questionType === QuestionFormat.CHECKBOX_GROUP) {
                    let checkedOptions = [];
                    question.options.map(option => {
                        if(option.boolValue || option.isDefault) {
                            checkedOptions = [...checkedOptions, option.id];
                        }
                    })
                    setValue(name, checkedOptions)
                }
                else {
                    if(!getValues()[name]) {
                        setValue(name, option.id)
                    }
                }
            }
        }
    }

    const handleGenerate = (data) => {

        if(!data.name) {
            setDocumentNameModal(true)
            return;
        }

        if (!areRequiredFieldsFilled(questions, data)) {
            setRequiredFieldsModal(true)
            return;
        }

        setErrorName(false)

        let cmp_count = 2;
        let items = [];

        for (let [key, value] of Object.entries(data)) {


            if (value === undefined || value === null || key === 'name') {
                continue;
            }

            if (key && key.includes('raw')) {
                continue;
            }

            let description = '';

            if (key.includes('_Company-Name') || key.includes('_Company-Address') || key.includes('_Company-Number')
                || key.includes('_Jurisdiction') || key.includes('_Jurisdiction-other')) {
                let splitData = key.split('_');
                key = splitData[0]
                description = splitData[1]
            }

            const questionValue = value.id ? value.id : value;
            const question = questions.find(x => x.id == key);

            if (!isQuestionVisible(data, question, relations)) {
                continue
            }

            if(key.includes('131') && value === '0'){
              cmp_count = 0;
            }else if(key.includes('131') && value === '1'){
                cmp_count = 1;
            } 

            if((key.includes('138') && cmp_count===0) || (key.includes('139') && cmp_count===0)){
                continue;
            }
            else if((key.includes('139') && cmp_count===1)){
                continue;
            }

            items.push({id: key, value: questionValue + '', description: description});
        }

        items = companyHouseItemsFilter(items)

        let requestData = {
            name: data.name,
            templateId: template.id,
            items: items
        }

        if(contract) {
            regenerateContract(requestData, contract.id).then( response => {
                if (!response || !response.ok) {
                    setErrorName(true)
                    return;
                }


                if (isTrial(user) && contractCount >= 1) {
                    navigate('/')
                } else {
                    navigate('/contract-preview/' + response.data.id)
                }
            })
        }
        else {
            showLoader(true)
            addContract(requestData).then(response => {

                if (!response || !response.ok) {
                    setErrorName(true)
                    showLoader(false)
                    return;
                }

                showLoader(false)

                if (isTrial(user) && contractCount >= 1) {
                    navigate('/')
                } else {
                    navigate('/contract-preview/' + response.data.id)
                }
            })
        }
    }

    return <FormProvider {...form}>
        <div id='add-contract-page'>

            <h1 className="title">{strings.pages.addContract.title}</h1>

            <div className="item-field-container name-field">
                <p className="info-icon-text">{strings.pages.addContract.documentName}
                    {/* <IconButton><img src="/images/icons/info.png"/></IconButton> */}
                </p>
                <TextFieldControl
                    name='name'
                    control={data}
                    defaultValue=''
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.required}
                    placeholder={strings.pages.addContract.enterDocumentName}
                />
                {errorName &&
                    <span className="error-msg">{strings.pages.addContract.documentNameAlreadyExistMsg}</span>
                }
            </div>
            <div id="add-contract" className="contracts-content">
                <div className="subheader-container">
                    <div className="subheader">
                        {renderTabs(steps)}
                    </div>
                    {/* <span className="percentage">{Math.round(percent)}%</span> */}
                </div>
                <AddContractStep relations={relations} watch={watch} template={template}
                                 steps={getSubSteps(activeStep, steps)} data={data}
                                 setValue={setValue} getValues={getValues} errors={errors}/>
                <div className="border"></div>
                <div className="submit-container">

                    {canBack() &&
                        <Button className="neutral-btn btn" variant="contained" onClick={handleBack}>
                            <span className="btn-txt">{strings.forms.contract.addContractForm.back}</span>
                        </Button>
                    }

                    {
                        canNext() &&
                        <Button className="default-btn btn" variant="contained" onClick={handleNext}>
                            <span className="btn-txt">{strings.forms.contract.addContractForm.next}</span>
                        </Button>
                    }

                    {
                        !canNext() &&
                        <React.Fragment>
                            <React.Fragment>
                                {
                                    // !contract &&
                                    (!isTrial(user) || (isTrial(user) && contractCount === 0)) &&
                                    <Button className="default-btn btn" variant="contained"
                                            onClick={handleSubmit(handleGenerate)}>
                                        <span className="btn-txt">{strings.forms.contract.addContractForm.generate}</span>
                                    </Button>
                                }

                                {/*{*/}
                                {/*    contract &&*/}
                                {/*    <Button className="default-btn btn" variant="contained"*/}
                                {/*            onClick={() => setEditContractModal(true)}>*/}
                                {/*        <span className="btn-txt">{strings.forms.contract.addContractForm.generate}</span>*/}
                                {/*    </Button>*/}
                                {/*}*/}
                            </React.Fragment>
                            {
                                isTrial(user) && contractCount > 0 &&
                                <Button className="default-btn btn" variant="contained"
                                        onClick={() => setOpenTrialModal(true)}>
                                    <span className="btn-txt">{strings.forms.contract.addContractForm.generate}</span>
                                </Button>
                            }
                        </React.Fragment>

                    }

                </div>

                <Modal
                    open={openTrialModal}
                    onClose={handleTrialModalNo}
                    className='confirm-modal-container'
                >
                    <ConfirmModal
                        contentText={user.userProfileType === UserType.COMPANY_ADMIN ? strings.modals.trialModal.confirmText : strings.modals.trialModal.confirmTextChild}
                        // cancelBtn={strings.modals.trialModal.no}
                        confirmBtn={strings.modals.trialModal.ok}
                        handleCloseModal={handleTrialModalNo}
                        handleConfirm={handleTrialModalYes}
                    />
                </Modal>

                <Modal
                    open={editContractModal}
                    onClose={() => setEditContractModal(false)}
                    className='confirm-modal-container'
                >
                    <ConfirmModal
                        contentText={strings.forms.contract.addContractForm.editConfirm}
                        confirmBtn={'ok'}
                        handleCloseModal={() => setEditContractModal(false)}
                        handleConfirm={() => setEditContractModal(false)}
                    />
                </Modal>
                <Modal
                    open={documentNameModal}
                    onClose={() => setDocumentNameModal(false)}
                    className='confirm-modal-container'
                >
                    <ConfirmModal
                        contentText={'Please add the document name'}
                        confirmBtn={'Ok'}
                        handleConfirm={() => setDocumentNameModal(false)}
                    />
                </Modal>

                <Modal
                    open={requiredFieldsModal}
                    onClose={() => setRequiredFieldsModal(false)}
                    className='confirm-modal-container'
                >
                    <ConfirmModal
                        contentText={'Certain questions are required to be answered before you can generate the contract.'}
                        confirmBtn={'Ok'}
                        handleConfirm={() => setRequiredFieldsModal(false)}
                    />
                </Modal>
            </div>
        </div>
    </FormProvider>
}

export default AddContract