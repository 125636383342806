import strings from '../localization';
import { format, parse } from 'date-fns'

export const getYears = (plusYears = 0) => {

    let result = [];
    let currentYear = (new Date()).getFullYear();

    currentYear += plusYears;

    for(let i = currentYear; i >= currentYear - 99; i--) {

        result.push({
            id: i,
            value: i
        });
    }

    return result;
}

export const getMonths = () => {

    return [
        {id: 1, value: strings.months.january},
        {id: 2, value: strings.months.february},
        {id: 3, value: strings.months.march},
        {id: 4, value: strings.months.april},
        {id: 5, value: strings.months.may},
        {id: 6, value: strings.months.june},
        {id: 7, value: strings.months.july},
        {id: 8, value: strings.months.august},
        {id: 9, value: strings.months.september},
        {id: 10, value: strings.months.october},
        {id: 11, value: strings.months.november},
        {id: 12, value: strings.months.december},
    ];
}

export const leapYear = (year) => {
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

export const stringToDate = (date, formatString = 'DD-MM-YYYY') => {
    return parse(date, formatString, new Date());
}

export const dateToString = (date, formatString = 'dd/MM/yyyy') => {
    return format(date, formatString);
}

export const dateToStringDatePickerFormat = (date, formatString = 'MM/DD/YYYY') => {
    return format(date, formatString);
}

export const dateTimeToString = (date, formatString = 'DD-MM-YYYY H:MM') => {
    return format(date, formatString);
}

export const getCurrentYear = () => {
    return new Date().getFullYear();
}

export const getCurrentMonthObj = () => {
    let currentMonth = new Date().getMonth()
    return getMonths().find(e => e.id == currentMonth + 1)
}

export const getDifferentBetweenDateInDays = (date1, date2) => {
    return Math.floor(
      (Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate() - 1) -
        Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
};

export const dateInPast = (date) => {
    return date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
};


export const getMonthName = (month) => {
    switch(month) {
        case 0: return strings.months.january;
        case 1: return strings.months.february;
        case 2: return strings.months.march;
        case 3: return strings.months.april;
        case 4: return strings.months.may;
        case 5: return strings.months.june;
        case 6: return strings.months.july;
        case 7: return strings.months.august;
        case 8: return strings.months.september;
        case 9: return strings.months.october;
        case 10: return strings.months.november;
        case 11: return strings.months.december;
    }
}

export const getDayOrdinalNum = (day) => {
    if (day === 1) {
        return '1st'
    } else if (day === 2) {
        return '2nd'
    } else if (day === 3) {
        return '3rd'
    } else {
        return day + 'th'
    }
}

export const formatDateInText = (date) => {
    let day = getDayOrdinalNum(date.getDate())
    let month = getMonthName(date.getMonth())
    let year = date.getFullYear()
    let formatDate = day + ' ' + month + ' ' + year
    return formatDate
}

export const formatDateWithTime = (date) => {
    let day = getDayOrdinalNum(date.getDate())
    let month = getMonthName(date.getMonth())
    let year = date.getFullYear()
    let h = date.getHours()
    let m = date.getMinutes()
    let partOfTheDay = h >= 12 ? 'pm' : 'am'
    let formatDate = day + ' ' + month + ' ' + year + ', ' + h + ':' + m + partOfTheDay
    return formatDate
}

export const datediff = (first, second) => {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second-first)/(1000*60*60*24));
}

export const isEqualDate = (first, second) => {
    return first?.getFullYear() === second?.getFullYear() && first?.getMonth() === second?.getMonth() && first?.getDate() === second?.getDate();
}

export const convertUTCToLocalTime = (utcDate) => {
    return utcDate?.getTime() ? new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000) : new Date();

}

export const commentTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const currentDate = new Date();

    const difference = currentDate - date;
    const millisecondsInDay = 1000 * 60 * 60 * 24;
    const daysDifference = Math.floor(difference / millisecondsInDay);

    if (date.toDateString() === currentDate.toDateString()) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amPm = hours >= 12 ? 'PM' : 'AM';
        const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes} ${amPm}`;
        return formattedTime;
    } else {
        if (daysDifference <= 1) {
            return '1 day ago';
        } else if (daysDifference <= 7) {
            return `${daysDifference} days ago`;
        }  else if (daysDifference <= 14) {
            return '1 week ago';
        } else if (daysDifference <= 30) {
            const weeksAgo = Math.floor(daysDifference / 7);
            return `${weeksAgo} weeks ago`;
        } else if (daysDifference <= 60) {
            return '1 month ago';
        } else if (daysDifference <= 365) {
            const monthsAgo = Math.floor(daysDifference / 30);
            return `${monthsAgo} weeks ago`;
        } else {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        }
    }
}

