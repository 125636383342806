import React, { useEffect, useState } from "react";

const SwitchControl = (props) => {
  // console.log("props", props);
  const [toggleValue, setToggleValue] = useState(props.value);

  useEffect(() => {
    setToggleValue(props.value);
  }, [props.value]);

  const handleToggle = (value) => {
    setToggleValue(value);
    props.setValue(props.name, value);
  };

  return (
    <div
      className={"switch-control-custom"}
      style={{ marginLeft: props.noLeftMargin ? "0rem" : "auto" }}
      onClick={props?.onClick}
    >
      <div
        className={
          toggleValue
            ? "switch-control-custom-container yes-switch"
            : "switch-control-custom-container no-switch"
        }
        style={props.style}
      >
        <div
          className="switch-control-custom"
          onClick={() => handleToggle(!toggleValue)}
          style={props.controlCustomStyle}
        >
          <span
            className={!toggleValue ? "tab active-white" : "tab"}
            style={props.tabWhiteStyle}
          />
          <span
            className={toggleValue ? "tab active" : "tab"}
            style={props.tabActiveStyle}
          />
        </div>
      </div>
      {props.showValues === undefined || props.showValues === true ? (
        <div className={"switch-control-custom-text"}>
          <p className={!toggleValue ? "active" : "deactive"}>No</p>
          <p className={toggleValue ? "active" : "deactive"}>Yes</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SwitchControl;
