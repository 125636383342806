import React, { useEffect, useState, useContext, useCallback } from "react";
import styles from "./AIData.module.css";
import AiDataTable from "./AiDataTable";
import { useSelector, useDispatch } from "react-redux";
import {
  aiClauseUpdateMany,
  aiRequestDeleteById,
  getAllAIData,
} from "../../../Services/AI/AIConversationService";
import SnackbarContext from "../../../Context/SnackbarContext";
import strings from "../../../localization";
import { AIDatacolumns } from "../../../Constants/Admin/AIDataModel";
import { aiDataActions } from "../../../Slices/AiDataSlice";
import Modal from "@mui/material/Modal";
import SimpleConfirmModal from "./../../../Components/Modals/SimpleConfirmModal";
import { useNavigate } from "react-router-dom";

function AIRequestTagQuestion({ deleteAiRequestHandler }) {
  const navigate = useNavigate();
  const { showMessage } = useContext(SnackbarContext);

  const dispatch = useDispatch();

  const taggedData = useSelector((state) => state.aiData.taggedData);

  const aiRequestDataRow = useSelector((state) => state.aiData.aiRequestRow);
  const aiAllData = useSelector((state) => state.aiData.aiAllData);

  const [deleteTab, setDeleteTab] = useState(false);
  // const [showError, setShowError] = useState(false);
  const [filterOptionSearchText, setFilterOptionSearchText] = useState("");
  const [showColumn, setShowColumn] = useState(AIDatacolumns);
  const [showAiData, setShowAiData] = useState([]);
  const [showLoader, setShowLoader] = useState({
    save: false,
    delete: false,
  });

  // console.log("aiAlldata", aiAllData)

  const [rowss, setRowss] = useState([]);

  function createData(
    data_type,
    question,
    category,
    contract,
    contract_variation,
    clause,
    sector,
    smart_tag,
    // buttons,
    isOpen,
    data
  ) {
    return {
      data_type,
      question,
      category,
      contract,
      contract_variation,
      clause,
      sector,
      smart_tag,
      // buttons,
      isOpen,
      data,
    };
  }

  const handleDeleteTab = () => {
    if (taggedData.length < 1) {
      showMessage("You don't have any tagged data.");
    } else {
      setShowLoader((preData) => {
        preData.delete = true;
        return preData;
      });
      setDeleteTab(true);
    }
  };

  const handleCloseDeleteTab = () => {
    setDeleteTab(false);
  };
  const deleteHandler = () => {
    dispatch(aiDataActions.setEmptyTaggedData([]));
    getAllAiDataHandler();

    setDeleteTab(false);
  };

  const handleClick = (data) => {
    let tempData = structuredClone(data);

    tempData.question.push(aiRequestDataRow.request);
    dispatch(
      aiDataActions.settaggedData({
        data: tempData,
        request: aiRequestDataRow.request,
      })
    );
    const tempShowAiData = showAiData.map((shoData) => {
      if (tempData.id === shoData.id) {
        return tempData;
      }
      return shoData;
    });
    setShowAiData(tempShowAiData);

    showMessage(
      "The question is tagged to the data. To save the entry, press the save button."
    );
  };

  const toggleRowHandler = (id) => {
    console.log("id", id);
    console.log("rowss", rowss);
    let tempRows = rowss.filter((row) => {
      if (id === row.data.id) {
        console.log("if", id);
        row.isOpen = !row.isOpen;
        return row;
      } else {
        return row;
      }
    });
    console.log("id 2", id);
    setRowss(tempRows);
  };

  const submitSelectedColumnFilterHandler = (column) => {
    let tempfilterObj = [];

    column.options.forEach((colOption) => {
      if (colOption.checked) {
        if (Array.isArray(aiAllData[0][column.id])) {
          aiAllData.forEach((aiData) => {
            if (aiData[column.id].length > 0) {
              aiData[column.id]?.forEach((aiDataValue) => {
                if (aiDataValue?.includes(colOption.label)) {
                  const find = tempfilterObj.find(
                    (obj) => obj.id === aiData.id
                  );
                  if (!find) {
                    tempfilterObj.push(aiData);
                  }
                }
              });
            }
          });
        } else {
          const filterData = aiAllData.filter((aiData) =>
            aiData[column.id]?.includes(colOption.label)
          );
          tempfilterObj = tempfilterObj.concat(filterData);
        }
      }
    });

    let filter_TempFilterObj = [];
    showColumn.forEach((col) => {
      col.options.forEach((colOption) => {
        if (colOption.checked) {
          if (Array.isArray(aiAllData[0][col.id])) {
            tempfilterObj.forEach((filterObj) => {
              if (filterObj[col.id].length > 0) {
                filterObj[col.id]?.forEach((aiDataValue) => {
                  if (aiDataValue?.includes(colOption.label)) {
                    const find = filter_TempFilterObj.find(
                      (obj) => obj.id === filterObj.id
                    );
                    if (!find) {
                      filter_TempFilterObj.push(filterObj);
                    }
                  }
                });
              }
            });
          } else {
            const filterData = tempfilterObj.filter((aiData) =>
              aiData[col.id]?.includes(colOption.label)
            );
            filter_TempFilterObj = filter_TempFilterObj.concat(filterData);
          }
        }
      });
    });

    let tempFilter_TempFilterObj = [];

    filter_TempFilterObj?.forEach((data) => {
      const find = tempFilter_TempFilterObj.find(
        (tempData) => tempData.id === data.id
      );
      if (!find) {
        tempFilter_TempFilterObj.push(data);
      }
    });

    columnFilterToggle(column.id);
    setShowAiData(tempFilter_TempFilterObj);
    setFilterOptionSearchText("");
  };

  const selectColumnFilterDataHandler = (colId, optionLabel) => {
    if (optionLabel === "selectAll") {
      let obj = showColumn.filter((showCol) => {
        if (showCol.id === colId) {
          showCol.isSelectAll = !showCol.isSelectAll;
          showCol.options.forEach((option) => {
            option.checked = showCol.isSelectAll;
          });
        }
        return showCol;
      });
      setShowColumn(obj);
    } else {
      let obj = showColumn.filter((showCol) => {
        if (showCol.id === colId) {
          showCol.options.forEach((option) => {
            if (option.label === optionLabel) {
              option.checked = !option.checked;
            }
          });
        }
        return showCol;
      });
      setShowColumn(obj);
    }
  };

  const columnFilterToggle = (id) => {
    const tempShowColumn = showColumn.filter((col) => {
      if (col.id === id) {
        col.isFilterBoxShown = !col.isFilterBoxShown;
        col.options.forEach((option) => {
          option.isShown = true;
        });
        return col;
      } else {
        col.isFilterBoxShown = false;
        return col;
      }
    });
    setShowColumn(tempShowColumn);
    setFilterOptionSearchText("");
  };

  const optionSearchHandler = (e, id) => {
    setFilterOptionSearchText(e.target.value);
    const tempShowColumn = showColumn.filter((sCol) => {
      if (sCol.id === id) {
        sCol?.options.forEach((option) => {
          option.isShown = option.label
            ?.toLowerCase()
            ?.includes(e.target.value?.toLowerCase());
        });
        return sCol;
      } else {
        return sCol;
      }
    });
    setShowColumn(tempShowColumn);
  };

  const dataForColumnFilter = (id) => {
    let tempArray = [];
    aiAllData?.forEach((data) => {
      if (Array.isArray(data[id])) {
        const uniqueArray = Array.from(new Set(data[id]));
        uniqueArray.forEach((item) => {
          const findData = tempArray.find((ele) => ele.label === item);
          if (!findData) {
            tempArray.push({
              label: item,
              checked: true,
              isShown: true,
            });
          }
        });
      } else {
        const findData = tempArray.find((ele) => ele.label === data[id]);
        if (!findData) {
          tempArray.push({
            label: data[id],
            checked: true,
            isShown: true,
          });
        }
      }
    });
    return tempArray;
  };

  const getAllAiDataHandler = useCallback(() => {
    getAllAIData()
      .then((res) => {
        dispatch(aiDataActions.setAiAllData(res.data));
        setShowLoader((preData) => {
          preData.delete = false;
          return preData;
        });
        // setAiAllData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch]);

  useEffect(() => {
    getAllAiDataHandler();
  }, [getAllAiDataHandler]);

  useEffect(() => {
    if (aiAllData?.length > 0) {
      setShowAiData(aiAllData);
    }
    let tempShowColumn = AIDatacolumns?.filter((col) => {
      col.options = dataForColumnFilter(col.id);
      return col;
    });
    setShowColumn(tempShowColumn);
  }, [aiAllData]);

  useEffect(() => {
    if (showAiData?.length > 0) {
      const tempRows = showAiData.map((data) => {
        return createData(
          data["data_type"],
          data["question"][0],
          data["category"][0],
          data["contract"][0],
          data["contract_variation"][0],
          data["clause"][0],
          data["sector"][0],
          data["smart_tag"][0],
          // tableButtonsJsx(data),
          false,
          data
        );
      });
      setRowss(tempRows);
    }
  }, [showAiData]);

  const saveTaggedQuestionHandler = (id) => {
    if (taggedData.length > 0) {
      setShowLoader((preData) => {
        preData.save = true;
        return preData;
      });
      aiClauseUpdateMany(taggedData)
        .then((res) => {
          showMessage(strings.common.saved);
          dispatch(aiDataActions.setEmptyTaggedData([]));
          deleteAiRequestHandler(id);
          setShowLoader((preData) => {
            preData.save = false;
            return preData;
          });
          navigate("/AIRequestData");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      showMessage("Please tag some data!");
    }
  };

  return (
    <>
      <div className={`${styles.whiteBox} ${styles.whiteBoxTagQuestion}`}>
        <div
          className={styles.formRow}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className={styles.aiRequestButtons}
            style={{ marginLeft: 0, alignSelf: "flex-end" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {showLoader.delete ? (
                <div style={{ marginRight: "10px" }} className={styles.loader}>
                  {" "}
                </div>
              ) : (
                <img
                  className={styles.deleteIconImg}
                  src="/images/icons/deleteNewRequest.png"
                  alt="delete"
                  onClick={() => handleDeleteTab()}
                  style={{ width: "50px", marginTop: "1px" }}
                />
              )}
            </div>

            <div className={styles.saveIconImg} style={{ width: "80px" }}>
              {showLoader.save ? (
                <div className={styles.loader}> </div>
              ) : (
                <span
                  onClick={() =>
                    saveTaggedQuestionHandler(aiRequestDataRow?.id)
                  }
                  style={{ fontWeight: "100px", fontStyle: "normal" }}
                >
                  Save
                </span>
              )}
            </div>
          </div>
          <div className={styles.reqQuestionBox}>
            <div className={styles.labelText} style={{ marginTop: "0px" }}>
              <span>Question asked by user</span>
            </div>
            <div className={styles.formRow}>
              <div className={styles.inputContainer}>
                <textarea
                  className={styles.textarea}
                  name="response"
                  type="text"
                  value={aiRequestDataRow?.request}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AiDataTable
        columns={AIDatacolumns}
        rows={rowss}
        choiceBoxName={"TagQuestion"}
        toggleRowHandler={toggleRowHandler}
        handleClick={handleClick}
        columnFilterToggle={columnFilterToggle}
        selectColumnFilterDataHandler={selectColumnFilterDataHandler}
        submitSelectedColumnFilterHandler={submitSelectedColumnFilterHandler}
        filterOptionSearchText={filterOptionSearchText}
        optionSearchHandler={optionSearchHandler}
        aiRequestDataRow={aiRequestDataRow}
      />
      <Modal
        open={deleteTab}
        onClose={handleCloseDeleteTab}
        className="confirm-modal-container"
      >
        <SimpleConfirmModal
          title={
            <span
              style={{
                fontSize: "17px",
                lineHeight: "1.75rem",
                fontWeight: "500",
              }}
            >
              {
                strings.modals.contractList.deleteContract
                  .confirmTextforTaggedQuestion
              }
            </span>
          }
          cancelBtn={strings.modals.contractList.deleteContract.no}
          confirmBtn={strings.modals.contractList.deleteContract.yes}
          confirmBtnClass={true}
          handleCloseModal={handleCloseDeleteTab}
          handleConfirm={deleteHandler}
        />
      </Modal>
    </>
  );
}

export default AIRequestTagQuestion;
