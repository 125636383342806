import React, { useContext, useEffect, useState } from 'react'
import {
    Box,
    Modal,
    Checkbox,
    TextField,
    IconButton,
    Button,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Stack,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { addDataRoomFolder, deleteSubFolder, editDataRoomFolder, getAllSubFolders } from '../../../../Services/DealRoom/DataRoomService';
import { useParams } from "react-router-dom";
import SnackbarContext from '../../../../Context/SnackbarContext';
import { useDispatch } from 'react-redux';
import { dataRoomActions } from '../../../../Slices/DataRoomSlice';
import strings from '../../../../localization';
import DataRoomFolderForm from '../../../../Components/Forms/Pages/DealRoom/DataRoom/DataRoomFolderForm';
import ConfirmModal from '../../../../Components/Modals/SimpleConfirmModal';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";


const SubFolderChild = ({ subFolderData, setActiveTab, fetchParent, parentIndex }) => {
    const params = useParams();
    const dispatch = useDispatch();

    const [subFolderChildArray, setSubFolderChildArray] = useState([])

    const { showMessage } = useContext(SnackbarContext);
    const [optionMenuAnchorEl, setOptionMenuAnchorEl] = useState(null);
    const [openFolderModal, setOpenFolderModal] = useState(false);

    const [isEditFolderName, setisEditFolderName] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openRedirectModal, setOpenRedirectModal] = useState(false)
    const [compCurrentFolderId, setCompCurrentFolderId] = useState('')
    const [compCurrentParentFolderId, setCompCurrentParentFolderId] = useState('')
    const [compCurrentFolderIndex, setCompCurrentFolderIndex] = useState('')
    const [compCurrentParentFolderIndex, setCompCurrentParentFolderIndex] = useState('')


    const handleMenuOpen = (event, folder, index) => {
        event.stopPropagation();

        dispatch(dataRoomActions.setIndex({
            currentFolderId: folder.id,
            parentFolderId: subFolderData?.id
        }))

        console.log("folder", folder)

        setCompCurrentFolderIndex(index)
        setCompCurrentParentFolderIndex(parentIndex)

        setCompCurrentFolderId(folder.id)
        setCompCurrentParentFolderId(subFolderData?.id)

        setOptionMenuAnchorEl(event.currentTarget);

        if (optionMenuAnchorEl !== event.currentTarget) {
            // Remove 'clicked' class from all elements
            const allClickedElements = document.querySelectorAll('.clicked');
            allClickedElements.forEach((element) => {
                element.classList.remove('clicked');
            });

            // Add 'clicked' class to the parent of parent box of the current box
            const currentBox = event.currentTarget;
            const parentOfParentBox = currentBox.parentElement.parentElement;
            parentOfParentBox.classList.add('clicked');
        }

    };
    const handleMenuClose = () => {
        setOptionMenuAnchorEl(null);

    };


    const handleAddBtn = (e) => {
        e.stopPropagation()

        setOptionMenuAnchorEl(null);

        setOpenFolderModal(!openFolderModal);
    };

    const handleEditBtn = (e) => {
        e.stopPropagation()

        setOptionMenuAnchorEl(null);

        setOpenFolderModal(!openFolderModal);
        setisEditFolderName(true)
    }

    const handleCloseFolderModal = () => {
        setOpenFolderModal(false);
    };

    const handlerOpenDeleteModal = (e) => {
        e.stopPropagation()
        setOpenDeleteModal(true)
        setOptionMenuAnchorEl(null);
    }

    const RedircectHanlder = () => {
        setOpenRedirectModal(false)
        setActiveTab("due-deligence");
    }

    const deleteSubFolderHandler = () => {

        deleteSubFolder(params?.id, compCurrentFolderId)
            .then(res => {
                if (res?.error?.response?.data === strings.modals.dueDeligenceRedircet.responseMessage) {
                    setOpenRedirectModal(true)
                    return;
                }
                setOpenDeleteModal(false)
                fetchParent(compCurrentParentFolderId, compCurrentParentFolderIndex)
                showMessage(strings.common.deleted);
            }).catch(err => {
                console.log("err", err)
            })
    }


    const submitFolderNameHandler = (data) => {
        if (isEditFolderName) {
            editDataRoomFolder(params?.id, compCurrentFolderId, data)
                .then((response) => {
                    handleCloseFolderModal()
                    // console.log(first)
                    if (response?.error?.response?.data === strings.modals.dueDeligenceRedircet.editResponseMessage) {
                        setOpenRedirectModal(true)
                        return;
                    }
                    fetchParent(compCurrentParentFolderId, compCurrentParentFolderIndex,"parent")
                    showMessage(strings.common.edited);
                });

        }
        else {
            addDataRoomFolder(params?.id, compCurrentFolderId, data)
                .then((response) => {
                    if (!response || !response.ok) {
                        return;
                    }
                    fetchAllSubFolders(compCurrentFolderId, compCurrentFolderIndex,'parent')
                    handleCloseFolderModal()
                    showMessage(strings.common.created);


                });
        }

    };

    const handleClick = (event) => {

        if (optionMenuAnchorEl !== event.currentTarget) {
            const allClickedElements = document.querySelectorAll('.clicked');
            allClickedElements.forEach((element) => {
                element.classList.remove('clicked');
            });

            const currentBox = event.currentTarget;
            const parentOfParentBox = currentBox.parentElement;
            parentOfParentBox.classList.add('clicked');
        }
    };



    const fetchAllSubFolders = (folderId, index,checkCall) => {
        console.log("first")
        dispatch(dataRoomActions.setIndex({
            currentFolderId: folderId,
            parentFolderId: subFolderData?.id
        }))

        setCompCurrentFolderIndex(index)
        setCompCurrentParentFolderIndex(parentIndex)

        setCompCurrentFolderId(folderId)
        setCompCurrentParentFolderId(subFolderData?.id)

        getAllSubFolders(params?.id, folderId)
            .then((response) => {
                if (!response || !response.ok) {
                    return;
                } else {
                    let tempSubFoldersData = structuredClone(subFolderChildArray);
                    tempSubFoldersData[index].subFolders = response.data
                    if(!checkCall){
                        tempSubFoldersData[index].isFolderOpen = !tempSubFoldersData[index].isFolderOpen
                    }

                    setSubFolderChildArray(tempSubFoldersData)
                }
            })
            .catch((err) => {
                console.log("error", err);
            });

    };

    useEffect(() => {
        if (subFolderData?.subFolders?.length > 0) {
            let tempData = subFolderData.subFolders.map((item) => {
                item.isFolderOpen = false
                return item
            })
            setSubFolderChildArray(tempData)
        }
    }, [subFolderData])



    return (
        <>
            {subFolderChildArray?.map((subfolder, index) => (
                <Box
                    ml={2}
                    key={index}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ cursor: "pointer" }}

                    >
                        <Box display="flex" alignItems="flex-start"
                            onClick={(e) => {
                                handleClick(e);
                                fetchAllSubFolders(subfolder.id, index,'')
                            }}
                        >
                            {subfolder.isFolderOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                            <IconButton sx={{ '&.MuiIconButton-root': { padding: '5px 8px 0 0 !important', } }}>
                                <img src="/images/icons/folder-black.png" alt="folder" />
                            </IconButton>
                            <Typography sx={{
                                '&.MuiTypography-root': {
                                    lineHeight: 'normal',
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                    padding: '5px 0 0 0'
                                }
                            }}>{subfolder?.name}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                className={"tree-icon-button"}
                                aria-haspopup="true"
                                style={{ marginLeft: "auto" }}
                                onClick={(event) => {
                                    handleMenuOpen(event, subfolder, index);
                                }}
                            >
                                <MoreVert />
                            </IconButton>

                            <Menu
                                id="folder-context-menu"
                                anchorEl={optionMenuAnchorEl}
                                open={Boolean(optionMenuAnchorEl)}
                                onClose={() => handleMenuClose()}
                            >
                                <MenuItem
                                    onClick={(e) => handleAddBtn(e)}
                                >
                                    <ListItemIcon>
                                        <img src={"/images/icons/menu/Add.png"} />
                                    </ListItemIcon>
                                    <ListItemText inset primary={"Add sub-folder"} />
                                </MenuItem>

                                <>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <img src={"/images/icons/menu/Share.png"} />
                                        </ListItemIcon>
                                        <ListItemText inset primary={"Share"} />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(e) => handleEditBtn(e)}
                                    >
                                        <ListItemIcon>
                                            <img src={"/images/icons/menu/Edit.png"} />
                                        </ListItemIcon>
                                        <ListItemText inset primary={"Rename"} />
                                    </MenuItem>
                                    <MenuItem onClick={(e) => handlerOpenDeleteModal(e)}>
                                        <ListItemIcon>
                                            <img src={"/images/icons/menu/Delete.png"} />
                                        </ListItemIcon>
                                        <ListItemText inset primary={"Delete"} />
                                    </MenuItem>
                                </>
                            </Menu>
                        </Box>

                    </Box>
                    {subfolder.isFolderOpen &&
                        <SubFolderChild
                            subFolderData={subfolder}
                            setActiveTab={setActiveTab}
                            fetchParent={fetchAllSubFolders}
                            parentIndex={index}
                        />
                    }

                </Box>
            ))}

            <Modal
                open={openFolderModal}
                onClose={handleCloseFolderModal}
                className="confirm-modal-container"
            >
                <DataRoomFolderForm
                    handleCloseModal={handleCloseFolderModal}
                    handleConfirm={submitFolderNameHandler}
                    isEditFolderName={isEditFolderName}
                />
            </Modal>
            <Modal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                className="confirm-modal-container"
            >
                <ConfirmModal
                    title={"Are you sure you want to delete ?"}
                    cancelBtn={strings.modals.adminDealRoom.cancel}
                    confirmBtn={strings.modals.adminDealRoom.ok}
                    handleCloseModal={() => setOpenDeleteModal(false)}
                    handleConfirm={() => deleteSubFolderHandler()}
                    contentTextStyle={{ fontWeight: "600", fontSize: "22px" }}
                    buttonsStyles={{ borderRadius: "10px !important" }}
                />
            </Modal>
            <Modal
                open={openRedirectModal}
                onClose={() => setOpenRedirectModal(false)}
                className="confirm-modal-container"
            >
                <ConfirmModal
                    title={strings.modals.dueDeligenceRedircet.redirectConfirmationTitle}
                    cancelBtn={strings.modals.adminDealRoom.cancel}
                    confirmBtn={strings.modals.adminDealRoom.ok}
                    handleCloseModal={() => setOpenRedirectModal(false)}
                    handleConfirm={() => RedircectHanlder()}
                    contentTextStyle={{ fontWeight: "600", fontSize: "22px" }}
                    buttonsStyles={{ borderRadius: "10px !important" }}
                />
            </Modal>
        </>
    )
}

export default SubFolderChild