import history from '../history';
import { clearUserData } from './OAuth';
import axios from 'axios';
import HttpMethod from "../Constants/Base/HttpMethod";

const Axios = (function () {

    let instance;

    function createInstance() {
        return axios.create({
            baseURL: process.env.REACT_APP_BASE_URL
        });
    }

    return {
        getInstance: function () {

            if (!instance) {
                instance = createInstance();
            }

            instance.defaults.headers.common['Authorization'] = getToken();
            instance.all = axios.all;

            return instance;
        }
    }
})();

Axios.getInstance().interceptors.response.use(response => {
    response.ok = response.status >= 200 && response.status < 300;

    return response;
}, async error => {

    const { response: { status } } = error;

    if (status === 404) {
        history.push('/not-found');
    }
    else if (status === 500) {
        history.push('/error');
    }
    else if (status === 401) {
        history.push('/forbidden');
        clearUserData();
    }
    else if (status === 403) {
        history.push('/');
        return error;
    }

    return {
        ok: false,
        error
    };
});

export async function request(url, data = [], method = HttpMethod.GET, options = {}) {

    try {
        return await connect(url, data, method, options);
    }
    catch {
        //window.location = '/500';
    }

}

export async function connect(url, data, method, options) {

    switch (method) {
        case HttpMethod.GET: {
            if (url.includes("/export/zip")) {
                options['responseType'] = "blob"
                return await Axios.getInstance().get(url + makeParametersList(data), options);
            }
            return await Axios.getInstance().get(url + makeParametersList(data), options);
        }
        case HttpMethod.POST:
            {
                if (url.includes("/export/zip")) {
                    options['responseType'] = "blob"
                    return Axios.getInstance().post(url, data, options);

                }
                return Axios.getInstance().post(url, data, options);
            }

        case HttpMethod.PUT: return Axios.getInstance().put(url, data, options);
        case HttpMethod.DELETE: return Axios.getInstance().delete(url, options);
        case HttpMethod.PATCH: return Axios.getInstance().patch(url, data, options);
    }
}

export function makeParametersList(parameters) {
    let parametersList = `?`;

    Object.keys(parameters).map((key, index) => (
        parametersList += parameters[key] ? `${key}=${parameters[key]}&` : ''
    ));

    parametersList = parametersList.slice(0, -1);

    return parametersList === '?' ? '' : parametersList;
}

export function getToken() {

    return 'Bearer ' + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
}

export function getUserFromLocalStorage() {

    let user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
}
