import React from 'react';
import {useForm} from "react-hook-form";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import TemplatePage from "../../../Components/DataPage/TemplatePage";
import TabPageContext, {
    FilterDefaults,
    TabPageOptionDefaults
} from "../../../Context/TabPageContext";
import strings from '../../../localization';
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeHeaderTitle} from "../../../Slices/HeaderSlice";
import PageState from "../../../Constants/Base/PageState";
import Template  from './Template';
import {getAllTemplates} from "../../../Services/Template/TemplateService";
import {getAdminTemplateFilter, adminTemplateDefaultValue} from "../../../Constants/Admin/Template";
import {sortByOrder} from "../../../Util/DnDUtil";

const filters = [
    {
        name: 'status',
        label: 'Active',
        options: getAdminTemplateFilter(),
        nameKey: 'name',
        valueKey: 'id',
        defaultValue: adminTemplateDefaultValue()
    }
]

const formRules = {
    'name': {required: true}
}

const AdminTemplates = (props) => {

    const dispatch = useDispatch();
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tabPageOptions, setTabPageOptions] = useState(TabPageOptionDefaults);
    const [templates, setTemplates] = useState([]);

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;

    const value = {
        tabPageOptions, setTabPageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeHeaderTitle(strings.pages.adminCategories.title));
        fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    const fetch = () => {

        getAllTemplates(filter).then(response => {
            if(!response || !response.ok) {
                return;
            }
            
            setTemplates(sortByOrder(response.data))
        })
    }

    const onFinish = () => {
        fetch();
    }

    const onSubmit = (data) => {
    }

    return (
        <TabPageContext.Provider value={value}>
            <TemplatePage 
                onFinish={() => onFinish()} 
                filter={filter} 
                filters={filters}
                addBtnText={strings.pages.adminTemplates.newTemplate}
                onSubmit={handleSubmit(onSubmit)} 
                tabData={
                    <Template 
                        onFinish={() => onFinish()}
                        templates={templates}
                        data={data}
                        fetch={fetch}
                    />
                }
            />
        </TabPageContext.Provider>
    )
};

export default AdminTemplates;
