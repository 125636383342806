import {useEffect} from "react";
import {useQuery} from "../../Util/RouterUtil";
import {activateUser} from "../../Services/User/UserService";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setAuth} from "../../Slices/AuthSlice";
import UserType from "../../Constants/User/UserType";
import {isProfileCompleted, isTrial} from "../../Util/PermissionUtil";
import { setUserToLocalStorage, setTokenToLocalStorage, clearUserData } from "../../Base/OAuth";

const ActivateUser = () => {

    const query = useQuery();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        activate();
    }, []);


    const activate = () => {
        const token = query.get('token');

        activateUser(token).then(response => {
            
            if(!response || !response.ok || !response.data?.token || !response.data?.user) {
                navigate('/login');
                return;
            }

            clearUserData();

            setUserToLocalStorage(response.data.user)

            setTokenToLocalStorage(response.data.token, response.data.token);

            dispatch(setAuth(response.data.user));

            if (response.data.user.userProfileType === UserType.GUEST || !isProfileCompleted(response.data.user)) {
                navigate('/registration-details', {state: {userProfileType: response.data.user.userProfileType}});
            } else {
                console.log(response.data)
                if(isTrial(response.data.user) && response.data.user.userProfileType !== UserType.COMPANY_USER) {
                    navigate('/pricing-and-package');
                    return;
                }

                navigate('/my-details', {state: {firstTime: true}});
            }
        });
    }

    return ''
}

export default ActivateUser;