import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import strings from "../../localization";
import { FormProvider, useForm } from "react-hook-form";
import TextFieldControl from "../../Components/Controls/Inputs/TextFieldControl";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { addQuestion } from "../../Services/AI/AIQuestionService";
import {
  addChatConversation,
  getAllChatConversationsForQuestion,
  addChatWithClauseConversation,
} from "../../Services/AI/AIConversationService";
import { getAllRecommendedByTemplateId } from "../../Services/AI/TemplateClauseAlternativesService";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  setAllConversations,
  setValueForAllConversations,
  updateConversation,
} from "../../Slices/ConversationSlice";
import { setClauseAllChoices } from "../../Slices/ClauseAllChoicesSlice";
import TypingLoader from "../../Components/Modals/TypingLoader";

const AIChat = ({
  chatID,
  setChatID,
  setSelectedQuestion,
  onClose,
  onNewTab,
  onNewConversation,
  fetchQuestions,
  className,
  template,
  addNewDataInContract = () => { },
}) => {
  const { allConversations } = useSelector((state) => state.conversations);
  const { clauseAllChoices } = useSelector((state) => state.clauseAllChoices);
  console.log("allConversations", allConversations)

  const dispatch = useDispatch();
  const { id } = useParams();
  const url = window?.location?.pathname;
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const form = useForm();

  var optionsArray = [];

  const {
    formData,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = form;

  const [allAlternatives, setAllAlternatives] = useState([]);
  const [alternativesSteps, setAlternativesSteps] = useState([]);
  const [searchQuestion, setSearchQuestion] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [responseQuestion, setResponseQuestion] = useState("");

  const conversationRef = useRef(null);

  const scrollToChatBottom = () => {
    conversationRef.current?.scrollIntoView({ behavior: "smooth" });
  };


  useEffect(() => {
    scrollToChatBottom();
  }, [allConversations]);

  useEffect(() => {
    let alternatives = [];
    let alternativesSteps = [];

    if (template) {
      getAllRecommendedByTemplateId(template.id).then((response) => {
        if (!response || !response.ok) {
          return;
        }

        response.data.forEach((recommended) => {
          const index = alternatives.findIndex(
            (item) =>
              item?.templateQuestionOptionClause?.templateQuestionOption?.id ===
              recommended?.templateClauseAlternatives
                ?.templateQuestionOptionClause?.templateQuestionOption?.id
          );

          if (index !== -1) {
            alternatives[index] = {
              ...recommended?.templateClauseAlternatives,
              contents: [
                ...alternatives[index].contents,
                {
                  content: recommended?.templateClauseAlternatives.content,
                  id: recommended?.templateClauseAlternatives.id,
                },
              ],
            };
            return;
          }

          alternatives = [
            ...alternatives,
            {
              ...recommended?.templateClauseAlternatives,
              contents: [
                {
                  content: recommended?.templateClauseAlternatives.content,
                  id: recommended?.templateClauseAlternatives.id,
                },
              ],
            },
          ];
          alternativesSteps = [
            ...alternativesSteps,
            {
              id: recommended?.templateClauseAlternatives
                .templateQuestionOptionClause?.templateQuestionOption.id,
              step: 0,
            },
          ];
        });

        setAllAlternatives(alternatives);
        setAlternativesSteps(alternativesSteps);
      });
    }
  }, [template]);

  useEffect(() => {
    const fetchConversations = (chatID) => {
      dispatch(setValueForAllConversations([]));
      if (chatID) {
        getAllChatConversationsForQuestion(chatID)
          .then((response) => {
            const messages = response?.data?.messages || [];
            dispatch(
              setValueForAllConversations(
                messages.map((message) => ({
                  request: message?.request,
                  response: message?.response,
                  question: message?.question
                }))
              )
            );
          });
      }
    };
    fetchConversations(chatID);
  }, [chatID, dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessage(null);
    }, 2000);
    return () => clearTimeout(timer);
  }, [errorMessage]);

  const scrollToBottom = () => {
    var lastConversation = document.querySelector(
      ".conversation-container:last-child"
    );
    if (lastConversation) {
      lastConversation.scrollIntoView({ behavior: "smooth" });
    }
  };

  const adoptChangesInContract = (adoptedText) => {
    addNewDataInContract(adoptedText.replaceAll("\n", " "));
  };

  const onSubmit = (data) => {
    let questionData = { ...data, name: data.question };
    setSearchQuestion(data?.question);
    scrollToBottom();
    form.reset();
    dispatch(
      setAllConversations({
        request: data?.question,
        response: "Loading...",
        id: "",
        status: "",
      })
    );
    if (data?.question) {
      let conversationData = { ...data, question: data.question };
      submitConversation(conversationData, questionData);
      return;
    }
  };

  const submitConversation = async (data, questionData) => {
    const chatData = {
      ...data,
      id: chatID,
      contract_id: parseInt(id),
      token: `${token}`,
    };
    addChatConversation(chatData)
      .then(async (response) => {
        scrollToBottom();
        if (!response || !response.status) {
          scrollToBottom();
          setErrorMessage(`Error: ${response?.error?.response?.data?.detail}`);
          dispatch(
            updateConversation({
              question: response?.data?.question,
              request: searchQuestion,
              response: response?.error?.response?.data?.detail
                ? response?.error?.response?.data?.detail
                : `An unexpected error occured. Please try again.`,
              id: chatID,
              status: response?.error?.response?.status,
            })
          );
        } else if (response.data) {
          if (response.data?.options) {
            // setChatID(chatData.id);
            setResponseQuestion(response?.data?.response_question);
            dispatch(setClauseAllChoices(response?.data?.options));
            scrollToBottom();
          } else {
            scrollToBottom();
            dispatch(
              updateConversation({
                question: response?.data?.question,
                request: response?.data?.request,
                response: response?.data?.response,
                id: response?.data?.id,
                status: response?.status,
              })
            );
          }
          if (response.data.chat_id && chatID !== response.data.chat_id) {
            setChatID(response.data.chat_id);
            const question = { ...questionData, chatId: response.data.chat_id };
            addQuestion(question).then((addQuestionResponse) => {
              if (!addQuestionResponse || !addQuestionResponse.ok) {
                return;
              } else if (fetchQuestions) {
                fetchQuestions();
              }
              setSelectedQuestion(data.question);
            });
          }
        }
      });
  };

  const handleChoiceSelection = async (choice, data) => {
    setErrorMessage(null);
    const choiceClauseData = {
      question: data,
      id: chatID,
      name: choice,
    };
    dispatch(setClauseAllChoices([]));
    const choiceData = await addChatWithClauseConversation(choiceClauseData);
    if (choiceData?.status === 200) {
      scrollToBottom();
      dispatch(
        updateConversation({
          question: choiceData?.data?.question,
          request: searchQuestion,
          response: choiceData?.data?.response,
          id: "",
          status: "",
        })
      );
      if (choiceData?.data?.chat_id && chatID !== choiceData?.data?.chat_id) {
        setChatID(choiceData?.data?.chat_id);
        const question = {
          name: choiceClauseData.question,
          chatId: choiceData?.data?.chat_id,
        };
        addQuestion(question).then((addQuestionResponse) => {
          if (!addQuestionResponse || !addQuestionResponse.ok) {
            return;
          } else if (fetchQuestions) {
            fetchQuestions();
          }
          setSelectedQuestion(data.question);
        });
      }
    } else {
      scrollToBottom();
      setErrorMessage(
        `An unexpected error occurred: ${choiceData?.error?.response?.data}`
      );
      dispatch(
        updateConversation({
          request: searchQuestion,
          response: `Unable to fetch the data. Please try again.`,
          id: "",
          status: "",
        })
      );
    }
  };

  const RenderArrayChoices = ({ clauseAllChoices }) => {
    for (var key in clauseAllChoices) {
      if (clauseAllChoices.hasOwnProperty(key)) {
        optionsArray.push(key);
      }
    }
    return (
      <div style={{ maxWidth: "100%", padding: "0 1rem", marginLeft: "8rem" }}>
        <p style={{ fontWeight: "500" }}>{responseQuestion}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "1rem 0.5rem",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {clauseAllChoices &&
            Object.values(clauseAllChoices)?.slice(0, 4)?.map((choice, index) => {
              if (choice !== "") {
                return (
                  <Button
                    key={index}
                    onClick={() =>
                      handleChoiceSelection(optionsArray[index], searchQuestion)
                    }
                    sx={{
                      padding: "0.75rem 1rem",
                      borderRadius: "0.25rem",
                      background: "#6193D7",
                      color: "#FFF",
                      cursor: "pointer",
                      transition: "0.3s all ease-in-out",
                      fontWeight: "600",
                      "&:hover": {
                        background: "#4a78ba", // Darken the color on hover
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                  >
                    {choice}
                  </Button>
                );
              }
              return null;
            })}
        </div>
      </div>
    );
  };

  const handleOnKeyPress = (event) => {
    // clearErrors("question");

    if (event.key.toLowerCase() === "enter") {
      event.preventDefault();

      setValue("question", event.target.value);

      if (form.getValues()?.question) {
        onSubmit(form.getValues());
        return;
      }
      setError("question");
    }
  };

  const handleSelectNextAlternative = (id, maxSteps) => {
    const index = alternativesSteps.findIndex((item) => item.id === id);

    if (alternativesSteps[index].step + 1 !== maxSteps) {
      const updatedList = [...alternativesSteps];
      updatedList[index] = {
        ...updatedList[index],
        step: updatedList[index].step + 1,
      };
      setAlternativesSteps(updatedList);
    }
  };

  const handleSelectPreviousAlternative = (id) => {
    const index = alternativesSteps.findIndex((item) => item.id === id);

    if (alternativesSteps[index].step + 1 > 1) {
      const updatedList = [...alternativesSteps];
      updatedList[index] = {
        ...updatedList[index],
        step: updatedList[index].step - 1,
      };
      setAlternativesSteps(updatedList);
    }
  };

  const renderAlternatives = () => {
    let result = [];

    allAlternatives.forEach((alternative) => {
      let step = 0;
      let index = 0;

      if (alternativesSteps.length > 0) {
        step = alternativesSteps.filter(
          (item) =>
            item.id ===
            alternative.templateQuestionOptionClause.templateQuestionOption.id
        )[0].step;
        index = alternativesSteps.findIndex(
          (item) =>
            item.id ===
            alternative.templateQuestionOptionClause.templateQuestionOption.id
        );
      }

      result.push(
        <div className={"alternative-container"}>
          <div className={"select-contents-container"}>
            <Button
              onClick={() =>
                handleSelectPreviousAlternative(
                  alternative.templateQuestionOptionClause
                    .templateQuestionOption.id
                )
              }
            >
              <ArrowForwardIosIcon
                className="arrow-icon"
                style={{ transform: "rotate(180deg)" }}
              />
            </Button>
            <div style={{ fontFamily: "sans-serif" }}>
              <span>
                {alternativesSteps.length > 0
                  ? alternativesSteps[index]?.step + 1
                  : 0}
              </span>
              /<span>{alternative?.contents?.length}</span>
            </div>
            <Button
              onClick={() =>
                handleSelectNextAlternative(
                  alternative.templateQuestionOptionClause
                    .templateQuestionOption.id,
                  alternative.contents.length
                )
              }
            >
              <ArrowForwardIosIcon
                className="arrow-icon"
                style={{ marginLeft: "2px" }}
              />
            </Button>
          </div>
          <div className={"alternative-content-container"}>
            <div className={"alternative-title"}>{alternative.title}</div>
            <div className={"alternative-content"}>
              {alternative.contents[step].content}
            </div>
          </div>
        </div>
      );
    });

    return result;
  };



  const RenderConversation = ({ conversationData }) => {
    return (
      <>
        {conversationData &&
          conversationData?.map((conversation, index) => (
            <div
              className={"conversation-container"}
              key={index}
              ref={conversationRef}
            >
              <div className={"conversation-question-container"}>
                <img src="/images/icons/profile-icon-chat.png" alt="" />
                <div className={"conversation-question"}>
                  {conversation?.request}
                </div>
              </div>

              <div className={"conversation-reply-container"}>
                <img
                  src="/apple-touch-icon.png"
                  style={{ marginBottom: "auto" }}
                  alt=""
                />
                {console.log("insideLoop", conversation)}
                {conversation?.response === "Loading..." ? (
                  <TypingLoader />
                ) : (
                  <>
                    <div className={"conversation-question"}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: conversation?.response,
                        }}
                      />
                      {url.toString().includes("contract-preview") && !conversation?.question && (
                        <Button
                          className="default-btn"
                          variant="contained"
                          onClick={() =>
                            adoptChangesInContract(conversation?.response)
                          }
                        >
                          Add to contract
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
      </>
    );
  };

  return (
    <div
      className={
        className ? "ai-chat-container " + className : "ai-chat-container"
      }
    >
      <div className="ai-chat-buttons-container">
        {onClose && (
          <Button className="close-button" onClick={() => onClose()}>
            <img src={"/images/icons/close.png"} alt="close" />
          </Button>
        )}

        <div className="ai-buttons-container">
          {onNewConversation && (
            <Button
              variant="contained"
              className="new-conversation"
              color="secondary"
              onClick={onNewConversation}
            >
              <img src="/images/icons/comment.png" alt="comment" />
              {strings.pages.aiTab.newConversation}
            </Button>
          )}

          {onNewTab && (
            <Button
              className="default-btn open-new-tab-button"
              variant="contained"
              onClick={() => onNewTab(true)}
            >
              Open in new tab&nbsp;&nbsp;&nbsp;
              <img src="/images/icons/open-new-tab.png" alt="open-new-tab" />
            </Button>
          )}
        </div>
      </div>
      {allAlternatives.length > 0 && (
        <div className="ai-chat-conversation">{renderAlternatives()}</div>
      )}

      {allConversations && (
        <div className="ai-chat-conversation">
          <RenderConversation conversationData={allConversations} />
          <div className="ai-chat-choices-div">
            {clauseAllChoices &&
              Object.entries(clauseAllChoices).length > 0 && (
                <RenderArrayChoices clauseAllChoices={clauseAllChoices} />
              )}
          </div>
        </div>
      )}

      <FormProvider {...form}>
        <form id="ai-chat-form" className="form" action="#">
          <div className="ai-chat-input-question">
            <TextFieldControl
              name="question"
              control={formData}
              defaultValue=""
              rules={{ required: true }}
              fullWidth
              margin="normal"
              error={Boolean(errors.question)}
              onKeyPress={handleOnKeyPress}
              icon={
                <img
                  src={"/images/icons/send.png"}
                  alt=""
                  onClick={handleSubmit(onSubmit)}
                />
              }
            />
            {strings.pages.aiTab.questionInfo}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AIChat;
