import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../../../Slices/PageSlice";
import PageSizeState from "../../../../Constants/Base/PageSizeState";
import strings from "../../../../localization";
import DetailsForm from '../../../../Components/Forms/Pages/User/UserType/CompanyDetails/DetailsForm';
import {getCompanyEmployeesNumberDefault} from '../../../../Constants/User/CompanyEmployeesNumber';

const CompanyDetails = ({onSubmitCompanyDetails, userTypeExist, loggedUser, selectUserTypePage}) => {

    const emptyCompanyDetailsObj = {
        companyType: loggedUser ? loggedUser.company.companyType : '',
        companyName: loggedUser ? loggedUser.company.companyName : '',
        jurisdiction: loggedUser ? loggedUser.company.jurisdiction : '',
        jurisdictionOther: loggedUser ? loggedUser.company.jurisdictionOther : '',
        address: loggedUser ? loggedUser.company.address : '',
        number: loggedUser ? loggedUser.company.number : '',
        director: loggedUser ? loggedUser.company.director : false,
        shareholder: loggedUser ? loggedUser.company.shareholder : false,
        employee: loggedUser ? loggedUser.company.employee : false,
        employeesNumber: loggedUser ? loggedUser.company.employeesNumber : getCompanyEmployeesNumberDefault(),
        description: loggedUser ? loggedUser.company.description : '',
        sector: loggedUser ? loggedUser.company.sector : '',
        sectorOther: loggedUser ? loggedUser.company.sectorOther : '',
        companyTurnover: loggedUser ? loggedUser.company.companyTurnover : '',
        mainGoal: loggedUser ? loggedUser.company.mainGoal?.split('|') : '',
        mainGoalCustom: loggedUser ? loggedUser.company.mainGoalCustom : ''
    }

    const dispatch = useDispatch();
    const [finalObj, setFinalObj] = useState(emptyCompanyDetailsObj)

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.FULL));
    }, [])


    const onSubmit = (data) => {

        onSubmitCompanyDetails({
            ...finalObj,
            ...data,
            companyType: data.companyType ? data.companyType.name : '',
            description: data.description ? data.description.name : '',
            jurisdiction: data.jurisdiction ? data.jurisdiction.name : '',
            mainGoal: data.mainGoal ? data.mainGoal : '',
            sector: data.sector ? data.sector.name : '',
            companyTurnover: data.companyTurnover ? data.companyTurnover.name : '',
        })
    }

    return (
        <div className='user-type-form-contaier'>
            <div class="logo-container">
                <img className="logo-img" src='/images/logo.png'/>
            </div>

            <div className="form-container">
                <h1 className="main-title">{strings.forms.registration.userType.companyDetailsForm.companyDetails}</h1>
                <p className="title-description">{strings.forms.registration.userType.companyDetailsForm.tellUsAboutYourself}</p>

                <DetailsForm
                    finalObj={finalObj}
                    selectUserTypePage={selectUserTypePage}
                    setFinalObj={setFinalObj}
                    userTypeExist={userTypeExist}
                    loggedUser={loggedUser}
                    onSubmit={onSubmit}
                />
            </div>
        </div>
    )
}

export default CompanyDetails;