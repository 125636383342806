import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import strings from "../../localization";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { convertUTCToLocalTime, formatDateWithTime } from "../../Util/DateUtil";
import {
  getAllContractContent,
  getContractContent,
  restoreContractContent,
} from "../../Services/Contract/ContractContentService";
import Tooltip from "@mui/material/Tooltip";

const HistoryList = ({
  setDisplayForms,
  contract,
  setContent,
  fetch,
  isOwner,
  setIsOpenContractOptionMenu,
}) => {
  const [revisions, setRevisions] = useState([]);
  const location = useLocation();

  useEffect(() => {
    getAllContractContent(contract.id, location?.hash?.substring(1)).then(
      (response) => {
        if (!response || !response.ok) {
          return;
        }
        setRevisions(response.data);
      }
    );
  }, [contract]);

  const handleCloseForms = () => {
    setDisplayForms(false);
    setIsOpenContractOptionMenu(false);
  };

  const handleView = (revision) => {
    getContractContent(revision.id).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      setContent(response.data);
    });
  };

  const handleRestore = (revision) => {
    restoreContractContent(
      contract.id,
      revision.id,
      location?.hash?.substring(1)
    ).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      fetch();
    });
  };

  const renderHistory = () => {
    let result = [];

    for (let revision of revisions) {
      result.push(
        <div key={"revision-stage-" + result.length} className="revision">
          <div className="data">
            <span className="date">
              {formatDateWithTime(
                convertUTCToLocalTime(new Date(revision.dateCreated))
              )}
            </span>

            <div className="users-container">
              <span className="user">
                {revision.externalUserFullName
                  ? revision.externalUserFullName
                  : revision.user?.fullName}
              </span>
            </div>
          </div>
          <div className="buttons">
            <Button
              className="history-btn view-btn"
              variant="contained"
              onClick={() => handleView(revision)}
            >
              {strings.forms.contractList.historyForm.view}
            </Button>

            {!revision.active && (
              <Button
                className="history-btn restore-btn"
                variant="contained"
                disabled={!isOwner}
                onClick={() => handleRestore(revision)}
              >
                {strings.forms.contractList.historyForm.restore}
              </Button>
            )}
          </div>
        </div>
      );
    }
    return result;
  };

  return (
    <div className="form-container history-form">
      <div className="header">
        <h1 className="title info-icon-title">
          {strings.forms.contractList.historyForm.title}
          <Tooltip
            title={strings.forms.contractList.historyForm.historyTooltip}
            placement="top"
            arrow
          >
            <IconButton>
              <img src="/images/icons/info.png" />
            </IconButton>
          </Tooltip>
        </h1>
        <CloseIcon className="close-icon" onClick={handleCloseForms} />
      </div>
      <div className="border"></div>
      <div className="history-container">{renderHistory()}</div>
    </div>
  );
};

export default HistoryList;
