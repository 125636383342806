import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import strings from "../../../../localization";
import InviteCollaboratorForm from "./InviteCollaboratorForm";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import {
  addContractUser,
  contractUserChangeReviewer,
  contractUserChangeSignatory,
  deleteContractUser,
  getAllContractUsersByContract,
} from "../../../../Services/Contract/ContractUserService";
import ContractUserType from "../../../../Constants/Contract/ContractUserType";
import Modal from "@mui/material/Modal";
import SignForm from "../../../Forms/Pages/ContractList/SignForm";
import { useSelector } from "react-redux";
import ContractStatus from "../../../../Constants/Contract/ContractStatus";
import Tooltip from "@mui/material/Tooltip";
import UserType from "../../../../Constants/User/UserType";
import RejectSignatureForm from "./RejectSignatureForm";
import SimpleConfirmModal from "../../../Modals/SimpleConfirmModal";

const DisplayCollaboratorType = {
  ALL: 1,
  REVIEWER: 2,
  SIGNATORY: 3,
};

const CollaboratorsForm = ({
  setDisplayForms,
  contract,
  fetchContract,
  isUserOwner,
  editor,
  uploadedContent,
  setPlacePDFSignaturePlaceholder,
  setCollaboratorForPlacement,
  placePDFSignaturePlaceholder,
  setPlacePDFNamePlaceholder,
  placePDFNamePlaceholder,
  setPlacePDFDatePlaceholder,
  placePDFDatePlaceholder,
  collaboratorForPlacement,
  setCollaboratorsTab,
  setParentCollaborators,
  forceRender,
  externalUser,
  setIsOpenContractOptionMenu,
  setSignatures,
  signatures,
  addRectangle,
}) => {
  const form = useForm();
  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [sendInviteForm, setSendInviteForm] = useState(false);
  const [autocompleteUsers, setAutocompleteUsers] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [displayType, setDisplayType] = useState(DisplayCollaboratorType.ALL);
  const [openSignModal, setOpenSignModal] = useState(false);
  const [signCollaborator, setSignCollaborator] = useState(null);
  const [openRejectionForm, setOpenRejectionForm] = useState(false);
  const [selectedSignatureForReject, setSelectedSignatureForReject] =
    useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [userAlreadyInvite, setUserAlreadyInvite] = useState(false);
  const [collaboratorExceedAlert, setCollaboratorExceedAlert] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const currentUser = externalUser?.user ? externalUser?.user : user;

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    fetch();
  }, [contract]);

  useEffect(() => {
    renderUserOptions();
    renderCollaborators();
  }, [users]);

  const fetch = () => {
    getAllContractUsersByContract(contract.id, location.hash.substring(1)).then(
      (response) => {
        if (!response || !response.ok) {
          return;
        }

        setCollaborators(response.data);
        setParentCollaborators(response.data);
      }
    );
  };

  const handleCloseForms = () => {
    setDisplayForms(false);
    setCollaboratorsTab(false);
    forceRender();
    setIsOpenContractOptionMenu(false);
    setSignatures([]);
  };

  const renderUserOptions = () => {
    let usersDisplay = getAutocompleteUsers().map((e) => {
      let optionEl = (
        <div className="user-option-element">
          <img
            className="image"
            src={
              e.profileImage
                ? e.profileImage
                : "/images/icons/profile-default-small.png"
            }
            alt="profile"
          />
          <div className="text-container">
            <span className="name">{e.fullName}</span>
            <span className="email">{e.email}</span>
          </div>
        </div>
      );

      let display = {
        id: e.id,
        email: e.email,
        fullName: e.fullName,
        display: optionEl,
      };

      return display;
    });

    setAutocompleteUsers([...usersDisplay]);
  };

  const collaboratorExists = async (data) => {
    let exists = false;
    await Promise.all(
      collaborators.map((collaborator) => {
        if (collaborator.email === data.email) {
          setUserAlreadyInvite(true);
          exists = true;
        }
      })
    );
    return exists;
  };

  const submitCollaborator = async (data) => {
    const externalCollaborators = collaborators.filter(
      (obj) => obj.user === null
    );
    console.log("external Collaborators");
    if (externalCollaborators.length === 5) {
      setCollaboratorExceedAlert(true);
    }

    collaboratorExists(data).then((exists) => {
      if (!exists) {
        addContractUser({
          userId: data.id,
          contractId: contract.id,
          contractUserType: ContractUserType.COLLABORATORS,
          reviewer:
            contract.state === ContractStatus.PENDING_REVIEW ||
            contract.state === ContractStatus.CREATED,
          signatory: contract.state > ContractStatus.PENDING_REVIEW,
          email: data.email,
          fullName: data.fullName,
          companyName: data.company ? data.company.companyName : "",
        }).then((response) => {
          if (!response || !response.ok) {
            return;
          }

          fetch();
          fetchContract();

          setShowForm(false);
        });
      }
    });
  };

  const handleCloseTag = (collaborator) => {
    deleteContractUser(collaborator.id).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      fetch();
    });
  };

  const handleCheckReviewer = (isChecked, collaboratorId) => {
    contractUserChangeReviewer({
      id: collaboratorId,
      value: isChecked,
    }).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      fetch();
      fetchContract();
    });
  };

  const handleCheckSignatory = (isChecked, collaboratorId) => {
    contractUserChangeSignatory({
      id: collaboratorId,
      value: isChecked,
    }).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      fetch();
      fetchContract();
    });
  };

  const handleOpenSignModal = (collaborator) => {
    setOpenSignModal(true);
    setSignCollaborator(collaborator);
  };

  const handleCloseSignModal = () => {
    setOpenSignModal(false);
  };

  const handleSignConfirm = () => {
    setOpenSignModal(false);

    fetch();
    fetchContract();
  };

  const showReviewers = () => {
    if (displayType === DisplayCollaboratorType.REVIEWER) {
      setDisplayType(DisplayCollaboratorType.ALL);
      return;
    }

    setDisplayType(DisplayCollaboratorType.REVIEWER);
  };

  const showSignatories = () => {
    if (displayType === DisplayCollaboratorType.SIGNATORY) {
      setDisplayType(DisplayCollaboratorType.ALL);
      return;
    }

    setDisplayType(DisplayCollaboratorType.SIGNATORY);
  };

  const renderCollaboratorsList = (collaborators) => {
    let result = [];

    for (let collaborator of collaborators) {
      result.push(
        <div
          className="collaborator"
          key={"collaborator-item-" + collaborator.id}
        >
          {renderSingleCollaborator(collaborator)}
        </div>
      );
    }

    return result;
  };

  const renderCollaborators = () => {
    let collaboratorsResult = [];

    if (
      contract.state === ContractStatus.PENDING_REVIEW ||
      contract.state === ContractStatus.CREATED
    ) {
      collaboratorsResult = collaborators.filter((x) => x.reviewer);
    } else {
      collaboratorsResult = collaborators;
    }

    let companies = [];

    for (let collaborator of collaboratorsResult) {
      if (
        !collaborator.companyName ||
        collaborator.userProfileType === UserType.INDIVIDUAL
      ) {
        continue;
      }

      const company = companies.indexOf(collaborator.companyName);

      if (company > -1) {
        continue;
      }

      companies.push(collaborator.companyName);
    }

    let result = [];

    for (let company of companies) {
      result.push(
        <React.Fragment>
          <p className="partie">{company}</p>
          {renderCollaboratorsList(
            collaboratorsResult.filter((x) => x.companyName === company)
          )}
        </React.Fragment>
      );
    }

    const individuals = collaboratorsResult.filter((x) => !x.companyName);

    if (individuals && individuals.length > 0) {
      result.push(
        <React.Fragment>
          <p className="partie">Individuals</p>
          {renderCollaboratorsList(individuals)}
        </React.Fragment>
      );
    }

    return <div className="collaborator-container">{result}</div>;
  };

  const handlePlaceSignature = (collaborator) => {
    setCollaboratorForPlacement(collaborator);
    setPlacePDFSignaturePlaceholder(true);
    setPlacePDFDatePlaceholder(false);
    setPlacePDFNamePlaceholder(false);
  };

  const handlePlaceName = (collaborator) => {
    setCollaboratorForPlacement(collaborator);
    setPlacePDFNamePlaceholder(true);
    setPlacePDFDatePlaceholder(false);
    setPlacePDFSignaturePlaceholder(false);
  };

  const handlePlaceDate = (collaborator) => {
    setCollaboratorForPlacement(collaborator);
    setPlacePDFDatePlaceholder(true);
    setPlacePDFSignaturePlaceholder(false);
    setPlacePDFNamePlaceholder(false);
  };

  const checkSameUser = (collaborator) => {
    if (externalUser) {
      if (!externalUser.isSigned && externalUser.id === collaborator.id) {
        return false;
      }
      return true;
    }
    return currentUser?.id !== collaborator.user?.id;
  };

  const renderSingleCollaborator = (collaborator) => {
    // console.log("uploadedContent : ", uploadedContent);
    // console.log("collaborator : ", collaborator);
    // console.log("currentUser : ", currentUser);
    // console.log("isUserOwner : ", isUserOwner);
    // console.log("contract : ", contract);

    return (
      <React.Fragment>
        <div style={{ width: "95%" }}>
          <div className="content" key={"collaborator-" + collaborator.id}>
            <div className="profile">
              <img
                className="image"
                src={
                  collaborator.user && collaborator.user.profileImage
                    ? collaborator.user.profileImage
                    : "/images/icons/profile-default-small.png"
                }
                alt=""
              />
              <div className="profile-info">
                <span className="name text">{collaborator.fullName}</span>
                <Tooltip title={collaborator.email}>
                  <span className="email">{collaborator.email}</span>
                </Tooltip>
              </div>
            </div>

            {contract.state === ContractStatus.PENDING_E_SIGN &&
              collaborator.user &&
              currentUser?.id === collaborator.user?.id &&
              collaborator.signatory &&
              !uploadedContent &&
              !collaborator.isSigned && (
                <div className="sign-container">
                  <Button
                    className="default-btn sign"
                    variant="contained"
                    onClick={() => handleOpenSignModal(collaborator)}
                  >
                    <span className="btn-txt">
                      {strings.forms.contractList.collaboratorsForm.sign}
                    </span>
                  </Button>
                </div>
              )}

            {uploadedContent && (
              <div>
                {contract.state < ContractStatus.PENDING_E_SIGN &&
                  isUserOwner && (
                    <div className="sign-container">
                      {contract.state === ContractStatus.REVIEWED &&
                      placePDFSignaturePlaceholder &&
                      collaboratorForPlacement.id === collaborator.id ? (
                        <Button
                          className="cancelPlacement-btn sign"
                          variant="contained"
                          onClick={() => setPlacePDFSignaturePlaceholder(false)}
                        >
                          <span className="btn-txt">Cancel placement</span>
                        </Button>
                      ) : (
                        <Button
                          className="placeSignature-btn"
                          variant="contained"
                          onClick={() => handlePlaceSignature(collaborator)}
                        >
                          <img
                            src="/images/icons/signature.png"
                            className="placeSign-icons"
                            alt="Place Signature"
                          />
                          <span className="">
                            {
                              strings.forms.contractList.collaboratorsForm
                                .placeSignature
                            }
                          </span>
                        </Button>
                      )}
                    </div>
                  )}

                {contract.state < ContractStatus.PENDING_E_SIGN &&
                  isUserOwner && (
                    <div className="sign-container">
                      {contract.state === ContractStatus.REVIEWED &&
                      placePDFNamePlaceholder &&
                      collaboratorForPlacement.id === collaborator.id ? (
                        <Button
                          className="cancelPlacement-btn sign"
                          variant="contained"
                          onClick={() => setPlacePDFNamePlaceholder(false)}
                        >
                          <span className="btn-txt">Cancel placement</span>
                        </Button>
                      ) : (
                        <Button
                          className="placeSignature-btn"
                          variant="contained"
                          onClick={() => handlePlaceName(collaborator)}
                        >
                          <img
                            src="/images/icons/user.png"
                            className="placeSign-icons"
                            alt="Place Name"
                          />
                          <span className="">
                            {
                              strings.forms.contractList.collaboratorsForm
                                .placeName
                            }
                          </span>
                        </Button>
                      )}
                    </div>
                  )}

                {contract.state < ContractStatus.PENDING_E_SIGN &&
                  isUserOwner && (
                    <div className="sign-container">
                      {contract.state === ContractStatus.REVIEWED &&
                      placePDFDatePlaceholder &&
                      collaboratorForPlacement.id === collaborator.id ? (
                        <Button
                          className="cancelPlacement-btn sign"
                          variant="contained"
                          onClick={() => setPlacePDFDatePlaceholder(false)}
                        >
                          <span className="btn-txt">Cancel placement</span>
                        </Button>
                      ) : (
                        <Button
                          className="placeSignature-btn"
                          variant="contained"
                          onClick={() => handlePlaceDate(collaborator)}
                        >
                          <img
                            src="/images/icons/date.png"
                            className="placeSign-icons"
                            alt="Place Date"
                          />
                          <span className="">
                            {
                              strings.forms.contractList.collaboratorsForm
                                .placeDate
                            }
                          </span>
                        </Button>
                      )}
                    </div>
                  )}
              </div>
            )}

            {contract.state === ContractStatus.PENDING_E_SIGN &&
              collaborator.signatory &&
              collaborator.isSigned && (
                <div className="sign-container-result">
                  <div className={"signed-status-gray"}>Signed</div>
                </div>
              )}

            {contract.state === ContractStatus.PENDING_E_SIGN &&
              checkSameUser(collaborator) &&
              collaborator.signatory &&
              collaborator.placeholderPlaced &&
              !collaborator.isSigned && (
                <div className="sign-container-result">
                  <div className={"signed-status-blue"}>Waiting signature</div>
                </div>
              )}

            {/*{(contract.state <= ContractStatus.PENDING_REVIEW && (collaborator.reviewer || collaborator.signatory)) &&*/}
            {/*    <span*/}
            {/*        className="sent-invite-msg">{strings.forms.contractList.collaboratorsForm.inviteHasBeenSent}</span>*/}
            {/*}*/}
          </div>
        </div>
        {isUserOwner && (
          <IconButton
            sx={{
              alignItems: "baseline",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            disabled={
              placePDFSignaturePlaceholder &&
              placePDFNamePlaceholder &&
              placePDFDatePlaceholder &&
              collaboratorForPlacement.id === collaborator.id
            }
          >
            <CloseIcon
              className="close-icon"
              onClick={() => handleCloseTag(collaborator)}
            />
          </IconButton>
        )}
      </React.Fragment>
    );
  };

  const getAutocompleteUsers = () => {
    let result = [];

    for (let user of users) {
      const collaborator = collaborators.find(
        (x) => x.user && x.user.id === user.id
      );

      if (collaborator) {
        continue;
      }

      result.push(user);
    }

    return result;
  };

  return (
    <div className="form-container collaborators-form">
      <div
        className={
          contract.state === ContractStatus.APPROVED ? "disabled-contract" : ""
        }
      >
        <FormProvider {...form}>
          <div className="header">
            <h1 className="title info-icon-title">Share</h1>
            <CloseIcon className="close-icon" onClick={handleCloseForms} />
          </div>
          <div className="border"></div>

          {!showForm &&
            isUserOwner &&
            (contract.state === ContractStatus.PENDING_REVIEW ||
              contract.state === ContractStatus.CREATED) && (
              <div
                onClick={() => setShowForm(true)}
                className={"collaborator-button"}
              >
                <img src={"/images/icons/blue-plus.png"} alt="" />
                Share with people
              </div>
            )}

          {!showForm &&
            isUserOwner &&
            contract.state > ContractStatus.PENDING_REVIEW &&
            contract.state < ContractStatus.PENDING_E_SIGN && (
              <div
                onClick={() => setShowForm(true)}
                className={"collaborator-button"}
              >
                <img src={"/images/icons/blue-plus.png"} alt="" /> Add Signee
              </div>
            )}

          {
            <React.Fragment>
              {isUserOwner && showForm && (
                <InviteCollaboratorForm
                  addSignee={
                    contract.state > ContractStatus.PENDING_REVIEW &&
                    contract.state < ContractStatus.PENDING_E_SIGN
                  }
                  setSendInviteForm={setSendInviteForm}
                  submitCollaborator={submitCollaborator}
                  uploadedContent={uploadedContent}
                  contract={contract}
                  setShowForm={setShowForm}
                />
              )}
            </React.Fragment>
          }

          <div className="tag-list">{renderCollaborators()}</div>

          <Modal
            open={openSignModal}
            onClose={handleCloseSignModal}
            className="confirm-modal-container"
          >
            <SignForm
              editor={editor}
              setOpenSignModal={setOpenSignModal}
              handleSignConfirm={handleSignConfirm}
              collaborator={signCollaborator}
              contract={contract}
            />
          </Modal>

          <Modal
            open={openRejectionForm}
            onClose={() => setOpenRejectionForm(false)}
            className="confirm-modal-container"
          >
            <RejectSignatureForm
              closeModal={() => setOpenRejectionForm(false)}
              collaborator={selectedSignatureForReject}
              fetch={fetch}
            />
          </Modal>

          <Modal
            open={showErrorModal}
            onClose={() => setShowErrorModal(false)}
            className="confirm-modal-container"
          >
            <SimpleConfirmModal
              title={"Please select an option"}
              confirmBtn={"Ok"}
              handleConfirm={() => setShowErrorModal(false)}
            />
          </Modal>

          <Modal
            open={userAlreadyInvite}
            onClose={() => setUserAlreadyInvite(false)}
            className="confirm-modal-container"
          >
            <SimpleConfirmModal
              title={"That person has already been added"}
              confirmBtn={"Ok"}
              handleConfirm={() => setUserAlreadyInvite(false)}
            />
          </Modal>

          <Modal
            open={collaboratorExceedAlert}
            onClose={() => setCollaboratorExceedAlert(false)}
            className="confirm-modal-container"
          >
            <SimpleConfirmModal
              title={
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#333",
                    lineHeight: "2",
                  }}
                >
                  Please note that inviting this user will exceed your monthly
                  limit of 5 external users. Any additional external user beyond
                  the initial 5 will incur a charge of £1.50 per month. These
                  costs will be added to your monthly direct debit at the end of
                  each billing cycle.
                </div>
              }
              confirmBtn={"Ok"}
              handleConfirm={() => setCollaboratorExceedAlert(false)}
            />
          </Modal>
        </FormProvider>
      </div>
    </div>
  );
};

export default CollaboratorsForm;
