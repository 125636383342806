import React, { useEffect } from 'react';
import strings from '../../../../../localization';
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import {FormProvider} from "react-hook-form";
import UserForm from './UserForm';
import CategoryForm from './CategoryForm';
import ContractForm from './ContractForm';
import PriceForm from './PriceForm';
import SubPackageForm from './SubPackageForm';
import {getCurrency, currencyDefaultValue} from '../../../../../Constants/Base/Currency';

const AdminPackageForm = ({
                              values,
                              setValue,
                              errors,
                              data,
                              form,
                              getValues,
                              onUserDataChanged,
                              onCategoryChanged,
                              onContractChanged,
                              setFeaturesFieldsNames,
                              setAdditionalFeaturesFieldsNames,
                              icon, 
                              setIcon
                          }) => {

    useEffect(() => {
        setValue('currency', currencyDefaultValue())
    })

    return (
        <FormProvider {...form}>

            <div className="package-form-content form-row form-row-with-inputs">
                <SubPackageForm 
                    data={data}
                    values={values}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    setFeaturesFieldsNames={setFeaturesFieldsNames}
                    setAdditionalFeaturesFieldsNames={setAdditionalFeaturesFieldsNames}
                    icon={icon}
                    setIcon={setIcon}
                />

                <div className="right">
                    <UserForm
                        data={data}
                        values={values}
                        errors={errors}
                        onUserDataChanged={onUserDataChanged}
                        getValues={getValues}
                    />

                    <CategoryForm
                        data={data}
                        values={values}
                        errors={errors}
                        onCategoryChanged={onCategoryChanged}
                    />

                    <PriceForm 
                        data={data}
                        values={values}
                        setValue={setValue}
                        errors={errors}
                    />

                    <ContractForm
                        data={data}
                        values={values}
                        errors={errors}
                        onContractChanged={onContractChanged}
                    />
                </div>
            </div>
        </FormProvider>
    )
}

export default AdminPackageForm;