import {FormProvider, useForm} from "react-hook-form";
import TextFieldControl from "../../../Components/Controls/Inputs/TextFieldControl";
import strings from "../../../localization";
import TextareaControl from "../../../Components/Controls/Inputs/TextareaControl";
import {Button, Select} from "@mui/material";
import QuestionFormat, {getQuestionFormat} from "../../../Constants/Admin/QuestionFormat";
import MenuItem from "@mui/material/MenuItem";
import React, {useContext, useEffect, useState} from "react";
import {
    addTemplateQuestion,
    editTemplateQuestion, getAllTemplateQuestions,
    getTemplateQuestion
} from "../../../Services/Template/TemplateQuestionService";
import SnackbarContext from "../../../Context/SnackbarContext";
import QuestionOptions from "./QuestionOptions";
import CheckBoxControl from "../../../Components/Controls/Inputs/CheckBoxControl";
import {
    getAllTemplateQuestionOptions,
    getTemplateQuestionOption
} from "../../../Services/Template/TemplateQuestionOptionService";
import {WarningAmber} from "@mui/icons-material";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import { Editor } from 'ckeditor5-custom-build/build/ckeditor';

const toolbarConfig = {
    items: [
        'heading', '|',
        'fontfamily', 'fontsize', '|',
        'alignment', '|',
        'fontColor', 'fontBackgroundColor', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
        'link', '|',
        'outdent', 'indent', '|',
        'bulletedList', 'numberedList', 'todoList', '|',
        'code', 'codeBlock', '|',
        'insertTable', '|',
        'uploadImage', 'blockQuote', '|',
        'undo', 'redo'
    ],
    link: { addTargetToExternalLinks: true },
    shouldNotGroupWhenFull: true,
    removePlugins: ['RealTimeCollaborativeEditing', 'PresenceList', 'RealTimeCollaborativeComments', 'RealTimeCollaborativeTrackChanges']
}

const AddTemplateQuestion = ({section, selectedQuestion, cancel, editor, setUpdateQuestions, refreshSections, updateQuestions}) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [questionType, setQuestionType] = useState(QuestionFormat.TEXT_FIELD);
    const [question, setQuestion] = useState(null);
    const {showMessage} = useContext(SnackbarContext);
    const [explanationEditor, setExplanationEditor] = useState(null);

    useEffect(() => {

        fetch();
    }, [selectedQuestion, explanationEditor]);


    const fetch = () => {
        if(!selectedQuestion) {

            getAllTemplateQuestions(section.id).then(response => {

                if(!response || !response.ok) {
                    return;
                }

                setValue('code', 'q-' + section.id + '-' + (response.data ? response.data.length + 1 : 1));
            })

            return;
        }

        getTemplateQuestion(selectedQuestion.id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            getAllTemplateQuestionOptions(response.data.id).then(optionsResponse => {
                setQuestion({
                    ...response.data,
                    options: optionsResponse.data ? optionsResponse.data : []
                });

                setValue('name', response.data.name);
                setValue('description', response.data.description);
                setValue('code', response.data.code);
                setValue('isRequired', response.data.isRequired);
                setValue('questionType', response.data.questionType);
                setQuestionType(response.data.questionType);

                if(explanationEditor) {
                    explanationEditor.setData(response.data.description)
                }
            })
        })
    }

    const save = (data) => {

        if(!question) {

            let selection = editor.model.document.selection
            const range = selection.getFirstRange();
            let selectedText = '';
            for (const item of range.getItems()) {

                if(!item.data) {
                    continue;
                }

                selectedText = item.data
            }

            if(selectedText !== '') {
                editor.model.change( writer => {
                    editor.model.insertContent( writer.createText('[' + data.code + ']'), range )
                } );
            }

            addTemplateQuestion({
                ...data,
                description: explanationEditor ? explanationEditor.getData() : '',
                templateStepId: section.id,
                questionType: questionType
            }).then(response => {

                if(!response || !response) {
                    return;
                }

                setQuestion(response.data);
                setValue('questionType', response.data.questionType)
                showMessage(strings.common.added);
                refreshSections();
            })
        }
        else {
            editTemplateQuestion({
                id: question.id,
                ...data,
                description: explanationEditor ? explanationEditor.getData() : '',
                questionType: questionType,
            }).then(response => {

                if(!response || !response) {
                    return;
                }

                setQuestion(response.data);
                setValue('questionType', response.data.questionType)
                showMessage(strings.common.saved);
                refreshSections();
                setUpdateQuestions(true)
            })
        }

        setUpdateQuestions(false)
    }

    const showOptions = (question) => {

        return question && (question.questionType === QuestionFormat.SELECT ||
            question.questionType === QuestionFormat.AUTOCOMPLETE ||
            question.questionType === QuestionFormat.RADIO_BUTTON ||
            question.questionType === QuestionFormat.TOGGLE ||
            question.questionType === QuestionFormat.COMPANY_HOUSE ||
            question.questionType === QuestionFormat.CHECKBOX ||
            question.questionType === QuestionFormat.SWITCH ||
            question.questionType === QuestionFormat.SLIDER ||
            question.questionType === QuestionFormat.CHECKBOX_GROUP)
    }

    return <FormProvider {...form}>
        <div className="subsection-form">

            <TextFieldControl
                name='name'
                control={data}
                defaultValue=''
                fullWidth
                margin="normal"
                error={Boolean(errors.question)}
                helperText={errors.question && strings.forms.admin.adminTemplateForm.question}
                placeholder={strings.forms.admin.adminTemplateForm.question}
            />
            <h5>Explanation</h5>
            <div style={{background: 'white', padding: '10px', marginTop: '15px', minHeight: '150px'}}>
                {
                    <CKEditor
                        onReady={ editor => {
                            editor.ui.getEditableElement()?.parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                            );
                            setExplanationEditor(editor)
                        } }
                        // onError={ ( { willEditorRestart } ) => {
                        //     if ( willEditorRestart ) {
                        //         explanationEditor.ui.view.toolbar.element.remove();
                        //     }
                        // } }
                        data={getValues()['description']}
                        editor={ Editor }
                        config={toolbarConfig}
                    />
                }
            </div>
            <TextareaControl
                name='code'
                control={data}
                defaultValue=''
                rules={{required: false}}
                fullWidth
                margin="normal"
                error={Boolean(errors.code)}
                helperText={errors.code && strings.forms.admin.adminTemplateForm.code}
                placeholder={strings.forms.admin.adminTemplateForm.code}
            />

            <Select
                className="select-box"
                value={questionType}
                name='questionType'
                onChange={(e) => { setQuestionType(e.target.value) }}
            >
                {getQuestionFormat().map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                                <span
                                    className={""}>{e.name}</span>
                    </MenuItem>
                ))}
            </Select>

            <div className='checkbox-container'>
                <CheckBoxControl
                    name={'isRequired'}
                    control={data}
                    defaultValue={question ? question.isRequired : false}
                    label={strings.forms.admin.adminTemplateForm.required}
                    setValue={setValue}
                />
            </div>
            {
                getValues()['questionType'] && getValues()['questionType'] !== questionType &&
                <p className='question-reset-warning'>
                    <WarningAmber/><b>You will loose all the content if you change the question type</b>
                </p>
            }
            <Button className="default-btn btn" variant="contained" onClick={handleSubmit(save)}>
                <span className="btn-txt">{strings.forms.admin.adminTemplateForm.save}</span>
            </Button>

            {
                cancel &&
                <Button className="neutral-btn btn" variant="contained" onClick={cancel}>
                    <span className="btn-txt">{strings.forms.admin.adminTemplateForm.cancel}</span>
                </Button>
            }

            {
                showOptions(question) &&
                <QuestionOptions question={question} editor={editor}/>
            }

        </div>

    </FormProvider>
}

export default AddTemplateQuestion;