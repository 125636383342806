import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import React from "react";
import InputAdornment from '@mui/material/InputAdornment';

const TextFieldControl = (props) => {

    return <Controller
        name={props.name}
        control={props.data}
        defaultValue={props.defaultValue}
        rules={props.rules}
        render={({ field }) =>
            <TextField {...field}
                InputLabelProps={{ shrink: Boolean(field.value) }}
                size={props.size ? props.size : 'small'}
                fullWidth={props.fullWidth ? props.fullWidth : true}
                type={props.type}
                margin={props.margin ? props.margin : 'normal'}
                error={props.error}
                helperText={props.helperText}
                label={props.label}
                onKeyPress={(e) => props.onKeyPress ? props.onKeyPress(e) : ''}
                disabled={props.disabled}
                className={props.shortInput ? "textfield-control short-input" : "textfield-control"}
                placeholder={props.placeholder}
                inputRef={input => {
                    if (props.inputRef && input != null) {
                        input.focus()
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment className="textfield-icon">
                            {props.icon ? props.icon : null}
                        </InputAdornment>
                    ),
                    readOnly: props.readOnly ? props.readOnly : false
                }}
            />
        }
    />
}

export default TextFieldControl;