import React from "react";
import {Link, useLocation} from "react-router-dom";
import MenuState from "../../Constants/Base/MenuState";
import strings from "../../localization";
import {useDispatch, useSelector} from "react-redux";
import HeaderButtons from "./HeaderButtons";
import Permissions from "../../Constants/Base/Permissions";
import {checkPermission} from "../../Util/PermissionUtil";

const getHeaderClass = (menuState) => {

    if(menuState === MenuState.SHORT) {
        return 'short';
    }

    return '';
}

const Header = () => {

    const menuState = useSelector((state) => state.navigation.menuState)
    const dispatch = useDispatch();
    const location = useLocation();
    const currentUser = useSelector((state) => state.auth.user)

    const isCurrentPath = (path) => {
        return location.pathname === path;
    }

    const isCurrentPathMatch = (path) => {
        if (location.pathname.includes(path)) {
            return true;
        }
        return false;
    }

    return <div id='header' className={ getHeaderClass(menuState) }>

        {
           <div className='left'>
                { (isCurrentPath('/') 
                    || isCurrentPath('/dashboard') 
                    || isCurrentPath('/my-details') 
                    || isCurrentPath('/pricing-and-package') 
                    || isCurrentPath('/subscription-details')) &&
                    <>
                        <Link to={'/dashboard'} 
                            className={ isCurrentPath('/') || isCurrentPath('/dashboard') ? 'header-navigation-link active' : 'header-navigation-link'} >
                            <span className="header-link">{strings.dashboardHeader.dashboard}</span>
                        </Link>

                        <Link to={'/my-details'} 
                            className={ isCurrentPath('/my-details') ? 'header-navigation-link active' : 'header-navigation-link'} >
                            <span className="header-link">{strings.dashboardHeader.myDetails}</span>
                        </Link>

                        {
                            checkPermission(currentUser, Permissions.PRICE_AND_PACKAGE.HEADER) &&
                            <Link to={'/pricing-and-package'}
                                  className={ isCurrentPath('/pricing-and-package') ? 'header-navigation-link active' : 'header-navigation-link'} >
                                <span className="header-link">{strings.dashboardHeader.pricingAndPackage}</span>
                            </Link>
                        }

                        {
                            checkPermission(currentUser, Permissions.SUBSCRIPTION.HEADER) &&
                            <Link to={'/subscription-details'}
                                  className={ isCurrentPath('/subscription-details') ? 'header-navigation-link active' : 'header-navigation-link'} >
                                <span className="header-link">{strings.dashboardHeader.subscriptionDetails}</span>
                            </Link>
                        }
                    </>
                }

                { ( isCurrentPath('/admin-all-templates')) &&
                    <>
                        <Link to={'/admin-all-templates'} 
                            className={ isCurrentPath('/admin-all-templates') ? 'header-navigation-link active' : 'header-navigation-link'} >
                            <span className="header-link">{strings.adminHeader.templates}</span>
                        </Link>
                    </>
                }

                { ( isCurrentPath('/admin-template-management-categories') 
                    || isCurrentPath('/admin-template-management-packages') 
                    || isCurrentPath('/admin-template-management-templates')
                    || isCurrentPath('/admin-template-management-AIAdminData')
                    || isCurrentPath('/admin-template-management-AdminDealRoom')
                    || isCurrentPathMatch('/AIData')
                    || isCurrentPathMatch('/AIDataCreate')
                    || isCurrentPathMatch('/AIRequestData')
                    || isCurrentPath('/add-admin-template')) &&
                    <>
                        <Link to={'/admin-template-management-categories'} 
                            className={ isCurrentPath('/admin-template-management-categories') ? 'header-navigation-link active' : 'header-navigation-link'} >
                            <span className="header-link">{strings.adminHeader.categories}</span>
                        </Link>

                        <Link to={'/admin-template-management-packages'} 
                            className={ isCurrentPath('/admin-template-management-packages') ? 'header-navigation-link active' : 'header-navigation-link'} >
                            <span className="header-link">{strings.adminHeader.packages}</span>
                        </Link>

                        <Link to={'/admin-template-management-templates'} 
                            className={ (isCurrentPath('/admin-template-management-templates') || isCurrentPath('/add-admin-template')) ? 'header-navigation-link active' : 'header-navigation-link'} >
                            <span className="header-link">{strings.adminHeader.templates}</span>
                        </Link>
                        <Link to={'/admin-template-management-AIAdminData'} 
                            className={ isCurrentPath('/admin-template-management-AIAdminData') ? 'header-navigation-link active' : 'header-navigation-link'} >
                            <span className="header-link">{strings.adminHeader.aiData}</span>
                        </Link>
                        <Link to={'/admin-template-management-AdminDealRoom'} 
                            className={ isCurrentPath('/admin-template-management-AdminDealRoom') ? 'header-navigation-link active' : 'header-navigation-link'} >
                            <span className="header-link">{strings.adminHeader.dataRoom}</span>
                        </Link>
                    </>
                }
            </div> }

        <HeaderButtons />

    </div>
};
export default Header;
