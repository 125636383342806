import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
    Box,
    IconButton,
    Typography
} from "@mui/material";
import React, { useState } from 'react';
import SubFolder from './SubFolder';

const MainFolders = ({ index, folderName,
    subFoldersData,
    onClick,
    setActiveTab,

}) => {
    const [isFolderOpen, setIsFolderOpen] = useState(true);

    const mainFolderToggleHandler = (event) => {
        setIsFolderOpen(!isFolderOpen);

        const allClickedElements = document.querySelectorAll('.clicked');
        allClickedElements.forEach((element) => {
            element.classList.remove('clicked');
        });
        const currentBox = event.currentTarget;
        const parentOfParentBox = currentBox;
        parentOfParentBox.classList.add('clicked');

    }


    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                className={`dataroom-left-folder-parent`}
                onClick={(e) => mainFolderToggleHandler(e)}
                key={index}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {isFolderOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    <IconButton>
                        <img src="/images/icons/folder.png" alt="folder" />
                    </IconButton>
                    <Typography fontWeight={600} color={"#3D32CF"}
                        sx={{
                            cursor: "pointer",
                            '&.MuiTypography-root': {
                                lineHeight: 'normal',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word'
                            }
                        }}>
                        {folderName}
                    </Typography>
                </Box>
            </Box>
            {isFolderOpen && <SubFolder
                subFoldersData={subFoldersData}
                setActiveTab={setActiveTab}
                onClick={onClick}
            />}
        </>
    )
}

export default MainFolders