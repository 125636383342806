import { configureStore } from "@reduxjs/toolkit";
import navigationReducer from "./Slices/NavigationSlice";
import pageReducer from "./Slices/PageSlice";
import loaderSlice from "./Slices/LoaderSlice";
import authSlice from "./Slices/AuthSlice";
import headerSlice from "./Slices/HeaderSlice";
import siteDataSlice from "./Slices/SiteDataSlice";
import notificationSlice from "./Slices/NotificationSlice";
import conversationSlice from "./Slices/ConversationSlice";
import clauseAllChoicesSlice from "./Slices/ClauseAllChoicesSlice";
import AiDataSlice from "./Slices/AiDataSlice";
import DataRoomSlice from "./Slices/DataRoomSlice";

export default configureStore({
  reducer: {
    navigation: navigationReducer,
    page: pageReducer,
    loader: loaderSlice,
    auth: authSlice,
    header: headerSlice,
    siteData: siteDataSlice,
    aiData:AiDataSlice,
    notifications: notificationSlice,
    conversations: conversationSlice,
    clauseAllChoices: clauseAllChoicesSlice,
    dataRoom:DataRoomSlice
  },
});
