import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Grid, Paper } from "@mui/material";
import strings from "../../localization";
import { useDispatch } from "react-redux";
import { changePageSizeState } from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import ResetPasswordForm from "../../Components/Forms/Pages/User/ResetPasswordForm";
import { confirmUserPassword, getUserWithResetPasswordToken } from "../../Services/User/UserService";
import { useQuery } from "../../Util/RouterUtil";
import { Snackbar } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import ValidationPatterns from './../../Constants/Base/ValidationPatterns';

const formRules = {
    'password': {
        required: { value: true, message: strings.forms.common.required },
        pattern: { value: ValidationPatterns.PASSWORD, message: strings.forms.resetPassword.passwordError }
    },
    'passwordRepeat': {
        required: { value: true, message: strings.forms.common.required },
        pattern: { value: ValidationPatterns.PASSWORD, message: strings.forms.resetPassword.passwordError }
    }
}

const ResetPassword = () => {

    const path = useLocation()

    const form = useForm();
    const { data, handleSubmit, formState: { errors }, setError } = form;
    const navigate = useNavigate();

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const query = useQuery();
    const token = query.get('token');

    useEffect(() => {
        fetch();
    }, [])

    const fetch = () => {
        getUserWithResetPasswordToken(token).then(response => {

            if (!response || !response.ok) {
                return;
            }

            if (!response.data.passwordResetRequestedAt) {
                return;
            }

            const expireDate = new Date(response.data.passwordResetRequestedAt)
            expireDate.setDate(expireDate.getDate() + 1);

            if (expireDate < new Date()) {
                showMessage(strings.pages.resetPassword.linkExpired, 'error')
                setTimeout(() => {
                    navigate('/reset-password-request-expired')
                }, 1500)
            }
        })
    }

    const showMessage = (message, alertType) => {
        setMessageSnackbar(message);
        setAlertType(alertType)
        setShowSnackbar(true);
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };

    const dispatch = useDispatch();
    dispatch(changePageSizeState(PageSizeState.FULL));

    const onSubmit = (data) => {
        if (data.password === data.passwordRepeat) {
            confirmUserPassword({
                ...data,
                token: token
            }).then(response => {

                if (!response || !response.ok) {
                    showMessage(strings.pages.resetPassword.errorResettingPassword, 'error')
                    return;
                }

                navigate('/reset-password-success')
            })
        } else {
            setError('passwordRepeat', { type: 'custom', message: strings.forms.resetPassword.passwordRepeatError })
        }
    }

    return <div id='reset-password' className={'full-form-page reset-password-page'}>
        <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
            <Alert severity={alertType} onClose={handleCloseSnackbar}>
                {messageSnackbar}
            </Alert>
        </Snackbar>
        <Grid item md={3.5} className="reset-password-grid ">
            <Paper className='paper'>

                <img className="logo-img" src='/images/logo.png' />
                {path.search.includes("dealRoomId")
                    ? <h1>{strings.pages.resetPassword.createPassword}</h1>
                    : <h1>{strings.pages.resetPassword.resetPassword}</h1>
                }

                <ResetPasswordForm
                    errors={errors}
                    data={data}
                    form={form}
                    onSubmit={handleSubmit(onSubmit)}
                    formRules={formRules}
                />

            </Paper>
        </Grid>
    </div>
};
export default ResetPassword;
