import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Button, IconButton, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import AddIcon from "@mui/icons-material/Add";
import Person2Icon from "@mui/icons-material/Person2";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import UserType from "../../../../Constants/User/UserType";
import TeamMemberForm from "../../../../Components/Forms/Pages/DealRoom/OverViewTab/TeamMemberForm";
import {
  closeDealroom,
  getDealRoomData,
  deleteDealRoomUser,
  addNewUserInDealRoom,
  updateUserInDealRoom,
  updateDealSummaryData,
  getProjectSubscription,
} from "../../../../Services/DealRoom/DealRoomService";
import ConfirmModal from "../../../../Components/Modals/ConfirmModal";
import UpdateDealSummaryForm from "../../../../Components/Forms/Pages/DealRoom/OverViewTab/UpdateDealSummaryForm";
import OverviewSummaryDetailsModal from "./OverviewSummaryDetailsModal";
import OverviewCommentSection from "./OverviewCommentSection";
import { getInitials } from "../../../../Util/NameInitials";
import { useNavigate, useParams } from "react-router-dom";
import strings from "../../../../localization";
import SnackbarContext from "../../../../Context/SnackbarContext";

const Overview = () => {
  const { showMessage } = useContext(SnackbarContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userDeletedSuccesModal, setUserDeletedSuccesModal] = useState(false);
  const [newUserAddedSuccessModal, setNewUserAddedSuccessModal] =
    useState(false);
  const [openEditDealSummaryModal, setOpenEditDealSummaryModal] =
    useState(false);
  const [openOverviewSummaryDetailsModal, setOpenOverviewSummaryDetailsModal] =
    useState(false);
  const [formData, setFormData] = useState({});
  const [dealRoomUserList, setDealRoomUserList] = useState([]);
  const [teamFormHeading, setTeamFormHeading] = useState("Add Team Member");
  const [overviewData, setOverviewData] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [btnText, setBtnText] = useState("Add");
  const [isDealRoomSubscriptionExpired, setIsDealRoomSubscriptionExpired] =
    useState(false);
  const [adminName, setAdminName] = useState("");
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    handleGetDealRoomData();
  }, []);

  useEffect(() => {
    getProjectSubscription(id).then((res) => {
      let validUntilData = new Date(res?.data?.validUntil);
      let todayData = new Date();
      if (todayData > validUntilData) {
        setIsDealRoomSubscriptionExpired(true);
      }
    });
  }, []);

  const openNewteamMemberFormModal = () => {
    setIsModalOpen(true);
    setTeamFormHeading("Add Team Member");
    setBtnText("Add");
  };

  const openEditTeamMemberFormModal = () => {
    setIsModalOpen(true);
    setTeamFormHeading("Edit Team Member");
    setBtnText("Save");
  };

  const handleUserDelete = () => {
    deleteDealRoomUser(id, selectedUser.id)
      .then((res) => {
        if (res) {
          handleGetDealRoomData();
          setOpenDeleteModal(false);
          setUserDeletedSuccesModal(true);
          showMessage("User deleted", "success");
        } else {
          showMessage("Error in deleting user", "error");
        }
      })
      .catch((err) => {
        console.log("error in deleteDealRoomUser", err);
      });
  };

  const handleGetDealRoomData = () => {
    getDealRoomData(id)
      .then((res) => {
        if (res.status === 200) {
          setOverviewData(res.data);
          setDealRoomUserList(res?.data?.teamMembers.slice(0, 4));
          let tempName = res?.data?.teamMembers.find((member) =>
            member.type === 1 ? member?.name : ""
          );
          let tempcurrentUser = res?.data?.teamMembers.find(
            (member) => member?.id === user?.id
          );
          setAdminName(tempName);
          setCurrentUser(tempcurrentUser);
        }
      })
      .catch((err) => {
        console.log("error in getDealRoomData", err);
      });
  };

  const addUserInDealRoom = (userData) => {
    const userExists = overviewData?.teamMembers.some(
      (user) => user.email === userData.email
    );

    if (userExists) {
      showMessage("User with the same email already exists", "error");
      return;
    }

    addNewUserInDealRoom(id, userData)
      .then((res) => {
        if (res.status === 204) {
          setIsModalOpen(false);
          handleGetDealRoomData();
          setNewUserAddedSuccessModal(true);
          showMessage("User added", "success");
        } else {
          showMessage("Error in adding user", "error");
        }
      })
      .catch((err) => {
        console.log("error in addNewUserInDealRoom", err);
      });
  };

  const updateDealRoomUser = (userData, userId) => {
    updateUserInDealRoom(id, userId, userData)
      .then((res) => {
        if (res.status === 204) {
          handleGetDealRoomData();
          setIsModalOpen(false);
          showMessage("User details updated", "success");
        } else {
          showMessage("Error in updating user", "error");
        }
      })
      .catch((err) => {
        console.log("error in updateUserInDealRoom", err);
      });
  };

  const updateDealSummaryDataHandle = (data) => {
    updateDealSummaryData(id, data)
      .then((res) => {
        if (res.status === 204) {
          handleGetDealRoomData();
          setOpenEditDealSummaryModal(false);
          showMessage("Data uploaded", "success");
        } else {
          showMessage("Error in uploading data", "error");
        }
      })
      .catch((err) => {
        console.log("error in updateDealSummaryData", err);
      });
  };

  const closeDealRoomHandler = () => {
    closeDealroom(id)
      .then((res) => {
        if (res.status === 204) {
          navigate("/virtualDealRoom");
          setIsDealRoomSubscriptionExpired(false);
          showMessage("Deal room closed");
        } else {
          showMessage("Error in closing deal room", "error");
        }
      })
      .catch((err) => {
        console.log("error in closeDealroom", err);
      });
  };

  return (
    <Box className="overview-wrapper" display="flex" flexDirection="column">
      <Box
        className="deal-summary-comment-wrapper"
        display="flex"
        justifyContent="space-between"
      >
        <Box className="deal-summary-box-parent">
          <Box
            className="deal-summary-box"
            display="flex"
            flexDirection="column"
          >
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5">Deal Summary</Typography>
              <Box>
                {user?.userProfileType === UserType.COMPANY_ADMIN && (
                  <>
                    <EditIcon
                      className="overview-icons"
                      sx={{ color: "#8494B1" }}
                      onClick={() => {
                        setFormData({
                          projectId: overviewData.id,
                          salePrice: overviewData.salePrice,
                          targetCompletionDate:
                            overviewData.targetCompletionDate,
                          expiryDate: overviewData.expiryDate,
                          buyerCompany: {
                            address: overviewData.buyerCompany.address,
                            name: overviewData.buyerCompany.name,
                            sector: overviewData.buyerCompany.sector,
                            type: overviewData.buyerCompany.type,
                          },
                        });
                        setOpenEditDealSummaryModal(true);
                      }}
                    />
                    <MoreVertOutlinedIcon
                      className="overview-icons"
                      onClick={() => setOpenOverviewSummaryDetailsModal(true)}
                    />
                  </>
                )}
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Box
                width={"50%"}
                display="flex"
                className="target-buyer-wrapper"
              >
                <Typography
                  className="deal-summary-chips-initial"
                  sx={{
                    backgroundColor: "#e4e4e4",
                    color: "#6193D7",
                  }}
                >
                  {getInitials(overviewData?.targetCompany?.name)}
                </Typography>
                <Box display="flex" flexDirection="column" ml={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {overviewData?.targetCompany?.name}
                  </Typography>
                  <Typography sx={{ color: "#6193D7" }} mt={1}>
                    The Target
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                  padding: "1rem",
                  borderRadius: "50%",
                  background: "#3D32CF",
                  zIndex: "10",
                }}
              >
                <EastOutlinedIcon sx={{ fontSize: "1.5rem", color: "white" }} />
              </Box>
              <Box
                display="flex"
                width={"50%"}
                className="target-buyer-wrapper"
              >
                <Typography
                  className="deal-summary-chips-initial"
                  sx={{
                    background: "#c8fcf1",
                    color: "#82F5DE",
                  }}
                >
                  {getInitials(overviewData?.buyerCompany?.name)}
                </Typography>
                <Box display="flex" flexDirection="column" ml={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {overviewData?.buyerCompany?.name}
                  </Typography>
                  <Typography sx={{ color: "#6193D7" }} mt={1}>
                    {overviewData.party === 0 ? "Seller" : "Buyer"}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display="flex"
              sx={{
                width: "calc(100% - 2rem)",
                padding: "1rem",
                marginTop: "2rem",
              }}
            >
              <Box display={"flex"} alignItems={"center"} width={"25%"} sx={{}}>
                <Box
                  sx={{
                    height: "1.5rem",
                    width: "1.5rem",
                    padding: "1rem",
                    borderRadius: "50%",
                    backgroundColor: "#ECF0F3",
                  }}
                >
                  <MonetizationOnIcon
                    sx={{ fontSize: "1.5rem", color: "#919191" }}
                  />
                </Box>
                <Box ml={2}>
                  <Typography sx={{ color: "#8494B1", fontWeight: "bold" }}>
                    Sale Price
                  </Typography>
                  <Typography sx={{ fontWeight: "500", color: "#283959" }}>
                    £ &nbsp;{overviewData?.salePrice}
                  </Typography>
                </Box>
              </Box>

              <Box
                display={"flex"}
                alignItems={"center"}
                sx={{
                  width: "calc(45% - 4rem)",
                  paddingLeft: "4rem",
                  borderLeft: "2px solid #e6e6e6",
                  borderRight: "2px solid #e6e6e6",
                }}
              >
                <Box
                  sx={{
                    height: "1.5rem",
                    width: "1.5rem",
                    padding: "1rem",
                    borderRadius: "50%",
                    backgroundColor: "#ECF0F3",
                  }}
                >
                  <CalendarMonthIcon
                    sx={{ fontSize: "1.5rem", color: "#919191" }}
                  />
                </Box>
                <Box ml={2}>
                  <Typography sx={{ color: "#8494B1", fontWeight: "bold" }}>
                    Target Completion Date
                  </Typography>
                  <Typography sx={{ fontWeight: "500", color: "#283959" }}>
                    {overviewData?.targetCompletionDate?.split("T")[0]}
                  </Typography>
                </Box>
              </Box>

              <Box
                display={"flex"}
                alignItems={"center"}
                sx={{
                  width: "calc(30% - 4rem)",
                  paddingLeft: "4rem",
                }}
              >
                <Box
                  sx={{
                    height: "1.5rem",
                    width: "1.5rem",
                    padding: "1rem",
                    borderRadius: "50%",
                    backgroundColor: "#ECF0F3",
                  }}
                >
                  <AdsClickIcon sx={{ fontSize: "1.5rem", color: "#919191" }} />
                </Box>
                <Box ml={2}>
                  <Typography sx={{ color: "#8494B1", fontWeight: "bold" }}>
                    Deal Room Expiry Date
                  </Typography>
                  <Typography sx={{ fontWeight: "500", color: "#283959" }}>
                    {overviewData?.expiryDate?.split("T")[0]}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="team-member-box">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={10}
              width={"100%"}
            >
              <Typography
                variant="h5"
                fontWeight="500"
                sx={{ borderBottom: "0.3rem #82F5DE solid" }}
              >
                Team Members
              </Typography>
              {user?.userProfileType === UserType.COMPANY_ADMIN && (
                <Button
                  className="default-btn btn"
                  onClick={openNewteamMemberFormModal}
                >
                  <AddIcon />
                  New Team Member
                </Button>
              )}
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              flexWrap={"wrap"}
              width={"100%"}
              mt={2}
              mb={2}
            >
              {dealRoomUserList?.map((teamMember) => (
                <Box
                  m={1}
                  mb={8}
                  sx={{
                    width: "calc(25% - 1rem)",
                  }}
                >
                  <Box
                    bgcolor={"white"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "3rem",
                      borderRadius: "100% 100% 0rem 0rem",
                    }}
                  >
                    <Box
                      className={"teamMember-profile-wrapper"}
                      sx={
                        teamMember?.party === 0
                          ? {
                              background: "#c8fcf1",
                            }
                          : {
                              background: "#9ec7ff",
                            }
                      }
                    >
                      <Person2Icon
                        sx={
                          teamMember?.party === 0
                            ? {
                                fontSize: "2.5rem",
                                color: "#82F5DE",
                              }
                            : {
                                fontSize: "2.5rem",
                                color: "#6193D7",
                              }
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    bgcolor={"white"}
                    sx={{
                      minHeight: "6rem",
                      borderRadius: "0rem 0rem 1rem 1rem",
                    }}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      pl={3}
                      pr={3}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Box
                          sx={
                            teamMember?.party === 0
                              ? {
                                  backgroundColor: "#82F5DE",
                                  width: "0.5rem",
                                  height: "0.5rem",
                                  borderRadius: "50%",
                                }
                              : {
                                  backgroundColor: "#6193D7",
                                  width: "0.5rem",
                                  height: "0.5rem",
                                  borderRadius: "50%  ",
                                }
                          }
                        ></Box>
                        <Typography
                          ml={1}
                          sx={
                            teamMember?.party === 0
                              ? { color: "#82F5DE" }
                              : { color: "#6193D7" }
                          }
                        >
                          {teamMember?.party === 0 ? "Buyer" : "Seller"}
                        </Typography>
                      </Box>
                      {user?.userProfileType === UserType.COMPANY_ADMIN &&
                        !teamMember.isOwner && (
                          <Box display={"flex"}>
                            <IconButton
                              onClick={() => {
                                openEditTeamMemberFormModal();
                                setSelectedUser(teamMember);
                              }}
                            >
                              <img
                                src="/images/icons/pen.png"
                                alt="edit-button"
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpenDeleteModal(true);
                                setSelectedUser(teamMember);
                              }}
                            >
                              <img
                                src="/images/icons/delete.png"
                                alt="edit-button"
                              />
                            </IconButton>
                          </Box>
                        )}
                    </Box>
                    <Box p={3}>
                      <Typography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {teamMember?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        mt={1}
                        sx={{ wordBreak: "break-all", minHeight: "3rem" }}
                      >
                        {teamMember?.email}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>

            {overviewData?.teamMembers?.length > 4 && (
              <Button
                className=""
                disabled={
                  dealRoomUserList?.length === overviewData?.teamMembers?.length
                }
                sx={{ color: "#3D32CF", textTransform: "none" }}
                onClick={() => setDealRoomUserList(overviewData?.teamMembers)}
              >
                See More
              </Button>
            )}
          </Box>
        </Box>
        <Box className="overview-comment-box">
          <OverviewCommentSection user={user} />
        </Box>
      </Box>

      {currentUser.type === 1 ? (
        <Modal
          open={isDealRoomSubscriptionExpired}
          onClose={() => closeDealRoomHandler()}
          className="confirm-modal-container"
        >
          <ConfirmModal
            contentText={`Your Subscription for ${overviewData?.name} has expired`}
            cancelBtn={"Close DealRoom"}
            confirmBtn={"Extend Subscription"}
            handleCloseModal={() => closeDealRoomHandler()}
            handleConfirm={() => navigate(`/virtualDealRoom/payment/${id}`)}
          />
        </Modal>
      ) : (
        <Modal
          open={isDealRoomSubscriptionExpired}
          onClose={() => closeDealRoomHandler()}
          className="confirm-modal-container"
        >
          <ConfirmModal
            contentText={`Your Subscription for ${overviewData?.name} has expired. Please contact ${adminName} to extend the deal room.`}
            confirmBtn={strings.modals.contractList.nonValidSubscription.ok}
            handleConfirm={() => navigate(`/virtualDealRoom`)}
          />
        </Modal>
      )}

      <Modal
        open={ismodalOpen}
        onClose={() => setIsModalOpen(false)}
        className="confirm-modal-container"
      >
        <TeamMemberForm
          dealRoomId={id}
          btnText={btnText}
          heading={teamFormHeading}
          selectedUser={selectedUser}
          setIsModalOpen={setIsModalOpen}
          setSelectedUser={setSelectedUser}
          addUserInDealRoom={addUserInDealRoom}
          updateDealRoomUser={updateDealRoomUser}
          handleCloseModal={() => setIsModalOpen(false)}
        />
      </Modal>

      <Modal
        open={openEditDealSummaryModal}
        onClose={() => setOpenEditDealSummaryModal(false)}
        className="confirm-modal-container"
      >
        <UpdateDealSummaryForm
          formData={formData}
          updateDealSummaryDataHandle={updateDealSummaryDataHandle}
          setOpenEditDealSummaryModal={setOpenEditDealSummaryModal}
          handleCloseModal={() => setOpenEditDealSummaryModal(false)}
        />
      </Modal>

      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        className="confirm-modal-container"
      >
        <ConfirmModal
          title="Are you sure you want to delete this Team member?"
          handleCloseModal={() => setOpenDeleteModal(false)}
          handleConfirm={handleUserDelete}
          cancelBtn="No"
          confirmBtn="Yes"
        />
      </Modal>

      <Modal
        open={userDeletedSuccesModal}
        onClose={() => setUserDeletedSuccesModal(false)}
        className="confirm-modal-container"
      >
        <ConfirmModal
          title="Team member has been deleted"
          handleCloseModal={() => setUserDeletedSuccesModal(false)}
          handleConfirm={() => setUserDeletedSuccesModal(false)}
          confirmBtn="Ok"
        />
      </Modal>

      <Modal
        open={newUserAddedSuccessModal}
        onClose={() => setNewUserAddedSuccessModal(false)}
        className="confirm-modal-container"
      >
        <ConfirmModal
          title="New Team Member has been added"
          handleCloseModal={() => setNewUserAddedSuccessModal(false)}
          handleConfirm={() => setNewUserAddedSuccessModal(false)}
          confirmBtn="Ok"
        />
      </Modal>

      <Modal
        open={openOverviewSummaryDetailsModal}
        onClose={() => setOpenOverviewSummaryDetailsModal(false)}
        className="confirm-modal-container"
      >
        <OverviewSummaryDetailsModal
          overviewData={overviewData}
          closeModal={() => setOpenOverviewSummaryDetailsModal(false)}
          closeDealRoomHandler={closeDealRoomHandler}
        />
      </Modal>
    </Box>
  );
};

export default Overview;
