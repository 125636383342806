import React, {useContext} from "react";
import strings from "../../localization";
import { Button } from "@mui/material";
import { deleteQuestion } from "../../Services/AI/AIQuestionService";
import SnackbarContext from "../../Context/SnackbarContext";

const AIHistory = ({
        handleClickQuestion, 
        handleNewQuestion, 
        setSelectedQuestion, 
        question,
        chatID, 
        questions, 
        fetchQuestions
    }) => {

    const { showMessage } = useContext(SnackbarContext);

    const handleDeleteQuestion = (e, id) => {
        e.stopPropagation();

        deleteQuestion(id).then((response) => {
            if(!response || !response.ok) {
                return;
            }

            if(id === question?.id) {
                setSelectedQuestion(null);
            }
            showMessage(strings.common.deleted);
            fetchQuestions();
        });
    }

    const renderHistoryQuestions = () => {
        let result = [];
    
        if(!questions) {
            return;
        }

        questions.map(response => {
            result.push(<div className={response?.chatId === chatID ? 'conversation-history active' : 'conversation-history'} onClick={() => handleClickQuestion(response)}>
                <p>{response.name}</p>
                <Button className="delete-icon" onClick={(e) => handleDeleteQuestion(e, response.id)}>
                    <img src="/images/icons/delete.png" />
                </Button>
            </div>);
        });

        return result;
    }

    return (
        <div className="ai-history-container">
            <div className="ai-history-new-conversation-button-container">
                <Button variant="contained" color="secondary" onClick={handleNewQuestion}>
                    <img src="/images/icons/comment.png" />
                    { strings.pages.aiTab.newConversation } 
                </Button>
                <div className="nav-border"></div>
            </div>
            <div className='conversation-history-container'>            
                { renderHistoryQuestions() }
            </div>
        </div>
    )
}

export default AIHistory;