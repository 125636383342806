import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Tooltip,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuState from "../../Constants/Base/MenuState";
import { changeMenuState } from "../../Slices/NavigationSlice";
import strings from "../../localization";
import IconButton from "@mui/material/IconButton";
import { clearUserData } from "../../Base/OAuth";
import { reducerLogout, setAuth } from "../../Slices/AuthSlice";
import {
  checkPermission,
  isPackageValid,
  isTrial,
  isTrialPackageValid,
} from "../../Util/PermissionUtil";
import Permissions from "../../Constants/Base/Permissions";
import ConfirmModal from "../Modals/ConfirmModal";
import UserType from "../../Constants/User/UserType";
import { getContractCount } from "../../Services/Company/CompanyService";
import { dateInPast } from "../../Util/DateUtil";

const getNavigationClass = (state) => {
  if (state === MenuState.SHORT) {
    return "navigation-content-container short";
  }

  return "navigation-content-container";
};

const Navigation = () => {
  const menuState = useSelector((state) => state.navigation.menuState);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.user);

  const [openNonValidSubscription, setOpenNonValidSubscription] =
    useState(false);

  const logout = () => {
    clearUserData();
    dispatch(reducerLogout());
    navigate("/login");
  };

  const isCurrentPath = (path) => {
    return location.pathname === path;
  };

  const isCurrentPathMatch = (path) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (openNonValidSubscription) {
      dispatch(changeMenuState(MenuState.SHORT));
    }
  }, [openNonValidSubscription]);

  const newContractHandler = (e) => {
    e.preventDefault();

    if (
      currentUser?.userProfileType !== UserType.SYSTEM_ADMIN &&
      currentUser?.userProfileType !== UserType.PORTAL_ADMIN
    ) {
      if (
        !currentUser?.company?.package ||
        !currentUser?.company?.package?.dataRoomSignature ||
        !currentUser?.company?.package?.dataRoomNoSignature ||
        !currentUser.company.packageValid ||
        dateInPast(new Date(currentUser.company.packageValidUntil))
      ) {
        setOpenNonValidSubscription(true);
        return;
      }
    }

    navigate("/contract-dashboard");
  };

  const dealRoomHandler = (e) => {
    e.preventDefault();
    if (
      !currentUser?.company?.package ||
      !currentUser?.company?.package?.dataRoomSignature ||
      !currentUser?.company?.package?.dataRoomNoSignature ||
      !currentUser.company.packageValid ||
      dateInPast(new Date(currentUser.company.packageValidUntil))
    ) {
      setOpenNonValidSubscription(true);
      return;
    }
    navigate("/virtualDealRoom");
  };

  const getAdminMenuItems = () => {
    return (
      <div className="navigation-list-links">
        {
          <Link
            to={"/contract-dashboard"}
            className={
              isCurrentPath("/contract-dashboard") ||
              isCurrentPath("/add-contract")
                ? "navigation-link navigation-btn active"
                : "navigation-btn navigation-link"
            }
          >
            <div onClick={newContractHandler}>
              <ListItem className="navigation-item">
                <ListItemIcon className="navigation-icon">
                  <IconButton>
                    <img
                      src="/images/icons/nav-add.png"
                      alt="add contract icon"
                    />
                  </IconButton>
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.newContract}
                  className="navigation-text"
                />
              </ListItem>
            </div>
          </Link>
        }

        <Link
          to={"/"}
          className={
            isCurrentPath("/") ||
            isCurrentPath("/dashboard") ||
            isCurrentPath("/my-details") ||
            isCurrentPath("/pricing-and-package") ||
            isCurrentPath("/subscription-details")
              ? "navigation-link active"
              : "navigation-link"
          }
        >
          <ListItem className="navigation-item">
            <ListItemIcon className="navigation-icon">
              <IconButton>
                <img src="/images/icons/nav-home.png" alt="home" />
              </IconButton>
            </ListItemIcon>
            <ListItemText
              inset
              primary={strings.navigation.home}
              className="navigation-text"
            />
          </ListItem>
        </Link>

        <Link
          to={"/contract-list"}
          className={
            isCurrentPath("/contract-list") ||
            isCurrentPath("/contract-preview")
              ? "navigation-link active"
              : "navigation-link"
          }
        >
          <ListItem className="navigation-item">
            <ListItemIcon className="navigation-icon">
              <IconButton>
                <img src="/images/icons/nav-contracts.png" alt="" />
              </IconButton>
            </ListItemIcon>
            <ListItemText
              inset
              primary={strings.navigation.contracts}
              className="navigation-text"
            />
          </ListItem>
        </Link>
        {currentUser?.userProfileType !== UserType.SYSTEM_ADMIN && (
          <Link
            to={"/virtualDealRoom"}
            className={
              isCurrentPath("/virtualDealRoom") ||
              isCurrentPath("/virtualDealRoom/create") ||
              isCurrentPath("/virtualDealRoom/payment") ||
              isCurrentPath("/virtualDealRoom/Dashboard")
                ? "navigation-link active"
                : "navigation-link"
            }
          >
            <div onClick={dealRoomHandler}>
              <ListItem className="navigation-item">
                <ListItemIcon className="navigation-icon">
                  <IconButton>
                    <img
                      src="/images/icons/dealRoomIcon.svg"
                      alt="dealroomicon"
                    />
                  </IconButton>
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.dealRoom}
                  className="navigation-text"
                />
              </ListItem>
            </div>
          </Link>
        )}
        <Link
          to={"/ai-assist"}
          className={
            isCurrentPath("/ai-assist")
              ? "navigation-link active-ai-tab"
              : "navigation-link ai-tab"
          }
        >
          <ListItem className="navigation-item">
            <ListItemIcon className="navigation-icon">
              <IconButton>
                <img
                  className="nav-ai-assist"
                  src={
                    isCurrentPath("/ai-assist")
                      ? "/images/icons/ai-assist-active.png"
                      : "/images/icons/ai-assist.png"
                  }
                  alt="ai-assist"
                />
              </IconButton>
            </ListItemIcon>
            <ListItemText
              inset
              primary={strings.navigation.aiAssist}
              className="navigation-text"
            />
          </ListItem>
        </Link>

        {checkPermission(currentUser, Permissions.MANAGE_USERS.NAVIGATION) && (
          <Link
            to={"/user-list"}
            className={
              isCurrentPath("/user-list")
                ? "navigation-link active"
                : "navigation-link"
            }
          >
            <ListItem className="navigation-item">
              <ListItemIcon className="navigation-icon">
                <IconButton>
                  <img src="/images/icons/nav-users.png" alt="Users" />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                inset
                primary={strings.navigation.users}
                className="navigation-text"
              />
            </ListItem>
          </Link>
        )}

        {checkPermission(currentUser, Permissions.ADMIN.NAVIGATION) && (
          <Link
            to={"/admin-all-templates"}
            className={
              isCurrentPath("/admin-all-templates") ||
              isCurrentPath("/admin-template-management") ||
              isCurrentPath("/admin-template-management-categories") ||
              isCurrentPath("/admin-template-management-packages") ||
              isCurrentPath("/admin-template-management-templates") ||
              isCurrentPath("/admin-template-management-AIAdminData") ||
              isCurrentPath("/add-admin-template")
                ? "navigation-link active"
                : "navigation-link"
            }
          >
            <ListItem className="navigation-item">
              <ListItemIcon className="navigation-icon">
                <IconButton>
                  <img src="/images/icons/nav-admin.png" alt="admin icon" />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                inset
                primary={strings.navigation.admin}
                className="navigation-text"
              />
            </ListItem>
          </Link>
        )}
      </div>
    );
  };

  const handleClickSidebar = (e) => {
    e.stopPropagation();

    if (e.target.className.includes("navigation-list")) {
      dispatch(
        changeMenuState(
          menuState === MenuState.SHORT ? MenuState.FULL : MenuState.SHORT
        )
      );
    }
  };

  return (
    <Drawer
      variant="permanent"
      id="navigation"
      onMouseDown={handleClickSidebar}
    >
      <div className={getNavigationClass(menuState)}>
        {menuState === MenuState.FULL && (
          <div className="logo-container">
            <div className="logo">
              <img src="/images/logo.png" alt="logo" />
            </div>
            <div className="nav-border"></div>

            <Tooltip
              title={strings.commonTooltips.collapse}
              placement="right"
              arrow
            >
              <IconButton
                className="nav-expand-icon"
                onClick={() => dispatch(changeMenuState(MenuState.SHORT))}
              >
                <img
                  src="/images/icons/double-arrow-left.png"
                  alt="previous-button"
                />
              </IconButton>
            </Tooltip>
          </div>
        )}

        {menuState === MenuState.SHORT && (
          <div className="logo-container">
            <div className="logo">
              <img src="/images/icons/logo-cropped.svg" alt="logo" />
            </div>

            <Tooltip
              title={strings.commonTooltips.expand}
              placement="right"
              arrow
            >
              <IconButton
                className="nav-expand-icon"
                onClick={() => dispatch(changeMenuState(MenuState.FULL))}
              >
                <img
                  src="/images/icons/double-arrow-right.png"
                  alt="next-button"
                />
              </IconButton>
            </Tooltip>
          </div>
        )}

        {currentUser && (
          <List component="nav" className="navigation-list">
            {getAdminMenuItems()}

            <div className="navigation-list-logout">
              <a
                onClick={() => logout()}
                className="navigation-link navigation-link-logout"
              >
                <ListItem className="navigation-item">
                  <ListItemIcon className="navigation-icon">
                    <IconButton>
                      <img
                        src="/images/icons/nav-logout.png"
                        alt="page-layout"
                      />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText
                    inset
                    primary={strings.navigation.logout}
                    className="navigation-text"
                  />
                </ListItem>
              </a>
            </div>
          </List>
        )}

        {/* here modal */}
        <Modal
          open={openNonValidSubscription}
          onClose={() => setOpenNonValidSubscription(false)}
          className="confirm-modal-container"
        >
          <ConfirmModal
            contentText={
              currentUser?.company?.freeTrialUsed
                ? strings.modals.contractCreatorNotAllowed.contentText
                : strings.modals.contractCreatorNotAllowed.contentTextTrial
            }
            confirmBtn={strings.modals.contractCreatorNotAllowed.ok}
            handleCloseModal={() => setOpenNonValidSubscription(false)}
            handleConfirm={() => {
              navigate("/pricing-and-package");
              setOpenNonValidSubscription(false);
            }}
          />
        </Modal>
      </div>
    </Drawer>
  );
};
export default Navigation;
