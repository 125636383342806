import {
    deleteTemplateQuestionOptionClause,
    editTemplateQuestionOptionClause
} from "../../../Services/Template/TemplateQuestionOptionClauseService";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import { Editor } from 'ckeditor5-custom-build/build/ckeditor';

const toolbarConfig = {
    items: [
        'heading', '|',
        'fontfamily', 'fontsize', '|',
        'alignment', '|',
        'fontColor', 'fontBackgroundColor', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
        'link', '|',
        'outdent', 'indent', '|',
        'bulletedList', 'numberedList', 'todoList', '|',
        'code', 'codeBlock', '|',
        'insertTable', '|',
        'uploadImage', 'blockQuote', '|',
        'undo', 'redo'
    ],
    link: { addTargetToExternalLinks: true },
    shouldNotGroupWhenFull: true,
    removePlugins: ['RealTimeCollaborativeEditing', 'PresenceList', 'RealTimeCollaborativeComments', 'RealTimeCollaborativeTrackChanges']
}

const OptionClause = ({clause, fetch, mainEditor}) => {

    const [editor, setEditor] = useState(null);

    const handleDelete = (clause) => {
        deleteTemplateQuestionOptionClause(clause.id).then(response => {

            if(!response && !response.ok) {
                return;
            }

            fetch();
        })
    }

    const updateClause = (clause) => {
        editTemplateQuestionOptionClause({
            ...clause,
            content: editor.getData()
        }).then(response => {

            if(!response && !response.ok) {
                return;
            }

            fetch();
        })
    }

    const placeCode = (clause) => {

        if(!mainEditor) {
            return;
        }

        let selection = mainEditor.model.document.selection
        const range = selection.getFirstRange();
        let selectedText = '';

        for (const item of range.getItems()) {

            if(!item.data) {
                continue;
            }

            selectedText = item.data
        }

        if(selectedText !== '') {
            mainEditor.model.change( writer => {
                mainEditor.model.insertContent( writer.createText('[' + clause.code + ']'), range )
            } );
        }
        else {
            mainEditor.model.change( writer => {
                writer.insertText( `[${clause.code}]`, mainEditor.model.document.selection.getFirstPosition() );
            } );
        }
    }

    return <div className={'clause'} key={'clause-' + clause.id}>
        <div className='title'>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            <h4>{clause.code}</h4>
                <div>
                    <IconButton className="item-icon" onClick={() => placeCode(clause)}>
                        <img src="/images/icons/merge.png" />
                    </IconButton>
                    <IconButton className="item-icon" onClick={() => updateClause(clause)}>
                        <img src="/images/icons/save-blue.png" />
                    </IconButton>
                    <IconButton className="item-icon" onClick={() => handleDelete(clause)}>
                        <img src="/images/icons/delete.png" />
                    </IconButton>
                </div>
            </div>
            <div style={{background: 'white', padding: '10px', width: '100%', minHeight: '200px'}}>
            {
                <CKEditor
                    onReady={ editor => {
                        editor.ui.getEditableElement()?.parentElement.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement()
                        );
                        setEditor(editor)
                    } }
                    // onError={ ( { willEditorRestart } ) => {
                    //     if ( willEditorRestart ) {
                    //         editor.ui.view.toolbar.element.remove();
                    //     }
                    // } }
                    data={clause.content}
                    editor={ Editor }
                    config={toolbarConfig}
                />
            }
        </div>

        </div>
        
    </div>
}

export default OptionClause;