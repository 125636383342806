import strings from "../../localization";
import PackageFeatureType from "./PackageFeatureType";

export function getCustomPackage() {
    return {
            name: strings.constants.dashboard.pricingAndPackages.custom.name,
            icon: "/images/pack-icons/custom-pack-icon.svg",
            description: strings.constants.dashboard.pricingAndPackages.custom.description,
            featureTitle: strings.constants.dashboard.pricingAndPackages.custom.features.features,
            features: [
                {
                    content: strings.constants.dashboard.pricingAndPackages.custom.features.feature1,
                    type: PackageFeatureType.REGULAR
                },
                {
                    content: strings.constants.dashboard.pricingAndPackages.custom.additionalFeatures.feature1,
                    type: PackageFeatureType.ADDITIONAL
                },
                {
                    content: strings.constants.dashboard.pricingAndPackages.custom.additionalFeatures.feature2,
                    type: PackageFeatureType.ADDITIONAL
                },
                {
                    content: strings.constants.dashboard.pricingAndPackages.custom.additionalFeatures.feature3,
                    type: PackageFeatureType.ADDITIONAL
                },
                {
                    content: strings.constants.dashboard.pricingAndPackages.custom.additionalFeatures.feature4,
                    type: PackageFeatureType.ADDITIONAL
                }
            ],
            additionalFeatureTitle: strings.constants.dashboard.pricingAndPackages.custom.additionalFeatures.additionalFeatures,
            price: strings.constants.dashboard.pricingAndPackages.custom.name,
            recommended: false,
            color: "#885DE2"
        }
}
