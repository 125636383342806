import React, {useState, useEffect, useContext} from "react";
import { useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {Alert, Drawer, Button, Paper, Snackbar} from "@mui/material";
import strings from "../../localization";
import TextFieldControl from '../../Components/Controls/Inputs/TextFieldControl';
import SelectControl from '../../Components/Controls/Inputs/SelectControl';
import {getSortUserFilter} from '../../Constants/User/SortUser';
import MenuState from "../../Constants/Base/MenuState";
import HeaderButtons from '../../Components/Layout/HeaderButtons';
import {
    getAllUsers,
    deleteUser,
    getUser,
    editUser,
    sendUserConfirmEmail,
    adminAddUser, adminEditUser, getAllUsersWithoutImageAndSignature, adminDeleteUser
} from '../../Services/User/UserService';
import UserType, {getUserTypes, getUserTypeStringAndClass, getUserTypesWithAll} from '../../Constants/User/UserType';
import SnackbarContext from "../../Context/SnackbarContext";
import ConfirmModal from "../../Components/Modals/ConfirmModal";
import Modal from "@mui/material/Modal";
import UserForm from "../../Components/Forms/Pages/UserList/UserForm";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";

const UserList = () => {

    const form = useForm();
    const user = useSelector((state) => state.auth.user)
    const {data, watch, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const menuState = useSelector((state) => state.navigation.menuState)
    const { showMessage } = useContext(SnackbarContext);
    
    const [allUsers, setAllUsers] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openUserLimitReachedModal, setOpenUserLimitReachedModal] = useState(false)
    const [userToDelete, setUserToDelete] = useState(-1)
    const [userToEdit, setUserToEdit] = useState(null)
    const [openFormModal, setOpenFormModal] = useState(false)
    const navigate = useNavigate();
    const [search, setSearch] = useState('')
    const [userProfileType, setUserProfileType] = useState(UserType.ALL);

    useEffect(() => {
        fetch();
    }, [])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if(value.userProfileType) {
                setUserProfileType(value.userProfileType.id)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const fetch = () => {
        getAllUsersWithoutImageAndSignature().then(response => {

            if(!response || !response.ok) {
                return;
            }

            setAllUsers(response.data);
        })
    }

    const getHeaderClass = (menuState) => {

        if(menuState === MenuState.SHORT) {
            return 'short';
        }
    
        return '';
    }

    const handleOpenDeleteModal = (userId) => {
        setUserToDelete(userId)
        setOpenDeleteModal(true)
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false)
    }

    const handleConfirmDelete = () => {
        setOpenDeleteModal(false)

        adminDeleteUser(userToDelete).then(response => {
            if(!response || !response.ok) {
                return;
            }
    
            showMessage('User deleted')
            fetch()
        })

        setUserToDelete(-1)
    }

    const handleResendLink = (id) => {
        sendUserConfirmEmail(id).then(response => {
            if(!response || !response.ok) {
                showMessage('Error resending email', 'Error')
                return;
            }

            showMessage('Email resend');
        })
    }

    const handleEdit = (user) => {
        getUser(user.id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            setUserToEdit(response.data)
            setOpenFormModal(true)
         });
    }

    const handleOpenFormModal = () => {
        if(userLimitReached(user)) {
            setOpenUserLimitReachedModal(true)
            return;
        }

        setUserToEdit(null)
        setOpenFormModal(true)
    }

    const handleCloseFormModal = () => {
        setOpenFormModal(false)
    }

    const userLimitReached = (user) => {
        const validUntilDate = new Date(user.company.packageValidUntil)
        const currentDate = new Date();

        if(user.userProfileType !== UserType.COMPANY_ADMIN) {
            return false;
        }

        const userPackage = user.company.package;
        if(!userPackage || (user.company.isTrial && (allUsers.length + 1) > 2)) {
            return true;
        }

        if (validUntilDate < currentDate) {
            return true;
        }

        if(userPackage.maxNumberOfUsers < 0) {
            return false;
        }

        return allUsers.length + 1 > userPackage.maxNumberOfUsers
    }

    const handleOkUserLimitReached = () => {
        setOpenUserLimitReachedModal(false)
        navigate('/pricing-and-package')
    }

    const handleSubmitConfirm = (data) => {

        if (data.id) {
            adminEditUser(data.id, data).then(response => {
                if(!response || !response.ok) {
                    showMessage('Error editing user', 'error');
                    return;
                }

                showMessage('User edited')
                fetch();
            })
        } else {
            adminAddUser(data).then(response => {
                if(!response || !response.ok) {
                    showMessage('Error adding user', 'error');
                    return;
                }

                showMessage('User added')
                fetch();
            })
        }
        setOpenFormModal(false)
    }

    const getUsers = () => {

        let result =  allUsers.filter(x => x.fullName.toLowerCase().includes(search.toLowerCase()) || x.email.toLowerCase().includes(search.toLowerCase()))

        if(!userProfileType || userProfileType === UserType.ALL) {
            return result
        }

        return result.filter(x => x.userProfileType === userProfileType);
    }

    const renderUsers = () => {
        let result = []

        for (let user of getUsers()) {
            result.push(
                <div key={'stage-' + result.length} className="item">
                    <div className="left">
                        <div className="name"><span>{user.fullName}</span></div>
                        <div className="email"><span>{user.email}</span></div>
                        
                    </div>
                    <div className="right">
                        {
                            !user.enabled &&
                            <a className="link" onClick={() => handleResendLink(user.id)}>{strings.pages.usersList.resendLink}</a>
                        }
                        <Tooltip 
                            title={strings.pages.usersList.editUser}
                            placement="top" 
                            arrow>
                            <IconButton className="icon" onClick={() => handleEdit(user)}>
                                <img src="/images/icons/pen.png" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={strings.pages.usersList.delete}
                            placement="top"
                            arrow>
                            <IconButton className="icon" onClick={() => handleOpenDeleteModal(user.id)}>
                                <img src="/images/icons/delete.png" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            )
        }
        return result
    }

    const contextText = () => {
        const validUntilDate = new Date(user.company.packageValidUntil)
        const currentDate = new Date();
        let returnedVal = ''
        if (user.company.isTrial === null ) {
            returnedVal = strings.modals.userList.trialNotActivatedText
        }
        else if (validUntilDate < currentDate) {
            returnedVal = strings.modals.userList.subscriptionExpiredText 
        }
        return returnedVal;
    }
                    
    return (
        <div className="user-list-container">

            <div id="header" className={ getHeaderClass(menuState)}>
                <div>
                    <h1>{strings.pages.usersList.title}</h1>
                </div>
                <HeaderButtons />
            </div>

            <div className={'filters-container'}>
                <FormProvider {...form}>
                    <div className='filter-item'>
                        <SelectControl
                            setValue={setValue}
                            name='userProfileType'
                            options={getUserTypesWithAll(user.userProfileType)}
                            nameKey='name'
                            valueKey='id'
                            defaultValue={getUserTypesWithAll(user.userProfileType)[0]}
                            className="select-control-container"
                        />
                    </div>
                    <div className='filter-serach-item'>
                        <TextField
                            className={'textfield-control'}
                            name='search'
                            fullWidth={true}
                            control={data}
                            defaultValue=''
                            margin="normal"
                            placeholder={strings.forms.searchForm.search}
                            icon={<img src="/images/icons/search.png" />}
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                        />
                    </div>
                </FormProvider>

                <Button className='default-btn filter-btn btn' variant='contained' onClick={handleOpenFormModal}>
                    <AddIcon />
                    <span>{strings.pages.usersList.newUser}</span>
                </Button>
            </div>

            <div className="user-list">
                {renderUsers()}
            </div>

            <Modal
                open={openUserLimitReachedModal}
                onClose={handleCloseDeleteModal}
                className='confirm-modal-container'
            >
                <ConfirmModal
                    contentText={`${contextText()}`}
                    confirmBtn={strings.modals.userList.ok}
                    handleConfirm={handleOkUserLimitReached}
                />
            </Modal>

            <Modal
                open={openDeleteModal}
                onClose={handleCloseDeleteModal}
                className='confirm-modal-container'
            >
                <ConfirmModal
                    contentText={strings.modals.userList.deleteUser.confirmText}
                    cancelBtn={strings.modals.userList.deleteUser.cancel}
                    confirmBtn={strings.modals.userList.deleteUser.yes}
                    handleCloseModal={handleCloseDeleteModal}
                    handleConfirm={handleConfirmDelete}
                />
            </Modal>

            <Modal
                open={openFormModal}
                onClose={handleCloseFormModal}
                className='confirm-modal-container-edit-user'
            >
                <UserForm
                    setOpenFormModal={setOpenFormModal}
                    handleConfirm={handleSubmitConfirm}
                    user={userToEdit}
                />
            </Modal>
           
            
        </div>
    )
}

export default UserList;