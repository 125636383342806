import React, {useEffect, useState, useContext} from "react";
import {FormProvider, useForm} from "react-hook-form";
import strings from "../../../../localization";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import Button from '@mui/material/Button';
import FileUploadComponent from './../../../Controls/FileUploadComponent';
import CloseIcon from "@mui/icons-material/Close";
import {toBase64} from "../../../../Util/ImageUtil";
import {updateUserProfile} from "../../../../Services/User/UserService";
import SnackbarContext from "../../../../Context/SnackbarContext";
import {useSelector} from "react-redux";
import {rejectContractSignatory} from "../../../../Services/Contract/ContractUserService";
import TextareaControl from "../../../Controls/Inputs/TextareaControl";

const RejectSignatureForm = ({
                                 closeModal,
                                 collaborator,
                                 fetch
                             }) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const {showMessage} = useContext(SnackbarContext);

    useEffect(() => {

    }, [])

    const cancel = () => {
        closeModal()
    }


    const onSubmit = (data) => {
        rejectContractSignatory(collaborator.id, data).then(response => {
            fetch();
            closeModal();
        });
    }

    return (<FormProvider {...form}>
        <div id="user-form-container" className="confirm-modal user-form-container sign-form">
            <h1 className="header">{strings.forms.contractList.rejectSignForm.title}</h1>

            <div className="user-form-border"></div>

            <div className="form-content">
                <div className="item">


                    <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.contractList.rejectSignForm.rejectionReason}
                        </span>
                        <TextareaControl
                            name='reason'
                            control={data}
                            defaultValue=''
                            fullWidth
                            margin="normal"
                            placeholder={strings.forms.contractList.rejectSignForm.rejectionReason}
                            rows={4}
                            maxRows={8}
                        />
                    </div>
                </div>
            </div>

            <div className="submit-container">
                <Button className="secondary-neutral-btn btn"
                        onClick={cancel}>{strings.forms.contractList.signForm.cancel}</Button>

                <Button className="secondary-btn btn" onClick={handleSubmit(onSubmit)}>
                    Reject
                </Button>
            </div>
        </div>
    </FormProvider>)
}

export default RejectSignatureForm;