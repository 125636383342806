import React, {useState, useEffect, useContext} from "react";
import {Collapse} from "@mui/material";
import TextFieldControl from "../../../../../Controls/Inputs/TextFieldControl";
import {FormProvider} from "react-hook-form";
import {Button} from "@mui/material";
import {useForm} from "react-hook-form";
import SnackbarContext from "../../../../../../Context/SnackbarContext";
import Modal from '@mui/material/Modal';
import ConfirmModal from '../../../../../Modals/ConfirmModal';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {reorder, sortByStepOrder} from "../../../../../../Util/DnDUtil";
import strings from "../../../../../../localization";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SubSections from './SubSections';
import SubSectionsForm from './SubSectionsForm';
import {
    addTemplateStep, deleteTemplateStep,
    editTemplateStep,
    getAllTemplateSteps,
    reorderSections
} from "../../../../../../Services/Template/TemplateStepService";
import Tooltip from "@mui/material/Tooltip";

const SectionsForm = ({
                          template,
                          editor,
                          isSubSectionFormHidden,
                          setIsSubSectionFormHidden
                      }) => {

    const form = useForm();
    const {data, control, handleSubmit, watch, getValues, setValue, formState: {errors}} = form;
    const {showMessage} = useContext(SnackbarContext);
    const [openModalSection, setOpenModalSection] = useState(false)
    const [openModalNewSection, setOpenModalNewSection] = useState(false)
    const [selectedSection, setSelectedSection] = useState(-1)
    const [sectionStages, setSectionStages] = useState([]);
    const [sectionsForTemplate, setSectionsForTemplate] = useState([])
    const [subSectionsForTemplate, setSubSectionsForTemplate] = useState([])
    const [sectionToEdit, setSectionToEdit] = useState(-1);
    const [selectedSubSection, setSelectedSubSection] = useState(-1);
    const [sectionForm, setSectionForm] = useState(false)
    const [drop, setDrop] = useState({id: 0, toggle: false})
    const [search, setSearch] = useState('')

    useEffect(() => {

        fetch();

    }, [template]);

    useEffect(() => {

        const subscription = watch((value) => {setSearch(value.search)});
        return () => subscription.unsubscribe();

    }, [watch])

    const fetch = () => {
        if(!template) {
            return
        }
        
        getAllTemplateSteps(template.id).then(response => {
            
            if(!response || !response.ok) {
                return;
            }
            
            let sections = sortByStepOrder(response.data.filter(e => e.parent === null))
            let subSections = response.data.filter(e => e.parent !== null)

            setSubSectionsForTemplate(subSections)
            setSectionsForTemplate(sections)
            setSectionStages(response.data)
        })
    }

    const handleDrop = (id) => {

        if(drop.id === id) {
            setDrop({
                id: id,
                toggle: !drop.toggle
            });
        }
        else {
            setDrop({
                id: id,
                toggle: true
            });
        }

        setSectionToEdit(-1)
    }

    const handleOpenEditSection = (section) => {
        setSectionToEdit(section.id)
        setSectionForm(false)
        setValue('sectionName', section.name)
    }

    const handleCloseEditSection = () => {
        setSectionToEdit(-1)
        setValue('sectionName', '')
    }

    const handleAddSubSection = (section) => {
        
        addTemplateStep({
            name: 'New sub-section',
            parentId: section.id,
            templateId: template.id
        }).then(response => {
            fetch();
        })
        
        setDrop({id: section.id, toggle:true})
        showMessage(strings.common.added)
    }

    const handleOpenNewSection = () => {
        setSectionToEdit(-1)
        setSectionForm(true)
        setValue('sectionName', '')
    }

    const handleOpenModalSection = (section) => {
        setOpenModalSection(true)
        setSelectedSection(section.id)
    }

    const handleCloseSectionModal = () => {
        setOpenModalSection(false)
    }

    const handleConfirmSection = section => {

        deleteTemplateStep(section.id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            let updatedSections = sectionStages.filter(e => e.id !== section.id)
            setSectionStages(updatedSections)
            showMessage(strings.common.removed)

            fetch()
        })
    }

    const handleOpenModalNewSection = () => {
        setOpenModalNewSection(true)
    }

    const handleCloseNewSectionModal = () => {
        setOpenModalNewSection(false)
    }

    const handleConfirmNewSection = () => {

    }

    const handleAddSection = (data) => {

        if (sectionToEdit === -1) {

            addTemplateStep({
                name: data.sectionName,
                templateId: template.id
            }).then(response => {

                fetch();

                showMessage(strings.common.added)
                setSectionForm(false)
            })

        } else {
            editTemplateStep({
                id: sectionToEdit,
                name: data.sectionName
            }).then(response => {
                fetch();
                showMessage(strings.common.edited)
                setSectionToEdit(-1)
            })
        }
        setValue('sectionName', '')
    }

    const handleClearSectionField = () => {
        setValue('sectionName', '')
    }

    const handleHideForm = () => {
        setSectionForm(false)
    }

    const onDragEnd = (result) => {

        if (!result.source || !result.destination) {
            return;
        }
        
        const stages = reorder(sectionsForTemplate, result.source.index, result.destination.index);

        const stagesModified = stages.map((e, index) => {
            e.templateStepOrder = index
            return e
        })

        setSectionsForTemplate(sortByStepOrder(stagesModified));

        setSectionStages([
            ...stagesModified,
            ...subSectionsForTemplate
        ])

        reorderSections(template.id, [...stagesModified, ...subSectionsForTemplate]).then(response => {
            if(!response || !response.ok) {
                return 
            }
        })
    }

    const canViewSection = (section, subsections, search) => {

        if(!search) {
            return true;
        }

        let canView = section.name.toLowerCase().includes(search.toLowerCase());

        for(let subsection of subsections)
        {
            if(subsection.name.toLowerCase().includes(search.toLowerCase())) {
                canView = true
                break;
            }
        }

        return canView;
    }


    const renderSections = () => {
        let result = [];

        for (let section of sectionsForTemplate) {

            if(section.parent || !canViewSection(section, subSectionsForTemplate, search)) {
                continue;
            }

            result.push(
                <Draggable
                    key={section.id}
                    draggableId={'stage-' + section.id}
                    index={result.length}
                >

                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                        >
                            <div key={'section-stage-' + result.length} className="template-page-item content-item">
                                <div {...provided.dragHandleProps} className="item-header">
                                    <div className="item-title">

                                        {sectionToEdit === section.id
                                            ?
                                            <React.Fragment>
                                                <FormProvider {...form}>
                                                    <div className="form-row form-row-with-inputs">
                                                        <div className="item input-wrap long-input-wrap">
                                                            <TextFieldControl
                                                                name='sectionName'
                                                                control={data}
                                                                // defaultValue={section.name}
                                                                rules={{required: true}}
                                                                fullWidth
                                                                margin="normal"
                                                                error={Boolean(errors.sectionName)}
                                                                helperText={errors.sectionName && strings.forms.admin.adminTemplateForm.sectionNameRequired}
                                                                placeholder={strings.forms.admin.adminTemplateForm.sectionName}
                                                                inputRef
                                                            />
                                                        </div>
                                                    </div>

                                                    <Tooltip 
                                                        title={strings.forms.admin.adminTemplateForm.tooltips.save}
                                                        placement="top" 
                                                        arrow>
                                                        <IconButton className="item-icon save-icon" onClick={handleSubmit(handleAddSection)}>
                                                            <img src="/images/icons/save.png" />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip 
                                                        title={strings.forms.admin.adminTemplateForm.tooltips.cancel}
                                                        placement="top" 
                                                        arrow>
                                                        <IconButton className="item-icon" onClick={handleCloseEditSection}>
                                                            <img src="/images/icons/close.png" className="close" />
                                                        </IconButton>
                                                    </Tooltip>

                                                </FormProvider>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <img className="title-icon" src="/images/icons/section-title-icon.png" />
                                                <h1 className="title">{section.name}</h1>

                                                <Tooltip 
                                                    title={strings.forms.admin.adminTemplateForm.tooltips.editSectionName}
                                                    placement="top" 
                                                    arrow>
                                                    <IconButton className="item-icon" onClick={() => handleOpenEditSection(section)}>
                                                        <img src="/images/icons/pen.png" />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip 
                                                    title={strings.forms.admin.adminTemplateForm.tooltips.addSubSection}
                                                    placement="top" 
                                                    arrow>
                                                    <IconButton className="item-icon" onClick={() => handleAddSubSection(section)}>
                                                        <img src="/images/icons/add-section.png" />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip 
                                                    title={strings.forms.admin.adminTemplateForm.tooltips.delete}
                                                    placement="top" 
                                                    arrow>
                                                    <IconButton className="item-icon delete-icon" onClick={() => handleOpenModalSection(section)}>
                                                        <img src="/images/icons/delete.png" />
                                                    </IconButton>
                                                </Tooltip>
                                            </React.Fragment>
                                        }

                                    </div>

                                    {
                                        section.childrenCount > 0 &&
                                        <React.Fragment>
                                            {drop.toggle && section.id === drop.id
                                                ? <Tooltip 
                                                    title={strings.commonTooltips.collapse}
                                                    placement="top" 
                                                    arrow>
                                                    <RemoveIcon className="toggle-icon" onClick={() => handleDrop(section.id)}/>
                                                </Tooltip>
                                                : <Tooltip 
                                                    title={strings.commonTooltips.expand}
                                                    placement="top" 
                                                    arrow>
                                                    <AddIcon className="toggle-icon" onClick={() => handleDrop(section.id)}/>
                                                </Tooltip>
                                            }
                                        </React.Fragment>
                                    }

                                </div>
                                <Collapse in={drop.id === section.id && drop.toggle} timeout="auto" unmountOnExit>
                                    <div className="subitems">
                                        <SubSections
                                            search={search}
                                            refreshSections={ () => fetch() }
                                            sectionStages={sectionStages}
                                            setSectionStages={setSectionStages}
                                            editor={editor}
                                            sectionId={section.id}
                                            setIsSubSectionFormHidden={setIsSubSectionFormHidden}
                                            selectedSubSection={selectedSubSection}
                                            setSelectedSubSection={setSelectedSubSection}
                                            template={template}
                                            subSectionsForTemplate={subSectionsForTemplate}
                                            setSubSectionsForTemplate={setSubSectionsForTemplate}
                                            sectionsForTemplate={sectionsForTemplate}
                                            handleConfirmSection={handleConfirmSection}
                                        />
                                    </div>
                                </Collapse>
                            </div>

                            {openModalSection && selectedSection === section.id &&
                            <Modal
                                open={openModalSection}
                                onClose={handleCloseSectionModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                className='confirm-modal-container'
                            >
                                <ConfirmModal
                                    title={strings.modals.adminTemplates.title}
                                    contentText={strings.modals.adminTemplates.confirmTextSection}
                                    cancelBtn={strings.modals.adminTemplates.cancelBtn}
                                    confirmBtn={strings.modals.adminTemplates.deleteBtn}
                                    handleCloseModal={handleCloseSectionModal}
                                    handleConfirm={() => handleConfirmSection(section)}
                                />
                            </Modal>
                            }

                        </div>
                    )}
                </Draggable>
            )
        }
        return result;
    }

    return (
        <div className="template-page-content admin-template">

            {isSubSectionFormHidden &&
            <div className="search-container">
                <FormProvider {...form}>
                    <div className='serach-item'>
                        <TextFieldControl
                            name='search'x
                            control={data}
                            defaultValue=''
                            margin="normal"
                            placeholder={strings.forms.admin.adminTemplateForm.searchSections}
                            icon={
                                <IconButton className="item-icon">
                                    <img src="/images/icons/search.png" />
                                </IconButton>
                            }
                        />
                    </div>
                </FormProvider>
                <Button className='default-btn add-btn btn' variant='contained' onClick={() => handleOpenNewSection()}>
                    <AddIcon/>
                    <span>{strings.forms.admin.adminTemplateForm.newSections}</span>
                </Button>
            </div>
            }

            <Collapse in={isSubSectionFormHidden && sectionForm} timeout="auto" unmountOnExit>
                <FormProvider {...form}>
                    <div className="template-page-item content-item section-form">
                        <TextFieldControl
                            name='sectionName'
                            control={data}
                            defaultValue=''
                            rules={{required: true}}
                            margin="normal"
                            error={Boolean(errors.sectionName)}
                            helperText={errors.sectionName && strings.forms.admin.adminTemplateForm.sectionNameRequired}
                            placeholder={strings.forms.admin.adminTemplateForm.sectionName}
                        />
                        
                        <Tooltip 
                            title={strings.forms.admin.adminTemplateForm.tooltips.save}
                            placement="top" 
                            arrow>
                            <IconButton className="item-icon save-icon" onClick={handleSubmit(handleAddSection)}>
                                <img src="/images/icons/save.png" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip 
                            title={strings.forms.admin.adminTemplateForm.tooltips.cancel}
                            placement="top" 
                            arrow>
                            <IconButton className="item-icon" onClick={handleHideForm}>
                                <img src="/images/icons/close.png" className="close" />
                            </IconButton>
                        </Tooltip>

                    </div>
                </FormProvider>
            </Collapse>

            {openModalNewSection &&
            <Modal
                open={openModalNewSection}
                onClose={handleCloseNewSectionModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='confirm-modal-container'
            >
                <ConfirmModal
                    title={strings.modals.adminTemplates.title}
                    contentText={strings.modals.adminTemplates.confirmTextNewSection}
                    cancelBtn={strings.modals.adminTemplates.cancelBtn}
                    confirmBtn={strings.modals.adminTemplates.deleteBtn}
                    handleCloseModal={handleCloseNewSectionModal}
                    handleConfirm={() => handleConfirmNewSection()}
                />
            </Modal>
            }

            {isSubSectionFormHidden &&
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable-container' direction="vertical">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {renderSections()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            }

            {!isSubSectionFormHidden &&
            <SubSectionsForm
                refreshSections={() => fetch()}
                editor={editor}
                sectionStages={sectionStages}
                isSubSectionFormHidden={isSubSectionFormHidden}
                setIsSubSectionFormHidden={setIsSubSectionFormHidden}
                selectedSubSection={selectedSubSection}
                setSelectedSubSection={setSelectedSubSection}
            />
            }
        </div>
    )
}

export default SectionsForm;