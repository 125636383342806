import React, {useState, useContext} from "react";
import TextFieldControl from "../../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../../localization";
import {FormProvider} from "react-hook-form";
import SnackbarContext from "../../../../../../Context/SnackbarContext";
import {useForm} from "react-hook-form";
import Modal from '@mui/material/Modal';
import ConfirmModal from '../../../../../Modals/ConfirmModal';
import IconButton from '@mui/material/IconButton';
import {getSubSections} from "../../../../../../Services/AdminFakeApi";
import AddTemplateQuestion from "../../../../../../Pages/Admin/AdminTemplates/AddTemplateQuestion";
import Tooltip from "@mui/material/Tooltip";

const SubSectionsForm = ({
                             setIsSubSectionFormHidden,
                             selectedSubSection,
                             setSelectedSubSection,
                             editor,
                             refreshSections
                         }) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const {showMessage} = useContext(SnackbarContext);

    const subSectionFirstEl = {id: -1, name: strings.forms.admin.adminTemplateForm.selectQuestionFormat}
    const [questionFormatId, setQuestionFormatId] = useState(-1)

    const [openModalSubSection, setOpenModalSubSection] = useState(false)
    const [editSubSectionTitle, setEditSubSectionTitle] = useState(false)

    const handleEditSubSectionTitle = () => {
        setEditSubSectionTitle(true)
    }

    const handleOpenModalSubSection = () => {
        setOpenModalSubSection(true)
    }

    const handleCloseSubSectionModal = () => {
        setOpenModalSubSection(false)
    }

    const handleConfirmSubSection = () => {

        setIsSubSectionFormHidden(true)
        getSubSections().filter(e => e.sectionId === selectedSubSection.id)
        showMessage(strings.common.deleted)
    }

    const submitSubSectionTitle = (data) => {
        setValue('subSectionName', data.subSectionName)
        setSelectedSubSection({
            ...selectedSubSection,
            name: data.subSectionName
        })
        showMessage(strings.common.edited)
        setIsSubSectionFormHidden(true)
    }

    const handleBackToSections = () => {
        setIsSubSectionFormHidden(true)
    }

    const handleAddSubSection = () => {

    }

    const handleQuestionFormat = (event) => {
        setQuestionFormatId(event.target.value)
    }

    return (
        <div>
            <div className="title">

                <IconButton className="back-icon" onClick={() => handleBackToSections()}>
                    <img src="/images/icons/undo.png" />
                </IconButton>

                {editSubSectionTitle
                    ? <FormProvider {...form}>
                        <React.Fragment>
                            <TextFieldControl
                                name='subSectionName'
                                control={data}
                                // defaultValue={subSection.name}
                                rules={{required: true}}
                                fullWidth
                                margin="normal"
                                error={Boolean(errors.subSectionName)}
                                helperText={errors.subSectionName && strings.forms.admin.adminTemplateForm.subSectionNameRequired}
                                placeholder={strings.forms.admin.adminTemplateForm.subSectionName}
                            />
                            
                            <Tooltip 
                                title={strings.forms.admin.adminTemplateForm.tooltips.save}
                                placement="top" 
                                arrow>
                                <IconButton className="title-icon save-icon" onClick={handleSubmit(submitSubSectionTitle)}>
                                    <img src="/images/icons/save.png" />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    </FormProvider>

                    : <React.Fragment>
                        <h1>{selectedSubSection.id > -1 ? selectedSubSection.name : strings.forms.admin.adminTemplateForm.subSectionName}</h1>
                        
                        <Tooltip 
                            title={strings.forms.admin.adminTemplateForm.tooltips.editSubSectionName}
                            placement="top" 
                            arrow>
                            <IconButton className="title-icon" onClick={() => handleEditSubSectionTitle()}>
                                <img src="/images/icons/pen.png" />
                            </IconButton>
                        </Tooltip>
                        
                    </React.Fragment>
                }

                <Tooltip 
                    title={strings.forms.admin.adminTemplateForm.tooltips.delete}
                    placement="top" 
                    arrow>
                    <IconButton className="title-icon" onClick={() => handleOpenModalSubSection()}>
                        <img src="/images/icons/delete.png" />
                    </IconButton>
                </Tooltip>
            </div>

            {openModalSubSection &&
            <Modal
                open={openModalSubSection}
                onClose={handleCloseSubSectionModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='confirm-modal-container'
            >
                <ConfirmModal
                    title={strings.modals.adminTemplates.title}
                    contentText={strings.modals.adminTemplates.confirmTextSubSection}
                    cancelBtn={strings.modals.adminTemplates.cancelBtn}
                    confirmBtn={strings.modals.adminTemplates.deleteBtn}
                    handleCloseModal={handleCloseSubSectionModal}
                    handleConfirm={() => handleConfirmSubSection()}
                />
            </Modal>
            }

            <AddTemplateQuestion refreshSections={refreshSections} section={selectedSubSection} editor={editor}/>
        </div>
    )
}

export default SubSectionsForm;