import React, {useEffect, useState} from "react";
import strings from "../../../../../localization";
import {FormProvider} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import {Button} from "@mui/material";
import DetailsForm from './DetailsForm';
import SectionsForm from './SectionsForm/SectionsForm';
import CategoriesForm from './CategoriesForm';
import HistorySections from './History/HistorySections';
import Modal from '@mui/material/Modal';
import ConfirmModal from '../../../../../Components/Modals/ConfirmModal';
import {deleteTemplate} from "../../../../../Services/Template/TemplateService";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Editor } from 'ckeditor5-custom-build/build/ckeditor';
import {getActiveTemplateContent, getAllTemplateContent, getLastTemplateContent, getTemplateContent} from "../../../../../Services/Template/TemplateContentService";
import Tooltip from "@mui/material/Tooltip";
import TemplateDocumentForm from "./TemplateDocumentForm";
import { activateAllTemplateSteps } from "../../../../../Services/Template/TemplateStepService";

const toolbarConfig = {
    items: [
        'heading', '|',
        'fontfamily', 'fontsize', '|',
        'alignment', '|',
        'fontColor', 'fontBackgroundColor', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
        'link', '|',
        'outdent', 'indent', '|',
        'bulletedList', 'numberedList', 'todoList', '|',
        'code', 'codeBlock', '|',
        'insertTable', '|',
        'uploadImage', 'blockQuote', '|',
        'undo', 'redo'
    ],
    link: { addTargetToExternalLinks: true },
    shouldNotGroupWhenFull: true,
    removePlugins: ['RealTimeCollaborativeEditing', 'PresenceList', 'RealTimeCollaborativeComments', 'RealTimeCollaborativeTrackChanges']
}

const AdminTemplateForm = ({
                               formRules,
                               values,
                               setValue,
                               control,
                               errors,
                               data,
                               form,
                               onSubmit,
                               isSubSectionFormHidden,
                               setIsSubSectionFormHidden,
                               categoriesData,
                               template,
                               autoSave,
                               onPublish,
                               templateContent,
                           }) => {

    const [details, setDetails] = useState(true)
    const [sections, setSections] = useState(false)
    const [history, setHistory] = useState(false)
    const [categories, setCategories] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const navigate = useNavigate();
    const [editor, setEditor] = useState(null)
    const [content, setContent] = useState('')
    const [isDescriptionLoaded, setIsDescriptionLoaded] = useState(false)
    const [description, setDescription] = useState('')
    const [uploadDialog, setUploadDialog] = useState(false);
    const [readyAutoSave, setReadyAutoSave] = useState(false);
    const [time, setTime] = useState(Date.now());
    const [editDraft, isEditDraft] = useState(false);
    const [isTemplateHasContents, setIsTemplateHasContents] = useState(true);

    useEffect(() => {
        fetch();
    }, [template])

    useEffect(() => {
        const interval = setInterval(() => {
            if(readyAutoSave) {
                // handleSaveTemplate(false)
                setTime(Date.now())
            }
        }, 10000);
        return () => {
            clearInterval(interval);
        };
    }, [readyAutoSave])

    const fetch = () => {

        if(!template) {
            return;
        }

        setDescription(template?.description)

        getLastTemplateContent(template.id).then(response => {

            setIsDescriptionLoaded(true);

            if(!response || !response.ok) {
                return;
            }

            if(editDraft) {
                getAllTemplateContent(template.id).then(draftContent => {
                    setContent(draftContent.data && draftContent.data[0].content ? draftContent.data[0].content : response.data.content);
                    templateContent(draftContent.data && draftContent.data[0] ? draftContent.data[0] : response.data);
                });
                isEditDraft(false);
                return;
            } 

            if(response.data) {
                setContent(response.data && response.data.content ? response.data.content : '');
                templateContent(response.data);
                setIsTemplateHasContents(true);
                return;
            }

            setIsTemplateHasContents(false);
        });
    }

    const fetchTemplateContent = (id) => {
        getTemplateContent(id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            setReadyAutoSave(response.data.active);

            setContent(response.data.content);
            templateContent(response.data);
        })
    }

    const handleDetails = () => {
        setDetails(true)
        setCategories(false)
        setSections(false)
        setHistory(false)
    }

    const handleCategories = () => {
        setDetails(false)
        setCategories(true)
        setSections(false)
        setHistory(false)
    }

    const handleSections = () => {
        setDetails(false)
        setCategories(false)
        setSections(true)
        setHistory(false)
    }

    const handleHistory = () => {
        setDetails(false)
        setCategories(false)
        setSections(false)
        setHistory(true)
    }

    const handleUploadDocument = () => {
        setUploadDialog(true)
    }

    const handlePublishTemplate = () => {

        if(editor) {
            setValue('content', editor.getData())
            setContent(editor.getData());
        }

        setValue('description', description)

        isEditDraft(false);

        activateAllTemplateSteps({templateId: template.id});

        isTemplateHasContents ? onPublish(templateContent.id) : onSubmit();
    }

    const handleSaveTemplate = (force) => {
        
        if(editor) {
            setValue('content', editor.getData())
        }

        setValue('description', description)

        isEditDraft(true);

        if(force) {
            onSubmit();
            return;
        }

        setContent(editor.getData());
        autoSave();
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleConfirm = () => {
        deleteTemplate(template.id).then(response => {
            if(!response || !response.ok) {
                return;
            }

            setOpenModal(false)
            navigate('/admin-template-management-templates')
        })
    }

    const handleUploadDocumentConfirm = (content) => {
        setUploadDialog(false)
        setContent(content);
    }

    return (
        <FormProvider {...form}>
            <div className="admin-template-form-container">

                <div className="template-form">

                    {isSubSectionFormHidden &&
                    <>
                        <div className="title">
                            <Link to={'/admin-template-management-templates'}>
                                <IconButton className="back-icon">
                                    <img src="/images/icons/undo.png" />
                                </IconButton>
                            </Link>
                            <h1>{ template ? template.name : strings.forms.admin.adminTemplateForm.templateName}</h1>
                            {
                                template &&
                                <React.Fragment>
                                    {/*<EditIcon className="title-icon edit-icon" onClick={() => handleEditTemplateTitle()}/>*/}

                                    <Tooltip 
                                        title={strings.forms.admin.adminTemplateForm.tooltips.delete}
                                        placement="top" 
                                        arrow>
                                        <IconButton className="title-icon" onClick={() => handleOpenModal()}>
                                            <img src="/images/icons/delete.png" />
                                        </IconButton>
                                    </Tooltip>
                                </React.Fragment>
                            }

                        </div>
                        <div className="border"></div>
                        <div className="subheader">
                            <span className={details ? "tab active" : "tab"}
                                  onClick={() => handleDetails()}>{strings.forms.admin.adminTemplateForm.detailsAndDescription}</span>
                            {
                                template &&
                                <React.Fragment>
                                    <span className={categories ? "tab active" : "tab"}
                                          onClick={() => handleCategories()}>{strings.forms.admin.adminTemplateForm.categories}</span>
                                    <span className={sections ? "tab active" : "tab"}
                                          onClick={() => handleSections()}>{strings.forms.admin.adminTemplateForm.sections}</span>
                                    <span className={history ? "tab active" : "tab"}
                                          onClick={() => handleHistory()}>{strings.forms.admin.adminTemplateForm.history}</span>
                                </React.Fragment>
                            }
                        </div>
                    </>
                    }

                    {details &&
                    <DetailsForm
                        formRules={formRules}
                        values={values}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        data={data}
                        form={form}
                        description={description}
                        setDescription={setDescription}
                        isDescriptionLoaded={isDescriptionLoaded}
                    />
                    }

                    {categories &&
                    <CategoriesForm
                        formRules={formRules}
                        values={values}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        data={data}
                        form={form}
                        template={template}
                        categories={categoriesData}
                    />
                    }

                    {sections &&
                    <SectionsForm
                        editor={editor}
                        template={template}
                        isSubSectionFormHidden={isSubSectionFormHidden}
                        setIsSubSectionFormHidden={setIsSubSectionFormHidden}
                    />
                    }

                    {history &&
                    <HistorySections
                        fetchTemplateContent={fetchTemplateContent}
                        fetchActiveTemplateContent={fetch}
                        template={template}
                        formRules={formRules}
                        values={values}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        data={data}
                        form={form}
                    />
                    }

                </div>

                {openModal &&
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className='confirm-modal-container'
                >
                    <ConfirmModal
                        title={strings.modals.adminTemplates.title}
                        contentText={strings.modals.adminTemplates.confirmTextTemplate}
                        cancelBtn={strings.modals.adminTemplates.cancelBtn}
                        confirmBtn={strings.modals.adminTemplates.deleteBtn}
                        handleCloseModal={handleCloseModal}
                        handleConfirm={() => handleConfirm()}
                    />
                </Modal>
                }

                {
                    uploadDialog &&
                    <Modal
                        open={uploadDialog}
                        onClose={() => setUploadDialog(false)}
                        className='confirm-modal-container'
                    >
                        <TemplateDocumentForm
                            handleCloseModal={() => setUploadDialog(false)}
                            handleConfirm={handleUploadDocumentConfirm}
                        />
                    </Modal>
                }

                <div className="template-editor">
                    <div className="template-editor-btn-container">
                        {
                            template &&
                            <Button className="neutral-btn btn" 
                                startIcon={<img src="/images/icons/attach.png" />} 
                                variant="contained" onClick={handleUploadDocument}>
                                <span className="btn-txt">{strings.forms.admin.adminTemplateForm.uploadDocument}</span>
                            </Button>
                        }

                        <Button className="default-btn btn" variant="contained" onClick={() => handleSaveTemplate(true)}>
                            <span className="btn-txt">{strings.forms.admin.adminTemplateForm.save}</span>
                        </Button>

                        <Button className="btn publish-btn" variant="contained" onClick={handlePublishTemplate}>
                            <span className="btn-txt">{strings.forms.admin.adminTemplateForm.publish}</span>
                        </Button>
                    </div>
                    <div style={{background: 'white', padding: '10px', marginTop: '15px', maxHeight: '77vh', marginLeft:'-12px'}}>
                        {
                            <CKEditor
                                onReady={ editor => {
                                    editor.ui.getEditableElement()?.parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement()
                                    );
                                    setEditor(editor)
                                } }
                                // onError={ ( { willEditorRestart } ) => {
                                //     if ( willEditorRestart ) {
                                //         editor.ui.view.toolbar.element.remove();
                                //     }
                                // } }
                                editor={ Editor }
                                data={content ? content : ''}
                                config={toolbarConfig}
                            />
                        }
                    </div>
                    
                </div>
            </div>
        </FormProvider>
    )
}

export default AdminTemplateForm;