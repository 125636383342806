import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import styles from "./DealRoom.module.css";
import {
  dealRoomParty,
  virtualDealRoomCol,
} from "../../Constants/Admin/DealRoomModel";
import VirtualRoomTable from "./VirtualRoomTable";
import { useNavigate } from "react-router-dom";
import HeaderButtons from "../../Components/Layout/HeaderButtons";
import { useSelector } from "react-redux";
import MenuState from "../../Constants/Base/MenuState";
import {
  closeDealroom,
  getAllDealRoom,
  downloadDealroomData,
  downloadAllQuestionnaire,
} from "../../Services/DealRoom/DealRoomService";
import SnackbarContext from "../../Context/SnackbarContext";
import UserType from "../../Constants/User/UserType";
import axios from "axios";

const DealRoom = () => {
  const navigation = useNavigate();
  const { showMessage } = useContext(SnackbarContext);
  const menuState = useSelector((state) => state.navigation.menuState);
  const user = useSelector((state) => state.auth.user);

  const [rows, setRows] = useState([]);
  const [loadApi, setLoadApi] = useState(false);

  function createData(
    id,
    name,
    targetCompanyName,
    party,
    isSubscriptionRequired,
    validUntil,
    owneFullName,
    ownerUserId,
    subscription,
    isAdmin,
    isClosed
  ) {
    let validUntilDate;
    if (validUntil) {
      const date = new Date(validUntil);
      date.setDate(date.getDate() + 30);
      validUntilDate = date.toISOString().split("T")[0];
    }
    if (party === 0) {
      party = dealRoomParty[0].name;
    } else {
      party = dealRoomParty[1].name;
    }
    return {
      id,
      name,
      targetCompanyName,
      party,
      isSubscriptionRequired,
      validUntilDate,
      owneFullName,
      ownerUserId,
      subscription,
      isAdmin,
      isClosed,
    };
  }

  useEffect(() => {
    getAllDealRoomData();
  }, []);

  const getAllDealRoomData = () => {
    getAllDealRoom()
      .then((res) => {
        if (res.status === 200) {
          const tempRows = res?.data?.map((obj) => {
            return createData(
              obj.id,
              obj.name,
              obj.targetCompanyName,
              obj.party,
              obj.isSubscriptionRequired,
              obj?.subscription?.validUntil,
              obj?.owner?.fullName,
              obj?.owner?.userId,
              obj?.subscription,
              obj?.isAdmin,
              obj?.isClosed
            );
          });
          setRows(tempRows);
          setLoadApi(true);
        } else {
          showMessage("Error in fetching deal room list", "error");
        }
      })
      .catch((err) => {
        console.log("error in getAllDealRoom", err);
      });
  };

  const getHeaderClass = (menuState) => {
    if (menuState === MenuState.SHORT) {
      return "short";
    }
    return "";
  };

  const downloadAllDealroomData = (projectId, name) => {
    downloadDealroomData(projectId)
      .then((res) => {
        if (res.status === 200) {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([res.data]));
          link.setAttribute("download", `${name}.zip`);
          document.body.appendChild(link);
          link.click();
        } else {
          showMessage("Error in downloading data", "error");
        }
      })
      .catch((err) => {
        console.log("error in downloadDealroomData", err);
      });
  };

  const downloadAllQuestionnaireHandler = (projectId, name) => {
    downloadAllQuestionnaire(projectId)
      .then((res) => {
        if (res.status === 200) {
          const binaryData = atob(res.data);
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([uint8Array], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${name + "-excel"}.xlsx`;
          link.click();
          showMessage("Excel downloaded");
        } else {
          showMessage("Error in downloading Questionnaire", "error");
        }
      })
      .catch((err) => {
        console.log("error in downloadAllQuestionnaire", err);
      });
  };

  const closeDealroomHandler = (projectId) => {
    closeDealroom(projectId)
      .then((res) => {
        if (res.status === 204) {
          getAllDealRoomData();
          showMessage("Deal room closed");
        } else {
          showMessage("Error in closing deal room", "error");
        }
      })
      .catch((err) => {
        console.log("error in closeDealroom", err);
      });
  };

  return (
    <div className={styles.dealRoom}>
      <div id="header" className={getHeaderClass(menuState)}>
        <div>{/* <h1>{strings.pages.contractList.title}</h1> */}</div>
        <HeaderButtons />
      </div>
      <div className={styles.landingPageHeader}>
        <div className={styles.headerFirstCol}>
          <div className={styles.headingTextBox}>
            <span className={styles.subHeading}>Virtual Deal Room</span>
          </div>
        </div>
        {user?.userProfileType === UserType.COMPANY_ADMIN && (
          <div className={styles.headerSecondCol}>
            <Button
              className="default-btn"
              variant="contained"
              onClick={() => navigation("/virtualDealRoom/create")}
            >
              <AddIcon />
              <span>New Project</span>
            </Button>
          </div>
        )}
      </div>
      <div className={styles.landingPageBody}>
        <div className={styles.payPageHeading}>
          {loadApi && rows?.length <= 0 && (
            <span>
              Start Strong and Close Stronger with Our Virtual Deal Room
            </span>
          )}
        </div>

        {loadApi &&
          (rows?.length > 0 ? (
            <div className={styles.tableBox} style={{ padding: "12px 0" }}>
              <VirtualRoomTable
                rows={rows}
                column={virtualDealRoomCol}
                downloadAllDealroomData={downloadAllDealroomData}
                downloadAllQuestionnaireHandler={
                  downloadAllQuestionnaireHandler
                }
                closeDealroom={closeDealroomHandler}
              />
            </div>
          ) : (
            <div className={styles.tableBox}>
              <iframe
                width="100%"
                height="350"
                src="https://www.youtube.com/embed/YOUR_VIDEO_ID_HERE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DealRoom;
