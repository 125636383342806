import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "@mui/material";
import strings from "../../../../localization";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";

const FolderForm = ({
  handleCloseModal,
  handleConfirm,
  folder,
  allFolders,
  selectedFolder,
  setFolderData,
}) => {
  const form = useForm();
  const {
    data,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;
  const [nameErr, setNameErr] = useState(false);

  const onSubmit = (data) => {
    if (data && data?.name) {
      setFolderData(data);
    }
    setNameErr(false);

    if (allFolders?.find((e) => e.name === data.name) == undefined) {
      let folderData = data;

      if (folder !== null) {
        folderData.id = folder?.id;
      }

      handleConfirm(folderData);
      handleConfirm(data);
    } else {
      setNameErr(true);
    }
  };

  return (
    <FormProvider {...form}>
      <div id="modal-form" className="confirm-modal modal-form">
        <h1 className="title">
          {folder == null
            ? strings.forms.contractList.folderForm.createFolderTitle
            : strings.forms.contractList.folderForm.editFolderTitle}
        </h1>

        <div className="field-container">
          <span className="label">
            {strings.forms.contractList.folderForm.folderName}
          </span>
          <TextFieldControl
            name="name"
            control={data}
            defaultValue={folder == null ? "" : folder.name}
            rules={{ required: true }}
            margin="normal"
            fullWidth
            error={Boolean(errors.name)}
            helperText={
              errors.name &&
              strings.forms.contractList.folderForm.enterFolderName
            }
            placeholder={strings.forms.contractList.folderForm.enterFolderName}
            inputRef={true}
          />
          {nameErr && (
            <span className="error-msg">
              {strings.forms.contractList.folderForm.folderNameErr}
            </span>
          )}
        </div>

        <div className="buttons-container">
          <Button
            className="neutral-btn btn cancel-btn"
            variant="contained"
            onClick={handleCloseModal}
          >
            {strings.forms.contractList.cancel}
          </Button>
          <Button
            className="default-btn btn"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {strings.forms.contractList.submit}
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default FolderForm;
