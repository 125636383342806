import React from "react";
import { matchPath, Route, Routes } from "react-router-dom";
import Login from "./Pages/User/Login";
import Registration from "./Pages/User/Registration/Registration";
import UserType from './Pages/User/Registration/UserType/UserType';
import InternalServerError from "./Pages/Error/InternalServerError";
import Lock from "./Pages/User/Lock";
import NotFound from "./Pages/Error/NotFound";
import BadRequest from "./Pages/Error/BadRequest";
import Forbidden from "./Pages/Error/Forbidden";
import Unauthorized from "./Pages/Error/Unauthorized";
import { isUserLoggedIn } from "./Base/OAuth";
import ResetPassword from "./Pages/User/ResetPassword";
import ResetPasswordRequest from "./Pages/User/ResetPasswordRequest";
import ResetPasswordSuccess from "./Pages/User/ResetPasswordSuccess";
import AdminCategories from "./Pages/Admin/AdminCategories/AdminCategories";
import AdminPackages from './Pages/Admin/AdminPackages/AdminPackages';
import AdminAllTemplates from './Pages/Admin/AdminAllTemplates';
import AdminTemplates from './Pages/Admin/AdminTemplates/AdminTemplates';
import AddAdminTemplate from "./Pages/Admin/AdminTemplates/AddAdminTemplate";
import Dashboard from './Pages/Dashboard/Dashboard';
import MyDetails from './Pages/Dashboard/MyDetails';
import PricingAndPackage from './Pages/Dashboard/PricingAndPackage/PricingAndPackage';
import SubscriptionDetails from './Pages/Dashboard/SubscriptionDetails';
import ActivateUser from "./Pages/User/ActivateUser";
import ContractDashboard from './Pages/Contract/ContractDashboard/ContractDashboard';
import AddContract from './Pages/Contract/AddContract/AddContract';
import ContractList from './Pages/ContractList/ContractList'
import ContractPreview from "./Pages/ContractList/ContractPreview";
import UserList from './Pages/UserList/UserList';
import ContractPreviewExternal from "./Pages/ContractList/ContractPreviewExternal";
import AITab from "./Pages/AI/AITab";
import AdminAIData from "./Pages/Admin/AdminAIData/AdminAIData";
import AIData from "./Pages/Admin/AdminAIData/AIData";
import AIDataCreate from "./Pages/Admin/AdminAIData/AIDataCreate";
import AIRequestData from "./Pages/Admin/AdminAIData/AIRequestData";
import AIRequestDataView from "./Pages/Admin/AdminAIData/AIRequestDataView";
import AdminDealRoom from "./Pages/Admin/AdminDealRoom/AdminDealRoom";
import DealRoomDashboard from "./Pages/dealRoom/DealRoomDashboard/DealRoomDashboard";
import DealRoom from "./Pages/dealRoom/DealRoom";
import CreateDealRoomForm from "./Pages/dealRoom/CreateDealRoomForm";
import DealRoomPaymentPage from "./Pages/dealRoom/DealRoomPaymentPage";
import DocPreview from "./Pages/dealRoom/DealRoomDashboard/DocPreview";

let aiPages = {
    AITab: {
        path: '/ai-assist',
        component: <AITab />,
        auth: true,
        exact: true
    }
}

let userPages = {
    Login: {
        path: '/login',
        component: <Login />,
        auth: false,
        exact: true
    },
    Registration: {
        path: '/registration',
        component: <Registration />,
        auth: false,
        exact: true
    },
    UserType: {
        path: '/registration-details',
        component: <Registration />,
        auth: false,
        exact: true
    },
    Lock: {
        path: '/lock',
        component: <Lock />,
        auth: false,
        exact: true
    },
    ResetPassword: {
        path: '/reset-password',
        component: <ResetPassword />,
        auth: false,
        exact: true
    },
    ResetPasswordRequest: {
        path: '/reset-password-request',
        component: <ResetPasswordRequest expired={false} />,
        auth: false,
        exact: true
    },
    ResetPasswordRequestExpired: {
        path: '/reset-password-request-expired',
        component: <ResetPasswordRequest expired={true} />,
        auth: false,
        exact: true
    },
    ResetPasswordSuccess: {
        path: '/reset-password-success',
        component: <ResetPasswordSuccess />,
        auth: false,
        exact: true
    },
    ActivateUser: {
        path: '/user/activate',
        component: <ActivateUser />,
        auth: false,
        exact: true
    },

}

let contractPages = {
    ContractDashboard: {
        path: '/contract-dashboard',
        component: <ContractDashboard />,
        auth: true,
        exact: true
    },
    AddContract: {
        path: '/add-contract',
        component: <AddContract />,
        auth: true,
        exact: true
    },
    MyContracts: {
        path: '/my-contracts',
        component: <AddContract />,
        auth: true,
        exact: true
    },
    MyFolders: {
        path: '/my-folders',
        component: <AddContract />,
        auth: true,
        exact: true
    }
}

let contractListPages = {
    ContractList: {
        path: '/contract-list',
        component: <ContractList />,
        auth: true,
        exact: true
    },
    ContractPreview: {
        path: '/contract-preview/:id',
        component: <ContractPreview />,
        auth: true,
        exact: true
    },
    ContractPreviewExternal: {
        path: '/contract-preview',
        component: <ContractPreviewExternal />,
        auth: false,
        exact: true
    }
}

let adminPages = {
    AdminAllTemplates: {
        path: '/admin-all-templates',
        component: <AdminAllTemplates />,
        auth: true,
        exact: true
    },
    AdminCategories: {
        path: '/admin-template-management-categories',
        component: <AdminCategories />,
        auth: true,
        exact: true
    },
    AdminPackages: {
        path: '/admin-template-management-packages',
        component: <AdminPackages />,
        auth: true,
        exact: true
    },
    AdminTemplates: {
        path: '/admin-template-management-templates',
        component: <AdminTemplates />,
        auth: true,
        exact: true
    },
    AddAdminTemplate: {
        path: '/add-admin-template',
        component: <AddAdminTemplate />,
        auth: true,
        exact: true
    },
    AdminAIData: {
        path: '/admin-template-management-AIAdminData',
        component: <AdminAIData />,
        auth: true,
        exact: true
    },
    AdminAIDataList: {
        path: '/AIData',
        component: <AIData />,
        auth: true,
        exact: true
    },
    AdminAIDataCreate: {
        path: '/AIDataCreate',
        component: <AIDataCreate />,
        auth: true,
        exact: true
    },
    AdminAIDataView: {
        path: '/AIData/:id',
        component: <AIDataCreate />,
        auth: true,
        exact: true
    },
    AdminAIDataRequestList: {
        path: '/AIRequestData',
        component: <AIRequestData />,
        auth: true,
        exact: true
    },
    AdminAIDataRequestView: {
        path: '/AIRequestData/:id',
        component: <AIRequestDataView />,
        auth: true,
        exact: true
    },
    AdminDealRoom: {
        path: '/admin-template-management-AdminDealRoom',
        component: <AdminDealRoom />,
        auth: true,
        exact: true
    },
}

let errorPages = {
    InternalServerError: {
        path: '/500',
        component: <InternalServerError />,
        auth: false,
        exact: true
    },
    NotFound: {
        path: '/404',
        component: <NotFound />,
        auth: false,
        exact: true
    },
    BadRequest: {
        path: '/400',
        component: <BadRequest />,
        auth: false,
        exact: true
    },
    Forbidden: {
        path: '/403',
        component: <Forbidden />,
        auth: false,
        exact: true
    },
    Unauthorized: {
        path: '/401',
        component: <Unauthorized />,
        auth: false,
        exact: true
    }
}

let dashboardPages = {
    Home: {
        path: '/',
        component: <Dashboard />,
        auth: true,
        exact: true
    },
    Dashboard: {
        path: '/dashboard',
        component: <Dashboard />,
        auth: true,
        exact: true
    },
    MyDetails: {
        path: '/my-details',
        component: <MyDetails />,
        auth: true,
        exact: true
    },
    PricingAndPackage: {
        path: '/pricing-and-package',
        component: <PricingAndPackage />,
        auth: true,
        exact: true
    },
    PaymentAndOffers: {
        path: '/subscription-details',
        component: <SubscriptionDetails />,
        auth: true,
        exact: true
    }
}

let usersPages = {
    UserList: {
        path: '/user-list',
        component: <UserList />,
        auth: true,
        exact: true
    },
}

let dealRoomPages = {
    virtualDealRoom: {
        path: '/virtualDealRoom',
        component: <DealRoom />,
        auth: true,
        exact: true
    },
    createDealRoomForm: {
        path: '/virtualDealRoom/create',
        component: <CreateDealRoomForm />,
        auth: true,
        exact: true
    },
    DealRoomPayment: {
        path: '/virtualDealRoom/payment/:id',
        component: <DealRoomPaymentPage />,
        auth: true,
        exact: true
    },
    DealRoomDashboard: {
        path: '/virtualDealRoom/dashboard/:tab/:id',
        component: <DealRoomDashboard />,
        auth:true,
        exact: true
    },
    DocView: {
        path: '/virtualDealRoom/DocPreview/:id',
        component: <DocPreview />,
        auth:true,
        exact: true
    },
    
}

let ROUTES = {
};

Object.assign(ROUTES, ROUTES, aiPages);
Object.assign(ROUTES, ROUTES, userPages);
Object.assign(ROUTES, ROUTES, contractPages);
Object.assign(ROUTES, ROUTES, contractListPages);
Object.assign(ROUTES, ROUTES, errorPages);
Object.assign(ROUTES, ROUTES, adminPages);
Object.assign(ROUTES, ROUTES, dashboardPages);
Object.assign(ROUTES, ROUTES, usersPages);
Object.assign(ROUTES, ROUTES, dealRoomPages);

export function getRoutes() {

    let result = [];

    for (const [key, value] of Object.entries(ROUTES)) {

        result.push(
            <Route key={'route-' + key} path={value.path} element={value.component} />
        )
    }

    return <Routes>{result}</Routes>;
}

function getRoute(path) {

    for (const [key, value] of Object.entries(ROUTES)) {
        const match = matchPath({
            path: value.path,
            exact: value.exact
        }, path);
        if (match) {
            return value;
        }
    }

    return null;
}

export function checkPath(path) {

    let pathObject = getRoute(path);

    if (!pathObject) {
        return true;
    }

    if (pathObject.auth) {
        return !isUserLoggedIn();
    }

    return false;
}