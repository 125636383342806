import React from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const OverviewSummaryDetailsModal = ({
  closeModal,
  overviewData,
  closeDealRoomHandler,
}) => {
  const params = useParams();
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  return (
    <Box
      id="confirm-modal"
      className="confirm-modal"
      sx={{ backgroundColor: "#FFFFFF !important", color: "#283959" }}
    >
      <Box display="flex" flexDirection={"column"} p={2}>
        <Box width={"100%"} display={"flex"} justifyContent={"flex-endi"}>
          <IconButton
            className={"cancel-btn-icon"}
            sx={{ alignSelf: "flex-end", marginLeft: "auto" }}
            onClick={() => closeModal()}
          >
            <CloseIcon sx={{ color: "#283959" }} />
          </IconButton>
        </Box>
        <Box display={"flex"} alignItems={"center"} pb={4} sx={{}}>
          <Box
            sx={{
              height: "1.5rem",
              width: "1.5rem",
              padding: "1rem",
              borderRadius: "50%",
              backgroundColor: "#e6e6e6",
            }}
          >
            <MonetizationOnIcon sx={{ fontSize: "1.5rem", color: "#919191" }} />
          </Box>
          <Box ml={2}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "1.25rem",
                marginBottom: "0.625rem",
              }}
            >
              Sale Price
            </Typography>
            <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
              £ &nbsp;{overviewData?.salePrice}
            </Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{
            padding: "2rem 0rem",
            borderTop: "2px solid #e6e6e6",
            borderBottom: "2px solid #e6e6e6",
          }}
        >
          <Box
            sx={{
              height: "1.5rem",
              width: "1.5rem",
              padding: "1rem",
              borderRadius: "50%",
              backgroundColor: "#e6e6e6",
            }}
          >
            <CalendarMonthIcon sx={{ fontSize: "1.5rem", color: "#919191" }} />
          </Box>
          <Box ml={2}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "1.25rem",
                marginBottom: "0.625rem",
              }}
            >
              Target Completion Date
            </Typography>
            <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
              {formatDate(overviewData?.targetCompletionDate)}
            </Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{
            padding: "2rem 0rem",
            borderBottom: "2px solid #e6e6e6",
          }}
        >
          <Box
            sx={{
              height: "1.5rem",
              width: "1.5rem",
              padding: "1rem",
              borderRadius: "50%",
              backgroundColor: "#e6e6e6",
            }}
          >
            <AdsClickIcon sx={{ fontSize: "1.5rem", color: "#919191" }} />
          </Box>
          <Box ml={2}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "1.25rem",
                marginBottom: "0.625rem",
              }}
            >
              Deal Room Expiry Date
            </Typography>
            <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
              {formatDate(overviewData?.expiryDate)}
            </Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mt={4}
        >
          <Button
            className="default-btn"
            sx={{
              backgroundColor: "#82F5DE !important",
              color: "#283959 !important",
              fontWeight: "bold",
              fontSize: "1.25rem",
              textTransform: "none",
              borderRadius: "0.5rem",
              marginRight: "1rem",
            }}
            onClick={() => closeDealRoomHandler()}
          >
            Close Deal Room
          </Button>
          <Button
            className="default-btn"
            sx={{
              borderRadius: "0.5rem",
              fontSize: "1.25rem",
              textTransform: "none",
            }}
            onClick={() =>
              navigate(`/virtualDealRoom/payment/${params?.id}`, {
                state: {
                  extendSubscription: true,
                },
              })
            }
          >
            Extend Deal room
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OverviewSummaryDetailsModal;
