import React, { useRef, useState } from "react";
import styles from "../../../../../Pages/dealRoom/DealRoom.module.css";
import { dealRoomParty } from "../../../../../Constants/Admin/DealRoomModel";

const ProjectForm = ({ formData, setFormData }) => {

  const inputRef = useRef(null);

  const [isOpenChoiceBox, setisOpenChoiceBox] = useState({});

  const handleMouseLeave = () => {
    inputRef?.current?.blur();
    setisOpenChoiceBox({ ...isOpenChoiceBox, party: false })
    // setisOpenChoiceBox({ ...isOpenChoiceBox, sector: false })
  };

  const setProjectDetailsHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setisOpenChoiceBox({ ...isOpenChoiceBox, [name]: true })

    if (name === "party") {
      value = parseInt(value);
    }
    let tempData = structuredClone(formData);
    tempData.project[name] = value;
    setFormData(tempData);
  };

  const selectPartyHandler = (value) => {
    let tempData = structuredClone(formData);
    tempData.project['party'] = value;
    setFormData(tempData);
  };

  return (
    <div className={styles.formContainer} style={{ marginTop: "16px" }}>
      <div
        className={styles.headingTextBox}
        style={{ margin: "0", width: "100%" }}
      >
        <span className={styles.subHeading}>Project</span>
      </div>

      <div className={styles.formRow}>
        <div className={styles.filledBox}>
          <div className={styles.labelText}>
            <span className="required">Project Name</span>
          </div>
          <div className={styles.inputContainer}>
            <input
              className={styles.inputBox}
              value={formData?.project?.name}
              name="name"
              onChange={setProjectDetailsHandler}
              type="text"
              placeholder="Add project name"
            />
          </div>
        </div>
      </div>

      <div className={styles.formRow} onMouseLeave={handleMouseLeave}>
        <div className={styles.filledBox}>
          <div className={styles.labelText}>
            <span className="required">Party</span>
          </div>
          <div className={styles.inputContainer}
            style={{ position: "relative" }}
          >
            <input
              readOnly
              value={formData?.project?.party === 0 ? "Buyer" : "Seller"}
              name="party"
              // onChange={setProjectDetailsHandler}
              className={styles.inputBox}
              ref={inputRef}
              type="text"
              onFocus={() => setisOpenChoiceBox({ ...isOpenChoiceBox, party: true })}
              placeholder="Please Select Party"
              style={{
                WebkitAppearance: "none",
                background: `url(/images/icons/downArrow.png)`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right center",
              }}
            />
            {isOpenChoiceBox?.party && (
              <div className={styles.options}>
                <ul onMouseLeave={handleMouseLeave}>
                  <li onClick={() => selectPartyHandler(dealRoomParty[0].value)}>
                    {dealRoomParty[0].name}
                  </li>
                  <li onClick={() => selectPartyHandler(dealRoomParty[1].value)}>
                    {dealRoomParty[1].name}
                  </li>
                </ul>
              </div>
            )}


          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectForm;
