import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getNotifications(data) {
    return await request('/api/notification/all', data);
}

export async function setNotificationRead(id) {
    return await request('/api/notification/read/' + id, {}, HttpMethod.PATCH);
}

export async function readAllNotifications() {
    return await request('/api/notification/read/all', {}, HttpMethod.PATCH);
}