import { Button } from "@mui/material";
import React from "react";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const UnsubscribeInfo = (props) => {
    return(
        <div id="info-modal" className={props.style ? props.style + " confirm-modal info-modal" : "confirm-modal info-modal"}>
            <div className="close-btn-container">
                <CloseIcon className="close-icon" onClick={props.onClose} />
            </div>
            <p className="info-title">{props.title} 
                {props.value &&
                    <span> {props.value}</span>
                }
            </p>

            {props.confirmBtn &&
                <div className="confirm-buttons">
                    <Button className={props.btnStyle ? props.btnStyle : "neutral-btn btn"} variant="contained" 
                        onClick={props.handleConfirmModal}>{props.confirmBtn}</Button>
                </div>
            }
        </div>                     
    )
}

export default UnsubscribeInfo;