import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../../Pages/dealRoom/DealRoom.module.css";
import { getSectors } from "../../../../../Constants/User/Sector";

const TargetCompanyForm = ({ formData, setFormData }) => {

  const inputRef = useRef(null);
  const inputRef1 = useRef(null);

  const [companiesList, setCompaniesList] = useState([]);
  const [isOpenChoiceBox, setisOpenChoiceBox] = useState({});
  const [companySearchText, setCompanySearchText] = useState("");

  const handleMouseLeave = () => {
    inputRef?.current?.blur();
    inputRef1?.current?.blur();
    setisOpenChoiceBox({ ...isOpenChoiceBox, type: false, sector: false })
    // setisOpenChoiceBox({ ...isOpenChoiceBox, sector: false })
  };

  const setCompanyDetailsHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setisOpenChoiceBox({ ...isOpenChoiceBox, [name]: true })

    if (name === "name") {
      setCompanySearchText(value);
    }

    companyHouseSearch(value);
    let tempData = structuredClone(formData);
    tempData.targetCompany[name] = value;
    setFormData(tempData);
  };

  const companyHouseSearch = (term) => {
    fetch(
      "https://api.company-information.service.gov.uk/search/companies?q=" + term,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "76e6bfbb-e044-4b6b-b5f5-fd49072b21bd",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setCompaniesList(data ? data.items : []);
      });
  };

  const selectCompanyHandler = (item) => {
    let tempData = structuredClone(formData);
    tempData.targetCompany["name"] = item?.title;
    tempData.targetCompany["address"] = item?.address_snippet;
    setFormData(tempData);
  };
  const selectSectorHandler = (item) => {
    let tempData = structuredClone(formData);
    tempData.targetCompany["sector"] = item?.name;

    setFormData(tempData);
  };

  const selectChoiceOptionHandler = (name, value) => {
    let tempData = structuredClone(formData);
    tempData.targetCompany[name] = value;
    setFormData(tempData);
  }


  useEffect(() => {
    if (companySearchText) {
      companyHouseSearch(companySearchText);
    }
  }, [companySearchText]);

  return (
    <div className={styles.formContainer} style={{ marginTop: "1rem" }}>
      <div
        className={styles.headingTextBox}
        style={{ margin: "0", width: "100%" }}
      >
        <span className={styles.subHeading}>Target Company</span>
      </div>

      <div className={styles.formRow}>
        <div className={styles.filledBox}>
          <div className={styles.labelText}>
            <span className="required">Company Name</span>
          </div>

          <div
            className={styles.inputContainer}
            style={{ position: "relative" }}
          >
            <input
              className={styles.inputBox}
              value={formData?.targetCompany?.name}
              name="name"
              onChange={setCompanyDetailsHandler}
              type="text"
              placeholder="Company Name"
            />
            {isOpenChoiceBox?.name && (
              <div className={styles.options}>
                <ul onMouseLeave={() => setisOpenChoiceBox({ ...isOpenChoiceBox, name: false })}>
                  {companiesList.map((item, index) => {
                    return (
                      <li id={index} onClick={() => selectCompanyHandler(item)}>
                        {item.title}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.formRow} onMouseLeave={handleMouseLeave}>
        <div className={styles.filledBox}>
          <div className={styles.labelText}>
            <span className="required">Company Type</span>
          </div>
          <div className={styles.inputContainer}
            style={{ position: "relative" }}>
            <input
              readOnly
              value={formData?.targetCompany?.type}
              name="type"
              ref={inputRef}
              // onChange={setCompanyDetailsHandler}
              className={styles.inputBox}
              type="text"
              onFocus={() => setisOpenChoiceBox({ ...isOpenChoiceBox, type: true })}
              placeholder="Please Select Type"
              style={{
                WebkitAppearance: "none",
                background: `url(/images/icons/downArrow.png)`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right center",
              }}
            />
            {isOpenChoiceBox?.type && (
              <div className={styles.options}>
                <ul onMouseLeave={handleMouseLeave}>

                  <li onClick={() => selectChoiceOptionHandler('type', 'private')}>
                    Private
                  </li>
                  <li onClick={() => selectChoiceOptionHandler('type', 'public')}>
                    Public
                  </li>
                </ul>
              </div>
            )}
            {/* <option value={"private"}>{"Private"}</option>;
              <option value={"public"}>{"Public"}</option>; */}

          </div>
        </div>
      </div>

      <div className={styles.formRow}>
        <div className={styles.filledBox}>
          <div className={styles.labelText}>
            <span className="required">Company Address</span>
          </div>
          <div className={styles.inputContainer}>
            <input
              className={styles.inputBox}
              value={formData?.targetCompany?.address}
              name="address"
              onChange={setCompanyDetailsHandler}
              type="text"
              placeholder="Company Address"
            />
          </div>
        </div>
      </div>

      <div className={styles.formRow} onMouseLeave={handleMouseLeave}>
        <div className={styles.filledBox}>
          <div className={styles.labelText}>
            <span className="required">Company Sector</span>
          </div>
          <div className={styles.inputContainer}
            style={{ position: "relative" }}
          >
            <input
              readOnly
              value={formData?.targetCompany?.sector}
              name="sector"
              ref={inputRef1}
              onFocus={() => setisOpenChoiceBox({ ...isOpenChoiceBox, sector: true })}
              // onChange={setCompanyDetailsHandler}
              className={styles.inputBox}
              placeholder="Please Select Sector"
              style={{
                WebkitAppearance: "none",
                background: `url(/images/icons/downArrow.png)`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right center",
              }}
            />
            {isOpenChoiceBox?.sector && (
              <div className={styles.options}>
                <ul onMouseLeave={() => setisOpenChoiceBox({ ...isOpenChoiceBox, name: false })}>
                  {getSectors().map((item, index) => {
                    return (
                      <li key={index} onClick={() => selectSectorHandler(item)}>
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetCompanyForm;
