import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getTemplateQuestionOptionClause(id) {
    return await request('/api/templatequestionoptionclause/' + id);
}

export async function getAllTemplateQuestionOptionClauses(optionId) {
    return await request('/api/templatequestionoptionclause/all/' + optionId);
}

export async function deleteTemplateQuestionOptionClause(id) {
    return await request('/api/templatequestionoptionclause/' + id, {}, HttpMethod.DELETE);
}

export async function addTemplateQuestionOptionClause(data) {
    return await request('/api/templatequestionoptionclause/add', data, HttpMethod.POST);
}

export async function editTemplateQuestionOptionClause(data) {
    return await request('/api/templatequestionoptionclause/edit/' + data.id, data, HttpMethod.PUT);
}

function transformRequestData(data) {
    return data;
}