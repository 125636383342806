import React, {useEffect} from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Paper } from "@mui/material";
import strings from "../../localization";
import RegisterForm from "../Forms/Pages/User/RegisterForm";
import ValidationPatterns from "../../Constants/Base/ValidationPatterns";
import { Link } from "react-router-dom";

const formRules = {
    'fullName': {required: { value: true, message: strings.forms.common.required},
        pattern: {value: ValidationPatterns.NAME, message: strings.forms.registration.fullNameError}},
    'email': {required: { value: true, message: strings.forms.common.required},
        pattern: { value: ValidationPatterns.EMAIL, message: strings.forms.registration.emailError, }},
    'password': {required: { value: true, message: strings.forms.common.required},
        pattern: { value: ValidationPatterns.PASSWORD, message: strings.forms.registration.passwordError}}
}

const RegistrationModal = (props) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;

    useEffect(() => {
        if(props.externalUser) {
            form.setValue('email', props.externalUser.email);
        }
    }, [])
 
    return(
        <FormProvider {...form}>
        <div id="simple-confirm-modal" className="confirm-modal registration-modal">
            <div className="form-fields">
                <div id='half-page-container' className={'full-form-page auth-page'}>
                    <div className='form-container'>
                        <Paper className='paper'>

                            <img className="logo-img" src='/images/logo.png' />
                            <h1>{ strings.pages.registration.createAccount }</h1>
                            <div className="text-with-link-container">
                                <p>{strings.pages.registration.alreadyHaveAccount}</p>
                                <Link to={'/login'} className="middle-text-link">
                                    {strings.pages.registration.signIn}
                                </Link>
                            </div>

                            <RegisterForm 
                                errors={ errors } 
                                data={ data } 
                                form={ form } 
                                control={control}
                                values={getValues()}
                                setValue={setValue}
                                formRules={formRules}
                                onSubmit={handleSubmit(props.onSubmitData)} 
                            />
                        </Paper>
                    </div>
                </div>
            </div>
        </div>
    </FormProvider>                    
    )
}

export default RegistrationModal;