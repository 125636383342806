import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import GeneralInformationForm from '../../Components/Forms/Pages/Dashboard/MyDetails/GeneralInformationForm';
import CompanyProfileForm from '../../Components/Forms/Pages/Dashboard/MyDetails/CompanyProfileForm';
import {getSubscriptionData} from "../../Services/Subscribe/SubscribeService";
import Modal from "@mui/material/Modal";
import UnsubscribeInfo from '../../Components/Modals/Pages/Dashboard/UnsubscribeInfo';
import strings from "../../localization";
import { useLocation } from "react-router-dom";
import UserType from "../../Constants/User/UserType";
import WelcomeInfo from '../../Components/Modals/WelcomeInfo';
import {discardFirstLogin} from "../../Services/User/UserService";
import {setAuth} from "../../Slices/AuthSlice";
import {useNavigate} from "react-router-dom";
import {isTrial, isTrialPackageValid} from "../../Util/PermissionUtil";
import {getContractCount} from "../../Services/Company/CompanyService";
import ConfirmModal from "../../Components/Modals/ConfirmModal";

const MyDetails = () => {

    const user = useSelector((state) => state.auth.user)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(user)
    const [openUnsubscriptionInfo, setOpenUnsubscriptionInfo] = useState(false)
    const location = useLocation();
    const [openFirstSignupModal, setOpenFirstSignupModal] = useState(user.firstLogin)
    const [openNonValidSubscription, setOpenNonValidSubscription] = useState(false);
    
    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));

        if(location.state) {
            fetch()
        }
    }, [])

    const fetch = () => {
        getSubscriptionData().then(response => {

            if (response.data?.product == null && currentUser.userProfileType === UserType.INDIVIDUAL) {
                setOpenUnsubscriptionInfo(true)
            }
        })

        getContractCount().then(response => {
            if (!isTrialPackageValid(currentUser, response.data) && !user.firstLogin) {
                setOpenNonValidSubscription(true);
            }
        })
    }

    const closeUnsubscriptionInfo = () => {
        setOpenUnsubscriptionInfo(false)
    }

    const onSubmit = (data) => {
        let updatedUser = {...currentUser, ...data}
    }

    const closeFirstSignupModal = () => {

        discardFirstLogin().then(response => {

            if(!response || !response.ok) {
                return;
            }

            dispatch(setAuth(response.data));
        });

        setOpenFirstSignupModal(false)
    }

    const handleAddInfo = () => {
        navigate('/registration-details', {state: {userProfileType: currentUser.userProfileType}});
    }

    return (
        <div className="tab-page-content">
            <div id="dashboard" className="dashboard my-details">
                <div className="left dashboard-box">
                    <GeneralInformationForm 
                        handleAddInfo={handleAddInfo} 
                        userProfileType={currentUser.userProfileType}
                    />
                </div>

                {currentUser.userProfileType === UserType.COMPANY_ADMIN &&
                    <div className="right dashboard-box">
                        <CompanyProfileForm handleAddInfo={handleAddInfo} />
                    </div>
                }

                {/*<Modal*/}
                {/*    open={openUnsubscriptionInfo}*/}
                {/*    onClose={closeUnsubscriptionInfo}*/}
                {/*    className='confirm-modal-container'*/}
                {/*>*/}
                {/*    <UnsubscribeInfo*/}
                {/*        title={strings.modals.subscriptionDetails.unsubscribeInfoLogin.infoText}*/}
                {/*        value={false}*/}
                {/*        confirmBtn={false}*/}
                {/*        onClose={closeUnsubscriptionInfo}*/}
                {/*        handleConfirmModal={false}*/}
                {/*        style='unsubscribe-info'*/}
                {/*        btnStyle='default-btn btn'*/}
                {/*    />*/}
                {/*</Modal>*/}

                <Modal
                    open={openFirstSignupModal && user.firstLogin}
                    onClose={closeFirstSignupModal}
                    className='confirm-modal-container'
                >
                    <WelcomeInfo onClose={closeFirstSignupModal} />
                </Modal>

                <Modal
                    open={openNonValidSubscription}
                    onClose={() => setOpenNonValidSubscription(false)}
                    className='confirm-modal-container'
                >
                    <ConfirmModal
                        contentText={
                            isTrial(currentUser) ? strings.modals.contractList.nonValidSubscription.confirmTextFreeTrial : strings.modals.contractList.nonValidSubscription.confirmTextSubscriptionEnd
                        }
                        cancelBtn={strings.modals.contractList.nonValidSubscription.no}
                        confirmBtn={strings.modals.contractList.nonValidSubscription.yes}
                        handleCloseModal={() => setOpenNonValidSubscription(false)}
                        handleConfirm={() => navigate('/pricing-and-package')}
                    />
                </Modal>
           </div>
        </div>
    )
}

export default MyDetails;