import {Box, TextField} from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import React, {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {dateTimeToString} from "../../../Util/DateUtil";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

const TimePickerControl = ({data, value, setValue, size, label, name, disabled, onChange}) => {

    const [date, setDate] = useState(value ? value : new Date());
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setDate(value ? value : new Date())
    }, [value])

    const getAMPM = (date) => {
        return date.getHours() >= 12 ? "PM" : "AM";
    }

    function addMinutes(minutes) {
        let newDate = new Date(date.getTime() + minutes * 60000);
        setDate(newDate)

        setValue(name, dateTimeToString(newDate, 'h:mm') + ' ' + getAMPM(newDate));
        setValue(name + '-raw', newDate);

        if(onChange) {
            onChange(newDate)
        }
    }

    return <Controller
        control={data}
        render={({ field }) =>
            <Box className='datetime-control time-control textfield-control'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                        inputFormat="h:mm a"
                        disableOpenPicker={true}
                        value={date}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        disabled={disabled}
                        onChange={(newValue) => {
                            setValue(name, dateTimeToString(newValue, 'h:mm') + ' ' + getAMPM(newValue));
                            setValue(name + '-raw', newValue);
                            setDate(newValue);

                            if(onChange) {
                                onChange(newValue)
                            }
                        }}
                        renderInput={(params) =>
                            <React.Fragment>
                                <TextField {...params}
                                           onClick={e => setOpen(!open)}
                                           size={size ? size : 'small'}
                                           fullWidth
                                />
                                <IconButton className={'clock-icon'} onClick={() => setOpen(!open)}>
                                    <AccessAlarmIcon/>
                                </IconButton>
                            </React.Fragment>

                        }
                    />
                </LocalizationProvider>
                {
                    date && open &&
                    <div className={'timepicker-selector-wrapper'}>
                        <label>Select a time</label>
                        <div className={'timepicker-selector-container'}>
                            <div className={'timepicker-control-item'}>
                                <IconButton onClick={() => addMinutes(60)}>
                                    <KeyboardArrowUpIcon/>
                                </IconButton>
                                <span>{dateTimeToString(date, 'h')}</span>
                                <IconButton onClick={() => addMinutes(-60)}>
                                    <KeyboardArrowDownIcon/>
                                </IconButton>
                            </div>
                            <div className={'timepicker-control-item'}>
                                <span>:</span>
                            </div>
                            <div className={'timepicker-control-item'}>
                                <IconButton onClick={() => addMinutes(1)}>
                                    <KeyboardArrowUpIcon/>
                                </IconButton>
                                <span>{dateTimeToString(date, 'mm')}</span>
                                <IconButton onClick={() => addMinutes(-1)}>
                                    <KeyboardArrowDownIcon/>
                                </IconButton>
                            </div>
                            <div className={'timepicker-control-item'}>
                                <IconButton onClick={() => addMinutes(12 * 60)}>
                                    <KeyboardArrowUpIcon/>
                                </IconButton>
                                <span>{getAMPM(date)}</span>
                                <IconButton onClick={() => addMinutes(12 * -60)}>
                                    <KeyboardArrowDownIcon/>
                                </IconButton>
                            </div>
                        </div>
                    </div>

                }
            </Box>
        }
    >
    </Controller>
}

export default TimePickerControl;