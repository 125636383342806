import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getTemplateStep(id) {
    return await request('/api/templatestep/' + id);
}

export async function getAllTemplateSteps(templateId) {
    return await request('/api/templatestep/all/' + templateId);
}

export async function activateAllTemplateSteps(data) {
    return await request('/api/templatestep/all/activate', data, HttpMethod.POST);
}

export async function getAllActivateTemplateSteps(templateId) {
    return await request('/api/templatestep/all/activate/' + templateId);
}

export async function deleteTemplateStep(id) {
    return await request('/api/templatestep/' + id, {}, HttpMethod.DELETE);
}

export async function addTemplateStep(data) {
    return await request('/api/templatestep/add', data, HttpMethod.POST);
}

export async function editTemplateStep(data) {
    return await request('/api/templatestep/edit/' + data.id, data, HttpMethod.PUT);
}

export async function reorderSections(templateId, data) {
    return await request('/api/templatestep/reorder/' + templateId, data, HttpMethod.PUT)
}

function transformRequestData(data) {
    return data;
}