import React, { useState } from "react";
import strings from "../../../../localization";
import { TreeItem, TreeView } from "@mui/lab";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const MoveToFolderModal = (props) => {
    
    const [expanded, setExpanded] = useState([]);

    const handleSelectFolder = (folder) => {
        props.handleConfirm(folder)
    }

    const renderFolders = () => {

        let result = [];

        for (let folder of props.folders) {

            if (folder.parent || folder.inSharedFolder) {                
                continue;
            }

            result.push(
                <TreeItem
                    className={folder.id === -2 ? 'shared-with-me-folder' : ''}
                    nodeId={folder.id}
                    onClick={() => handleSelectFolder(folder)}
                    label={
                        <div className={(folder.id === -1 || folder.id === -2) ? 'tree-item-wrapper main-folders' : 'tree-item-wrapper'}>
                            <img
                                src={expanded.includes(folder.id) ? 'images/icons/open-folder.png' : 'images/icons/folder-new.png'}
                                className='folder-icon'/>
                            <span className={folder.showAdd ? 'show-add' : ''}>
                                {folder.name}
                            </span>
                        </div>
                    }>
                    { renderSubFoldersTree(folder, props.folders) }
                </TreeItem>
            )
        }

        if(expanded.length === 0) {
            setExpanded(expandFolderIfHasChildren(result));
        }

        return result;
    }
      

    const renderSubFoldersTree = (parent, folders) => {
        let result = [];

        for (let folder of folders) {
            if ((parent && folder.parent && folder.parent.id === parent.id) ||
                (parent.id === -2 && folder.inSharedFolder)) {
                result.push(
                    <TreeItem
                        nodeId={folder.id}
                        onClick={() => handleSelectFolder(folder)}
                        label={
                            <div className={'tree-item-wrapper'}>
                                <img
                                    src={expanded.includes(folder.id) ? 'images/icons/open-folder.png' : 'images/icons/folder-new.png'}
                                    className='folder-icon'/>
                                {folder.name}
                            </div>
                        }
                    >
                        {renderSubFoldersTree(folder, folders)}
                    </TreeItem>
                )
            }
        }
        return result;
    }

    const expandFolderIfHasChildren = (elements) => {
        let expandedItems = [];
      
        elements.map((element) => {
          if (element.props.children && element.props.children.length > 0) {
            expandedItems.push(element.props.nodeId);
      
            const childExpandedItems = expandFolderIfHasChildren(element.props.children);
            expandedItems = [...expandedItems, ...childExpandedItems];
          }
        });
      
        return expandedItems;
    }

    return(
        <div id="move-to-folder-modal" className="confirm-modal move-to-folder-modal">
            <h1 className="move-title">{strings.modals.contractList.moveToFolderContract.confirmText}</h1>
            <div className="folders-list">
                <TreeView
                    className="tree-component"
                    defaultCollapseIcon={
                        <div className="icons-container">
                            <KeyboardArrowDownIcon className="arrow-icon arrow-down-icon"/>
                        </div>
                    }
                    defaultExpandIcon={
                        <div className="icons-container">
                            <ArrowForwardIosIcon className="arrow-icon arrow-right-icon"/>
                        </div>
                    }
                    expanded={expanded}
                >
                    { renderFolders() }
                </TreeView>
             </div>
        </div>
    )
}

export default MoveToFolderModal;