import React, { Fragment, useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Controller } from 'react-hook-form';
import FormGroup from '@mui/material/FormGroup';
import { IconButton, Tooltip } from '@mui/material';

const CheckGroupBoxControl = ({value, setValue, name, label, control, defaultValue, disabled, options, description, helperText, error}) => {

    const [checked, setChecked] = useState(value ? value : []);    
    
    const handleChange = (e, optionId) => {
        
        let checkedOptions = [...checked];

        if (e.target.checked) {
            checkedOptions = [...checkedOptions, optionId];
        } else {
            const index = checkedOptions.indexOf(optionId);
            checkedOptions.splice(index, 1);
        }

        setChecked(checkedOptions);        
        setValue(name, checkedOptions);
    };
      
    useEffect(() => {
        if(options && !checked.length > 0) {
            let checkedOptions = [];
            options.map(option => {
                if(option.boolValue || option.isDefault) {
                    checkedOptions = [...checkedOptions, option.id];
                }
            });
            setChecked(checkedOptions);
        }
    }, [options]);

    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            control={control}
            render={({ field: props }) => (
                <FormGroup>
                    <div className='checkbox-control-label'>
                        <p>{label}</p>
                        { description && (
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: description }} />}>
                            <IconButton>
                            <img src="/images/info_circle.svg" />
                            </IconButton>
                        </Tooltip>
                        )}
                    </div>
                    { options.map((option) => (
                        <div className='checkbox-options'>
                            <FormControlLabel
                                key={option.id}
                                label={option.stringValue}
                                className='checkbox-control'
                                control={
                                    <Checkbox
                                        error={props.error}
                                        checked={checked.includes(option.id)}
                                        disabled={disabled}
                                        onClick={(e) => handleChange(e, option.id) }
                                    />
                                }
                            />
                            { option.description &&
                            <Tooltip title={<div dangerouslySetInnerHTML={{ __html: option.description }} />}>
                                <IconButton>
                                    <img src="/images/info_circle.svg" />
                                </IconButton>
                            </Tooltip>
                            }
                        </div>
                    ))}
                    {helperText && <span style={{color: 'red'}}>{helperText}</span> }
                </FormGroup>
            )}
        />
    );
}

export default CheckGroupBoxControl;
