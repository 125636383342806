import React, {useEffect, useState} from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "@mui/material";
import TextFieldControl from '../../../Controls/Inputs/TextFieldControl';
import CreditCardField from '../../../Controls/Inputs/CreditCardField';
import strings from "../../../../localization";

const PaymentMethodModal = (props) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;

    const [errorFullName, setErrorFullName] = useState(false)
    const [errorCard, setErrorCard] = useState(false)

    useEffect(() => {
        setValue('creditCard', null)
    }, [])

    const onSubmit = (data) => {
        if (!props.creditCardNumber){  
            if(data.creditCard == null || data.creditCard == undefined
            || data.creditCard.number.length != 19 
            || data.creditCard.expiry.length != 7 
            || data.creditCard.cvc.length != 3) {
            setErrorCard(true)
            } else {
                setErrorCard(false)
            }
        }else{
            setErrorCard(false);
        }

        if (data.fullName == undefined || data.fullName == '') {
            setErrorFullName(true)
        } else {
            setErrorFullName(false)
        } 

        if (data.creditCard != null             
            && data.creditCard.number.length == 19 
            && data.creditCard.expiry.length == 7 
            && data.creditCard.cvc.length == 3
            && data.fullName != undefined
            && data.fullName.length >= 1) {
                props.handleConfirm(data)
        }
    }
 
    return(
        <FormProvider {...form}>
        <div id="simple-confirm-modal" className="confirm-modal payment-method-modal">
            {!props.creditCardNumber ?
            <span className="simple-confirm-title">{strings.modals.subscriptionDetails.editPaymentMethod.editPaymentMethod}</span> 
            : <span className="simple-confirm-title">{strings.modals.subscriptionDetails.editPaymentMethod.updateCardInfo}</span>
            }
            <div className="form-fields" style={{marginTop: "0px"}}>
                {!props.creditCardNumber &&( 
                <div className="item-field-container">
                    <span className="info-icon-text">
                        {strings.modals.subscriptionDetails.editPaymentMethod.fullName}
                    </span>
                    <TextFieldControl
                        name='fullName'
                        control={data}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.fullName)}
                        helperText={errors.fullName && strings.forms.common.required}
                        placeholder={strings.modals.subscriptionDetails.editPaymentMethod.enterFullName}
                    />
                    {errorFullName &&
                        <span className="error-msg">{strings.forms.common.required}</span>
                    }
                </div>)
                }

                <div className="item-field-container">
                    {!props.creditCardNumber ? 
                    <><span className="info-icon-text" >
                                {strings.modals.subscriptionDetails.editPaymentMethod.creditCard}
                            </span><CreditCardField
                                    name='creditCard'
                                    control={data}
                                    setValue={setValue} /></> :
                                    <><span className="info-icon-text" style={{fontWeight:'800', marginBottom:'5px'}}>
                                {strings.modals.subscriptionDetails.editPaymentMethod.cardNumber}
                            </span><span>{props.creditCardNumber}</span>
                            <span className="info-icon-text"  style={{fontWeight:'800', marginTop:'20px'}}>
                                {strings.modals.subscriptionDetails.editPaymentMethod.expirytime}
                            </span>
                            <span>{props.validationTime}</span>
                            </>
                                
                    }
                    {errorCard &&
                        <span className="error-msg">{strings.forms.common.required}</span>
                    }
                </div>
            </div>

            <div className="confirm-buttons">
                <Button 
                    className="btn cancel-btn" 
                    variant="contained" 
                    onClick={props.handleCloseModal}>
                        {props.cancelBtn}
                </Button>
                <Button 
                    className="secondary-btn btn"
                    variant="contained" 
                    onClick={!props.creditCardNumber ? handleSubmit(onSubmit) : props.handleConfirm}>
                        {props.confirmBtn}
                </Button>
            </div>
        </div> 
    </FormProvider>                    
    )
}

export default PaymentMethodModal;