import React, { useState } from "react";
import LoaderContext from "../../Context/LoaderContext";
import Modal from "@mui/material/Modal";

const LoaderWrapper = ({ children }) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");

  const showLoader = (show) => {
    setShow(show);
  };

  const setLoaderTitle = (title) => {
    setTitle(title);
  };

  const value = { showLoader, setLoaderTitle };

  return (
    <LoaderContext.Provider value={value}>
      <Modal open={show} className="confirm-modal-container">
        <div className="loader-container">
          {title && <p style={{ textAlign: "center" }}>{title}</p>}
          <img src={"/images/LC.gif"} alt="" />
        </div>
      </Modal>

      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderWrapper;
