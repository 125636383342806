import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {deleteTemplateQuestionOptionClause} from "../../../Services/Template/TemplateQuestionOptionClauseService";
import OptionClause from "./OptionClause";

export const OptionClauses = ({clauses, fetch, mainEditor}) => {


    const renderClauses = (clauses) => {

        let result = [];

        for(let clause of clauses) {
            result.push(<OptionClause mainEditor={mainEditor} clause={clause} fetch={fetch}/>)
        }

        return result;
    }

    return <div id='clauses'>
        {renderClauses(clauses)}
    </div>
}