import React from "react";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import strings from "../../localization";

const InternalServerError = () => {

    const dispatch = useDispatch();
    dispatch(changePageSizeState(PageSizeState.FULL));

    return (
        <div className={'error-page'}>
        <h1>500</h1>
        <h3>{ strings.pages.errors.internalServerError }</h3>

        <Link to={'/'}>
            <Button className="btn" variant="contained" color="secondary">
                { strings.pages.errors.dashboard }
            </Button>
        </Link>
    </div>
    );
};
export default InternalServerError;

