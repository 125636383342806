import React, { useEffect, useState } from "react";
import { getPackageByContractCategory } from "../../../Services/ContractCategory/PackageService";

const Package = (props) => {

    const [packages, setPackages] = useState([]);

    useEffect(() => {

        fetchPackages();

    }, [])


    const fetchPackages = () => {

        getPackageByContractCategory(props.category.id).then(response => {

            if (!response || !response.ok) {
                return;
            }

            let result = [];

            for (let item of response.data) {
                if (item.package && item.package.active) {
                    result.push(item.package);
                }
            }

            setPackages(result);
        });

    }

    const getDataItem = () => {
        let result = [];

        if (packages.length > 0) {
            for (let item of packages) {
                result.push(
                    <div className="row" key={item.id}>
                        <p className="row-text">{item.name}</p>
                    </div>
                )
            }
        }

        return result
    }

    const handleAdd = () => {
    }

    return (
        <div className="subitem subitem-2">
            <div className="header">
                <h1>{props.name}</h1>
                <div className='subitem-add-btn' style={{ visibility: 'hidden' }}></div>
            </div>
            <div className="content">
                {getDataItem()}
            </div>
        </div>
    )
}

export default Package;