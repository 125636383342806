import React, {useContext, useEffect, useState} from "react";
import AddIcon from '@mui/icons-material/Add';
import {Button} from "@mui/material";
import Switch from '@mui/material/Switch';
import strings from "../../../localization";
import {
    addContractCategory,
    changeActiveContractCategories
} from "../../../Services/ContractCategory/ContractCategoryService";
import {useForm} from "react-hook-form";
import TextFieldControl from "../../../Components/Controls/Inputs/TextFieldControl";
import {FormProvider} from "react-hook-form";
import SnackbarContext from "../../../Context/SnackbarContext";
// import ConfirmModal from "../../../Components/Modals/ConfirmModal";
// import Modal from "@mui/material/Modal";

const SubCategory = (props) => {

    const [subcategories, setSubcategories] = useState(props.data ? props.data : [])
    const [isAddMode, setIsAddMode] = useState(false);
    // const [openModal, setOpenModal] = useState(false)

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const { showMessage } = useContext(SnackbarContext);

    const handleConfirm = () => {
        
    }

    const changeState = (item) => {

        let data = subcategories;
        let index = data.indexOf(item);

        if(index === -1) {
            return;
        }

        data[index].active = !data[index].active;

        changeActiveContractCategories({
            id: data[index].id,
            active: data[index].active
        }).then(response => {
            if(!response || !response.ok) {
                return;
            }
        })

        showMessage(strings.common.saved)
        setSubcategories([...data]);
    }

    const handleCloseModal = () => {
    }

    const getDataItem = () => {
        let result = [];

        if(!subcategories) {
            return result;
        }

        for (let item of subcategories) {
            result.push(
                <div className="row" key={'subcategory-' + result.length}>
                    <p className={item.active ? "row-text" : "row-text deactivated-text"}>{item.name}</p>
                    <Switch 
                        className={item.active ? "switch-btn switch-active" : "switch-btn switch-deactivated"} 
                        onClick={() => changeState(item)} 
                        checked={item.active}  
                    />
                </div>
            )
        }

        return result
    }

    const handleAdd = () => {
        setIsAddMode(true);
    }

    const submitSubcategory = (data) => {

        addContractCategory({
            name: data.name,
            active: true,
            parentId: props.category.id
        }).then(response => {
            if(props.fetch) {
                props.fetch();
            }

            setSubcategories([
                ...subcategories,
                response.data
            ])
            showMessage(strings.common.added)
            setIsAddMode(false);
        })
    }

    return (
        <div className="subitem">
            <div className="header">
                {
                    !isAddMode &&
                    <React.Fragment>
                        <h1>{props.name}</h1>
                        <Button className='default-btn subitem-add-btn btn' variant='contained' onClick={() => handleAdd()}>
                            <AddIcon/>
                            <span>{strings.pages.adminCategories.new}</span>
                        </Button>
                    </React.Fragment>
                }

                {
                    isAddMode &&
                    <React.Fragment>
                        <FormProvider {...form}>
                            <div className="form-row form-row-with-inputs">
                                <div className="item input-wrap long-input-wrap">
                                    <TextFieldControl
                                        name='name'
                                        control={data}
                                        defaultValue=''
                                        rules={{required: true}}
                                        fullWidth
                                        margin="normal"
                                        error={Boolean(errors.name)}
                                        helperText={errors.name && strings.forms.admin.adminCategoryForm.subCategoryName}
                                        placeholder={strings.forms.admin.adminCategoryForm.subCategoryName}
                                    />
                                </div>
                            </div>
                            <Button className='default-btn subitem-add-btn btn' variant='contained' onClick={handleSubmit(submitSubcategory)}>
                                <span>{strings.pages.adminCategories.add}</span>
                            </Button>
                        </FormProvider>

                    </React.Fragment>
                }

                {/* {
                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className='confirm-modal-container'
                    >
                        <ConfirmModal
                            title={strings.modals.adminPackages.title}
                            contentText={strings.modals.adminPackages.confirmText}
                            cancelBtn={strings.modals.adminPackages.cancelBtn}
                            confirmBtn={strings.modals.adminPackages.deactivateBtn}
                            handleCloseModal={() => handleCloseModal()}
                            handleConfirm={() => handleConfirm()}
                        />
                    </Modal>
                } */}

            </div>
            <div className="content">
                {getDataItem()}
            </div>
        </div>
    )
}

export default SubCategory;