import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllTemplateContractCategoriesByTemplate(templateId) {
    return await request('/api/templatecontractcategory/template/' + templateId);
}

export async function getAllTemplateContractCategoriesByContractCategory(contractCategoryId) {
    return await request('/api/templatecontractcategory/contractCategory/' + contractCategoryId);
}

export async function deleteTemplateContractCategory(id) {
    return await request('/api/templatecontractcategory/' + id, {}, HttpMethod.DELETE);
}

export async function addTemplateContractCategory(data) {
    return await request('/api/templatecontractcategory/add', data, HttpMethod.POST);
}

function transformRequestData(data) {
    return data;
}