import strings from "../../localization";
import QuestionFormat from "./QuestionFormat";

const QuestionRelationType = {
    NONE: -1,
    EQUAL: 1,
    LOWER: 2,
    LOWER_EQUAL: 3,
    GREATER: 4,
    GREATER_EQUAL: 5,
    NO_EQUAL: 6
};

export function getQuestionRelationTypesByQuestionFormat(questionFormat) {

    if(questionFormat === QuestionFormat.SLIDER || questionFormat === QuestionFormat.NUMBER_INPUT
    || questionFormat === QuestionFormat.DATE_PICKER || questionFormat === QuestionFormat.TIME_PICKER) {
        return getQuestionRelationTypes();
    }

    return getQuestionRelationTypesForQuestionOptions();
}

export function getQuestionRelationTypes() {
    return [
        {
            id: QuestionRelationType.EQUAL,
            name: getQuestionRelationTypeString(QuestionRelationType.EQUAL)
        },
        {
            id: QuestionRelationType.LOWER,
            name: getQuestionRelationTypeString(QuestionRelationType.LOWER)
        },
        {
            id: QuestionRelationType.LOWER_EQUAL,
            name: getQuestionRelationTypeString(QuestionRelationType.LOWER_EQUAL)
        },
        {
            id: QuestionRelationType.GREATER,
            name: getQuestionRelationTypeString(QuestionRelationType.GREATER)
        },
        {
            id: QuestionRelationType.GREATER_EQUAL,
            name: getQuestionRelationTypeString(QuestionRelationType.GREATER_EQUAL)
        },
        {
            id: QuestionRelationType.NO_EQUAL,
            name: getQuestionRelationTypeString(QuestionRelationType.NO_EQUAL)
        },

    ]
}

export function getQuestionRelationTypesForQuestionOptions() {
    return [
        {
            id: QuestionRelationType.EQUAL,
            name: getQuestionRelationTypeString(QuestionRelationType.EQUAL)
        },
        {
            id: QuestionRelationType.NO_EQUAL,
            name: getQuestionRelationTypeString(QuestionRelationType.NO_EQUAL)
        },

    ]
}


export function getQuestionRelationTypeString(questionRelationType) {
    switch(questionRelationType) {
        case QuestionRelationType.EQUAL: return strings.constants.admin.questionRelationType.equal;
        case QuestionRelationType.LOWER: return strings.constants.admin.questionRelationType.lower;
        case QuestionRelationType.LOWER_EQUAL: return strings.constants.admin.questionRelationType.lowerEqual;
        case QuestionRelationType.GREATER: return strings.constants.admin.questionRelationType.greater;
        case QuestionRelationType.GREATER_EQUAL: return strings.constants.admin.questionRelationType.greaterEqual;
        case QuestionRelationType.NO_EQUAL: return strings.constants.admin.questionRelationType.notEqual;
    }
}

export default QuestionRelationType;