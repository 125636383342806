import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getTemplate(id) {
    return await request('/api/template/' + id);
}

export async function getAllTemplates(data) {
    return await request('/api/template/all', data);
}

export async function getAllTemplatesByPackage() {
    return await request('/api/template/package/all');
}

export async function changeActiveTemplate(data) {
    return await request('/api/template/active/' + data.id, data, HttpMethod.PATCH);
}

export async function deleteTemplate(id) {
    return await request('/api/template/' + id, {}, HttpMethod.DELETE);
}

export async function addTemplate(data) {
    return await request('/api/template/add', data, HttpMethod.POST);
}

export async function editTemplate(data) {
    return await request('/api/template/edit/' + data.id, data, HttpMethod.PUT);
}

export async function reorderTemplates(data) {
    return await request('/api/template/reorder', data, HttpMethod.PUT)
}

function transformRequestData(data) {
    return data;
}