import React, {useEffect, useState} from "react";
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import TextFieldControl from "./Inputs/TextFieldControl";

const SliderControl = (props) => {

    const [value, setValue] = useState(props.value ? props.value : 0)

    useEffect(() => {
        props.setValue(props.name, value);
    }, [value])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleAddSlider = () => {
        if(value + 1 > props.max) {
            return
        }

        setValue(value + 1)
    }

    const handleSubtractSlider = () => {
        if(value - 1 < props.min) {
            return;
        }

        setValue(value - 1)
    }

    return (
        <div className='slider-control-wrapper' style={{textAlign: 'center'}}>
            <TextField
                className='textfield-control short-input'
                value={value}
                type={'number'}
                onChange={(e) => {

                    if(e.target.value > props.max) {
                        setValue(props.max)
                        return
                    }

                    if(e.target.value < props.min) {
                        setValue(props.min)
                        return
                    }

                    setValue(e.target.value)
                }}
            />

            <div className="item-field-container slider-control">
                <IconButton onClick={() => handleSubtractSlider()}>
                    <span className={'control-button'}>-</span>
                </IconButton>
                <Slider
                    value={value}
                    aria-label="Default"
                    step={props.step ? props.step : 1}
                    min={props.min}
                    max={props.max}
                    valueLabelDisplay="on"
                    onChange={handleChange}
                />
                <IconButton onClick={() => handleAddSlider()}>
                    <span className={'control-button'}>+</span>
                </IconButton>
            </div>
        </div>

    )
}

export default SliderControl;