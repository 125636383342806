import UserType from "../Constants/User/UserType";
import Permissions from "../Constants/Base/Permissions";

export const checkPermission = (user, permission) => {
    switch (permission) {
        case Permissions.MANAGE_USERS.NAVIGATION: return checkManageUsersNavigation(user)
        case Permissions.ADMIN.NAVIGATION: return checkTemplateNavigation(user)
        case Permissions.PRICE_AND_PACKAGE.HEADER: return checkPriceAndPackageHeader(user)
        case Permissions.SUBSCRIPTION.HEADER: return checkSubscriptionHeader(user)
        case Permissions.ADD_USER.CAN_SELECT_PARTY: return checkCanSelectParty(user)
        default: return false;
    }
}

export const isPackageValid = (user) => {

    if (!user || !user.company || !user.company.package) {
        return false;
    }

    return user.company.packageValid && (new Date() < new Date(user.company.packageValidUntil))
}

export const hasAdminRole = (user) => {
    return user && (user.userProfileType === UserType.PORTAL_ADMIN ||
        user.userProfileType === UserType.SYSTEM_ADMIN)
}

export const isTrialPackageValid = (user, contractCount) => {

    if (user.userProfileType === UserType.PORTAL_ADMIN ||
        user.userProfileType === UserType.SYSTEM_ADMIN) {
        return true;
    }

    if (!user.company) {
        return false;
    }

    if (user.company.isTrial && contractCount >= 2) {
        return false;
    }

    return user.company.packageValid && (new Date() < new Date(user.company.packageValidUntil))
}

export const isTrial = (user) => {

    if (user.userProfileType === UserType.PORTAL_ADMIN ||
        user.userProfileType === UserType.SYSTEM_ADMIN) {
        return false;
    }

    if (!user.company) {
        return false;
    }

    return user.company.isTrial;
}

export const isTrialUsed = (user) => {

    if (user.userProfileType === UserType.PORTAL_ADMIN ||
        user.userProfileType === UserType.SYSTEM_ADMIN) {
        return false;
    }

    if (!user.company) {
        return false;
    }
    return user.company.freeTrialUsed;
}

const checkManageUsersNavigation = (user) => {
    return user && (user.userProfileType === UserType.PORTAL_ADMIN ||
        user.userProfileType === UserType.SYSTEM_ADMIN ||
        user.userProfileType === UserType.COMPANY_ADMIN);
}

const checkTemplateNavigation = (user) => {
    return user && (user.userProfileType === UserType.PORTAL_ADMIN ||
        user.userProfileType === UserType.SYSTEM_ADMIN);
}

const checkPriceAndPackageHeader = (user) => {
    return user && (user.userProfileType === UserType.COMPANY_ADMIN ||
        user.userProfileType === UserType.INDIVIDUAL);
}

const checkSubscriptionHeader = (user) => {
    return user && (user.userProfileType === UserType.COMPANY_ADMIN ||
        user.userProfileType === UserType.INDIVIDUAL);
}

const checkCanSelectParty = (user) => {
    return user && (user.userProfileType === UserType.PORTAL_ADMIN ||
        user.userProfileType === UserType.SYSTEM_ADMIN);
}

export const isProfileCompleted = (user) => {

    if (user.userProfileType === UserType.INDIVIDUAL) {
        return user && user.company && user.company.address && user.company.description && user.company.mainGoal
            && user.company.sector
    }

    if (user.userProfileType === UserType.COMPANY_USER || user.userProfileType === UserType.COMPANY_ADMIN) {
        // return user && user.company && user.company.companyType && user.company.companyName && user.company.jurisdiction
        //     && user.company.companyTurnover
        // && user.company.address && user.company.number && (user.company.director || user.company.shareholder || user.company.employee)
        //     && user.company.employeesNumber
        //     && user.company.sector 
        //     && user.company.mainGoal
        return user && user.company && user.company.companyName && user.company.address && user.company.sector
    }

    return true
}