import strings from "../../localization";

const Package = {
    ALL: -1,
    ACTIVE: 1,
    DEACTIVATED: 2
};

export function getAdminPackageFilter() {
    return [
        {
            id: Package.ALL,
            name: getPackageString(Package.ALL)
        },
        {
            id: Package.ACTIVE,
            name: getPackageString(Package.ACTIVE)
        },
        {
            id: Package.DEACTIVATED,
            name: getPackageString(Package.DEACTIVATED)
        }
    ]
}

export function adminPackageDefaultValue() {
    return {
        id: Package.ALL,
        name: getPackageString(Package.ALL)
    }
}

export function getPackageString(packageValue) {
    switch(packageValue) {
        case Package.ALL: return strings.constants.admin.adminPackage.all;
        case Package.ACTIVE: return strings.constants.admin.adminPackage.active;
        case Package.DEACTIVATED: return strings.constants.admin.adminPackage.deactivated;
    }
}

export default Package;