import React, {useEffect, useState} from "react";
import {useForm, FormProvider} from "react-hook-form";
import strings from '../../../localization';
import Package from './Package';
import {getCustomPackage, getPricingAndPackage} from '../../../Constants/Dashboard/PricingAndPackage';
import {getAllPublicPackages, getPackagesByUser} from "../../../Services/ContractCategory/PackageService";
import {useSelector} from "react-redux";

const PackageList = ({
                         handleSubscribe,
                         handleDowngrade,
                         handleUpgrade,
                         handleCustom,
                         paidPack,
                         parentIsAnnual
                     }) => {

    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [packages, setPackages] = useState([])
    const [isAnnual, setIsAnnual] = useState(false)

    const user = useSelector((state) => state.auth.user)

    useEffect(() => {
        fetch();
    }, [])

    const handleToggle = (value) => {
        setIsAnnual(value)
        parentIsAnnual(value)
    }

    const fetch = () => {
        getAllPublicPackages().then(publicPackages => {

            let packagesList = [...publicPackages.data];

            getPackagesByUser(user.id).then(selectedPackages => {     
                 
                if(selectedPackages.data) {
                    selectedPackages.data.map(pack => {
                        packagesList = [...packagesList, pack];
                    });
                }         

                setPackages(packagesList);
            });


        });

    }

    const renderPackages = () => {
        let result = []

        for (let pack of packages) {
            result.push(
                <Package
                    key={'stage-' + result.length}
                    pack={pack.package}
                    features={pack.features}
                    handleSubscribe={handleSubscribe}
                    handleDowngrade={handleDowngrade}
                    handleUpgrade={handleUpgrade}
                    paidPack={paidPack}
                    isCustom={false}
                    isAnnual={isAnnual}
                    handleCustom={handleCustom}
                />
            )
        }

        const customPackage = getCustomPackage();

        result.push(
            <Package
                pack={customPackage}
                features={customPackage.features}
                handleSubscribe={handleSubscribe}
                handleDowngrade={handleDowngrade}
                handleUpgrade={handleUpgrade}
                paidPack={paidPack}
                isCustom={true}
                isAnnual={isAnnual}
                handleCustom={handleCustom}
            />
        )

        return result
    }

    return (
        <div id="dashboard" className="dashboard pricing-and-packages">
            <FormProvider {...form}>
                <div className="package-list-container">
                    <div className="save-toggle-container">
                        <div className="save-up-toggle">

                            <div className="switch-control-custom">

                                {
                                    isAnnual &&
                                    <div className={'value-container annually'} onClick={() => handleToggle(false)}>
                                        <div className={'switch-text'}>
                                            Annually
                                        </div>
                                        <div className={'circle'}></div>
                                    </div>
                                }

                                {
                                    !isAnnual &&
                                    <div className={'value-container monthly'} onClick={() => handleToggle(true)}>
                                        <div className={'circle'}></div>
                                        <div className={'switch-text'}>
                                            Monthly
                                        </div>
                                    </div>
                                }

                            </div>
                            <span
                                className="save-up-msg">{strings.pages.dashboard.pricingAndPackages.annuallySave}</span>
                        </div>
                    </div>
                    <div className="package-list">
                        {renderPackages()}
                    </div>
                </div>
            </FormProvider>
        </div>
    )
}

export default PackageList;