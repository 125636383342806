import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";

import SwitchControl from "../../../Controls/Inputs/SwitchControl";
import UploadView from "./UploadView";
import TagView from "./TagView";
import SnackbarContext from "../../../../Context/SnackbarContext";

const DueDiligenceUploadModal = ({ handleCloseModal,questionRow,getSectionListHandler }) => {

  const { showMessage } = useContext(SnackbarContext);

  const [docType, setDocType] = useState(0)

  const toggleDocTypeHandler = () => {
    if (docType === 0) {
      setDocType(1)
    }
    else {
      setDocType(0)
    }
  }

  return (
    // <FormProvider {...form}>
    <Box
      display="flex"
      flexDirection="column"
      className="due-diligence-modal"
     
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        mt={4}
      >
        <Typography
          variant="h5"
          fontWeight={500}
          sx={{ margin: "0 0.5rem" }}
          className="left-toggle-value"
        >
          Upload Documents
        </Typography>
        <SwitchControl
          noLeftMargin={true}
          className="switch-control"
          onClick={toggleDocTypeHandler}
          style={{
            marginTop: "0px",
            height: "30px",
            width: "78px",
            marginRight: "5px",
            marginLeft: "0px !important",
          }}
          controlCustomStyle={{
            height: "30px",
            width: "76px",
            marginLeft: "0px",
          }}
          tabActiveStyle={{
            marginLeft: "auto",
            height: "30px",
            width: "30px",
          }}
          tabWhiteStyle={{ height: "30px", width: "30px" }}
          showValues={false}
          value={docType}
          setValue={""}
        />

        <Typography
          variant="h5"
          fontWeight={500}
          className="left-toggle-value"
          sx={{ margin: "0 0.5rem" }}
        >
          Tag Existing Document
        </Typography>
      </Box>
      {docType === 0
        ? <UploadView
          handleCloseModal={handleCloseModal}
          questionRow={questionRow}
          showMessage={showMessage}
          getSectionListHandler={getSectionListHandler}
          
        />
        : <TagView
          handleCloseModal={handleCloseModal}
          questionRow={questionRow}
          showMessage={showMessage}
          getSectionListHandler={getSectionListHandler}
        />
      }


    </Box>
    // </FormProvider>
  );
};

export default DueDiligenceUploadModal;
