import React, { useEffect, useState } from "react";
import styles from "../AdminAIData/AIData.module.css";
import { Button, IconButton } from "@mui/material";
import { getAllPriceAvailableAdmin, updatePriceAvailableAdmin } from "../../../Services/DealRoom/AdminDealRoomService";

const DealRoomPrice = ({handleCloseModal,title,showMessage}) => {
  const [priceAvailable, setPriceAvailable] = useState([])

  const getPriceHandler = () => {
    getAllPriceAvailableAdmin()
      .then(res => {
        if(res?.data){
          setPriceAvailable(res.data)
        }
      })
  }

  const setPriceHandler = (e) => {
      let tempData = structuredClone(priceAvailable)
      tempData.forEach((data)=>{
        console.log(typeof data.intervalCountInMonths)
        console.log(typeof data.intervalCountInMonths)
        if(parseInt(data.intervalCountInMonths) === parseInt(e.target.name)){
          data.price = e.target.value
        }
      })
      setPriceAvailable(tempData)
  }

  const saveHandler = (interval,price) => {

    const tempData = {interval,price}
    updatePriceAvailableAdmin(tempData)
    .then(res => {
      showMessage("Saved successfully!")
    })

  }

  useEffect(()=>{

    getPriceHandler()

  },[])


  return (
    <div id="confirm-modal" className="edit-price-modal">
      <div className="edit-price-header">
        <IconButton
          onClick={handleCloseModal}
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "10px",
          }}
        >
          <img src="/images/icons/close.png" alt="close" />
        </IconButton>
        <h1
          className="confirm-title"
          style={{ fontWeight: "700", margin: "12px 100px" }}
        >
          {title}
        </h1>
      </div>
      <div className="edit-price-table">
        {priceAvailable?.map((priceRow) => {

          let label = priceRow?.intervalCountInMonths + " Months Price"
          if(priceRow.intervalCountInMonths === 1){
            label = "Monthly extension Price"
          }

          return (
            <div className="price-table">
              <div className="price-table-label">{label} </div>
              <div className="price-pound">£</div>
              <div
                className={styles.inputContainer}
                style={{
                  margin: " 8px 10px",
                  height: "max-content",
                }}
              >
                <input
                  className={styles.inputBox}
                  value={priceRow.price}
                  onChange={setPriceHandler}
                  name={priceRow.intervalCountInMonths}
                  type="text"
                />
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  className="save-btn"
                  variant="contained"
                  style={{
                    margin: "10px 10px",
                  }}
                onClick={() => saveHandler(priceRow?.intervalCountInMonths,priceRow?.price)}
                >
                  Save
                </Button>
              </div>
            </div>
          )
        })}


      </div>
    </div>
  );
};

export default DealRoomPrice;
