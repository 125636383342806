import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";


export async function packageSubscribe(data) {
    return await request('/api/subscribe', data, HttpMethod.POST);
}

export async function updatePackageValidation(data) {
    return await request('/api/subscribe/update-package-validation', data, HttpMethod.POST);
}

export async function updatePaymentMethod(data) {
    return await request('/api/subscribe/payment-method', data, HttpMethod.POST);
}

export async function cancelSubscription(id) {
    return await request('/api/subscribe/cancel/' + id, {}, HttpMethod.POST);
}

export async function getSubscriptionData() {
    return await request('/api/subscribe');
}

export async function getCreditcardDetails(){
    return await request('/api/subscribe/payment-method');
}