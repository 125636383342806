import React, { useContext, useEffect, useState } from "react";
import styles from "./DealRoom.module.css";
import HeaderButtons from "../../Components/Layout/HeaderButtons";
import MenuState from "../../Constants/Base/MenuState";
import { useSelector } from "react-redux";
import TargetCompanyForm from "../../Components/Forms/Pages/DealRoom/createProjectForms/TargetCompanyForm";
import ProjectForm from "../../Components/Forms/Pages/DealRoom/createProjectForms/ProjectForm";
import AdministratorsForm from "../../Components/Forms/Pages/DealRoom/createProjectForms/AdministratorsForm";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "@mui/material";
import { dealRoomProjectFormData } from "../../Constants/Admin/DealRoomModel";
import { createDealRoom } from "../../Services/DealRoom/DealRoomService";
import ConfirmModal from "../../Components/Modals/ConfirmModal";
import strings from "../../localization";
import SnackbarContext from "../../Context/SnackbarContext";

const CreateDealRoomForm = () => {
  const { showMessage } = useContext(SnackbarContext);
  const navigation = useNavigate();
  const [newlyCreatedDealroomId, setNewlyCreatedDealroomId] = useState("");
  const warningMsg = useSelector((state) => state.notifications.warningMsg);

  const menuState = useSelector((state) => state.navigation.menuState);

  const [formData, setFormData] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);

  const getHeaderClass = (menuState) => {
    if (menuState === MenuState.SHORT) {
      return "short";
    }
    return "";
  };

  const isAnyFieldEmpty = (obj) => {
    return Object.keys(obj).some((key) => {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        return isAnyFieldEmpty(obj[key]);
      } else {
        return obj[key] === "" || obj[key] === null || obj[key] === undefined;
      }
    });
  };

  const submitFormHandler = () => {
    const isNotEmpty = isAnyFieldEmpty(formData);
    if (isNotEmpty) {
      showMessage("Please fill out all required fields.", "error");
      return;
    }

    createDealRoom(formData)
      .then((res) => {
        if (res.data) {
          if (res?.data?.isSubscriptionRequired) {
            navigation(`/virtualDealRoom/payment/${res.data.id}`);
          } else {
            setIsOpenModal(true);
            showMessage("Deal Room created.", "success");
          }
          setNewlyCreatedDealroomId(res.data.id);
        } else {
          showMessage("Error in creating Deal Room.", "error");
        }
      })
      .catch((err) => {
        console.log("error in createDealRoom", err);
      });
  };

  const navigateToDashboardHandler = () => {
    setIsOpenModal(false);
    navigation(`/virtualDealRoom/dashboard/overview/${newlyCreatedDealroomId}`);
  };

  useEffect(() => {
    setFormData(dealRoomProjectFormData);
  }, []);

  return (
    <div className={styles.dealRoom}>
      <div id="header" className={getHeaderClass(menuState)}>
        <div>{/* <h1>{strings.pages.contractList.title}</h1> */}</div>
        <HeaderButtons />
      </div>
      <div className={styles.landingPageHeader}>
        <div className={styles.formFirstCol}>
          <div
            className={styles.button}
            onClick={() => navigation("/virtualDealRoom")}
          >
            <span>Back</span>
          </div>
        </div>
        <div className={styles.headerFirstCol}>
          <div className={styles.headingTextBox}>
            <span className={styles.headingText}>
              Create New Virtual Deal Room
            </span>
          </div>
        </div>
      </div>
      <div className={styles.landingPageBody}>
        <div className={styles.formBox}>
          <TargetCompanyForm formData={formData} setFormData={setFormData} />
          <ProjectForm formData={formData} setFormData={setFormData} />
          <AdministratorsForm
            formData={formData}
            setFormData={setFormData}
            showMessage={showMessage}
          />
          <div className={styles.submissionButtonBox}>
            <Button
              className="neutral-btn btn"
              sx={{ marginRight: "1rem" }}
              onClick={() => navigation("/virtualDealRoom")}
            >
              Cancel
            </Button>
            <Button className="default-btn btn" onClick={submitFormHandler}>
              Setup Deal Room
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        className="confirm-modal-container"
      >
        <ConfirmModal
          contentText={strings.forms.dealRoom.freeTrialMsg}
          confirmBtn={"Ok"}
          handleConfirm={navigateToDashboardHandler}
        />
      </Modal>
    </div>
  );
};

export default CreateDealRoomForm;
