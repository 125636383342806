import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { createFilterOptions } from "@mui/material/Autocomplete";
import strings from "../../../../localization";
import styles from "./../../../../Pages/Admin/AdminAIData/AIData.module.css";
import TextField from "@mui/material/TextField";
import AutocompleteComponent from "../../../Controls/AutocompleteComponent";
import { getAllUsersWithoutImageAndSignature } from "../../../../Services/User/UserService";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  addContractUser,
  deleteContractUser,
  getAllContractOwnersByContract,
} from "../../../../Services/Contract/ContractUserService";
import ContractUserType from "../../../../Constants/Contract/ContractUserType";
import Tooltip from "@mui/material/Tooltip";
import { getContractByName } from "../../../../Services/Company/CompanyService";
import { useLocation } from "react-router-dom";

const DetailsForm = ({
  handleEditContract,
  setDisplayForms,
  contract,
  setContract,
  allContracts,
  setIsOpenContractOptionMenu,
}) => {
  const form = useForm();
  const location = useLocation();
  const {
    data,
    formState: { errors },
  } = form;
  const [users, setUsers] = useState([]);
  const [owners, setOwners] = useState([]);

  const [nameFieldError, setNameFieldError] = useState(false);
  const [nameAlreadyExistErr, setNameAlreadyExistErr] = useState(false);
  const [name, setName] = useState(contract.name);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    renderOwner();
  }, [owners]);

  const fetch = () => {
    getAllUsersWithoutImageAndSignature().then((response) => {
      if (!response || !response.ok) {
        return;
      }
      setUsers(response.data);
    });

    getAllContractOwnersByContract(
      contract.id,
      location?.hash?.substring(1)
    ).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      setOwners(response.data);
    });
  };

  const getAutocompleteUsers = () => {
    let result = [];

    for (let user of users) {
      const owner = owners.find((x) => x.id === user.id);

      if (owner) {
        continue;
      }

      result.push(user);
    }

    return result;
  };

  const handleCloseForms = () => {
    setDisplayForms(false);
    setIsOpenContractOptionMenu(false);
  };

  const filterOptions = createFilterOptions({
    stringify: ({ fullName, email }) => `${fullName} ${email}`,
  });

  const handleCloseTag = (owner) => {
    deleteContractUser(owner.id).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      fetch();
    });
  };

  const submitName = () => {
    if (!name) {
      setNameFieldError(true);
      return;
    } else if (name !== contract.name) {
      getContractByName(name)
        .then((response) => {
          if (response.data.result) {
            setNameAlreadyExistErr(true);
          } else {
            handleEditContract({ name: name });
          }
        })
        .catch((error) => {
          console.error("Error fetching contract by name:", error);
        });
    }
  };

  const submitOwner = (data) => {
    addContractUser({
      contractId: contract.id,
      userId: data.id,
      reviewer: false,
      signatory: false,
      ContractUserType: ContractUserType.OWNER,
    }).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      fetch();
    });
  };

  const renderOwner = () => {
    if (!owners) {
      return "";
    }

    let result = [];

    for (let owner of owners) {
      result.push(
        <div className="tag">
          <img
            src={
              owner.profileImage
                ? owner.profileImage
                : "/images/icons/profile-default-small.png"
            }
          />
          <span className="tag-text">
            {owner.user ? owner.user.fullName : ""}
          </span>
          <CloseIcon
            className="close-icon"
            onClick={() => handleCloseTag(owner)}
          />
        </div>
      );
    }

    return result;
  };

  return (
    <div className="form-container details-form">
      <FormProvider {...form}>
        <div className="header">
          <h1 className="title">
            {strings.forms.contractList.templateDetailsForm.title}
          </h1>
          <CloseIcon className="close-icon" onClick={handleCloseForms} />
        </div>
        <div className="border"></div>

        <div className="item-field-container">
          <span className="info-icon-text">
            {strings.forms.contractList.templateDetailsForm.documentName}
            <Tooltip
              title={
                strings.forms.contractList.templateDetailsForm
                  .documentNameTooltip
              }
              placement="top"
              arrow
            >
              <IconButton>
                <img src="/images/icons/info.png" />
              </IconButton>
            </Tooltip>
          </span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              name="name"
              value={name}
              fullWidth
              className="textfield-control"
              error={Boolean(errors.name)}
              helperText={
                errors.name &&
                strings.forms.contractList.templateDetailsForm.documentName
              }
              placeholder={
                strings.forms.contractList.templateDetailsForm.enterDocumentName
              }
              onChange={handleNameChange}
              required
            />
            <div
              className={styles.inputContainerButton}
              onClick={submitName}
              style={{
                position: "relative",
                marginTop: "0.3rem",
                height: "40px",
              }}
            >
              <span>Save</span>
            </div>
          </div>
          {nameFieldError && (
            <span className="error-msg">{strings.forms.common.required}</span>
          )}

          {nameAlreadyExistErr && (
            <span className="error-msg">
              {
                strings.forms.contractList.templateDetailsForm
                  .documentNameAlreadyExistMsg
              }
            </span>
          )}
        </div>

        <div className="item-field-container">
          <span className="info-icon-text">
            {strings.forms.contractList.templateDetailsForm.documentOwner}
            <Tooltip
              title={
                strings.forms.contractList.templateDetailsForm
                  .documentOwnerTooltip
              }
              placement="top"
              arrow
            >
              <IconButton>
                <img src="/images/icons/info.png" />
              </IconButton>
            </Tooltip>
          </span>
          <AutocompleteComponent
            name={"owners"}
            nameKey={"fullName"}
            data={data}
            allElements={getAutocompleteUsers()}
            placeholder={
              strings.forms.contractList.templateDetailsForm.enterName
            }
            filterOptions={filterOptions}
            submitOnInputChange={submitOwner}
          />
        </div>

        <div className="tag-list">{renderOwner()}</div>
      </FormProvider>
    </div>
  );
};

export default DetailsForm;
