import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import strings from "../../../../localization";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import RadioGroupControl from "../../../Controls/Inputs/RadioGroupControl";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import ImageUploadComponent from '../../../Controls/ImageUploadComponent';
import Button from '@mui/material/Button';
import {getUserTypes, getUserTypesDefault} from "../../../../Constants/User/UserType";
import {getParties, getPartiesDefault} from '../../../../Constants/Contract/Partie';
import TextFieldPasswordControl from "../../../Controls/Inputs/TextFieldPasswordControl";
import ValidationPatterns from "../../../../Constants/Base/ValidationPatterns";
import {useSelector} from "react-redux";
import {checkPermission} from "../../../../Util/PermissionUtil";
import Permissions from "../../../../Constants/Base/Permissions";
import {getAllCompanies} from "../../../../Services/Company/CompanyService";

const formRules = {
    'email': {required: { value: true, message: strings.forms.common.required},
        pattern: { value: ValidationPatterns.EMAIL, message: strings.forms.registration.emailError, }},
    'password': {required: { value: true, message: strings.forms.common.required},
        pattern: { value: ValidationPatterns.PASSWORD, message: strings.forms.registration.passwordError}}
}

const UserForm = ({setOpenFormModal, handleConfirm, user}) => {

    const currentUser = useSelector((state) => state.auth.user)
    const [radioButtonList, setRadioButtonList] = useState(getUserTypes(currentUser.userProfileType))
    const form = useForm({
        defaultValues: {
            email: user ? user.email : ''
        }
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [image, setImage] = useState('');
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        if (user) {
            setValue('userProfileType', user.userProfileType)
            setImage(user.profileImage)
        } else {
            setValue('userProfileType', getUserTypesDefault(currentUser.userProfileType))
        }

        fetchCompanies();
    }, [user])


    const fetchCompanies = () => {
        getAllCompanies().then(response => {

            let companies = [];
            response.data.map(company => {
                if(company.companyName) {
                    companies = [...companies, company]
                }

            });

            setCompanies(companies)
        });
    }

    const handleCancel = () => {
        setOpenFormModal(false)
    }

    const onSubmit = (data) => {

        const company = data.company ? data.company : (user ? user.company : currentUser.company)

        handleConfirm({
            ...data,
            profileImage: image,
            partie: data.partie ? data.partie.id : 1,
            id: user ? user.id : undefined,
            companyId: company ? company.id : -1
        })
    }

    return (<FormProvider {...form}>
        <div id="user-form-container" className="confirm-modal user-form-container">

            <h1 className="header">
                {user?.id
                    ? strings.forms.userList.userForm.editTitle
                    : strings.forms.userList.userForm.addTitle
                }
            </h1>

            <div className="user-form-border"></div>

            <div className="form-content">

                <div className="item">
                    <div className="profile-picture-container">
                        <div className="profile-picture-icon-container">
                            <ImageUploadComponent
                                accept={['.jpeg', '.jpg', '.png', '.svg']}
                                hideUploadButton={true}
                                setImage={setImage}
                                image={image}
                            />
                        </div>
                        <div className="profile-picture-text">
                            <h2 className="subtitle">{strings.forms.userList.userForm.profilePicture}</h2>
                        </div>
                    </div>

                    <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.userList.userForm.fullName}
                        </span>
                        <TextFieldControl
                            name='fullName'
                            control={data}
                            rules={{required: true}}
                            defaultValue={user ? user.fullName : ''}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.fullName)}
                            helperText={errors.fullName && strings.forms.common.required}
                            placeholder={strings.forms.userList.userForm.fullName}
                        />
                    </div>

                    <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.userList.userForm.email}
                        </span>
                        <TextFieldControl
                            name='email'
                            control={data}
                            rules={formRules['email']}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.email)}
                            helperText={errors.email && errors.email.message}
                            placeholder={strings.forms.userList.userForm.enterEmail}
                        />
                    </div>

                    {
                        !user &&
                        <div className="item-field-container">
                        <span className="info-icon-text">
                            {strings.forms.login.password}
                        </span>
                            <TextFieldPasswordControl
                                name='password'
                                control={data}
                                defaultValue=''
                                rules={formRules.password}
                                fullWidth
                                type='password'
                                margin="normal"
                                error={Boolean(errors.password)}
                                helperText={errors.password && errors.password.message}
                                placeholder={strings.forms.login.password}
                            />
                        </div>
                    }
                    {
                        checkPermission(currentUser, Permissions.ADD_USER.CAN_SELECT_PARTY) && companies.length > 0 &&
                        <div className="field-container">
                            <span className="label">{strings.forms.userList.userForm.partie}</span>
                            <SelectControl
                                name='company'
                                defaultValue={user?.company}
                                options={companies}
                                setValue={setValue}
                                nameKey='companyName'
                                valueKey='id'
                                className="select-control-container"
                            />
                        </div>
                    }

                </div>

                <div className="item">
                    <div className="checkboxes-container">
                        <p className="title">{strings.forms.userList.userForm.typeOfUser}</p>
                        <RadioGroupControl
                            name={'userProfileType'}
                            setValue={setValue}
                            radioButtonList={radioButtonList}
                            defaultValue={user && user.userProfileType ? user.userProfileType : getUserTypesDefault(currentUser.userProfileType)}
                            tooltip={true}
                        />
                    </div>
                </div>
            </div>

            <div className="submit-container">
                <Button className="secondary-neutral-btn btn"
                        onClick={handleCancel}>{strings.forms.userList.userForm.cancel}</Button>
                <Button className="secondary-btn btn"
                        onClick={handleSubmit(onSubmit)}>{strings.forms.userList.userForm.save}</Button>
            </div>
        </div>
    </FormProvider>)
}

export default UserForm;