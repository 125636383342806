import React from "react";
import AutocompleteSelectControl from './AutocompleteSelectControl';

const AutocompleteComponent = (props) => {

    const handleInputChange = (element) => {

        if (props.setSelectedElements) {

            props.setSelectedElements([
                ...props.selectedElements, 
                element
            ])

            props.setAllElements(props.allElements.filter(e => e.id != element.id))
        }
        
        if (props.setSelectedElement) {
            props.setSelectedElement(element)
            const allFatchElements = props.allFatchElements
            props.setAllElements(allFatchElements.filter(e => e.id != element.id))
        }

        if (props.submitOnInputChange) {
            props.submitOnInputChange(element)
        }
    }

    return (
        <React.Fragment>
            <AutocompleteSelectControl 
                name={props.name}
                nameKey={props.nameKey ? props.nameKey : 'name' }
                control={props.data}
                options={props.allElements}
                handleInputChange={handleInputChange}
                placeholder={props.placeholder}
                filterOptions={props.filterOptions && props.filterOptions}
                noOptionsText={props.noOptionsText && props.noOptionsText}
                paperClass={props.paperClass && props.paperClass}
            />
        </React.Fragment>
    )
}

export default AutocompleteComponent;