import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {getUserTypeString} from '../../Constants/User/UserType';
import ProfileMenu from './ProfileMenu';
import NotificationMenu from './NotificationMenu';
import IconButton from '@mui/material/IconButton';
import {reducerLogout} from "../../Slices/AuthSlice";
import {clearUserData} from "../../Base/OAuth";

const HeaderButtons = () => {

    const navigate = useNavigate();
    const [userInitials, setUserInitials] = useState(null)
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
    const [notificationMenuAnchorEl, setNotificationMenuAnchorEl] = useState(null);
    const currentUser = useSelector((state) => state.auth.user)
    const notifications = useSelector((state) => state.notifications.notifications)
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {

        if (currentUser != null) {
            setUserInitials(currentUser?.fullName?.split(' ').map(item => {
                return item[0]
            }).join(''))
        }

    }, [currentUser])

    const clickProfileMenu = event => {
        setProfileMenuAnchorEl(event.currentTarget);
    };

    const closeProfileMenu = () => {
        setProfileMenuAnchorEl(null);
    }

    const handleLogout = () => {
        closeProfileMenu();
        clearUserData();
        dispatch(reducerLogout());
        navigate('/login');
    }

    const handleMyProfile = () => {
        closeProfileMenu();
        navigate('/my-details');
    }

    const clickNotificationMenu = event => {
        setNotificationMenuAnchorEl(event.currentTarget);
    }

    const closeNotificationMenu = () => {
        setNotificationMenuAnchorEl(null);
    }

    const isThereNewNotifications = (notifications) => {
        if(!notifications) {
            return false
        }

        return notifications.filter(x => !x.isRead).length > 0;
    }

    const isCurrentPath = (path) => {
        return location.pathname === path;
    }

    return (
        <div className={isCurrentPath('/ai-assist') ? 'right ml-auto' : 'right'}>
            <div className="header-icon"
                 onClick={(event) => clickProfileMenu(event)}>
                <span>{userInitials}</span>
            </div>

            <ProfileMenu
                profileMenuAnchorEl={profileMenuAnchorEl}
                fullName={currentUser?.fullName}
                email={currentUser?.email}
                userType={getUserTypeString(currentUser?.userType)}
                handleLogout={handleLogout}
                handleMyProfile={handleMyProfile}
                closeProfileMenu={closeProfileMenu}
            />

            <div className="header-icon header-icon-notification"
                 onClick={(event) => clickNotificationMenu(event)}>
                <IconButton><img src="/images/icons/bell.png"/></IconButton>
                {isThereNewNotifications(notifications) &&
                <div className="notification-dot"/>
                }
            </div>

            <NotificationMenu
                notificationMenuAnchorEl={notificationMenuAnchorEl}
                fullName={currentUser?.fullName}
                handleLogout={handleLogout}
                handleMyProfile={handleMyProfile}
                closeNotificationMenu={closeNotificationMenu}
            />
        </div>)
}

export default HeaderButtons;