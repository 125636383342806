import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import strings from "../../localization";
import {
  deleteContract,
  moveContract,
  editContract,
  getAllContracts,
  changeContractState,
  getContractCount,
} from "../../Services/Company/CompanyService";
import { useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { Editor } from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SnackbarContext from "../../Context/SnackbarContext";
import DetailsForm from "../../Components/Forms/Pages/ContractList/DetailsForm";
import CommentList from "./CommentList";
import CollaboratorsForm from "../../Components/Forms/Pages/ContractList/CollaboratorsForm";
import FoldersForm from "../../Components/Forms/Pages/ContractList/FoldersForm";
import HistoryList from "./HistoryList";
import SimpleConfirmModal from "../../Components/Modals/SimpleConfirmModal";
import IconButton from "@mui/material/IconButton";
import {
  getActiveContractContent,
  saveContract,
  saveContractMetadata,
} from "../../Services/Contract/ContractContentService";
import { Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
  addComment,
  deleteComment,
  editComment,
  changeCommentResolve,
} from "../../Services/Contract/CommentService";
import ContractStatus from "../../Constants/Contract/ContractStatus";
import { isTrialPackageValid } from "../../Util/PermissionUtil";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../Components/Modals/ConfirmModal";

import {
  esignContract,
  getAllContractUsersByContract,
  getAllContractOwnersByContract,
  getContractUserByHash,
  placePlaceholderContractUser,
} from "../../Services/Contract/ContractUserService";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import {
  ZoomIn,
  ZoomOut,
  ArrowBackIos,
  ArrowForwardIos,
} from "@mui/icons-material";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import ContractContentType from "../../Constants/Contract/ContractContentType";
import LoaderContext from "../../Context/LoaderContext";
import UserType from "../../Constants/User/UserType";
import AdoptSignatureForm from "../../Components/Forms/Pages/ContractList/AdoptSignatureForm";
import { useElementSize } from "usehooks-ts";
import RegistrationModal from "../../Components/Modals/RegistrationModal";
import SendIcon from "@mui/icons-material/Send";
import { registration } from "../../Services/User/UserService";
import { changeMenuState } from "../../Slices/NavigationSlice";
import MenuState from "../../Constants/Base/MenuState";
import { TrackChangesIntegration } from "../../Util/CKEditorUtil";

const ContractPreviewExternal = () => {
  const user = useSelector((state) => state.auth.user);
  const menuState = useSelector((state) => state.navigation.menuState);
  const [contract, setContract] = useState(null);
  const [allContracts, setAllContracts] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [editor, setEditor] = useState(null);
  const { showMessage } = useContext(SnackbarContext);
  const dispatch = useDispatch();

  const [tempPDFContent, setTempPDFContent] = useState();
  const [signMode, setSignMode] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openInfoModalText, setOpenInfoModalText] = useState("");
  const [detailsTab, setDetailsTab] = useState(true);
  const [commentsTab, setCommentsTab] = useState(false);
  const [collaboratorsTab, setCollaboratorsTab] = useState(false);
  const [foldersTab, setFoldersTab] = useState(false);
  const [historyTab, setHistoryTab] = useState(false);
  const [downloadTab, setDownloadTab] = useState(false);
  const [deleteTab, setDeleteTab] = useState(false);
  const [displayForms, setDisplayForms] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [content, setContent] = useState("");
  const [pdfContent, setPDFContent] = useState("");
  const [hasChange, setHasChange] = useState(false);
  const [signedPageNumber, setSignedPageNumber] = useState(null);
  const [contractCount, setContractCount] = useState(0);
  const [openNonValidSubscription, setOpenNonValidSubscription] =
    useState(false);
  const [owners, setOwners] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pdfScale, setPdfScale] = useState(1.3);
  const pdfViewer = useRef();
  const [placeAllSignatures, setPlaceAllSignatures] = useState(false);
  const [forwardOnRegistration, setForwardOnRegistration] = useState(false);
  const [acceptReviewOpen, setAcceptReviewOpen] = useState(false);
  const [placePDFSignaturePlaceholder, setPlacePDFSignaturePlaceholder] =
    useState(false);
  const [placePDFNamePlaceholder, setPlacePDFNamePlaceholder] = useState(false);
  const [placePDFDatePlaceholder, setPlacePDFDatePlaceholder] = useState(false);
  const [placePDFSignature, setPlacePDFSignature] = useState(false);
  const [collaboratorForPlacement, setCollaboratorForPlacement] =
    useState(null);
  const [openAdoptSignModal, setOpenAdoptSignModal] = useState(false);
  const [showSignBanner, setShowSignBanner] = useState(true);
  const [squareRef, size] = useElementSize();
  const [forceValue, setForceValue] = useState(0);
  const [signState, setSignState] = useState(false);
  const [contractId, setContractId] = useState(null);
  const [externalUser, setExternalUser] = useState(null);
  const [externalAdoptSignature, setExternalAdoptSignature] = useState(null);
  const [email, setEmail] = useState("");
  const [showRegDialog, setShowRegDialog] = useState(false);
  const [showAddSignatureButton, setShowAddSignatureButton] = useState(true);
  const [canvasLeftPosition, setCanvasLeftPosition] = useState(0);
  const [currentCanvasLeftPosition, setCurrentCanvasLeftPosition] = useState(0);
  const [isOpenDisplayForm, setIsOpenDisplayForm] = useState(false);
  const [signatoryColor, setSignatoryColor] = useState({});
  const [isOpenDisplayFormAndSidebar, setIsOpenDisplayFormAndSidebar] =
    useState(false);
  const [numberPagesToRender, setNumberPagesToRender] = useState(0);
  const [showLocalLoader, setShowLocalLoader] = useState(false);
  const [showLocalLoaderText, setShowLocalLoaderText] = useState("");
  const [contractContentLoading, setContractContentLoading] = useState(true);
  const [isOpenCommentSideBar, setIsOpenCommentSideBar] = useState(false);
  const [channelId, setChannelId] = useState("");
  const [activeThreadId, setActiveThreadId] = useState("");
  const { showLoader, setLoaderTitle } = useContext(LoaderContext);

  const forceRender = () => {
    setForceValue(forceValue + 1);
  };

  const handleScroll = () => {
    const wrappers = document.getElementsByClassName("react-pdf__Page__canvas");

    const visibleWrappers = Array.from(wrappers).filter((wrapper) => {
      const rect = wrapper.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const visibleHeight = windowHeight * 0.5;
      return rect.top >= -visibleHeight && rect.bottom;
    });

    if (visibleWrappers.length > 0 && visibleWrappers.length === 2) {
      let difference = numPages - wrappers.length;
      if (difference > 0) {
        if (difference > 10) {
          setNumberPagesToRender(wrappers.length + 10);
          return;
        }
        setNumberPagesToRender(numPages);
      }
    }
  };

  useEffect(() => {
    if (numPages > 0) {
      setNumberPagesToRender(numPages > 10 ? 10 : numPages);
      document
        .querySelector(".contract-container")
        .addEventListener("scroll", handleScroll);
      return () =>
        document
          .querySelector(".contract-container")
          .removeEventListener("scroll", handleScroll);
    }
  }, [numPages]);

  const debounce = useCallback((fn, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      dispatch(changeMenuState(MenuState.SHORT));
      updateCanvasLeftPosition();
    });

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [debounce]);

  useEffect(() => {
    if (!location.hash) {
      navigate("/contract-list");
    }

    fetchContract();
  }, []);

  useEffect(() => {
    if (menuState === 2 && !displayForms) {
      setCurrentCanvasLeftPosition(canvasLeftPosition);
    }
  }, [canvasLeftPosition]);

  useEffect(() => {
    if (displayForms) {
      setIsOpenDisplayForm(true);
      if (menuState === 1) {
        setIsOpenDisplayFormAndSidebar(true);
        setCurrentCanvasLeftPosition(canvasLeftPosition - 350);
      } else {
        setIsOpenDisplayFormAndSidebar(false);
        setCurrentCanvasLeftPosition(canvasLeftPosition - 283);
      }
      return;
    }

    if (isOpenDisplayForm && !displayForms) {
      setIsOpenDisplayForm(false);
      updateCanvasLeftPosition();
    }

    if (menuState === 1 && !displayForms) {
      setCurrentCanvasLeftPosition(canvasLeftPosition - 67.5);
    }

    if (menuState === 2 && !displayForms) {
      if (isOpenDisplayFormAndSidebar) {
        setCurrentCanvasLeftPosition(canvasLeftPosition + 67.5);
        setCanvasLeftPosition(canvasLeftPosition + 67.5);
        setIsOpenDisplayFormAndSidebar(false);
        return;
      }

      setCurrentCanvasLeftPosition(canvasLeftPosition);
    }
  }, [menuState, displayForms]);

  useEffect(() => {
    if (
      !contract ||
      isUserSignedContract() ||
      !isUserSignatory() ||
      contract.state !== ContractStatus.PENDING_E_SIGN
    ) {
      return;
    }
  }, [contract, signMode]);

  useEffect(() => {
    setForceValue(forceValue + 1);
  }, [size]);

  useEffect(() => {
    fetchByContractId();
    fetchCollaborators();
  }, [contractId]);

  useEffect(() => {
    const initializeEditor = () => {
      const commentsRepository = editor.plugins.get("CommentsRepository");

      commentsRepository.on(
        `addComment:${channelId}:${activeThreadId}`,
        (evt, data) => {
          let saveData = {
            id: data.threadId,
            contractId: contract.id,
            content: data.content,
            contractContent: "",
            userId: -1,
            externalUserFullName: externalUser.fullName,
            externalUserEmail: externalUser.email,
          };

          addComment(saveData)
            .then((res) => {
              console.log("resp", res);
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      );

      commentsRepository.on(
        `removeComment:${channelId}:${activeThreadId}`,
        (evt, data) => {
          let saveData = {
            contractId: contract.id,
            userId: -1,
            content: data.content,
            externalUserFullName: externalUser.fullName,
            externalUserEmail: externalUser.email,
          };
          deleteComment(data.threadId, saveData)
            .then((res) => {
              console.log("delete resp", res);
            })
            .catch((err) => {
              console.log("delete err", err);
            });
        }
      );

      commentsRepository.on(
        `resolveCommentThread:${channelId}`,
        (evt, data) => {
          let saveData = {
            id: data.threadId,
            contractId: contract.id,
            content: data.content,
            userId: -1,
            externalUserFullName: externalUser.fullName,
            externalUserEmail: externalUser.email,
          };
          changeCommentResolve(saveData)
            .then((res) => {
              console.log("delete resp", res);
            })
            .catch((err) => {
              console.log("delete err", err);
            });
        }
      );

      commentsRepository.on(
        `updateComment:${channelId}:${activeThreadId}`,
        (evt, data) => {
          let saveData = {
            id: data.threadId,
            contractId: contract.id,
            content: data.content,
            contractContent: "",
            userId: -1,
            externalUserFullName: externalUser.fullName,
            externalUserEmail: externalUser.email,
          };
          editComment(saveData)
            .then((res) => {
              console.log("edit resp", res);
            })
            .catch((err) => {
              console.log("edit err", err);
            });
        }
      );
      return () => {
        commentsRepository.off(`addComment:${channelId}:${activeThreadId}`);
        commentsRepository.off(`removeComment:${channelId}:${activeThreadId}`);
        commentsRepository.off(`resolveCommentThread:${channelId}`);
        commentsRepository.off(`updateComment:${channelId}:${activeThreadId}`);
      };
    };

    if (editor) {
      initializeEditor();
    }

    return () => {
      if (editor) {
        const commentsRepository = editor.plugins.get("CommentsRepository");
        commentsRepository.off(`addComment:${channelId}:${activeThreadId}`);
        commentsRepository.off(`removeComment:${channelId}:${activeThreadId}`);
        commentsRepository.off(`resolveCommentThread:${channelId}`);
        commentsRepository.off(`updateComment:${channelId}:${activeThreadId}`);
      }
    };
  }, [editor, activeThreadId, channelId]);

  const isOpenCommentSideBarHandler = () => {
    setIsOpenCommentSideBar(!isOpenCommentSideBar);
  };

  const updateCanvasLeftPosition = () => {
    let canvas = document.querySelector(".document-upload-wrapper");

    if (!canvas || canvas.length === 0) {
      return 0;
    }

    if (canvas && getCanvasWidth()) {
      setCanvasLeftPosition((canvas.offsetWidth - getCanvasWidth()) / 2);
      setCurrentCanvasLeftPosition((canvas.offsetWidth - getCanvasWidth()) / 2);
    }
  };

  const overlayClick = (event, page) => {
    let overlay = document.getElementsByClassName("pdf-overlay");
    const rect = overlay[page - 1].getBoundingClientRect();
    let x = event.clientX - rect.left;
    let y = event.clientY - rect.top;

    if (
      !overlay ||
      overlay.length === 0 ||
      (!placePDFSignaturePlaceholder &&
        !placePDFNamePlaceholder &&
        !placePDFDatePlaceholder)
    ) {
      return;
    }

    if (x < 0) {
      x = 0;
    }

    if (y < 0) {
      y = 0;
    }

    const canvasWidth = getCanvasWidth();
    const canvasHeight = getCanvasHeight();

    let metadata = contract.metadata;

    if (!metadata.placeholders) {
      metadata.placeholders = [];
    }

    let eventType = "signature";
    if (placePDFNamePlaceholder) {
      eventType = "name";
    }

    if (placePDFDatePlaceholder) {
      eventType = "date";
    }

    metadata.placeholders.push({
      id: metadata.placeholders.length + 1,
      x: x,
      y: y,
      canvasWidth: canvasWidth,
      canvasHeight: canvasHeight,
      pdfScale: 1.3,
      pageNumber: page,
      event: eventType,
      collaborator: collaboratorForPlacement,
    });

    setContract({
      ...contract,
      metadata: metadata,
    });

    placePlaceholderContractUser(collaboratorForPlacement.id).then(
      (response) => {
        fetchCollaborators();
      }
    );

    saveContractMetadata(contract.id, { metadata: JSON.stringify(metadata) });
  };

  const renderPlaceholders = (pageNumber) => {
    let result = [];

    if (
      !contract ||
      !externalUser?.signatory ||
      !contract.metadata?.placeholders ||
      contract.state <= ContractStatus.PENDING_REVIEW
    ) {
      return result;
    }

    const width = 100;
    const height = 50;
    const canvasWidth = getCanvasWidth();
    const canvasHeight = getCanvasHeight();

    contract.metadata.placeholders.map((placeholder) => {
      const collaboratorExist = collaborators.find(
        (x) => placeholder?.collaborator?.id === x?.id
      );

      if (!collaboratorExist) {
        return result;
      }

      if (placeholder.pageNumber !== pageNumber) {
        return result;
      }

      let color;
      switch (Object.keys(signatoryColor).length) {
        case 0:
          color = "#BECCEE";
          break;
        case 1:
          color = "#EEDEBE";
          break;
        case 2:
          color = "#b8f1e4";
          break;
        case 3:
          color = "#DCFFFB";
          break;
        case 4:
          color = "#AFF8D8";
          break;
        case 5:
          color = "#FFABAB";
          break;
        default:
          color = "#DCDCDCE6";
          break;
      }

      if (!signatoryColor[placeholder.collaborator.email]) {
        setSignatoryColor({
          ...signatoryColor,
          [placeholder.collaborator.email]: color,
        });
      }

      let left =
        (placeholder.x - width / 2) * (canvasWidth / placeholder.canvasWidth);
      let top =
        (placeholder.y - height / 2) *
        (canvasHeight / placeholder.canvasHeight);
      const spiltedNames = placeholder?.collaborator?.fullName
        .split(" ")
        .filter((name) => name !== "");
      const longestName = spiltedNames?.reduce((longest, current) => {
        return current.length > longest.length ? current : longest;
      }, "");

      let updatedHeight;
      switch (spiltedNames.length) {
        case 1:
          updatedHeight = height * 1.5;
          break;
        case 2:
          updatedHeight = height * 2;
          break;
        case 3:
          updatedHeight = height * 2.5;
          break;
        case 4:
          updatedHeight = height * 3;
          break;
        case 5:
          updatedHeight = height * 3.5;
          break;
        case 6:
          updatedHeight = height * 4;
          break;
        case 7:
          updatedHeight = height * 4.5;
          break;
        case 8:
          updatedHeight = height * 5;
          break;
        case 9:
          updatedHeight = height * 5.5;
          break;
        case 10:
          updatedHeight = height * 6;
          break;
        default:
          updatedHeight = height * spiltedNames.length - 3;
          break;
      }

      result.push(
        <>
          {placeholder.event === "signature" && (
            <div
              className={
                signMode && isUserSignatory()
                  ? "placeholder can-sign"
                  : "placeholder"
              }
              id="ele"
              style={{
                width:
                  longestName.length > 8
                    ? longestName.length * 10 + 60
                    : width * 1.25,
                height: updatedHeight,
                left: left,
                top: top,
                paddingBottom: "6px",
                transform: "scale(" + 1 + ")",
                backgroundColor:
                  isUserOwner(user, owners) || isUserSignatory()
                    ? signatoryColor[placeholder.collaborator.email]
                    : "#EEDEBE",
                border: "4px solid #3D32CF",
                opacity:
                  !placeholder.collaborator.user &&
                  placeholder.collaborator.email === externalUser.email
                    ? 1
                    : 0.25,
              }}
              onClick={() => placeholderSign(placeholder, pageNumber)}
            >
              <h4
                style={{
                  fontSize: (width * 1) / 5,
                  position: "absolute",
                  top: 1.25,
                  color: "#283959",
                  fontWeight: "500",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {spiltedNames.map((item) => (
                  <span>{item}</span>
                ))}
                <h5 style={{ margin: "0", fontWeight: "500" }}>SIGN</h5>
              </h4>
              <img
                src="/images/download.png"
                style={{
                  width: "auto",
                  height: "1.5rem",
                }}
                alt="download icon"
              ></img>
            </div>
          )}
          {placeholder.event === "name" && (
            <div
              className={
                signMode && isUserSignatory()
                  ? "date-placeholder placeholder can-sign"
                  : "date-placeholder placeholder"
              }
              style={{
                width: width * 1.25,
                height: "32px",
                left: left,
                top: top,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "scale(" + 1 + ")",
                backgroundColor:
                  isUserOwner(user, owners) || isUserSignatory()
                    ? signatoryColor[placeholder.collaborator.email]
                    : "#EEDEBE",
                border: "4px solid #3D32CF",
                borderRadius: "5px !important",
                opacity:
                  !placeholder.collaborator.user &&
                  placeholder.collaborator.email === externalUser.email
                    ? 1
                    : 0.25,
              }}
            >
              <h5
                style={{
                  fontSize: 16,
                  color: "#283959",
                  margin: 0,
                  padding: "6px",
                }}
              >
                Full Name
              </h5>
            </div>
          )}
          {placeholder.event === "date" && (
            <div
              className={
                signMode && isUserSignatory()
                  ? "date-placeholder placeholder can-sign"
                  : "date-placeholder placeholder"
              }
              style={{
                width: width * 1.25,
                height: "32px",
                left: left,
                top: top,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "scale(" + 1 + ")",
                backgroundColor:
                  isUserOwner(user, owners) || isUserSignatory()
                    ? signatoryColor[placeholder.collaborator.email]
                    : "#EEDEBE",
                border: "4px solid #3D32CF",
                borderRadius: "5px !important",
                opacity:
                  !placeholder.collaborator.user &&
                  placeholder.collaborator.email === externalUser.email
                    ? 1
                    : 0.25,
              }}
            >
              <h5
                style={{
                  fontSize: 16,
                  color: "#283959",
                  margin: 0,
                  padding: "6px",
                }}
              >
                Date Signed
              </h5>
            </div>
          )}
        </>
      );
    });

    return result;
  };

  const placeholderSign = async (placeholder, pageNumber) => {
    let currentUser = externalUser ? externalUser : user;
    let signature = null;

    if (externalUser) {
      signature = externalUser?.user
        ? externalUser.user.signature
        : externalAdoptSignature;
    } else {
      signature = user.signature;
    }

    if (!signMode) {
      return;
    }

    if (placeholder?.collaborator?.email !== currentUser.email) {
      return;
    }

    if (!signature) {
      setOpenAdoptSignModal(true);
      return;
    }

    await adoptSignature(
      currentUser,
      placeholder.x,
      placeholder.y,
      placeholder
    );

    let placeholders = contract.metadata.placeholders;
    const index = placeholders.indexOf(placeholder);
    placeholders.splice(index, 1);

    setContract({
      ...contract,
      metadata: {
        placeholders: placeholders,
      },
    });
    setSignState(true);
    setSignedPageNumber(pageNumber);
  };

  const getCanvasWidth = () => {
    let canvas = document.querySelector(".react-pdf__Page__canvas");

    if (!canvas || canvas.length === 0) {
      return 0;
    }

    const rect = canvas.getBoundingClientRect();
    return rect.width;
  };

  const getCanvasLeft = () => {
    let canvas = document.querySelector(".document-upload-wrapper");

    if (!canvas || canvas.length === 0) {
      return 0;
    }

    if (canvas && canvasLeftPosition === 0 && getCanvasWidth()) {
      setCanvasLeftPosition((canvas.offsetWidth - getCanvasWidth()) / 2);
      setCurrentCanvasLeftPosition((canvas.offsetWidth - getCanvasWidth()) / 2);
    }

    return (canvas.offsetWidth - getCanvasWidth()) / 2;
  };

  const getCanvasHeight = () => {
    let canvas = document.getElementsByClassName("react-pdf__Page__canvas");

    if (!canvas || canvas.length === 0) {
      return 0;
    }

    const rect = canvas[0].getBoundingClientRect();

    return rect.height;
  };

  const adoptSignature = async (user, x, y, placeholder) => {
    let signature = null;

    if (externalUser) {
      signature = externalUser.user
        ? externalUser.user.signature
        : externalAdoptSignature;
    } else {
      signature = user.signature;
    }

    const pdfDoc = await PDFDocument.load(pdfContent);
    const pages = pdfDoc.getPages();
    const page = pages[placeholder.pageNumber - 1];

    const { height } = page.getSize();

    const pngImage = await pdfDoc.embedPng(signature);

    const ratio = pngImage.width / pngImage.height;
    const heightImg = 23;
    const widthImg = ratio * heightImg;

    const scaledWidth = widthImg / 1.3;

    const imgX = x * (1 / 1.3) - scaledWidth / 2;
    const imgY = height - y * (1 / 1.3) - heightImg / 2;

    page.drawImage(pngImage, {
      x: imgX,
      y: imgY,
      width: scaledWidth,
      height: heightImg,
    });
    const lineX = imgX - 5;
    const lineStartY = imgY - 7;
    const lineEndY = imgY + heightImg + 5;

    page.drawLine({
      start: { x: lineX, y: lineStartY },
      end: { x: lineX, y: lineEndY },
      thickness: 3,
      color: rgb(0.50980392, 0.96078431, 0.87058824),
      opacity: 1,
    });

    const fontSizePt = 5;
    const textX = imgX;
    const textY = imgY + heightImg;
    page.drawText("signed via Logical Contracts:", {
      x: textX,
      y: textY,
      size: fontSizePt,
    });

    const alphanumericId = generateAlphanumericId();
    const date = new Date().toLocaleDateString("en-GB").replace(/\//g, ".");
    const bottomText = `${alphanumericId} ${date}`;
    const bottomTextX = imgX;
    const bottomTextY = imgY - fontSizePt;
    page.drawText(bottomText, {
      x: bottomTextX,
      y: bottomTextY,
      size: fontSizePt,
    });

    const pdfBytes = await pdfDoc.saveAsBase64();
    setPDFContent("data:application/pdf;base64," + pdfBytes);
  };

  const adoptName = async (
    nameEventFilteredPlaceholders,
    dateEventFilteredPlaceholders
  ) => {
    let signature = null;
    let currentUser = externalUser ? externalUser : user;
    if (externalUser) {
      signature = externalUser.user
        ? externalUser.user.signature
        : externalAdoptSignature;
    } else {
      signature = user.signature;
    }
    if (!signature) {
      setOpenAdoptSignModal(true);
    }
    const pdfDoc = await PDFDocument.load(pdfContent);
    const pages = pdfDoc.getPages();
    const name = externalUser.fullName;
    const pngImage = await pdfDoc.embedPng(signature);
    const ratio = pngImage.width / pngImage.height;
    let font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    let width = name.split("").filter((name) => name !== "").length * 6.5 + 20;
    const heightImg = 23;
    const widthImg = ratio * heightImg;
    const scaledWidth = widthImg / 1.3;
    const fontSize = 8;

    nameEventFilteredPlaceholders.length > 0 &&
      nameEventFilteredPlaceholders?.forEach((placeholder) => {
        if (placeholder?.collaborator?.email !== currentUser.email) {
          return;
        }
        const x = placeholder.x;
        const y = placeholder.y;
        const page = pages[placeholder.pageNumber - 1];
        const { height } = page.getSize();
        const imgX = x * (1 / 1.3) - scaledWidth / 2;
        const imgY = height - y * (1 / 1.3) - heightImg / 2;

        page.drawText(name, {
          x: imgX,
          y: imgY + 10,
          font: font,
          size: fontSize * 1.5,
          color: rgb(0, 0, 0),
        });

        page.drawRectangle({
          x: imgX - 5,
          y: imgY,
          width: width,
          height: fontSize * 1.5 + 15,
          borderColor: rgb(0, 0, 0),
          borderWidth: 1,
        });

        let placeholders = contract.metadata.placeholders;

        const index = placeholders.indexOf(placeholder);
        placeholders.splice(index, 1);

        setContract({
          ...contract,
          metadata: {
            placeholders: placeholders,
          },
        });

        setSignedPageNumber(placeholder.pageNumber);
      });

    const pdfBytes = await pdfDoc.saveAsBase64();
    if (!dateEventFilteredPlaceholders) {
      setPDFContent("data:application/pdf;base64," + pdfBytes);
      return;
    }

    await adoptDate(
      dateEventFilteredPlaceholders,
      "data:application/pdf;base64," + pdfBytes
    );
  };

  const adoptDate = async (dateEventFilteredPlaceholders, pdfContent) => {
    let signature = null;
    let currentUser = externalUser ? externalUser : user;
    if (externalUser) {
      signature = externalUser.user
        ? externalUser.user.signature
        : externalAdoptSignature;
    } else {
      signature = user.signature;
    }
    if (!signature) {
      setOpenAdoptSignModal(true);
    }
    const pdfDoc = await PDFDocument.load(pdfContent);
    const pages = pdfDoc.getPages();
    const pngImage = await pdfDoc.embedPng(signature);
    let font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const ratio = pngImage.width / pngImage.height;
    const heightImg = 23;
    const widthImg = ratio * heightImg;
    const scaledWidth = widthImg / 1.3;
    const fontSize = 8;
    const date = new Date();
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    let width = formattedDate.split("").length * 6 + 10;

    dateEventFilteredPlaceholders.length > 0 &&
      dateEventFilteredPlaceholders.forEach((placeholder) => {
        if (placeholder?.collaborator?.email !== currentUser.email) {
          return;
        }

        const x = placeholder.x;
        const y = placeholder.y;
        const page = pages[placeholder.pageNumber - 1];
        const { height } = page.getSize();
        const imgX = x * (1 / 1.3) - scaledWidth / 2;
        const imgY = height - y * (1 / 1.3) - heightImg / 2;

        page.drawText(formattedDate, {
          x: imgX,
          y: imgY + 10,
          font: font,
          size: fontSize * 1.5,
          color: rgb(0, 0, 0),
        });

        page.drawRectangle({
          x: imgX - 5,
          y: imgY,
          width: width,
          height: fontSize * 1.5 + 15,
          borderColor: rgb(0, 0, 0),
          borderWidth: 1,
        });

        let placeholders = contract.metadata.placeholders;
        const index = placeholders.indexOf(placeholder);
        placeholders.splice(index, 1);

        setContract({
          ...contract,
          metadata: {
            placeholders: placeholders,
          },
        });

        setSignedPageNumber(placeholder.pageNumber);
      });

    const pdfBytes = await pdfDoc.saveAsBase64();
    setPDFContent("data:application/pdf;base64," + pdfBytes);
  };

  const generateAlphanumericId = () => {
    const alphanumericCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const idLength = 10;
    let id = "";
    for (let i = 0; i < idLength; i++) {
      const randomIndex = Math.floor(
        Math.random() * alphanumericCharacters.length
      );
      id += alphanumericCharacters.charAt(randomIndex);
    }
    return id;
  };

  const onDocumentLoadSuccess = (numPages) => {
    setNumPages(numPages.numPages);
    setTimeout(() => {
      if (signedPageNumber) {
        addSignatureFunct();
      }
    }, 500);
  };

  const fetchContract = () => {
    getContractUserByHash({ hash: location.hash.substring(1) }).then(
      (response) => {
        if (!response.data || !response.ok) {
          navigate("/contract-list");
          return;
        }

        if (user || response.data.user) {
          navigate("/contract-preview/" + response.data.contract.id);
          return;
        }

        setContractId(response.data.contract.id);

        if (!response.data.user || response.data.user?.id !== user?.id) {
          setExternalUser(response.data);
        }

        getActiveContractContent(
          response.data.contract.id,
          `hash=${location.hash.substring(1)}`
        ).then(async (responseContent) => {
          setContract({
            ...response.data.contract,
            metadata: responseContent.data.metadata
              ? JSON.parse(responseContent.data.metadata)
              : {},
          });

          setContent(responseContent.data.content);

          setContractContentLoading(false);

          if (response.data.contract.state > ContractStatus.REVIEWED) {
            setPDFContent(responseContent.data.uploadedContentSignature);
          } else {
            setPDFContent(
              responseContent.data.uploadedContentSignaturePlaceholder
            );
          }
        });
      }
    );

    if (user) {
      getAllContracts().then((response) => {
        if (!response || !response.ok) {
          return;
        }
        setAllContracts(response.data);
      });

      getContractCount().then((response) => {
        if (!response || !response.ok) {
          return;
        }

        setContractCount(response.data);
      });
    }
  };

  const fetchByContractId = () => {
    if (contractId) {
      getAllContractOwnersByContract(
        contractId,
        location.hash.substring(1)
      ).then((response) => {
        if (!response || !response.ok) {
          return;
        }

        setOwners(response.data);
      });

      getAllContractUsersByContract(
        contractId,
        location.hash.substring(1)
      ).then((response) => {
        if (!response || !response.ok) {
          return;
        }

        setCollaborators(response.data);
      });
    }
  };

  const fetchCollaborators = () => {
    if (contractId) {
      getAllContractUsersByContract(
        contractId,
        location.hash.substring(1)
      ).then((response) => {
        if (!response || !response.ok) {
          return;
        }

        setCollaborators(response.data);
      });
    }
  };

  const isUserOwner = (user, owners) => {
    if (externalUser) {
      return false;
    }

    if (user) {
      for (let owner of owners) {
        if (user?.id === owner?.user?.id) {
          return true;
        }
      }
    }

    return false;
  };

  const resetTabs = () => {
    setDisplayForms(true);
    setDetailsTab(false);
    setCommentsTab(false);
    setCollaboratorsTab(false);
    setFoldersTab(false);
    setDownloadTab(false);
    setDeleteTab(false);
    setHistoryTab(false);
    setForceValue(forceValue + 1);
  };

  const handleDetailsTab = () => {
    let currentUser = externalUser?.user ? externalUser.user : user;

    if (!currentUser) {
      setOpenNonValidSubscription(true);
      return;
    }

    if (!!currentUser) {
      if (!isTrialPackageValid(currentUser, contractCount)) {
        setOpenNonValidSubscription(true);
        return;
      }
    }

    resetTabs();
    setDetailsTab(true);
  };

  const checkIsPackageTrialIsValid = () => {
    let currentUser = externalUser?.user ? externalUser.user : user;

    if (externalUser) {
      return false;
    }
    if (!currentUser) {
      return false;
    }

    if (!!currentUser) {
      if (!isTrialPackageValid(currentUser, contractCount)) {
        return false;
      }
    }
    return true;
  };
  const handleCommentsTab = () => {
    if (!externalUser) {
      if (!isTrialPackageValid(user, contractCount)) {
        setOpenNonValidSubscription(true);
        return;
      }
    }

    if (!externalUser?.reviewer) {
      return;
    }

    resetTabs();
    setCommentsTab(true);
  };

  const handleCollaboratorsTab = () => {
    let currentUser = externalUser?.user ? externalUser.user : user;

    if (!externalUser.reviewer) {
      if (!currentUser) {
        setOpenNonValidSubscription(true);
        return;
      }

      if (!!currentUser) {
        if (!isTrialPackageValid(currentUser, contractCount)) {
          setOpenNonValidSubscription(true);
          return;
        }
      }
    }

    resetTabs();
    setCollaboratorsTab(true);
  };

  const handleFoldersTab = () => {
    let currentUser = externalUser?.user ? externalUser.user : user;

    if (!currentUser) {
      setOpenNonValidSubscription(true);
      return;
    }

    if (!!currentUser) {
      if (!isTrialPackageValid(currentUser, contractCount)) {
        setOpenNonValidSubscription(true);
        return;
      }
    }

    resetTabs();
    setFoldersTab(true);
  };

  const handleHistoryTab = () => {
    let currentUser = externalUser?.user ? externalUser.user : user;

    if (!currentUser) {
      setOpenNonValidSubscription(true);
      return;
    }

    if (!!currentUser) {
      if (!isTrialPackageValid(currentUser, contractCount)) {
        setOpenNonValidSubscription(true);
        return;
      }
    }

    resetTabs();
    setHistoryTab(true);
  };

  const handleDownloadTab = () => {
    setDownloadTab(true);
  };

  const handleCloseDownloadTab = () => {
    setDownloadTab(false);
  };

  const handleDownloadConfirm = async () => {
    setDownloadTab(false);

    if (pdfContent && contract.state > ContractStatus.PENDING_REVIEW) {
      fetch(pdfContent)
        .then((b) => b.blob())
        .then((r) => {
          const file = new Blob([r], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);

          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
        });
      return;
    } else if (editor && contract.state === ContractStatus.CREATED) {
      const config = editor.config.get("exportWord");
      editor.execute("exportWord", config);
    }
  };

  const handleDeleteTab = () => {
    let currentUser = externalUser?.user ? externalUser.user : user;

    if (!currentUser) {
      setOpenNonValidSubscription(true);
      return;
    }

    if (!!currentUser) {
      if (!isTrialPackageValid(currentUser, contractCount)) {
        setOpenNonValidSubscription(true);
        return;
      }
    }

    setDeleteTab(true);
  };

  const handleCloseDeleteTab = () => {
    setDeleteTab(false);
  };

  const handleDeleteConfirm = () => {
    deleteContract(contract.id).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      navigate("/contract-list");
      showMessage(strings.common.deleted);
    });

    setDeleteTab(false);
  };

  const handleEditContract = (data) => {
    let updateData = {};
    updateData.id = contract.id;

    if (data.name) {
      updateData.name = data.name;
      updateData.email = contract.user.email;
    }

    if (data.email) {
      updateData.email = data.email;
    }

    if (data.email === null) {
      updateData.email = null;
    }

    editContract(updateData).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      showMessage(strings.common.edited);
      fetch();
    });
  };

  const submitMoveToFolder = (data) => {
    moveContract({
      contractId: contract.id,
      folderId: data.id,
    }).then((response) => {
      fetch();
    });

    if (data.id == contract.folderId) {
      showMessage(
        strings.pages.contractPreview.contractMovedToItsFolderMsg + data.name
      );
    } else {
      showMessage(
        strings.pages.contractPreview.contractMovedToFolderMsg + data.name
      );
    }
  };

  const submitNewCollaborator = (collaborator) => {
    setContract({
      ...contract,
      collaborators: collaborators,
    });
  };

  const signContract = async () => {
    let currentUser = externalUser ? externalUser : user;

    const collaborator = collaborators.find(
      (x) => x?.email === currentUser.email && x.signatory
    );

    const placeholders = contract.metadata?.placeholders?.filter(
      (x) => x?.collaborator?.email === currentUser.email
    );

    if (placeholders.length > 0) {
      setPlaceAllSignatures(true);
      return;
    }

    let fullName = externalUser
      ? externalUser.fullName
      : collaborator.user.fullName;
    let contractUserId = externalUser ? externalUser.id : collaborator.id;
    let signature = user?.signature;

    if (externalUser) {
      if (externalUser.user) {
        signature = externalUser.user.signature;
      } else {
        signature = externalAdoptSignature;
      }
    }

    setShowLocalLoader(true);
    setShowLocalLoaderText("Saving your document - just a moment...");
    save(true, pdfContent).then(async (response) => {
      esignContract(
        {
          fullName,
          initials: fullName
            .split(" ")
            .map((item) => {
              return item[0];
            })
            .join(""),
          signature: signature,
          newSignature: true,
          contractUserId,
        },
        location.hash.substring(1)
      ).then((response) => {
        setSignMode(false);
        setShowLocalLoader(false);
        setShowLocalLoaderText("");
        setShowAddSignatureButton(false);
        if (externalUser && !externalUser.user) {
          setForwardOnRegistration(true);
        }
      });
    });
  };

  const save = async (force, uploadedContent) => {
    if (!contract || contract.state === ContractStatus.APPROVED) {
      return;
    }

    if (force) {
      showLoader(true);
    }

    saveContract(
      contract.id,
      {
        content: editor ? editor.getData() : content,
        uploadedContent: uploadedContent,
        metadata: JSON.stringify(contract.metadata),
        force: force,
        userId: externalUser ? -1 : user.id,
        externalUserFullName: externalUser ? externalUser.fullName : null,
      },
      location.hash.substring(1)
    ).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      if (force) {
        fetchContract();
      }

      if (editor?.getData()) {
        setContent(editor.getData());
      }

      setHasChange(false);
      showLoader(false);
    });
  };

  const updateContractState = async (contract, state) => {
    setLoaderTitle("");
    setShowSignBanner(true);

    let currentUser = externalUser?.user ? externalUser.user : user;

    if (!currentUser) {
      setOpenNonValidSubscription(true);
      return;
    }

    if (!!currentUser) {
      if (!isTrialPackageValid(currentUser, contractCount)) {
        setOpenNonValidSubscription(true);
        return;
      }
    }

    if (state === ContractStatus.PENDING_E_SIGN) {
      setShowLocalLoader(true);
      setShowLocalLoaderText("Sending for signature - just a moment...");
      saveContract(
        contract.id,
        {
          content: editor ? editor.getData() : content,
          uploadedContent: pdfContent,
          metadata: JSON.stringify(contract.metadata),
          force: true,
        },
        location.hash.substring(1)
      ).then((response) => {
        if (!response || !response.ok) {
          setShowLocalLoader(false);
          setShowLocalLoaderText("");
          return;
        }

        changeContractState(contract.id, {
          state: state,
        }).then((response) => {
          setPlacePDFSignature(true);
          fetchContract();
          setShowLocalLoader(false);
          setShowLocalLoaderText("");
        });
      });

      return;
    }

    showLoader(true);
    changeContractState(contract.id, {
      state: state,
    }).then((response) => {
      showLoader(false);
      fetchContract();
    });
  };

  const renderPdfPages = () => {
    let result = [];

    if (numPages <= 1 && !!numPages) {
      return result;
    }

    for (let page = 2; page <= numberPagesToRender; page++) {
      result.push(
        <div
          className={
            showSignBanner &&
            contract.state === ContractStatus.PENDING_E_SIGN &&
            isUserSignatory()
              ? "document-upload-wrapper banner "
              : "document-upload-wrapper " +
                ((placePDFSignaturePlaceholder || placePDFSignature) && signMode
                  ? "place-active"
                  : "")
          }
        >
          {(contract.state === ContractStatus.REVIEWED ||
            contract.state === ContractStatus.PENDING_E_SIGN) && (
            <div
              className={
                !displayForms ? "pdf-overlay transition" : "pdf-overlay"
              }
              style={{
                width: getCanvasWidth(),
                height: getCanvasHeight(),
                left: getCanvasLeft() >= 0 ? currentCanvasLeftPosition : 0,
              }}
              onClick={(e) => overlayClick(e, page)}
            >
              {renderPlaceholders(page)}
            </div>
          )}
          <Document
            inputRef={pdfViewer}
            file={pdfContent}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              scale={pdfScale}
              pageNumber={page}
              onRenderSuccess={() => {
                setPlacePDFSignature(true);
              }}
            ></Page>
          </Document>
        </div>
      );
    }
    return result;
  };

  const canSaveContract = (contract) => {
    return (
      isUserOwner(user, owners) && contract.state <= ContractStatus.REVIEWED
    );
  };

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
  };

  const zoomIn = () => {
    if (pdfScale + 0.1 > 5) {
      setPdfScale(5);
      return;
    }
    setPdfScale(pdfScale + 0.1);
  };

  const zoomOut = () => {
    if (pdfScale - 0.1 < 1.3) {
      setPdfScale(1.3);
      return;
    }
    setPdfScale(pdfScale - 0.1);
  };

  const isEditDisabled = (contract, user, contractCount) => {
    const collaborator = collaborators.find(
      (x) => x?.email === externalUser?.email
    );

    if (collaborator) {
      return false;
    }
    return true;
  };

  const isUserSignedContract = () => {
    let currentUser = externalUser ? externalUser : user;

    const collaborator = collaborators.find((x) => x?.id === currentUser?.id);
    if (!collaborator) {
      return false;
    }

    return collaborator.isSigned;
  };

  const isUserSignatory = () => {
    const collaboratorList = collaborators.filter((x) =>
      externalUser
        ? x?.externalUserInviteID === externalUser?.externalUserInviteID
        : x?.user?.id === user.id
    );

    if (!collaboratorList) {
      return false;
    }

    for (let coll of collaboratorList) {
      if (coll?.signatory) {
        return true;
      }
    }

    return false;
  };

  const commentsDisabled = () => {
    if (!externalUser) {
      if (
        user?.userProfileType === UserType.PORTAL_ADMIN ||
        user?.userProfileType === UserType.SYSTEM_ADMIN
      ) {
        return contract.state >= ContractStatus.REVIEWED;
      }

      if (!user || !user.company || !user.company.package) {
        return true;
      }

      if (
        (user.company.package.dataRoomSignature ||
          user.company.package.dataRoomNoSignature) &&
        !user.company.package.baseFeatures
      ) {
        return true;
      }
    }

    if (externalUser?.reviewer) {
      return false;
    }

    return contract.state >= ContractStatus.REVIEWED;
  };

  const collaboratorsDisabled = () => {
    if (externalUser) {
      return false;
    }

    if (
      user?.userProfileType === UserType.PORTAL_ADMIN ||
      user?.userProfileType === UserType.SYSTEM_ADMIN
    ) {
      return false;
    }

    if (!user || !user.company || !user.company.package) {
      return true;
    }

    if (
      user.company.package.dataRoomSignature ||
      user.company.package.baseFeatures
    ) {
      return false;
    }

    return true;
  };

  const allPlaceholdersPlaced = () => {
    let counter = 0;

    for (let collaborator of collaborators) {
      if (collaborator.signatory && collaborator.placeholderPlaced) {
        counter++;
      }
    }

    return counter > 0 && collaborators.length !== 0;
  };

  const discardPlaceholder = () => {
    setPlacePDFSignaturePlaceholder(false);
    setPlacePDFNamePlaceholder(false);
    setPlacePDFDatePlaceholder(false);
    setSignState(false);
    setContract({
      ...contract,
      metadata: { placeholders: [] },
    });

    saveContractMetadata(contract.id, {
      metadata: JSON.stringify({ placeholders: [] }),
    });
  };

  const onSubmitData = (data) => {
    setForwardOnRegistration(false);

    setLoaderTitle("");
    showLoader(true);

    setEmail(data.email);
    registration(data).then((response) => {
      if (!response || !response.ok) {
        showMessage(strings.pages.registration.userAlreadyExists, "error");
        return;
      }

      setShowRegDialog(true);
      showLoader(false);
    });
  };

  const signatoriesExist = () => {
    if (!collaborators || collaborators.length === 0) {
      return false;
    }

    for (let collaborator of collaborators) {
      if (collaborator.signatory) {
        return true;
      }
    }

    return false;
  };

  const requestSign = () => {
    if (!signatoriesExist()) {
      setOpenInfoModal(true);
      setOpenInfoModalText("Add recipients signatures to the document");
      return;
    }

    if (!allPlaceholdersPlaced()) {
      setOpenInfoModal(true);
      setOpenInfoModalText("Add recipients signatures to the document");
      return;
    }

    setPlacePDFSignaturePlaceholder(false);
    setPlacePDFNamePlaceholder(false);
    setPlacePDFDatePlaceholder(false);
    updateContractState(contract, ContractStatus.PENDING_E_SIGN);
  };

  const handleChangeHistoryView = (contractContent) => {
    setContent(contractContent.content);
  };

  const addSignatureFunct = async () => {
    let elems = document.querySelectorAll("#ele[style*='opacity: 1']");

    elems[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });

    const filteredPlaceholders = contract.metadata.placeholders.filter(
      (placeholder) => placeholder.collaborator?.id === externalUser?.id
    );

    if (!filteredPlaceholders) {
      return;
    }

    const nameEventFilteredPlaceholders = filteredPlaceholders?.filter(
      (placeholder) => placeholder.event === "name"
    );

    const dateEventFilteredPlaceholders = filteredPlaceholders?.filter(
      (placeholder) => placeholder.event === "date"
    );

    if (nameEventFilteredPlaceholders.length > 0) {
      await adoptName(
        nameEventFilteredPlaceholders,
        dateEventFilteredPlaceholders
      );
    } else {
      if (dateEventFilteredPlaceholders.length > 0) {
        await adoptDate(dateEventFilteredPlaceholders, pdfContent);
      }
    }
  };

  return (
    <div id="contract-preview" className="contract-preview-container">
      {contract && (
        <>
          <div className="contract-container">
            <div
              className={
                contract.state < ContractStatus.REVIEWED
                  ? "save-container auto-height"
                  : "save-container"
              }
              ref={squareRef}
            >
              <div className="presence-list-container" />

              <div
                className={
                  contract.state > ContractStatus.PENDING_REVIEW
                    ? displayForms
                      ? "save-buttons-container fixed move-left"
                      : "save-buttons-container fixed"
                    : "save-buttons-container"
                }
              >
                {!externalUser && isUserOwner(user, owners) && (
                  <React.Fragment>
                    {!collaboratorsTab &&
                      contract.state <= ContractStatus.REVIEWED && (
                        <Button
                          className="default-btn white"
                          variant="contained"
                          onClick={handleCollaboratorsTab}
                        >
                          Share&nbsp;&nbsp;&nbsp;
                          <img src={"/images/icons/share.png"} alt="" />
                        </Button>
                      )}
                    {(contract.state === ContractStatus.CREATED ||
                      contract.state === ContractStatus.PENDING_REVIEW) && (
                      <React.Fragment>
                        <Button
                          className="default-btn"
                          variant="contained"
                          onClick={() => setAcceptReviewOpen(true)}
                        >
                          Complete
                        </Button>
                      </React.Fragment>
                    )}

                    {contract.state === ContractStatus.REVIEWED && (
                      <React.Fragment>
                        <Button
                          className="default-btn white"
                          variant="contained"
                          onClick={discardPlaceholder}
                        >
                          Discard
                        </Button>
                        <Button
                          className="default-btn"
                          variant="contained"
                          onClick={() => requestSign()}
                        >
                          Send for signing &nbsp;&nbsp;
                          <img src={"/images/icons/plane.png"} alt="" />
                        </Button>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}

                {showAddSignatureButton &&
                  contract.state === ContractStatus.PENDING_E_SIGN &&
                  !signMode &&
                  isUserSignatory() &&
                  !isUserSignedContract() && (
                    <Button
                      className="default-btn"
                      variant="contained"
                      onClick={() => {
                        setSignMode(true);
                        setTempPDFContent(pdfContent);
                        addSignatureFunct();
                        setShowAddSignatureButton(false);
                      }}
                    >
                      Add Signature
                    </Button>
                  )}

                {signMode && (
                  <React.Fragment>
                    <Button
                      className="default-btn white"
                      variant="contained"
                      onClick={() => {
                        setSignMode(false);
                        setPDFContent(tempPDFContent);
                        fetchContract();
                        setSignState(false);
                        setShowAddSignatureButton(true);
                      }}
                    >
                      Discard
                    </Button>
                    <Button
                      className="default-btn"
                      variant="contained"
                      onClick={() => {
                        signContract();
                        setShowAddSignatureButton(false);
                      }}
                    >
                      Finish
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </div>

            {(contract.contentType === ContractContentType.UPLOADED ||
              (contract.contentType === ContractContentType.GENERATED &&
                contract.state >= ContractStatus.REVIEWED)) && (
              <div>
                <div className="pdf-top-navigation">
                  <Tooltip title={"Zoom in"} placement="top">
                    <IconButton onClick={zoomIn} color="info">
                      <ZoomIn />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Zoom out"} placement="top">
                    <IconButton onClick={zoomOut} color="info">
                      <ZoomOut />
                    </IconButton>
                  </Tooltip>
                </div>
                <div
                  className={
                    showSignBanner &&
                    contract.state === ContractStatus.PENDING_E_SIGN &&
                    isUserSignatory()
                      ? "document-upload-wrapper banner "
                      : "document-upload-wrapper " +
                        ((placePDFSignaturePlaceholder || placePDFSignature) &&
                        signMode
                          ? "place-active"
                          : "")
                  }
                >
                  {(contract.state === ContractStatus.REVIEWED ||
                    contract.state === ContractStatus.PENDING_E_SIGN) && (
                    <div
                      className={
                        !displayForms ? "pdf-overlay transition" : "pdf-overlay"
                      }
                      style={{
                        width: getCanvasWidth(),
                        height: getCanvasHeight(),
                        left:
                          getCanvasLeft() >= 0 ? currentCanvasLeftPosition : 0,
                      }}
                      onClick={(e) => overlayClick(e, 1)}
                    >
                      {renderPlaceholders(1)}
                    </div>
                  )}
                  <Document
                    inputRef={pdfViewer}
                    file={pdfContent}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      scale={pdfScale}
                      pageNumber={1}
                      onRenderSuccess={() => {
                        setPlacePDFSignature(true);
                      }}
                    ></Page>
                  </Document>
                </div>

                {renderPdfPages()}
              </div>
            )}

            {contract.contentType === ContractContentType.GENERATED &&
              contract.state < ContractStatus.REVIEWED &&
              !contractContentLoading && (
                <div className="ckEditorContainer">
                  <div
                    className="commentSideBar"
                    style={{ minWidth: isOpenCommentSideBar ? "20vw" : "5vw" }}
                  >
                    <div
                      className="commentBoxHeader"
                      onClick={isOpenCommentSideBarHandler}
                      title={isOpenCommentSideBar ? "hide" : "show"}
                    >
                      {" "}
                      {isOpenCommentSideBar ? (
                        <ArrowForwardIos />
                      ) : (
                        <ArrowBackIos />
                      )}{" "}
                    </div>
                    <div
                      style={{
                        display: isOpenCommentSideBar ? "block" : "none",
                      }}
                      className="sidebar"
                    ></div>
                  </div>
                  <div
                    className={
                      "contract " +
                      (checkIsPackageTrialIsValid() ? "disable-scroll" : "")
                    }
                    style={{ maxWidth: isOpenCommentSideBar ? "70vw" : "88vw" }}
                  >
                    <CKEditor
                      onReady={(editor) => {
                        editor.execute("trackChanges");
                        const commentsRepository =
                          editor.plugins.get("CommentsRepository");

                        commentsRepository.on(
                          `change:activeCommentThread`,
                          (evt2, data2, value) => {
                            setIsOpenCommentSideBar(true);
                            setActiveThreadId(value?.id ?? "");
                            setChannelId(value?.channelId ?? "");
                          }
                        );

                        commentsRepository.on(
                          `set:activeCommentThread`,
                          (evt2, data2, value) => {
                            setIsOpenCommentSideBar(true);
                            setActiveThreadId(value?.id ?? "");
                            setChannelId(value?.channelId ?? "");
                          }
                        );

                        editor?.ui
                          ?.getEditableElement()
                          ?.parentElement?.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement()
                          );
                        setEditor(editor);

                        editor.data.set(content, {
                          suppressErrorInCollaboration: true,
                        });
                      }}
                      onError={(error, { willEditorRestart }) => {
                        if (willEditorRestart) {
                          editor.ui.view.toolbar.element.remove();
                        }
                      }}
                      onChange={(event, editor) => {
                        setHasChange(true);
                        setEditor(editor);
                      }}
                      disabled={isEditDisabled(contract, user, contractCount)}
                      // disabled={false}
                      editor={Editor}
                      config={{
                        extraPlugins: [TrackChangesIntegration],
                        toolbar: [
                          "heading",
                          "|",
                          "fontfamily",
                          "fontsize",
                          "|",
                          "wproofreader",
                          "|",
                          "alignment",
                          "|",
                          "fontColor",
                          "fontBackgroundColor",
                          "|",
                          "bold",
                          "italic",
                          "strikethrough",
                          "underline",
                          "|",
                          "link",
                          "|",
                          "outdent",
                          "indent",
                          "|",
                          "bulletedList",
                          "numberedList",
                          "|",
                          "insertTable",
                          "|",
                          "undo",
                          "redo",
                          "|",
                          "trackChanges",
                          "comment",
                          "commentsArchive",
                          "|",
                          "pageBreak",
                          "highlight",
                        ],
                        link: { addTargetToExternalLinks: true },
                        collaboration: {
                          channelId: "contract-" + contract.id,
                        },
                        presenceList: {
                          container: document.querySelector(
                            ".presence-list-container"
                          ),
                          collapseAt: 3,
                          onClick: (user, element) =>
                            console.log(user, element),
                        },
                        sidebar: {
                          container: document.querySelector(".sidebar"),
                        },
                        // autosave: {
                        //     waitingTime: 2000, // in ms
                        //     save() {
                        //         return save(false);
                        //     }
                        // },
                        cloudServices: {
                          tokenUrl:
                            process.env.REACT_APP_BASE_URL +
                            "api/user/get-cs-token/" +
                            externalUser?.email +
                            "/" +
                            externalUser?.fullName,
                        },
                        exportWord: {
                          tokenUrl:
                            process.env.REACT_APP_BASE_URL +
                            "api/user/get-cs-token/" +
                            externalUser?.email +
                            "/" +
                            externalUser?.fullName,
                        },
                      }}
                    />
                  </div>
                </div>
              )}

            {showAddSignatureButton &&
              showSignBanner &&
              contract.state === ContractStatus.PENDING_E_SIGN &&
              !signMode &&
              isUserSignatory() &&
              !isUserSignedContract() && (
                <div
                  className={
                    menuState === MenuState.FULL
                      ? "sign-banner reduce"
                      : "sign-banner"
                  }
                >
                  <img
                    src={"/images/icons/close.png"}
                    onClick={() => setShowSignBanner(false)}
                    alt=""
                  />
                  <div className={"sign-banner-content"}>
                    <div className={"left"}>
                      <h2>Ready to sign?</h2>
                      <p>
                        Click <b>Add signature</b> and place your signature on
                        the contract
                      </p>
                    </div>
                    <div className={"button"}>
                      <Button
                        className="default-btn"
                        variant="contained"
                        onClick={() => {
                          setSignMode(true);
                          setTempPDFContent(pdfContent);
                          addSignatureFunct();
                          setShowAddSignatureButton(false);
                        }}
                      >
                        Add Signature
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            {showSignBanner &&
              contract.state === ContractStatus.PENDING_E_SIGN &&
              signMode &&
              isUserSignatory() &&
              !isUserSignedContract() &&
              !signState && (
                <div
                  className={
                    menuState === MenuState.FULL
                      ? "sign-banner reduce"
                      : "sign-banner"
                  }
                >
                  <img
                    src={"/images/icons/close.png"}
                    onClick={() => setShowSignBanner(false)}
                    alt=""
                  />
                  <div className={"sign-banner-content"}>
                    <div className={"left"}>
                      <h2>Ready to sign?</h2>
                      <p>Place your signature on the contract</p>
                    </div>
                    <div className={"button"}>
                      <Button
                        className="default-btn"
                        variant="contained"
                        onClick={() => {
                          signContract();
                          setShowAddSignatureButton(false);
                        }}
                      >
                        Finish
                      </Button>
                    </div>
                  </div>
                </div>
              )}

            {showSignBanner &&
              contract.state === ContractStatus.PENDING_E_SIGN &&
              signMode &&
              isUserSignatory() &&
              signState && (
                <div
                  className={
                    menuState === MenuState.FULL
                      ? "sign-banner reduce"
                      : "sign-banner"
                  }
                >
                  <img
                    src={"/images/icons/close.png"}
                    onClick={() => setShowSignBanner(false)}
                    alt=""
                  />
                  <div className={"sign-banner-content"}>
                    <div className={"left"}>
                      <h2>Ready to finish?</h2>
                      <p>
                        You’ve added your signature. Review your work and then
                        select Finish
                      </p>
                    </div>
                    <div className={"button"}>
                      <Button
                        className="default-btn"
                        variant="contained"
                        onClick={() => {
                          signContract();
                          setShowAddSignatureButton(false);
                        }}
                      >
                        Finish
                      </Button>
                    </div>
                  </div>
                </div>
              )}
          </div>

          {displayForms && (
            <React.Fragment>
              {detailsTab && (
                <DetailsForm
                  handleEditContract={handleEditContract}
                  setDisplayForms={setDisplayForms}
                  contract={contract}
                  setContract={setContract}
                  allContracts={allContracts}
                />
              )}

              {commentsTab && (
                <CommentList
                  setDisplayForms={setDisplayForms}
                  contract={contract}
                  editor={editor}
                  contractContent={content}
                  currentUser={externalUser ? externalUser : user}
                />
              )}

              {collaboratorsTab && (
                <CollaboratorsForm
                  setCollaboratorForPlacement={setCollaboratorForPlacement}
                  collaboratorForPlacement={collaboratorForPlacement}
                  uploadedContent={pdfContent}
                  submitNewCollaborator={submitNewCollaborator}
                  setDisplayForms={setDisplayForms}
                  setCollaboratorsTab={setCollaboratorsTab}
                  collaborators={collaborators}
                  setParentCollaborators={setCollaborators}
                  contract={contract}
                  fetchContract={fetchContract}
                  owners={owners}
                  isUserOwner={isUserOwner(user, owners)}
                  editor={editor}
                  setPlacePDFSignaturePlaceholder={
                    setPlacePDFSignaturePlaceholder
                  }
                  placePDFSignaturePlaceholder={placePDFSignaturePlaceholder}
                  setPlacePDFNamePlaceholder={setPlacePDFNamePlaceholder}
                  placePDFNamePlaceholder={placePDFNamePlaceholder}
                  setPlacePDFDatePlaceholder={setPlacePDFDatePlaceholder}
                  placePDFDatePlaceholder={placePDFDatePlaceholder}
                  forceRender={forceRender}
                  externalUser={externalUser}
                />
              )}

              {foldersTab && (
                <FoldersForm
                  contract={contract}
                  submitMoveToFolder={submitMoveToFolder}
                  setDisplayForms={setDisplayForms}
                />
              )}

              {historyTab && (
                <HistoryList
                  isOwner={isUserOwner(user, owners)}
                  fetch={fetchContract}
                  setContent={handleChangeHistoryView}
                  setDisplayForms={setDisplayForms}
                  contract={contract}
                />
              )}
            </React.Fragment>
          )}

          <div className="icons">
            <Tooltip
              title={strings.pages.contractPreview.templateDetailsTooltip}
              placement="left"
              arrow
            >
              <div className="icon">
                <IconButton
                  disabled={!isUserOwner(user, owners)}
                  onClick={handleDetailsTab}
                >
                  <img src="/images/icons/document.png" alt="" />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip
              title={strings.pages.contractPreview.collaboratorsTooltip}
              placement="left"
              arrow
            >
              <div className="icon">
                <IconButton
                  disabled={collaboratorsDisabled()}
                  onClick={handleCollaboratorsTab}
                >
                  <img src="/images/icons/share.png" alt="" />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip
              title={strings.pages.contractPreview.moveToFolderTooltip}
              placement="left"
              arrow
            >
              <div className="icon">
                <IconButton
                  onClick={handleFoldersTab}
                  disabled={!isUserOwner(user, owners)}
                >
                  <img src="/images/icons/folder.png" alt="" />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip
              title={strings.pages.contractPreview.downloadTooltip}
              placement="left"
              arrow
            >
              <div className="icon" onClick={handleDownloadTab}>
                <IconButton>
                  <img src="/images/icons/download.png" alt="" />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip
              title={strings.pages.contractPreview.revisionHistoryTooltip}
              placement="left"
              arrow
            >
              <div className="icon" onClick={handleHistoryTab}>
                <IconButton>
                  <img src="/images/icons/history.png" alt="" />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip
              title={strings.pages.contractPreview.deleteTooltip}
              placement="left"
              arrow
            >
              <div className="icon">
                <IconButton
                  disabled={!isUserOwner(user, owners)}
                  onClick={handleDeleteTab}
                >
                  <img src="/images/icons/delete.png" alt="" />
                </IconButton>
              </div>
            </Tooltip>
          </div>

          <Modal
            open={openInfoModal}
            onClose={() => setOpenInfoModal(false)}
            className="confirm-modal-container"
          >
            <ConfirmModal
              contentText={openInfoModalText}
              confirmBtn={"Ok"}
              handleConfirm={() => {
                setOpenInfoModal(false);
                resetTabs();
                setCollaboratorsTab(true);
              }}
            />
          </Modal>

          <Modal
            open={downloadTab}
            onClose={handleCloseDownloadTab}
            className="confirm-modal-container"
          >
            <SimpleConfirmModal
              title={strings.modals.contractList.downloadContract.confirmText}
              cancelBtn={strings.modals.contractList.downloadContract.cancel}
              confirmBtn={strings.modals.contractList.downloadContract.yes}
              handleCloseModal={handleCloseDownloadTab}
              handleConfirm={handleDownloadConfirm}
            />
          </Modal>

          <Modal
            open={deleteTab}
            onClose={handleCloseDeleteTab}
            className="confirm-modal-container"
          >
            <SimpleConfirmModal
              title={strings.modals.contractList.deleteContract.confirmText}
              cancelBtn={strings.modals.contractList.deleteContract.no}
              confirmBtn={strings.modals.contractList.deleteContract.yes}
              confirmBtnClass={true}
              handleCloseModal={handleCloseDeleteTab}
              handleConfirm={handleDeleteConfirm}
            />
          </Modal>

          <Modal open={showLocalLoader} className="confirm-modal-container">
            <div className="loader-container">
              <p style={{ textAlign: "center" }}>{showLocalLoaderText} </p>
              <img src={"/images/LC.gif"} alt="" />
            </div>
          </Modal>

          <Modal
            open={openNonValidSubscription}
            onClose={() => setOpenNonValidSubscription(false)}
            className="confirm-modal-container"
          >
            <ConfirmModal
              contentText={
                strings.modals.contractList.nonValidSubscription
                  .confirmTextFreeTrial
              }
              cancelBtn={strings.modals.contractList.nonValidSubscription.no}
              confirmBtn={strings.modals.contractList.nonValidSubscription.yes}
              handleCloseModal={() => setOpenNonValidSubscription(false)}
              handleConfirm={() => navigate("/pricing-and-package")}
            />
          </Modal>

          <Modal
            open={acceptReviewOpen}
            onClose={() => setAcceptReviewOpen(false)}
            className="confirm-modal-container"
          >
            <ConfirmModal
              contentText={
                "Completing the contract accepts all amendments made by each party. It is suggested that you review and accept each amendment on its own merit."
              }
              cancelBtn={"Cancel"}
              confirmBtn={"Complete"}
              handleCloseModal={() => setAcceptReviewOpen(false)}
              handleConfirm={() => {
                updateContractState(contract, ContractStatus.REVIEWED);
                setAcceptReviewOpen(false);
              }}
            />
          </Modal>

          <Modal
            open={placeAllSignatures}
            onClose={() => setPlaceAllSignatures(false)}
            className="confirm-modal-container"
          >
            <ConfirmModal
              contentText={"Please place all signatures"}
              confirmBtn={"Ok"}
              handleCloseModal={() => {
                setPlaceAllSignatures(false);
                setShowAddSignatureButton(true);
              }}
              handleConfirm={() => {
                setPlaceAllSignatures(false);
                setShowAddSignatureButton(true);
              }}
            />
          </Modal>

          <Modal
            open={forwardOnRegistration}
            onClose={() => setForwardOnRegistration(false)}
            className="confirm-modal-container"
          >
            <RegistrationModal
              externalUser={externalUser}
              onSubmitData={onSubmitData}
            />
          </Modal>

          <Modal open={showRegDialog} className="confirm-modal-container">
            <div id="info-modal" className={"confirm-modal registration-modal"}>
              <SendIcon style={{ color: "#68d99c", margin: "0 auto" }} />
              <h2>Verification email sent</h2>
              <div></div>
              <p className="info-title">
                Verification email sent to <b>{email}</b> - please be sure to
                check spam folder too.
              </p>
              <div className="confirm-buttons" style={{ marginTop: "20px" }}>
                <Button
                  className={"neutral-btn btn"}
                  variant="contained"
                  onClick={() => {
                    setShowRegDialog(false);
                    navigate("/login");
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            open={openAdoptSignModal}
            onClose={() => setOpenAdoptSignModal(false)}
            className="confirm-modal-container"
          >
            <AdoptSignatureForm
              setOpenAdoptSignModal={setOpenAdoptSignModal}
              adoptSignature={(signature) => {
                setExternalAdoptSignature(signature);
                addSignatureFunct();
              }}
              externalUser={externalUser}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default ContractPreviewExternal;
