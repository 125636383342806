import React, { useState, useContext } from "react";
import styles from "../../DealRoom.module.css";
import strings from "../../../../localization";
import FundingForm from "../../../../Components/Forms/Pages/DealRoom/ConnectTabForms.js/FundingForm";
import BusinessInfoForm from "../../../../Components/Forms/Pages/DealRoom/ConnectTabForms.js/BusinessInfoForm";
import BorrowForm from "../../../../Components/Forms/Pages/DealRoom/ConnectTabForms.js/BorrowForm";
import { connectFundingSupport } from "../../../../Services/DealRoom/DealRoomService";
import { useParams } from "react-router-dom";
import SnackbarContext from "../../../../Context/SnackbarContext";

const FundingPage = ({ setPageName }) => {
  const { id } = useParams();
  const { showMessage } = useContext(SnackbarContext);
  const [formName, setFormName] = useState("fundingForm");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    businessDetails: {
      name: "",
      type: "",
      annualTurnover: null,
      industry: "",
    },
    typeOfFinance: "",
    borrowAmount: null,
    borrowDuration: "",
  });
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    mobile: "",
    businessDetails: {
      name: "",
      type: "",
      annualTurnover: "",
      industry: "",
    },
    typeOfFinance: "",
    borrowAmount: "",
    borrowDuration: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setFormData((prevState) => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: value,
        },
      }));
      setErrors((prevState) => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: "",
        },
      }));
    } else {
      if (name === "mobile") {
        if (/^\d*$/.test(value) && value.length <= 10) {
          setFormData((prevState) => ({
            ...prevState,
            [name]: value,
          }));
          setErrors((prevState) => ({
            ...prevState,
            [name]: "",
          }));
        }
        return;
      }
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const handleSubmit = () => {
    const newErrors = {};

    if (!formData.typeOfFinance) {
      newErrors.typeOfFinance = "Type of finance is required";
    }
    if (!formData.borrowAmount) {
      newErrors.borrowAmount = "Borrow amount is required";
    }
    if (!formData.borrowDuration) {
      newErrors.borrowDuration = "Borrow duration is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const requiredFields = [
      "fullName",
      "email",
      "mobile",
      "businessDetails.name",
      "businessDetails.type",
      "businessDetails.annualTurnover",
      "businessDetails.industry",
      "typeOfFinance",
      "borrowAmount",
      "borrowDuration",
    ];

    const missingFields = requiredFields.filter((field) => {
      const value = field.split(".").reduce((obj, key) => obj[key], formData);
      return !value;
    });

    if (missingFields.length > 0) {
      showMessage("Please fill in all required fields", "error");
      return;
    }

    connectFundingSupport(id, formData)
      .then((res) => {
        if (res.status === 204) {
          setPageName("requestMsgPage");
        } else {
          showMessage("Error in Funding Page", "error");
        }
      })
      .catch((err) => {
        console.log("error in connectFundingSupport", err);
      });
  };

  return (
    <div className={styles.dealRoom}>
      <div className={styles.paymentformContainer}>
        <div className={styles.payPageHeading}>
          <span>
            {formName === "fundingForm" &&
              strings.forms.dealRoom.connect.funding.title}
            {formName === "businessInfoForm" &&
              strings.forms.dealRoom.connect.businessInfo.title}
            {formName === "borrowForm" &&
              strings.forms.dealRoom.connect.borrow.title}
          </span>
        </div>
        <div className={styles.paySubHeadingBox}>
          <span className={styles.labelText}>
            {formName === "fundingForm" &&
              strings.forms.dealRoom.connect.funding.para}
            {formName === "businessInfoForm" &&
              strings.forms.dealRoom.connect.businessInfo.para}
            {formName === "borrowForm" &&
              strings.forms.dealRoom.connect.borrow.para}
          </span>
        </div>

        <div className={styles.fundingFormBox}>
          {formName === "fundingForm" && (
            <FundingForm
              setPageName={setPageName}
              setFormName={setFormName}
              formData={formData}
              handleChange={handleChange}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          {formName === "businessInfoForm" && (
            <BusinessInfoForm
              setFormName={setFormName}
              formData={formData}
              handleChange={handleChange}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          {formName === "borrowForm" && (
            <BorrowForm
              setPageName={setPageName}
              setFormName={setFormName}
              formData={formData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              setErrors={setErrors}
              errors={errors}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FundingPage;
