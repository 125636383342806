import strings from "../../localization";


export function getUserGoal() {
    return [
        {
            id: 'Creating contracts more quickly and efficiently',
            name: 'Creating contracts more quickly and efficiently'
        },
        {
            id: 'Creating contracts at a responsable cost',
            name: 'Creating contracts at a responsable cost'
        },
        {
            id: 'Creating contracts with my colleagues, customer and suppliers',
            name: 'Creating contracts with my colleagues, customer and suppliers'
        },
        {
            id: 'Learning and understanding more about contracts',
            name: 'Learning and understanding more about contracts'
        },
        {
            id: 'Managing and storing all of my contracts',
            name: 'Managing and storing all of my contracts'
        },
        {
            id: 'None of these',
            name: 'None of these'
        },
    ];
}
