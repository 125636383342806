import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import strings from "../../../localization";
import { registration } from "../../../Services/User/UserService";
import CreateAccount from './CreateAccount';
import UserType from './UserType/UserType';
import CompanyDetails from './UserType/CompanyDetails';
import { editUser } from '../../../Services/User/UserService';
import { setAuth } from "../../../Slices/AuthSlice";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import SnackbarContext from "../../../Context/SnackbarContext";
import { default as UserAccountType } from "../../../Constants/User/UserType";

const Registration = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch()
    const currentRoute = location.pathname;
    const loggedUser = useSelector((state) => state.auth.user)

    const [createAccountPage, setCreateAccountPage] = useState(loggedUser ? false : true)
    const [companyDetailsPage, setCompanyDetailsPage] = useState(false)

    const [accountData, setAccountData] = useState({})
    const [userType, setUserType] = useState(UserType.GUEST)
    const [finalData, setFinalData] = useState({})
    const [showRegDialog, setShowRegDialog] = useState(false)
    const [email, setEmail] = useState('')
    const { showMessage } = useContext(SnackbarContext);
    const [externalUser, setExternalUser] = useState(null)

    useEffect(() => {
        if (location.state) {

            if (location.state.userProfileType === UserAccountType.COMPANY_ADMIN || location.state.userProfileType === UserAccountType.COMPANY_USER || location.state.userProfileType === UserAccountType.GUEST) {
                deselectPages()
                setCompanyDetailsPage(true)
            }

            if (location.state.externalUserRegistration) {
                setExternalUser(location.state.externalUserRegistration);
            }
        }

    }, [location.state?.userProfileType])


    const deselectPages = () => {
        setCreateAccountPage(false)
        setCompanyDetailsPage(false)
    }

    const onSubmitAccount = (data) => {
        console.log("data",data)
        onSubmit(data);
    }

    const onSubmit = (data) => {
        if (externalUser) {

            let finalData = {
                ...externalUser,
                ...data,
            }

            setEmail(finalData.email);
            registration(finalData).then(response => {
                if (!response || !response.ok) {
                    showMessage(strings.pages.registration.userAlreadyExists, 'error')
                    return;
                }

                setShowRegDialog(true)
            });
            return;
        }


        if (location.state) {

            let company = {
                ...data,
            }

            let finalData = {
                ...loggedUser, 
                // userProfileType: UserAccountType.COMPANY_ADMIN, 
                company: company
            }

            editUser(finalData).then(response => {
                if (!response || !response.ok) {
                    return
                }

                dispatch(setAuth(response.data));
                navigate('/dashboard');
            })


        } else {
            let finalData = {
                ...accountData,
                userProfileType: UserAccountType.COMPANY_ADMIN,
                ...data,
            }

            if (loggedUser != null) {

                editUser({
                    ...loggedUser,
                    // userProfileType: UserAccountType.COMPANY_ADMIN, 
                    ...data,
                }).then(response => {
                    if (!response || !response.ok) {
                        return
                    }

                    dispatch(setAuth(response.data));
                    navigate('/dashboard', { state: { firstTime: true } });

                })


            } else {
                setEmail(finalData.email)
                registration(finalData).then(response => {
                    if (!response || !response.ok) {
                        showMessage(strings.pages.registration.userAlreadyExists, 'error')
                        return;
                    }

                    setShowRegDialog(true)
                });
            }
        }
    }

    const onSkipSubmit = () => {

        let finalData = { ...accountData, userProfileType: userType }
        setEmail(finalData.email)
        registration(finalData).then(response => {

            if (!response || !response.ok) {
                showMessage(strings.pages.registration.userAlreadyExists, 'error')
                return;
            }

            setShowRegDialog(true)
        });
    }

    return (
        <React.Fragment>
            {createAccountPage && !loggedUser &&
                <CreateAccount
                    onSubmitAccount={onSubmitAccount}
                />
            }

            {companyDetailsPage &&
                <CompanyDetails
                    onSubmitCompanyDetails={onSubmit}
                    userTypeExist={(location.state && location.state.userProfileType !== 6) ? true : false}
                    loggedUser={loggedUser ? loggedUser : null}
                />
            }

            <Modal
                open={showRegDialog}
                className='confirm-modal-container'
            >
                <div id="info-modal" className={" confirm-modal info-modal"}>

                    <SendIcon style={{ color: '#68d99c', margin: '0 auto' }} />
                    <h2>Verification email sent</h2>
                    <div>
                    </div>
                    <p className="info-title">Verification email sent to <b>{email}</b> - please be sure to check spam folder too.</p>
                    <div className="confirm-buttons" style={{ marginTop: '20px' }}>
                        <Button className={"neutral-btn btn"} variant="contained"
                            onClick={() => { setShowRegDialog(false); navigate('/login') }}>Ok</Button>
                    </div>
                </div>
            </Modal>

        </React.Fragment>
    )
};
export default Registration;