import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { Box, Modal, Typography } from "@mui/material";
import SectionList from "./SectionList";
import strings from "../../../../localization";
import SnackbarContext from "../../../../Context/SnackbarContext";
import ConfirmModal from "../../../../Components/Modals/ConfirmModal";
import SwitchControl from "../../../../Components/Controls/Inputs/SwitchControl";
import {
  dueDeligenceShareResponse,
  getDueDeligenceSections,
} from "../../../../Services/DealRoom/DealRoomService";

const DueDiligence = () => {
  const { showMessage } = useContext(SnackbarContext);
  const params = useParams();
  const [sectionList, setSectionList] = useState([]);
  const [sectionsType, setSectionsType] = useState(0);
  const [activeOpenSection, setActiveOpenSection] = useState([]);
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);

  const getSectionListHandler = () => {
    let projectId = params.id;
    getDueDeligenceSections(projectId, sectionsType)
      .then((res) => {
        if (res?.data) {
          let tempData = res?.data?.map((obj) => {
            obj.iseditable = false;
            if (activeOpenSection.includes(obj.id)) {
              obj.isOpen = true;
            } else {
              obj.isOpen = false;
            }

            obj.questions = obj.questions.map((queObj) => {
              queObj.iseditable = false;
              return queObj;
            });
            return obj;
          });
          setSectionList(tempData);
        }
      })
      .catch((err) => {
        console.log("error in getDueDeligenceSections", err);
      });
  };

  const toggleDueDeligence = () => {
    if (sectionsType === 0) {
      setSectionsType(1);
    } else {
      setSectionsType(0);
    }
  };

  const shareResponseHandler = () => {
    let projectId = params.id;

    dueDeligenceShareResponse(projectId).then((res) => {
      setIsOpenShareModal(false);
      showMessage("Shared Successfully!");
    });
  };

  useEffect(() => {
    getSectionListHandler();
  }, [sectionsType]);

  return (
    <>
      <Box display="flex" flexDirection="column" className="admin-deal-room">
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mt={4}
        >
          <Typography
            variant="h5"
            fontWeight={500}
            sx={{ margin: "0 1rem" }}
            className="left-toggle-value"
          >
            Legal
          </Typography>
          <SwitchControl
            onClick={toggleDueDeligence}
            noLeftMargin={true}
            className="switch-control"
            style={{
              marginTop: "0px",
              height: "30px",
              width: "78px",
              marginRight: "5px",
              marginLeft: "0px !important",
            }}
            controlCustomStyle={{
              height: "30px",
              width: "76px",
              marginLeft: "0px",
            }}
            tabActiveStyle={{
              marginLeft: "auto",
              height: "30px",
              width: "30px",
            }}
            tabWhiteStyle={{ height: "30px", width: "30px" }}
            showValues={false}
            value={sectionsType}
          />

          <Typography
            variant="h5"
            fontWeight={500}
            className="left-toggle-value"
            sx={{ margin: "0 0.5rem" }}
          >
            Financial
          </Typography>
          <Button
            className="default-btn btn"
            variant="contained"
            sx={{ marginLeft: "1rem", display: "flex", alignItems: "center" }}
            onClick={() => {
              setIsOpenShareModal(true);
            }}
          >
            <UploadIcon sx={{ marginRight: "0.5rem" }} />
            Share Response
          </Button>
        </Box>
        <Box>
          <SectionList
            style={{ marginTop: "10rem", width: "100%" }}
            sectionList={sectionList}
            sectionsType={sectionsType}
            projectId={params?.id}
            getSectionListHandler={getSectionListHandler}
            showMessage={showMessage}
            setActiveOpenSection={setActiveOpenSection}
            activeOpenSection={activeOpenSection}
          />
        </Box>
        <Modal
          open={isOpenShareModal}
          onClose={() => setIsOpenShareModal(false)}
          className="confirm-modal-container"
        >
          <ConfirmModal
            title={strings.forms.dealRoom.dueDeligence.shareResponseTitle}
            cancelBtn={strings.modals.adminDealRoom.cancel}
            confirmBtn={strings.modals.adminDealRoom.share}
            handleCloseModal={() => setIsOpenShareModal(false)}
            handleConfirm={shareResponseHandler}
            contentTextStyle={{ fontWeight: "600", fontSize: "22px" }}
            buttonsStyles={{ borderRadius: "10px !important" }}
          />
        </Modal>
      </Box>
    </>
  );
};

export default DueDiligence;
