import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getQuestionRelation(id) {
    return await request('/api/questionrelation/' + id);
}

export async function getAllQuestionRelations(questionId) {
    return await request('/api/questionrelation/all/' + questionId);
}

export async function getAllQuestionRelationsByTemplate(templateId) {
    return await request('/api/questionrelation/template/all/' + templateId);
}

export async function deleteQuestionRelation(id) {
    return await request('/api/questionrelation/' + id, {}, HttpMethod.DELETE);
}

export async function addQuestionRelation(data) {
    return await request('/api/questionrelation/add', data, HttpMethod.POST);
}

export async function editQuestionRelation(data) {
    return await request('/api/questionrelation/edit/' + data.id, data, HttpMethod.PUT);
}

function transformRequestData(data) {
    return data;
}