import React, {useEffect, useState} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';

const RadioGroupControl = (props) => {

    const [value, setValue] = useState(props.value);

    useEffect(() => {

        setValue(props.value)
    }, [props.value]);

    const handleChange = (event) => {
        setValue(event.target.value);
        props.setValue(props.name, parseInt(event.target.value));
    };

    return (
        <FormControl>
            <RadioGroup
                aria-label={props.ariaLabel}
                value={value}
                name="radio-buttons-group"
                defaultValue={props.defaultValue ? props.defaultValue : null}
                onChange={(event) => handleChange(event)}
                className="radio-group-control"
            >
                {
                    props.radioButtonList.map(e =>
                        <FormControlLabel
                            key={'stage-' + e.id}
                            value={e.id}
                            control={<Radio/>}
                            label={<div className="radio-text-with-tooltip">
                                {e.name}
                                {
                                    e.description &&
                                    <Tooltip
                                        title={<div dangerouslySetInnerHTML={{__html: e.description}}/>}
                                        placement="top"
                                        arrow
                                    >
                                        <IconButton><img src="/images/info_circle.svg"/></IconButton>
                                    </Tooltip>
                                }

                            </div>
                            }
                        />
                    )
                }
            </RadioGroup>
        </FormControl>
    );
}

export default RadioGroupControl;