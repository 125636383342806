import React, {useEffect} from "react";
import strings from "../../../localization";
import CheckBoxControl2 from '../../../Components/Controls/Inputs/CheckBoxControl2';
import TextFieldControl from '../../../Components/Controls/Inputs/TextFieldControl';
import  ValidationPatterns from '../../../Constants/Base/ValidationPatterns';

const formRules = {
    'price': {
        required: {value: true, message: strings.forms.common.required},
        pattern: {
            value: ValidationPatterns.NUMERIC,
            message: strings.forms.common.onlyNumeric
        }
    },
    'annualPrice': {
        required: {value: true, message: strings.forms.common.required},
        pattern: {
            value: ValidationPatterns.NUMERIC,
            message: strings.forms.common.onlyNumeric
        }
    }
}

const Price = ({ 
                name, 
                pack, 
                editIcon,
                setValue,
                data,
                errors
            }) => {

    useEffect(() => {
        setValue('onceOf', pack.onceOf)
        setValue('recommended', pack.recommended)
        setValue('annualPrice', pack.annualPrice)
        setValue('price', pack.price)
    }, [pack])

    return (
        <div className="item drop">
            <div className="item-title">
                <span>{name}</span>
            </div>

            <div>
                <div className={editIcon ? "short-input-field" : "short-input-field disabled"}>
                    <span className={editIcon ? "label" : "label disabled"}>{strings.forms.admin.adminPackageForm.price.monthlyPrice}</span>
                    <TextFieldControl
                        name='price'
                        control={data}
                        defaultValue={pack.price ? pack.price : ''}
                        rules={formRules['price']}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.price)}
                        helperText={errors.price && errors.price.message}
                        placeholder={pack.price}
                        readOnly={editIcon ? false : true }
                    />
                </div>

                <div className={editIcon ? "short-input-field" : "short-input-field disabled"}>
                    <span className={editIcon ? "label" : "label disabled"}>{strings.forms.admin.adminPackageForm.price.annualPrice}</span>
                    <TextFieldControl
                        name='annualPrice'
                        control={data}
                        defaultValue={pack.annualPrice ? pack.annualPrice : ''}
                        rules={formRules['annualPrice']}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.annualPrice)}
                        helperText={errors.annualPrice && errors.annualPrice.message}
                        placeholder={pack.annualPrice}
                        readOnly={editIcon ? false : true }
                    />
                </div>

                <CheckBoxControl2
                    name={'onceOf'}
                    control={data}
                    label={strings.forms.admin.adminPackageForm.price.onceOffFee}
                    setValue={setValue}
                    disabled={editIcon ? false : true }
                    defaulValue={pack.onceOf}
                />

                <CheckBoxControl2
                    name={'recommended'}
                    control={data}
                    label={strings.forms.admin.adminPackageForm.price.setAsRecommendedPackage}
                    setValue={setValue}
                    disabled={editIcon ? false : true }
                    defaulValue={pack.recommended}
                />
            </div>
        </div>
    )
}

export default Price;