import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "@mui/material";
import strings from "../../../../../../localization";
import TextFieldControl from '../../../../../Controls/Inputs/TextFieldControl';
import { getCompanyEmployeesNumber } from '../../../../../../Constants/User/CompanyEmployeesNumber';
import SelectControl from "../../../../../Controls/Inputs/SelectControl";
import { getJurisdiction } from "../../../../../../Constants/User/Jurisdiction";
import { getCompanyTypes } from "../../../../../../Constants/User/CompanyType";
import { getSectors } from "../../../../../../Constants/User/Sector";
import { getCompanyTurnover } from "../../../../../../Constants/User/CompanyTurnover";
import { getUserGoal } from "../../../../../../Constants/User/UserGoal";
import { getCompanyDescription } from "../../../../../../Constants/User/UserDescription";
import UserType from "../../../../../../Constants/User/UserType";

const DetailsForm = ({
    finalObj,
    userTypeExist,
    onSubmit,
    loggedUser,
    selectUserTypePage
}) => {

    const navigate = useNavigate();
    const form = useForm()
    const { data, control, watch, handleSubmit, getValues, setValue, formState: { errors } } = form;
    const [radioButtonList, setRadioButtonList] = useState(getCompanyEmployeesNumber())
    const [companies, setCompanies] = useState([]);
    const [showOther, setShowOther] = useState(false)
    const [showOtherJurisdiction, setShowOtherJurisdiction] = useState(false)
    const [openCompanySelect, setOpenCompanySelect] = useState(false)
    const [showOtherGoal, setShowOtherGoal] = useState(false)
    const [step, setStep] = useState(1);
    const [selectedGoals, setSelectedGoals] = useState([]);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setValue('companyType', getCompanyTypes().find(x => x.id === finalObj.companyType))
        setValue('companyName', finalObj.companyName)
        setValue('jurisdiction', getJurisdiction().find(x => x.id === finalObj.jurisdiction))
        setValue('companyTurnover', getCompanyTurnover().find(x => x.name === finalObj.companyTurnover))
        setValue('jurisdictionOther', finalObj.jurisdictionOther)
        setValue('address', finalObj.address)
        setValue('number', finalObj.number)
        setValue('director', finalObj.director)
        setValue('shareholder', finalObj.shareholder)
        setValue('employee', finalObj.employee)
        setValue('employeesNumber', finalObj.employeesNumber)
        setValue('description', getCompanyDescription().find(x => x.id === finalObj.description))
        setValue('sector', getSectors().find(x => x.id === finalObj.sector))
        setValue('sectorOther', finalObj.sectorOther)
        setSelectedGoals(finalObj.mainGoal ? finalObj.mainGoal : [])
        setValue('mainGoalCustom', finalObj.mainGoalCustom)

        setShowOtherJurisdiction(finalObj.jurisdiction === 'Other')
        setShowOther(finalObj.sector === 'Other')
    }, [finalObj])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {

            if (name === 'companyName') {
                handleInputChange(value.companyName)
            }

            setShowOtherJurisdiction(getValues().jurisdiction && getValues().jurisdiction.name === 'Other')
            setShowOther(getValues().sector && getValues().sector.name === 'Other')
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setOpenCompanySelect(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setShowOtherGoal(selectedGoals.indexOf('None of these') !== -1)
    }, [selectedGoals])

    const handleBack = () => {

        if (userTypeExist) {
            navigate('/my-details')
        } else {
            navigate('/registration')
        }
    }

    const handleInputChange = (term) => {

        if (!term || term.length < 2) {
            return;
        }

        setOpenCompanySelect(true);
        companyHouseSearch(term);
    }

    const companyHouseSearch = (term) => {


        fetch('https://api.company-information.service.gov.uk/search/companies?q=' + term, {
            headers: {
                'Content-Type': 'text/json',
                'Authorization': '76e6bfbb-e044-4b6b-b5f5-fd49072b21bd'
            }
        }).then(response => response.json())
            .then(data => {
                setCompanies(data ? data.items : [])
            });
    }

    const companySelected = (company) => {

        if (!company) {
            return;
        }

        setValue('companyName', company.title);
        setValue('address', company.address_snippet)
        setValue('number', company.company_number)
        setValue('companyCountry', company.address.country)
        setOpenCompanySelect(false);
    }

    const renderCompanies = () => {
        let result = []

        for (let company of companies) {
            result.push(
                <div className={'company-select-item'} onClick={() => companySelected(company)}>
                    <p>{company.title}</p>
                </div>
            )
        }

        return result
    }

    const handleTrial = (data) => {
        onSubmit({
            ...finalObj,
            ...data,
            mainGoal: selectedGoals.join('|')
        })
    }

    const renderGoals = () => {
        let result = []

        for (let goal of getUserGoal()) {
            result.push(
                <div key={'item-' + result.length}
                    className={selectedGoals?.find(x => x === goal?.name) === goal?.name ? "item selected-item" : "item"}
                    onClick={() => {
                        const index = selectedGoals.indexOf(goal.name);
                        if (index === -1) {
                            setSelectedGoals(goal.name === 'None of these' ? [goal.name] : [
                                ...selectedGoals.filter(x => x !== 'None of these'),
                                goal.name
                            ])
                        } else {
                            setSelectedGoals(selectedGoals.filter(x => x !== goal.name))
                        }

                    }}>
                    <span>{goal.name}</span>
                </div>
            )
        }

        return result
    }

    const handleOnKeyPress = (event) => {
        if (event.key.toLowerCase() === 'enter') {
            setValue('companyName', event.target.value);
            setOpenCompanySelect(false);
        }
    }

    return (
        <FormProvider {...form}>
            <form className="form">
                <div
                    className="form-fields details-form-fields describe-form-fields turnovers-form-fields goal-form-fields">
                    {
                        step === 1 &&
                        <React.Fragment>
                            <div className="item-field-container">
                                <span className="info-icon-text">{strings.forms.registration.userType.companyDetailsForm.fullCompanyName}</span>

                                <TextFieldControl
                                    name='companyName'
                                    control={data}
                                    defaultValue={''}
                                    fullWidth
                                    margin="normal"
                                    onKeyPress={handleOnKeyPress}
                                    error={Boolean(errors.companyName)}
                                    helperText={errors.companyName && strings.forms.common.required}
                                    placeholder={strings.forms.registration.userType.companyDetailsForm.enterFullCompanyName}
                                />
                                {
                                    openCompanySelect && companies?.length > 0 &&
                                    <div className={'company-select-container'} ref={dropdownRef}>
                                        {renderCompanies()}
                                    </div>
                                }
                            </div>
                            {/* {
                                ((userTypeExist && getValues().companyType) || !userTypeExist || !finalObj.companyType) &&
                                <div className="item-field-container">
                                    <label>Company Type</label>
                                    <SelectControl
                                        name='companyType'
                                        defaultValue={getCompanyTypes().find(x => x.id === getValues().companyType?.id)}
                                        options={getCompanyTypes()}
                                        setValue={setValue}
                                        nameKey='name'
                                        valueKey='id'
                                        className="select-control-container"
                                        error={Boolean(errors.companyType)}
                                        helperText={errors.companyType && errors.companyType.message}
                                    />
                                </div>
                            }
                            {
                                ((userTypeExist && getValues().jurisdiction) || !userTypeExist || !finalObj.jurisdiction) &&
                                <div className="item-field-container">
                                    <label>Jurisdiction</label>
                                    <SelectControl
                                        name='jurisdiction'
                                        defaultValue={getJurisdiction().find(x => x.id === getValues().jurisdiction?.id)}
                                        options={getJurisdiction()}
                                        setValue={setValue}
                                        nameKey='name'
                                        valueKey='id'
                                        className="select-control-container"
                                        error={Boolean(errors.jurisdiction)}
                                        helperText={errors.sector && errors.jurisdiction.message}
                                    />
                                </div>
                            } 
                            {
                                showOtherJurisdiction &&
                                <div className="item-field-container">
                                    <TextFieldControl
                                        name='jurisdictionOther'
                                        control={data}
                                        fullWidth
                                        margin="normal"
                                        placeholder={'Other'}
                                    />
                                </div>
                            } */}

                            <div className="item-field-container">
                                <span
                                    className="info-icon-text">{strings.forms.registration.userType.companyDetailsForm.companyAddress}</span>
                                <TextFieldControl
                                    name='address'
                                    control={data}
                                    defaultValue={''}
                                    fullWidth
                                    margin="normal"
                                    error={Boolean(errors.address)}
                                    helperText={errors.address && strings.forms.common.required}
                                    placeholder={strings.forms.registration.userType.companyDetailsForm.enterCompanyAddress}
                                />
                            </div>

                            <div className="item-field-container">
                                {/* <span
                            className="info-icon-text">{strings.forms.registration.userType.companyDetailsForm.companyNumber}</span>
                                <TextFieldControl
                                    name='number'
                                    control={data}
                                    defaultValue={''}
                                    fullWidth
                                    margin="normal"
                                    error={Boolean(errors.number)}
                                    helperText={errors.number && strings.forms.common.required}
                                    placeholder={strings.forms.registration.userType.companyDetailsForm.enterCompanyNumber}
                                />*/}
                            </div>

                            <div className={'item-field-container'}>

                                {
                                    ((userTypeExist && getValues().sector) || !userTypeExist || !finalObj.sector) &&
                                    <div className="item-field-container">
                                        <label>Business Sector</label>
                                        <SelectControl
                                            name='sector'
                                            defaultValue={getSectors().find(x => x.id === getValues().sector?.id)}
                                            options={getSectors()}
                                            setValue={setValue}
                                            nameKey='name'
                                            valueKey='id'
                                            className="select-control-container"
                                            error={Boolean(errors.sector)}
                                            helperText={errors.sector && errors.sector.message}
                                        />
                                    </div>
                                }
                                {
                                    showOther &&
                                    <div className="item-field-container">
                                        <TextFieldControl
                                            name='sectorOther'
                                            control={data}
                                            fullWidth
                                            margin="normal"
                                            placeholder={'Other sector'}
                                        />
                                    </div>
                                }
                            </div>

                            {/* {
                                ((userTypeExist && getValues().companyTurnover) || !userTypeExist || !finalObj.companyTurnover) &&
                                <div className="item-field-container">
                                    <label>What is your company’s yearly turnover?</label>
                                    <SelectControl
                                        name='companyTurnover'
                                        defaultValue={getCompanyTurnover().find(x => x.id === getValues().companyTurnover?.id)}
                                        options={getCompanyTurnover()}
                                        setValue={setValue}
                                        nameKey='name'
                                        valueKey='id'
                                        className="select-control-container"
                                        error={Boolean(errors.companyTurnover)}
                                        helperText={errors.companyTurnover && errors.companyTurnover.message}
                                    />
                                </div>
                            }


                            <div className="two-columns">
                                <div className="checkboxes-container">
                                    <p className="title">{strings.forms.registration.userType.companyDetailsForm.yourDetails}</p>
                                    {
                                        ((userTypeExist && getValues().director !== undefined) || !userTypeExist) &&
                                        <CheckBoxControl2
                                            name={'director'}
                                            control={data}
                                            label={strings.forms.registration.userType.companyDetailsForm.director}
                                            setValue={setValue}
                                            defaulValue={getValues().director}
                                        />
                                    }
                                    {
                                        ((userTypeExist && getValues().shareholder !== undefined) || !userTypeExist) &&
                                        <CheckBoxControl2
                                            name={'shareholder'}
                                            control={data}
                                            label={strings.forms.registration.userType.companyDetailsForm.shareholder}
                                            setValue={setValue}
                                            defaulValue={getValues().shareholder}
                                        />
                                    }
                                    {
                                        ((userTypeExist && getValues().employee !== undefined) || !userTypeExist) &&
                                        <CheckBoxControl2
                                            name={'employee'}
                                            control={data}
                                            label={strings.forms.registration.userType.companyDetailsForm.employee}
                                            setValue={setValue}
                                            defaulValue={getValues().employee}
                                        />
                                    }
                                </div>

                                {
                                    ((userTypeExist && getValues().employeesNumber !== undefined) || !userTypeExist) &&
                                    <div className="checkboxes-container">
                                        <p className="title">{strings.forms.registration.userType.companyDetailsForm.employees}</p>
                                        <RadioGroupControl
                                            name={'employeesNumber'}
                                            setValue={setValue}
                                            radioButtonList={radioButtonList}
                                            defaultValue={getValues().employeesNumber}
                                        />
                                    </div>
                                } 
                            </div> */}
                        </React.Fragment>
                    }

                    {
                        step === 2 &&
                        <React.Fragment>
                            <div className="title-container">
                                <span className="title">What is your main goal with Logical Contract?</span>
                                <span className="title">02/02</span>
                            </div>

                            <div className="goals-container">
                                {renderGoals()}
                            </div>

                            {showOtherGoal &&
                                <React.Fragment>
                                    <div className="item-field-container">
                                        <span
                                            className="info-icon-text">{strings.forms.registration.userType.common.whatIsYourGoal}</span>
                                        <TextFieldControl
                                            name='mainGoalCustom'
                                            control={data}
                                            defaultValue={''}
                                            fullWidth
                                            margin="normal"
                                            error={Boolean(errors.mainGoalCustom)}
                                            helperText={errors.mainGoalCustom && strings.forms.common.required}
                                            placeholder={strings.forms.registration.userType.common.enterYourGoal}
                                        />
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }

                </div>

                {/* {
                    step === 1 &&
                    <React.Fragment>
                        <div className="submit-container">
                            <Button className="neutral-btn btn" variant="contained"
                                    onClick={() => userTypeExist ? navigate('/my-details') : selectUserTypePage()}>
                                <span className="btn-txt">{strings.forms.registration.userType.common.back}</span>
                            </Button>
                            <Button className="default-btn btn" variant="contained" onClick={() => setStep(2)}>
                                <span className="btn-txt">Next</span>
                            </Button>
                        </div>
                        <p className="skip-text"
                           onClick={() => setStep(2)}>{strings.forms.registration.userType.common.skipThisStep}</p>
                    </React.Fragment>

                }

                {
                    step === 2 && */}
                <div className="submit-container">
                        <Button className="neutral-btn btn" style={{ fontWeight: '400' }} variant="contained" onClick={handleSubmit(handleTrial)}>
                            <span className="btn-txt">{strings.forms.registration.userType.common.skip}</span>
                        </Button>
                        {/* {
                                (selectedGoals && selectedGoals.length > 0) && */}
                        <Button className="default-btn btn" style={{ fontWeight: '400' }} variant="contained"
                            onClick={handleSubmit(handleTrial)}>
                            <span className="btn-txt">
                                {loggedUser.userProfileType === UserType.GUEST ? strings.forms.registration.createAccount : strings.forms.registration.submit}
                            </span>
                        </Button>
                        {/* } */}

                    </div>
                    {/* {
                            (selectedGoals && selectedGoals.length === 0) &&
                            <p className="skip-text"
                               onClick={handleSubmit(handleTrial)}>{strings.forms.registration.userType.common.skipThisStep}</p>
                        } */}

                {/* } */}

            </form>
        </FormProvider>
    )
}

export default DetailsForm;