import React, {useEffect, useState} from "react";
import {getAllTemplateQuestionOptions} from "../../../Services/Template/TemplateQuestionOptionService";
import QuestionOption from "./QuestionOption";
import AddIcon from "@mui/icons-material/Add";
import strings from "../../../localization";
import {Button} from "@mui/material";
import QuestionFormat from "../../../Constants/Admin/QuestionFormat";

const QuestionOptions = (props) => {

    const [question, setQuestion] = useState(props.question)
    const [questionOptions, setQuestionOptions] = useState([]);

    useEffect(() => {

        setQuestion(props.question);

        if(!props.question) {
            return;
        }

        fetchOptions(props.question.id)

    }, [props.question]);

    const fetchOptions = (id) => {
        getAllTemplateQuestionOptions(id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            setQuestionOptions(response.data);
        })
    }

    const renderQuestionOptions = (questionOptions) => {

        let result = [];

        if(!questionOptions) {
            return result;
        }
        for(let questionOption of questionOptions) {
            result.push(<QuestionOption fetchOptions={fetchOptions} questionOption={questionOption} question={question} editor={props.editor}/>);
        }

        return result;
    }

    const addOption = () => {
        setQuestionOptions([
            ...questionOptions,
            {id: null}
        ]);
    }

    return <div className="question-format-form">
        {renderQuestionOptions(questionOptions)}
        {
            question && question.id && props.question.questionType !== QuestionFormat.TOGGLE
            && props.question.questionType !== QuestionFormat.SWITCH
            && props.question.questionType !== QuestionFormat.CHECKBOX
            && props.question.questionType !== QuestionFormat.COMPANY_HOUSE
            && props.question.questionType !== QuestionFormat.SLIDER &&
            <Button className="neutral-btn btn" startIcon={<AddIcon/>} variant="contained" style={{marginTop: '20px'}}
                    onClick={() => addOption()}>
                <span className="btn-txt">{strings.forms.admin.adminTemplateForm.questionFormatForm.newOptions}</span>
            </Button>
        }
    </div>
}

export default QuestionOptions