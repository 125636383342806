import React, { useEffect, useState, useContext } from 'react';
import styles from './AIData.module.css';
import AddIcon from '@mui/icons-material/Add';
import { Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AiDataTable from './AiDataTable';
import { VisibilityOutlined } from "@mui/icons-material";
import { aiRequestDeleteById, getAllAIRequestData } from '../../../Services/AI/AIConversationService';
import { useDispatch } from 'react-redux';
import { aiDataActions } from '../../../Slices/AiDataSlice';
import SimpleConfirmModal from "./../../../Components/Modals/SimpleConfirmModal";
import SnackbarContext from '../../../Context/SnackbarContext';
import strings from '../../../localization';
import { useNavigate } from 'react-router'


const minWidth = 80

function AIRequestData({ setSubOption }) {
  const naviagte = useNavigate()

  const dispatch = useDispatch()

  const columns = [
    { id: 'request', label: 'Question', minWidth: '47%', fontWeight: '900' },
    { id: 'response', label: 'Answer', minWidth: '47%', fontWeight: '900' },
    { id: 'buttons', label: '', minWidth: "6%" },

  ];
  
  const { showMessage } = useContext(SnackbarContext);

  const [aiRequestData, setAiRequestlData] = useState([])
  const [rows, setRows] = useState([])
  const [deleteTab, setDeleteTab] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const setaiRequestRowHandler = (rowData) => {
    // setSubOption("AIRequestDataView")
    dispatch(aiDataActions.setaiRequestRow(rowData))
    naviagte(`/AIRequestData/${rowData.id}`)
  }

  const handleDeleteTab = (id) => {
    setDeleteId(id);
    setDeleteTab(true);
  };

  const handleCloseDeleteTab = () => {
    setDeleteId(null);
    setDeleteTab(false);
  };

  const deleteHandler = () => {
    if (deleteId !== null) {
      aiRequestDeleteById(deleteId)
      .then(res => {
        setDeleteTab(false);
        showMessage(strings.common.deleted);
        getAllAIRequestData()
        .then(res => {
          setAiRequestlData(res?.data?.items);
        })
        .catch(err => {
          console.log(err)
        })
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  const tableButtonsJsx = (data) => {
    return (<div className={styles.tableCell} >
      <VisibilityOutlined style={{ cursor: 'pointer' }} onClick={() => setaiRequestRowHandler(data)}  />
      <img  title={"Delete"} className={styles.iconImg} style={{ marginLeft: '10px', cursor: 'pointer' , marginBottom:"0.12rem"}} src="/images/icons/delete.png" onClick={() => handleDeleteTab(data.id)} alt="delete" />
    </div>)
  }

  function createData(request, response, buttons) {
    

    return { request, response, buttons };
  }


  useEffect(() => {

    getAllAIRequestData()
      .then(res => {
        // console.log("res", res.data)
        setAiRequestlData(res.data?.items)
      })
      .catch(err => {
        console.log(err)
      })

  }, [])


  useEffect(() => {

    if (aiRequestData?.length > 0) {
      const tempRows = aiRequestData.map(data => {
        return createData(data['request'],
          data['response'],
          tableButtonsJsx(data)
        )
      })
      setRows(tempRows)
    }

  }, [aiRequestData])





  // const {data, watch, setValue} = form;

  return (
    <div className={styles.mainContainer}>
      <div className={styles.firstRow}>
        <div className={styles.firstRow_Left}>
          <div className={styles.button} onClick={() => naviagte(-1)}>
            <span>Back</span>
          </div>

          <span className={styles.headingtext}>AI Requests</span>

        </div>
        <div className={styles.firstRow_Right}>
          {/* <Button className='default-btn filter-btn btn' variant='contained'>
            <AddIcon />
            <span style={{ marginLeft: '20px' }}>New</span>
          </Button> */}
        </div>
      </div>

      <AiDataTable
        columns={columns}
        rows={rows}
      />

          <Modal
            open={deleteTab}
            onClose={handleCloseDeleteTab}
            className="confirm-modal-container"
          >
            <SimpleConfirmModal
              title={strings.modals.contractList.deleteContract.confirmTextForAI}
              cancelBtn={strings.modals.contractList.deleteContract.no}
              confirmBtn={strings.modals.contractList.deleteContract.yes}
              confirmBtnClass={true}
              handleCloseModal={handleCloseDeleteTab}
              handleConfirm={deleteHandler}
            />
          </Modal>


    </div>
  )
}

export default AIRequestData