import React, {useEffect, useRef, useState} from "react";
import {getAllActiveTemplateQuestions, getAllTemplateQuestions} from "../../../Services/Template/TemplateQuestionService";
import QuestionFormat from "../../../Constants/Admin/QuestionFormat";
import strings from "../../../localization";
import TextFieldControl from "../../../Components/Controls/Inputs/TextFieldControl";
import IconButton from '@mui/material/IconButton';
import {Tooltip} from "@mui/material";
import SwitchControl from "../../../Components/Controls/Inputs/SwitchControl";
import AutocompleteSelectControl from "../../../Components/Controls/AutocompleteSelectControl";
import CheckBoxControl from "../../../Components/Controls/Inputs/CheckBoxControl";
import DatePickerControl from "../../../Components/Controls/Inputs/DatePickerControl";
import TimePickerControl from "../../../Components/Controls/Inputs/TimePickerControl";
import TextareaControl from "../../../Components/Controls/Inputs/TextareaControl";
import RadioGroupControl from "../../../Components/Controls/Inputs/RadioGroupControl";
import SelectControl from "../../../Components/Controls/Inputs/SelectControl";
import SliderControl from "../../../Components/Controls/SliderControl";
import {compareDate, dateTimeToString, isEqualDate, stringToDate} from "../../../Util/DateUtil";
import ToggleControl from "../../../Components/Controls/Inputs/ToggleControl";
import QuestionRelationType from "../../../Constants/Admin/QuestionRelationType";
import {getJurisdiction} from "../../../Constants/User/Jurisdiction";
import CheckGroupBoxControl from "../../../Components/Controls/Inputs/CheckGroupBoxControl";

const StepQuestionList = ({step, watch, template, data, setValue, getValues, errors, relations}) => {

    const [questions, setQuestions] = useState([]);
    const [questionsVisibility, setQuestionsVisibility] = useState({})
    const [companies, setCompanies] = useState([]);
    const [openCompanySelect, setOpenCompanySelect] = useState('')
    const dropdownRef = useRef(null);

    useEffect(() => {

        if (!step || !template) {
            return;
        }

        getAllActiveTemplateQuestions(step.id).then(response => {

            if (!response || !response.ok) {
                return;
            }

            response.data = response.data.map(question => {
                if (question.options && question.options.length > 0) {
                  question.options = question.options.filter(option => !option.deleted);
                }
                return question;
              });

            setQuestions(response.data);

            setupDefaultValues(response.data);
            setDefaultValuesQuestions(response.data);

            setupQuestionVisibility(response.data, relations)

            const subscription = watch((value, {name, type}) => {
                setupQuestionVisibility(response.data, relations)
            });
        })
    }, [step, template, relations]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if(name.includes('_Company-Name') ) {
                handleInputChange(value[name], name)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const setupDefaultValues = (questions) => {
        for(let question of questions) {

            if(!question.options) {
                continue;
            }
            
            for(let option of question.options) {

                if(!option.isDefault) {
                    continue;
                }

                const name = question.id + '';

                if(question.questionType === QuestionFormat.SWITCH ||
                    question.questionType === QuestionFormat.TOGGLE ||
                    question.questionType === QuestionFormat.CHECKBOX) {
                    if(getValues()[name] === undefined || getValues()[name] === null) {
                        setValue(question.id + '', option.boolValue)
                    }
                }
                else if(question.questionType === QuestionFormat.CHECKBOX_GROUP) {
                    let checkedOptions = [];
                    question.options.map(option => {
                        if(option.boolValue || option.isDefault) {
                            checkedOptions = [...checkedOptions, option.id];
                        }
                    })
                    setValue(name, checkedOptions)
                }
                else {
                    if(!getValues()[name]) {
                        setValue(name, option.id)
                    }
                }
            }
        }
    }

    const getSwitchValue = (question, value) => {

        if (!question.options) {
            return ''
        }

        for (let option of question.options) {

            if (option.boolValue === value) {
                return option.stringValue;
            }
        }

        return ''
    }

    const getToggleValue = (question, index) => {
        if (!question.options) {
            return ''
        }

        if (!question.options[index]) {
            return ''
        }

        return question.options[index].stringValue;
    }

    const getSliderValue = (question, value) => {

        if (!question.options) {
            return 0
        }

        for (let option of question.options) {

            if (option.stringValue === value) {
                return option.intValue;
            }
        }

        return 0
    }

    const getOptions = (options) => {

        let result = [];

        if (!options) {
            return result;
        }
        
        for (let option of options) {

            if(option.deleted) {
                continue;
            }

            result.push({
                ...option,
                id: option.id,
                name: option.stringValue,
            })
        }

        return result;
    }

    const isRelationSatisfied = (relation) => {

        const relationQuestionValue = getValues()[relation.toQuestion.id]

        if(relation.toQuestion.questionType === QuestionFormat.SWITCH ||
            relation.toQuestion.questionType === QuestionFormat.CHECKBOX ||
            relation.toQuestion.questionType === QuestionFormat.TOGGLE) {
            if(relation.type === QuestionRelationType.EQUAL) {
                return relation.templateQuestionOption?.boolValue === relationQuestionValue;
            }
            else {

                return relationQuestionValue && relation.templateQuestionOption?.boolValue !== relationQuestionValue;
            }
        }
        else if(relation.toQuestion.questionType === QuestionFormat.RADIO_BUTTON) {
            if(relation.type === QuestionRelationType.EQUAL) {
                return relation.templateQuestionOption?.id === relationQuestionValue;
            }
            else {

                return relationQuestionValue && relation.templateQuestionOption?.id !== relationQuestionValue;
            }
        }
        else if(relation.toQuestion.questionType === QuestionFormat.CHECKBOX_GROUP) {
            if(relation.type === QuestionRelationType.EQUAL) {
                return relationQuestionValue && relationQuestionValue.includes(relation.templateQuestionOption?.id);
            }
            else {

                return relationQuestionValue && !relationQuestionValue.includes(relation.templateQuestionOption?.id);
            }
        }
        else if(relation.toQuestion.questionType === QuestionFormat.SELECT) {
            if(relation.type === QuestionRelationType.EQUAL) {
                return relationQuestionValue && relation.templateQuestionOption?.id === relationQuestionValue.id;
            }
            else {

                return relationQuestionValue && relation.templateQuestionOption?.id !== relationQuestionValue.id;
            }
        }
        else if(relation.toQuestion.questionType === QuestionFormat.NUMBER_INPUT) {
            const numberValue = parseFloat(relationQuestionValue);

            if(relation.type === QuestionRelationType.EQUAL) {
                return relation.numberValue === numberValue;
            }
            else if(relation.type === QuestionRelationType.NO_EQUAL) {
                return relation.numberValue !== numberValue;
            }
            else if(relation.type === QuestionRelationType.LOWER) {
                return  numberValue < relation.numberValue;
            }
            else if(relation.type === QuestionRelationType.LOWER_EQUAL) {
                return  numberValue <= relation.numberValue;
            }
            else if(relation.type === QuestionRelationType.GREATER) {
                return  numberValue > relation.numberValue;
            }
            else if(relation.type === QuestionRelationType.GREATER_EQUAL) {
                return  numberValue >= relation.numberValue;
            }
        }
        else if(relation.toQuestion.questionType === QuestionFormat.DATE_PICKER) {
            const inputDate = stringToDate(relationQuestionValue, 'dd MMMM yyyy');
            const relationDate = new Date(relation.stringValue);

            if(relation.type === QuestionRelationType.EQUAL) {
                return isEqualDate(inputDate, relationDate);
            }
            else if(relation.type === QuestionRelationType.NO_EQUAL) {
                return !isEqualDate(inputDate, relationDate)
            }
            else if(relation.type === QuestionRelationType.LOWER) {
                return inputDate < relationDate && !isEqualDate(inputDate, relationDate);
            }
            else if(relation.type === QuestionRelationType.LOWER_EQUAL) {
                return inputDate <= relationDate || isEqualDate(inputDate, relationDate);
            }
            else if(relation.type === QuestionRelationType.GREATER) {
                return inputDate > relationDate && !isEqualDate(inputDate, relationDate);
            }
            else if(relation.type === QuestionRelationType.GREATER_EQUAL) {
                return inputDate >= relationDate || isEqualDate(inputDate, relationDate);
            }
        }
        else if(relation.toQuestion.questionType === QuestionFormat.TIME_PICKER) {
            const inputDate = stringToDate(relationQuestionValue, 'h:mm a');
            const relationDate = new Date(relation.stringValue);
            relationDate.setSeconds(0);
            relationDate.setMilliseconds(0)

            if(relation.type === QuestionRelationType.EQUAL) {
                return inputDate.getTime() === relationDate.getTime();
            }
            else if(relation.type === QuestionRelationType.NO_EQUAL) {
                return inputDate.getTime() !== relationDate.getTime();
            }
            else if(relation.type === QuestionRelationType.LOWER) {
                return inputDate.getTime() < relationDate.getTime();
            }
            else if(relation.type === QuestionRelationType.LOWER_EQUAL) {
                return inputDate.getTime() <= relationDate.getTime();
            }
            else if(relation.type === QuestionRelationType.GREATER) {
                return inputDate.getTime() > relationDate.getTime();
            }
            else if(relation.type === QuestionRelationType.GREATER_EQUAL) {
                return inputDate.getTime() >= relationDate.getTime();
            }
        }
        else if(relation.toQuestion.questionType === QuestionFormat.SLIDER) {
            if(relation.type === QuestionRelationType.EQUAL) {
                return relation.numberValue === relationQuestionValue;
            }
            else if(relation.type === QuestionRelationType.NO_EQUAL) {
                return relation.numberValue !== relationQuestionValue;
            }
            else if(relation.type === QuestionRelationType.LOWER) {
                return  relationQuestionValue < relation.numberValue;
            }
            else if(relation.type === QuestionRelationType.LOWER_EQUAL) {
                return  relationQuestionValue <= relation.numberValue;
            }
            else if(relation.type === QuestionRelationType.GREATER) {
                return  relationQuestionValue > relation.numberValue;
            }
            else if(relation.type === QuestionRelationType.GREATER_EQUAL) {
                return  relationQuestionValue >= relation.numberValue;
            }
        }

        return true;
    }

    const isQuestionVisible = (question, relations) => {

        for (let relation of relations) {
            if (question && relation && relation.question && question.id === relation.question.id) {
                const result = isRelationSatisfied(relation)

                if (!result) {
                    return false;
                }
            }
        }

        return true;
    }

    const setupQuestionVisibility = (questions, relations) => {

        let visibility = {}

        for (let question of questions) {
            visibility[question.id] = isQuestionVisible(question, relations)
        }

        setQuestionsVisibility(visibility);
    }

    const handleOnKeyPress = (event, name) => {
        if(event.key.toLowerCase() === 'enter') {
            setValue(name, event.target.value);
            setOpenCompanySelect(false);
        }
    }

    const renderQuestion = (question) => {

        let values = getValues();
        let name = '' + question.id;
        const selectedOption = getOptions(question.options)?.find(x => values[name] && (x.id == values[name] || x.id == values[name].id) );

        if (question.questionType === QuestionFormat.TEXT_FIELD) {
            return <TextFieldControl
                name={name}
                control={data}
                defaultValue=''
                fullWidth
                margin="normal"
                error={Boolean(errors[name])}
                helperText={errors[name] && errors[name].message}
                placeholder={question.name}
            />
        }

        if (question.questionType === QuestionFormat.TEXTAREA) {
            return <TextareaControl
                name={name}
                control={data}
                defaultValue=''
                fullWidth
                margin="normal"
                error={Boolean(errors[name])}
                helperText={errors[name] && strings.forms.common.required}
                placeholder={question.name}
                rows={4}
                maxRows={8}
            />
        }

        if (question.questionType === QuestionFormat.NUMBER_INPUT) {
            return <TextFieldControl
                name={name}
                control={data}
                defaultValue={0}
                type="number"
                shortInput
                margin="normal"
                helperText={errors[name] && strings.forms.common.required}
                placeholder={question.name}
            />
        }

        if (question.questionType === QuestionFormat.TOGGLE) {

            return <ToggleControl
                name={name}
                value={values[name] !== undefined ? values[name] : question.options.find(x => x.isDefault && !x.deleted)?.boolValue}
                control={data}
                setValue={setValue}
                leftText={getToggleValue(question, 0)}
                rightText={getToggleValue(question, 1)}
            />
        }

        if (question.questionType === QuestionFormat.SWITCH) {
            return <SwitchControl
                name={name}
                value={values[name] !== undefined ? values[name] : question.options.find(x => x.isDefault && !x.deleted)?.boolValue}
                control={data}
                setValue={setValue}
                leftText={getSwitchValue(question, false)}
                rightText={getSwitchValue(question, true)}
            />
        }

        if (question.questionType === QuestionFormat.AUTOCOMPLETE) {
            return <AutocompleteSelectControl
                name={name}
                control={data}
                options={[]}
                placeholder={question.name}
            />
        }

        if (question.questionType === QuestionFormat.CHECKBOX) {
            return <div className="item-checkbox-container">
                <CheckBoxControl
                    name={name}
                    control={data}
                    label={question.name}
                    setValue={setValue}
                    defaultValue={values[name] !== undefined ? values[name] : question.options.find(x => x.isDefault && !x.deleted).boolValue}
                />
                {
                    question.description &&
                    <Tooltip title={<div dangerouslySetInnerHTML={{ __html: question.description }} />}>
                        <IconButton><img src="/images/info_circle.svg"/></IconButton>
                    </Tooltip>
                }
            </div>
        }

        if (question.questionType === QuestionFormat.CHECKBOX_GROUP) {
            return (
              <div className="item-checkbox-container">
                <CheckGroupBoxControl
                  name={name}
                  control={data}
                  label={question.name}
                  description={question.description}
                  setValue={setValue}
                  value={values[name]}
                  options={question.options}
                  error={Boolean(errors[name])}
                  helperText={errors[name] && errors[name].message}
                />
              </div>
            );
        }

        if (question.questionType === QuestionFormat.DATE_PICKER) {
            return <DatePickerControl
                format={'dd MMMM yyyy'}
                data={data}
                name={name}
                value={values[name + '-raw']}
                setValue={setValue}
            />
        }

        if (question.questionType === QuestionFormat.TIME_PICKER) {
            return <TimePickerControl
                data={data}
                name={name}
                value={values[name + '-raw']}
                setValue={setValue}
            />
        }

        if (question.questionType === QuestionFormat.RADIO_BUTTON) {
            return <RadioGroupControl
                name={name}
                defaultValue={values[name] ? values[name] : getOptions(question.options).find(x => x.isDefault)?.id}
                setValue={setValue}
                value={values[name]}
                tooltip
                radioButtonList={getOptions(question.options)}
            />
        }

        if (question.questionType === QuestionFormat.SLIDER) {
            return <SliderControl
                name={name}
                value={values ? values[name] : getSliderValue(question, 'Min')}
                step={1}
                min={getSliderValue(question, 'Min')}
                max={getSliderValue(question, 'Max')}
                setValue={setValue}
            />
        }

        if (question.questionType === QuestionFormat.SELECT) {

            return <SelectControl
                setValue={setValue}
                defaultValue={selectedOption ? selectedOption : getOptions(question.options).find(x => x.isDefault)}
                name={name}
                nameKey={'name'}
                valueKey={'id'}
                options={getOptions(question.options)}
            />
        }

        if(question.questionType === QuestionFormat.COMPANY_HOUSE) {

            const jurisdictionOtherName = name + '_Jurisdiction';

            return <div>
                <div className="item-field-container company-fields">
                    <TextFieldControl
                        name={name + '_Company-Name'}
                        control={data}
                        defaultValue={''}
                        // rules={{required: true}}
                        onKeyPress={(e) => handleOnKeyPress(e, name + '_Company-Name')}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors[name + '_Company-Name'])}
                        placeholder={strings.forms.dashboard.companyProfile.companyProfileForm.companyName}
                    />
                    {
                        (openCompanySelect === name + '_Company-Name' && companies?.length > 0) &&
                        <div className={'company-select-container'} ref={dropdownRef}>
                            {renderCompanies(name)}
                        </div>
                    }
                </div>


                <TextFieldControl
                    name={name + '_Company-Address'}
                    control={data}
                    defaultValue={''}
                    // rules={{required: true}}
                    fullWidth
                    margin="normal"
                    placeholder={strings.forms.dashboard.companyProfile.companyProfileForm.enterCompanyAddress}
                />

                <TextFieldControl
                    name={name + '_Company-Number'}
                    control={data}
                    defaultValue={''}
                    // rules={{required: true}}
                    fullWidth
                    margin="normal"
                    placeholder={strings.forms.dashboard.companyProfile.companyProfileForm.companyNumber}
                />

                <SelectControl
                    name={name + '_Jurisdiction'}
                    options={getJurisdiction()}
                    defaultValue={getJurisdiction().find(x => x.id === values[jurisdictionOtherName]?.id)}
                    setValue={setValue}
                    label='Jurisdiction'
                    nameKey='name'
                    valueKey='id'
                    className="select-control-container"
                    // rules={{required: true}}
                />

                {
                    (values[jurisdictionOtherName] && values[jurisdictionOtherName].name === 'Other') &&
                    <TextFieldControl
                        name={name + '_Jurisdiction-other'}
                        control={data}
                        defaultValue={''}
                        // rules={{required: true}}
                        fullWidth
                        margin="normal"
                        placeholder={'Other jurisdiction'}
                    />
                }
                {errors[name + '_Company-Name'] && errors[name + '_Company-Name'].message && <p style={{color: 'red', fontSize: '14px'}}>{errors[name + '_Company-Name'].message}</p> }
            </div>
        }
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
          if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setOpenCompanySelect(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

    const handleInputChange = (term, name) => {

        if(!term || term.length < 2) {
            return;
        }

        setOpenCompanySelect(name);
        companyHouseSearch(term);
    }

    const companyHouseSearch = (term) => {

        fetch('https://api.company-information.service.gov.uk/search/companies?q=' + term, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': '76e6bfbb-e044-4b6b-b5f5-fd49072b21bd'
            }
        }).then(response => response.json())
            .then(data => {
                setCompanies(data ? data.items : [])
            });
    }

    const companySelected = (company, name) => {

        if(!company) {
            return;
        }

        setValue(name + '_Company-Name', company.title);
        setValue(name + '_Company-Address', company.address_snippet)
        setValue(name + '_Company-Number', company.company_number)
        setOpenCompanySelect('')
    }

    const renderCompanies = (name) => {
        let result = []

        for(let company of companies) {
            result.push(
                <div className={'company-select-item'} onClick={() => companySelected(company, name)}>
                    <p>{company.title}</p>
                </div>
            )
        }

        return result
    }

    const setDefaultValuesQuestions = (questions) => {
        for (let question of questions) {
            if (question.questionType === QuestionFormat.TIME_PICKER) {
                if(!getValues([question?.id?.toString()])) {
                    setValue('' + question.id, dateTimeToString(new Date(), 'h:mm a'));
                }
            }

            if (question.questionType === QuestionFormat.DATE_PICKER) {
                if(!getValues([question?.id?.toString()])) {
                    setValue('' + question.id, dateTimeToString(new Date(), 'dd MMMM yyyy'));
                }
            }
        }
    }

    const renderQuestions = (questions) => {

        let result = [];

        if (!questions) {
            return result;
        }

        for (let question of questions) {
            if (!questionsVisibility[question.id]) {
                continue
            }

            let itemFieldClassName = 'item-field-container'
            let infoIconText = 'info-icon-text'

            if(question.questionType === QuestionFormat.SWITCH) {
                itemFieldClassName += ' switch-button-container'
                infoIconText += ' switch-button-container info-text'
            }

            result.push(
                <div className={itemFieldClassName}>
                    {
                        question.questionType !== QuestionFormat.CHECKBOX && question.questionType !== QuestionFormat.CHECKBOX_GROUP &&
                        <React.Fragment>
                                <span className={infoIconText}>{question.name}
                                    {
                                        question.description &&
                                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: question.description }} />}>
                                            <IconButton><img src="/images/info_circle.svg"/></IconButton>
                                        </Tooltip>
                                    }
                                </span>
                        </React.Fragment>
                    }

                    {renderQuestion(question)}
                </div>
            )
        }

        return result;
    }

    return renderQuestions(questions)
}

export default StepQuestionList