import React, { useState } from 'react';
import ConnectPage from './ConnectOptionsPage';
import LegalSupportForm from '../../../../Components/Forms/Pages/DealRoom/ConnectTabForms.js/SupportForm';
import RequestMsgPage from '../../RequestMsgPage';
import BuyOrSellForm from '../../../../Components/Forms/Pages/DealRoom/ConnectTabForms.js/BuyOrSellForm';
import LegalSupportPage from './LegalSupportPage';
import BuyOrSellPage from './BuyOrSellPage';
import FundingPage from './FundingPage';

const ConnectTab = () => {

  const [pageName, setPageName] = useState("connectPage")

  return (
    <>
      {pageName === "connectPage" && <ConnectPage setPageName={setPageName}/>}
      {pageName === "legalSupportPage" && <LegalSupportPage setPageName={setPageName}/>}
      {pageName === "buyOrSellFormPage" && <BuyOrSellPage setPageName={setPageName}/>}
      {pageName === "fundingPage" && <FundingPage setPageName={setPageName}/>}
      {pageName === "requestMsgPage" && <RequestMsgPage setPageName={setPageName}/>}
    </>
  )
}

export default ConnectTab