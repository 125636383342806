import strings from "../../localization";

const CompanyEmployeesNumber = {
    LESS_THAN_10: 1,
    MORE_THAN_10: 2
}

export function getCompanyEmployeesNumber() {
    return [
        {
            id: CompanyEmployeesNumber.LESS_THAN_10,
            name: getCompanyEmployeesNumberString(CompanyEmployeesNumber.LESS_THAN_10)
        },
        {
            id: CompanyEmployeesNumber.MORE_THAN_10,
            name: getCompanyEmployeesNumberString(CompanyEmployeesNumber.MORE_THAN_10)
        }
    ];
}

export function getCompanyEmployeesNumberDefault() {
    return 1;
}

export function getCompanyEmployeesNumberString(companyEmployeesNumber) {
    switch(companyEmployeesNumber) {
        case CompanyEmployeesNumber.LESS_THAN_10: return strings.constants.user.companyEmployeesNumber.lessThan;
        case CompanyEmployeesNumber.MORE_THAN_10: return strings.constants.user.companyEmployeesNumber.moreThan;
    }
}

export default CompanyEmployeesNumber;