import {useEffect, useState} from "react";
import {getAllQuestionRelations} from "../../../Services/Template/QuestionRelationService";
import {QuestionRelation} from "./QuestionRelation";
import {getAllTemplateQuestionsByTemplate} from "../../../Services/Template/TemplateQuestionService";
import React from "react";

export const QuestionRelations = ({question, template}) => {

    const [relations, setRelations] = useState([]);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        fetch()
    }, [question])

    const fetch = () => {
        getAllQuestionRelations(question.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setRelations(response.data);
        })

        getAllTemplateQuestionsByTemplate(template.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setQuestions(response.data);
        })
    }

    const renderRelations = (relations) => {
        let result = [];

        for(let relation of relations) {
            result.push(<QuestionRelation questions={questions} relation={relation} order={result.length + 1} fetch={fetch} ket={'relation-' + relation.id}/>)
        }

        return result;
    }

    return <React.Fragment>
        {
            relations && relations.length > 0 &&
            <div id='relations'>
                {
                    renderRelations(relations)
                }
            </div>
        }
    </React.Fragment>

}