import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import TextFieldPasswordControl from "../../../Controls/Inputs/TextFieldPasswordControl";
import CheckBoxControl from "../../../Controls/Inputs/CheckBoxControl";
import {Link} from "react-router-dom";
import IconButton from '@mui/material/IconButton';

const LoginForm = ({
                       onSubmit,
                       data,
                       form,
                       errors,
                       control,
                       setValue
                   }) => {

    return <FormProvider {...form}>
        <form id='auth-form' className='form' action="#">

            <TextFieldControl
                name='email'
                control={data}
                defaultValue=''
                rules={{required: true}}
                fullWidth
                margin="normal"
                error={Boolean(errors.email)}
                helperText={errors.email && strings.forms.login.emailError}
                placeholder={strings.forms.login.email}
                icon={<img src="/images/icons/email.png" />}
            />

            <TextFieldPasswordControl
                name='password'
                control={data}
                defaultValue=''
                rules={{required: true}}
                fullWidth
                type='password'
                margin="normal"
                error={Boolean(errors.password)}
                helperText={errors.password && strings.forms.login.passwordError}
                placeholder={strings.forms.login.password}
            />

            {/* <div className="checkbox-link-wrap">
                <CheckBoxControl
                    control={control}
                    name={'rememberMe'}
                    control={data}
                    label={strings.forms.login.rememberMe}
                    setValue={setValue}
                />
            </div> */}

            <div className='submit-container'>
                <Button className="auth-btn btn" variant="contained" onClick={onSubmit}>
                    {strings.forms.login.signIn}
                </Button>
            </div>

            <Link className='reset-password-text' to={'/reset-password-request'}>
                {strings.forms.login.forgotPassword}
            </Link>

            <p className='terms-service-and-privacy-policy-text'>
                <a target="_blank" href="https://logicalcontracts.io/terms/">
                    {strings.forms.login.serviceTerms}
                </a>
                {strings.forms.registration.and}
                <a target="_blank" href="https://logicalcontracts.io/privacy/">
                    {strings.forms.login.privacyPolicy}
                </a>
            </p>

        </form>
    </FormProvider>
}

export default LoginForm;
