import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllTemplateContent(templateId) {
    return await request('/api/templatecontent/all/' + templateId);
}

export async function getTemplateContent(id) {
    return await request('/api/templatecontent/' + id);
}

export async function restoreTemplateContent(templateId, templateContentId) {
    return await request('/api/templatecontent/restore/' + templateId + '/' + templateContentId, {}, HttpMethod.PATCH);
}

export async function getActiveTemplateContent(templateId) {
    return await request('/api/templatecontent/template/' + templateId);
}

export async function getLastTemplateContent(templateId) {
    return await request('/api/templatecontent/template-last/' + templateId);
}