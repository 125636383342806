import React, {useEffect, useState, useContext} from "react";
import {deleteTemplateQuestion, getAllTemplateQuestions, reorderQuestions} from "../../../Services/Template/TemplateQuestionService";
import IconButton from '@mui/material/IconButton';
import AddTemplateQuestion from "./AddTemplateQuestion";
import Modal from "@mui/material/Modal";
import ConfirmModal from "../../../Components/Modals/ConfirmModal";
import strings from "../../../localization";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {reorder, sortByQuestionOrder} from "../../../Util/DnDUtil";
import {addQuestionRelation} from "../../../Services/Template/QuestionRelationService";
import {QuestionRelations} from "./QuestionRelations";
import Tooltip from "@mui/material/Tooltip";
import SnackbarContext from "../../../Context/SnackbarContext";

const QuestionList = ({section, editor, refreshSections, template}) => {

    const {showMessage} = useContext(SnackbarContext);
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [openModal, setOpenModal] = useState(false)
    const [updateQuestions, setUpdateQuestions] = useState(false)

    useEffect(() => {
        fetch();
    }, [section])

    useEffect(() => {
        fetch()
    }, [updateQuestions])

    const fetch = () => {
        getAllTemplateQuestions(section.id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            setQuestions(sortByQuestionOrder(response.data));
        })
    }

    const handleOpenModal = (question) => {
        setOpenModal(true)
        setSelectedQuestion(question)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setSelectedQuestion(null);
    }

    const handleConfirmDelete = (question) => {

        deleteTemplateQuestion(question.id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            setSelectedQuestion(null);
            fetch();
            refreshSections()
        });

        showMessage(strings.common.removed)
    }

    const placeCode = (code) => {

        if(!editor) {
            return;
        }

        let selection = editor.model.document.selection
        const range = selection.getFirstRange();
        let selectedText = '';

        for (const item of range.getItems()) {

            if(!item.data) {
                continue;
            }

            selectedText = item.data
        }

        if(selectedText !== '') {
            editor.model.change( writer => {
                editor.model.insertContent( writer.createText('[' + code + ']'), range )
            } );
        }
        else {
            editor.model.change( writer => {
                writer.insertText( `[${code}]`, editor.model.document.selection.getFirstPosition() );
            } );
        }
    }

    const handleEditQuestion = (question) => {
        setSelectedQuestion(question);
    }

    const onDragEnd = (result) => {
        if(!result.source || !result.destination) {
            return;
        }

        const stages = reorder(questions, result.source.index, result.destination.index);
        
        const stagesModified = stages.map((e, index) => {
            e.templateQuestionOrder = index
            return e
        })

        setQuestions(sortByQuestionOrder(stagesModified))

        reorderQuestions(section.id, stagesModified).then(response => {
            if(!response || !response.ok) {
                return 
            }
        })
    }

    const handleRelations = (question) => {
        addQuestionRelation({
            questionId: question.id,
            toQuestion: -1,
            type: -1,
            stringValue: '',
            numberValue: 0,
            boolValue: false,
            templateQuestionOptionId: -1
        }).then(response => {
            fetch();
        })
    }

    const renderQuestions = () => {

        let result = [];

        if(!questions) {
            return result;
        }
        
        for(let question of questions) {
            result.push(
                <Draggable
                    key={question.id}
                    draggableId={'question-stage-' + question.id}
                    index={result.length}
                >
                
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >

                        <div {...provided.dragHandleProps} key={'question-' + question.id + '-section-' + section.id} className='question'>
                            <div className='question-header'>
                                <div className='content'>
                                    <h4>{question.name}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: question.description }} />
                                </div>
                                <div className="icons-container">
                                    <Tooltip
                                        title={strings.forms.admin.adminTemplateForm.tooltips.selectToAddMergeKey}
                                        placement="top"
                                        arrow>
                                        <IconButton className="item-icon" onClick={() => placeCode(question.code)}>
                                            <img src="/images/icons/merge.png" />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title={strings.forms.admin.adminTemplateForm.tooltips.editQuestion}
                                        placement="top"
                                        arrow>
                                        <IconButton className="item-icon" onClick={() => handleEditQuestion(question)}>
                                            <img src="/images/icons/pen.png" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title={strings.forms.admin.adminTemplateForm.tooltips.relations}
                                        placement="top"
                                        arrow>
                                        <IconButton className="item-icon" onClick={() => handleRelations(question)}>
                                            <img src="/images/icons/relation.png" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title={strings.forms.admin.adminTemplateForm.tooltips.delete}
                                        placement="top"
                                        arrow>
                                        <IconButton className="item-icon" onClick={() => handleOpenModal(question)}>
                                            <img src="/images/icons/delete.png" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <QuestionRelations question={question} template={template}/>
                        </div>
                    </div>
                    )}
                </Draggable>
            )}

        return result
    }

    const cancel = () => {
        setSelectedQuestion(null);
    }

    const refresh = () => {
        fetch();
        refreshSections();
    }

    return <div id='question-list' className='questions'>
        
        {
            (!selectedQuestion || openModal) &&

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable  droppableId='droppable-container' direction="vertical">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {renderQuestions()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext> 
        }
        {
            !openModal && selectedQuestion &&
            <AddTemplateQuestion
                editor={editor}
                section={null} 
                selectedQuestion={selectedQuestion} 
                cancel={cancel}
                refreshSections={refresh}
                setUpdateQuestions={setUpdateQuestions}
                updateQuestions={updateQuestions}
            />
        }

        { openModal && selectedQuestion &&
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='confirm-modal-container'
        >
            <ConfirmModal
                title={strings.modals.adminTemplates.title}
                contentText={strings.modals.adminTemplates.confirmTextQuestion}
                cancelBtn={strings.modals.adminTemplates.cancelBtn}
                confirmBtn={strings.modals.adminTemplates.deleteBtn}
                handleCloseModal={handleCloseModal}
                handleConfirm={() => handleConfirmDelete(selectedQuestion)}
            />
        </Modal>
        }
    </div>;
}

export default QuestionList