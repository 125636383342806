import {useLocation, useNavigate} from "react-router-dom";
import {checkPath} from "../route";
import {isUserLocked, isUserLoggedIn} from "./OAuth";
import Login from "../Pages/User/Login";
import Lock from "../Pages/User/Lock";
import Dashboard from "../Pages/Dashboard/Dashboard";

const AuthWrapper = (props) => {
    const location = useLocation();
    const needAuth = checkPath(location.pathname);

    const checkPermission = () => {
        if (needAuth && isUserLocked()) {
            return <Lock/>
        }
        else if (needAuth && !isUserLoggedIn()) {
            return <Dashboard/>;
        }

        return props.children;
    }

    return checkPermission();
};

export default AuthWrapper;
